import { Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import AlertModal from 'app/components/alert-modal/alert-modal';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { ALL_ADMIN_AUTHORITIES, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { searchProducers } from 'app/entities/producer/producer.reducer';
import { searchFinalDestinations } from 'app/entities/final-destination/final-destination.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Pageable } from 'app/shared/model/pageable';
import { IMtr, MtrStatus } from 'app/shared/model/mtr.model';
import { IRootState } from 'app/shared/reducers';
import React, { Fragment, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './custom-mtr-signature-all.scss';
import StaticTableBar, { StaticTableBarItem } from 'app/components/residues-components/add/static-table-bar/static-table-bar';
import moment from 'moment';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import { fetchMtrsAndSignatures, findMtr, reset, resetStatus } from 'app/modules/generic/mtr/mtr-reducer';
import { IProducer } from 'app/shared/model/producer.model';
import { IFinalDestination } from 'app/shared/model/final-destination.model';
import { isEmpty, isEqual } from 'lodash';
import { MtrReport } from 'app/shared/model/mtr-report.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import EmptyList from '../empty-list/empty-list';
import { generateCsvSelectedMtr, generateCsvUnselectedMtr } from 'app/modules/generic/mtr/mtr-reducer';
import { getMtrs } from 'app/modules/generic/mtr/mtr-reducer';
import { ISignature } from 'app/shared/model/signature.model';
import { getSignature } from 'app/modules/generic/mtr/signature/mtr-signature.reducer';
import { getEntity } from 'app/entities/signature/signature.reducer';
import { getSession } from 'app/shared/reducers/authentication';
import mtrSignatureService from 'app/services/mtrSignatureService';
import AlertModalNew from '../alert-modal-new/alert-modal-new';
import { toast } from 'react-toastify';

interface ICustomMtrSignatureAllProps extends StateProps, DispatchProps, RouteComponentProps {
    // TODO
}
interface ICustomMtrSignatureAllState {
    page: Pageable;
    status: MtrStatus.PENDING;
    signature: ISignature;
    signatureDate?: string;
    isAvailable?: boolean;
    endDate?: string;
    content: IMtr;
    startDate?: string;
    filterName: string;
    showFilter: boolean;
    isFirstGet: boolean;
    selectedMtrs: IMtr[];
    allSelected: boolean;
    unSelectedMtrs: IMtr[];
    showSuccess: boolean;
    finalDestinationAlert: boolean;
    producers: IProducer[];
    showErrorModal: boolean;
    producerOrFinalDestinationText: string;
    finalDestinations: IFinalDestination[];
    hasChangedProducerOrFinalDestination: boolean;
    selectedProducerOrFinalDestination: { id: number | null; name: string };
    searchFilter?: string;
    isFilteringDone: boolean;
    mtrReport: any;
    isLoading?: boolean;
}

class CustomMtrSignatureAll extends React.Component<ICustomMtrSignatureAllProps, ICustomMtrSignatureAllState> {
    timer = null;

    private tableHeaders: StaticTableBarItem[] = [
        { name: translate('mtr-report.mtrList.date'), width: '15%' },
        { name: translate('mtr-report.mtrList.mtrCode'), width: '15%' },
        { name: translate('mtr-report.mtrList.transporter'), width: '17%' },
        { name: translate(`mtr-report.mtrList.${this.props.hasFinalDestinationAuthorities ? 'producer' : 'finalDestination'}`), width: '15%' },
        { name: translate('mtr-report.mtrList.status'), width: '17%' },
        {
            name: translate('mtr-report.mtrList.selectAll'),
            width: '20%',
            color: '#30AB64',
            checkbox: true,
            onClick: event => this.selectAllMtrs(event),
        },
    ];

    constructor(props: Readonly<ICustomMtrSignatureAllProps>) {
        super(props);
        this.state = {
            producers: [],
            filterName: '',
            selectedMtrs: [],
            unSelectedMtrs: [],
            allSelected: false,
            showFilter: false,
            isFirstGet: false,
            showSuccess: false,
            finalDestinationAlert: true,
            showErrorModal: false,
            finalDestinations: [],
            producerOrFinalDestinationText: '',
            hasChangedProducerOrFinalDestination: false,
            selectedProducerOrFinalDestination: { id: null, name: '' },
            page: { totalElements: 0, size: 20, totalPages: 0, page: 0 },
            status: MtrStatus.PENDING,
            isFilteringDone: false,
            searchFilter: '',
            content: null,
            mtrReport: {},
            signature: null,
            isAvailable: false,
        };
    }

    componentDidMount() {
        this.resetAll();
        this.getMtrsAndSignatures();
        this.getAllProducersOrFinalDestinations();
        this.props.getSignature();
        this.props.getSession();
    }

    getMtrsAndSignatures = () => {
        const { page } = this.state;

        this.props.fetchMtrsAndSignatures(page.page, page.size, page.sort, {
            numberMtr: this.state.filterName,
            searchText: this.state.searchFilter,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        }, MtrStatus.PENDING);
    }


    componentWillUnmount() {
        this.resetAll();
    }

    componentWillReceiveProps(newProps: Readonly<ICustomMtrSignatureAllProps>) {
        if (newProps.totalItems !== this.props.totalItems) {
            this.setState({
                page: {
                    ...this.state.page,
                    totalElements: newProps.totalItems,
                },
            });
        }

        if (!isEqual(newProps.producers, this.state.producers) && newProps.producers.length !== 0) {
            // @ts-ignore
            this.setState({ producers: newProps.producers }, async () => {
                if (!this.state.isFirstGet) {
                    this.isFirstGet(true);
                    this.getMtrsAndSignatures();
                }
            });
        }

        if (!isEqual(newProps.finalDestinations, this.state.finalDestinations) && newProps.finalDestinations.length !== 0) {
            // @ts-ignore
            this.setState({ finalDestinations: newProps.finalDestinations }, async () => {
                if (!this.state.isFirstGet) {
                    this.isFirstGet(true);
                    this.getMtrsAndSignatures();
                }
            });
        }
        if (newProps.fetchSignatureStatus === HttpRequestStatus.SUCCESS && newProps.fetchSignatureStatus != this.props.fetchSignatureStatus) {
            this.setState({
                signature: newProps.signature,
            });
        }

        if (newProps.signMtrStatus === HttpRequestStatus.SUCCESS) {
            this.toggleSuccessModal();
        }
        if (newProps.signMtrStatus === HttpRequestStatus.ERROR) {
            this.toggleErrorModal();
        }
    }

    resetAll = () => {
        this.props.reset();
    };

    isFirstGet = (isFirstGet: boolean) => {
        this.setState({
            isFirstGet,
        });
    };

    isSelectedProducerOrFinalDestinationEmpty = () => {
        const { selectedProducerOrFinalDestination } = this.state;
        return selectedProducerOrFinalDestination.id == null;
    };

    setSelectedProducerOrFinalDestination = async (selectedProducerOrFinalDestination: IProducer | IFinalDestination) => {
        if (selectedProducerOrFinalDestination.id === this.state.selectedProducerOrFinalDestination.id) return;
        this.setState({
            hasChangedProducerOrFinalDestination: true,
            selectedProducerOrFinalDestination: {
                id: selectedProducerOrFinalDestination.id,
                name: selectedProducerOrFinalDestination['name'] || selectedProducerOrFinalDestination.legalPerson?.companyName || selectedProducerOrFinalDestination.naturalPerson?.name || '',
            },
        });
    };

    hasChangedProducerOrFinalDestination = (hasChangedProducerOrFinalDestination: boolean) => {
        this.setState({
            hasChangedProducerOrFinalDestination,
        });
    };

    showFilter = (showFilter: boolean) => {
        this.setState(
            {
                showFilter,
            },
            () => this.getAllProducersOrFinalDestinations()
        );
    };

    selectAllMtrs = (event: boolean) => {
        const { allSelected } = this.state;
        const selectedMtrs = this.props.mtrs.filter(mtr => !mtr.producerSignature || !mtr.transporterSignature || !mtr.finalDestinationSignature);

        if (allSelected) {
            this.setState({
                selectedMtrs: [],
                allSelected: false,
            });
        } else {
            this.setState({
                selectedMtrs,
                allSelected: true,
            });
        }
    };

    selectedMtr = (mtr: IMtr) => {
        const selectedMtrs = [...this.state.selectedMtrs];
        const selectedMtr = selectedMtrs.find(it => it.id === mtr.id);
        const { allSelected, unSelectedMtrs } = this.state;

        if (allSelected) {
            const unSelectedMtr = unSelectedMtrs.find(it => it.id === mtr.id);
            if (unSelectedMtr != null) {
                this.setState(prevState => ({
                    unSelectedMtrs: prevState.unSelectedMtrs.filter(item => item.id !== mtr.id),
                }));
            } else {
                this.setState(prevState => ({
                    unSelectedMtrs: [...prevState.unSelectedMtrs, mtr],
                }));
            }
        } else {
            if (selectedMtr != null) {
                this.setState(prevState => ({
                    selectedMtrs: prevState.selectedMtrs.filter(item => item.id !== mtr.id),
                }));
            } else {
                this.setState(prevState => ({
                    selectedMtrs: [...prevState.selectedMtrs, mtr],
                }));
            }
        }
    };

    checkedFunc = (content?: IMtr): boolean => {
        const { allSelected, unSelectedMtrs, selectedMtrs } = this.state;

        if (allSelected) {
            return !unSelectedMtrs.some(mtr => mtr.id === content.id);
        } else {
            return selectedMtrs.some(mtr => mtr.id === content.id);
        }
    };

    handleTransformToTableContent = (content: IMtr): (string | JSX.Element)[][] => {
        const { hasFinalDestinationAuthorities } = this.props;

        const result: (string | JSX.Element)[][] = [];

        const checked = this.checkedFunc(content);

        result.push([
            content.collectionDate != null ? moment(content.collectionDate).format(APP_LOCAL_DATE_FORMAT) : '',
            content.numberMtr || '',
            content.transporter != null ? content.transporter.legalPerson?.companyName || content.transporter.naturalPerson?.name : '',
            hasFinalDestinationAuthorities ? content.producer?.companyName || '' : content.finalDestination?.legalPerson?.companyName || '',
            this.getMtrStatusStyle(content.status),
            <Checkbox
                icon={<img src={'content/images/icons/check-gray.svg'} alt="" />}
                checkedIcon={<img src={'content/images/icons/check-green.svg'} alt="" />}
                checked={checked}
            />,
        ]);

        return result;
    };

    onCreateMtr = () => {
        this.props.history.push(`${this.props.match.url}/new`);
    };

    onHandleFilterChanged = (searchFilter: string) => {
        this.setState(
            {
                searchFilter,
                page: {
                    ...this.state.page,
                    page: 0,
                },
            },
            () => this.getMtrsAndSignatures()
        );
    };

    onMtrClick = (index: number) => {
        const { mtrs } = this.props;
        const mtr = mtrs[index];
        this.selectedMtr(mtr);
    };

    onBackHandler = () => {
        this.props.history.goBack();
    };

    toggleErrorModal = () => {
        this.setState({
            showErrorModal: !this.state.showErrorModal,
        });
    };

    toogleFinalDestinationAlert = () => {
        this.setState({
            finalDestinationAlert: !this.state.finalDestinationAlert,
        })
    }

    toggleSuccessModal = () => {
        this.setState({
            showSuccess: !this.state.showSuccess,
        });
    };

    cleanFilter = () => {
        this.setState(
            {
                searchFilter: '',
                endDate: undefined,
                startDate: undefined,
            },
            () => this.getMtrsAndSignatures()
        );
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: {
                    ...this.state.page,
                    page: newPage,
                },
            },
            () => this.getMtrsAndSignatures()
        );
    };

    getMtrStatusStyle = (mtrStatus: MtrStatus) => {
        if (mtrStatus == null) {
            return <div />;
        }
        let style = 'status-tag ';
        switch (mtrStatus) {
            case MtrStatus.PENDING:
                style = style.concat('pending');
                break;
        }
        return (
            <div className={style}>
                <span>{translate(`mtr-list.status.${mtrStatus}`).toUpperCase()}</span>
            </div>
        );
    };

    getAllProducersOrFinalDestinations = (searchText?: string) => {
        const { mtrs, hasFinalDestinationAuthorities } = this.props;
        const { selectedProducerOrFinalDestination } = this.state;
        if (mtrs.length > 0 && selectedProducerOrFinalDestination.id == null) {
            const typeAuthority = hasFinalDestinationAuthorities ? 'producers' : 'finalDestinations';
            let objects = mtrs
                .map(({ finalDestination, producer }) => (hasFinalDestinationAuthorities ? producer : finalDestination))
                .filter((item, index, array) =>
                    searchText
                        ? item?.companyName.toLowerCase().includes(searchText.toLowerCase())
                        : true &&
                        index === array.findIndex(t => t?.companyName === item?.companyName || t?.id === item?.id) &&
                        item?.companyName != null
                );
            // @ts-ignore
            this.setState({ [`${typeAuthority}`]: objects });
        }
    };

    mapAllProducersOrFinalDestinations = () => {
        const { producers, finalDestinations } = this.state;
        const { hasFinalDestinationAuthorities } = this.props;
        // @ts-ignore
        const items: (IProducer | IFinalDestination)[] = hasFinalDestinationAuthorities ? producers : finalDestinations;
        const objects = items
            ?.map(item => ({ name: item?.legalPerson?.companyName, id: item?.id }))
            .filter((item, index, array) => index === array.findIndex(t => t?.name === item?.name || t?.id === item?.id));
        return objects || [];
    };

    handleFilter = () => {
        this.setState(
            {
                page: {
                    ...this.state.page,
                    page: 0,
                },
            },
            () => this.getMtrsAndSignatures()
        );
    };

    setSignatures = (signature: ISignature): void => {
        this.setState({ isLoading: true });
        const { hasProducerAuthorities, hasTransporterAuthorities, hasFinalDestinationAuthorities } = this.props;
        const { id, signatureDocument } = this.props.signature;
        const { selectedMtrs, unSelectedMtrs } = this.state;

        if (hasProducerAuthorities) {
            const mtrReport: MtrReport = { mtrs: selectedMtrs || unSelectedMtrs };
            const param: ISignature = {
                id: id,
                signatureDocument: signatureDocument,
                signatureDate: moment().format('YYYY-MM-DD')
            };
            mtrSignatureService.sign(mtrReport, param)
                .then(() => {
                    this.setState({
                        mtrReport: { ...this.state.mtrReport, producerSignature: signature, producerSignatureDate: moment().format('DD/MM/YY') }
                    });

                })
                .catch(() => {
                    toast.success(translate('mtr-signature.signatureSuccess'));
                    this.props.history.goBack();
                })
        } if (hasTransporterAuthorities) {
            const mtrReport: MtrReport = { mtrs: selectedMtrs || unSelectedMtrs };
            const param: ISignature = {
                id: id,
                signatureDocument: signatureDocument,
                signatureDate: moment().format('YYYY-MM-DD')
            };
            mtrSignatureService.sign(mtrReport, param)
                .then(() => {
                    this.setState({
                        mtrReport: { ...this.state.mtrReport, transporterSignature: signature, transporterSignatureDate: moment().format('DD/MM/YY') }
                    });
                })
                .catch(() => {
                    toast.success(translate('mtr-signature.signatureSuccess'));
                    this.props.history.goBack();
                });
        } if (hasFinalDestinationAuthorities) {
            const mtrReport: MtrReport = { mtrs: selectedMtrs || unSelectedMtrs };
            const param: ISignature = {
                id: id,
                signatureDocument: signatureDocument,
                signatureDate: moment().format('YYYY-MM-DD')
            };
            mtrSignatureService.sign(mtrReport, param)
                .then(() => {
                    this.setState({
                        mtrReport: { ...this.state.mtrReport, finalDestinationSignature: signature, finalDestinationSignatureDate: moment().format('DD/MM/YY') }
                    });
                })
                .catch(() => {
                    toast.success(translate('mtr-signature.signatureSuccess'));
                    this.props.history.goBack();
                })
        }


    }

    renderRowItem = (info: string | JSX.Element, index: number) => (
        <div className="add-mtrs-list__item" style={{ width: this.tableHeaders[index].width }}>
            {info}
        </div>
    );

    renderRow = (mtr: IMtr) => {
        const row = this.handleTransformToTableContent(mtr);
        const { hasProducerAuthorities, hasTransporterAuthorities, hasFinalDestinationAuthorities } = this.props;

        if (hasProducerAuthorities && mtr.producerSignature == null) {
            return (
                <div className="add-mtrs-list__row" onClick={() => this.selectedMtr(mtr)}>
                    {this.renderRowItem(row[0][0], 0)}
                    {this.renderRowItem(row[0][1], 1)}
                    {this.renderRowItem(row[0][2], 2)}
                    {this.renderRowItem(row[0][3], 3)}
                    {this.renderRowItem(row[0][4], 4)}
                    {this.renderRowItem(row[0][5], 5)}
                </div>
            );
        } else if (hasTransporterAuthorities && mtr.transporterSignature == null) {
            return (
                <div className="add-mtrs-list__row" onClick={() => this.selectedMtr(mtr)}>
                    {this.renderRowItem(row[0][0], 0)}
                    {this.renderRowItem(row[0][1], 1)}
                    {this.renderRowItem(row[0][2], 2)}
                    {this.renderRowItem(row[0][3], 3)}
                    {this.renderRowItem(row[0][4], 4)}
                    {this.renderRowItem(row[0][5], 5)}
                </div>
            );
        } else if (hasFinalDestinationAuthorities && mtr.finalDestinationSignature == null) {
            return (
                <div className="add-mtrs-list__row" onClick={() => this.selectedMtr(mtr)}>
                    {this.renderRowItem(row[0][0], 0)}
                    {this.renderRowItem(row[0][1], 1)}
                    {this.renderRowItem(row[0][2], 2)}
                    {this.renderRowItem(row[0][3], 3)}
                    {this.renderRowItem(row[0][4], 4)}
                    {this.renderRowItem(row[0][5], 5)}
                </div>
            );
        } else {
            // Aqui você pode adicionar lógica para outras condições, ou retornar null se nenhum caso se aplicar.
            return null;
        }
    };

    renderMtrFilter = () => {
        // @ts-ignore
        return (
            <div className="mtr-list__filter-open" style={{ marginBottom: '0', paddingTop: '0' }}>
                <div className="sessao_1">
                    <div className="mtr-list__filter-open--title">{translate('mtr-list.filter-open.title-first')}</div>
                    <div className="mtr-list__filter-open--items">
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={value => this.setState({ startDate: value })}
                            dateSelected={this.state.startDate}
                            placeholder={translate('mtr-list.filter-open.if')}
                        />
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={value => this.setState({ endDate: value })}
                            dateSelected={this.state.endDate}
                            placeholder={translate('mtr-list.filter-open.until')}
                        />
                        <div className="mtr-list__filter-open--button" onClick={() => this.getMtrsAndSignatures()}>
                            <span>{translate('mtr-list.filter-open.button')}</span>
                            <div className="mtr-list__filter-open--img" />
                        </div>
                        <div className="mtr-list__filter-open--clear-filter" onClick={() => this.cleanFilter()}>
                            {translate('mtr-list.filter-open.clear-filter')}
                        </div>
                    </div>
                </div>
                <div className="mtr-list__filter-open--close" onClick={() => this.setState({ showFilter: false })} />
            </div>
        );
    };

    renderAddPage = () => {
        const { mtrs, hasFinalDestinationAuthorities } = this.props;
        const { finalDestinationAlert, showErrorModal, showSuccess, showFilter, selectedMtrs } = this.state;
        return (
            <Fragment>
                <Container>
                    <div>
                        <h1 style={{ marginTop: '28px', marginBottom: '15px', fontWeight: 600 }}>{translate('mtr-signature.requestSignatureAll')}</h1>
                        <div className={'custom-mtr-create'}>
                            <span style={{ marginBottom: '15px', fontSize: '18px' }}>{translate('mtr-signature.selectMtr')} <span className='selectSignature'>{translate('mtr-signature.selectSignature')}</span></span>
                        </div>
                    </div>
                    <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <Col md={{ size: '6', offset: '3' }}>
                            <SimpleSearchField
                                value={this.state.searchFilter}
                                placeholder={translate('mtr-report.searchMtr')}
                                onChange={this.onHandleFilterChanged}
                            />
                        </Col>
                        <div className={'mtr-list__items'}>
                            <div className="mtr-list__items--filter" onClick={() => this.showFilter(!showFilter)}>
                                <div className="mtr-list__items--filter-img" />
                                <span>{translate('mtr-list.filter')}</span>
                            </div>
                        </div>
                    </Row>
                    {showFilter && <Row style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>{this.renderMtrFilter()}</Row>}
                    <Row>
                        <Col>
                            <StaticTableBar staticItems={this.tableHeaders} />
                            <div className={'add-mtrs-list__container'}>
                                <div className="add-mtrs-list-item__container">{!isEmpty(mtrs) ? mtrs.map(mtr => this.renderRow(mtr)) : <EmptyList />}</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '40px' }} />
                        </Col>
                    </Row>
                </Container>
                <div className="mtr-add-mtrs__items--modal">
                    <Container>
                        <div className="mtr-add-mtrs__items--modal-buttons">
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col md="5">
                                    <Pagination
                                        color="primary"
                                        page={this.state.page.page + 1}
                                        count={Math.ceil(this.props.mtrs.filter(mtr => mtr.producerSignature == null).length || (mtr => mtr.finalDestinationSignature == null).length || (mtr => mtr.transporterSignature == null).length / this.state.page.size)}
                                        // count={Math.ceil(this.state.page.totalElements / this.state.page.size)}
                                        onChange={(e, page) => this.handleChangePage(page - 1)}
                                    />
                                </Col>
                                <Col md={{ size: 2, offset: 2 }}>
                                    <Button className={'button third'} type={'submit'} onClick={this.onBackHandler}>
                                        <Translate contentKey={'entity.action.cancel'} />
                                    </Button>
                                </Col>
                                <Col md="2">
                                    <Button
                                        onClick={this.setSignatures}
                                        className={'button fourth button-fourth'}
                                        disabled={
                                            this.state.allSelected ? false : isEmpty(selectedMtrs)
                                        }
                                    >
                                        <Translate contentKey={'mtr-signature.continue'} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                {hasFinalDestinationAuthorities && (
                    <AlertModalNew
                        hasTwoButtons
                        showModal={finalDestinationAlert}
                        statusImage="paper-question-mark.svg"
                        alertMessage="mtr-signature.finalDestinationAlert"
                        buttonOneAction={this.onBackHandler}
                        buttonOneActionMessage={'mtr-signature.button-back'}
                        buttonTwoAction={this.toogleFinalDestinationAlert}
                        buttonTwoActionMessage={'mtr-signature.button-continue'}
                    />
                )}

                <AlertModal
                    hasOneButton
                    showModal={showSuccess}
                    statusImage="paper-signature.svg"
                    alertMessage="mtr-signature.success"
                    buttonTwoAction={this.onBackHandler}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                />
                <AlertModal
                    hasOneButton
                    alertMessage="mtr-signature.error"
                    showModal={showErrorModal}
                    statusImage="paper-signature-error.svg"
                    buttonTwoAction={this.toggleErrorModal}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                />
            </Fragment>
        );
    };

    render() {
        return <div className={'mtr-add-mtrs__items'}>{this.renderAddPage()}</div>;
    }
}

const mapStateToProps = (root: IRootState) => ({
    mtrs: root.mtr.mtrs,
    mtr: root.mtr.mtr,
    signatureMtr: root.mtrSignature.signature,
    account: root.authentication.account,
    signature: root.profile.signature,
    fetchSignatureStatus: root.mtrSignature.fetchSignatureStatus,
    totalItems: root.mtr.totalItems,
    producers: root.producer.producers,
    signMtrStatus: root.mtrSignature.signMtrStatus,
    createMtrReportStatus: root.mtr.createMtrReportStatus,
    finalDestinations: root.finalDestination.finalDestinations,
    hasProducerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.PRODUCER_ADMIN,
        AUTHORITIES.PRODUCER_USER,
    ]),
    hasFinalDestinationAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.FINAL_DESTINATION_ADMIN,
        AUTHORITIES.FINAL_DESTINATION_USER,
    ]),
    hasTransporterAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSPORTER_ADMIN,
        AUTHORITIES.TRANSPORTER_USER,
    ]),
});

const mapDispatchToProps = {
    getSession,
    reset,
    findMtr,
    generateCsvSelectedMtr,
    generateCsvUnselectedMtr,
    resetStatus,
    searchProducers,
    searchFinalDestinations,
    getMtrs,
    fetchMtrsAndSignatures,
    getEntity,
    getSignature,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(CustomMtrSignatureAll);
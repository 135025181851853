import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export const ACTION_TYPES = {
  CREATE_FINAL_DESTINATION: 'producerRegister/CREATE_FINAL_DESTINATION',
  RESET: 'producerRegister/RESET',
};

const initialState = {
  errorMessage: null,
  entity: defaultValue,
  createFinalDestinationStatus: HttpRequestStatus.NOOP,
};

export type FinalDestinationRegisterState = Readonly<typeof initialState>;

// Reducer

export default (state: FinalDestinationRegisterState = initialState, action): FinalDestinationRegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_FINAL_DESTINATION):
      return {
        ...state,
        errorMessage: null,
        createFinalDestinationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_FINAL_DESTINATION):
      return {
        ...state,
        errorMessage: action.payload,
        createFinalDestinationStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.CREATE_FINAL_DESTINATION):
      return {
        ...state,
        entity: action.payload.data,
        createFinalDestinationStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-final-destinations';

// Actions
export const createFinalDestination: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const url = `${apiUrl}/new`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FINAL_DESTINATION,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const createFinalDestinationNatural: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const url = `${apiUrl}/natural`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FINAL_DESTINATION,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import * as React from 'react';

export interface IDeleteEditProps {
  hideDelete?: boolean;
  hideEdit?: boolean;
  id: any;
  onDelete?: (id: any) => void;
  onEdit?: (id: any) => void;
}

export default class DeleteEditButton extends React.Component<IDeleteEditProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { hideDelete, hideEdit, id } = this.props;
    return (
      <div className="invoice-table--img">
        {!hideDelete && (
          <div
            className="invoice-table--delete-img"
            onClick={e => {
              e.stopPropagation();
              this.props.onDelete(id);
            }}
          />
        )}
        {!hideEdit && (
          <div
            className="invoice-table--open-img"
            onClick={e => {
              e.stopPropagation();
              this.props.onEdit(id);
            }}
          />
        )}
      </div>
    );
  }
}

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './mtr-invite.scss';
import TableList from '../invite/table-list';

export const MtrInvite = () => {
  return (
    <div className="mtr-invite__items">
      <Container>
        <Row style={{ marginBottom: '15px', paddingTop: '40px', alignItems:'center' }}>
          <Col md="8">
            <h1 style={{ textAlign: 'left', fontWeight: 600 }}>{translate('mtr-invite.title')}</h1>
          </Col>
          <Col md={{ size: 4 }}>
            <Button className={'button primary'} type={'submit'}>
              <Translate contentKey={'mtr-invite.button'} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableList />
          <div className="mtr-invite__items--link">
            <a href="#">{translate('mtr-invite.link')}</a>
          </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '15px', paddingTop: '40px', alignItems:'center' }}>
          <Col md="12">
            <h1 style={{ textAlign: 'left', fontWeight: 600 }}>{translate('mtr-invite.title-second')}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableList />
          <div className="mtr-invite__items--link">
            <a href="#">{translate('mtr-invite.link-second')}</a>
          </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MtrInvite;

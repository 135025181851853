import React, { useState } from 'react';
import './cardholder.scss';
import { Col, Container, Row } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import CustomTextFieldForPlansScreen, { TypeMask } from 'app/components/custom-text-field-for-plans-screen/CustomTextFieldForPlansScreen';
import { Button } from '@material-ui/core';
import ICreditCard from 'app/shared/model/credit-card.model';
import CreditCardService from 'app/services/creditCardService';
import { maskNumber } from 'app/shared/util/mask-utils';
import ButtonLoading from 'app/components/button-loding/ButtonLoading';
interface IProps {
    handleShowFormCardholder: React.Dispatch<React.SetStateAction<boolean>>;
    creditCardData: ICreditCard;
    hadleCloseAddNew: () => void;
}

const Cardholder = ({ handleShowFormCardholder, creditCardData, hadleCloseAddNew }: IProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cpfOrCnpj, setCpfOrCnpj] = useState<string>('');

    const schema = yup.object().shape({
        email: yup
            .string()
            .email(translate('register.errors.invalidEmail'))
            .required(translate('register.errors.requiredField')),
        cpfOrCnpj: yup
            .string()
            .required(translate('register.errors.requiredField'))
            .matches(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/, translate('register.errors.invalidIdentification')),
        postalCode: yup
            .string()
            .required(translate('register.errors.requiredField'))
            .matches(/[0-9]{5}-[0-9]{3}/, translate('register.errors.invalidZipCode')),
        addressNumber: yup
            .string()
            .required(translate('register.errors.requiredField')),
        mobilePhone: yup
            .string()
            .required(translate('register.errors.requiredField'))
            .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/gi, translate('register.errors.invalidNumber')),
        phone: yup
            .string()
            .required(translate('register.errors.requiredField'))
            .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/gi, translate('register.errors.invalidNumber')),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
    });

    const handleOnSubmitDataForm = (data: ICreditCard) => {
        setIsLoading(true);
        const formatCpfOrCnpj = maskNumber(cpfOrCnpj);

        creditCardData.creditCardNumber = maskNumber(creditCardData.creditCardNumber);
        data.mobilePhone = maskNumber(data.mobilePhone);
        data.phone = maskNumber(data.phone);
        data.postalCode = maskNumber(data.postalCode);

        if (formatCpfOrCnpj.length === 11) {
            data.cpf = formatCpfOrCnpj;
        } else {
            data.cnpj = formatCpfOrCnpj;
        }

        const formatData: ICreditCard = {
            expireMonth: creditCardData.validity.substring(0, 2),
            expireYear: creditCardData.validity.substring(3, 5),
            ...creditCardData,
            ...data
        }

        delete formatData.validity;
        delete formatData.cpfOrCnpj;

        CreditCardService.createCreditCard(formatData).then(() => {
            setIsLoading(false);
            hadleCloseAddNew();
        });
    };

    return (
        <div className='cardholder__container'>
            <Container style={{ maxHeight: 'calc(100vh - 230px)', overflow: 'auto' }}>
                <Row>
                    <Col>
                        <div style={{ maxWidth: '521px', margin: '0 auto' }}>
                            <div className='cardholder__container--form-title'>
                                <div className='cardholder__container--icon-close' onClick={() => handleShowFormCardholder(false)} />
                                <span>
                                    <Translate contentKey={'register.select-payment.cardholder'} />
                                </span>
                            </div>
                            <div className='cardholder__container--form'>
                                <FormProvider {...methods}>
                                    <form onSubmit={methods.handleSubmit(handleOnSubmitDataForm)}>
                                        <fieldset>
                                            <CustomTextFieldForPlansScreen
                                                inputName='email'
                                                label={translate('register.select-payment.formCardholder.email')}
                                                errorText={methods.formState.errors.email?.message}
                                            />
                                            <CustomTextFieldForPlansScreen
                                                inputName='cpfOrCnpj'
                                                label={translate('register.select-payment.formCardholder.cpfOrCnpj')}
                                                errorText={methods.formState.errors.cpfOrCnpj?.message}
                                                mask={cpfOrCnpj.length < 14 ? TypeMask.CPF : TypeMask.CNPJ}
                                                value={cpfOrCnpj}
                                                onChange={setCpfOrCnpj}
                                            />
                                            <CustomTextFieldForPlansScreen
                                                inputName='postalCode'
                                                label={translate('register.select-payment.formCardholder.zipCode')}
                                                errorText={methods.formState.errors.postalCode?.message}
                                                mask={TypeMask.ZIP_CODE}
                                            />
                                            <CustomTextFieldForPlansScreen
                                                inputName='addressNumber'
                                                label={translate('register.select-payment.formCardholder.number')}
                                                errorText={methods.formState.errors.addressNumber?.message}
                                            />
                                            <CustomTextFieldForPlansScreen
                                                inputName='complement'
                                                label={translate('register.select-payment.formCardholder.complement')}
                                            />
                                            <CustomTextFieldForPlansScreen
                                                inputName='phone'
                                                label={translate('register.select-payment.formCardholder.phone')}
                                                errorText={methods.formState.errors.phone?.message}
                                                mask={TypeMask.PHONE}
                                            />
                                            <CustomTextFieldForPlansScreen
                                                inputName='mobilePhone'
                                                label={translate('register.select-payment.formCardholder.cell')}
                                                errorText={methods.formState.errors.mobilePhone?.message}
                                                mask={TypeMask.PHONE}
                                            />
                                        </fieldset>
                                        <div className='cardholder__container--form-buttons-container'>
                                            <Button
                                                className={'button third'}
                                                style={{ maxWidth: '138px' }}
                                                onClick={() => handleShowFormCardholder(false)}
                                            >
                                                <Translate contentKey={'register.select-payment.cancel'} />
                                            </Button>
                                            <ButtonLoading
                                                className='button fourth button-fourth'
                                                colorIconLoading="white"
                                                textButton={translate('register.select-payment.save')}
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    </form>
                                </FormProvider>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >
        </div >
    );
}

export default Cardholder;

import React from 'react';
import { Link } from 'react-router-dom';
import './custom-menu-item.scss';

export interface ICustomMenuItem {
  to: string;
  id?: string;
  active?: boolean;
}

export default class CustomMenuItem extends React.Component<ICustomMenuItem> {
  render() {
    const { to, id, children, active } = this.props;

    return (
      <Link to={to} key={id} className={`custom-menu__container ${active ? 'active' : ''}`}>
        {children}
      </Link>
    );
  }
}

import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import Header from '../header';
import SimpleHeader from '../simple-header';
import { RouteProps } from 'react-router-dom';
import { generateCsv } from 'app/modules/administration/user-management/user-management.reducer';
import { getProducerDetailByUuid } from 'app/entities/user-producer/user-producer.reducer';

export enum HeaderType {
  SIMPLE = 'SIMPLE',
  HEADER = 'HEADER',
  NONE = 'NONE',
}

export interface IHeaderBuilderProps extends StateProps, DispatchProps, RouteProps {
  headerType: HeaderType;
}

export class HeaderBuilder extends React.Component<IHeaderBuilderProps> {

  state = { userProducer: {} };

  componentDidMount() {
    if (this.props.account.finishedRegister && this.props.isProducerAdmin) {
      this.props.getProducerDetailByUuid().then((response) => this.setState({ userProducer: response.value.data }));
    }
  }

  renderHeaderType = () => {
    const { headerType, generateCsv, csvStatus } = this.props;

    if (headerType === HeaderType.NONE) {
      return <div />;
    } else if (headerType === HeaderType.SIMPLE) {
      return <SimpleHeader isAuthenticated={this.props.isAuthenticated} downloadCsvAction={generateCsv} csvStatus={csvStatus} />;
    } else {
      return (
        <Header
          isAuthenticated={this.props.isAuthenticated}
          isAdmin={this.props.isAdmin}
          isUser={this.props.isUser}
          isTransshipmentAdmin={this.props.isTransshipmentAdmin}
          isTransshipmentUser={this.props.isTransshipmentUser}
          isTransporterAdmin={this.props.isTransporterAdmin}
          isTransporterUser={this.props.isTransporterUser}
          isManagerAdmin={this.props.isManagerAdmin}
          isManagerUser={this.props.isManagerUser}
          isProducerAdmin={this.props.isProducerAdmin}
          isProducerUser={this.props.isProducerUser}
          isFinalDestinationAdmin={this.props.isFinalDestinationAdmin}
          isFinalDestinationUser={this.props.isFinalDestinationUser}
          currentLocale={this.props.currentLocale}
          ribbonEnv={this.props.ribbonEnv}
          isInProduction={this.props.isInProduction}
          isSwaggerEnabled={this.props.isSwaggerEnabled}
          account={this.props.account}
          location={this.props.location}
          userProducer={this.state.userProducer}
        />
      );
    }
  };

  render() {
    return <div>{this.renderHeaderType()}</div>;
  }
}

const mapDispatchToProps = {
  generateCsv,
  getProducerDetailByUuid,
};

const mapStateToProps = ({ authentication, applicationProfile, locale, userManagement }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  account: authentication.account,
  csvStatus: userManagement.csvStatus,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.USER]),
  isTransporterAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSPORTER_ADMIN]),
  isTransporterUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSPORTER_USER]),
  isTransshipmentUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_USER]),
  isTransshipmentAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN]),
  isManagerAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
  isManagerUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER_USER]),
  isProducerAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN]),
  isProducerUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PRODUCER_USER]),
  isFinalDestinationAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN]),
  isFinalDestinationUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_USER]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  hideHeader: applicationProfile.hideHeader,
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(HeaderBuilder);

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './vehicle-info.scss';
import { AlertModal } from 'app/components/alert-modal/alert-modal';

export const MtrVehicleInfo = () => {
  return (
    <div className="vehicle-info__items">
      <div className="vehicle-info__items--back">
        <Container>
          <div className="confirmation--back">
            <div className="confirmation--back-icon" />
            <span>
              <Translate contentKey={'mtr-signature.back'} />
            </span>
          </div>
        </Container>
      </div>
      <div style={{ marginBottom: '35px' }}>
        <h1>{translate('mtr-transporter.driver.vehicle.title')}</h1>
      </div>
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <div className="vehicle-info__items--component">Insira o modelo do veículo</div>
          <div className="vehicle-info__items--component">Insira a placa do veículo</div>
          <div className="vehicle-info__items--component">Insira o ano do veículo</div>
          <div className="vehicle-info__items--component">Selecione o tipo do veículo</div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 2, offset: 4 }}>
          <div className="vehicle-info__items--component">Insira a capacidade do veículo</div>
        </Col>
        <Col md={{ size: 1}}>
          <div className="vehicle-info__items--component">m³</div>
        </Col>
      </Row>
      <div className="vehicle-info--buttons">
        <button style={{ width: '172px' }} className={'button third'} type={'submit'}>
          <Translate contentKey={'mtr-signature.button-cancel'} />
        </button>
        <button style={{ width: '172px' }} className={'button primary'} type={'button'} onClick={() => {}}>
          <Translate contentKey={'mtr-signature.button-save'} />
        </button>
      </div>


      {/* ==========================================================================
                            MODAIS TRANSPORTADOR/MOTORISTAS
      ==========================================================================  */}

      <AlertModal
        showModal={false}
        buttonOneActionMessage={'mtr-signature.button-back'}
        buttonTwoActionMessage={'mtr-signature.button-agree'}
        hasTwoButtons
        buttonTwoAction={() => {}}
        buttonOneAction={() => {}}
        statusImage="transporter-driver-1.svg"
        alertMessage="modal-transporter-vehicle-save"
      />

      <AlertModal
        showModal={false}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="transporter-vehicle-1.svg"
        alertMessage="modal-transporter-vehicle-agree"
      />
    </div>
  );
};

export default MtrVehicleInfo;

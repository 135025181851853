import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './address-form-component.scss';
import { translate } from 'react-jhipster';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import { validateGenericField, validateObject, validateZipCode } from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { maskZipCode, unmask } from 'app/shared/util/mask-utils';
import { IAddress } from 'app/shared/model/address.model';
import { ICity } from 'app/shared/model/city.model';
import { IState } from 'app/shared/model/state.model';
import { getEntities as getStates } from 'app/entities/state/state.reducer';
import { getEntitiesByStateId as getCitiesByState } from 'app/entities/city/city.reducer';
import DropdownSearch from '../dropdown-search/dropdown-search';
import { Col, Row } from 'reactstrap';
import NextCancelButton from '../next-cancel-button/next-cancel-button';

export interface IAddressFormComponentProps extends StateProps, DispatchProps {
  address: IAddress;
  onNextHandler: (address: IAddress) => void;
  onBack: () => void;
}

export interface IAddressFormComponentState {
  street: string;
  complement: string;
  city: ICity;
  selectedState: IState;
  zipcode: string;
  streetError: ValidationResult;
  complementError: ValidationResult;
  stateError: ValidationResult;
  cityError: ValidationResult;
  zipcodeError: ValidationResult;
  isStateSelected: boolean;
}

export class AddressFormComponent extends React.Component<IAddressFormComponentProps, IAddressFormComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      street: this.props.address.street ? this.props.address.street : null,
      complement: this.props.address.complement ? this.props.address.complement : null,
      selectedState: this.props.address.city ? (this.props.address.city.state ? this.props.address.city.state : null) : null,
      city: this.props.address.city ? this.props.address.city : null,
      zipcode: this.props.address.zipcode ? this.props.address.zipcode : null,
      streetError: ValidationResultInstance,
      complementError: ValidationResultInstance,
      stateError: ValidationResultInstance,
      cityError: ValidationResultInstance,
      zipcodeError: ValidationResultInstance,
      isStateSelected: this.props.address.city ? this.props.address.city.state != null : false,
    };
  }

  async componentDidMount() {
    await this.props.getStates(0, 50, 'name');
    if (this.props.address.city && this.props.address.city.state) {
      await this.props.getCitiesByState(this.props.address.city.state.id);
    }
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateStreet()) {
      hasError = true;
    }
    if (this.onValidateState()) {
      hasError = true;
    }
    if (this.onValidateCity()) {
      hasError = true;
    }
    if (this.onValidateZipCode()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateStreet = () => {
    const { street } = this.state;
    const validate = validateGenericField(street);
    this.setState({
      streetError: validate,
    });
    return validate.hasError;
  };

  onValidateComplement = () => {
    const { complement } = this.state;
    const validate = validateGenericField(complement);
    if (!validate.hasError) {
      this.setState({
        complementError: validate,
      });
    }
    return validate.hasError;
  };

  onValidateState = () => {
    const { selectedState } = this.state;
    const validate = validateObject(selectedState);
    this.setState({
      stateError: validate,
    });
    return validate.hasError;
  };

  onValidateCity = () => {
    const { city } = this.state;
    const validate = validateObject(city);
    this.setState({
      cityError: validate,
    });
    return validate.hasError;
  };

  onValidateZipCode = () => {
    const { zipcode } = this.state;
    const validate = validateZipCode(zipcode);
    this.setState({
      zipcodeError: validate,
    });
    return validate.hasError;
  };

  mapStatesToSearch = () => {
    const { states } = this.props;
    const objects = states.map(state => {
      return { name: state.name, id: state.id };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  mapCitiesToSearch = () => {
    const { cities } = this.props;
    const { isStateSelected } = this.state;
    if (!isStateSelected) {
      return [];
    }
    const objects = cities.map(city => {
      return { name: city.name, id: city.id };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  mapSelectedStateToSelect = () => {
    const { selectedState } = this.state;

    return selectedState
      ? {
        name: selectedState.name,
        id: selectedState.id,
      }
      : null;
  };

  mapSelectedCityToSelect = () => {
    const { city } = this.state;

    return city
      ? {
        name: city.name,
        id: city.id,
      }
      : null;
  };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }
    const { address } = this.props;
    const { street, complement, city, zipcode, selectedState } = this.state;
    const addressUpdated: IAddress = {
      ...address,
      street,
      complement,
      city: {
        ...city,
        state: {
          id: selectedState.id,
          name: selectedState.name,
        },
      },
      zipcode: unmask(zipcode),
    };

    this.props.onNextHandler(addressUpdated);
  };

  search = () => { };

  onClickState = (item: any) => {
    const { states } = this.props;
    this.setState(
      {
        selectedState: states.filter(it => it.id === item.id)[0],
        city: null,
        cityError: ValidationResultInstance,
        isStateSelected: true,
      },
      () => {
        this.props.getCitiesByState(this.state.selectedState.id);
        this.onValidateState();
      }
    );
  };

  onClickCity = (item: any) => {
    const { cities } = this.props;
    this.setState(
      {
        city: cities.filter(it => it.id === item.id)[0],
      },
      () => this.onValidateCity()
    );
  };

  setCityMissingStateError = () => {
    this.setState({
      cityError: {
        hasError: true,
        errorMessage: 'validation.city.state',
      },
    });
  };

  render() {
    const { street, streetError, complement, complementError, cityError, stateError, zipcode, zipcodeError } = this.state;
    return (
      <div className="address__items">
        <h1>{translate('address.title')}</h1>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  street: text,
                })
              }
              id="street"
              style={{ marginTop: '20px' }}
              value={street}
              label={translate('address.form.street')}
              placeholder={translate('address.form.street')}
              error={streetError.hasError}
              errorText={translateErrorMessage(streetError.errorMessage)}
              onBlur={this.onValidateStreet}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  complement: text,
                })
              }
              id="complement"
              style={{ marginTop: '20px' }}
              value={complement}
              label={translate('address.form.complement')}
              placeholder={translate('address.form.complement')}
              error={complementError.hasError}
              errorText={translateErrorMessage(complementError.errorMessage)}
              onBlur={this.onValidateComplement}
            />
            <div style={{ marginTop: '20px' }}>
              <DropdownSearch
                showSearchField
                data={this.mapStatesToSearch()}
                getData={this.search}
                onClickItem={this.onClickState}
                title={translate('address.form.state')}
                style={{ backgroundColor: '#f6f6f6' }}
                notAlphabeticalOrder
                showInsiderSelect
                filterLocalData
                error={stateError.hasError}
                selectedData={this.mapSelectedStateToSelect()}
                errorMessage={translateErrorMessage(stateError.errorMessage)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, offset: 3 }}>
            <div style={{ marginTop: '20px' }}>
              <DropdownSearch
                showSearchField
                data={this.mapCitiesToSearch()}
                getData={this.search}
                onClickItem={this.onClickCity}
                title={translate('address.form.city')}
                style={{ backgroundColor: '#f6f6f6' }}
                notAlphabeticalOrder
                showInsiderSelect
                filterLocalData
                error={cityError.hasError}
                selectedData={this.mapSelectedCityToSelect()}
                disableOpen={this.state.selectedState == null}
                errorMessage={translateErrorMessage(cityError.errorMessage)}
                onOpen={this.setCityMissingStateError}
              />
            </div>
          </Col>
          <Col md="3">
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  zipcode: text,
                })
              }
              id="zipcode"
              onMask={maskZipCode}
              style={{ marginTop: '20px' }}
              value={zipcode}
              label={translate('address.form.zipcode')}
              placeholder={translate('address.form.zipcode')}
              error={zipcodeError.hasError}
              errorText={translateErrorMessage(zipcodeError.errorMessage)}
              onBlur={this.onValidateZipCode}
            />
          </Col>
        </Row>
        <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCitiesByState,
  getStates,
};

const mapStateToProps = (root: IRootState) => ({
  states: root.state.entities,
  cities: root.city.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressFormComponent);

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transshipment-register-address.scss';
import { UserTransshipment } from 'app/shared/model/user-transshipment.model';
import AddressFormComponent from 'app/components/address-form-component/address-form-component';
import { IAddress } from 'app/shared/model/address.model';

export interface ITransshipmentRegisterAddressProps extends StateProps, DispatchProps {
  userTransshipment: UserTransshipment;
  onNext: (userTransshipment: UserTransshipment) => void;
  onBack: () => void;
}

export interface ITransshipmentRegisterAddressState {
  address: IAddress;
}

export class TransshipmentRegisterAddress extends React.Component<ITransshipmentRegisterAddressProps, ITransshipmentRegisterAddressState> {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.userTransshipment.transshipment
        ? this.props.userTransshipment.transshipment.address
          ? this.props.userTransshipment.transshipment.address
          : {}
        : {},
    };
  }

  onNextHandle = (address: IAddress) => {
    const { userTransshipment } = { ...this.props };
    userTransshipment.transshipment = {
      ...userTransshipment.transshipment,
      address: address,
    };

    this.props.onNext(userTransshipment);
  };

  render() {
    const { address } = this.state;
    return (
      <div>
        <AddressFormComponent onBack={this.props.onBack} onNextHandler={this.onNextHandle} address={address} />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentRegisterAddress);

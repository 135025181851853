import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { IIntegrationQueue } from './../../../shared/model/integration-queue.model';

export const ACTION_TYPES = {
  GET_INTEGRATION_QUEUES: 'integrationQueue/GET_INTEGRATION_QUEUES',
  GET_INTEGRATION_QUEUES_ERROR: 'integrationQueue/GET_INTEGRATION_QUEUES_ERROR',
  RESET_STATUS: 'mtr/RESET_STATUS',
};

const initialState = {
  errorMessage: null,
  loading: false,
  totalItems: 0,
  integrationQueues: [],
  integrationQueuesError: [],
};

export type IntegrationQueueState = Readonly<typeof initialState>;

export default (state: IntegrationQueueState = initialState, action): IntegrationQueueState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_INTEGRATION_QUEUES):
    case REQUEST(ACTION_TYPES.GET_INTEGRATION_QUEUES_ERROR):
      return {
        ...state,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.GET_INTEGRATION_QUEUES):
    case FAILURE(ACTION_TYPES.GET_INTEGRATION_QUEUES_ERROR):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_INTEGRATION_QUEUES):
      return {
        ...state,
        integrationQueues: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.GET_INTEGRATION_QUEUES_ERROR):
      return {
        ...state,
        integrationQueuesError: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case ACTION_TYPES.RESET_STATUS:
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/integration-queues';

// Actions

export const getIntegrationQueues = (page, size, sort) => {
  const requestUrl = `${apiUrl}`;
  return {
    type: ACTION_TYPES.GET_INTEGRATION_QUEUES,
    payload: axios.get<IIntegrationQueue>(
      `${requestUrl}?sort=${sort ?? ''}&size=${size ?? ''}&page=${page ?? ''}cacheBuster=${new Date().getTime()}`
    ),
  };
};

export const getIntegrationQueuesError = (page, size, sort) => {
    const requestUrl = `${apiUrl}`;
    return {
      type: ACTION_TYPES.GET_INTEGRATION_QUEUES_ERROR,
      payload: axios.get<IIntegrationQueue>(
        `${requestUrl}/error?sort=${sort ?? ''}&size=${size ?? ''}&page=${page ?? ''}cacheBuster=${new Date().getTime()}`
      ),
    };
  };

export const resetStatus = () => ({
  type: ACTION_TYPES.RESET_STATUS,
});

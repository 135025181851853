import React from 'react';
import moment from 'moment';

interface MtrTagProps {
    tag: string;
    date: string;
};

function lerp(progress, color1, color2, color3) {
    let red, green, blue;
    if (progress < 0.5) {
        red = (Math.round(color1[0] + ((color2[0] - color1[0]) * progress)));
        green = (Math.round(color1[1] + ((color2[1] - color1[1]) * progress)));
        blue = (Math.round(color1[2] + ((color2[2] - color1[2]) * progress)));
    } else {
        red = (Math.round(color2[0] + ((color3[0] - color2[0]) * progress)));
        green = (Math.round(color2[1] + ((color3[1] - color2[1]) * progress)));
        blue = (Math.round(color2[2] + ((color3[2] - color2[2]) * progress)));
    }
    return `rgb(${red}, ${green}, ${blue})`;
}

const CustomMtrTag = (props: MtrTagProps) => {
    const displayDate = moment(props.date, 'DD/MM').format('DD/MMM').toUpperCase();
    const month = parseInt(props.date.split('/')[1]);
    const mixColor = lerp((month - 1) / 11, [0x30, 0xAB, 0x64], [0x30, 0x9F, 0xAB], [0x3A, 0x50, 0x5A]);
    return (
        <div className="container-days--month" style={{ borderColor: mixColor }}>
            <div className="container-days--month-title" style={{ background: mixColor }}>{props.tag}</div>
            <div className="container-days--month-subtitle" style={{ color: mixColor }}>{displayDate}</div>
        </div>
    );
}

export default CustomMtrTag;

import './generic-card-choice.scss';
import React from 'react';
import {Col} from 'reactstrap';

export interface IGenericCardProps {
  index: any;
  title: string;
  text: string;
  selected: boolean;
  hideText: boolean;
  onSelect: (index: any) => void;
}

export class GenericCardChoice extends React.Component<IGenericCardProps> {
  constructor(props) {
    super(props);
  }

  renderCheckBox = () => {
    const { selected } = this.props;

    if (!selected) {
      return <div className="check-radio-blank" />;
    } else {
      return <div className="check-radio" />;
    }
  };

  render() {
    const { hideText, text, index, selected } = this.props;
    return (
      <Col>
        <div className={`generic-card-choices__items ${selected ? 'selected' : ''}`} onClick={() => this.props.onSelect(index)}>
          <div className="generic-card-choices__items--checkbox">{this.renderCheckBox()}</div>
          <div className="generic-card-choices__items--text">
            <div className="generic-card-choices__items--title">
              <span>{this.props.title}</span>
            </div>
            <div className="generic-card-choices__items--subtitle">{!hideText && <span>{text}</span>}</div>
          </div>
        </div>
      </Col>
    );
  }
}

export default GenericCardChoice;

import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './final-destination-register-person.scss';
import { translate } from 'react-jhipster';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import { FinalDestinationCategory, IFinalDestination } from 'app/shared/model/final-destination.model';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { Col, Row } from 'reactstrap';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import {
  validateCNPJ,
  validateCPF,
  validateGenericField,
  validateObject,
  validatePhoneNumber
} from 'app/modules/validation/validation-constants';
import InlineSelect from 'app/components/inline-select/inline-select';
import { maskCPF, maskNumber, maskPhone, unmask } from 'app/shared/util/mask-utils';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface IFinalDestinationRegisterPersonProps extends StateProps, DispatchProps {
  userFinalDestination: IUserFinalDestination;
  onNext: (userFinalDestination: IUserFinalDestination) => void;
  onBack: () => void;
}

const FinalDestinationRegisterPerson = (props: IFinalDestinationRegisterPersonProps) => {

  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  // const [municipalRegistration, setMunicipalRegistration] = useState('');
  const [phone, setPhone] = useState('');
  const [finalDestinationCategory, setFinalDestinationCategory] = useState(null as FinalDestinationCategory);

  const [cpfError, setCpfError] = useState<ValidationResult | undefined>(ValidationResultInstance);
  const [nameError, setNameError] = useState<ValidationResult | undefined>(ValidationResultInstance);
  // const [municipalRegistrationError, setMunicipalRegistrationError] = useState<ValidationResult | undefined>(ValidationResultInstance)
  const [phoneError, setPhoneError] = useState<ValidationResult | undefined>(ValidationResultInstance);
  const [finalDestinationCategoryError, setFinalDestinationCategoryError] = useState<ValidationResult | undefined>(ValidationResultInstance);

  useEffect(() => {
    const { finalDestination } = props.userFinalDestination;

    setCpf(finalDestination?.naturalPerson?.cpf ?? null);
    setName(finalDestination?.naturalPerson?.name ?? null);
    setPhone(finalDestination?.naturalPerson?.phone ?? null);
    // setMunicipalRegistration(finalDestination?.naturalPerson?.municipalRegistration ?? null);
    setFinalDestinationCategory(finalDestination?.finalDestinationCategory ?? null);

  }, [])

  const onValidateAll = () => {
    let hasError = false;
    if (onValidateCpf()) {
      hasError = true;
    }
    if (onValidateName()) {
      hasError = true;
    }
    if (onValidatePhone()) {
      hasError = true;
    }
    // if (onValidateMunicipalRegistration()) {
    //   hasError = true;
    // }
    if (onValidateCategory()) {
      hasError = true;
    }
    return hasError;
  };

  const onValidateCpf = () => {
    const validate = validateCPF(cpf);
    setCpfError(validate);
    return validate.hasError;
  };

  const onValidateName = () => {
    const validate = validateGenericField(name);
    setNameError(validate);
    return validate.hasError;
  };

  const onValidatePhone = () => {
    const validate = validatePhoneNumber(phone);
    setPhoneError(validate);
    return validate.hasError;
  };

  // const onValidateMunicipalRegistration = () => {
  //   const validate = validateGenericField(municipalRegistration);
  //   setMunicipalRegistrationError(validate);
  //   return validate.hasError;
  // };

  const onValidateCategory = (item) => {
    const validate = validateObject(item ?? finalDestinationCategory);
    console.log({ validate, finalDestinationCategory })
    setFinalDestinationCategoryError(validate);
    return validate.hasError;
  };

  const mapFinalDestinationCategoryToOptions = () => {
    const optionsList = [];
    Object.keys(FinalDestinationCategory).forEach((item, index) => {
      optionsList.push({ label: translate(`enums.finalDestinationCategory.${item}`), value: item });
    });
    return optionsList;
  };

  const mapSelectedCategoryToSelect = () => {
    return finalDestinationCategory
      ? { label: translate(`enums.finalDestinationCategory.${finalDestinationCategory}`), value: finalDestinationCategory }
      : null;
  };

  const onSelectFinalDestinationCategory = (item: any) => {
    setFinalDestinationCategory(item);
    onValidateCategory(item);
  };
  const onNext = () => {
    if (onValidateAll()) {
      return;
    }
    const userFinalDestination = { ...props.userFinalDestination };
    const finalDestination: IFinalDestination = {
      ...userFinalDestination.finalDestination,
      naturalPerson: {
        cpf: unmask(cpf),
        name,
        phone: unmask(phone),
        // municipalRegistration
      },
      finalDestinationCategory
    };

    userFinalDestination.finalDestination = finalDestination;
    props.onNext(userFinalDestination);
  };

  return (
    <div>
      <h1>{translate('finalDestinationRegister.company.register-title')}</h1>
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <CustomTextField
            onChange={(text: string) =>
              setCpf(text)
            }
            id="cpf"
            style={{ marginTop: '20px' }}
            value={cpf}
            onMask={maskCPF}
            // label={translate('producerRegister.company.holding.form.cnpj')}
            // placeholder={translate('producerRegister.company.holding.form.cnpj')}
            label={translate('finalDestinationRegister.physicalPerson.form.cpf')}
            placeholder={translate('finalDestinationRegister.physicalPerson.form.cpf')}
            error={cpfError.hasError}
            errorText={translateErrorMessage(cpfError.errorMessage)}
            onBlur={onValidateCpf}
          />
          <CustomTextField
            onChange={(text: string) =>
              setName(text)
            }
            id="companyName"
            style={{ marginTop: '20px' }}
            value={name}
            // label={translate('producerRegister.company.subsidiary.form.companyName')}
            // placeholder={translate('producerRegister.company.subsidiary.form.companyName')}
            label={translate('finalDestinationRegister.physicalPerson.form.name')}
            placeholder={translate('finalDestinationRegister.physicalPerson.form.name')}
            error={nameError.hasError}
            errorText={translateErrorMessage(nameError.errorMessage)}
            onBlur={onValidateName}
          />
          {/* <CustomTextField
            onChange={(text: string) =>
              setMunicipalRegistration(text)
            }
            id="municipalRegistration"
            onMask={maskNumber}
            style={{ marginTop: '20px' }}
            value={municipalRegistration}
            label={translate('producerRegister.company.holding.form.municipalRegistration')}
            placeholder={translate('producerRegister.company.holding.form.municipalRegistration')}
            error={municipalRegistrationError.hasError}
            errorText={translateErrorMessage(municipalRegistrationError.errorMessage)}
          /> */}
          <CustomTextField
            onChange={(text: string) =>
              setPhone(text)
            }
            onMask={maskPhone}
            id="phone"
            style={{ marginTop: '20px' }}
            value={phone}
            label={translate('producerRegister.company.holding.form.phone')}
            placeholder={translate('producerRegister.company.holding.form.phone')}
            error={phoneError.hasError}
            errorText={translateErrorMessage(phoneError.errorMessage)}
            onBlur={onValidatePhone}
          />
          <InlineSelect
            placeholder={translate('finalDestinationRegister.company.form.selectCategory')}
            error={finalDestinationCategoryError.hasError}
            errorMessage={translateErrorMessage(finalDestinationCategoryError.errorMessage)}
            options={mapFinalDestinationCategoryToOptions()}
            onChange={onSelectFinalDestinationCategory}
            startIndex={0}
            selectedOption={mapSelectedCategoryToSelect()}
          />
          <NextCancelButton onCancelClick={props.onBack} onNextClick={onNext} />
        </Col>
      </Row>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationRegisterPerson);

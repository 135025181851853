import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {getEntity} from './user-transporter.reducer';

export interface IUserTransporterDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class UserTransporterDetail extends React.Component<IUserTransporterDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { userTransporterEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.userTransporter.detail.title">UserTransporter</Translate> [<b>{userTransporterEntity.id}</b>
            ]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="registrationNumber">
                <Translate contentKey="resitrackApp.userTransporter.registrationNumber">Registration Number</Translate>
              </span>
            </dt>
            <dd>{userTransporterEntity.registrationNumber}</dd>
            <dt>
              <span id="classCouncil">
                <Translate contentKey="resitrackApp.userTransporter.classCouncil">Class Council</Translate>
              </span>
            </dt>
            <dd>{userTransporterEntity.classCouncil}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userTransporter.user">User</Translate>
            </dt>
            <dd>{userTransporterEntity.user ? userTransporterEntity.user.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userTransporter.transporter">Transporter</Translate>
            </dt>
            <dd>{userTransporterEntity.transporter ? userTransporterEntity.transporter.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/user-transporter" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/user-transporter/${userTransporterEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ userTransporter }: IRootState) => ({
  userTransporterEntity: userTransporter.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserTransporterDetail);

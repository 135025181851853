import Loading from 'app/components/loading/loading';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './user-profile-data.scss';
import {maskCnpj, maskCPF, maskPhone, maskZipCode} from "app/shared/util/mask-utils";

export interface IUserProfileDataProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IUserProfileDataState { }

export class UserProfileData extends React.Component<IUserProfileDataProps, IUserProfileDataState> {
  constructor(props: Readonly<IUserProfileDataProps>) {
    super(props);
    this.state = {};
  }

  async componentDidMount() { }

  componentWillUnmount() { }

  componentWillReceiveProps(newProps) { }

  renderInfo = (title: string, info: string) => (
    <div className="user-profile-menu__items--component">
      <div className="user-profile-menu__items--title-second">{title}</div>
      <div className="user-profile-menu__items--text">{info}</div>
    </div>
  );

  render() {
    const { loading, account, hasAdminAuthorities } = this.props;
    if (loading) {
      return <Loading />;
    }
    return (
      <div>
        <div className="user-profile-menu__items--title">
          <Translate contentKey={'profile.personal-data.personal-data'} />
        </div>
        {this.renderInfo(translate('global.account.info.name'), account.name)}
        {this.renderInfo(translate('global.account.info.companyName'), account.companyName)}
        {this.renderInfo(translate('global.account.info.fantasyName'), account.fantasyName)}
        {this.renderInfo(translate('global.account.info.cnpj'), account.cnpj != null ? maskCnpj(account.cnpj)  : account.cpf != null ? maskCPF(account.cpf) : '' )}
        {this.renderInfo(translate('global.account.info.phone'), account.phoneProfile ? maskPhone(account.phoneProfile) : '')}
        {this.renderInfo(translate('global.account.info.address'), account.addressDescription)}
        {this.renderInfo(translate('global.account.info.profileType'), translate(`enums.profileType.${hasAdminAuthorities ? 'ADMIN' : 'USER'}`))}
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  loading: root.authentication.loading,
  hasAdminAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.PRODUCER_ADMIN,
    AUTHORITIES.TRANSPORTER_ADMIN,
    AUTHORITIES.FINAL_DESTINATION_ADMIN,
    AUTHORITIES.MANAGER_ADMIN,
  ]),
  hasManagerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
});

const mapDispatchToProps = {
  getSession,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileData);

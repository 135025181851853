import React, { useMemo, useState } from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './mtr-signature.scss';
import AttachmentComponent from 'app/components/attachment-component/attachmentComponent';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Steps } from 'app/modules/account/register/full-register/steps/steps';
import { AlertModal } from 'app/components/alert-modal/alert-modal';

export const MtrSignature = ({
  licenseDocument,
  licenseNumber,
  expireDate,
  issuingBody,
  licenseDocumentError,
  licenseNumberError,
  environmentalLicenseTypeError,
  expireDateError,
  issuingBodyError,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const steps = useMemo(
    () => [
      /* ==========================================================================
      Step Assinatura Gerador/ os componentes servem para gerador e transportador
      ========================================================================== */
      {
        title: 'ASSINATURA GERADOR',
        render: () => (
          <div className="mtr-signature__steps">
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <div style={{ marginBottom: '30px' }}>
                  <h1 style={{ fontWeight: 600 }}>
                    <Translate contentKey={'mtr-signature.title'} />
                  </h1>
                </div>
                <AttachmentComponent
                  attachment={licenseDocument}
                  error={false}
                  errorMessage={''}
                  attachmentTitle={translate('environmentalLicense.form.sign')}
                  onFileAttached={() => {}}
                />
                <div className="mtr-signature__steps--info">
                  <div>Lorem Ipsum Ipsum</div>
                  <div style={{ fontSize: '0.85rem' }}>Lorem Ipsum</div>
                </div>
              </Col>
            </Row>
            <Row>
            <Col md={{ size: 6, offset: 3 }}>
                <DatePickerComponent
                  dateFormat={APP_LOCAL_DATE_FORMAT}
                  onDateChange={value => {
                    this.setState({
                      expireDate: value,
                    });
                  }}
                  dateSelected={expireDate}
                  placeholder={translate('environmentalLicense.form.emission-mtr')}
                  error={false}
                  errorMessage=""
                />
              </Col>
            </Row>
            <div className="mtr-signature--buttons">
              <button style={{ width: '172px' }} className={'button third'} type={'submit'}>
                <Translate contentKey={'mtr-signature.button-cancel'} />
              </button>
              <button style={{ width: '172px' }} className={'button primary'} type={'button'} onClick={() => {}}>
                <Translate contentKey={'mtr-signature.button-sign'} />
              </button>
            </div>
            {/* <button onClick={() => setCurrentIndex(currentIndex + 1)}>next</button> */}
          </div>
        ),
      },
      /* ==========================================================================
      Step Assinatura Transportador
      ========================================================================== */
      {
        title: 'ASSINATURA TRANSPORTADOR',
        render: () => <div>{/* <button onClick={() => setCurrentIndex(currentIndex + 1)}>next</button> */}</div>,
      },
      /* ==========================================================================
      Step Assinatura Destino Final
      ========================================================================== */
      {
        title: 'ASSINATURA DESTINO FINAL',
        render: () => <div>{/* <button onClick={() => setCurrentIndex(currentIndex + 1)}>next</button> */}</div>,
      },
    ],
    [currentIndex]
  );

  return (
    <div className="mtr-signature__items">
      <div className="container__confirmation--back">
        <Container>
          <div className="confirmation--back">
            <div className="confirmation--back-icon" />
            <span>
              <Translate contentKey={'mtr-signature.back'} />
            </span>
          </div>
        </Container>
      </div>
      <Steps steps={steps} currentIndex={currentIndex} />

      {/* ==========================================================================
                    Modal de cancelamento do Gerador e Transportador
      ==========================================================================  */}

      <AlertModal
        showModal={false}
        buttonOneActionMessage={'mtr-signature.button-cancel'}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasTwoButtons
        buttonTwoAction={() => {}}
        buttonOneAction={() => {}}
        statusImage="cancel-icon.svg"
        alertMessage="modal-gerador-cancel"
      />

      <AlertModal
        showModal={false}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="cancel-icon2.svg"
        alertMessage="modal-gerador-cancel-ok"
      />
    </div>
  );
};

export default MtrSignature;

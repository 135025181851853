import { IFinalDestination } from './final-destination.model';
import { ITransporter } from './transporter.model';
import { IProducer } from './producer.model';
import { Transshipment } from 'app/shared/model/transshipment.model';

export const enum FavoriteType {
  PRODUCER = 'PRODUCER',
  TRANSSHIPMENT = 'TRANSSHIPMENT',
  FINAL_DESTINATION = 'FINAL_DESTINATION',
  TRANSPORTER = 'TRANSPORTER',
}

export const enum FavoriteSourceType {
  PRODUCER = 'PRODUCER',
  TRANSSHIPMENT = 'TRANSSHIPMENT',
  FINAL_DESTINATION = 'FINAL_DESTINATION',
  TRANSPORTER = 'TRANSPORTER',
  MANAGER = 'MANAGER',
}

export interface IFavorite {
  id?: number;
  sourceId?: number;
  sourceType?: FavoriteSourceType;
  favoriteType?: FavoriteType;
  finalDestination?: IFinalDestination;
  transporter?: ITransporter;
  transshipment?: Transshipment;
  producer?: IProducer;
}

export const defaultValue: Readonly<IFavorite> = {};

import { IProducer } from 'app/shared/model/producer.model';
import { IManager } from 'app/shared/model/manager.model';

export const enum InvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = ' ACCEPTED',
  REFUSED = ' REFUSED',
}

export interface IManagerProducerInvitation {
  id?: number;
  invitationStatus?: InvitationStatus;
  producer?: IProducer;
  manager?: IManager;
}

export const defaultValue: Readonly<IManagerProducerInvitation> = {};

import { IUser } from 'app/shared/model/user.model';

export enum ClassCouncil {
  CREA = 'CREA',
  CRQ = 'CRQ',
  CRBIO = 'CRBIO',
  CFT = 'CFT',
  CAU = 'CAU',
  NÃO_SE_APLICA = 'Não se Aplica',
}

export interface IUserGeneric {
  id?: number;
  registrationNumber?: string;
  classCouncil?: ClassCouncil;
  user?: IUser;
  fantasyName?: string;
}

export const defaultValue: Readonly<IUserGeneric> = {};

import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { UnitApi } from '../api/unit';
import { Pageable, PageableResponse } from '../shared/model/pageable';
import { checkApiStatus } from '../shared/util/api-utils';
import { HttpStatusNumber } from '../shared/model/enum/HttpStatusNumber';

import { IUnit } from '../shared/model/unit';
import { translate } from 'react-jhipster';

export class UnitService {
  static getAllUnits = async (pageable: Pageable, searchString?: string): Promise<PageableResponse<IUnit>> => {
    try {
      const result: AxiosResponse<PageableResponse<IUnit>> = await UnitApi.getAllUnits(pageable, searchString);
      if (!checkApiStatus(result.data != null, result.status)) {
        toast.error('Erro ao listar unidades!');
        return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao listar unidades!');
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static getUnit = async (unitId: number): Promise<IUnit> => {
    try {
      const result: AxiosResponse<IUnit> = await UnitApi.getUnit(unitId);
      if (!checkApiStatus(result.data != null, result.status)) {
        toast.error('Erro ao listar unidades!');
        return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao listar unidades!');
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static createUnit = async (unit: IUnit): Promise<IUnit> => {
    try {
      const result: AxiosResponse<IUnit> = await UnitApi.createUnit(unit);
      if (!checkApiStatus(result.data != null, result.status, HttpStatusNumber.CREATED)) {
        toast.error('Erro ao cadastrar unidade!');
        return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao cadastrar unidade!');
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static updateUnit = async (unit: IUnit): Promise<IUnit> => {
    try {
      const result: AxiosResponse<IUnit> = await UnitApi.updateUnit(unit);
      if (!checkApiStatus(result.data != null, result.status, HttpStatusNumber.OK)) {
        toast.error('Erro ao atualizar unidade!');
        return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao atualizar unidade!');
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static deleteUnit = async (unitId: number): Promise<IUnit> => {
    try {
      const result: AxiosResponse<IUnit> = await UnitApi.deleteUnit(unitId);
      if (!checkApiStatus(result.data != null, result.status)) {
        toast.error(translate('unitTranslation.deleteError'));
        return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      toast.error(translate('unitTranslation.deleteError'));
      return Promise.reject(error.response as AxiosResponse);
    }
  };
}

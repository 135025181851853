import { defaultValue, IDriver } from 'app/shared/model/driver.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import StringUtils from 'app/shared/util/string-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';

export const ACTION_TYPES = {
  FETCH_DRIVER_LIST: 'driver/FETCH_DRIVER_LIST',
  FETCH_DRIVER: 'driver/FETCH_DRIVER',
  SEARCH_DRIVERS: 'driver/SEARCH_DRIVERS',
  CREATE_DRIVER: 'driver/CREATE_DRIVER',
  UPDATE_DRIVER: 'driver/UPDATE_DRIVER',
  DELETE_DRIVER: 'driver/DELETE_DRIVER',
  RESET: 'driver/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDriver>,
  drivers: [] as ReadonlyArray<IDriver>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  deleteDriverStatus: HttpRequestStatus.NOOP,
};

export type DriverState = Readonly<typeof initialState>;

// Reducer

export default (state: DriverState = initialState, action): DriverState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DRIVER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DRIVER):
    case REQUEST(ACTION_TYPES.SEARCH_DRIVERS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_DRIVER):
    case REQUEST(ACTION_TYPES.UPDATE_DRIVER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_DRIVER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        deleteDriverStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_DRIVER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DRIVER):
    case FAILURE(ACTION_TYPES.CREATE_DRIVER):
    case FAILURE(ACTION_TYPES.SEARCH_DRIVERS):
    case FAILURE(ACTION_TYPES.UPDATE_DRIVER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_DRIVER):
      return {
        ...state,
        deleteDriverStatus: HttpRequestStatus.ERROR,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DRIVER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_DRIVERS):
      return {
        ...state,
        loading: false,
        drivers: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DRIVER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DRIVER):
    case SUCCESS(ACTION_TYPES.UPDATE_DRIVER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DRIVER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        deleteDriverStatus: HttpRequestStatus.SUCCESS,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/drivers';

// Actions

export const getEntities: ICrudGetAllAction<IDriver> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DRIVER_LIST,
  payload: axios.get<IDriver>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const searchDrivers: ICrudSearchActionCustom<IDriver> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}/transporter/${search.transporterId}${params}`;
  return {
    type: ACTION_TYPES.SEARCH_DRIVERS,
    payload: axios.get<IDriver>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IDriver> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DRIVER,
    payload: axios.get<IDriver>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IDriver> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DRIVER,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDriver> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DRIVER,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDriver> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DRIVER,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

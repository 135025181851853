// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../content/images/icons/down-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".select {\n  position: relative;\n}\n\n.select__title {\n  height: 45px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border: solid 1px #e8ebec;\n  border-radius: 6px;\n  margin-top: 20px;\n  padding-left: 15px;\n  height: 56px;\n  background-color: #f6f6f6;\n  cursor: pointer;\n  justify-content: space-between;\n  overflow: hidden;\n}\n.select__title[data-dropdown-open=true] {\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.select__title__text {\n  font-family: Catamaran;\n  font-size: 1.125rem;\n  font-weight: 300;\n  color: #9199a1;\n  letter-spacing: 0.75px;\n  opacity: 0.9;\n  line-height: 16px;\n}\n\n.select__title__arrow-icn {\n  width: 15px;\n  height: 15px;\n  -o-object-fit: contain;\n     object-fit: contain;\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 35px;\n}\n\n.select__options {\n  border-bottom-left-radius: 6px;\n  border-bottom-right-radius: 6px;\n  border-bottom: solid 1px #e1e2e6;\n  border-left: solid 1px #e1e2e6;\n  border-right: solid 1px #e1e2e6;\n  line-height: 14px;\n  cursor: pointer;\n  overflow: hidden;\n  max-height: 200px;\n  overflow-y: auto;\n  position: absolute;\n  z-index: 100;\n  background-color: white;\n  width: 100%;\n}\n\na.select__option:first-child .select__option__label {\n  border: none;\n}\n\n.select__option__label {\n  font-family: \"Catamaran\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 1.43;\n  color: #1a3c47;\n  display: flex;\n  flex-direction: column;\n  border-top: solid 1px #ebebeb;\n  padding: 10px 22px 10px 22px;\n}\n\n.select__option__label:hover {\n  background-color: #fafafa;\n}\n\n.inline__error {\n  margin-left: 20px;\n  font-family: Catamaran !important;\n  font-size: 13px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: 0.54px;\n  color: #f44336;\n}", ""]);
// Exports
module.exports = exports;

import React, { useEffect, useRef, useState } from 'react';
import './producer-menu-dropdonw.scss';
import { Translate, translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Item } from '../../../../components/account-float-menu-item/account-float-menu-item';
import CustomMenuItem from '../common/custom-menu-item/custom-menu-item';

export const MenuDropDown = props => {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef();
    const { currentUrl, profile } = props;

    useEffect(() => {
        function handleMouseClickOutside(evt: MouseEvent) {
            // @ts-ignore
            if (dropdownRef && dropdownRef.current && !dropdownRef.current.contains(evt.target) && open) {
                setOpen(false);
            }
        }
        window.addEventListener('click', handleMouseClickOutside);

        return () => window.removeEventListener('click', handleMouseClickOutside);
    }, [open]);
    const checkSubcategorySelected = (path: string) => {
        return currentUrl.indexOf(path) != -1;
    };

    function closeMenu() {
        setOpen(false);
    }

    return (
        <>
            <div className='custom-menu__container' style={{ position: 'relative', cursor: 'pointer' }} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                <CustomMenuItem id={`${profile}-mtr`} to={`/${profile}/mtr`} active={checkSubcategorySelected("/mtr")}>
                    <Translate contentKey={'header.common.mtr'} />
                </CustomMenuItem>
                {open && (
                    <div className="float-menu">
                        <ul >
                            <Item
                                show={true}
                                title={translate('header.common.mtrDropdown')}
                                to={`/${profile}/mtr`}
                                style={{ display: 'block' }}
                            />
                            <Item
                                show={true}
                                title={translate('header.common.mtrModel')}
                                to={`/${profile}/mtr-model`}
                                style={{ display: 'block' }}
                            />
                        </ul>
                    </div>
                )}
            </div>
        </>
    )
}




import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import SimpleFileComponent from 'app/components/simple-file-component/simple-file-component';
import { getEntity as getAuthorization, reset } from '../generic-authorization.reducer';
import moment from 'moment';

export interface IAuthorizationDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export interface IAuthorizationDetailsState { }

export class AuthorizationDetails extends React.Component<IAuthorizationDetailsProps, IAuthorizationDetailsState> {
  constructor(props: Readonly<IAuthorizationDetailsProps>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getAuthorization(id);
    }
  }

  componentWillReceiveProps(newProps) { }

  handleGoBack = () => {
    this.props.history.replace(`${this.props.match.url.replace('/details', '')}`);
  };

  onEditAuthorization = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url.replace('/details', '')}/edit`,
      state: { id: id },
    });
  };

  renderComponent = (title: string, info: string) => (
    <div className="list__items--component">
      <div className="list__items--title">{title}</div>
      <div className="list__items--text">{info}</div>
    </div>
  );

  render() {
    const { authorization, fetchAuthorizationStatus } = this.props;
    if (checkStatusOnGoing([fetchAuthorizationStatus])) {
      return <Loading />;
    }
    return (
      <div className="list__items">
        <div className="list__items--back">
          <Container>
            <Row>
              <Col md="10">
                <div className="confirmation--back" onClick={this.handleGoBack}>
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              <Col md="2">
                <div className="list__items--edit" onClick={() => this.onEditAuthorization(authorization.id)}>
                  <Translate contentKey={'mtr-signature.edit'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <h1 style={{ fontWeight: 550 }}>{authorization.authorizationDocument ? authorization.authorizationDocument.fileName : ''}</h1>
        </div>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second"></div>
            {this.renderComponent(
              translate('authorization.detail.specificResidue'),
              authorization.sourceResidue ? authorization.sourceResidue.specificResidueDescription : ''
            )}
            {this.renderComponent(
              translate('authorization.detail.amount'),
              authorization.amount && authorization.measurementType
                ? `${authorization.amount} ${translate(`enums.measurementType.${authorization.measurementType}`)}`
                : ''
            )}
            {this.renderComponent(
              translate('authorization.detail.amountReceipt'),
              authorization.amountReceipt && authorization.measurementType
                ? `${authorization.amountReceipt} ${translate(`enums.measurementType.${authorization.measurementType}`)}`
                : ''
            )}
            {this.renderComponent(
              translate('authorization.detail.expirationDate'),
              authorization.expirationDate ? moment(authorization.expirationDate).format('DD/MM/YYYY') : ''
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('authorization.detail.docTitle')}</div>
            <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
              <div className="list__items--title">{translate('authorization.detail.authorizationDocument')}</div>
              <SimpleFileComponent file={authorization.authorizationDocument ? authorization.authorizationDocument : null} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  authorization: root.genericAuthorization.authorization,
  fetchAuthorizationStatus: root.genericAuthorization.fetchAuthorizationStatus,
});

const mapDispatchToProps = {
  getAuthorization,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationDetails);

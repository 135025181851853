import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { deleteEntity as deleteProducer, reset as resetProducer } from 'app/entities/producer/producer.reducer';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { Pageable } from 'app/shared/model/pageable';
import { IRootState } from 'app/shared/reducers';
import { maskCnpj, maskCPF } from 'app/shared/util/mask-utils';
import StringUtils from 'app/shared/util/string-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { getAllManagerProducers, removeProducer, reset } from '../manager-link-reducer';
import './manager-producers.scss';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { GeneratorCompanyType } from 'app/shared/model/producer.model';

export interface IManagerProducerListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IManagerProducerListState {
  filterName: string;
  page: Pageable;
  showDeleteModal: boolean;
  deleteItemId: number;
}

export class ManagerProducerList extends React.Component<IManagerProducerListProps, IManagerProducerListState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('manager.producer.headers.cnpj'), sortCode: 'producer.cnpj' },
    { name: translate('manager.producer.headers.name'), sortCode: 'producer.companyName' },
    { name: translate('manager.producer.headers.city'), sortCode: 'producer.address.city.name' },
    { name: translate('manager.producer.headers.responsible'), sortCode: 'user.name' },
    { name: translate('manager.producer.headers.phone'), sortCode: 'producer.phone' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IManagerProducerListProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      showDeleteModal: false,
      deleteItemId: null,
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getAllManagerProducers();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.removeProducerStatus === HttpRequestStatus.SUCCESS && newProps.removeProducerStatus != this.props.removeProducerStatus) {
      this.props.getAllManagerProducers();
      this.closeDeleteModal();
      this.props.resetProducer();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getAllManagerProducers = () => {
    const { page } = this.state;
    this.props.getAllManagerProducers(page.page, page.size, page.sort, StringUtils.toObject('producer.legalPerson.companyName', this.state.filterName));
  };

  private handleTransformToTableContent = (content?: IUserProducer[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IUserProducer) => {

      if (item.producer.generatorCompanyType === GeneratorCompanyType.NATURAL) {
        result.push([
          maskCPF(item.producer?.naturalPerson.cpf) ?? '',
          item.producer?.naturalPerson.name ?? '',
          item.producer?.address?.city.name ?? '',
          item?.user.name ?? '',
          item.producer?.naturalPerson.phone ?? '',
          <DeleteEditButton
            id={item.id}
            hideDelete={!this.props.hasAdminAuthority}
            onDelete={() => this.onDeleteUserModal(item.producer.id)}
            hideEdit={true}
          />,
        ]);
      }

      console.log(item)

      result.push([
        maskCnpj(item?.producer?.legalPerson?.cnpj ?? ''),
        item?.producer?.legalPerson?.companyName ?? item?.producer?.naturalPerson?.name ?? '',
        item?.producer?.address?.city?.name ?? '',
        item?.user?.name ?? '',
        item?.producer?.legalPerson?.phone ?? '',
        <DeleteEditButton
          id={item.id}
          hideDelete={!this.props.hasAdminAuthority}
          onDelete={() => this.onDeleteUserModal(item.producer.id)}
          hideEdit={true}
        />,
      ]);
    });

    return result;
  };

  onProducerClick = (index: number) => {
    const { managerProducers } = this.props;
    const producer = managerProducers[index];
    this.props.history.push({
      pathname: `${this.props.match.url}/details`,
      state: { id: producer.producer.id },
    });

  };;

  onDeleteProducer = (id: number) => {
    this.props.deleteProducer(id);
  };

  onLinkProducer = () => {
    this.props.history.push(`${this.props.match.url}/link`);
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getAllManagerProducers()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getAllManagerProducers()
    );
  };

  onHandleFilterChanged = value => {
    console.log(value);
    this.setState({ filterName: value }, () => this.getAllManagerProducers());
  };

  chooseDelete = () => {
    this.props.removeProducer(this.state.deleteItemId);
  };

  onDeleteUserModal = (id: number) => {
    this.setState({
      showDeleteModal: true,
      deleteItemId: id,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      deleteItemId: null,
    });
  };

  render() {
    const { managerProducers } = this.props;
    const { showDeleteModal } = this.state;
    const rows = this.handleTransformToTableContent(managerProducers);
    return (
      <div className="mtr-unity__items">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('manager.producer.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px', display: 'flex', justifyContent: 'space-between' }}>
            <Col md="6">
              <SimpleSearchField
                value={this.state.filterName}
                placeholder={translate('manager.producer.search')}
                onChange={this.onHandleFilterChanged}
              />
            </Col>
            <Col
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                maxWidth: '233px',
                marginRight: '-15px',
              }}
            >
              <Button className={'button primary'} type={'submit'} onClick={this.onLinkProducer}>
                <Translate contentKey={'manager.producer.linkProducer'} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onProducerClick}
              />
            </Col>
          </Row>

          <AlertModal
            showModal={showDeleteModal}
            buttonOneActionMessage={'mtr-signature.button-no'}
            buttonTwoActionMessage={'mtr-signature.button-yes'}
            hasTwoButtons
            buttonTwoAction={this.chooseDelete}
            buttonOneAction={this.closeDeleteModal}
            statusImage="money-bag.svg"
            alertMessage="alert.producerLinkDelete"
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchManagerProducersStatus: root.managerlink.fetchManagerProducersStatus,
  totalItems: root.managerlink.totalItems,
  managerProducers: root.managerlink.managerProducers,
  removeProducerStatus: root.managerlink.removeProducerStatus,
  hasAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
});

const mapDispatchToProps = {
  getAllManagerProducers,
  deleteProducer,
  removeProducer,
  reset,
  resetProducer,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerProducerList);

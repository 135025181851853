import React from 'react';
import './infoPlans.scss';

export default function InfoPlans() {

    const cardsStatus = (status: string, title: string, description: string, width: string, height = '') => {
        const statusClass = `cardInfo cardInfo--${status}`
        return (
            <div className='container-infoPlans-cardStatus' style={{ height }}>
                <div className={statusClass} style={{ width: width }}>
                    <span>{title}</span>
                </div>
                <span className='container-infoPlans-cardStatus--Description'>{description}</span>
            </div>
        )
    }

    return (
        <div className='container-infoPlans'>
            <p className='container-infoPlans--Title'>Legenda de Status:</p>
            {
                cardsStatus('active', 'ATIVO', 'Plano está ativo.', '57px')
            }
            {
                cardsStatus('pending', 'EM PROCESSAMENTO', 'Pagamento em processamento.', '120px')
            }
            {
                cardsStatus('processing', 'ERRO NO PAGAMENTO', 'Boleto ou cartão com problema, veriricar email enviado.', '120px', '80px')
            }
            {
                cardsStatus('inactive', 'INATIVO', 'Plano cancelado, mas ainda vigente.', '57px')
            }
        </div>
    )
}

import { IResidue, ResidueTreatment } from 'app/shared/model/residue.model';
import { ISpecificResidue } from 'app/shared/model/specific-residue.model';
import { MeasurementType } from './authorization.model';

export const enum ResidueSourceType {
  PRODUCER = 'PRODUCER',
  MANAGER = ' MANAGER',
  TRANSPORTER = ' TRANSPORTER',
  FINAL_DESTINATION = ' FINAL_DESTINATION',
}
export interface Error {
  inputClass?: boolean;
  inputSpecificResidue?: boolean;
  inputResidueState?: boolean;
  inputPackingGroup?: boolean;
  inputResidueTreatment?: boolean;
  inputBoardingName?: boolean;
  inputConditioning?: boolean;
  inputMeasurementType?: boolean;
}
export interface ISourceResidue {
  id?: number;
  sourceId?: number;
  sourceType?: ResidueSourceType;
  residue?: IResidue;
  specificResidue?: ISpecificResidue;
  error?: Error;
  visible?: boolean;
  sourceResidueClassification?: string;
  riskClassification?: string;
  onuClassification?: string;
  boardingName?: string;
  residueTreatment?: string;
  conditioning?: string;
  residueState?: string;
  packingGroup?: string;
  sourceName?: string;
  density?: number;
  measurementType?: string;
  specificResidueDescription?: string;
}

export interface ISourceResidueMtr {
  id?: number;
  code?: string;
  description?: string;
  residueType?: string;
  residueSubcategory?: string;
  residueTreatment?: ResidueTreatment;
  measurementType?: MeasurementType;
  name?: string;
  sourceId?: number;
  sourceType?: ResidueSourceType;
  residue?: IResidue;
  specificResidue?: ISpecificResidue;
  error?: boolean;
  specificResidueDescription?: string;
}

export function mapSourceResiduesToSearch(residues: ISourceResidueMtr[]) {
  const objects = residues.map(it => {
    return { name: it.name, id: it.id };
  });
  return objects.sort((a, b) => a.name.localeCompare(b.name));
}

export function getFormatedDescription(sourceResidue: ISourceResidueMtr) {
  let result = '';
  if (sourceResidue != null) {
    const { code, description } = sourceResidue;
    result += description ?? '';
    if (code != null) {
      result = `${code} - ${result}`;
    }
  }
  return result;
}

export const defaultValue: Readonly<ISourceResidue> = {};

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Authorization from '../generic/authorization/index';
import ProducerDetails from '../generic/details/producer/producer-details';
import TransporterDetails from '../generic/details/transporter/transporter-details';
import FavoriteProducerList from '../generic/favorite-list/favorite-producer/favorite-producer-list';
import FavoriteTransporterList from '../generic/favorite-list/favorite-transporter/favorite-transporter-list';
import GenericInvite from '../generic/invite/generic-invite';
import MtrManage from '../generic/mtr/manage/mtr-manage';
import MtrList from '../generic/mtr/mtr-list';
import FinalDestinationFavoriteSummary from './favorite/final-destination-favorite-summary';
import EnvironmentalLicenseDetails from './licenses/detail/environmental-license-details';
import EnvironmentalLicenseEdit from './licenses/edit/environmental-license-edit';
import FinalDestinationLicensesList from './licenses/final-destination-licenses';
import FinalDestinationResidues from './residues/final-destination-residues';
import FinalDestinationResiduesAdd from './residues/final-destination-residues-add';
import MtrSignatureStep from '../generic/mtr/signature/mtr-signature';
import FinalDestinationUnits from './units/final-destination-units';
import SubsidiaryCreateMenu from './units/subsidiary-create/menu/subsidiary-create-menu';
import CustomCdfList from 'app/components/custom-cdf-list/custom-cdf-list';
import CustomCdfCreate from 'app/components/custom-cdf-create/custom-cdf-create';
import CustomCdfManage from 'app/components/custom-cdf-manage/custom-cdf-manage';
import CustomCdfSignature from 'app/components/custom-cdf-signature/custom-cdf-signature';
import SubsidiaryDetails from './units/subsidiary-details/subsidiary-details';
import FinalDestinationUpdateMenu from './units/update/menu/final-destination-update-menu';
import CustomMtrReportCreate from 'app/components/custom-mtr-report-create/custom-mtr-report-create';
import customMtrReceiptCreate from 'app/components/custom-mtr-receipt-create/custom-mtr-receipt-create';
import CustomMtrSignatureAll from 'app/components/custom-mtr-signature-all/custom-mtr-signature-all';

const Routes = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/mtr/manage`} component={MtrManage} />
        <Route path={`${match.url}/mtr/manage/signature`} component={MtrSignatureStep} />
        <Route exact path={`${match.url}/mtr`} component={MtrList} />
        <Route path={`${match.url}/mtr-report/create`} component={CustomMtrReportCreate} />
        <Route path={`${match.url}/mtr-signature/all`} component={CustomMtrSignatureAll} />
        <Route path={`${match.url}/mtr-receipt/create`} component={customMtrReceiptCreate} />
        <Route exact path={`${match.url}/licenses`} component={FinalDestinationLicensesList} />
        <Route path={`${match.url}/licenses/edit`} component={EnvironmentalLicenseEdit} />
        <Route path={`${match.url}/licenses/new`} component={EnvironmentalLicenseEdit} />
        <Route path={`${match.url}/licenses/details`} component={EnvironmentalLicenseDetails} />
        <Route exact path={`${match.url}/residues`} component={FinalDestinationResidues} />
        <Route path={`${match.url}/residues/add`} component={FinalDestinationResiduesAdd} />
        <Route path={`${match.url}/authorizations`} component={Authorization} />
        <Route exact path={`${match.url}/summary`} component={FinalDestinationFavoriteSummary} />
        <Route exact path={`${match.url}/summary/transporters`} component={FavoriteTransporterList} />
        <Route path={`${match.url}/summary/transporters/detail`} component={TransporterDetails} />
        <Route exact path={`${match.url}/summary/producers`} component={FavoriteProducerList} />
        <Route path={`${match.url}/summary/producers/detail`} component={ProducerDetails} />
        <Route path={`${match.url}/invite`} component={GenericInvite} />
        <Route exact path={`${match.url}/cdf`} component={CustomCdfList} />
        <Route path={`${match.url}/cdf/:cdfNumber/manage/signature`} component={CustomCdfSignature} />
        <Route path={`${match.url}/cdf/:cdfNumber/manage`} component={CustomCdfManage} />
        <Route path={`${match.url}/cdf/create`} component={CustomCdfCreate} />
        <Route exact path={`${match.url}/destination`} component={FinalDestinationUnits} />
        <Route exact path={`${match.url}/destination/details`} component={SubsidiaryDetails} />
        <Route exact path={`${match.url}/destination/subsidiary/update`} component={FinalDestinationUpdateMenu} />
        <Route exact path={`${match.url}/destination/subsidiary/create/new`} component={SubsidiaryCreateMenu} />
        <Route exact path={`${match.url}/destination/holding/update`} component={FinalDestinationUpdateMenu} />
    </Switch>
)

export default Routes;

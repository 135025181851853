import AlertModal from 'app/components/alert-modal/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import Loading from 'app/components/loading/loading';
import SignatureComponent from 'app/components/signature-component/signature-component';
import { ALL_ADMIN_AUTHORITIES, ALL_USER_AUTHORITIES, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IDocument } from 'app/shared/model/document.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { ISignature } from 'app/shared/model/signature.model';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { getEntity as findCdf, reset as resetCdfStatus } from '../../entities/cdf/cdf.reducer';
import { getResponsibleSignature, getSignature, reset, signCdf } from './custom-cdf-signature.reducer';
import './custom-cdf-signature.scss';
import { Cdf } from 'app/shared/model/cdf.model';

interface ICustomCdfSignatureProps extends StateProps, DispatchProps, RouteComponentProps<{ cdfNumber: string }> {
  // TODO
}

interface ICustomCdfSignatureState {
  signature: ISignature;
  showErrorModal: boolean;
  showSuccessModal: boolean;
}

class CustomCdfSignature extends React.Component<ICustomCdfSignatureProps, ICustomCdfSignatureState> {
  constructor(props) {
    super(props);
    this.state = {
      signature: null,
      showErrorModal: false,
      showSuccessModal: false,
    };
  }

  componentDidMount() {
    this.resetAll();

    const { hasAdminAuthority, match, findCdf } = this.props;

    findCdf(match.params.cdfNumber);

    if (hasAdminAuthority) {
      this.props.getSignature();
    } else {
      this.props.getResponsibleSignature();
    }
  }

  componentWillUnmount() {
    this.resetAll();
  }

  componentWillReceiveProps(newProps: Readonly<ICustomCdfSignatureProps>) {

    if (newProps.getCdfStatus === HttpRequestStatus.ERROR || newProps.cdf?.finalDestinationSignature?.id != null) {
      this.redirectToCdfManage();
    }

    if (newProps.fetchSignatureStatus === HttpRequestStatus.SUCCESS && newProps.fetchSignatureStatus != this.props.fetchSignatureStatus) {
      this.setState({
        signature: newProps.signature,
      });
    }

    if (
        newProps.fetchResponsibleSignatureStatus === HttpRequestStatus.SUCCESS &&
        newProps.fetchResponsibleSignatureStatus != this.props.fetchResponsibleSignatureStatus) {
      this.setState({
        signature: newProps.signature,
      });
    }

    if (newProps.signCdfStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
    }

    if (newProps.signCdfStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  isProducerOrFinalDestination = () => {
    const { hasFinalDestinationAuthorities } = this.props;
    return hasFinalDestinationAuthorities ? 'final-destination' : 'producer';
  };

  resetAll = () => {
    this.props.reset();
    this.props.resetCdfStatus();
  };

  toggleSuccessModal = () => {
    this.setState({
      showSuccessModal: !this.state.showSuccessModal
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal
    });
  };

  handleFileAttach = (document: IDocument) => {
    const { account } = this.props;
    this.setState({
      signature: {
        ...this.state.signature,
        user: account,
        signatureDocument: document
      }
    });
  };

  onSubmitSignature = () => {
    const { cdf } = this.props;
    const { signature } = this.state;

    const entity: Cdf = {
      id: cdf.id,
      finalDestinationSignature: signature
    };

    this.props.signCdf(entity);
  };

  onBackHandler = () => {
    this.props.history.goBack();
  };

  redirectToCdfManage = () => {
    const { history, match } = this.props;
    history.push(`/${this.isProducerOrFinalDestination()}/cdf/${match.params.cdfNumber}/manage`);
  };

  render() {
    const { showSuccessModal, showErrorModal, signature } = this.state;
    const { fetchSignatureStatus, fetchResponsibleSignatureStatus, signCdfStatus, getCdfStatus } = this.props;
    if (checkStatusOnGoing([fetchSignatureStatus, fetchResponsibleSignatureStatus, signCdfStatus, getCdfStatus])) {
      return <Loading />;
    }
    return (
        <div className="mtr-signature__steps">
          <BackHeaderComponent onBack={this.redirectToCdfManage} />
          <Container>
            <Row style={{ justifyContent: 'center' }}>
              <Col md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <SignatureComponent
                  className={''}
                  isUser={this.props.hasUserAuthority}
                  onFileAttached={this.handleFileAttach}
                  signature={signature ? signature.signatureDocument : null}
                  responsibleName={signature ? (signature.user ? signature.user.nameTechnical : '') : ''}
                />
                <div style={{ marginBottom: '30px' }}>
                  <h1 style={{ fontWeight: 600 }}>
                    <Translate contentKey={'cdf.signature.title'} />
                  </h1>
                </div>
                {/*<DatePickerComponent*/}
                {/*    error={false}*/}
                {/*    errorMessage=""*/}
                {/*    dateFormat={APP_LOCAL_DATE_FORMAT}*/}
                {/*    dateSelected={signature ? signature.signatureDate : null}*/}
                {/*    placeholder={translate('environmentalLicense.form.emission-mtr')}*/}
                {/*    onDateChange={value => {*/}
                {/*      this.setState(prevState => ({*/}
                {/*        signature: {*/}
                {/*          ...prevState.signature,*/}
                {/*          signatureDate: value,*/}
                {/*        },*/}
                {/*      }));*/}
                {/*    }}*/}
                {/*/>*/}
                <div className="mtr-signature--buttons">
                  <button style={{ width: '172px' }} className={'button third'} type={'submit'} onClick={this.redirectToCdfManage}>
                    <Translate contentKey={'mtr-signature.button-cancel'} />
                  </button>
                  <button
                      type={'button'}
                      style={{ width: '172px' }}
                      className={'button primary'}
                      onClick={() => this.onSubmitSignature()}
                      disabled={
                        this.state.signature == null ||
                        // this.state.signature.signatureDate == null ||
                        this.state.signature.signatureDocument == null ||
                        this.props.signCdfStatus === HttpRequestStatus.ONGOING
                      }
                  >
                    <Translate contentKey={'mtr-signature.button-sign'} />
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
          <AlertModal
              hasOneButton
              showModal={showSuccessModal}
              statusImage="assinatura.svg"
              alertMessage="alert.cdfSignatureSuccess"
              buttonTwoAction={() => this.redirectToCdfManage()}
              buttonTwoActionMessage={'mtr-signature.button-ok'}
          />
          <AlertModal
              hasOneButton
              showModal={showErrorModal}
              statusImage="bag-error.svg"
              alertMessage="errorMessages.signature"
              buttonTwoAction={() => this.toggleErrorModal()}
              buttonTwoActionMessage={'mtr-signature.button-ok'}
          />
        </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  cdf: root.cdf.entity,
  account: root.authentication.account,
  getCdfStatus: root.cdf.findCdfStatus,
  signature: root.customCdfSignature.signature,
  signCdfStatus: root.customCdfSignature.signCdfStatus,
  fetchSignatureStatus: root.customCdfSignature.fetchSignatureStatus,
  fetchResponsibleSignatureStatus: root.customCdfSignature.fetchResponsibleSignatureStatus,
  hasUserAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_USER_AUTHORITIES),
  hasAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_ADMIN_AUTHORITIES),
  hasProducerAuthorities: hasAnyAuthority(
      root.authentication.account.authorities,
      [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER,]),
  hasFinalDestinationAuthorities: hasAnyAuthority(
      root.authentication.account.authorities,
      [AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER,])
});

const mapDispatchToProps = {
  reset,
  signCdf,
  findCdf,
  getSession,
  getSignature,
  resetCdfStatus,
  getResponsibleSignature
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CustomCdfSignature);

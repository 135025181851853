import React from 'react';
import './QuantityDropdown.scss';

const QuantityDropdown = ({ onChange, selectedValue }) => {
    const handleChange = (event) => {
        const newValue = event.target.value;
        onChange(newValue); // Passa o valor selecionado para o componente pai
    };

    return (
        <div>
            <select
                id="quantity-dropdown"
                value={selectedValue}
                onChange={handleChange}
            >
                {Array.from({ length: 100 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                        {index + 1}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default QuantityDropdown;

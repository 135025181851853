import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import { translate, Translate } from 'react-jhipster';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { getSubsidiariesByHolding, reset } from './final-destination-units-reducer';
import Loading from 'app/components/loading/loading';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import { maskCnpj, maskPhone } from 'app/shared/util/mask-utils';
import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { Pageable } from 'app/shared/model/pageable';
import {
  deleteEntity,
  deleteSubsidiary,
  getFinalDestinationDetailByUuid,
  reset as resetFinalDestination,
} from 'app/entities/user-final-destination/user-final-destination.reducer';
import StringUtils from 'app/shared/util/string-utils';
import HoldingDetailComponent from './holding-detail-component/holding-detail-component';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import './final-destination-units.scss';

export interface IFinalDestinationUnitsProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IFinalDestinationUnitsState {
  filterName: string;
  page: Pageable;
}

export class FinalDestinationUnits extends React.Component<IFinalDestinationUnitsProps, IFinalDestinationUnitsState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('producer.units.header.cnpj'), sortCode: 'finalDestination.cnpj' },
    { name: translate('producer.units.header.companyName'), sortCode: 'finalDestination.companyName' },
    { name: translate('producer.units.header.city'), sortCode: 'finalDestination.address.city.name' },
    { name: translate('producer.units.header.responsible'), sortCode: 'finalDestination.name' },
    { name: translate('producer.units.header.phone'), sortCode: 'finalDestination.phone' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IFinalDestinationUnitsProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.props.resetFinalDestination();
    await this.props.getFinalDestinationDetailByUuid();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.userUuidDetail != this.props.userUuidDetail &&
      newProps.userUuidDetail.finalDestination != null &&
      !newProps.userUuidDetail.finalDestination.isSubsidiary 
    ) {
      this.getSubsidiariesByHolding();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
    if (newProps.deleteEntityStatus != this.props.deleteEntityStatus && newProps.deleteEntityStatus === HttpRequestStatus.SUCCESS) {
      this.getSubsidiariesByHolding();
    }
  }

  getSubsidiariesByHolding = () => {
    const { page } = this.state;
    this.props.getSubsidiariesByHolding(
      page.page,
      page.size,
      page.sort,
      StringUtils.toObject('producer.companyName', this.state.filterName)
    );
  };

  private handleTransformToTableContent = (content?: IUserFinalDestination[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IUserFinalDestination) => {
      result.push([
        item.finalDestination.holdingCnpj != null ? maskCnpj(item.finalDestination.holdingCnpj) ?? '' : '',
        item.finalDestination.fantasyName ?? '',
        item.finalDestination.address != null ? item.finalDestination.address.city.name : '',
        item.user.name,
        item.finalDestination.phone ? maskPhone(item.finalDestination.phone) : '',
        <DeleteEditButton
          id={item.id}
          hideEdit={!this.props.isFinalDestinationAdmin}
          hideDelete={!this.props.isFinalDestinationAdmin}
          onDelete={this.onDeleteSubsidiary}
          onEdit={this.onEditSubsidiary}
        />,
      ]);
    });

    return result;
  };

  private handleTransformSubsidiaryToTableContent = (content?: IUserFinalDestination[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IUserFinalDestination) => {
      result.push([
        item.finalDestination.cnpj != null ? maskCnpj(item.finalDestination.cnpj) ?? '' : '',
        item.finalDestination.companyName ?? '',
        item.finalDestination.address != null ? item.finalDestination.address.city.name : '',
        item.user.name,
        item.user.phone ? maskPhone(item.user.phone) : '',
        <DeleteEditButton
          id={item.id}
          hideDelete
          hideEdit={!this.props.isFinalDestinationAdmin}
          onDelete={this.onDeleteSubsidiary}
          onEdit={this.onEditSubsidiary}
        />,
      ]);
    });

    return result;
  };

  onDeleteSubsidiary = (id: number) => {
    this.props.deleteSubsidiary(id);
  };

  onCreateSubsidiary = () => {
    this.props.history.push({
      pathname: `${this.props.match.url}/subsidiary/create/new`,
      state: { cnpj: this.props.userUuidDetail?.finalDestination?.cnpj,
        companyName: this.props.userUuidDetail?.finalDestination?.companyName      
      },
    })
  };

  onEditSubsidiary = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/subsidiary/update`,
      state: { id: id },
    });
  };

  onEditHolding = (id: number) => {
    // this.props.history.push(`${this.props.match.url}/holding`);
    this.props.history.push({
      pathname: `${this.props.match.url}/holding/update`,
      state: { id: id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getSubsidiariesByHolding()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getSubsidiariesByHolding()
    );
  };

  onHandleFilterChanged = value => {
    this.setState({ filterName: value }, () => this.getSubsidiariesByHolding());
  };

  onSubsidiaryClick = (index: number) => {
    const { userUuidDetail } = this.props;

    if (this.props?.subsidiaries?.length >= 1) {
      const { subsidiaries } = this.props;
      const subsidiaryClicked = subsidiaries[index];
      this.props.history.push({
        pathname: `${this.props.match.url}/details`,
        state: { id: subsidiaryClicked.id },
      });
    } else {
      this.props.history.push({
        pathname: `${this.props.match.url}/details`,
        state: { id: userUuidDetail.id },
      });
    }
  };

  render() {
    const { getHoldingSubidiariesStatus, userUuidDetailStatus, deleteEntityStatus, userUuidDetail, isFinalDestinationAdmin } = this.props;
    const rows: (string | JSX.Element)[][] =
      userUuidDetail.finalDestination && !userUuidDetail.finalDestination.isSubsidiary
        ? this.handleTransformToTableContent(this.props.subsidiaries)
        : this.handleTransformSubsidiaryToTableContent(userUuidDetail.finalDestination ? [userUuidDetail] : null);
    if (checkStatusOnGoing([userUuidDetailStatus, deleteEntityStatus])) {
      return <Loading />;
    }
    return (
      <div className="mtr-unity__items">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('mtr-unity.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px' }}>
            <Col md="6">
              <SimpleSearchField placeholder="Buscar Unidades" onChange={this.onHandleFilterChanged} />
            </Col>
            <Col md={{ size: 2, offset: 4 }}>
              {userUuidDetail.finalDestination && !userUuidDetail.finalDestination?.isSubsidiary && isFinalDestinationAdmin && (
                <Button className={'button primary'} type={'submit'} onClick={this.onCreateSubsidiary}>
                  <Translate contentKey={'mtr-unity.button'} />
                </Button>
             )}
            </Col>
          </Row>
          <Row>
            <Col>
              <HoldingDetailComponent
                isAdmin={isFinalDestinationAdmin}
                onEditHolding={this.onEditHolding}
                userFinalDestination={this.props.userUuidDetail}
              />
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onSubsidiaryClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  getHoldingSubidiariesStatus: root.finalDestinationUnits.getHoldingSubidiariesStatus,
  subsidiaries: root.finalDestinationUnits.subsidiaries,
  totalItems: root.finalDestinationUnits.totalItems,
  userUuidDetail: root.userFinalDestination.finalDestinationUuidDetail,
  userUuidDetailStatus: root.userFinalDestination.finalDestinationDetailUuidStatus,
  deleteEntityStatus: root.userFinalDestination.deleteEntityStatus,
  isFinalDestinationAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN]),
});

const mapDispatchToProps = {
  getFinalDestinationDetailByUuid,
  getSubsidiariesByHolding,
  resetFinalDestination,
  deleteEntity,
  deleteSubsidiary,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationUnits);

import { APP_FILE_SIZE_LIMIT } from 'app/config/constants';

export const IsFileTooLarge = (size: number) => {
  if (size >= APP_FILE_SIZE_LIMIT) {
    return true;
  }
  return false;
};

export const ACCEPT_IMAGE_PDF = 'image/*, application/pdf';
export const ACCEPT_IMAGE = 'image/*';

import { IDocument } from 'app/shared/model/document.model';
import { IProducer } from 'app/shared/model/producer.model';

export enum EnvironmentalLicenseType {
  PRELIMINARY = 'PRELIMINARY',
  INSTALLATION = 'INSTALLATION',
  OPERATION = 'OPERATION',
  DISMISS = 'DISMISS',
  AUTHORIZATION = 'AUTHORIZATION',
  SIMPLIFIED = 'SIMPLIFIED',
}

export interface IEnvironmentalLicense {
  id?: number;
  licenseNumber?: string;
  expireDate?: string;
  issuingBody?: string;
  environmentalLicenseType?: EnvironmentalLicenseType;
  licenseDocument?: IDocument;
  producer?: IProducer;
  active?: boolean;
}

export const defaultValue: Readonly<IEnvironmentalLicense> = {};

import React, { useEffect, useState } from 'react';
import './units-new.scss';
import { translate, Translate } from 'react-jhipster';
import * as yup from "yup";
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UnitService } from 'app/services/unit';
import { IUnit } from 'app/shared/model/unit';
import { getEntities as getStates } from 'app/entities/state/state.reducer';
import { getSession } from 'app/shared/reducers/authentication';
import { getEntitiesByStateId as getCitiesByState } from 'app/entities/city/city.reducer';
import { maskZipCode, maskCPF, maskCnpj } from 'app/shared/util/mask-utils';
import Button from 'reactstrap/lib/Col';
import { Formik, useFormik } from 'formik';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { IState } from 'app/shared/model/state.model';
import { ICity } from 'app/shared/model/city.model';
import { RESIDUE_MANAGER_TYPES } from 'app/shared/model/enum/ResidueManagerType';
import { toast } from 'react-toastify';
import { validateCNPJ, validateCPF } from 'app/modules/validation/validation-constants';


const defaultUnit = {
    unitCode: "",
    fantasyName: "",
    address: {
        street: "",
        name: "",
        description: "",
        number: "",
        zipcode: "",
        district: "",
        complement: "",
        city: {
            id: undefined
        }
    }
}

interface IMtrUnitsNewProps extends StateProps, DispatchProps { }

enum E_PageType {
    CREATE,
    EDIT
}

const MtrUnitsNew = (props: IMtrUnitsNewProps) => {
    const [unit, setUnit] = useState<IUnit>(defaultUnit);
    const [selectedState, setSelectedState] = useState<IState>({} as IState);
    const [city, setCity] = useState<ICity>({} as ICity);
    const [pageType, setPageType] = useState<E_PageType>(null as E_PageType);
    const [state, setState] = useState<IState | undefined>(undefined)
    const [cityName, setCityName] = useState<ICity | undefined>(undefined)
    const [canRemove, setCanRemove] = useState<boolean>(false)
    const [canRemoveCity, setCanRemoveCity] = useState<boolean>(false)
    const history = useHistory();

    let schema = yup.object().shape({
        unitCode: yup.number().required(),
        fantasyName: yup.string(),
        address: yup.object({
            street: yup.string(),
            name: yup.string(),
            description: yup.string(),
            number: yup.number(),
            zipcode: yup.string().length(9, translate('unitTranslation.invalidZipCode')),
            district: yup.string(),
            complement: yup.string(),
            city: yup.object({
                state: yup.object({
                    // id: yup.string().required()
                }),
                id: yup.string().required(),
            })
        })
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: unit,
        onSubmit: values => {
            pageType === E_PageType.CREATE
                ? createUnit(values)
                : updateUnit(values)
        },
        validationSchema: schema,
        validateOnBlur: false,
        validateOnMount: false,
        validateOnChange: false
    });

    const createUnit = (values: IUnit) => {
        UnitService.createUnit(values)
            .then(() => {
                toast.success(translate('unitTranslation.createSuccess'));
                history.push('/mtr-units'); /* TODO:: Deixar "/mtr-units dinâmico" */
            });
    }

    const updateUnit = (values: IUnit) => {
        UnitService.updateUnit(values)
            .then(() => {
                toast.success(translate('unitTranslation.updateSuccess'));
                history.push('/mtr-units'); /* TODO:: Deixar "/mtr-units dinâmico" */
            });
    }

    const getUnit = (unitId: number) => {
        UnitService.getUnit(unitId)
            .then((response) => {
                setUnit(response);
                setSelectedState(response.address.city?.state)
                setCity({ id: response.address.city.id, name: response.address?.city?.name })
            })
    }

    const mapStatesToSearch = () => {
        const { states } = props;

        const objects = states.map(state => {
            return { name: state.name, id: state.id };
        });
        return objects.sort((a, b) => a.name.localeCompare(b.name));
    };

    const mapCitiesToSearch = () => {
        const { cities } = props;
        if (selectedState == null || selectedState === {}) {
            return [];
        }
        const objects = cities.map(city => {
            return { name: city.name, id: city.id };
        });
        return objects.sort((a, b) => a.name.localeCompare(b.name));
    };

    const onClickState = (item: any) => {
        const { states } = props;
        setState(item)
        setCanRemove(true)
        touchAndValidateField('address.city.state.id')
        setSelectedState(states.filter(it => it.id === item.id)[0]);
        setCity(null);
    };

    const onClickCity = (item: { name: string, id: number }) => {
        const { cities } = props;
        setCityName(item)
        setCanRemoveCity(true)
        setCity(cities.filter(it => it.id === item.id)[0]);
        formik.setFieldValue('address.city.id', item.id)
    };

    const mapSelectedStateToSelect = () => {
        return selectedState
            ? {
                name: selectedState.name,
                id: selectedState.id,
            }
            : null;
    };

    const mapSelectedCityToSelect = () => {
        return city
            ? {
                name: city.name,
                id: city.id,
            }
            : null;
    };

    const touchAndValidateField = (fieldName: string) => {
        formik.setFieldTouched(fieldName, true, true);
    }

    const cleanState = () => {
        setState(undefined)
        setSelectedState(undefined);
        setCityName(undefined)
        setCity(undefined);
    }

    const cleanCity = () => {
        setCityName(undefined)
        setCity(undefined);
    }

    const renderAddressForm = () => {
        return (
            <div className="address__items">
                <Row>
                    <Col>
                        <CustomTextField
                            onChange={e => formik.setFieldValue('address.street', e)}
                            value={formik.values.address.street}
                            name={"address.street"}
                            id="address.street"
                            style={{ marginTop: '20px' }}
                            label={translate('address.form.street')}
                            placeholder={translate('address.form.street')}
                            errorText={formik.errors.address?.street}
                            dinamic
                            error={formik.touched.address?.street && formik.errors.address?.street != null}
                            onBlur={() => touchAndValidateField('address.street')}
                        />

                        <CustomTextField
                            onChange={e => formik.setFieldValue('address.complement', e)}
                            value={formik.values.address.complement}
                            name={"complement"}
                            id="complement"
                            style={{ marginTop: '20px' }}
                            label={translate('address.form.complement')}
                            placeholder={translate('address.form.complement')}
                            errorText={formik.errors.address?.complement}
                            dinamic
                            error={formik.touched.address?.complement && formik.errors.address?.complement != null}
                            onBlur={() => touchAndValidateField('address.complement')}
                        />

                        {
                            state ?
                                <Col md={{ size: 20 }} style={{ marginTop: '20px', height: '63px' }}>
                                    <div className="item__select" style={{ height: '63px' }}>
                                        <div>
                                            <div className="list__items--title" style={{ color: '#9199a1' }}>{translate('address.form.state')}</div>
                                            <div className="list__items--text" style={{ color: '#9199a1' }}>{state?.name}</div>
                                        </div>
                                        <div> {canRemove && <div className="list__items--icon" onClick={cleanState} />}</div>
                                    </div>
                                </Col>
                                :
                                <div style={{ marginTop: '20px' }}>
                                    <DropdownSearch
                                        //errorMessage={translateErrorMessage(driverError.errorMessage)}
                                        showSearchField
                                        data={mapStatesToSearch()}
                                        getData={(item) => { }}
                                        onClickItem={onClickState}
                                        title={translate('address.form.state')}
                                        style={{ backgroundColor: '#f6f6f6' }}
                                        notAlphabeticalOrder
                                        showInsiderSelect
                                        filterLocalData
                                        label={translate('address.form.state')}
                                        placeholder={translate('address.form.state')}
                                        selectedData={mapSelectedStateToSelect()}
                                    />
                                </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            cityName ?
                                <Col md={{ size: 20 }} style={{ marginTop: '20px', height: '63px' }}>
                                    <div className="item__select" style={{ height: '63px' }}>
                                        <div>
                                            <div className="list__items--title" style={{ color: '#9199a1' }}>{translate('address.form.city')}</div>
                                            <div className="list__items--text" style={{ color: '#9199a1' }}>{cityName?.name}</div>
                                        </div>
                                        <div> {canRemoveCity && <div className="list__items--icon" onClick={cleanCity} />}</div>
                                    </div>
                                </Col>
                                :
                                <div style={{ marginTop: '20px' }}>
                                    <DropdownSearch
                                        showSearchField
                                        data={mapCitiesToSearch()}
                                        getData={(item) => { }}
                                        onClickItem={item => {
                                            onClickCity(item);
                                            touchAndValidateField('address.city')
                                        }}
                                        title={translate('address.form.city')}
                                        label={translate('address.form.city')}
                                        placeholder={translate('address.form.city')}
                                        style={{ backgroundColor: '#f6f6f6' }}
                                        notAlphabeticalOrder
                                        showInsiderSelect
                                        filterLocalData
                                        selectedData={mapSelectedCityToSelect()}
                                        disableOpen={selectedState == {}}
                                        errorMessage={formik.errors.address?.city?.id}
                                        error={formik.touched.address?.city && formik.errors.address?.city != null}
                                    />
                                </div>
                        }
                    </Col>
                    <Col md="4">
                        <CustomTextField
                            onChange={e => {
                                formik.setFieldValue('address.zipcode', e)
                            }}
                            onMask={maskZipCode}
                            value={formik.values.address.zipcode}
                            name={"zipcode"}
                            id="zipcode"
                            style={{ marginTop: '15px' }}
                            label={translate('address.form.zipcode')}
                            placeholder={translate('address.form.zipcode')}
                            errorText={formik.errors.address?.zipcode}
                            dinamic
                            error={formik.touched.address?.zipcode && formik.errors.address?.zipcode != null}
                            onBlur={() => touchAndValidateField('address.zipcode')}
                        />

                    </Col>
                </Row>
            </div>
        );
    }

    const renderForm = () => {
        return (
            <Col md={{ size: 4, offset: 4 }}>
                {props?.profile?.authorities.filter(it=>it=='ROLE_FINAL_DESTINATION_ADMIN')[0] === 'ROLE_FINAL_DESTINATION_ADMIN' ?
                    <p className="unity__info"> <Translate contentKey={'unitTranslation.infoFinalDestination'} /></p>
                    : props?.profile?.authorities.filter(it=>it=='ROLE_TRANSPORTER_ADMIN')[0] === 'ROLE_TRANSPORTER_ADMIN'
                  ? <p className="unity__info"> <Translate contentKey={'unitTranslation.infoTransporter'} /></p>
                    :
                    <p className="unity__info"> <Translate contentKey={'unitTranslation.info'} /></p>
                }
                <CustomTextField
                    onChange={e => formik.setFieldValue('unitCode', e)}
                    value={formik.values.unitCode}
                    name={"unitCode"}
                    id="unitCode"
                    style={{ marginTop: '20px' }}
                    label={translate('unitTranslation.form.unitCode')}
                    placeholder={translate('unitTranslation.form.unitCode')}
                    errorText={formik.errors.unitCode}
                    dinamic
                    error={formik.touched.unitCode && formik.errors.unitCode != null}
                    onBlur={() => touchAndValidateField('unitCode')}
                />

                <CustomTextField
                    onChange={e => formik.setFieldValue('fantasyName', e)}
                    value={formik.values.fantasyName}
                    name={"fantasyName"}
                    id="fantasyName"
                    style={{ marginTop: '20px' }}
                    label={translate('unitTranslation.form.fantasyName')}
                    placeholder={translate('unitTranslation.form.fantasyName')}
                    errorText={formik.errors.fantasyName}
                    dinamic
                    error={formik.touched.fantasyName && formik.errors.fantasyName != null}
                    onBlur={() => touchAndValidateField('fantasyName')}
                />

                {renderAddressForm()}
                <div style={{ marginTop: 22, marginBottom: 22 }}>
                    <Row className="justify-center">
                        <Col md="3" classame>
                            <Button
                                onClick={() => formik.submitForm()}
                                className={'button fourth button-fourth'}
                                type={'submit'}
                            >
                                <Translate contentKey={
                                    pageType === E_PageType.CREATE
                                        ? 'unitTranslation.form.submitButton'
                                        : 'unitTranslation.form.submitEditButton'
                                } />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        )
    }

    useEffect(() => {
        props.getStates(0, 50, 'name');
    }, []);

    useEffect(() => {
        const { id } = (history.location.state as any) || { id: null };
        if (id == null) {
            setPageType(E_PageType.CREATE);
            return; /* TODO:: Melhorar */
        } else {
            setPageType(E_PageType.EDIT);
            getUnit(id)
            return; /* TODO:: Melhorar */
        }
    }, [])

    useEffect(() => {
        if (selectedState?.id == null) return;
        props.getCitiesByState(selectedState.id);
    }, [selectedState])

    /* TODO:: Deixar "/mtr-units dinâmico" */
    return (
        <div className="list__items">
            <div className="list__items--back">
                <Container>
                    <Row>
                        <Col md="10">
                            <Link to="/mtr-units">
                                <div className="confirmation--back" >
                                    <div className="confirmation--back-icon" />
                                    <span>
                                        <Translate contentKey={'mtr-signature.back'} />
                                    </span>
                                </div>
                            </Link>
                        </Col>
                        <Col md="2">

                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <h1 style={{ fontWeight: 550, marginBottom: '30px' }}>
                    {pageType === E_PageType.CREATE
                        ? translate('unitTranslation.createUnit')
                        : translate('unitTranslation.editUnit')
                    }
                </h1>
            </div>

            {renderForm()}
        </div>
    );
}

const mapDispatchToProps = {
    getCitiesByState,
    getStates,
    getSession
};

const mapStateToProps = (root: IRootState) => ({
    states: root.state.entities,
    cities: root.city.entities,
    profile: root.authentication.account
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MtrUnitsNew);

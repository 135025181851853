import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './manager-mtr-list.scss';
import { SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import { translate, Translate } from 'react-jhipster';
import SearchPage from 'app/modules/login/generator/mtr-list/search';
import MtrFilterOpen from 'app/modules/login/generator/mtr-list/mtr-filter-open';

export interface IManagerMtrListProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IManagerMtrListState {}

export class ManagerMtrList extends React.Component<IManagerMtrListProps, IManagerMtrListState> {
  constructor(props: Readonly<IManagerMtrListProps>) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mtr-list__items">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px' }}>{translate('mtr-list.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col md="3">
              <SearchPage />
            </Col>
            <Col md="6">
              <div className="mtr-list__items--filter">
                <div className="mtr-list__items--filter-img" />
                <span>{translate('mtr-list.filter')}</span>
              </div>
            </Col>
            <Col md={{ offset: '1', span: '2' }}>
              <Button className={'button primary'} type={'submit'}>
                <Translate contentKey={'mtr-list.button'} />
              </Button>
            </Col>
          </Row>
          <MtrFilterOpen />
          <Row>
            <Col>
              <SimpleOrderedTable rows={[]} columNameKeys={[]} page={{ totalElements: 0, totalPages: 0 }} onChangePage={null} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerMtrList);

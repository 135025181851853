import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import { ALERT_ICONS, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IMtr, IntegrationMtrStatus, MtrStatus } from 'app/shared/model/mtr.model';
import { Pageable, SortType } from 'app/shared/model/pageable';
import { PersonType } from 'app/shared/model/transporter.model';
import { IRootState } from 'app/shared/reducers';
import moment from 'moment';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { findMtr, getMtrs, reset, resetStatus } from './mtr-reducer';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import { getAllManagerProducers, reset as resetLinkedProducers } from '../../manager/manager-link-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import _, { isEmpty } from 'lodash';
import { createSortParameter } from 'app/shared/util/resitrack-utils';
import Loading from '../../../components/loading/loading';
import './mtr-list.scss';
import ImportMtrModal from 'app/components/import-mtr-modal/import-mtr-modal';
import { getIntegrationUsers } from '../user-integration/user-integration.reducer';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { IIntegrationUser } from 'app/shared/model/integration-user.model';

export interface IMtrListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IMtrListState {
    startDate?: string;
    endDate?: string;
    page: Pageable;
    showFilter: boolean;
    searchFilter: string;
    isLinkedProducersEmpty: boolean;
    hasItems: boolean;
    isModalOpen: boolean;
    integrationUsers: IIntegrationUser[];
}

export class MtrList extends React.Component<IMtrListProps, IMtrListState> {
    constructor(props: Readonly<IMtrListProps>) {
        super(props);
        this.state = {
            page: { size: 5, page: 0, totalElements: 0, totalPages: 0 },
            searchFilter: '',
            showFilter: false,
            isLinkedProducersEmpty: false,
            hasItems: true,
            isModalOpen: false,
            integrationUsers: [],
        };
    }
    private MTR_DEFAULT_SORT_PARAMETERS: string[] = ['collectionDate', 'numberMtr'];

    private managerTableHeaders: ColumnSort[] = [
        { name: translate('mtr-list.table.date'), sortCode: 'collectionDate' },
        { name: translate('mtr-list.table.code'), sortCode: 'numberMtr' },
        { name: translate('mtr-list.table.producer'), sortCode: 'producer.legalPerson.fantasyName' },
        { name: translate('mtr-list.table.transporter'), sortCode: 'transporter.id' },
        { name: translate('mtr-list.table.finalDestination'), sortCode: 'finalDestination.legalPerson.companyName' },
        { name: translate('mtr-list.table.transshipment'), sortCode: 'transshipment.fantasyName' },
        { name: translate('mtr-list.table.status'), sortCode: 'status' },
        { name: translate('mtr-list.table.integrationMtrStatus'), sortCode: 'integrationMtrStatus' }

    ];

    private tableHeaders: ColumnSort[] = [
        { name: translate('mtr-list.table.date'), sortCode: 'collectionDate' },
        { name: translate('mtr-list.table.code'), sortCode: 'numberMtr' },
        this.props.hasTransporterAuthorities
            ? { name: translate('mtr-list.table.producer'), sortCode: 'producer.naturalPerson.name' }
            : { name: translate('mtr-list.table.transporter'), sortCode: 'transporter.id' },
        this.props.hasFinalDestinationAuthorities
            ? { name: translate('mtr-list.table.producer'), sortCode: 'producer.naturalPerson.name' }
            : { name: translate('mtr-list.table.finalDestination'), sortCode: 'finalDestination.legalPerson.companyName' },
        this.props.hasTransshipmentAuthorities
            ? { name: translate('mtr-list.table.producer'), sortCode: 'producer.fantasyName' }
            : { name: translate('mtr-list.table.transshipment'), sortCode: 'transshipment.fantasyName' },
        { name: translate('mtr-list.table.status'), sortCode: 'status' },
        { name: translate('mtr-list.table.integrationMtrStatus'), sortCode: 'integrationMtrStatus' }
    ];

    componentDidMount() {
        const { search } = this.getQuerie();
        const { page } = this.getQuerie();
        this.setFilterState(search ? search : '', page ? Number(page) : 0);
    }

    componentWillReceiveProps(newProps: Readonly<IMtrListProps>) {
        if (newProps.totalItems !== this.state.page.totalElements) {
            this.props.resetStatus();
            this.setState(prevState => ({
                page: {
                    ...prevState.page,
                    totalElements: newProps.totalItems,
                },
            }));
        }
        if (newProps.fetchManagerProducersStatus === HttpRequestStatus.SUCCESS) {
            this.setState({ isLinkedProducersEmpty: isEmpty(newProps.managerProducers) });
            this.props.resetLinkedProducers();
        }

        if (newProps.mtrs.length) {
            this.setState({ hasItems: true });
        }
    }

    handleChangePage = (newPage: number) => {
        const { searchFilter } = this.state;
        this.setState(
            {
                page: { ...this.state.page, page: newPage },
            },
            () => this.getMtrs()
        );
        this.setFilterState(searchFilter, newPage);
    };

    cleanFilter = () => {
        this.setState(
            {
                showFilter: false,
                startDate: undefined,
                endDate: undefined,
                searchFilter: '',
            },
            () => this.getMtrs()
        );
    };

    getMtrs = (search: string = this.state.searchFilter, newPage: number = this.state.page.page) => {
        const { page } = this.state;
        const sort = this.handleSortParameters(page);

        // Busca apenas 5 MTRs para exibição na interface
        this.props.getMtrs(newPage, 5, sort, {
            searchText: search,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        });
    };

    getQuerie = () => {
        const queries = new URLSearchParams(this.props.location.search);
        const search = queries.get('search');
        const page = queries.get('page');

        return {
            queries,
            search,
            page,
        };
    };

    setFilterState = (search: string, newPage: number) => {
        this.setState({
            searchFilter: search,
            page: {
                ...this.state.page,
                page: newPage,
            },
        });

        this.getMtrs(search, newPage);
        this.props.history.replace(`${this.props.match.url}?search=${search}&page=${newPage}`);
    };

    handleSortParameters = (page: Pageable) => {
        if (!page.sort) {
            return null;
        }

        let sortWithDefaultParameters = page.sort.concat('&');

        this.MTR_DEFAULT_SORT_PARAMETERS.forEach(it => {
            if (!sortWithDefaultParameters?.includes(it)) {
                const defaultSortParameter = createSortParameter(it.concat(',').concat(SortType.DESC));
                sortWithDefaultParameters = sortWithDefaultParameters.concat(defaultSortParameter);
            }
        });

        return sortWithDefaultParameters;
    };

    onHandleFilterChanged = value => {
        this.setFilterState(value, 0);
    };

    onHandleCreateMtrClick = () => {
        if (this.props.hasManagerAuthorities) {
            this.props.getAllManagerProducers(0, 1, null, null);
            if (this.state.isLinkedProducersEmpty) {
                this.noLinkedManagerProducersAlertPage();
            } else {
                this.props.history.push(`${this.props.match.url}/manage`);
            }
        } else {
            this.props.history.push(`${this.props.match.url}/manage`);
        }
    };

    // onHandleCreateMTrComplementarCLick = () => {
    //     this.props.history.push(`${this.props.match.url}/mtr-complementar`);
    // }

    noLinkedManagerProducersAlertPage = () => {
        this.props.history.push({
            pathname: '/alert-page',
            state: { statusImage: ALERT_ICONS.BAG_ERROR, alertMessage: 'alert.noLinkedProducers' },
        });
    };

    handleOpenModal = () => {
        this.setState({ isModalOpen: true });
        this.props.getIntegrationUsers();
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
    };

    renderMtrFilter = () => {
        return (
            <div className="mtr-list__filter-open">
                <div className="sessao_1">
                    <div className="mtr-list__filter-open--title">{translate('mtr-list.filter-open.title-first')}</div>
                    <div className="mtr-list__filter-open--items">
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={value => this.setState({ startDate: value })}
                            dateSelected={this.state.startDate}
                            placeholder={translate('mtr-list.filter-open.if')}
                        />
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={value => this.setState({ endDate: value })}
                            dateSelected={this.state.endDate}
                            placeholder={translate('mtr-list.filter-open.until')}
                        />
                        <div className="mtr-list__filter-open--button" onClick={() => this.getMtrs()}>
                            <span>{translate('mtr-list.filter-open.button')}</span>
                            <div className="mtr-list__filter-open--img" />
                        </div>
                        <div className="mtr-list__filter-open--clear-filter" onClick={() => this.cleanFilter()}>
                            {translate('mtr-list.filter-open.clear-filter')}
                        </div>
                    </div>
                </div>
                <div className="mtr-list__filter-open--close" onClick={() => this.setState({ showFilter: false })} />
            </div>
        );
    };

    private handleTransformToTableContent = (content?: IMtr[]): (string | JSX.Element)[][] => {
        if (content == null || content.length === 0) {
            return [];
        }
        const result: (string | JSX.Element)[][] = [];

        this.props.hasManagerAuthorities ?
            content.map((item: IMtr) => {
                result.push([
                    item.collectionDate != null ? moment(item.collectionDate).format('DD/MM/YYYY') : '',
                    this.renderMtrNumber(item),
                    item.producer != null
                        ? item.producer?.legalPerson?.companyName ?? item.producer?.naturalPerson?.name
                        : '',
                    item.transporter != null
                        ? item.transporter.personType === PersonType.LEGAL
                            ? item.transporter?.legalPerson?.fantasyName ?? ''
                            : item.transporter?.naturalPerson?.name ?? ''
                        : '',
                    item.finalDestination != null
                        ? item.finalDestination?.naturalPerson?.name || item.finalDestination?.legalPerson?.companyName
                        : '',
                    item.transshipment != null
                        ? item.transshipment.fantasyName
                        : '',
                    this.getMtrStatusStyle(item.status),
                    this.getIntegrationMtrStatusStyle(item.integrationMtrStatus)
                ]);
            })
            :
            content.map((item: IMtr) => {
                result.push([
                    item.collectionDate != null ? moment(item.collectionDate).format('DD/MM/YYYY') : '',
                    this.renderMtrNumber(item),
                    this.props.hasTransporterAuthorities
                        ? item.producer != null
                            ? item.producer?.naturalPerson?.name ?? item.producer?.legalPerson?.companyName
                            : '' || item.parDescricaoGerador
                        : item.transporter != null
                            ? item.transporter.personType === PersonType.LEGAL
                                ? item?.transporter?.legalPerson?.fantasyName
                                : item?.transporter?.naturalPerson?.name ?? ''
                            : '' || item.parDescricaoTransportador,
                    this.props.hasFinalDestinationAuthorities
                        ? item.producer != null
                            ? item.producer?.naturalPerson?.name ?? item.producer?.legalPerson?.companyName
                            : '' || item.parDescricaoGerador
                        : item.finalDestination != null
                            ? item.finalDestination?.naturalPerson?.name || item.finalDestination?.legalPerson?.companyName
                            : '' || item.parDescricaoDestino,
                    this.props.hasTransshipmentAuthorities
                        ? item.producer != null
                            ? item.producer?.legalPerson?.companyName ?? item.producer?.naturalPerson?.name
                            : ''
                        : item.transshipment != null
                            ? item.transshipment.companyName
                            : '',
                    this.getMtrStatusStyle(item.status),
                    this.getIntegrationMtrStatusStyle(item.integrationMtrStatus),
                ]);
            });

        return result;
    };

    getMtrStatusStyle = (mtrStatus: MtrStatus) => {
        if (mtrStatus == null) {
            return <div />;
        }
        let style = 'status-tag ';
        switch (mtrStatus) {
            case MtrStatus.COMPLETE:
                style = style.concat('complete');
                break;
            case MtrStatus.INCOMPLETE:
                style = style.concat('incomplete');
                break;
            case MtrStatus.CANCELLED:
                style = style.concat('cancelled');
                break;
            case MtrStatus.PENDING:
                style = style.concat('pending');
                break;
        }

        return (
            <div className={style}>
                <span>{translate(`mtr-list.status.${mtrStatus}`).toUpperCase()}</span>
            </div>
        );
    };

    renderMtrNumber = (mtr: IMtr) => {
        if (mtr?.numberMtr == null || mtr?.linkIntegration == null) {
            return mtr?.numberMtr ?? '';
        }

        return (
            <a href={mtr.linkIntegration} target="_blank" rel="noopener noreferrer">
                <span style={{ color: 'black', fontWeight: 300, textDecoration: 'underline' }}>
                    {mtr?.numberMtr}
                </span>
            </a>
        );
    };

    getIntegrationMtrStatusStyle = (status: IntegrationMtrStatus) => {
        if (status == null) {
            return <div />;
        }
        let style = 'status-tag ';
        switch (status) {
            case IntegrationMtrStatus.FINISHED_ALL:
            case IntegrationMtrStatus.FINISHED_MTR:
                style = style.concat('complete');
                break;
            case IntegrationMtrStatus.INCOMPLETE_TO_INTEGRATION:
            case IntegrationMtrStatus.DENIED:
                style = style.concat('incomplete');
                break;
            case IntegrationMtrStatus.ERROR:
                style = style.concat('cancelled');
                break;
            case IntegrationMtrStatus.IN_PROGRESS:
            case IntegrationMtrStatus.WAITING:
                style = style.concat('pending');
                break;
        }

        return (
            <div className={style}>
                <span>{translate(`mtr-list.integrationStatus.${status}`).toUpperCase()}</span>
            </div>
        );
    };

    private handleSort = (code: string) => {
        this.setState(
            {
                page: { ...this.state.page, sort: code },
            },
            () => this.getMtrs()
        );
    };

    onClickMtr = (id: number) => {
        this.props.history.push({
            pathname: `${this.props.match.url}/manage`,
            state: { mtrId: id },
        });
        // this.props.findMtr(
    };

    redirectToCreateMtrReport = () => {
        this.props.history.push(`${this.props.match.url}-report/create`);
    };
    redirectToCreateMtrReceipt = () => {
        this.props.history.push(`${this.props.match.url}-receipt/create`);
    };
    redirectToSignatureAll = () => {
        this.props.history.push(`${this.props.match.url}-signature/all`);
    }

    render() {
        const { mtrs, totalItems } = this.props;
        const { hasItems, isModalOpen } = this.state;
        const rows = this.handleTransformToTableContent(mtrs);
        return (
            <div className="mtr-list__items">
                <Container>
                    <Row>
                        <Col style={{ margin: 'auto' }} md="12">
                            <h1 style={{ textAlign: 'left', marginTop: '28px' }}>{translate('mtr-list.title')}</h1>
                        </Col>
                    </Row>
                    <Row className="no-wrap-row">
                        <Col md="5" className="mtr-list__search-container">
                            <div className="mtr-list__search">
                                <div className="form-inline md-form">
                                    <SimpleSearchField
                                        value={this.state.searchFilter}
                                        placeholder={translate('mtr-report.searchMtr')}
                                        onChange={this.onHandleFilterChanged}
                                        notimer
                                    />
                                    <div className="mtr-list__search--icon" />
                                </div>
                            </div>

                            <div className="mtr-list__items--filter" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                                <div className="mtr-list__items--filter-img" />
                                <span>{translate('mtr-list.filter')}</span>
                            </div>
                        </Col>
                        <Col md="7">
                            <div className={'generate-csv-wrapper'}>
                                <Button className={'button primary'} type={'submit'} onClick={this.redirectToCreateMtrReport}>
                                    <Translate contentKey={'mtr-list.generateCsv'} />
                                </Button>
                                {!this.props.hasManagerAuthorities && !this.props.hasTransshipmentAuthorities && (
                                    <Button className={'button primary signature-btn'} type={'submit'} onClick={this.redirectToSignatureAll}>
                                        <Translate contentKey={'mtr-list.signaturelAll'} />
                                    </Button>
                                )}
                                {!this.props.hasFinalDestinationAuthorities && !this.props.hasTransshipmentAuthorities && (
                                    <Button style={{ marginRight: '10px' }} className={'button primary'} type={'submit'} onClick={this.onHandleCreateMtrClick}>
                                        <Translate contentKey={'mtr-list.button'} />
                                    </Button>
                                )}
                                <Button className={'button primary'} type={'submit'} onClick={this.handleOpenModal}>
                                    <Translate contentKey={'mtr-list.mtr-integration'} />
                                </Button>
                                {/* {!this.props.hasManagerAuthorities && !this.props.hasTransshipmentAuthorities && !this.props.hasProducerAuthority && !this.props.hasTransporterAuthorities && (
                                    <Button className={'button primary'} type={'submit'} onClick={this.redirectToCreateMtrReceipt}>
                                        <Translate contentKey={'mtr-list.receiptMTR'} />
                                    </Button>
                                )} */}

                            </div>
                        </Col>
                    </Row>
                    {this.state.showFilter && this.renderMtrFilter()}
                    <Row>
                        <Col>
                            {hasItems ? (
                                <SimpleOrderedTable
                                    rows={rows}
                                    columNameKeys={this.props.hasManagerAuthorities ? this.managerTableHeaders : this.tableHeaders}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onClick={index => this.onClickMtr(mtrs[index].id)}
                                    onSort={code => this.handleSort(code)}
                                    hideLongText={true}
                                />
                            ) : (
                                <div style={{ position: 'relative', height: '30vh' }}>
                                    <Loading />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
                <ImportMtrModal
                    isOpen={isModalOpen}
                    onClose={this.handleCloseModal}
                    totalItems={totalItems}
                >
                    <h2>Importar Mtrs</h2>
                    <p>Coloque a Data inicial e a Data Final que deseja para iniciar a importação das MTRS do órgão Ambiental:</p>
                    <p style={{ color: 'red' }}>*Importação tem um período máximo de 1 mês!</p>
                </ImportMtrModal>
            </div>
        );
    }
}

const mapStateToProps = (root: IRootState) => ({
    mtrs: root.mtr.mtrs,
    mtr: root.mtr.mtr,
    mtrss: root.mtr.mtrs,
    csv: root.mtr.csv,
    getMtrStatus: root.mtr.getMtr,
    totalItems: root.mtr.totalItems,
    integrationUsers: root.integrationUser.integrationUsers,
    hasTransporterAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSPORTER_ADMIN,
        AUTHORITIES.TRANSPORTER_USER,
    ]),
    hasFinalDestinationAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.FINAL_DESTINATION_ADMIN,
        AUTHORITIES.FINAL_DESTINATION_USER,
    ]),
    hasTransshipmentAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSSHIPMENT_ADMIN,
        AUTHORITIES.TRANSSHIPMENT_USER,
    ]),
    hasManagerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
    hasProducerAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]),
    fetchManagerProducersStatus: root.managerlink.fetchManagerProducersStatus,
    managerProducers: root.managerlink.managerProducers,
});

const mapDispatchToProps = {
    getMtrs,
    findMtr,
    reset,
    resetStatus,
    getAllManagerProducers,
    resetLinkedProducers,
    getIntegrationUsers
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MtrList);

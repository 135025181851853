import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './final-destination-register-company.scss';
import { translate } from 'react-jhipster';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import { FinalDestinationCategory, IFinalDestination } from 'app/shared/model/final-destination.model';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { Col, Row } from 'reactstrap';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import {
    validateCNPJ,
    validateGenericField,
    validateObject,
    validatePhoneNumber
} from 'app/modules/validation/validation-constants';
import InlineSelect from 'app/components/inline-select/inline-select';
import { maskCnpj, maskPhone, unmask } from 'app/shared/util/mask-utils';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface IFinalDestinationRegisterCompanyProps extends StateProps, DispatchProps {
    userFinalDestination: IUserFinalDestination;
    onNext: (userFinalDestination: IUserFinalDestination) => void;
    onBack: () => void;
}

const FinalDestinationRegisterCompany = (props: IFinalDestinationRegisterCompanyProps) => {

    const [cnpj, setCnpj] = useState('');
    const [fantasyName, setFantasyName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [finalDestinationCategory, setFinalDestinationCategory] = useState(null as FinalDestinationCategory);

    const [cnpjError, setCnpjError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [companyNameError, setCompanyNameError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [fantasyNameError, setFantasyNameError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [finalDestinationCategoryError, setFinalDestinationCategoryError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [phoneError, setPhoneError] = useState<ValidationResult | undefined>(ValidationResultInstance);

    useEffect(() => {
        const { finalDestination } = props.userFinalDestination;

        setCnpj(finalDestination?.cnpj ?? null);
        setFantasyName(finalDestination?.fantasyName ?? null);
        setCompanyName(finalDestination?.companyName ?? null);
        setPhone(finalDestination?.phone ?? null);
        setFinalDestinationCategory(finalDestination?.finalDestinationCategory ?? null);
    }, [])

    const onValidateAll = () => {
        let hasError = false;
        if (onValidateCnpj()) {
            hasError = true;
        }
        if (onValidateFantasyName()) {
            hasError = true;
        }
        if (onValidateCompanyName()) {
            hasError = true;
        }
        if (onValidatePhone()) {
            hasError = true;
        }
        if (onValidateCategory()) {
            hasError = true;
        }
        return hasError;
    };

    const onValidateCnpj = () => {
        const validate = validateCNPJ(cnpj);
        setCnpjError(validate);
        return validate.hasError;
    };

    const onValidateFantasyName = () => {
        const validate = validateGenericField(fantasyName);
        setFantasyNameError(validate);
        return validate.hasError;
    };

    const onValidateCompanyName = () => {
        const validate = validateGenericField(companyName);
        setCompanyNameError(validate);
        return validate.hasError;
    };

    const onValidatePhone = () => {
        const validate = validatePhoneNumber(phone);
        setPhoneError(validate);
        return validate.hasError;
    };

    const onValidateCategory = (item) => {
        const validate = validateObject(item ?? finalDestinationCategory);
        console.log({ validate, finalDestinationCategory })
        setFinalDestinationCategoryError(validate);
        return validate.hasError;
    };

    const mapFinalDestinationCategoryToOptions = () => {
        const optionsList = [];
        Object.keys(FinalDestinationCategory).forEach((item, index) => {
            optionsList.push({ label: translate(`enums.finalDestinationCategory.${item}`), value: item });
        });
        return optionsList;
    };

    const mapSelectedCategoryToSelect = () => {
        return finalDestinationCategory
            ? { label: translate(`enums.finalDestinationCategory.${finalDestinationCategory}`), value: finalDestinationCategory }
            : null;
    };

    const onSelectFinalDestinationCategory = (item: any) => {
        setFinalDestinationCategory(item);
        onValidateCategory(item);
    };

    const onNext = () => {
        if (onValidateAll()) {
            return;
        }
        const userFinalDestination = { ...props.userFinalDestination };
        const finalDestination: IFinalDestination = {
            ...userFinalDestination.finalDestination,
            legalPerson: {
                cnpj: unmask(cnpj),
                fantasyName,
                companyName,
                phone: unmask(phone),
            },
            finalDestinationCategory,
        };

        userFinalDestination.finalDestination = finalDestination;
        props.onNext(userFinalDestination);
    };

    return (
        <div>
            <h1>{translate('finalDestinationRegister.company.register-title')}</h1>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <CustomTextField
                        onChange={(text: string) =>
                            setCnpj(text)
                        }
                        id="cnpj"
                        style={{ marginTop: '20px' }}
                        value={cnpj}
                        onMask={maskCnpj}
                        label={translate('producerRegister.company.holding.form.cnpj')}
                        placeholder={translate('producerRegister.company.holding.form.cnpj')}
                        error={cnpjError.hasError}
                        errorText={translateErrorMessage(cnpjError.errorMessage)}
                        onBlur={onValidateCnpj}
                    />
                    <CustomTextField
                        onChange={(text: string) =>
                            setFantasyName(text)
                        }
                        id="fantasyName"
                        style={{ marginTop: '20px' }}
                        value={fantasyName}
                        label={translate('producerRegister.company.holding.form.fantasyName')}
                        placeholder={translate('producerRegister.company.holding.form.fantasyName')}
                        error={fantasyNameError.hasError}
                        errorText={translateErrorMessage(fantasyNameError.errorMessage)}
                        onBlur={onValidateFantasyName}
                    />
                    <CustomTextField
                        onChange={(text: string) =>
                            setCompanyName(text)
                        }
                        id="companyName"
                        style={{ marginTop: '20px' }}
                        value={companyName}
                        label={translate('producerRegister.company.subsidiary.form.companyName')}
                        placeholder={translate('producerRegister.company.subsidiary.form.companyName')}
                        error={companyNameError.hasError}
                        errorText={translateErrorMessage(companyNameError.errorMessage)}
                        onBlur={onValidateCompanyName}
                    />
                    <CustomTextField
                        onChange={(text: string) =>
                            setPhone(text)
                        }
                        onMask={maskPhone}
                        id="phone"
                        style={{ marginTop: '20px' }}
                        value={phone}
                        label={translate('producerRegister.company.holding.form.phone')}
                        placeholder={translate('producerRegister.company.holding.form.phone')}
                        error={phoneError.hasError}
                        errorText={translateErrorMessage(phoneError.errorMessage)}
                        onBlur={onValidatePhone}
                    />
                    <InlineSelect
                        placeholder={translate('finalDestinationRegister.company.form.selectCategory')}
                        error={finalDestinationCategoryError.hasError}
                        errorMessage={translateErrorMessage(finalDestinationCategoryError.errorMessage)}
                        options={mapFinalDestinationCategoryToOptions()}
                        onChange={onSelectFinalDestinationCategory}
                        startIndex={0}
                        selectedOption={mapSelectedCategoryToSelect()}
                    />
                    <NextCancelButton onCancelClick={props.onBack} onNextClick={onNext} />
                </Col>
            </Row>
        </div>
    );
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationRegisterCompany);

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './register-init.scss';
import { Translate, translate } from 'react-jhipster';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';

export interface IRegisterInitProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

const RegisterInit = (props: IRegisterInitProps) => {
  const onButtonClick = () => {
    const { match } = props;
    props.history.push(`${match.url}/choose`);
  };

  return (
    <div className="profile__container container">
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h1 style={{ fontWeight: 550 }}> {translate('global.helloMessage', { name: props.account.name })}</h1>
            <div className="profile__container--title">
              {' '}
              <Translate contentKey={'register.profile.title'} />{' '}
            </div>
            <h2 style={{ marginBottom: '40px' }}>
              {' '}
              <Translate contentKey={'register.profile.subtitle'} />{' '}
            </h2>
            <button style={{ margin: 'auto' }} className={'button primary'} type={'submit'} onClick={() => onButtonClick()}>
              <Translate contentKey={'register.profile.button'} />
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterInit);

import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import StringUtils from 'app/shared/util/string-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';

export const ACTION_TYPES = {
  CREATE_SUBSIDIARY_BY_HOLDING: 'userProducer/CREATE_SUBSIDIARY_BY_HOLDING',
  UPDATE_HOLDING: 'userProducer/UPDATE_HOLDING',
  UPDATE_SUBSIDIARY: 'userProducer/UPDATE_SUBSIDIARY',
  GET_HOLDING_SUBSIDIARIES: 'userProducer/GET_HOLDING_SUBSIDIARIES',
  RESET: 'userProducer/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  createSubsidiaryStatus: HttpRequestStatus.NOOP,
  updateHoldingStatus: HttpRequestStatus.NOOP,
  updateSubsidiaryStatus: HttpRequestStatus.NOOP,
  getHoldingSubidiariesStatus: HttpRequestStatus.NOOP,
  subsidiaries: [],
  totalItems: 0,
};

export type FinalDestinationUnitsState = Readonly<typeof initialState>;

// Reducer

export default (state: FinalDestinationUnitsState = initialState, action): FinalDestinationUnitsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING):
      return {
        ...state,
        errorMessage: null,
        createSubsidiaryStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING):
      return {
        ...state,
        createSubsidiaryStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING):
      return {
        ...state,
        createSubsidiaryStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        errorMessage: null,
        updateHoldingStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        updateHoldingStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        updateHoldingStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        errorMessage: null,
        updateSubsidiaryStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        updateSubsidiaryStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        updateSubsidiaryStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_HOLDING_SUBSIDIARIES):
      return {
        ...state,
        errorMessage: null,
        getHoldingSubidiariesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_HOLDING_SUBSIDIARIES):
      return {
        ...state,
        getHoldingSubidiariesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_HOLDING_SUBSIDIARIES):
      return {
        ...state,
        getHoldingSubidiariesStatus: HttpRequestStatus.SUCCESS,
        subsidiaries: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-final-destinations';

// Actions

export const getSubsidiariesByHolding: ICrudSearchActionCustom<IUserFinalDestination> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}/subsidiaries${params}`;
  return {
    type: ACTION_TYPES.GET_HOLDING_SUBSIDIARIES,
    payload: axios.get<IUserFinalDestination>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const createSubidiaryByHolding: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/holding/subsidiary`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateSubsidiary: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/subsidiary`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SUBSIDIARY,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateHolding: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/holding`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOLDING,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

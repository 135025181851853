import { ALL_WEB_AUTHORITIES, AUTHORITIES } from 'app/config/constants';
import Entities from 'app/entities';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import FullRegister from 'app/modules/account/register/full-register/index';
import Register from 'app/modules/account/register/register';
import Home from 'app/modules/home/home';
import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import React from 'react';
import Loadable from 'react-loadable';
import { Switch } from 'react-router-dom';
import ChangePasswordPage from './modules/account/activate/change-password/change-password';
import AlertPage from './modules/alert/alert-page';
import FinalDestination from './modules/final-destination/index';
import AccountRoutes from './modules/generic/profile/index';
import MtrAuthorizationName from './modules/login/generator/authorization-name/authorization-name';
import MtrAuthorizationRegister from './modules/login/generator/authorization-register/authorization-register';
import MtrAuthorization from './modules/login/generator/authorization/mtr-authorization';
import MtrInviteEmpty from './modules/login/generator/invite-empty/mtr-invite-empty';
import MtrInvite from './modules/login/generator/invite/mtr-invite';
import MtrList from './modules/login/generator/mtr-list/mtr-list';
import Units from './modules/login/generator/mtr-units/'
import MtrUnity from './modules/login/generator/unity/mtr-unity';
import MtrWaste from './modules/login/generator/waste/mtr-waste';
import MtrAuthorizationSecond from './modules/login/manager/authorization-second/authorization-second';
import MtrManagerList from './modules/login/manager/list/list';
import MtrSignatureGenerator from './modules/login/mtr-signature/generator/mtr-signature-generator';
import MtrSignatureGeneratorCollapse from './modules/login/mtr-signature/generator/mtr-signature-generator-collapse';
import MtrSignatureGeneratorEdit from './modules/login/mtr-signature/generator/mtr-signature-generator-edit';
import MtrSignature from './modules/login/mtr-signature/mtr-signature';
import MtrDigitalSignature from './modules/login/profile/digital-signature';
import MtrDigitalSignatureSecond from './modules/login/profile/digital-signature-second';
import { MtrManageUsers } from './modules/login/profile/manage-users/manage-users';
import MtrManageNewUser from './modules/login/profile/manage-users/new-user';
import MtrPersonalData from './modules/login/profile/personal-data';
import MtrDriverInfo from './modules/login/transporter/driver/driver-info';
import MtrInviteSecond from './modules/login/transporter/invite/invite-second';
import MtrLicenseInfo from './modules/login/transporter/license/license-info';
import MtrLicenseName from './modules/login/transporter/license/license-name';
import MtrAddResidues from './modules/login/transporter/residues/add-residues/mtr-add-residues';
import MtrResidues from './modules/login/transporter/residues/mtr-residues';
import MtrVehicleInfo from './modules/login/transporter/transporter/vehicle-info';
import Manager from './modules/manager/index';
import Producer from './modules/producer/index';
import Transporter from './modules/transporter/index';
import Transshipment from './modules/transshipment/index';
import { HeaderType } from './shared/layout/header/builder/header-builder';
import Footer from './shared/layout/footer/footer';
import { Error } from '@material-ui/icons';
import refusePage from './modules/refuse/refuse-page';
import { InviteAlertPage } from './modules/inviteAlert/invite-alert-page';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => (
  <div className="view-routes">
    <Switch>
      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute exact path="/alert-page" component={AlertPage} />
      <ErrorBoundaryRoute exact path="/refuse-page" component={refusePage} />
      <ErrorBoundaryRoute exact path="/invite-alert-page" component={InviteAlertPage} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/register" component={Register} />
      <PrivateRoute path="/mtr-signature" component={MtrSignature} />
      <PrivateRoute path="/mtr-units" component={Units} />
      <PrivateRoute path="/mtr-unity" component={MtrUnity} />
      <PrivateRoute path="/mtr-list" component={MtrList} />
      <PrivateRoute path="/mtr-waste" component={MtrWaste} />
      <PrivateRoute path="/mtr-authorization" component={MtrAuthorization} />
      <PrivateRoute path="/mtr-invite" component={MtrInvite} />
      <PrivateRoute path="/mtr-invite-empty" component={MtrInviteEmpty} />
      <PrivateRoute path="/mtr-driver-info" component={MtrDriverInfo} />
      <PrivateRoute path="/mtr-vehicle-info" component={MtrVehicleInfo} />
      <PrivateRoute path="/mtr-license-info" component={MtrLicenseInfo} />
      <PrivateRoute path="/mtr-license-name" component={MtrLicenseName} />
      <PrivateRoute path="/mtr-authorization-name" component={MtrAuthorizationName} />
      <PrivateRoute path="/mtr-authorization-second" component={MtrAuthorizationSecond} />
      <PrivateRoute path="/mtr-invite-second" component={MtrInviteSecond} />
      <PrivateRoute path="/mtr-residues" component={MtrResidues} />
      <PrivateRoute path="/mtr-add-residues" component={MtrAddResidues} />
      <PrivateRoute path="/mtr-authorization-register" component={MtrAuthorizationRegister} />
      <PrivateRoute path="/mtr-manager-list" component={MtrManagerList} />
      <PrivateRoute path="/mtr-personal-data" component={MtrPersonalData} />
      <PrivateRoute path="/mtr-digital-signature" component={MtrDigitalSignature} />
      <PrivateRoute path="/mtr-digital-signature-second" component={MtrDigitalSignatureSecond} />
      <PrivateRoute path="/mtr-manage-new-user" component={MtrManageNewUser} />
      <PrivateRoute path="/mtr-manage-users" component={MtrManageUsers} />
      <PrivateRoute path="/mtr-signature-generator" component={MtrSignatureGenerator} />
      <PrivateRoute path="/mtr-signature-generator-edit" component={MtrSignatureGeneratorEdit} />
      <PrivateRoute path="/mtr-signature-generator-collapse" component={MtrSignatureGeneratorCollapse} />
      <ErrorBoundaryRoute path="/reset/request" component={PasswordResetInit} />
      <ErrorBoundaryRoute path="/reset/finish/:key?" component={PasswordResetFinish} />
      <ErrorBoundaryRoute path="/activate/:key?" component={ChangePasswordPage} />
      <PrivateRoute path="/producer" component={Producer} hasAnyAuthorities={[AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]} />
      <PrivateRoute path="/manager" component={Manager} hasAnyAuthorities={[AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]} />
      <PrivateRoute
        path="/final-destination"
        component={FinalDestination}
        hasAnyAuthorities={[AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER]}
      />
      <PrivateRoute
        path="/transporter"
        component={Transporter}
        hasAnyAuthorities={[AUTHORITIES.TRANSPORTER_ADMIN, AUTHORITIES.TRANSPORTER_USER]}
      />
      <PrivateRoute
        path="/transshipment"
        component={Transshipment}
        hasAnyAuthorities={[AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER]}
      />
      <PrivateRoute path="/full-register" component={FullRegister} hasAnyAuthorities={[AUTHORITIES.USER]} headerType={HeaderType.SIMPLE} />
      <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute path="/me" component={AccountRoutes} hasAnyAuthorities={ALL_WEB_AUTHORITIES} />
      <ErrorBoundaryRoute path="/" exact component={Home} headerType={HeaderType.SIMPLE} />
      <PrivateRoute path="/entity" component={Entities} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
    <Footer />
  </div>
);

export default Routes;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { getEntities } from './user-manager.reducer';

export interface IUserManagerProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class UserManager extends React.Component<IUserManagerProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { userManagerList, match } = this.props;
    return (
      <div>
        <h2 id="user-manager-heading">
          <Translate contentKey="resitrackApp.userManager.home.title">User Managers</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="resitrackApp.userManager.home.createLabel">Create new User Manager</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {userManagerList && userManagerList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userManager.registrationNumber">Registration Number</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userManager.classCouncil">Class Council</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userManager.user">User</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userManager.manager">Manager</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {userManagerList.map((userManager, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${userManager.id}`} color="link" size="sm">
                        {userManager.id}
                      </Button>
                    </td>
                    <td>{userManager.registrationNumber}</td>
                    <td>
                      <Translate contentKey={`resitrackApp.ClassCouncil.${userManager.classCouncil}`} />
                    </td>
                    <td>{userManager.user ? userManager.user.login : ''}</td>
                    <td>{userManager.manager ? <Link to={`manager/${userManager.manager.id}`}>{userManager.manager.id}</Link> : ''}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${userManager.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${userManager.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${userManager.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="resitrackApp.userManager.home.notFound">No User Managers found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userManager }: IRootState) => ({
  userManagerList: userManager.entities,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserManager);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../content/images/icons/empty-box.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mtr-invite-empty__items {\n  background: #fafafa;\n}\n.mtr-invite-empty__items--link a {\n  font-size: 10px;\n  color: #30ab64;\n  font-weight: 300;\n  text-decoration: underline;\n  display: flex;\n  justify-content: flex-end;\n}\n.mtr-invite-empty__items--empty-box {\n  height: 147px;\n  background: #f5f5f5;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.mtr-invite-empty__items--empty-box .mtr-invite-empty__items--empty-img {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-top: -8px;\n}\n.mtr-invite-empty__items--empty-box .mtr-invite-empty__items--empty-text {\n  font-size: 1rem;\n  color: #a4a4a4;\n  margin-top: -8px;\n}\n.mtr-invite-empty__items .button.primary {\n  border-radius: 6px;\n  margin-right: 15px;\n}", ""]);
// Exports
module.exports = exports;

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import {Switch} from 'react-router-dom';
import FinalDestination from './final-destination';
import FinalDestinationDeleteDialog from './final-destination-delete-dialog';
import FinalDestinationDetail from './final-destination-detail';
import FinalDestinationUpdate from './final-destination-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={FinalDestinationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={FinalDestinationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={FinalDestinationDetail} />
      <ErrorBoundaryRoute path={match.url} component={FinalDestination} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={FinalDestinationDeleteDialog} />
  </>
);

export default Routes;

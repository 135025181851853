import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import StringUtils from 'app/shared/util/string-utils';
import { defaultValue as defaultEnvironmental, IEnvironmentalLicense } from 'app/shared/model/environmental-license.model';
import { defaultValue as defaultStorage, StorageLicense } from 'app/shared/model/storage-license.model';
import { defaultValue as defaultTransport, ITransportLicense } from 'app/shared/model/transport-license.model';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';

export const ACTION_TYPES = {
  CREATE_ENVIRONMENTAL_LICENSE: 'userProducer/CREATE_ENVIRONMENTAL_LICENSE',
  UPDATE_ENVIRONMENTAL_LICENSE: 'userProducer/UPDATE_ENVIRONMENTAL_LICENSE',
  DELETE_ENVIRONMENTAL_LICENSE: 'userProducer/DELETE_ENVIRONMENTAL_LICENSE',
  GET_FINAL_DESTINATION_ENVIRONMENTAL_LICENSES: 'userProducer/GET_FINAL_DESTINATION_ENVIRONMENTAL_LICENSES',
  GET_ENVIRONMENTAL_LICENSE_DETAIL: 'userProducer/GET_ENVIRONMENTAL_LICENSE_DETAIL',
  CREATE_TRANSPORT_LICENSE: 'userProducer/CREATE_TRANSPORT_LICENSE',
  UPDATE_TRANSPORT_LICENSE: 'userProducer/UPDATE_TRANSPORT_LICENSE',
  DELETE_TRANSPORT_LICENSE: 'userProducer/DELETE_TRANSPORT_LICENSE',
  GET_TRANSPORTER_TRANSPORT_LICENSES: 'userProducer/GET_TRANSPORTER_TRANSPORT_LICENSES',
  GET_TRANSPORT_LICENSE_DETAIL: 'userProducer/GET_TRANSPORT_LICENSE_DETAIL',

  CREATE_STORAGE_LICENSE: 'userProducer/CREATE_STORAGE_LICENSE',
  UPDATE_STORAGE_LICENSE: 'userProducer/UPDATE_STORAGE_LICENSE',
  DELETE_STORAGE_LICENSE: 'userProducer/DELETE_STORAGE_LICENSE',
  GET_TRANSSHIPMENT_STORAGE_LICENSES: 'userProducer/GET_TRANSSHIPMENT_STORAGE_LICENSES',
  GET_STORAGE_LICENSE_DETAIL: 'userProducer/GET_STORAGE_LICENSE_DETAIL',
  RESET: 'userProducer/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  createEnvironmentalLicenseStatus: HttpRequestStatus.NOOP,
  updateEnvironmentalLicenseStatus: HttpRequestStatus.NOOP,
  deleteEnvironmentalLicenseStatus: HttpRequestStatus.NOOP,
  getFinalDestinationLicensesStatus: HttpRequestStatus.NOOP,
  getEnvironmentalLicenseDetailStatus: HttpRequestStatus.NOOP,
  environmentalLicenses: [],
  environmentalLicenseDetail: defaultEnvironmental,
  createTransportLicenseStatus: HttpRequestStatus.NOOP,
  updateTransportLicenseStatus: HttpRequestStatus.NOOP,
  getTransporterLicensesStatus: HttpRequestStatus.NOOP,
  getTransportLicenseDetailStatus: HttpRequestStatus.NOOP,
  deleteTransportLicenseStatus: HttpRequestStatus.NOOP,
  transportLicenses: [],
  transportLicenseDetail: defaultTransport,

  createStorageLicenseStatus: HttpRequestStatus.NOOP,
  updateStorageLicenseStatus: HttpRequestStatus.NOOP,
  deleteStorageLicenseStatus: HttpRequestStatus.NOOP,
  getTransshipmentLicensesStatus: HttpRequestStatus.NOOP,
  getTransshipmentLicenseDetailStatus: HttpRequestStatus.NOOP,
  getStorageLicenseDetailStatus: HttpRequestStatus.NOOP,
  storageLicenses: [],
  storageLicenseDetail: defaultStorage,
  totalItems: 0,
};

export type LicensesState = Readonly<typeof initialState>;

// Reducer

export default (state: LicensesState = initialState, action): LicensesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        errorMessage: null,
        createEnvironmentalLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        createEnvironmentalLicenseStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        createEnvironmentalLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        errorMessage: null,
        updateEnvironmentalLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        updateEnvironmentalLicenseStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        updateEnvironmentalLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.DELETE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        errorMessage: null,
        deleteEnvironmentalLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.DELETE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        deleteEnvironmentalLicenseStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ENVIRONMENTAL_LICENSE):
      return {
        ...state,
        deleteEnvironmentalLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_FINAL_DESTINATION_ENVIRONMENTAL_LICENSES):
      return {
        ...state,
        errorMessage: null,
        getFinalDestinationLicensesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_FINAL_DESTINATION_ENVIRONMENTAL_LICENSES):
      return {
        ...state,
        getFinalDestinationLicensesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_FINAL_DESTINATION_ENVIRONMENTAL_LICENSES):
      return {
        ...state,
        getFinalDestinationLicensesStatus: HttpRequestStatus.SUCCESS,
        environmentalLicenses: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.GET_ENVIRONMENTAL_LICENSE_DETAIL):
      return {
        ...state,
        errorMessage: null,
        getEnvironmentalLicenseDetailStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_ENVIRONMENTAL_LICENSE_DETAIL):
      return {
        ...state,
        getEnvironmentalLicenseDetailStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_ENVIRONMENTAL_LICENSE_DETAIL):
      return {
        ...state,
        getEnvironmentalLicenseDetailStatus: HttpRequestStatus.SUCCESS,
        environmentalLicenseDetail: action.payload.data,
      };

    //** TRANSPORT **/

    case REQUEST(ACTION_TYPES.CREATE_TRANSPORT_LICENSE):
      return {
        ...state,
        errorMessage: null,
        createTransportLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_TRANSPORT_LICENSE):
      return {
        ...state,
        createTransportLicenseStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_TRANSPORT_LICENSE):
      return {
        ...state,
        createTransportLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_TRANSPORT_LICENSE):
      return {
        ...state,
        errorMessage: null,
        updateTransportLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_TRANSPORT_LICENSE):
      return {
        ...state,
        updateTransportLicenseStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_TRANSPORT_LICENSE):
      return {
        ...state,
        updateTransportLicenseStatus: HttpRequestStatus.SUCCESS,
      };

    case REQUEST(ACTION_TYPES.DELETE_TRANSPORT_LICENSE):
      return {
        ...state,
        errorMessage: null,
        deleteTransportLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.DELETE_TRANSPORT_LICENSE):
      return {
        ...state,
        deleteTransportLicenseStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.DELETE_TRANSPORT_LICENSE):
      return {
        ...state,
        deleteTransportLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_TRANSPORTER_TRANSPORT_LICENSES):
      return {
        ...state,
        errorMessage: null,
        getTransporterLicensesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSPORTER_TRANSPORT_LICENSES):
      return {
        ...state,
        getTransporterLicensesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSPORTER_TRANSPORT_LICENSES):
      return {
        ...state,
        getTransporterLicensesStatus: HttpRequestStatus.SUCCESS,
        transportLicenses: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.GET_TRANSPORT_LICENSE_DETAIL):
      return {
        ...state,
        errorMessage: null,
        getTransportLicenseDetailStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSPORT_LICENSE_DETAIL):
      return {
        ...state,
        getTransportLicenseDetailStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSPORT_LICENSE_DETAIL):
      return {
        ...state,
        getTransportLicenseDetailStatus: HttpRequestStatus.SUCCESS,
        transportLicenseDetail: action.payload.data,
      };

    //** TRANSSHIMENT **/

    case REQUEST(ACTION_TYPES.CREATE_STORAGE_LICENSE):
      return {
        ...state,
        errorMessage: null,
        createStorageLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_STORAGE_LICENSE):
      return {
        ...state,
        createStorageLicenseStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_STORAGE_LICENSE):
      return {
        ...state,
        createStorageLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_STORAGE_LICENSE):
      return {
        ...state,
        errorMessage: null,
        updateStorageLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_STORAGE_LICENSE):
      return {
        ...state,
        updateStorageLicenseStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_STORAGE_LICENSE):
      return {
        ...state,
        updateStorageLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.DELETE_STORAGE_LICENSE):
      return {
        ...state,
        errorMessage: null,
        deleteStorageLicenseStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.DELETE_STORAGE_LICENSE):
      return {
        ...state,
        deleteStorageLicenseStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.DELETE_STORAGE_LICENSE):
      return {
        ...state,
        deleteStorageLicenseStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_TRANSSHIPMENT_STORAGE_LICENSES):
      return {
        ...state,
        errorMessage: null,
        getTransshipmentLicensesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSSHIPMENT_STORAGE_LICENSES):
      return {
        ...state,
        getTransshipmentLicensesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSSHIPMENT_STORAGE_LICENSES):
      return {
        ...state,
        getTransshipmentLicensesStatus: HttpRequestStatus.SUCCESS,
        storageLicenses: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.GET_STORAGE_LICENSE_DETAIL):
      return {
        ...state,
        errorMessage: null,
        getStorageLicenseDetailStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_STORAGE_LICENSE_DETAIL):
      return {
        ...state,
        getStorageLicenseDetailStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_STORAGE_LICENSE_DETAIL):
      return {
        ...state,
        getStorageLicenseDetailStatus: HttpRequestStatus.SUCCESS,
        storageLicenseDetail: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlEnvironmental = 'api/environmental-licenses';
const apiUrlTransport = 'api/transport-licenses';
const apiUrlStorage = 'api/storage-licenses';

// Actions

export const getEnvironmentalLicensesByFinalDestination: ICrudSearchActionCustom<IEnvironmentalLicense> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrlEnvironmental}/final-destination${params}`;
  return {
    type: ACTION_TYPES.GET_FINAL_DESTINATION_ENVIRONMENTAL_LICENSES,
    payload: axios.get<IEnvironmentalLicense>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const createEnvironmentalLicense: ICrudPutAction<IEnvironmentalLicense> = entity => async dispatch => {
  const requestUrl = `${apiUrlEnvironmental}/new`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ENVIRONMENTAL_LICENSE,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  dispatch(getEnvironmentalLicensesByFinalDestination());
  return result;
};

export const updateEnvironmentalLicense: ICrudPutAction<IEnvironmentalLicense> = entity => async dispatch => {
  const requestUrl = `${apiUrlEnvironmental}/update`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ENVIRONMENTAL_LICENSE,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getEnvironmentalLicensesByFinalDestination());
  return result;
};

export const deleteEnvironmentalLicense: ICrudDeleteAction<IEnvironmentalLicense> = id => async dispatch => {
  const requestUrl = `${apiUrlEnvironmental}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ENVIRONMENTAL_LICENSE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEnvironmentalLicensesByFinalDestination());
  return result;
};

export const getEnvironmentalLicenseDetail: ICrudGetAction<IEnvironmentalLicense> = environmentalLicenseId => {
  const requestUrl = `${apiUrlEnvironmental}/${environmentalLicenseId}`;
  return {
    type: ACTION_TYPES.GET_ENVIRONMENTAL_LICENSE_DETAIL,
    payload: axios.get<IEnvironmentalLicense>(`${requestUrl}`),
  };
};

//** Transport **/

export const getTransportLicensesByTransporter: ICrudSearchActionCustom<ITransportLicense> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrlTransport}/transporter${params}`;
  return {
    type: ACTION_TYPES.GET_TRANSPORTER_TRANSPORT_LICENSES,
    payload: axios.get<ITransportLicense>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const createTransportLicense: ICrudPutAction<ITransportLicense> = entity => async dispatch => {
  const requestUrl = `${apiUrlTransport}/new`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSPORT_LICENSE,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  dispatch(getTransportLicensesByTransporter());
  return result;
};

export const updateTransportLicense: ICrudPutAction<ITransportLicense> = entity => async dispatch => {
  const requestUrl = `${apiUrlTransport}/update`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TRANSPORT_LICENSE,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getTransportLicensesByTransporter());
  return result;
};

export const getTransportLicenseDetail: ICrudGetAction<ITransportLicense> = environmentalLicenseId => {
  const requestUrl = `${apiUrlTransport}/${environmentalLicenseId}`;
  return {
    type: ACTION_TYPES.GET_TRANSPORT_LICENSE_DETAIL,
    payload: axios.get<ITransportLicense>(`${requestUrl}`),
  };
};

export const deleteTransportLicense: ICrudDeleteAction<ITransportLicense> = id => async dispatch => {
  const requestUrl = `${apiUrlTransport}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TRANSPORT_LICENSE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getTransportLicensesByTransporter());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

//** STORAGE **/

export const getStorageLicensesByTransshipment: ICrudSearchActionCustom<StorageLicense> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrlStorage}/${params}`;
  return {
    type: ACTION_TYPES.GET_TRANSSHIPMENT_STORAGE_LICENSES,
    payload: axios.get<StorageLicense>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const createStorageLicense: ICrudPutAction<StorageLicense> = entity => async dispatch => {
  const requestUrl = `${apiUrlStorage}/current-transshipment`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_STORAGE_LICENSE,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  dispatch(getStorageLicensesByTransshipment());
  return result;
};

export const updateStorageLicense: ICrudPutAction<StorageLicense> = entity => async dispatch => {
  const requestUrl = `${apiUrlStorage}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_STORAGE_LICENSE,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getStorageLicensesByTransshipment());
  return result;
};

export const deleteStoragelLicense: ICrudDeleteAction<StorageLicense> = id => async dispatch => {
  const requestUrl = `${apiUrlStorage}/active/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_STORAGE_LICENSE,
    payload: axios.put(requestUrl),
  });
  dispatch(getStorageLicensesByTransshipment());
  return result;
};

export const getStorageLicenseDetail: ICrudGetAction<StorageLicense> = storageLicenseId => {
  const requestUrl = `${apiUrlStorage}/${storageLicenseId}`;
  return {
    type: ACTION_TYPES.GET_STORAGE_LICENSE_DETAIL,
    payload: axios.get<StorageLicense>(`${requestUrl}`),
  };
};

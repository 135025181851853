import React from 'react';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './mtr-list.scss';
import Search from './search';
import MtrFilterOpen from './mtr-filter-open';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import moment from 'moment';

export class MtrList extends React.Component {
  private tableHeaders: ColumnSort[] = [
    { name: 'Nome teste 1', sortCode: 'tag.name' },
    { name: 'Nome teste 2', sortCode: 'name' },
    { name: 'Nome teste 3', sortCode: 'updatedDate' },
    { sortDisabled: true },
  ];

  private handleTransformToTableContent = (): (string | JSX.Element)[][] => {
    const result: (string | JSX.Element)[][] = [];

    const content = [
      { tag: 'topper', name: 'topper', updatedDate: moment() },
      { tag: 'topper1', name: 'topper1', updatedDate: moment() },
      { tag: 'topper2', name: 'topper2', updatedDate: moment() },
      { tag: 'topper3', name: 'topper3', updatedDate: moment() },
      { tag: 'topper3', name: 'topper3', updatedDate: moment() },
    ];

    content.map(item => {
      result.push([
        item.tag != null ? item.tag ?? '' : '',
        item.name ?? '',
        item.updatedDate != null ? moment(item.updatedDate).format('DD/MM/YYYY[, ]hh:mm') : '',
        <div className="invoice-table--img">
          <div className="invoice-table--delete-img" />
          <div className="invoice-table--open-img" />
        </div>,
      ]);
    });

    return result;
  };

  render() {
    return (
      <div className="mtr-list__items">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px' }}>{translate('mtr-list.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col md="3">
              <Search />
            </Col>
            <Col md="6">
              <div className="mtr-list__items--filter">
                <div className="mtr-list__items--filter-img" />
                <span>{translate('mtr-list.filter')}</span>
              </div>
            </Col>
            <Col md={{ offset: '1', span: '2' }}>
              <Button className={'button primary'} type={'submit'}>
                <Translate contentKey={'mtr-list.button'} />
              </Button>
            </Col>
          </Row>
          <MtrFilterOpen />
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={[]}
                columNameKeys={this.tableHeaders}
                page={{ totalPages: 0, totalElements: 0 }}
                onChangePage={null}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MtrList;

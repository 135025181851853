import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import EnvironmentalLicense from './environmental-license';
import EnvironmentalLicenseDetail from './environmental-license-detail';
import EnvironmentalLicenseUpdate from './environmental-license-update';
import EnvironmentalLicenseDeleteDialog from './environmental-license-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EnvironmentalLicenseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EnvironmentalLicenseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EnvironmentalLicenseDetail} />
      <ErrorBoundaryRoute path={match.url} component={EnvironmentalLicense} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={EnvironmentalLicenseDeleteDialog} />
  </>
);

export default Routes;

import { Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import AlertModal from 'app/components/alert-modal/alert-modal';
import Loading from 'app/components/loading/loading';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { ALL_ADMIN_AUTHORITIES, AUTHORITIES } from 'app/config/constants';
import { addSourceResidues, getEntities as getResidues, reset } from 'app/entities/residue/residue-entities.reducer';
import ManagerProducerModal from 'app/modules/manager/residues/modal/manager-producer-modal';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { Pageable, SortType } from 'app/shared/model/pageable';
import { IProducer } from 'app/shared/model/producer.model';
import { IResidue } from 'app/shared/model/residue.model';
import { ISourceResidue, ResidueSourceType } from 'app/shared/model/source-residue.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/shared/util/string-utils';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './residues-add.scss';
import SpecificResidueSelect from './specific-residue-select/specific-residue-select';
import StaticTableBar, { StaticTableBarItem } from './static-table-bar/static-table-bar';
import { createSortParameter } from 'app/shared/util/resitrack-utils';

export interface IResidueAddProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IResidueAddState {
  filterName: string;
  selectedResidues: IResidue[];
  residueTypes: Map<string, string>;
  sourceResidues: ISourceResidue[];
  showSelectSpecific: boolean;
  showErrorModal: boolean;
  showSuccess: boolean;
  page: Pageable;
  showSelectProducerModal: boolean;
}

export class ResidueAdd extends React.Component<IResidueAddProps, IResidueAddState> {
  timer = null;
  private tableHeaders: StaticTableBarItem[] = [
    { name: translate('residue.headers.code'), width: '10%' },
    { name: translate('residue.headers.residueSubcategory'), width: '20%' },
    { name: translate('residue.headers.description'), width: '45%' },
    { name: translate('residue.headers.classification'), width: '15%' },
    //{ name: translate('residue.headers.measurementType'), width: '15%' },
    { name: translate('entity.action.select'), width: '10%' },
  ];

  constructor(props: Readonly<IResidueAddProps>) {
    super(props);
    this.state = {
      filterName: '',
      selectedResidues: [],
      showErrorModal: false,
      showSuccess: false,
      residueTypes: new Map<string, string>(),
      showSelectSpecific: false,
      sourceResidues: [],
      page: { totalElements: 0, size: 20, totalPages: 1, page: 0 },
      showSelectProducerModal: false,
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getResidues();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.totalItems != this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
    if (
      newProps.fetchResidueListStatus === HttpRequestStatus.SUCCESS &&
      newProps.residues != null &&
      newProps.residues != this.props.residues
    ) {
      this.setState({
        residueTypes: this.mapResidueTypes(newProps.residues),
      });
    }
    if (newProps.addSourceResidueStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
    }
    if (newProps.addSourceResidueStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  mapResidueTypes = (residues: IResidue[]) => {
    const listTypes = new Map<string, string>();
    residues.forEach((item, index) => {
      if (listTypes.get(item.code.split('.')[0]) == null) {
        listTypes.set(item.code.split('.')[0], item.residueType);
      }
    });

    return listTypes;
  };

  handleResidue = (residue: IResidue) => {
    const selectedResidues = [...this.state.selectedResidues];
    var selectedResidue = selectedResidues.filter(it => it.id === residue.id)[0];
    if (selectedResidue != null) {
      selectedResidues.splice(selectedResidues.indexOf(selectedResidue), 1);
    } else {
      selectedResidues.push(residue);
    }
    this.setState({
      selectedResidues,
    });
  };

  getResidues = () => {
    const { page } = this.state;
    const sort = this.handleSortParameters(page);
    this.props.getResidues(
      page.page,
      page.size,
      sort,
      !StringUtils.isStringInvalid(this.state.filterName) ? this.state.filterName : null
    );
  };

  handleSortParameters = (page: Pageable) => {
    if (!page.sort) {
      return `code,${SortType.ASC}`;
    }

    let sortWithDefaultParameters = page.sort;

    if (!sortWithDefaultParameters.includes('code')) {
      sortWithDefaultParameters += `,code,${SortType.ASC}`;
    }

    return sortWithDefaultParameters;
  };


  private handleTransformToTableContent = (content?: IResidue): (string | JSX.Element)[][] => {
    const { selectedResidues } = this.state;

    const result: (string | JSX.Element)[][] = [];

    result.push([
      content.code != null ? content.code ?? '' : '',
      content.residueSubcategory != null ? this.renderResidueSubcategory(content.residueSubcategory, content.code) : '',
      content.description != null ? content.description : '',
      content.residueClassification != null ? translate(`enums.residueClassification.${content.residueClassification}`) : '',
      //content.measurementType != null ? translate(`enums.measurementType.${content.measurementType}`) : '',
      <Checkbox
        icon={<img src={'content/images/icons/check-gray.svg'} alt="" />}
        checkedIcon={<img src={'content/images/icons/check-green.svg'} alt="" />}
        checked={selectedResidues ? selectedResidues.filter(it => it.id === content.id).length > 0 : false}
      />,
    ]);

    return result;
  };

  componentWillUnmount() {
    this.props.reset();
  }

  onCreateResidue = () => {
    this.props.history.push(`${this.props.match.url}/new`);
  };

  onHandleFilterChanged = value => {
    this.setState(
      prevState => ({
        filterName: value,
        page: {
          ...prevState.page,
          page: 0,
        },
      }),
      () => this.getResidues()
    );
  };

  onResidueClick = (index: number) => {
    const { residues } = this.props;
    const residue = residues[index];

    this.handleResidue(residue);
  };

  onFinishAdding = () => {
    this.setState({
      showSelectSpecific: true,
    });
  };

  onBackHandler = () => {
    this.props.history.goBack();
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
      showSelectProducerModal: false
    });
  };

  toggleSuccessModal = () => {
    this.setState({
      showSuccess: !this.state.showSuccess,
      showSelectSpecific: false,
      showSelectProducerModal: false
    });
  };

  toggleManagerProducerModal = () => {
    this.setState({
      showSelectProducerModal: !this.state.showSelectProducerModal,
    });
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getResidues()
    );
  };

  renderRowItem = (info: string | JSX.Element, index: number) => (
    <div className="add-residues-list__item" style={{ width: this.tableHeaders[index].width }}>
      {info}
    </div>
  );

  renderRow = (residue: IResidue) => {
    const row = this.handleTransformToTableContent(residue);
    return (
      <div className="add-residues-list__row" onClick={() => this.handleResidue(residue)}>
        {this.renderRowItem(row[0][0], 0)}
        {this.renderRowItem(row[0][1], 1)}
        {this.renderRowItem(row[0][2], 2)}
        {this.renderRowItem(row[0][3], 3)}
        {this.renderRowItem(row[0][4], 4)}
      </div>
    );
  };

  renderResidueSubcategory = (subcategory: string, code: string) => {
    const splitted = code.split('.');
    return `${splitted[0].concat('.').concat(splitted[1])} - ${subcategory}`;
  };

  renderResidueType = (type: string, code: string) => {
    const splitted = code.split('.');
    return `${splitted[0]} - ${type}`;
  };

  onSelectProducer = (producer: IProducer) => {
    const { sourceResidues } = this.state;

    sourceResidues.forEach(item => {
      item.sourceId = producer.id;
      item.sourceType = ResidueSourceType.PRODUCER;
    });
    this.props.addSourceResidues(sourceResidues);
    this.toggleManagerProducerModal();
  };

  renderAddPage = () => {
    const { residues, hasAnyAdminAuthority, fetchResidueListStatus } = this.props;
    const { showErrorModal, showSuccess, residueTypes } = this.state;

    return (
      <div>
        <Container>
          <div>
            <h1 style={{ marginTop: '28px', marginBottom: '15px', fontWeight: 600 }}>
              {translate('mtr-transporter.residuesTitleSecond')}
            </h1>
          </div>
          <Row style={{ marginBottom: '5px' }}>
            <Col md={{ size: '6', offset: '3' }}>
              <SimpleSearchField placeholder={translate('residue.search')} onChange={this.onHandleFilterChanged} />
            </Col>
          </Row>
          <Row>
            <Col>
              <StaticTableBar staticItems={this.tableHeaders} />
              <div className={`add-residues-list__container`}>
                {residueTypes &&
                  Array.from(residueTypes).map(([code, type]) => {
                    const typeResidues = residues.filter(it => it.residueType === type);
                    return (
                      <div className="add-residues-list-item__container">
                        <span className="add-residues-list__title">{this.renderResidueType(type, code)}</span>
                        {typeResidues.map(residue => this.renderRow(residue))}
                      </div>
                    );
                  })}
                {checkStatusOnGoing([fetchResidueListStatus]) && (
                  <div style={{ position: 'absolute', zIndex: 1000, top: 100, right: 50, left: 50 }}>
                    <Loading />
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '40px' }} />
            </Col>
          </Row>
        </Container>
        <div className="mtr-add-residues__items--modal">
          <Container>
            <div className="mtr-add-residues__items--modal-buttons">
              <Row>
                <Col md="5">
                  <Pagination
                    onChange={(e, page) => this.handleChangePage(page - 1)}
                    page={this.state.page.page + 1}
                    count={Math.ceil(this.state.page.totalElements / this.state.page.size)}
                    color="primary"
                  />
                </Col>
                <Col md={{ size: 2, offset: 2 }}>
                  <Button className={'button third'} type={'submit'} onClick={this.onBackHandler}>
                    <Translate contentKey={'entity.action.cancel'} />
                  </Button>
                </Col>
                {hasAnyAdminAuthority && (
                  <Col md="3">
                    <Button
                      disabled={this.state.selectedResidues.length === 0}
                      className={'button fourth button-fourth'}
                      onClick={this.onFinishAdding}
                    >
                      <Translate contentKey={'mtr-transporter.residuesButton'} />
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Container>
        </div>


        <AlertModal
          showModal={showSuccess}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.onBackHandler}
          statusImage="garbage.svg"
          alertMessage="modal-transporter-add-residue"
        />
        <AlertModal
          showModal={showErrorModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.residueAdd"
        />
      </div>
    );
  };

  onSelectSpecificBack = () => {
    this.setState({
      showSelectSpecific: false,
    });
  };

  onNextSpecificHandler = (sourceResidues: ISourceResidue[]) => {
    if (this.props.hasManagerAuthority) {
      this.setState(
        {
          sourceResidues,
        },
        () => this.toggleManagerProducerModal()
      );
    } else {
      this.props.addSourceResidues(sourceResidues);
    }
  };

  renderSelectSpecificPage = () => {
    const { selectedResidues } = this.state;

    return (
      <SpecificResidueSelect
        onBackHandler={this.onSelectSpecificBack}
        onNextHandler={this.onNextSpecificHandler}
        selectedResidues={selectedResidues}
      />
    );
  };

  render() {
    const { addSourceResidueStatus } = this.props;
    const { showSelectSpecific, showSelectProducerModal } = this.state;
    if (checkStatusOnGoing([addSourceResidueStatus])) {
      return <Loading />;
    }
    return (
      <div className={`mtr-add-residues__items ${showSelectSpecific ? 'overflow-specific' : ''}`}>
        {!showSelectSpecific && this.renderAddPage()}
        {showSelectSpecific && this.renderSelectSpecificPage()}
        {this.props.hasManagerAuthority &&
          <ManagerProducerModal
            showModal={showSelectProducerModal}
            onCloseModal={this.toggleManagerProducerModal}
            onSelectProducer={this.onSelectProducer}
          />}
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchResidueListStatus: root.residuesEntities.fetchResidueListStatus,
  addSourceResidueStatus: root.residuesEntities.addSourceResidueStatus,
  residues: root.residuesEntities.residues,
  totalItems: root.residuesEntities.totalItems,
  hasAnyAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_ADMIN_AUTHORITIES),
  hasManagerAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER])
});

const mapDispatchToProps = {
  getResidues,
  addSourceResidues,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ResidueAdd);

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './final-destination-register-menu.scss';
import { Col, Row } from 'reactstrap';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from '../../steps/steps';
import { GeneratorUserType, IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import FinalDestinationRegisterCompany from '../steps/company/final-destination-register-company';
import { FinalDestinationRegisterAddress } from '../steps/address/final-destination-register-address';
import { FinalDestinationRegisterResponsible } from '../steps/responsible/final-destination-register-responsible';
import { FinalDestinationRegisterDocumentation } from '../steps/documentation/final-destination-register-documentation';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { createFinalDestination, createFinalDestinationNatural, reset } from '../final-destination-register.reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS, AUTHORITIES } from 'app/config/constants';
import { changeAuthority, getSession } from 'app/shared/reducers/authentication';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import {
  getFinalDestinationDetailByUuid,
  updateHolding,
  updateSubsidiary,
} from 'app/entities/user-final-destination/user-final-destination.reducer';
import GenericCardChoice from 'app/components/generic-card-choice/generic-card-choice';
import FinalDestinationRegisterPerson from '../steps/person/final-destination-register-person';


export interface IFinalDestinationRegisterMenuProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IFinalDestinationRegisterMenuState {
  steps: StepItem[];
  currentIndex: number;
  userFinalDestination: IUserFinalDestination;
  showResponsabilityModal: boolean;
  isEdit: boolean;
  selectedUserType: GeneratorUserType;
  isUserTypeSelected: boolean;
}

export class FinalDestinationRegisterMenu extends React.Component<IFinalDestinationRegisterMenuProps, IFinalDestinationRegisterMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userFinalDestination: {
        user: this.props.account,
      },
      steps: this.mapSteps(),
      currentIndex: 0,
      showResponsabilityModal: false,
      isEdit: false,
      selectedUserType: null,
      isUserTypeSelected: false
    };
  }

  onNext = (userFinalDestination: IUserFinalDestination) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsabilityModal: true,
        userFinalDestination,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userFinalDestination,
      });
    }
  };

  componentDidMount() {
    if (this.props.location.pathname.indexOf('/full-register/final-destination/edit') >= 0) {
      this.setState({ isEdit: true })
      this.props.getFinalDestinationDetailByUuid()
        .then((response) => this.setState({ userFinalDestination: response.value.data }))

    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.createFinalDestinationStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.createFinalDestinationStatus === HttpRequestStatus.ERROR) {
      this.goToErrorAlertPage();
      this.props.reset();
    }
  }

  goToErrorAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_NO,
        alertMessage: 'alert.finalDestinationRegisterError',
        buttonUrl: '/full-register/final-destination',
      },
    });
  }

  goToSuccessAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.finalDestinationRegister',
        buttonUrl: '/login',
      },
    });
  };

  onCancelModal = () => {
    this.setState({
      showResponsabilityModal: false,
    });
  };

  onConfirmModal = () => {
    const { userFinalDestination, isEdit, selectedUserType } = this.state;
    if (isEdit) {
      this.props.updateHolding(userFinalDestination);
    } else {
      if (selectedUserType == GeneratorUserType.LEGALPERSON) {
        this.props.createFinalDestination(userFinalDestination);
      } else {
        this.props.createFinalDestinationNatural(userFinalDestination);
      }
    }


    if (this.props.hasFinalDestinationAdminAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.FINAL_DESTINATION_ADMIN);
    } else if (this.props.hasFinalDestinationUserAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.FINAL_DESTINATION_USER);
    }

  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  private mapSteps = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => this.renderChoice()
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => (
        <FinalDestinationRegisterAddress onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />
      ),
    },
    {
      title: translate('fullRegister.header.responsible'),
      render: () => (
        <FinalDestinationRegisterResponsible
          onNext={this.onNext}
          onBack={this.onBack}
          userFinalDestination={this.state.userFinalDestination}
        />
      ),
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => (
        <FinalDestinationRegisterDocumentation
          onNext={this.onNext}
          onBack={this.onBack}
          userFinalDestination={this.state.userFinalDestination}
        />
      ),
    },
  ];

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  onSelectUserType = (index: GeneratorUserType) => {
    this.setState({
      isUserTypeSelected: true,
      selectedUserType: index,
    });
  }
  renderForms = () => {
    const { selectedUserType } = this.state;


    return (
      <div>
        {selectedUserType == GeneratorUserType.LEGALPERSON && (
          <FinalDestinationRegisterCompany onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />
        )}
        {selectedUserType == GeneratorUserType.PHYSICALPERSON && (
          <FinalDestinationRegisterPerson onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />
        )}
      </div>
    );
  }
  renderChoice = () => {
    const { selectedUserType, isUserTypeSelected } = this.state;
    const producerRegister = translate('producerRegister.company')
    const finalDestinationRegister = translate('finalDestinationRegister.company')
    return (
      <div className="producer-register-company__items">
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <div className="producer-register-company__items--cards">
              <Row>
                <GenericCardChoice
                  index={GeneratorUserType.PHYSICALPERSON}
                  title={finalDestinationRegister.natural.title}
                  text={finalDestinationRegister.natural.text}
                  onSelect={this.onSelectUserType}
                  selected={selectedUserType === GeneratorUserType.PHYSICALPERSON}
                  hideText={isUserTypeSelected}
                />

                <GenericCardChoice
                  index={GeneratorUserType.LEGALPERSON}
                  title={finalDestinationRegister.legal.title}
                  text={finalDestinationRegister.legal.text}
                  onSelect={this.onSelectUserType}
                  selected={selectedUserType === GeneratorUserType.LEGALPERSON}
                  hideText={isUserTypeSelected}
                />
              </Row>
            </div>
          </Col>
        </Row>
        {isUserTypeSelected && this.renderForms()}
      </div>
    );
  };
  render() {
    const { steps, currentIndex } = this.state;
    const { createFinalDestinationStatus } = this.props;
    const { finalDestination } = this.state.userFinalDestination;
    // console.log(finalDestination.personType)
    // if (finalDestination.personType !== undefined) {
    //   if (finalDestination.personType === 'NATURAL') {
    //     this.setState({
    //       isUserTypeSelected: true,
    //       selectedUserType: GeneratorUserType.PHYSICALPERSON,
    //     });
    //   }
    //   if (finalDestination.personType === 'LEGAL') {
    //     this.setState({
    //       isUserTypeSelected: true,
    //       selectedUserType: GeneratorUserType.LEGALPERSON,
    //     });
    //   }
    // }

    if (createFinalDestinationStatus === HttpRequestStatus.ONGOING) {
      return <Loading />;
    }
    return (
      <div style={{ position: 'relative' }}>
        <Steps onIndexClick={this.onIndexClick} steps={steps} currentIndex={currentIndex} />
        <AlertModal
          showModal={this.state.showResponsabilityModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={this.onConfirmModal}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="alert.responsibilityTerms"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  createFinalDestination,
  createFinalDestinationNatural,
  getSession,
  reset,
  changeAuthority,
  getFinalDestinationDetailByUuid,
  updateHolding,
  updateSubsidiary,
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  createFinalDestinationStatus: root.finalDestinationRegister.createFinalDestinationStatus,
  isCreatingNewProfile: root.authentication.isCreatingNewProfile,
  hasFinalDestinationUserAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.FINAL_DESTINATION_USER]),
  hasFinalDestinationAdminAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN])
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationRegisterMenu);

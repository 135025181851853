import React from 'react';

export interface Props {
  steps: string[];
  currentIndex: number;
  onIndexClick: (index: number) => void;
}

export const StepsHeader = (props: Props): JSX.Element => {
  return (
    <div className="steps__header">
      {props.steps.map((it, index) => (
        <div key={index} className="steps__header__step">
          <span className={`steps__header__step__title ${index <= props.currentIndex ? 'steps__header__step__title--active' : ''}`}>
            {it.toUpperCase()}
          </span>
          <div className="steps__header__step__icon-container">
            <StepIcon index={index} currentIndex={props.currentIndex} onClickEvent={props.onIndexClick} />
            {index < props.steps.length - 1 && (
              <div className={`steps__header__step__line ${index < props.currentIndex ? 'steps__header__step__line--active' : ''}`} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const StepIcon = ({ index, currentIndex, onClickEvent }: { index: number; currentIndex: number; onClickEvent: (index) => void }): JSX.Element => {
  if (index === currentIndex) {
    return <div className="steps__header__step__icon-active" />;
  } else if (index < currentIndex) {
    return <div className="steps__header__step__icon-done" onClick={() => onClickEvent(index)} />;
  } else {
    return <div className="steps__header__step__icon-inactive" />;
  }
};

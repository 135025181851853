import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Container, Row } from 'reactstrap';

const Footer = props => (
  <div className="container__footer">
    <Container>
      <Row>
        <Col md="12">
          <p className="container__footer--text">
            <Translate contentKey="footer"/>
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;

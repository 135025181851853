import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, ISignature } from 'app/shared/model/signature.model';
import { Cdf } from 'app/shared/model/cdf.model';

export const ACTION_TYPES = {
  RESET: 'cdf/RESET',
  SIGN_CDF: 'profile/SIGN_CDF',
  RESET_STATUS: 'cdf/RESET_STATUS',
  FETCH_SIGNATURE: 'profile/FETCH_SIGNATURE',
  FETCH_SIGNATURE_RESPONSIBLE: 'profile/FETCH_SIGNATURE_RESPONSIBLE',
};

const initialState = {
  errorMessage: null,
  signature: defaultValue,
  signCdfStatus: HttpRequestStatus.NOOP,
  fetchSignatureStatus: HttpRequestStatus.NOOP,
  fetchResponsibleSignatureStatus: HttpRequestStatus.NOOP,
};

export type CustomCdfSignatureState = Readonly<typeof initialState>;

export default (state: CustomCdfSignatureState = initialState, action): CustomCdfSignatureState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        errorMessage: null,
        fetchSignatureStatus: HttpRequestStatus.ONGOING,
      };

    case REQUEST(ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE):
      return {
        ...state,
        errorMessage: null,
        fetchResponsibleSignatureStatus: HttpRequestStatus.ONGOING,
      };

    case REQUEST(ACTION_TYPES.SIGN_CDF):
      return {
        ...state,
        errorMessage: null,
        signCdfStatus: HttpRequestStatus.ONGOING,
      };

    case FAILURE(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        errorMessage: action.payload,
      };

    case FAILURE(ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE):
      return {
        ...state,
        errorMessage: action.payload,
      };

    case FAILURE(ACTION_TYPES.SIGN_CDF):
      return {
        ...state,
        errorMessage: action.payload,
        signCdfStatus: HttpRequestStatus.ERROR,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        signature: action.payload.data,
        fetchSignatureStatus: HttpRequestStatus.SUCCESS,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE):
      return {
        ...state,
        signature: action.payload.data,
        fetchResponsibleSignatureStatus: HttpRequestStatus.SUCCESS,
      };

    case SUCCESS(ACTION_TYPES.SIGN_CDF):
      return {
        ...state,
        signCdfStatus: HttpRequestStatus.SUCCESS,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.RESET_STATUS:
      return {
        ...state,
        errorMessage: undefined,
        signCdfStatus: HttpRequestStatus.NOOP,
        fetchSignatureStatus: HttpRequestStatus.NOOP,
        fetchResponsibleSignatureStatus: HttpRequestStatus.NOOP,
      };

    default:
      return state;
  }
};

const cdfUrl = 'api/cdfs';
const signatureUrl = 'api/signatures';

export const getSignature = () => {
  const requestUrl = `${signatureUrl}/current`;
  return {
    type: ACTION_TYPES.FETCH_SIGNATURE,
    payload: axios.get<ISignature>(requestUrl),
  };
};

export const getResponsibleSignature = () => {
  const requestUrl = `${signatureUrl}/responsible`;
  return {
    type: ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE,
    payload: axios.get<ISignature>(requestUrl),
  };
};

export const signCdf = (entity: Cdf) => {
  return {
    type: ACTION_TYPES.SIGN_CDF,
    payload: axios.put(`${cdfUrl}`, entity),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const resetStatus = () => ({
  type: ACTION_TYPES.RESET_STATUS,
});

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../content/images/icons/x-2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".cardholder__container {\n  font-family: \"Catamaran\";\n  box-sizing: border-box;\n  margin: 0 auto;\n  width: 100%;\n}\n.cardholder__container--icon-close {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: -webkit-sticky;\n  position: sticky;\n  left: 100%;\n  top: 60px;\n  cursor: pointer;\n}\n.cardholder__container--form-title {\n  display: block;\n  text-align: center;\n  font-weight: 600;\n  font-size: 20px;\n  color: #4a4a4a;\n}\n.cardholder__container--form-buttons-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n.cardholder__container--form-buttons-container button.MuiButtonBase-root.MuiButton-root.MuiButton-text.button.third {\n  border-color: #30ab64 !important;\n  margin-right: 20px;\n}\n.cardholder__container--form-buttons-container .button.third span {\n  padding-top: 2px;\n  color: #30ab64 !important;\n}\n.cardholder__container--form-buttons-container .button.fourth span {\n  padding-top: 2px;\n}\n.cardholder__container--form form {\n  margin-top: 16px;\n}\n.cardholder__container--form-fields-bottom-container {\n  display: flex;\n  justify-content: space-between;\n}", ""]);
// Exports
module.exports = exports;

import mtr, { MtrState } from './../../modules/generic/mtr/mtr-reducer';
import mtrModel, { MtrModelState } from './../../modules/producer/mtr-model/mtr-model.reducer';
import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import document, { DocumentState } from 'app/entities/document/document.reducer';
// prettier-ignore
import userToken, { UserTokenState } from 'app/entities/user-token/user-token.reducer';
// prettier-ignore
import country, { CountryState } from 'app/entities/country/country.reducer';
// prettier-ignore
import state, { StateState } from 'app/entities/state/state.reducer';
// prettier-ignore
import city, { CityState } from 'app/entities/city/city.reducer';
// prettier-ignore
import address, { AddressState } from 'app/entities/address/address.reducer';
// prettier-ignore
import cnaeCode, { CnaeCodeState } from 'app/entities/cnae-code/cnae-code.reducer';
// prettier-ignore
import environmentalLicense, { EnvironmentalLicenseState } from 'app/entities/environmental-license/environmental-license.reducer';
// prettier-ignore
import authorization, { AuthorizationState } from 'app/entities/authorization/authorization.reducer';
// prettier-ignore
import producer, { ProducerState } from 'app/entities/producer/producer.reducer';
// prettier-ignore
import userProducer, { UserProducerState } from 'app/entities/user-producer/user-producer.reducer';
import producerRegister, { ProducerRegisterState } from 'app/modules/account/register/full-register/producer/producer-register.reducer';
import transporterRegister, {
  TransporterRegisterState,
} from 'app/modules/account/register/full-register/transporter/transporter-register.reducer';
// prettier-ignore
import transportLicense, { TransportLicenseState } from 'app/entities/transport-license/transport-license.reducer';
// prettier-ignore
import transporter, { TransporterState } from 'app/entities/transporter/transporter.reducer';
// prettier-ignore
import userTransporter, { UserTransporterState } from 'app/entities/user-transporter/user-transporter.reducer';
// prettier-ignore
import driver, { DriverState } from 'app/entities/driver/driver.reducer';
// prettier-ignore
import finalDestination, { FinalDestinationState } from 'app/entities/final-destination/final-destination.reducer';
// prettier-ignore
import transshipment, { TransshipmentState } from 'app/entities/transshipment/transshipment.reducer';
// prettier-ignore
import userFinalDestination, { UserFinalDestinationState } from 'app/entities/user-final-destination/user-final-destination.reducer';

import finalDestinationRegister, {
  FinalDestinationRegisterState,
} from 'app/modules/account/register/full-register/final-destination/final-destination-register.reducer';

import managerRegister, { ManagerRegisterState } from 'app/modules/account/register/full-register/manager/manager-register.reducer';

// prettier-ignore
import legalPerson, { LegalPersonState } from 'app/entities/legal-person/legal-person.reducer';
// prettier-ignore
import naturalPerson, { NaturalPersonState } from 'app/entities/natural-person/natural-person.reducer';
// prettier-ignore
import manager, { ManagerState } from 'app/entities/manager/manager.reducer';
// prettier-ignore
import userManager, { UserManagerState } from 'app/entities/user-manager/user-manager.reducer';

import producerUnits, { ProducerUnitsState } from 'app/modules/producer/units/producer-units-reducer';

import finalDestinationUnits, { FinalDestinationUnitsState } from 'app/modules/final-destination/units/final-destination-units-reducer';

import transport, { TransportState } from 'app/modules/transporter/transport/transport-reducer';

import licenses, { LicensesState } from 'app/entities/licenses/licenses-reducer';

import residuesEntities, { ResidueEntitiesState } from 'app/entities/residue/residue-entities.reducer';

import genericAuthorization, { GenericAuthorizationState } from 'app/modules/generic/authorization/generic-authorization.reducer';

import genericFavorite, { GenericFavoriteState } from 'app/modules/generic/favorite-list/generic-favorite.reducer';

import profile, { ProfileState } from 'app/modules/generic/profile/profile-reducer';

import managerlink, { ManagerLinkState } from 'app/modules/manager/manager-link-reducer';
import integrationQueue, { IntegrationQueueState } from 'app/modules/administration/integration-queue/integration-queue-reducer';

import mtrSignature, { MtrSignatureState } from 'app/modules/generic/mtr/signature/mtr-signature.reducer';

// prettier-ignore
import vehicle, { VehicleState } from 'app/entities/vehicle/vehicle.reducer';
// prettier-ignore
import residue, { ResidueState } from 'app/entities/residue/residue.reducer';
// prettier-ignore
import specificResidue, { SpecificResidueState } from 'app/entities/specific-residue/specific-residue.reducer';
// prettier-ignore
import sourceResidue, { SourceResidueState } from 'app/entities/source-residue/source-residue.reducer';
// prettier-ignore
import favorite, { FavoriteState } from 'app/entities/favorite/favorite.reducer';
// prettier-ignore
import cdf, { CdfState } from 'app/entities/cdf/cdf.reducer';
// prettier-ignore
import signature, { SignatureState } from 'app/entities/signature/signature.reducer';
// prettier-ignore
import transshipmentRegister, { TransshipmentRegisterState, } from 'app/modules/account/register/full-register/transshipment/transshipment-register.reducer'; // prettier-ignore
// prettier-ignore
import userTransshipment, { UserTransshipmentState } from 'app/entities/user-transshipment/user-transshipment.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */
import customCdfSignature, { CustomCdfSignatureState } from 'app/components/custom-cdf-signature/custom-cdf-signature.reducer';

import integrationUser, { IntegrationUserState } from 'app/modules/generic/user-integration/user-integration.reducer';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly cdf: CdfState;
  readonly customCdfSignature: CustomCdfSignatureState;
  readonly locale: LocaleState;
  readonly userTransshipment: UserTransshipmentState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly document: DocumentState;
  readonly userToken: UserTokenState;
  readonly country: CountryState;
  readonly state: StateState;
  readonly city: CityState;
  readonly address: AddressState;
  readonly cnaeCode: CnaeCodeState;
  readonly environmentalLicense: EnvironmentalLicenseState;
  readonly authorization: AuthorizationState;
  readonly producer: ProducerState;
  readonly userProducer: UserProducerState;
  readonly producerRegister: ProducerRegisterState;
  readonly transporterRegister: TransporterRegisterState;
  readonly transshipmentRegister: TransshipmentRegisterState;
  readonly transportLicense: TransportLicenseState;
  readonly transporter: TransporterState;
  readonly userTransporter: UserTransporterState;
  readonly driver: DriverState;
  readonly mtr: MtrState;
  readonly mtrModel: MtrModelState;
  readonly transshipment: TransshipmentState;
  readonly finalDestination: FinalDestinationState;
  readonly userFinalDestination: UserFinalDestinationState;
  readonly finalDestinationRegister: FinalDestinationRegisterState;
  readonly legalPerson: LegalPersonState;
  readonly naturalPerson: NaturalPersonState;
  readonly manager: ManagerState;
  readonly userManager: UserManagerState;
  readonly managerRegister: ManagerRegisterState;
  readonly producerUnits: ProducerUnitsState;
  readonly finalDestinationUnits: FinalDestinationUnitsState;
  readonly integrationQueue: IntegrationQueueState;
  readonly vehicle: VehicleState;
  readonly transport: TransportState;
  readonly licenses: LicensesState;
  readonly residue: ResidueState;
  readonly residuesEntities: ResidueEntitiesState;
  readonly genericAuthorization: GenericAuthorizationState;
  readonly specificResidue: SpecificResidueState;
  readonly sourceResidue: SourceResidueState;
  readonly favorite: FavoriteState;
  readonly genericFavorite: GenericFavoriteState;
  readonly signature: SignatureState;
  readonly profile: ProfileState;
  readonly managerlink: ManagerLinkState;
  readonly mtrSignature: MtrSignatureState;
  readonly integrationUser: IntegrationUserState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  customCdfSignature,
  cdf,
  userTransshipment,
  transshipment,
  transshipmentRegister,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  document,
  userToken,
  country,
  state,
  city,
  mtr,
  mtrModel,
  address,
  cnaeCode,
  environmentalLicense,
  authorization,
  producer,
  userProducer,
  transportLicense,
  transporter,
  userTransporter,
  driver,
  finalDestination,
  userFinalDestination,
  legalPerson,
  naturalPerson,
  manager,
  userManager,
  vehicle,
  residue,
  specificResidue,
  sourceResidue,
  favorite,
  integrationQueue,
  signature,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  producerRegister,
  transporterRegister,
  finalDestinationRegister,
  managerRegister,
  producerUnits,
  finalDestinationUnits,
  transport,
  licenses,
  residuesEntities,
  genericAuthorization,
  genericFavorite,
  profile,
  managerlink,
  mtrSignature,
  integrationUser,
});

export default rootReducer;

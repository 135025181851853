import * as React from 'react';
import {Translate} from 'react-jhipster';

export interface IButtonComponentProps {
  onClick: () => void;
  title?: string;
  buttonType?: string;
}

export default class ButtonComponent extends React.Component<IButtonComponentProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, buttonType } = this.props;
    return (
      <div className={'button-container'}>
        <button className={`button ${buttonType ? buttonType : 'primary'}`} type={'submit'} onClick={this.props.onClick}>
          {title ? <span>{title}</span> : <Translate contentKey={'entity.action.next'}>Next</Translate>}
        </button>
      </div>
    );
  }
}

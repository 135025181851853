import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './manager.reducer';

export interface IManagerDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ManagerDetail extends React.Component<IManagerDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { managerEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.manager.detail.title">Manager</Translate> [<b>{managerEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="personType">
                <Translate contentKey="resitrackApp.manager.personType">Person Type</Translate>
              </span>
            </dt>
            <dd>{managerEntity.personType}</dd>
            <dt>
              <Translate contentKey="resitrackApp.manager.address">Address</Translate>
            </dt>
            <dd>{managerEntity.address ? managerEntity.address.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.manager.legalPerson">Legal Person</Translate>
            </dt>
            <dd>{managerEntity.legalPerson ? managerEntity.legalPerson.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.manager.naturalPerson">Natural Person</Translate>
            </dt>
            <dd>{managerEntity.naturalPerson ? managerEntity.naturalPerson.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.manager.producer">Producer</Translate>
            </dt>
            <dd>
              {managerEntity.producers
                ? managerEntity.producers.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.id}</a>
                      {i === managerEntity.producers.length - 1 ? '' : ', '}
                    </span>
                  ))
                : null}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/manager" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/manager/${managerEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ manager }: IRootState) => ({
  managerEntity: manager.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDetail);

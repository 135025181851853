import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {getEntity} from './user-producer.reducer';

export interface IUserProducerDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class UserProducerDetail extends React.Component<IUserProducerDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { userProducerEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.userProducer.detail.title">UserProducer</Translate> [<b>{userProducerEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="registrationNumber">
                <Translate contentKey="resitrackApp.userProducer.registrationNumber">Registration Number</Translate>
              </span>
            </dt>
            <dd>{userProducerEntity.registrationNumber}</dd>
            <dt>
              <span id="classCouncil">
                <Translate contentKey="resitrackApp.userProducer.classCouncil">Class Council</Translate>
              </span>
            </dt>
            <dd>{userProducerEntity.classCouncil}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userProducer.user">User</Translate>
            </dt>
            <dd>{userProducerEntity.user ? userProducerEntity.user.login : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userProducer.producer">Producer</Translate>
            </dt>
            <dd>{userProducerEntity.producer ? userProducerEntity.producer.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/user-producer" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/user-producer/${userProducerEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ userProducer }: IRootState) => ({
  userProducerEntity: userProducer.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProducerDetail);

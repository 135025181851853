import React from 'react';
import MenuItem from 'app/shared/layout/menus/common/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <MenuItem icon="asterisk" to="/entity/document">
      <Translate contentKey="global.menu.entities.document" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/user-token">
      <Translate contentKey="global.menu.entities.userToken" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/country">
      <Translate contentKey="global.menu.entities.country" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/state">
      <Translate contentKey="global.menu.entities.state" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/city">
      <Translate contentKey="global.menu.entities.city" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/address">
      <Translate contentKey="global.menu.entities.address" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/cnae-code">
      <Translate contentKey="global.menu.entities.cnaeCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/environmental-license">
      <Translate contentKey="global.menu.entities.environmentalLicense" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/authorization">
      <Translate contentKey="global.menu.entities.authorization" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/producer">
      <Translate contentKey="global.menu.entities.producer" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/user-producer">
      <Translate contentKey="global.menu.entities.userProducer" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/environmental-license">
      <Translate contentKey="global.menu.entities.environmentalLicense" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/transport-license">
      <Translate contentKey="global.menu.entities.transportLicense" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/transporter">
      <Translate contentKey="global.menu.entities.transporter" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/user-transporter">
      <Translate contentKey="global.menu.entities.userTransporter" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/driver">
      <Translate contentKey="global.menu.entities.driver" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/final-destination">
      <Translate contentKey="global.menu.entities.finalDestination" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/user-final-destination">
      <Translate contentKey="global.menu.entities.userFinalDestination" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/legal-person">
      <Translate contentKey="global.menu.entities.legalPerson" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/natural-person">
      <Translate contentKey="global.menu.entities.naturalPerson" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/manager">
      <Translate contentKey="global.menu.entities.manager" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/entity/user-manager">
      <Translate contentKey="global.menu.entities.userManager" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);

import React from 'react';
import {translate} from 'react-jhipster';
import './empty-list.scss';

export const EmptyList = () => {
  return (
    <div className="empty-list__items">
      <div className="empty-list__items--empty-box">
        <div className="empty-list__items--empty-img" />
        <div className="empty-list__items--empty-text">{translate('global.emptyList')}</div>
      </div>
    </div>
  );
};

export default EmptyList;

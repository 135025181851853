import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, ISignature } from 'app/shared/model/signature.model';

export const ACTION_TYPES = {
  FETCH_SIGNATURE: 'profile/FETCH_SIGNATURE',
  FETCH_SIGNATURE_RESPONSIBLE: 'profile/FETCH_SIGNATURE_RESPONSIBLE',
  SIGN_MTR: 'profile/SIGN_MTR',
  RESET: 'mtr/RESET',
  RESET_STATUS: 'mtr/RESET_STATUS',
};

const initialState = {
  errorMessage: null,
  fetchSignatureStatus: HttpRequestStatus.NOOP,
  fetchResponsibleSignatureStatus: HttpRequestStatus.NOOP,
  signMtrStatus: HttpRequestStatus.NOOP,
  signature: defaultValue,
};

export type MtrSignatureState = Readonly<typeof initialState>;

// Reducer

export default (state: MtrSignatureState = initialState, action): MtrSignatureState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        errorMessage: null,
        fetchSignatureStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE):
      return {
        ...state,
        errorMessage: null,
        fetchResponsibleSignatureStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.SIGN_MTR):
      return {
        ...state,
        errorMessage: null,
        signMtrStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.SIGN_MTR):
      return {
        ...state,
        errorMessage: action.payload,
        signMtrStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        signature: action.payload.data,
        fetchSignatureStatus: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE):
      return {
        ...state,
        signature: action.payload.data,
        fetchResponsibleSignatureStatus: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.SIGN_MTR):
      return {
        ...state,
        signMtrStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.RESET_STATUS:
      return {
        ...state,
        errorMessage: undefined,
        signMtrStatus: HttpRequestStatus.NOOP,
        fetchResponsibleSignatureStatus: HttpRequestStatus.NOOP,
        fetchSignatureStatus: HttpRequestStatus.NOOP,
      };
    default:
      return state;
  }
};

const signatureUrl = 'api/signatures';
const mtrUrl = 'api/mtr';

// Actions

export const getSignature = () => {
  const requestUrl = `${signatureUrl}/current`;
  return {
    type: ACTION_TYPES.FETCH_SIGNATURE,
    payload: axios.get<ISignature>(requestUrl),
  };
};

export const getResponsibleSignature = () => {
  const requestUrl = `${signatureUrl}/responsible`;
  return {
    type: ACTION_TYPES.FETCH_SIGNATURE_RESPONSIBLE,
    payload: axios.get<ISignature>(requestUrl),
  };
};

export const signMtr = (entity: ISignature, id: number) => {
  const requestUrl = `${mtrUrl}/sign/${id}`;
  return {
    type: ACTION_TYPES.SIGN_MTR,
    payload: axios.post(`${requestUrl}`, entity),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const resetStatus = () => ({
  type: ACTION_TYPES.RESET_STATUS,
});

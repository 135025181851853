import React, { useEffect, useRef, useState } from 'react';
import './account-dropdown.scss';
import { translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Item } from '../../../../components/account-float-menu-item/account-float-menu-item';

export const AccountDropDown = props => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    function handleMouseClickOutside(evt: MouseEvent) {
      // @ts-ignore
      if (dropdownRef && dropdownRef.current && !dropdownRef.current.contains(evt.target) && open) {
        setOpen(false);
      }
    }
    window.addEventListener('click', handleMouseClickOutside);

    return () => window.removeEventListener('click', handleMouseClickOutside);
  }, [open]);

  function closeMenu() {
    setOpen(false);
  }


  return (
      <>
        <div  style={{ position: 'relative', flex:'0 0 20%'}} className="d-flex align-items-center">
          <div className="account-header__profile--image" />
          <div className="account-header__profile--information">
            <div className="title-header">
              <span>
                {translate('header.profile.companyName', { companyName: props.companyName || 'Resitrack' })}
              </span>
            </div>
            <div ref={dropdownRef} onClick={() => setOpen(!open)} style={{  width: '130px'}}>
              <div style={{ cursor:'pointer'}} className="info-header">
                <span>{translate('header.profile.name', { name: props.userName.split(' ')[0] })}</span>
                <span className="profile-type">{translate('header.profile.profileType', { profileType: props.profileType })}</span>
              </div>
              {open && (
                  <div className="float-menu">
                    <ul>

                      <Item
                        show={true}
                        title={translate('user.menu.data')}
                        to={'/me/profile/data'}
                        style={{ display: 'block' }}
                      />
                      <Item
                        show={true}
                        title={translate('user.menu.change-password')}
                        to={'/me/profile/password'}
                        style={{ display: 'block' }}
                      />
                      <Item
                        show={!props.isManager && props.isProfileAdmin}
                        title={translate('user.menu.responsibleTecnican')}
                        to={'/me/profile/signature'}
                      />
                      <Item
                        show={props.isProfileAdmin && !props.isTransporter}
                        title={translate('user.menu.user')}
                        to={'/me/profile/user-manage'}
                        style={{ display: 'block' }}
                      />
                      <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'10px', padding: '0 15px'}}>

                        <Item
                          show={props.isProfileAdmin || props.isTransporter}
                          title={translate('user.menu.profile')}
                          to={'/me/profile/profiles-manage'}
                          className={'menu-button'}
                          style={{ display: 'block', color: "#16934a" }}
                        />
                        <Link className="logout-button__container" style={{fontWeight:'normal'}} to="/logout">
                          <div className="logout-button" style={props.isProfileAdmin || props.isTransporter ? {} : {marginLeft:'0px', marginRight:'30px', paddingLeft:'0px'}}>
                            {translate('user.menu.logout')}
                          </div>
                        </Link>

                      </div>

                    </ul>
                  </div>
              )}
            </div>
          </div>
        </div>
      </>
  )}




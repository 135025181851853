import { ICity } from 'app/shared/model/city.model';

export interface IUser {
  companyName?: string;
  id?: any;
  uuid?: string;
  name?: string;
  cpf?: string;
  cpfTechnical?: string;
  phone?: string;
  occupation?: string;
  finishedRegister?: boolean;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  allAuthorities?: any[];
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
  newPassword?: string;
  cnpj?: string;
  fantasyName?: string;
  nameTechnical?: string;
  phoneProfile?: string;
  street?: string;
  number?: string;
  zipcode?: string;
  complement?: string;
  city?: ICity;
  addressDescription?: string;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  uuid: '',
  name: '',
  email: '',
  activated: true,
  langKey: '',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
  nameTechnical: '',
};

import React from 'react';
import CustomMenuItem from 'app/shared/layout/menus/common/custom-menu-item/custom-menu-item';
import { Translate } from 'react-jhipster';
import { ProfileType } from 'app/shared/model/enum/Profile-dropdonw';
import { MenuDropDown } from '../producer/producer-menu-dropdonw';

export interface IManagerMenuProps {
    isAdmin: boolean;
    isUser: boolean;
    currentUrl: string;
}

export interface IManagerMenuState {
    subCategorySelected: boolean;
}

export class ManagerMenu extends React.Component<IManagerMenuProps, IManagerMenuState> {
    constructor(props) {
        super(props);
        this.state = {
            subCategorySelected: false,
        };
    }

    checkSubcategorySelected = (path: string) => {
        const { currentUrl } = this.props;
        const selected = currentUrl.indexOf(path) != -1;
        return selected;
    };

    render() {
        const { isAdmin, isUser, currentUrl } = this.props;
        // const active = this.checkSubcategorySelected('/contractor/rides');
        return (
            <div style={{ display: 'flex' }}>
                <MenuDropDown currentUrl={this.props.currentUrl} profile={ProfileType.MANAGER} />

                <CustomMenuItem id={'producers'} to="/manager/producers" active={this.checkSubcategorySelected("/manager/producers")}>
                    <Translate contentKey={'header.manager.producers'}>Producers</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'residues'} to="/manager/residues" active={this.checkSubcategorySelected("/manager/residues")}>
                    <Translate contentKey={'header.common.residues'}>Residues</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'authorizations'} to="/manager/authorizations" active={this.checkSubcategorySelected("/manager/authorizations")}>
                    <Translate contentKey={'header.common.authorizations'}>Authorizations</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'invite'} to="/manager/summary" active={this.checkSubcategorySelected("/manager/summary")}>
                    <Translate contentKey={'header.common.invite'}>Invite</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'cdf'} to="/manager/cdf" active={this.checkSubcategorySelected("/producer/cdf")}>
                    <Translate contentKey={'header.finalDestination.cdf'}>CDF</Translate>
                </CustomMenuItem>
            </div>
        );
    }
}

export default ManagerMenu;

import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue } from 'app/shared/model/vehicle.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import StringUtils from 'app/shared/util/string-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { IVehicle } from './../../shared/model/vehicle.model';

export const ACTION_TYPES = {
  FETCH_VEHICLE_LIST: 'vehicle/FETCH_VEHICLE_LIST',
  FETCH_VEHICLE: 'vehicle/FETCH_VEHICLE',
  SEARCH_VEHICLE: 'vehicle/SEARCH_VEHICLE',
  CREATE_VEHICLE: 'vehicle/CREATE_VEHICLE',
  UPDATE_VEHICLE: 'vehicle/UPDATE_VEHICLE',
  DELETE_VEHICLE: 'vehicle/DELETE_VEHICLE',
  RESET: 'vehicle/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVehicle>,
  vehicles: [] as ReadonlyArray<IVehicle>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  updateError: false,
  createSuccess: false,
  createError: false,
  deleteVehicleStatus: HttpRequestStatus.NOOP,
};

export type VehicleState = Readonly<typeof initialState>;

// Reducer

export default (state: VehicleState = initialState, action): VehicleState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VEHICLE_LIST):
    case REQUEST(ACTION_TYPES.SEARCH_VEHICLE):
    case REQUEST(ACTION_TYPES.FETCH_VEHICLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_VEHICLE):
    case REQUEST(ACTION_TYPES.UPDATE_VEHICLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updateError: false,
        createError: false,
        createSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_VEHICLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        deleteVehicleStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_VEHICLE_LIST):
    case FAILURE(ACTION_TYPES.SEARCH_VEHICLE):
    case FAILURE(ACTION_TYPES.FETCH_VEHICLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        createSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.CREATE_VEHICLE):
      return {
        ...state,
        loading: false,
        createError: true,
        createSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.UPDATE_VEHICLE):
      return {
        ...state,
        loading: false,
        updateError: true,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_VEHICLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        deleteVehicleStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VEHICLE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VEHICLE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_VEHICLE):
      return {
        ...state,
        loading: false,
        vehicles: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VEHICLE):
      return {
        ...state,
        updating: false,
        createSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_VEHICLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VEHICLE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        deleteVehicleStatus: HttpRequestStatus.SUCCESS,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/vehicles';

// Actions

export const getEntities: ICrudGetAllAction<IVehicle> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_VEHICLE_LIST,
  payload: axios.get<IVehicle>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const searchVehicles: ICrudSearchActionCustom<IVehicle> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}/transporter/${search.transporterId}${params}`;
  return {
    type: ACTION_TYPES.SEARCH_VEHICLE,
    payload: axios.get<IVehicle>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IVehicle> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VEHICLE,
    payload: axios.get<IVehicle>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IVehicle> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VEHICLE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<IVehicle> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VEHICLE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IVehicle> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VEHICLE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import * as React from 'react';
import './inline-select.scss';

export interface SelectOption {
  label: string;
  value: unknown;
}
export interface IInlineSelectProps {
  startIndex?: number;
  options: SelectOption[];
  selectedOption?: SelectOption;
  placeholder?: string;
  onChange: (value: unknown) => void;
  dontAttachSelected?: boolean;
  error?: boolean;
  errorMessage?: string;
  noPermissionToOpen?: boolean;
}

export interface IInlineSelectState {
  selectedOption: SelectOption;
  optionsOpen: boolean;
}

export default class InlineSelect extends React.Component<IInlineSelectProps, IInlineSelectState> {
  private selectRef;
  constructor(props: any) {
    super(props);

    this.state = {
      selectedOption: this.props.selectedOption ? this.props.selectedOption : null,
      optionsOpen: false,
    };
  }
  // Component Life Cycle
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // Handlers
  private handleClickOutside = event => {
    if (this.selectRef && !this.selectRef.contains(event.target)) {
      this.setState({
        optionsOpen: false,
      });
    }
  };
  private onChange = (option: SelectOption) => {
    const { dontAttachSelected } = this.props;
    if (!dontAttachSelected) {
      this.setState({
        selectedOption: option,
        optionsOpen: false,
      });
    } else {
      this.setState({
        selectedOption: null,
        optionsOpen: false,
      });
    }

    this.props.onChange(option.value);
  };
  private onExpand = () => {
    if (this.props.noPermissionToOpen) {
      return;
    }
    this.setState({
      optionsOpen: !this.state.optionsOpen,
    });
  };
  // Renders
  private renderOption = (option: SelectOption, index: number) => {
    return (
      <a className="select__option" onClick={() => this.onChange(option)}>
        <div>
          <span className="select__option__label">{option.label}</span>
        </div>
      </a>
    );
  };
  render() {
    const { options, error, errorMessage, placeholder } = this.props;
    const { selectedOption, optionsOpen } = this.state;
    return (
      <div className="select" onBlur={(e) => e.preventDefault()} ref={ref => (this.selectRef = ref)}>
        <a className="select__title" data-dropdown-open={optionsOpen} onClick={this.onExpand}>
          <div className="select__title__text">{selectedOption ? selectedOption.label : placeholder}</div>
          <div className="select__title__arrow-icn" />
        </a>
        <input
          id="fakeTabField"
          style={{ position: 'absolute', opacity: 0, zIndex: -100 }}
          onFocus={e => {
            e.preventDefault();
            this.selectRef.focus();
            this.onExpand();
          }}
        />
        {optionsOpen && (
          <div className="select__options">
            {options
              .filter(opt => selectedOption === null || opt.label !== selectedOption.label)
              .map((opt, index) => this.renderOption(opt, index))}
          </div>
        )}
        {error && <span className="inline__error">{errorMessage ? errorMessage : ''}</span>}
      </div>
    );
  }
}

import './card.scss';
import React from 'react';
import { Col } from 'reactstrap';

export const Card = props => {
  return (
    <Col md="3" onClick={props.onClick}>
      <div className="profile-company-card__container" style={props.disabled ? {pointerEvents: "none", opacity: "0.5"} : {}}>
        <div className="profile-company-card__icon-container">
          <img className="profile-company-card--icon" src={props.icon} />
          <img className="profile-company-card--icon-alt" src={props.iconAlt} />
        </div>
        <div className="profile-company-card__title">{props.title}</div>
        <div className="profile-company-card__text">{props.text}</div>
      </div>
    </Col>
  );
};

export default Card;

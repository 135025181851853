// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../content/images/icons/credit-card-gray.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../../../../content/images/icons/lixo-cinza.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".credit-card-container, .credit-card-container-active {\n  font-family: \"Catamaran\";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 414px;\n  height: 76px;\n  background-color: #ffffff;\n  border: 1px solid #e3e3e3;\n  border-radius: 6px;\n  font-size: 12px;\n  line-height: 22px;\n  color: #7b7b7b;\n  font-weight: 300;\n  box-sizing: border-box;\n  margin-bottom: 16px;\n}\n.credit-card-container .icon-check, .credit-card-container-active .icon-check {\n  margin-right: 9px;\n}\n.credit-card-container--card {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n.credit-card-container--card-data {\n  display: flex;\n  flex-direction: column;\n}\n.credit-card-container--card-credit {\n  font-weight: 400;\n  color: #2d3e46;\n}\n.credit-card-container--icon-credit-card {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin: 0 24px;\n}\n.credit-card-container--icon-trash {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  margin-left: auto;\n  margin-right: 10px;\n  height: 30px;\n  cursor: pointer;\n}\n\n.credit-card-container-active {\n  border: 1px solid #30ab64;\n}", ""]);
// Exports
module.exports = exports;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './producer-register-menu.scss';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from '../../steps/steps';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { ProducerRegisterCompany } from '../steps/company/producer-register-company';
import { ProducerRegisterAddress } from '../steps/address/producer-register-address';
import { ProducerRegisterResponsible } from '../steps/responsible/producer-register-responsible';
import { ProducerRegisterDocumentation } from '../steps/documentation/producer-register-documentation';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { createHolding, createSubsidiary, reset, createPhysicalPerson } from '../producer-register.reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { GeneratorCompanyType } from 'app/shared/model/producer.model';
import { ALERT_ICONS, AUTHORITIES } from 'app/config/constants';
import { changeAuthority, getSession } from 'app/shared/reducers/authentication';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getProducerDetailByUuid } from 'app/entities/user-producer/user-producer.reducer';
import { updateHolding, updateSubsidiary, updatePhyscalPerson } from 'app/modules/producer/units/producer-units-reducer';

export interface IProducerRegisterMenuProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IProducerRegisterMenuState {
  steps: StepItem[];
  currentIndex: number;
  userProducer: IUserProducer;
  showResponsabilityModal: boolean;
  isEdit: boolean;
}

export class ProducerRegisterMenu extends React.Component<IProducerRegisterMenuProps, IProducerRegisterMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userProducer: {
        user: this.props.account,
      },
      steps: this.mapSteps(),
      currentIndex: 0,
      showResponsabilityModal: false,
      isEdit: false,
    };
  }

  onNext = (userProducer: IUserProducer) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsabilityModal: true,
        userProducer,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userProducer,
      });
    }
  };

  componentDidMount() {
    if (this.props.location.pathname.indexOf('/full-register/producer/edit') >= 0) {
      this.setState({ isEdit: true })
      this.props.getProducerDetailByUuid()
        .then((response) => this.setState({ userProducer: response.value.data }))
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.createProducerStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.createProducerStatus === HttpRequestStatus.ERROR) {
      this.goToErrorAlertPage();
      this.props.reset();
    }
  }

  goToSuccessAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.producerRegister',
        buttonUrl: '/login',
      },
    });
  };

  goToErrorAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_NO,
        alertMessage: 'alert.producerRegisterError',
        buttonUrl: '/full-register/producer',
      },
    });
  }

  onCancelModal = () => {
    this.setState({
      showResponsabilityModal: false,
    });
  };

  onConfirmModal = () => {
    const { userProducer, isEdit } = this.state;

    if (userProducer.producer.generatorCompanyType === GeneratorCompanyType.HOLDING) {
      if (isEdit) {
        this.props.updateHolding(userProducer)
      } else {
        this.props.createHolding(userProducer);
      }
    } else if (userProducer.producer.generatorCompanyType === GeneratorCompanyType.NATURAL) {
      if (isEdit) {
        this.props.updatePhyscalPerson(userProducer);
      } else {
        this.props.createPhysicalPerson(userProducer);
      }
    } else {
      if (isEdit) {
        this.props.updateSubsidiary(userProducer)
      } else {
        this.props.createSubsidiary(userProducer);
      }
    }

    if (this.props.hasProducerAdminAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.PRODUCER_ADMIN);
    } else if (this.props.hasProducerUserAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.PRODUCER_USER);
    }
  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  private mapSteps = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => <ProducerRegisterCompany onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <ProducerRegisterAddress onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.responsible'),
      render: () => <ProducerRegisterResponsible onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => <ProducerRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
  ];

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  render() {
    const { steps, currentIndex } = this.state;
    const { createProducerStatus } = this.props;
    if (createProducerStatus === HttpRequestStatus.ONGOING) {
      return <Loading />;
    }
    return (
      <div style={{ position: 'relative' }}>
        <Steps steps={steps} onIndexClick={this.onIndexClick} currentIndex={currentIndex} />
        <AlertModal
          showModal={this.state.showResponsabilityModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={() => {
            this.onConfirmModal()
            this.onCancelModal()
          }}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="producerRegister.responsibilityTerms"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  createHolding,
  createSubsidiary,
  createPhysicalPerson,
  getSession,
  reset,
  changeAuthority,
  getProducerDetailByUuid,
  updateHolding,
  updateSubsidiary,
  updatePhyscalPerson
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  createProducerStatus: root.producerRegister.createProducerStatus,
  isCreatingNewProfile: root.authentication.isCreatingNewProfile,
  hasProducerUserAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.PRODUCER_USER]),
  hasProducerAdminAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.PRODUCER_ADMIN])
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerRegisterMenu);

import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm } from 'availity-reactstrap-validation';
import { Col, Row } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { handleRegister, reset } from './register.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { ALERT_ICONS } from 'app/config/constants';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import './register.scss';
import { fetchIsOnGoing, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import {
  validateEmail,
  validatePassword,
  validatePasswordMatch,
  validatePersonName
} from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import EmailModal from 'app/components/email-modal/email-modal';

export interface IRegisterProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IRegisterState {
  password: string;
  email: string;
  name: string;
  confirmPassword: string;
  emailError: ValidationResult;
  passwordError: ValidationResult;
  nameError: ValidationResult;
  confirmPasswordError: ValidationResult;
  modalEmail?: boolean,
}
export class RegisterPage extends React.Component<IRegisterProps, IRegisterState> {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      email: null,
      name: null,
      confirmPassword: null,
      emailError: ValidationResultInstance,
      passwordError: ValidationResultInstance,
      nameError: ValidationResultInstance,
      confirmPasswordError: ValidationResultInstance,
      modalEmail: false,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.registrationStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessAlertPage();
      this.props.reset();
    } else if (newProps.registrationStatus === HttpRequestStatus.ERROR) {
      this.setState({ modalEmail: true })
      this.props.reset();
    }
  }

  onChangeField = (key: string, value) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  handleValidSubmit = (event, values) => {
    const { name, password, email, emailError, passwordError, confirmPasswordError, nameError } = this.state;
    if (this.validateAllFields()) {
      return;
    }
    const user = {
      name: name,
      password: password,
      email: email,
    };
    this.props.handleRegister(user);
    event.preventDefault();
  };

  goToSuccessAlertPage = () => {
    this.props.history.push({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.register',
        buttonUrl: '/login',
        messageParams: { email: this.state.email },
      },
    });
  };
  validateAllFields = () => {
    let hasError = false;
    // if (this.onValidateName()) {
    //   hasError = true;
    // }
    if (this.onValidateEmail()) {
      hasError = true;
    }
    if (this.onValidatePassword()) {
      hasError = true;
    }
    if (this.onValidateConfirmPassword()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onValidatePassword = () => {
    const { password } = this.state;
    const validate = validatePassword(password);
    this.setState({
      passwordError: validate,
    });
    return validate.hasError;
  };

  onValidateConfirmPassword = () => {
    const { password, confirmPassword } = this.state;
    const validate = validatePasswordMatch({ password, match: confirmPassword });
    this.setState({
      confirmPasswordError: validate,
    });
    return validate.hasError;
  };

  closeEmailModal = () => {
    this.setState({ modalEmail: false })
  }

  handleBlur = () => {
    const { name } = this.state;
    const palavras = name.split(' ');
    const nomeFormatado = palavras
      .map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase())
      .join(' ');
    this.setState({ name: nomeFormatado });
    console.log('Formatado:', nomeFormatado)
  };

  render() {
    const { registrationStatus } = this.props;
    const { email, emailError, name, nameError, password, passwordError, confirmPassword, confirmPasswordError } = this.state;
    console.log('render - name:', name);
    if (fetchIsOnGoing(registrationStatus)) {
      return <Loading />;
    }
    return (
      <div className="register__container container">
        {/* {fetchIsOnGoing(registrationStatus) && <Loading />} */}
        <div className="register--title">
          <Translate contentKey="register.title">Registration</Translate>
          <span className="resitrack">
            <Translate contentKey="register.resitrack">Resitrack</Translate>
          </span>
        </div>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className="register--subtitle">
              <Translate contentKey="register.message">Message</Translate>
            </div>
            <div className="register--subtitle">
              <Translate contentKey="register.message-integration"></Translate>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <AvForm id="register-form" onValidSubmit={this.handleValidSubmit}>
              <CustomTextField
                onChange={e => this.onChangeField('name', e)}
                onBlur={this.handleBlur}
                id="name"
                style={{ marginTop: '20px' }}
                value={name}
                label={translate('register.form.name.placeholder')}
                placeholder={translate('register.form.name.placeholder')}
                error={nameError.hasError}
                errorText={nameError.errorMessage ? translate(nameError.errorMessage) : ''}
              />
              <CustomTextField
                onChange={e => this.onChangeField('email', e)}
                id="username"
                style={{ marginTop: '25px' }}
                isEmail
                value={email}
                label={translate('register.form.email.placeholder')}
                placeholder={translate('register.form.email.placeholder')}
                error={emailError.hasError}
                errorText={emailError.errorMessage ? translate(emailError.errorMessage) : ''}
                onBlur={this.onValidateEmail}
              />

              <CustomTextField
                id={'firstPassword'}
                style={{ marginTop: '25px' }}
                value={password}
                onChange={(text: string) =>
                  this.setState({
                    password: text,
                  })
                }
                error={passwordError.hasError}
                errorText={passwordError.errorMessage ? translate(passwordError.errorMessage) : ''}
                isPassword
                label={translate('register.form.newpassword.placeholder')}
                placeholder={translate('register.form.newpassword.placeholder')}
                onBlur={this.onValidatePassword}
              />
              <CustomTextField
                id={'secondPassword'}
                value={confirmPassword}
                style={{ marginTop: '25px' }}
                onChange={(text: string) =>
                  this.setState({
                    confirmPassword: text,
                  })
                }
                error={confirmPasswordError.hasError}
                errorText={confirmPasswordError.errorMessage ? translate(confirmPasswordError.errorMessage) : ''}
                isPassword
                label={translate('register.form.confirmpassword.placeholder')}
                placeholder={translate('register.form.confirmpassword.placeholder')}
                onBlur={this.onValidateConfirmPassword}
              />
              <div className={'button-container'}>
                <button className={'button primary'} disabled={fetchIsOnGoing(registrationStatus)} type={'submit'}>
                  <Translate contentKey={'userLogin.register'}>Register</Translate>
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
        <EmailModal closeModal={() => this.closeEmailModal()} showModal={this.state.modalEmail} email={email} />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  registrationStatus: root.register.registrationStatus,
});

const mapDispatchToProps = { handleRegister, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IUserProducer } from 'app/shared/model/user-producer.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export const ACTION_TYPES = {
  CREATE_HOLDING: 'producerRegister/CREATE_HOLDING',
  CREATE_SUBSIDIARY: 'producerRegister/CREATE_SUBSIDIARY',
  CREATE_NATURAL: 'producerRegister/CREATE_NATURAL',
  RESET: 'producerRegister/RESET',
};

const initialState = {
  errorMessage: null,
  entity: defaultValue,
  createProducerStatus: HttpRequestStatus.NOOP,
};

export type ProducerRegisterState = Readonly<typeof initialState>;

// Reducer

export default (state: ProducerRegisterState = initialState, action): ProducerRegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_HOLDING):
    case REQUEST(ACTION_TYPES.CREATE_NATURAL):
    case REQUEST(ACTION_TYPES.CREATE_SUBSIDIARY):
      return {
        ...state,
        errorMessage: null,
        createProducerStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_HOLDING):
    case FAILURE(ACTION_TYPES.CREATE_NATURAL):
    case FAILURE(ACTION_TYPES.CREATE_SUBSIDIARY):
      return {
        ...state,
        errorMessage: action.payload,
        createProducerStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.CREATE_HOLDING):
    case SUCCESS(ACTION_TYPES.CREATE_NATURAL):
    case SUCCESS(ACTION_TYPES.CREATE_SUBSIDIARY):
      return {
        ...state,
        entity: action.payload.data,
        createProducerStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-producers';

// Actions
export const createHolding: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const url = `${apiUrl}/holding`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HOLDING,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const createSubsidiary: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const url = `${apiUrl}/subsidiary`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_HOLDING,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const createPhysicalPerson: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const url = `${apiUrl}/natural`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NATURAL,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import React from 'react';
import { Translate } from 'react-jhipster';
import { Button } from 'reactstrap';
import './page-not-found.scss';
import { RouteComponentProps } from 'react-router-dom';

interface PageNotFoundProps extends RouteComponentProps<{}> {}

class PageNotFound extends React.Component<PageNotFoundProps> {
  render() {
    return (
      <div className="page-not-found__items">
        <div className="alert alert-danger">
          <div style={{ fontSize: '45px', color: '#B9B9B9', marginBottom: '30px' }}>Ops!</div>
          <div style={{ marginBottom: '65px' }}>
            <Translate contentKey="error.http.message-second" />
          </div>
          <Button className={'button primary'} type={'submit'} onClick={() => this.props.history.push('/login')}>
            <Translate contentKey="entity.action.back" />
          </Button>
          {/* <Translate contentKey="error.http.404">The page does not exist.</Translate> */}
        </div>
      </div>
    );
  }
}

export default PageNotFound;

import { FavoriteType } from 'app/shared/model/favorite.model';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './favorite-transporter-list.scss';
import { RouteComponentProps } from 'react-router-dom';
import { GenericFavorite } from 'app/modules/generic/favorite-list/generic-favorite';
import { Container } from 'reactstrap';
import GenericFavoriteList from 'app/modules/generic/favorite-list/generic-favorite-list';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';

export interface IFavoriteTransporterListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IFavoriteTransporterListState { }

export class FavoriteTransporterList extends GenericFavorite {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mtr-invite__items">
        <BackHeaderComponent onBack={this.handleGoBack} />
        <Container>
          <div className="back-component-margin">
            <GenericFavoriteList
              title={this.titleTransporter}
              page={this.page}
              headers={this.tableTransporterHeaders}
              favoriteType={FavoriteType.TRANSPORTER}
              onItemClick={this.onItemClicked}
            />
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteTransporterList);

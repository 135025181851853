export interface IDocument {
  id?: number;
  url?: string;
  presignedUrl?: string;
  description?: string;
  fileName?: string;
  name?: string;
  s3Name?: string;
  processed?: boolean;
  fileContentType?: string;
  file?: any;
}

export const defaultValue: Readonly<IDocument> = {
  processed: false,
};

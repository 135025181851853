import GenericCardChoice from 'app/components/generic-card-choice/generic-card-choice';
import { ITransporter, PersonType } from 'app/shared/model/transporter.model';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';
import { IUser } from 'app/shared/model/user.model';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import TransporterRegisterLegal from './legal/transporter-register-legal';
import { TransporterRegisterNatural } from './natural/transporter-register-natural';
import './transporter-register-person.scss';

export interface ITransporterRegisterPersonProps extends StateProps, DispatchProps {
  userTransporter: IUserTransporter;
  onNext: (userTransporter: IUserTransporter) => void;
  onBack: () => void;
}

export interface ITransporterRegisterPersonState {
  selectedPersonType: PersonType;
  isPersonSelected: boolean;
  transporter: ITransporter;
}

export class TransporterRegisterPerson extends React.Component<ITransporterRegisterPersonProps, ITransporterRegisterPersonState> {
  constructor(props) {
    super(props);
    const { transporter } = this.props.userTransporter;
    this.state = {
      selectedPersonType: transporter ? transporter.personType : null,
      isPersonSelected: transporter ? transporter.personType != null : false,
      transporter: transporter ? transporter : {},
    };
  }

  mapType = () => {
    const objects = Object.keys(PersonType).map(key => {
      return { name: translate(`enums.personType.${key}`), keyEnum: key };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  search = () => { };

  onBack = () => {
    this.props.onBack();
  };

  onSelectPersonType = (index: PersonType) => {
    this.setState({
      selectedPersonType: index,
      isPersonSelected: true,
    });
  };

  onNext = (personTransporter: ITransporter) => {
    const { selectedPersonType } = this.state;
    const userTransporter = { ...this.props.userTransporter };
    const user: IUser = {
      ...userTransporter.user,
      cpf: personTransporter.naturalPerson ? personTransporter.naturalPerson.cpf : userTransporter.user.cpf,
      phone: personTransporter.naturalPerson ? personTransporter.naturalPerson.phone : userTransporter.user.phone,
      name: personTransporter.naturalPerson ? personTransporter.naturalPerson.name : userTransporter.user.name,
    };
    const updatedTransporter: ITransporter = {
      ...userTransporter.transporter,
      legalPerson: personTransporter.legalPerson,
      naturalPerson: personTransporter.naturalPerson,
      cnaeCodes: personTransporter.cnaeCodes,
      personType: selectedPersonType,
    };
    userTransporter.transporter = updatedTransporter;
    userTransporter.user = user;
    this.props.onNext(userTransporter);
  };

  renderForms = () => {
    const { selectedPersonType, transporter } = this.state;

    return (
      <div>
        {selectedPersonType == PersonType.LEGAL && (
          <TransporterRegisterLegal transporter={transporter} onNext={this.onNext} onBack={this.onBack} />
        )}
        {selectedPersonType == PersonType.NATURAL && (
          <TransporterRegisterNatural transporter={transporter} onNext={this.onNext} onBack={this.onBack} />
        )}
      </div>
    );
  };

  renderChoice = () => {
    const { isPersonSelected, selectedPersonType } = this.state;
    const transporterRegister = translate('transporterRegister.person');
    return (
      <div className="transporter-register-person__items">
        <h1>{translate('transporterRegister.person.legal.title')}</h1>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <div className="transporter-register-person__items--subtitle">
              {' '}
              <span>{translate('transporterRegister.person.selectPerson')}</span>
            </div>
            <div className="transporter-register-person__items--cards">
              <Row>
                <GenericCardChoice
                  index={PersonType.NATURAL}
                  title={transporterRegister.natural.title}
                  text={transporterRegister.natural.text}
                  onSelect={this.onSelectPersonType}
                  selected={selectedPersonType === PersonType.NATURAL}
                  hideText={isPersonSelected}
                />
                <GenericCardChoice
                  index={PersonType.LEGAL}
                  title={transporterRegister.legal.subtitle}
                  text={transporterRegister.legal.text}
                  onSelect={this.onSelectPersonType}
                  selected={selectedPersonType === PersonType.LEGAL}
                  hideText={isPersonSelected}
                />
              </Row>
            </div>
            {isPersonSelected && this.renderForms()}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return <div>{this.renderChoice()}</div>;
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterPerson);

import React, { HTMLAttributes } from 'react';
import { Translate } from 'react-jhipster';
import './button-payment.scss';

interface Props extends HTMLAttributes<HTMLButtonElement> {
    isSelect: boolean;
    isTypeButtonCreditCard?: boolean;
}

const ButtonPayment = ({ isSelect, isTypeButtonCreditCard, ...rest }: Props) => {
    return (
        <button className={`button-payment-method${isSelect ? '-active' : ''}`} {...rest}>
            <div className={`button-payment-method${isTypeButtonCreditCard ? '--icon-credit-card' : '--icon-file'}`} />
            <div className='button-payment-method--text'>
                <Translate contentKey={`register.select-payment.${isTypeButtonCreditCard ? 'creditCard' : 'bankSlip'}`} />
            </div>
        </button>
    );
};

export default ButtonPayment;

import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { connect } from 'react-redux';
import './our-plans.scss'
import { Col, Container, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import ManagerProfileCards from './cards/manager-profile-cards/ManagerProfileCards';
import GeneratorProfileCards from './cards/generator-profile-cards/GeneratorProfileCards';
import TransporterProfileCards from './cards/transporter-profile-cards/TransporterProfileCards';
import TranshipmentAndFinalDestination from './cards/transhipment-and-final-destination/TranshipmentAndFinalDestinationProfileCard';
import { useHistory } from 'react-router-dom';

const OurPlans = (props: StateProps) => {
    const history = useHistory();

    const getProfileName = () => {
        if (props.isManagerAdmin) {
            return <Translate contentKey={'register.plans.profileName.manager'} />;
        }

        if (props.isProducerAdmin) {
            return <Translate contentKey={'register.plans.profileName.generator'} />;
        }

        if (props.isTransporterAdmin) {
            return <Translate contentKey={'register.plans.profileName.transporter'} />;
        }

        if (props.isTransshipmentAdmin) {
            return <Translate contentKey={'register.plans.profileName.transhipment'} />;
        }

        if (props.isFinalDestinationAdmin) {
            return <Translate contentKey={'register.plans.profileName.finalDestination'} />;
        }
    };

    return (
        <div className='plans__container'>
            <Container style={{ overflow: 'auto', position: 'relative' }}>
                <Row>
                    <Col style={{ margin: '0 auto' }}>
                        <span className='plans__container--title-main'>
                            <Translate contentKey={'register.plans.discoverPlans'} />
                        </span>
                        {props.hasSubscription && <div className='plans__container--icon-close' onClick={() => history.goBack()} />}
                        <div className='plans__container--profile'>
                            <span className='plans__container--profile-name'>{getProfileName()}</span>
                        </div>
                    </Col>
                </Row>

                {props.isManagerAdmin && <ManagerProfileCards hasSubscription={props.hasSubscription} />}
                {props.isProducerAdmin && <GeneratorProfileCards hasSubscription={props.hasSubscription} />}
                {props.isTransporterAdmin && <TransporterProfileCards hasSubscription={props.hasSubscription} />}
                {(props.isTransshipmentAdmin || props.isFinalDestinationAdmin) && <TranshipmentAndFinalDestination />}
            </Container>
        </div>
    );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isManagerAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
    isProducerAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN]),
    isTransporterAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSPORTER_ADMIN]),
    isTransshipmentAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN]),
    isFinalDestinationAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN]),
    hasSubscription: authentication.account.hasSubscription
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(OurPlans);

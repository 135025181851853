import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './state.reducer';

export interface IStateDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class StateDetail extends React.Component<IStateDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { stateEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.state.detail.title">State</Translate> [<b>{stateEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="name">
                <Translate contentKey="resitrackApp.state.name">Name</Translate>
              </span>
            </dt>
            <dd>{stateEntity.name}</dd>
            <dt>
              <span id="acronym">
                <Translate contentKey="resitrackApp.state.acronym">Acronym</Translate>
              </span>
            </dt>
            <dd>{stateEntity.acronym}</dd>
            <dt>
              <span id="active">
                <Translate contentKey="resitrackApp.state.active">Active</Translate>
              </span>
            </dt>
            <dd>{stateEntity.active ? 'true' : 'false'}</dd>
            <dt>
              <Translate contentKey="resitrackApp.state.country">Country</Translate>
            </dt>
            <dd>{stateEntity.country ? stateEntity.country.name : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/state" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/state/${stateEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ state }: IRootState) => ({
  stateEntity: state.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StateDetail);

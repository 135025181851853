import { IMtr } from 'app/shared/model/mtr.model';
import { IProducer } from 'app/shared/model/producer.model';
import { IFinalDestination } from 'app/shared/model/final-destination.model';
import { ISignature } from 'app/shared/model/signature.model';
import { Moment } from 'moment';
import { CdfStatus } from 'app/shared/model/enum/cdfStatus';
import { IIntegrationCdf } from './integration-cdf.model';

export interface Cdf {
  id?: number;
  mtrs?: IMtr[];
  cdfNumber?: string;
  producer?: IProducer;
  cdfStatus?: CdfStatus;
  finalDestination?: IFinalDestination;
  emissionDate?: string | Date | Moment;
  finalDestinationSignature?: ISignature;
  finalDestinationSignatureDate?: string | Date | Moment;
  integrationCdf?: IIntegrationCdf[];
  startDate?: string;
  endDate?: string;
}

export const defaultValue: Cdf = {
  cdfStatus: CdfStatus.PENDING,
};

import ICreditCard from 'app/shared/model/credit-card.model';
import axios from 'axios';

const basePath = 'api/subscriptions/customer/cards';

const creditCardApi = () => {
  const createCreditCard = (creditCard: ICreditCard) => {
    return axios.post<ICreditCard>(`${basePath}`, creditCard);
  };

  const getAllCreditCards = () => {
    return axios.get<ICreditCard[]>(`${basePath}`);
  };

  const deleteCreditCard = (creditCardId: number) => {
    return axios.delete<ICreditCard>(`${basePath}/${creditCardId}`);
  };

  return {
    createCreditCard,
    getAllCreditCards,
    deleteCreditCard,
  };
};

export default creditCardApi();

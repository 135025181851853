import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { translate, Translate } from 'react-jhipster';
import './purchase-summary.scss';
import greenIndustrySvg from '../../../../../../../content/images/icons/green-industry.svg';
import creditCardGreenSvg from '../../../../../../../content/images/icons/credit-card-green.svg';
import { useLocation, useHistory } from 'react-router-dom';
import { PremiumPlan } from '../payment-method/PaymentMethod';
import { realCoinMask, realCoinMaskWithoutSymbol } from 'app/shared/util/mask-utils';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import PlanService from 'app/services/PlanService';
import PublicIpAddressService from 'app/services/publicIpAddressService';
import TypesOfPlans from 'app/shared/model/enum/typeOfPlans';
import PaymentMethodType from 'app/shared/model/enum/paymentMethodType';
import { ITypeSendPlan, IBankSlipType } from 'app/shared/model/plan.model';
import { convertBrazilianStandardDate } from 'app/shared/util/date-utils';
import IAddressIpType from 'app/shared/model/public-ip-address.model';
import ButtonLoading from 'app/components/button-loding/ButtonLoading';
import planApi from 'app/api/planApi';

const supportEmail = 'suporte@resitrack.com.br';
const supportCel = '+55 41 98860-0819';
const discountPercentage = 20;

const PurchaseSummary = (props: StateProps) => {
    const location = useLocation<PremiumPlan>();
    const history = useHistory();
    const { price, planName, creditCard } = location.state;
    const discount = (discountPercentage / 100) * price;

    const [isPurchaseSummary, setIsPurchaseSummary] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bankSlip, setBankSlip] = useState<IBankSlipType>();
    const [planId, setPlanId] = useState<number>();

    const [ipAddress, setIpAddress] = useState<IAddressIpType>();

    useEffect(() => {
        if (planId != null && creditCard == null) {
            PlanService.getBankSlip(planId).then(setBankSlip);
        }
    }, [planId]);

    useEffect(() => {
        PublicIpAddressService.getIp().then(setIpAddress);
    }, []);

    const handlePremiumSubscription = () => {
        setIsLoading(true);

        const paymentDetails: ITypeSendPlan = {
            planType: TypesOfPlans.PREMIUM,
            billingType: creditCard != null ? PaymentMethodType.CREDIT_CARD : PaymentMethodType.BOLETO,
            remoteIp: ipAddress.ipAddress
        };

        if (creditCard != null) {
            paymentDetails.customerBillingInfo = { id: creditCard.id };
        }

        props.hasSubscription ? (
            PlanService.updatePlan(paymentDetails).then((plan) => {
                setPlanId(plan.id);
                setIsLoading(false);
                setIsPurchaseSummary(true);
            })
        ) : (
            PlanService.createPlan(paymentDetails).then((plan) => {
                setPlanId(plan.id);
                setIsLoading(false);
                setIsPurchaseSummary(true);
            })
        );

        if (paymentDetails.billingType === PaymentMethodType.BOLETO) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
                history.push('/full-register/bankslip-summary');
            }, 5000); // 5 segundos em milissegundos
        }
    };

    return (
        <div className='purchase-summary__container'>
            <Container style={{ maxWidth: '786px', overflow: 'auto' }}>
                {!isPurchaseSummary ? (
                    <>
                        <Row>
                            <Col>
                                <div className='purchase-summary__container--title'>
                                    <Translate contentKey={'register.purchaseSummary.purchase'} />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ backgroundColor: '#FFFFFF', padding: '30px 15px' }}>
                            <Col md='7' className='col-data-plan'>
                                <div className='purchase-summary__container--title-card'>
                                    <Translate contentKey={'register.purchaseSummary.summary'} />
                                </div>
                                <div className='purchase-summary__container--container-plan'>
                                    <figure style={{ flexGrow: 1, minWidth: '100px', maxWidth: '100px' }}>
                                        <img src={greenIndustrySvg} alt="Imagem plano selecionado" />
                                    </figure>
                                    <div className='purchase-summary__container--container-plan-data-plan-selected'>
                                        <span className='purchase-summary__container--container-plan-data-plan-selected-plan-name'>{planName}</span>
                                        <span className='purchase-summary__container--container-plan-data-plan-selected-profile'>{realCoinMask(price)}</span>
                                    </div>
                                    <button
                                        onClick={() => history.push('/full-register/plans')}
                                    >
                                        <Translate contentKey={'register.purchaseSummary.edit'} />
                                    </button>
                                </div>

                                {creditCard != null && (
                                    <div className='purchase-summary__container--container-credit-card'>
                                        <figure style={{ flexGrow: 1, minWidth: '100px', maxWidth: '100px' }}  >
                                            <img src={creditCardGreenSvg} alt="Imagem cartão de crédito" />
                                        </figure>
                                        <div className='purchase-summary__container--container-credit-card-data-card'>
                                            <span className='purchase-summary__container--container-plan-data-plan-selected-plan-name'>
                                                {creditCard.brand}
                                            </span>
                                            <div className='purchase-summary__container--container-credit-card-info-card'>
                                                <Translate contentKey={'register.purchaseSummary.end'} />
                                                {creditCard.lastFourDigits}
                                            </div>
                                        </div>
                                        <button onClick={() => history.goBack()}>
                                            <Translate contentKey={'register.purchaseSummary.edit'} />
                                        </button>
                                    </div>
                                )}
                            </Col>
                            <Col style={{ maxWidth: '400px' }}>
                                <div className='purchase-summary__container--title-card'>
                                    <Translate contentKey={'register.purchaseSummary.purchaseData'} />
                                </div>

                                <div className='purchase-summary__container--buy-container'>
                                    <div className='purchase-summary__container--buy-container-price-container'>
                                        <div className='purchase-summary__container--buy-container-price-container-subtotal'>
                                            <Translate contentKey={'register.purchaseSummary.subtotal'} />
                                        </div>
                                        <div className='purchase-summary__container--buy-container-price-container-dollar-sign'>
                                            R$
                                            <span className='purchase-summary__container--buy-container-price-container-value'>{` ${realCoinMaskWithoutSymbol(price + discount)}`}</span>
                                            <span className='purchase-summary__container--buy-container-price-container-month'>
                                                <Translate contentKey={'register.purchaseSummary.month'} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='purchase-summary__container--buy-container'>
                                    <div className='purchase-summary__container--buy-container-price-container'>
                                        <div className='purchase-summary__container--buy-container-price-container-subtotal' style={{ color: '#000' }}>
                                            <Translate contentKey={'register.purchaseSummary.discount'} />
                                        </div>
                                        <div className='purchase-summary__container--buy-container-price-container-dollar-sign'>
                                            <span>{"R$ "}</span>
                                            <span className='purchase-summary__container--buy-container-price-container-value'>{realCoinMaskWithoutSymbol(discount)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='purchase-summary__container--buy-container' style={{ marginTop: '40px', borderBottom: '1px solid #E1E2E6', paddingBottom: '3px' }}>
                                    <div className='purchase-summary__container--buy-container-price-container'>
                                        <div className='purchase-summary__container--buy-container-price-container-subtotal' style={{ fontWeight: 700 }}>
                                            <Translate contentKey={'register.purchaseSummary.total'} />
                                        </div>
                                        <div className='purchase-summary__container--buy-container-price-container-dollar-sign'>
                                            R$
                                            <span className='purchase-summary__container--buy-container-price-container-value'>{` ${realCoinMaskWithoutSymbol(price)}`}</span>
                                            <span className='purchase-summary__container--buy-container-price-container-month'>
                                                <Translate contentKey={'register.purchaseSummary.month'} />
                                            </span>
                                            <div className='purchase-summary__container--buy-container-price-container-form-payment'>
                                                {creditCard != null ? <Translate contentKey={'register.purchaseSummary.inCard'} /> : <Translate contentKey={'register.purchaseSummary.onTicket'} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                                    <ButtonLoading
                                        className='button fourth button-fourth'
                                        colorIconLoading="white"
                                        textButton={translate('register.purchaseSummary.confirm')}
                                        isLoading={isLoading}
                                        onClick={() => handlePremiumSubscription()}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row style={{ backgroundColor: '#FFFFFF', padding: '40px 80px' }}>
                            <Col>

                                {/* <span> */}
                                {creditCard != null ? (
                                    <>
                                        <div className='purchase-summary__container--title'>
                                            <p>
                                                Seu plano foi escolhido com<span style={{ color: '#00A038' }}> sucesso!</span>
                                            </p>
                                            <p>
                                                Clique em Entrar na Resitrack, para começar a utilizar a ferramenta!
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                                <Translate contentKey={'register.purchaseSummary.totalWithSymbol'} />
                                            </p>
                                            {realCoinMask(price)}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <p style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                                <Translate contentKey={'register.purchaseSummary.payment'} />
                                            </p>
                                            <Translate contentKey={'register.select-payment.creditCard'} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Row>
                                            <Col>
                                                <div className='purchase-summary__container--title'>
                                                    Seu plano foi escolhido com sucesso! <span style={{ color: '#00A038' }}>GERANDO SEU BOLETO POR FAVOR, AGUARDE!</span>
                                                    <div className='purchase-summary__container--loading'>
                                                        <div className="spinner"></div>
                                                        <p>Carregando...</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                {/* </span> */}
                                {creditCard != null ?
                                    <div className='purchase-summary__container--button-container'>
                                        <Button
                                            onClick={() => history.replace('/me/profile/plans')}
                                            className={'button fifth'}
                                        >
                                            <Translate contentKey={'register.purchaseSummary.enterResitrack'} />
                                        </Button>
                                        {/* {bankSlip != null && (
                                        <a href={`${bankSlip.bankSlipUrl}`} target="_blank" style={{ textDecoration: "none" }}>
                                            <Button className={'button fourth button-fourth'}>
                                                <Translate contentKey={'register.purchaseSummary.printTicket'} />
                                            </Button>
                                        </a>
                                    )} */}
                                    </div>
                                    :
                                    <>
                                    </>
                                }
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    <Col>
                        <div className='purchase-summary__container--informational-bank-slip'>
                            <Translate contentKey={'register.purchaseSummary.informationalTextBankSlip'} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='purchase-summary__container--support-text'>
                            <Translate contentKey={'register.purchaseSummary.supportText'} />
                            <div className='purchase-summary__container--support-text-support-email'>{supportEmail}</div>
                            <div className='purchase-summary__container--support-text-support-email'>
                                <img className='whats' />{supportCel}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account.hasSubscription
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PurchaseSummary);
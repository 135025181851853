import React from 'react';
import './holding-detail-component.scss';
import { translate } from 'react-jhipster';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { GeneratorCompanyType } from 'app/shared/model/producer.model';
import { Row } from 'reactstrap';
import { maskCnpj, maskPhone } from 'app/shared/util/mask-utils';

export interface IHoldingDetailComponentProps {
  userProducer: IUserProducer;
  isAdmin: boolean;
  onEditHolding: (id: number) => void;
}

export interface IHoldingDetailComponentState {
  open: boolean;
  holding: IUserProducer;
}

export class HoldingDetailComponent extends React.Component<IHoldingDetailComponentProps, IHoldingDetailComponentState> {
  constructor(props: Readonly<IHoldingDetailComponentProps>) {
    super(props);
    const { userProducer } = this.props;
    this.state = {
      open: false,
      holding:
        userProducer && userProducer.producer
          ? userProducer.producer.generatorCompanyType === GeneratorCompanyType.HOLDING
            ? userProducer
            : {
                producer: userProducer.producer.holdingCompany,
              }
          : null,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userProducer != this.props.userProducer) {
      const userProducer = newProps.userProducer;
      this.setState({
        holding:
          userProducer && userProducer.producer
            ? userProducer.producer.generatorCompanyType === GeneratorCompanyType.HOLDING
              ? userProducer
              : userProducer.producer.holdingCompany
            : null,
      });
    }
  }

  toggleCollapse = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderClosed = () => {
    const { holding } = this.state;
    return (
      <div className="mtr-unity__items--matrix-collapsed" onClick={this.toggleCollapse}>
        <div className="mtr-unity__items--text">{holding ? holding.producer.fantasyName : ''}</div>
        <div className="mtr-unity__items--img --collapsed" />
      </div>
    );
  };

  renderOpen = () => {
    const { isAdmin } = this.props;
    const { holding } = this.state;
    return (
      <div>
        <div className="mtr-unity__items--matrix-open">
          <div className="mtr-unity__info--container">
            <div className="mtr-unity__title-button--container">
              <span className="mtr-unity__title-button--title">{holding ? holding.producer.fantasyName.toUpperCase() : ''}</span>
              {this.props.userProducer.producer.generatorCompanyType === GeneratorCompanyType.HOLDING && isAdmin && (
                <span className="mtr-unity__title-button--button" onClick={() => this.props.onEditHolding(holding.id)}>
                  {translate('entity.action.edit').toUpperCase()}
                </span>
              )}
            </div>
            <Row className="mtr-unity__items--matrix-line-info">
              <div style={{borderRight:'none', borderBottom:'none'}} className="mtr-unity__items--matrix-info">
                <span className="span--title">{translate('producer.units.holding.fantasyName')}</span>
                <span className="span--info">{holding ? holding.producer.fantasyName : ''}</span>
              </div>
              <div style={{borderBottom:'none'}} className="mtr-unity__items--matrix-info">
                <span className="span--title">{translate('producer.units.holding.cnpj')}</span>
                <span className="span--info">{holding ? maskCnpj(holding.producer.cnpj) : ''}</span>
              </div>
            </Row>
            <Row className="mtr-unity__items--matrix-line-info">
              <div style={{borderRight:'none'}} className="mtr-unity__items--matrix-info">
                <span className="span--title">{translate('producer.units.holding.phone')}</span>
                <span className="span--info">{holding ? maskPhone(holding.producer.phone) : ''}</span>
              </div>
              <div className="mtr-unity__items--matrix-info --no-right-border">
                <span className="span--title">{translate('producer.units.holding.municipalRegistration')}</span>
                <span className="span--info">{holding ? holding.producer.municipalRegistration : ''}</span>
              </div>
              <div className="mtr-unity__items--matrix-info --no-left-border">
                <span className="span--title">{translate('producer.units.holding.cnaeCode')}</span>
                <span className="span--info">{holding ? holding.producer.cnaeCodes[0].code : ''}</span>
              </div>
            </Row>
          </div>
          <div className="mtr-unity__items--img" onClick={this.toggleCollapse} />
        </div>
      </div>
    );
  };

  render() {
    const { open } = this.state;
    const { holding } = this.state;
    if (holding == null || holding.producer == null) {
      return <div />;
    }
    return (
      <div>
        {!open && this.renderClosed()}
        {open && this.renderOpen()}
      </div>
    );
  }
}

export default HoldingDetailComponent;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './manager-register-legal.scss';
import { translate } from 'react-jhipster';
import { IManager } from 'app/shared/model/manager.model';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import { validateCNPJ, validateGenericField, validatePhoneNumber, } from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { getEntities as getCnaeCodes } from 'app/entities/cnae-code/cnae-code.reducer';
import { maskCnpj, maskPhone, unmask } from 'app/shared/util/mask-utils';
import { ILegalPerson } from 'app/shared/model/legal-person.model';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface IManagerRegisterLegalProps extends StateProps, DispatchProps {
  manager: IManager;
  onNext: (manager: IManager) => void;
  onBack: () => void;
}

export interface IManagerRegisterLegalState {
  cnpj: string;
  fantasyName: string;
  companyName: string;
  phone: string;
  cnpjError: ValidationResult;
  fantasyNameError: ValidationResult;
  companyNameError: ValidationResult;
  phoneError: ValidationResult;
}

export class ManagerRegisterLegal extends React.Component<IManagerRegisterLegalProps, IManagerRegisterLegalState> {
  constructor(props) {
    super(props);
    const legalPerson = this.props.manager.legalPerson;
    this.state = {
      cnpj: legalPerson ? maskCnpj(legalPerson.cnpj) : null,
      fantasyName: legalPerson ? legalPerson.fantasyName : null,
      companyName: legalPerson ? legalPerson.companyName : null,
      phone: legalPerson ? maskPhone(legalPerson.phone) : null,
      cnpjError: ValidationResultInstance,
      fantasyNameError: ValidationResultInstance,
      companyNameError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    await this.props.getCnaeCodes();
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateCnpj()) {
      hasError = true;
    }
    if (this.onValidateFantasyName()) {
      hasError = true;
    }
    if (this.onValidateCompanyName()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateCnpj = () => {
    const { cnpj } = this.state;
    const validate = validateCNPJ(cnpj);
    this.setState({
      cnpjError: validate,
    });
    return validate.hasError;
  };

  onValidateFantasyName = () => {
    const { fantasyName } = this.state;
    const validate = validateGenericField(fantasyName);
    this.setState({
      fantasyNameError: validate,
    });
    return validate.hasError;
  };

  onValidateCompanyName = () => {
    const { companyName } = this.state;
    const validate = validateGenericField(companyName);
    this.setState({
      companyNameError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };


  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }
    const { cnpj, fantasyName, companyName, phone } = this.state;
    const { manager } = this.props;

    const legalPerson: ILegalPerson = {
      cnpj: unmask(cnpj),
      fantasyName,
      companyName,
      phone: unmask(phone),
    };

    const managerUpdated: IManager = {
      ...manager,
      legalPerson,
    };

    this.props.onNext(managerUpdated);
  };

  render() {
    const {
      cnpj,
      cnpjError,
      fantasyName,
      fantasyNameError,
      companyName,
      companyNameError,
      phone,
      phoneError,
    } = this.state;
    return (
      <div className="manager-register-legal__items">
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              cnpj: text,
            })
          }
          id="cnpj"
          style={{ marginTop: '20px' }}
          value={cnpj}
          onMask={maskCnpj}
          label={translate('managerRegister.person.legal.form.cnpj')}
          placeholder={translate('managerRegister.person.legal.form.cnpj')}
          error={cnpjError.hasError}
          errorText={translateErrorMessage(cnpjError.errorMessage)}
          onBlur={this.onValidateCnpj}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              fantasyName: text,
            })
          }
          id="fantasyName"
          style={{ marginTop: '20px' }}
          value={fantasyName}
          label={translate('managerRegister.person.legal.form.fantasyName')}
          placeholder={translate('managerRegister.person.legal.form.fantasyName')}
          error={fantasyNameError.hasError}
          errorText={translateErrorMessage(fantasyNameError.errorMessage)}
          onBlur={this.onValidateFantasyName}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              companyName: text,
            })
          }
          id="companyName"
          style={{ marginTop: '20px' }}
          value={companyName}
          label={translate('managerRegister.person.legal.form.companyName')}
          placeholder={translate('managerRegister.person.legal.form.companyName')}
          error={companyNameError.hasError}
          errorText={translateErrorMessage(companyNameError.errorMessage)}
          onBlur={this.onValidateCompanyName}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              phone: text,
            })
          }
          onMask={maskPhone}
          id="phone"
          style={{ marginTop: '20px' }}
          value={phone}
          label={translate('managerRegister.person.legal.form.phone')}
          placeholder={translate('managerRegister.person.legal.form.phone')}
          error={phoneError.hasError}
          errorText={translateErrorMessage(phoneError.errorMessage)}
          onBlur={this.onValidatePhone}
        />
        <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCnaeCodes,
};

const mapStateToProps = (root: IRootState) => ({
  cnaeCodes: root.cnaeCode.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerRegisterLegal);

import IAddressIpType from 'app/shared/model/public-ip-address.model';
import axios, { AxiosRequestConfig } from 'axios';

const publicIpAddressApi = () => {
  const config: AxiosRequestConfig = {
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);

  const getIp = () => {
    return instance.get<IAddressIpType>('https://api.db-ip.com/v2/free/self');
  };

  return {
    getIp,
  };
};

export default publicIpAddressApi();

import { IUser } from 'app/shared/model/user.model';
import { IProducer } from 'app/shared/model/producer.model';
import { ClassCouncil } from './user-generic.model';

export interface IUserProducer {
  id?: number;
  registrationNumber?: string;
  classCouncil?: ClassCouncil;
  user?: IUser;
  producer?: IProducer;
}

export const defaultValue: Readonly<IUserProducer> = {};

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { maskCPF, maskPhone } from 'app/shared/util/mask-utils';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import { getDriverDetail, reset } from '../../transport-reducer';
import moment from 'moment';

export interface IDriverDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IDriverDetailsState {}

export class DriverDetails extends React.Component<IDriverDetailsProps, IDriverDetailsState> {
  constructor(props: Readonly<IDriverDetailsProps>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getDriverDetail(id);
    }
  }

  componentWillReceiveProps(newProps) {}

  handleGoBack = () => {
    this.props.history.replace('/transporter/transport/drivers');
  };

  onEditDriver = (id: number) => {
    this.props.history.push({
      pathname: `/transporter/transport/drivers/edit`,
      state: { id: id },
    });
  };

  renderComponent = (title: string, info: string) => (
    <div className="list__items--component">
      <div className="list__items--title">{title}</div>
      <div className="list__items--text">{info}</div>
    </div>
  );

  render() {
    const { driverDetail, getDriveDetailStatus } = this.props;
    if (checkStatusOnGoing([getDriveDetailStatus])) {
      return <Loading />;
    }
    return (
      <div className="list__items">
        <div className="list__items--back">
          <Container>
            <Row>
              <Col md="10">
                <div className="confirmation--back" onClick={this.handleGoBack}>
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              <Col md="2">
                <div className="list__items--edit" onClick={() => this.onEditDriver(driverDetail.id)}>
                  <Translate contentKey={'mtr-signature.edit'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <h1 style={{ fontWeight: 550 }}>{driverDetail.userTransporter ? driverDetail.userTransporter.user.name : ''}</h1>
        </div>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('transporter.transport.driver.data')}</div>
            {this.renderComponent(
              translate('transporter.transport.driver.info.email'),
              driverDetail.userTransporter ? driverDetail.userTransporter.user.email : ''
            )}
            {this.renderComponent(
              translate('transporter.transport.driver.info.name'),
              driverDetail.userTransporter ? driverDetail.userTransporter.user.name : ''
            )}
            {this.renderComponent(
              translate('transporter.transport.driver.info.cpf'),
              driverDetail.userTransporter ? maskCPF(driverDetail.userTransporter.user.cpf) : ''
            )}
            {this.renderComponent(
              translate('transporter.transport.driver.info.phone'),
              driverDetail.userTransporter ? maskPhone(driverDetail.userTransporter.user.phone) : ''
            )}
            {this.renderComponent(
              translate('transporter.transport.driver.info.driverLicense'),
              driverDetail.driverLicense ? driverDetail.driverLicense : ''
            )}
            {this.renderComponent(
              translate('transporter.transport.driver.info.driverType'),
              driverDetail.driverType ? driverDetail.driverType : ''
            )}
            {this.renderComponent(
              translate('transporter.transport.driver.info.expireDate'),
              driverDetail.expirationDate ? moment(driverDetail.expirationDate).format('DD/MM/YYYY') : ''
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  driverDetail: root.transport.driverDetail,
  getDriveDetailStatus: root.transport.getDriveDetailStatus,
});

const mapDispatchToProps = {
  getDriverDetail,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetails);

import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import Loading from 'app/components/loading/loading';
import {
  validateEmail,
  validateGenericField,
  validateObject,
  validatePersonName
} from 'app/modules/validation/validation-constants';
import {IRootState} from 'app/shared/reducers';
import {getSession} from 'app/shared/reducers/authentication';
import {
  checkStatusOnGoing,
  translateErrorMessage,
  ValidationResult,
  ValidationResultInstance
} from 'app/shared/util/validation-utils';
import React from 'react';
import {translate, Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import './user-profile-data-edit.scss';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import {reset, updateAccount} from '../../profile-reducer';
import {IUser} from 'app/shared/model/user.model';
import AlertModal from 'app/components/alert-modal/alert-modal';
import {HttpRequestStatus} from 'app/shared/model/enum/HttpRequestStatus';
import DropdownSearch from "app/components/dropdown-search/dropdown-search";
import {maskCnpj, maskCPF, maskPhone, maskZipCode} from "app/shared/util/mask-utils";
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import {IAddress} from "app/shared/model/address.model";
import {ICity} from 'app/shared/model/city.model';
import {IState} from 'app/shared/model/state.model';
import {getEntities as getStates} from 'app/entities/state/state.reducer';
import {getEntitiesByStateId as getCitiesByState} from 'app/entities/city/city.reducer';

export interface IUserProfileDataEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
  //addressMtrDTO: IAddress;
}

export interface IUserProfileDataEditState {
  addressMtrDTO: IAddress;
  name: string;
  email: string;
  companyName: string;
  fantasyName: string;
  cnpj: string;
  cpf: string;
  phoneProfile: string;
  street: string;
  complement: string;
  city: ICity;
  selectedState: IState;
  zipcode: string;

  nameError: ValidationResult;
  emailError: ValidationResult;
  companyNameError: ValidationResult;
  fantasyNameError: ValidationResult;
  cnpjError: ValidationResult;
  cpfError: ValidationResult;
  phoneProfileError: ValidationResult;
  cityError: ValidationResult;
  stateError: ValidationResult;
  streetError: ValidationResult;
  complementError: ValidationResult;
  zipcodeError: ValidationResult;
  isStateSelected: boolean;

  showSuccess: boolean;
  showErrorModal: boolean;
}

export class UserProfileDataEdit extends React.Component<IUserProfileDataEditProps, IUserProfileDataEditState> {
  constructor(props: Readonly<IUserProfileDataEditProps>) {
    super(props);
    this.state = {
      addressMtrDTO: this.props.account.addressMtrDTO,
      name: null,
      email: null,
      companyName: null,
      fantasyName: null,
      cnpj: null,
      cpf: null,
      phoneProfile: null,
      street: this.props.account.addressMtrDTO?.street ? this.props.account.addressMtrDTO?.street : null,
      complement: this.props.account.addressMtrDTO?.complement ? this.props.account.addressMtrDTO?.complement : null,
      selectedState: this.props.account.addressMtrDTO?.city ? (this.props.account.addressMtrDTO?.city?.state ? this.props.account.addressMtrDTO?.city?.state : null) : null,
      city: this.props.account.addressMtrDTO?.city ? this.props.account.addressMtrDTO?.city : null,
      zipcode: this.props.account.addressMtrDTO?.zipcode ? this.props.account.addressMtrDTO?.zipcode : null,
      isStateSelected: this.props.account.addressMtrDTO?.city ? this.props.account.addressMtrDTO?.city.state != null : false,

      nameError: ValidationResultInstance,
      emailError: ValidationResultInstance,
      companyNameError: ValidationResultInstance,
      fantasyNameError: ValidationResultInstance,
      cnpjError: ValidationResultInstance,
      cpfError: ValidationResultInstance,
      phoneProfileError: ValidationResultInstance,
      cityError: ValidationResultInstance,
      stateError: ValidationResultInstance,

      showErrorModal: false,
      showSuccess: false,

      streetError: ValidationResultInstance,
      complementError: ValidationResultInstance,
      zipcodeError: ValidationResultInstance,

    };
  }

  async componentDidMount() {
    this.props.getSession();

    await this.props.getStates(0, 50, 'name');
    if (this.props.account.addressMtrDTO?.city && this.props.account.addressMtrDTO?.city?.state) {
      await this.props.getCitiesByState(this.props.account.addressMtrDTO.city?.state.id);
    }
  }

  componentWillUnmount() { }

  componentWillReceiveProps(newProps) {
    if (newProps.account != null && newProps.account != this.props.account) {
      this.setState({
        name: newProps.account.name,
        email: newProps.account.email,
        companyName: newProps.account.companyName,
        fantasyName: newProps.account.fantasyName,
        cnpj: newProps.account.cnpj,
        cpf: newProps.account.cpf,
        phoneProfile: newProps.account.phoneProfile,
        street: newProps.account.addressMtrDTO.street,
        complement: newProps.account.addressMtrDTO.complement,
        selectedState: newProps.account.addressMtrDTO.city.state,
        city: newProps.account.addressMtrDTO.city,
        zipcode: newProps.account.addressMtrDTO.zipcode,
        isStateSelected: newProps.account.isStateSelected,
      });
    }
    if (newProps.updateAccountStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
      this.props.getSession();
      this.props.reset();
    }
  }

  search = () => { };

  validateAllFields = () => {
    let hasError = false;
    if (this.onValidateEmail()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    if (name == null || name.length === 0) {
      return;
    }
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    if (email == null || email.length === 0) {
      return;
    }
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onNextClick = () => {
    if (this.validateAllFields()) {
      return;
    }

    const { name, email, companyName, fantasyName, cnpj, cpf, phoneProfile, street, complement, city, zipcode} = this.state;

    const user: IUser = {
      name: name && name.length > 0 ? name : null,
      email: email && email.length > 0 ? email : null,
      companyName: companyName && companyName.length > 0 ? companyName : null,
      fantasyName: fantasyName && fantasyName.length > 0 ? fantasyName : null,
      cnpj: cnpj && cnpj.length > 0 ? cnpj : null,
      cpf: cpf && cpf.length > 0 ? cpf : null,
      phoneProfile: phoneProfile && phoneProfile.length > 0 ? phoneProfile : null,
      street: street && street.length > 0 ? street : null,
      complement: complement && complement.length > 0 ? complement : null,
      city: city && city.name.length > 0 ? city : null,
      zipcode: zipcode && zipcode.length > 0 ? zipcode : null,
    };

    this.props.updateAccount(user);
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  toggleSuccessModal = () => {
    this.setState({
      showSuccess: !this.state.showSuccess,
    });
  };

  onBackHandler = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, updateAccountStatus } = this.props;
    const {
      name,email,companyName,fantasyName,cnpj,phoneProfile,nameError,emailError, companyNameError,fantasyNameError,cnpjError,phoneProfileError,showErrorModal,showSuccess, cpf, cpfError
    } = this.state;
    if (loading || checkStatusOnGoing([updateAccountStatus])) {
      return <Loading />;
    }
    return (
      <div>
        <div className="user-profile-menu__items--title">
          <Translate contentKey={'profile.personal-data.personal-data'} />
        </div>
        <CustomTextField
          id={'name'}
          style={{ marginTop: '10px' }}
          value={name}
          onChange={(text: string) =>
            this.setState({
              name: text,
            })
          }
          error={nameError.hasError}
          errorText={nameError.errorMessage ? translate(nameError.errorMessage) : ''}
          label={translate('global.account.edit.name')}
          placeholder={translate('global.account.edit.name')}
          onBlur={this.onValidateName}
        />
        {/*
        <CustomTextField
          id={'email'}
          style={{ marginTop: '10px' }}
          value={email}
          onChange={(text: string) =>
            this.setState({
              email: text,
            })
          }
          error={emailError.hasError}
          errorText={emailError.errorMessage ? translate(emailError.errorMessage) : ''}
          label={translate('global.account.edit.email')}
          placeholder={translate('global.account.edit.email')}
          onBlur={this.onValidateEmail}
        />
        */}

        <CustomTextField
          id={'companyName'}
          style={{ marginTop: '10px' }}
          value={companyName}
          onChange={(text: string) =>
            this.setState({
              companyName: text,
            })
          }
          error={companyNameError.hasError}
          errorText={companyNameError.errorMessage ? translate(companyNameError.errorMessage) : ''}
          label={translate('global.account.edit.companyName')}
          placeholder={translate('global.account.edit.companyName')}
          //onBlur={this.onValidateCpf}
        />
        <CustomTextField
          id={'fantasyName'}
          style={{ marginTop: '10px' }}
          value={fantasyName}
          onChange={(text: string) =>
            this.setState({
              fantasyName: text,
            })
          }
          error={fantasyNameError.hasError}
          errorText={fantasyNameError.errorMessage ? translate(fantasyNameError.errorMessage) : ''}
          label={translate('global.account.edit.fantasyName')}
          placeholder={translate('global.account.edit.fantasyName')}
          //onBlur={this.onValidateCpf}
        />
        {cnpj && <CustomTextField
          id={'cnpj'}
          style={{ marginTop: '10px' }}
          value={cnpj}
          onMask={maskCnpj}
          error={cnpjError.hasError}
          errorText={cnpjError.errorMessage ? translate(cnpjError.errorMessage) : ''}
          label={translate('global.account.edit.cnpj')}
          placeholder={translate('global.account.edit.cnpj')}
          readonly
        />}
        {/*cpf && <CustomTextField
          id={'cpf'}
          style={{ marginTop: '10px' }}
          value={cpf}
          onMask={maskCPF}
          error={cpfError.hasError}
          errorText={cpfError.errorMessage ? translate(cpfError.errorMessage) : ''}
          label={translate('global.account.edit.cpf')}
          placeholder={translate('global.account.edit.cpf')}
          readonly
        />*/}
        <CustomTextField
          id={'phoneProfile'}
          style={{ marginTop: '10px' }}
          value={phoneProfile}
          onChange={(text: string) =>
            this.setState({
              phoneProfile: text,
            })
          }
          onMask={maskPhone}
          error={phoneProfileError.hasError}
          errorText={phoneProfileError.errorMessage ? translate(phoneProfileError.errorMessage) : ''}
          label={translate('global.account.edit.phone')}
          placeholder={translate('global.account.edit.phone')}
          //onBlur={this.onValidateCpf}
        />

        {this.renderAddressForm()}

        <NextCancelButton
          onCancelClick={() => this.props.history.goBack()}
          onNextClick={this.onNextClick}
          nextButtonName={translate('entity.action.save')}
          noArrow
        />

        <AlertModal
          showModal={showSuccess}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.onBackHandler}
          statusImage="garbage.svg"
          alertMessage="alert.accountUpdate"
        />
        <AlertModal
          showModal={showErrorModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.account"
        />
      </div>
    );
  }

  mapSelectedStateToSelect = () => {
    const { selectedState } = this.state;

    return selectedState
      ? {
        name: selectedState.name,
        id: selectedState.id,
      }
      : null;
  };

  mapSelectedCityToSelect = () => {
    const { city } = this.state;

    return city
      ? {
        name: city.name,
        id: city.id,
      }
      : null;
  };


  onClickState = (item: any) => {
    const { states } = this.props;
    this.setState(
      {
        selectedState: states.filter(it => it.id === item.id)[0],
        city: null,
        cityError: ValidationResultInstance,
        isStateSelected: true,
      },
      () => {
        this.props.getCitiesByState(this.state.selectedState.id);
        this.onValidateState();
      }
    );
  };

  onClickCity = (item: any) => {
    const { cities } = this.props;
    this.setState(
      {
        city: cities.filter(it => it.id === item.id)[0],
      },
      () => this.onValidateCity()
    );
  };

  onValidateState = () => {
    const { selectedState } = this.state;
    const validate = validateObject(selectedState);
    this.setState({
      stateError: validate,
    });
    return validate.hasError;
  };

  onValidateCity = () => {
    const { city } = this.state;
    const validate = validateObject(city);
    this.setState({
      cityError: validate,
    });
    return validate.hasError;
  };

  onValidateStreet = () => {
    const { street } = this.state;
    const validate = validateGenericField(street);
    this.setState({
      streetError: validate,
    });
    return validate.hasError;
  };

  onValidateComplement = () => {
    const { complement } = this.state;
    const validate = validateGenericField(complement);
    if (!validate.hasError) {
      this.setState({
        complementError: validate,
      });
    }
    return validate.hasError;
  };

  setCityMissingStateError = () => {
    this.setState({
      cityError: {
        hasError: true,
        errorMessage: 'validation.city.state',
      },
    });
  };


  renderAddressForm() {
    const { street, streetError, complement, complementError, cityError, stateError, zipcode, zipcodeError } = this.state;
    return (
      <div className="address__items">
        <Row>
          <Col>
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  street: text,
                })
              }
              id="street"
              style={{ marginTop: '20px' }}
              value={street}
              label={translate('address.form.street')}
              placeholder={translate('address.form.street')}
              error={streetError.hasError}
              errorText={translateErrorMessage(streetError.errorMessage)}
              onBlur={this.onValidateStreet}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  complement: text,
                })
              }
              id="complement"
              style={{ marginTop: '20px' }}
              value={complement}
              label={translate('address.form.complement')}
              placeholder={translate('address.form.complement')}
              error={complementError.hasError}
              errorText={translateErrorMessage(complementError.errorMessage)}
              onBlur={this.onValidateComplement}
            />
            <div style={{ marginTop: '20px' }}>
              <DropdownSearch
                showSearchField
                data={this.mapStatesToSearch()}
                getData={this.search}
                onClickItem={this.onClickState}
                title={translate('address.form.state')}
                style={{ backgroundColor: '#f6f6f6' }}
                notAlphabeticalOrder
                showInsiderSelect
                filterLocalData
                error={stateError.hasError}
                selectedData={this.mapSelectedStateToSelect()}
                errorMessage={translateErrorMessage(stateError.errorMessage)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ marginTop: '20px' }}>
              <DropdownSearch
                showSearchField
                data={this.mapCitiesToSearch()}
                getData={this.search}
                onClickItem={this.onClickCity}
                title={translate('address.form.city')}
                style={{ backgroundColor: '#f6f6f6' }}
                notAlphabeticalOrder
                showInsiderSelect
                filterLocalData
                error={cityError.hasError}
                selectedData={this.mapSelectedCityToSelect()}
                disableOpen={this.state.selectedState == null}
                errorMessage={translateErrorMessage(cityError.errorMessage)}
                onOpen={this.setCityMissingStateError}
              />
            </div>
          </Col>
          <Col>
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  zipcode: text,
                })
              }
              id="zipcode"
              onMask={maskZipCode}
              style={{ marginTop: '20px' }}
              value={zipcode}
              label={translate('address.form.zipcode')}
              placeholder={translate('address.form.zipcode')}
              error={zipcodeError.hasError}
              errorText={translateErrorMessage(zipcodeError.errorMessage)}
              //onBlur={this.onValidateZipCode}
            />
          </Col>
        </Row>
      </div>
    );
  }
  mapCitiesToSearch = () => {
    const { cities } = this.props;
    const { isStateSelected } = this.state;
    if (!isStateSelected) {
      return [];
    }
    const objects = cities.map(city => {
      return { name: city.name, id: city.id };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  mapStatesToSearch = () => {
    const { states } = this.props;
    const objects = states.map(state => {
      return { name: state.name, id: state.id };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };
}

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  loading: root.authentication.loading,
  updateAccountStatus: root.profile.updateAccountStatus,
  states: root.state.entities,
  cities: root.city.entities,
});

const mapDispatchToProps = {
  getSession,
  updateAccount,
  reset,
  getCitiesByState,
  getStates,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileDataEdit);

import { IRootState } from 'app/shared/reducers';
import { maskPhone } from 'app/shared/util/mask-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './final-destination-details.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  getEntityByFinalDestination as getFinalDestination,
  reset,
} from 'app/entities/user-final-destination/user-final-destination.reducer';
import Loading from 'app/components/loading/loading';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import moment from 'moment';
import { APP_ONLY_DATE_FORMAT } from 'app/config/constants';
import { formatToCEP, formatToCNPJ, formatToCPF } from 'brazilian-values';
import SimpleFileComponent from 'app/components/simple-file-component/simple-file-component';
import { PersonType } from 'app/shared/model/transporter.model';

export interface IFinalDestinationDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IFinalDestinationDetailsState { }

interface DetailComponent {
  title: string;
  info: string;
}

export class FinalDestinationDetails extends React.Component<IFinalDestinationDetailsProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getFinalDestination(id);
    }
  }

  renderComponent = (title: string, info: string) => (
    <div className="list__items--component">
      <div className="list__items--title">{title}</div>
      <div className="list__items--text">{info}</div>
    </div>
  );

  renderComponentSeries = (details: DetailComponent[]) => (
    <Row className="no-margin">
      {details &&
        details.map((item, index) => (
          <Col className={`no-padding ${index < details.length - 1 ? 'marginR1' : ''}`}>{this.renderComponent(item.title, item.info)}</Col>
        ))}
    </Row>
  );

  createDetailComponent = (title: string, info: string) => {
    return {
      title,
      info,
    };
  };

  renderTitle = (title: string) => {
    return <div className="list__items--title-second">{title}</div>;
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, userFinalDestination } = this.props;
    if (loading) {
      return <Loading />;
    }
    const environmentalLicenses = userFinalDestination.finalDestination
      ? userFinalDestination.finalDestination.environmentalLicenses
        ? userFinalDestination.finalDestination.environmentalLicenses
        : null
      : null;
    return (
      <div className="list__items">
        <BackHeaderComponent onBack={this.handleGoBack} />
        <div>
          <h1 style={{ fontWeight: 550 }}>
            {userFinalDestination.finalDestination ? userFinalDestination.finalDestination.companyName : ''}
          </h1>
          <Row>
            <Col md="6" style={{ margin: 'auto' }}>
              {this.renderTitle(translate('detailsInfo.companyInfo.title'))}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  userFinalDestination.finalDestination?.personType === PersonType.LEGAL ? translate('detailsInfo.companyInfo.companyName') : translate('detailsInfo.companyInfo.name'),
                  userFinalDestination.finalDestination?.personType === PersonType.LEGAL ? (userFinalDestination?.finalDestination?.legalPerson?.companyName ?? '') : (userFinalDestination?.finalDestination?.naturalPerson?.name ?? '')
                ),
                this.createDetailComponent(
                  userFinalDestination.finalDestination?.personType === PersonType.LEGAL ? translate('detailsInfo.companyInfo.cnpj') : translate('detailsInfo.companyInfo.cpf'),
                  userFinalDestination.finalDestination?.personType === PersonType.LEGAL ? (formatToCNPJ(userFinalDestination?.finalDestination?.legalPerson?.cnpj ?? '')) : (formatToCPF(userFinalDestination?.finalDestination?.naturalPerson?.cpf ?? ''))
                ),
              ])}
              {this.renderComponent(
                translate('detailsInfo.finalDestination.finalDestinationCategory'),
                userFinalDestination.finalDestination
                  ? translate(`enums.finalDestinationCategory.${userFinalDestination.finalDestination.finalDestinationCategory}`)
                  : ''
              )}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.licenseNumber'),
                  environmentalLicenses && environmentalLicenses[0] ? environmentalLicenses[0].licenseNumber : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.issuingBody'),
                  environmentalLicenses && environmentalLicenses[0] ? environmentalLicenses[0].issuingBody : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.licenseType'),
                  environmentalLicenses && environmentalLicenses[0]
                    ? translate(`enums.environmentalLicenseType.${environmentalLicenses[0].environmentalLicenseType}`)
                    : ''
                ),
              ])}
              {this.renderComponent(
                translate('detailsInfo.licenseInfo.expirationDate'),
                environmentalLicenses && environmentalLicenses[0] ? moment(environmentalLicenses[0].expireDate).format(APP_ONLY_DATE_FORMAT) : ''
              )}
              {this.renderTitle(translate('detailsInfo.addressInfo.title'))}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.address'),
                  userFinalDestination.finalDestination ? userFinalDestination.finalDestination.address.street : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.address'),
                  userFinalDestination.finalDestination ? userFinalDestination.finalDestination.address.complement : ''
                ),
              ])}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.city'),
                  userFinalDestination.finalDestination ? userFinalDestination.finalDestination.address.city.name : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.state'),
                  userFinalDestination.finalDestination ? userFinalDestination.finalDestination.address.city.state.name : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.zipcode'),
                  userFinalDestination.finalDestination ? formatToCEP(userFinalDestination.finalDestination.address.zipcode) : ''
                ),
              ])}
              {this.renderTitle(translate('detailsInfo.responsible.title'))}
              {this.renderComponent(
                translate('detailsInfo.responsible.name'),
                userFinalDestination.user ? userFinalDestination.user.name : ''
              )}
              {this.renderComponent(
                translate('detailsInfo.responsible.email'),
                userFinalDestination.user ? userFinalDestination.user.email : ''
              )}
              {this.renderComponent(
                translate('detailsInfo.responsible.phone'),
                userFinalDestination.user ? maskPhone(userFinalDestination.user.phone) : ''
              )}
              {this.renderTitle(translate('detailsInfo.documentation.title'))}
              <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
                <div className="list__items--title">{translate('detailsInfo.documentation.environmentalLicense')}</div>
                <SimpleFileComponent file={environmentalLicenses && environmentalLicenses[0] ? environmentalLicenses[0].licenseDocument : null} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  loading: root.userFinalDestination.loading,
  userFinalDestination: root.userFinalDestination.entity,
});

const mapDispatchToProps = {
  getFinalDestination,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationDetails);

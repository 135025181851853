import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './authorization-second.scss';
import { AlertModal } from 'app/components/alert-modal/alert-modal';

export const MtrAuthorizationSecond = () => {
  return (
    <div className="authorization-second__items">
      <h1>{translate('mtr-manager.authorization-second.title')}</h1>
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <div className="authorization-second__items--component">Buscar gerador</div>
        </Col>
      </Row>
      <div className={'button-container'}>
        <button className={'button third'} type={'submit'}>
          <Translate contentKey={'mtr-signature.button-cancel'} />
        </button>
        <button className={'button fourth'} type={'submit'}>
          <Translate contentKey={'entity.action.next'}>Next</Translate>
          <div className="next-arrow" />
        </button>
      </div>
      {/* ==========================================================================
                            MODAL
      ==========================================================================  */}

      <AlertModal
        showModal={false}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="transporter-driver-1.svg"
        alertMessage="modal-authorization-register"
      />
    </div>
  );
};

export default MtrAuthorizationSecond;

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate } from 'react-jhipster';
import './digital-signature-second.scss';
import { AlertModal } from 'app/components/alert-modal/alert-modal';

export const MtrDigitalSignatureSecond = () => {
  return (
    <div className="digital-signature__items">
      <div className="digital-signature__items--back">
        <Container>
          <Row>
            <Col md="12">
              <div className="confirmation--back">
                <div className="confirmation--back-icon" />
                <span>
                  <Translate contentKey={'mtr-signature.back'} />
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="container">
        <Row>
          <Col md="2">
            <div className="digital-signature__items--sidebar">
              <div className="digital-signature__items--sidebar-title">
                <Translate contentKey={'profile.personal-data.profile'} />
              </div>
              <div className="digital-signature__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.personal-data'} />
              </div>
              <div className="digital-signature__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.digital-signature'} />
              </div>
              <div className="digital-signature__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.manage-users'} />
              </div>
            </div>
          </Col>
          <Col md={{ size: 6, offset: 1 }}>
            <div className="digital-signature__items--title">
              <Translate contentKey={'profile.digital-signature.title'} />
            </div>
            <div className="digital-signature__items--subtitle">
              <Translate contentKey={'profile.digital-signature.subtitle'} />
            </div>
            <div className="digital-signature__items--component-second">
              <div>Insira a sua assinatura</div>
              <button>EDITAR ASSINATURA</button>
            </div>
            <div className="digital-signature__items--signature">
              <span>Nome do responsável</span>
              <span>João da Silva</span>
            </div>

            <button className={'button fourth'} type={'submit'}>
              <Translate contentKey={'mtr-signature.button-save'} />
            </button>
          </Col>
        </Row>
      </div>

            {/* ==========================================================================
                            MODAL LICENÇA
      ==========================================================================  */}



        <AlertModal
        showModal={true}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="assinatura.svg"
        alertMessage="modal-digital-signature-second"
      />

    </div>
  );
};

export default MtrDigitalSignatureSecond;

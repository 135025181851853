import React from 'react';
import './static-table-bar.scss';
import { Checkbox } from '@material-ui/core';

export interface IStaticTableBarProps {
  staticItems: StaticTableBarItem[];
}

export interface StaticTableBarItem {
  name: string;
  width: string;
  color?: string;
  checkbox?: boolean;
  onClick?: (event: boolean) => void;
}

export class StaticTableBar extends React.Component<IStaticTableBarProps> {
  constructor(props: Readonly<IStaticTableBarProps>) {
    super(props);
  }

  render() {
    const { staticItems } = this.props;
    return (
        <div className="static-table-bar__container">
          {staticItems && staticItems.map((item, index) => (
              <div className="static-table-bar__item" style={{ width: item.width, display: 'flex', alignItems: 'center' }}>
                <span style={{ color: item.color }}>
                  {item.name?.toUpperCase() || ''}
                </span>
                {item.checkbox && (
                    <Checkbox
                        onChange={event => item.onClick(event.target.checked)}
                        icon={<img src={'content/images/icons/check-gray.svg'} alt='' />}
                        checkedIcon={<img src={'content/images/icons/check-green.svg'} alt='' />}
                    />
                )}
              </div>
          ))}
        </div>
    );
  }
}

export default StaticTableBar;

import React from 'react';
import { translate } from 'react-jhipster';
import { IProducer } from 'app/shared/model/producer.model';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import {
  validateCPF,
  validateGenericField,
  validatePhoneNumber,
} from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { maskCPF, maskNumber, maskPhone, unmask } from 'app/shared/util/mask-utils';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface IProducerRegisterPhysicalPersonProps {
  producer: IProducer;
  onNext: (producer: IProducer) => void;
  onBack: () => void;
}

export interface IProducerRegisterPhysicalPersonState {
  cnpjError: ValidationResult;
  companyNameError: ValidationResult;
  municipalRegistrationError: ValidationResult;
  phoneError: ValidationResult;
  cpf: string;
  name: string;
  phone: string;
  municipalRegistration: string;
}

export class ProducerRegisterPhysicalPerson extends React.Component<IProducerRegisterPhysicalPersonProps, IProducerRegisterPhysicalPersonState> {
  constructor(props) {
    super(props);
    this.state = {
      cnpjError: ValidationResultInstance,
      companyNameError: ValidationResultInstance,
      municipalRegistrationError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
      cpf: maskCPF(this.props.producer?.naturalPerson?.cpf),
      name: this.props.producer?.naturalPerson?.name,
      phone: maskPhone(this.props.producer?.naturalPerson?.phone),
      municipalRegistration: this.props.producer?.naturalPerson?.municipalRegistration,
    };
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateCpf()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateCpf = () => {
    const { cpf } = this.state;
    const validate = validateCPF(cpf);
    this.setState({
      cnpjError: validate,
    });
    return validate.hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    const validate = validateGenericField(name);
    this.setState({
      companyNameError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const { cpf, name, phone, municipalRegistration } = this.state;

    const producer: IProducer = {
      naturalPerson: {
        cpf: unmask(cpf),
        name: name,
        phone: unmask(phone),
        municipalRegistration
      }
    };

    this.props.onNext(producer);
  };

  render() {
    const {
      cnpjError,
      phoneError,
      companyNameError,
      cpf,
      name,
      phone,
      municipalRegistration,
      municipalRegistrationError,
    } = this.state;
    return (
      <div className="producer-register-holding__items">
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              cpf: text,
            })
          }
          id="cpf"
          style={{ marginTop: '20px' }}
          value={cpf}
          onMask={maskCPF}
          label={translate('producerRegister.company.physicalPerson.form.cpf')}
          placeholder={translate('producerRegister.company.physicalPerson.form.cpf')}
          error={cnpjError.hasError}
          errorText={translateErrorMessage(cnpjError.errorMessage)}
          onBlur={this.onValidateCpf}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              name: text,
            })
          }
          id="name"
          style={{ marginTop: '20px' }}
          value={name}
          label={translate('producerRegister.company.physicalPerson.form.name')}
          placeholder={translate('producerRegister.company.physicalPerson.form.name')}
          error={companyNameError.hasError}
          errorText={translateErrorMessage(companyNameError.errorMessage)}
          onBlur={this.onValidateName}
        />
        {/* <CustomTextField
          onChange={(text: string) =>
            this.setState({
              municipalRegistration: text,
            })
          }
          id="municipalRegistration"
          onMask={maskNumber}
          style={{ marginTop: '20px' }}
          value={municipalRegistration}
          label={translate('producerRegister.company.physicalPerson.form.municipalRegistration')}
          placeholder={translate('producerRegister.company.physicalPerson.form.municipalRegistration')}
          error={municipalRegistrationError.hasError}
          errorText={translateErrorMessage(municipalRegistrationError.errorMessage)}
        /> */}
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              phone: text,
            })
          }
          onMask={maskPhone}
          id="phone"
          style={{ marginTop: '20px' }}
          value={phone}
          label={translate('producerRegister.company.physicalPerson.form.phone')}
          placeholder={translate('producerRegister.company.physicalPerson.form.phone')}
          error={phoneError.hasError}
          errorText={translateErrorMessage(phoneError.errorMessage)}
          onBlur={this.onValidatePhone}
        />
        <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
      </div>
    );
  }
}

export default ProducerRegisterPhysicalPerson

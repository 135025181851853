import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ISourceResidue, ISourceResidueMtr } from 'app/shared/model/source-residue.model';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import StringUtils from 'app/shared/util/string-utils';

export const ACTION_TYPES = {
  FETCH_SOURCERESIDUE_LIST: 'sourceResidue/FETCH_SOURCERESIDUE_LIST',
  SEARCH_SOURCE_RESIDUE: 'sourceResidue/SEARCH_SOURCE_RESIDUE',
  FETCH_SOURCERESIDUE: 'sourceResidue/FETCH_SOURCERESIDUE',
  CREATE_SOURCERESIDUE: 'sourceResidue/CREATE_SOURCERESIDUE',
  UPDATE_SOURCERESIDUE: 'sourceResidue/UPDATE_SOURCERESIDUE',
  DELETE_SOURCERESIDUE: 'sourceResidue/DELETE_SOURCERESIDUE',
  RESET: 'sourceResidue/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISourceResidue>,
  sourceResidues: [] as ReadonlyArray<ISourceResidueMtr>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type SourceResidueState = Readonly<typeof initialState>;

// Reducer

export default (state: SourceResidueState = initialState, action): SourceResidueState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SOURCERESIDUE_LIST):
    case REQUEST(ACTION_TYPES.SEARCH_SOURCE_RESIDUE):
    case REQUEST(ACTION_TYPES.FETCH_SOURCERESIDUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SOURCERESIDUE):
    case REQUEST(ACTION_TYPES.UPDATE_SOURCERESIDUE):
    case REQUEST(ACTION_TYPES.DELETE_SOURCERESIDUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SOURCERESIDUE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SOURCERESIDUE):
    case FAILURE(ACTION_TYPES.SEARCH_SOURCE_RESIDUE):
    case FAILURE(ACTION_TYPES.CREATE_SOURCERESIDUE):
    case FAILURE(ACTION_TYPES.UPDATE_SOURCERESIDUE):
    case FAILURE(ACTION_TYPES.DELETE_SOURCERESIDUE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SOURCERESIDUE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_SOURCE_RESIDUE):
      return {
        ...state,
        loading: false,
        sourceResidues: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SOURCERESIDUE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SOURCERESIDUE):
    case SUCCESS(ACTION_TYPES.UPDATE_SOURCERESIDUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SOURCERESIDUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/source-residues';

// Actions

export const getEntities: ICrudGetAllAction<ISourceResidue> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SOURCERESIDUE_LIST,
  payload: axios.get<ISourceResidue>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<ISourceResidue> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SOURCERESIDUE,
    payload: axios.get<ISourceResidue>(requestUrl),
  };
};

export const searchSourceResidues: ICrudSearchActionCustom<ISourceResidueMtr> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}/search${params}`;
  return {
    type: ACTION_TYPES.SEARCH_SOURCE_RESIDUE,
    payload: axios.get<ISourceResidueMtr>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const createEntity: ICrudPutAction<ISourceResidue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SOURCERESIDUE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISourceResidue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SOURCERESIDUE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISourceResidue> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SOURCERESIDUE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

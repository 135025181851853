import { InputAdornment, TextField } from '@material-ui/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import './custom-text-field-observation.scss';
import StringUtils from 'app/shared/util/string-utils';

export interface TextFieldProps {
    id: string;
    placeholder?: string;
    label?: string;
    isEmail?: boolean;
    isPassword?: boolean;

    value?: string;
    maxLength?: number;

    helpText?: string;

    readonly?: boolean;
    name?: string;

    error?: boolean;
    errorText?: string;

    onChange?: (value: string) => void;
    onError?: (value: boolean) => void;

    onMask?: (value: string) => string;

    onEnterPress?: () => void;
    style?: any;
    onBlur?: () => void;
    validate?: () => void;
    InputProps?: any;
    dinamic?: boolean;
}

export interface TextFieldState {
    value: string;
    error: boolean;
    filled: boolean;
    focused: boolean;

    placeholder: string;
    label: string;
    maxLength: number;
}

export default class CustomTextFieldObservation extends React.Component<TextFieldProps, TextFieldState> {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value != null ? (this.props.onMask ? this.props.onMask(props.value) : props.value) : '',
            error: props.error != null ? props.error : false,
            filled: props.value != null,
            focused: false,
            placeholder: props.placeholder != null ? props.placeholder : '',
            label: props.placeholder != null ? props.placeholder : props.label != null ? props.label : '',
            maxLength: props.maxLength || Infinity,
        };
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillReceiveProps(newProps: TextFieldProps) {
        if (this.state.error !== newProps.error) {
            this.setState({
                error: newProps.error != null ? newProps.error : false,
            });
        }

        if (newProps.placeholder !== this.props.placeholder || newProps.label !== this.props.label) {
            const label = newProps.placeholder != null ? newProps.placeholder : newProps.label != null ? newProps.label : '';
            this.setState({
                label
            });
        }

        if (this.state.value != newProps.value && this.props.dinamic) {
            this.setState({
                value: newProps.value
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (
            (!domNode || !domNode.contains(event.target)) &&
            this.state.label !== this.props.label &&
            (this.state.value === null || this.state.value === '')
        ) {
            this.setState({
                label: this.props.label ? this.props.label : '',
            });
        } else if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                error: this.props.error ? this.props.error : false,
                filled: this.state.value != null && this.state.value.length > 0,
            });
        }
    };

    private onChangeValue = event => {
        const { onMask, maxLength } = this.props;

        let newValue = event.target.value;

        // Limitar ao maxLength, se estiver definido
        if (maxLength && newValue.length > maxLength) {
            newValue = newValue.substring(0, maxLength);
        }

        if (StringUtils.checkEmptyString(newValue)) {
            return;
        }

        this.setState({
            value: onMask ? onMask(newValue) : newValue,
            filled: newValue !== null && newValue.length > 0,
            maxLength: maxLength || Infinity, // Atualizado
        });

        this.props.onChange(onMask ? onMask(newValue) : newValue);
    };


    private clickField = () => {
        this.setState({
            label: this.props.placeholder ? this.props.placeholder : '',
        });
    };

    private onKeyPress = event => {
        if ((event.which === 13 || event.keyCode === 13) && this.props.onEnterPress) {
            this.props.onEnterPress();
            return false;
        }
        return true;
    };

    private onKeyDownCapture = event => {
        if (event.which === 9 || event.keyCode === 9) {
            if (this.state.label !== this.props.label && (this.state.value === null || this.state.value === '')) {
                this.setState({
                    label: this.props.label ? this.props.label : '',
                });
            }
            return false;
        }
        return true;
    };
    private onFocus = (): void => {
        this.setState({
            focused: true,
        });
    };
    private onBlur = (): void => {
        if (this.props.readonly) {
            return;
        }

        this.setState({
            focused: false,
        });
        const valueFormatted = this.state.value.replace(/\s+$/, '');
        this.props.onChange(valueFormatted);
        this.setState(
            {
                value: valueFormatted,
            },
            () => {
                if (this.props.onBlur) {
                    this.props.onBlur();
                }
            }
        );
    };
    render() {
        const { isPassword, error, errorText, helpText, InputProps, style, id, name } = this.props;
        const { filled, focused, value, label, maxLength } = this.state;
        const didNotValidated = filled && (error == null || !focused);
        const hasError = error === true && !focused;
        const isValid = error === false && filled && !focused;
        return (
            <div className="text__field" style={{ position: 'relative', width: '100%', ...style }}>
                <TextField
                    className={
                        hasError ? 'error filled MuiFilledInput-underline' : isValid ? 'filled validate' : didNotValidated ? 'filled' : 'action'
                    }
                    id={id}
                    type={isPassword ? 'password' : 'text'}
                    onClick={this.clickField}
                    onChange={this.onChangeValue}
                    fullWidth
                    autoComplete="off"
                    error={hasError}
                    name={name}
                    InputProps={
                        filled && isValid
                            ? {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <div className="check-icn" />
                                    </InputAdornment>
                                ),
                            }
                            : InputProps || { readOnly: this.props.readonly }
                    }
                    helperText={errorText && hasError ? errorText : null}
                    value={value}
                    label={label}
                    variant="filled"
                    onKeyDownCapture={this.onKeyDownCapture}
                    onKeyPress={this.onKeyPress}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                />
                <div className="character-count">
                    {maxLength - value.length} caracteres restantes
                </div>
                {isPassword === true && helpText && label !== this.props.label && !hasError && focused ? (
                    <div className="helpText">{helpText}</div>
                ) : (
                    <div className="blankHelpText" />
                )}
            </div>
        );
    }
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './transporter.reducer';

export interface ITransporterDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class TransporterDetail extends React.Component<ITransporterDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { transporterEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.transporter.detail.title">Transporter</Translate> [<b>{transporterEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="fantasyName">
                <Translate contentKey="resitrackApp.transporter.fantasyName">Fantasy Name</Translate>
              </span>
            </dt>
            <dd>{transporterEntity.fantasyName}</dd>
            <dt>
              <span id="cnpj">
                <Translate contentKey="resitrackApp.transporter.cnpj">Cnpj</Translate>
              </span>
            </dt>
            <dd>{transporterEntity.cnpj}</dd>
            <dt>
              <span id="phone">
                <Translate contentKey="resitrackApp.transporter.phone">Phone</Translate>
              </span>
            </dt>
            <dd>{transporterEntity.phone}</dd>
            <dt>
              <span id="companyName">
                <Translate contentKey="resitrackApp.transporter.companyName">Company Name</Translate>
              </span>
            </dt>
            <dd>{transporterEntity.companyName}</dd>
            <dt>
              <span id="municipalRegistration">
                <Translate contentKey="resitrackApp.transporter.municipalRegistration">Municipal Registration</Translate>
              </span>
            </dt>
            <dd>{transporterEntity.municipalRegistration}</dd>
            <dt>
              <span id="personType">
                <Translate contentKey="resitrackApp.transporter.personType">Person Type</Translate>
              </span>
            </dt>
            <dd>{transporterEntity.personType}</dd>
            <dt>
              <Translate contentKey="resitrackApp.transporter.cnaeCodes">Cnae Codes</Translate>
            </dt>
            <dd>
              {transporterEntity.cnaeCodes
                ? transporterEntity.cnaeCodes.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.id}</a>
                      {i === transporterEntity.cnaeCodes.length - 1 ? '' : ', '}
                    </span>
                  ))
                : null}
            </dd>
            <dt>
              <Translate contentKey="resitrackApp.transporter.address">Address</Translate>
            </dt>
            <dd>{transporterEntity.address ? transporterEntity.address.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/transporter" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/transporter/${transporterEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ transporter }: IRootState) => ({
  transporterEntity: transporter.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterDetail);

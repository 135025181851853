import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './final-destination-register-responsible.scss';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import ResponsibleFormComponent from 'app/components/responsible-form-component/responsible-form-component';
import { IUserGeneric } from 'app/shared/model/user-generic.model';

export interface IFinalDestinationRegisterResponsibleProps extends StateProps, DispatchProps {
  userFinalDestination: IUserFinalDestination;
  onNext: (userFinalDestination: IUserFinalDestination) => void;
  onBack: () => void;
}

export interface IFinalDestinationRegisterResponsibleState { }

export class FinalDestinationRegisterResponsible extends React.Component<
  IFinalDestinationRegisterResponsibleProps,
  IFinalDestinationRegisterResponsibleState
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onNextHandler = (userFinalDestinationGeneric: IUserGeneric) => {
    const userFinalDestination = { ...this.props.userFinalDestination };

    const userFinalDestinationUpdated: IUserFinalDestination = {
      ...userFinalDestination,
      classCouncil: userFinalDestinationGeneric.classCouncil,
      registrationNumber: userFinalDestinationGeneric.registrationNumber,
      user: userFinalDestinationGeneric.user,
    };

    this.props.onNext(userFinalDestinationUpdated);
  };

  mapToUserGeneric = () => {
    const { userFinalDestination } = this.props;
    if (userFinalDestination != null) {
      const userFinalDestinationGeneric: IUserGeneric = {
        user: userFinalDestination.user,
        classCouncil: userFinalDestination.classCouncil,
        registrationNumber: userFinalDestination.registrationNumber,
      };
      return userFinalDestinationGeneric;
    }
    return {};
  };

  render() {
    return (
      <div>
        <ResponsibleFormComponent
          userGeneric={this.mapToUserGeneric()}
          onNextHandler={this.onNextHandler}
          onBack={this.props.onBack}
          validationIgnore={
            new Map([
              ['classCouncil', true],
              ['registrationNumber', true],
            ])
          }
          isFinalDestination={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationRegisterResponsible);

import { IUser } from 'app/shared/model/user.model';

export const enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export interface IUserToken {
  id?: number;
  token?: string;
  expiresIn?: string;
  platform?: Platform;
  hash?: string;
  user?: IUser;
}

export const defaultValue: Readonly<IUserToken> = {};

import { MeasurementType } from './authorization.model';

export const enum ResidueClassification {
  DANGEROUS = 'DANGEROUS',
  NOT_DANGEROUS_INERT = 'NOT_DANGEROUS_INERT',
  NOT_DANGEROUS_NOT_INERT = 'NOT_DANGEROUS_NOT_INERT',
  RCC_A = 'RCC_A',
  RCC_B = 'RCC_B',
  RCC_C = 'RCC_C',
  RCC_D = 'RCC_D',
  RSS_A = 'RSS_A',
  RSS_B = 'RSS_B',
  RSS_C = 'RSS_C',
  RSS_D = 'RSS_D',
  RSS_E = 'RSS_E',
  RSS_A1 = 'RSS_A1',
  RSS_A2 = 'RSS_A2',
  RSS_A3 = 'RSS_A3',
  RSS_A4 = 'RSS_A4',
  RSS_A5 = 'RSS_A5',
  OTHERS = 'OTHERS',
}

export const enum ResidueTreatment {
  LANDFILL_RESIDUE_CLASS_I = 'LANDFILL_RESIDUE_CLASS_I',
  LANDFILL_RESIDUE_CLASSES_IIA_AND_IIB = 'LANDFILL_RESIDUE_CLASSES_IIA_AND_IIB',
  AUTOCLAVE = 'AUTOCLAVE',
  TAILING_DAM = 'TAILING_DAM',
  BIO_DIGESTION = 'BIO_DIGESTION',
  BIO_METHANIZATION = 'BIO_METHANIZATION',
  MINING_DIGGING_DISPOSITION = 'MINING_DIGGING_DISPOSITION',
  BIOREMEDIATION = 'BIOREMEDIATION',
  CO_PROCESSING_BLEND = 'CO_PROCESSING_BLEND',
  BLEND_FOR_INCINERATION = 'BLEND_FOR_INCINERATION',
  LAMP_DECONTAMINATION = 'LAMP_DECONTAMINATION',
  VEHICLE_UNMOUNT = 'VEHICLE_UNMOUNT',
  INCINERATION = 'INCINERATION',
  EFFLUENT_TREATMENT = 'EFFLUENT_TREATMENT',
  DIDACTIC_OR_RESEARCH_PURPOSES = 'DIDACTIC_OR_RESEARCH_PURPOSES',
  GASIFICATION = 'GASIFICATION',
  MICROWAVES = 'MICROWAVES',
  STERILE_STACK = 'STERILE_STACK',
  PYROLYSIS = 'PYROLYSIS',
  RECYCLING = 'RECYCLING',
  RE_REFINE = 'RE_REFINE',
  ENERGETIC_RECOVERY = 'ENERGETIC_RECOVERY',
  SCREENING_WITH_STORAGE = 'SCREENING_WITH_STORAGE',
  AGRICULTURAL_USAGE = 'AGRICULTURAL_USAGE',
  ANIMAL_FEED_USAGE = 'ANIMAL_FEED_USAGE',
  THERMAL_TREATMENT_OTHERS = 'THERMAL_TREATMENT_OTHERS',
  COMPOSTING = 'COMPOSTING',
  TRANSSHIPMENT_STATION_RSU = 'TRANSSHIPMENT_STATION_RSU',
  RESERVATION_LANDFILL_RCC = 'RESERVATION_LANDFILL_RCC',
  LANDFILL_RSU = 'LANDFILL_RSU',
  CO_PROCESSING = 'CO_PROCESSING',
  PHOSPHINE_DEACTIVATION = 'PHOSPHINE_DEACTIVATION',
  SCREENING_AND_TRANSSHIPMENT = 'SCREENING_AND_TRANSSHIPMENT',
  FERTIRRIGATION = 'FERTIRRIGATION',
  OTHERS = 'OTHERS',
}

export const enum ResidueState {
  LIQUID = 'LIQUID',
  GAS = 'GAS',
  SOLID = 'SOLID',
  SEMI_SOLID = 'SEMI_SOLID',
}

export const enum ResiduePackingGroup {
  GROUP_I = 'GROUP_I',
  GROUP_II = 'GROUP_II',
  GROUP_III = 'GROUP_III',
}

export interface IResidue {
  id?: number;
  code?: string;
  description?: string;
  residueType?: string;
  residueSubcategory?: string;
  measurementType?: MeasurementType;
  residueClassification?: ResidueClassification;
}

export const defaultValue: Readonly<IResidue> = {};

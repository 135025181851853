import './signature-component.scss';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { translate } from 'react-jhipster';
import { IDocument } from 'app/shared/model/document.model';
import { ACCEPT_IMAGE, IsFileTooLarge } from 'app/shared/util/file-utils';
import { toast } from 'react-toastify';

export interface ISignatureComponentProps {
  onFileAttached?: (document: IDocument) => void;
  signature: IDocument;
  responsibleName: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  isUser?: boolean;
}

export interface ISignatureComponentState {
  hasFileAttached: boolean;
}

export class SignatureComponent extends React.Component<ISignatureComponentProps, ISignatureComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      hasFileAttached: this.props.signature != null,
    };
  }

  addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    if (IsFileTooLarge(selectedFile.size)) {
      toast(translate('error.image.size'));
      return;
    }
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const document: IDocument = {
        ...this.props.signature,
      };
      const contentType = selectedFile.type;
      document.fileName = selectedFile.name;
      document.name = selectedFile.name;
      document.processed = false;
      document.fileContentType = contentType;
      document.file = ev.target.result.split(',')[1];
      this.setState(
          {
            hasFileAttached: true,
          },
          () => this.props.onFileAttached(document)
      );
    };
    reader.readAsDataURL(selectedFile);
  };

  getDocumentUrl = () => {
    const { signature } = this.props;

    if (signature == null) {
      return '';
    }

    if (signature.file != null) {
      return `data:${signature.fileContentType};base64,${signature.file}`;
    } else if (signature.presignedUrl != null) {
      return signature.presignedUrl;
    }

    return '';
  };

  render() {
    const { error, errorMessage, responsibleName, className, isUser } = this.props;
    const { hasFileAttached } = this.state;
    const eventClick = () => document.getElementById('fileInput').click();
    return (
        <div className={className ? className : ''}>
          <div className="signature__items">
            <Row>
              <Col md={isUser ? '12' : '8'}>
                <div style={{ display: 'flex', flexDirection: 'column', wordWrap: 'break-word' }}>
                  {hasFileAttached ? (
                      <img src={this.getDocumentUrl()} style={{ maxHeight: '300px', minHeight:'80px', objectFit: 'cover' }} />
                  ) : (
                      <div>
                        {!isUser && <div className="signature__items--text">{translate('signatureComponent.add')}</div>}
                        {isUser && <div className="signature__items--text">{translate('signatureComponent.noSignature')}</div>}
                      </div>
                  )}
                </div>
              </Col>
              {!isUser && (
                  <Col style={{ margin: 'auto' }} md="4">
                    <input id={'fileInput'} onChange={this.addFile} accept={ACCEPT_IMAGE} type={'file'} style={{ display: 'none' }} />
                    {hasFileAttached ? (
                        <div>
                          <div onClick={eventClick} className="signature__items--button">
                            {translate('signatureComponent.edit')}
                          </div>
                        </div>
                    ) : (
                        <div>
                          <div onClick={eventClick} className="signature__items--button">
                            {translate('signatureComponent.attach')}
                          </div>
                        </div>
                    )}
                  </Col>
              )}
            </Row>
          </div>
          <div className="user-signature__items--signature">
            <span>{translate('signatureComponent.responsible')}</span>
            <span>{responsibleName ? responsibleName : ''}</span>
          </div>
          {}
        </div>
    );
  }
}

export default SignatureComponent;

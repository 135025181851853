import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getEntities as getDocuments} from 'app/entities/document/document.reducer';
import {getEntities as getProducers} from 'app/entities/producer/producer.reducer';
import {IRootState} from 'app/shared/reducers';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import React from 'react';
import {Translate, translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {createEntity, getEntity, reset, updateEntity} from './environmental-license.reducer';

export interface IEnvironmentalLicenseUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEnvironmentalLicenseUpdateState {
  isNew: boolean;
  licenseDocumentId: string;
  producerId: string;
}

export class EnvironmentalLicenseUpdate extends React.Component<IEnvironmentalLicenseUpdateProps, IEnvironmentalLicenseUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      licenseDocumentId: '0',
      producerId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getDocuments();
    this.props.getProducers();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { environmentalLicenseEntity } = this.props;
      const entity = {
        ...environmentalLicenseEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/environmental-license');
  };

  render() {
    const { environmentalLicenseEntity, documents, producers, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.environmentalLicense.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.environmentalLicense.home.createOrEditLabel">
                Create or edit a EnvironmentalLicense
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : environmentalLicenseEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="environmental-license-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="environmental-license-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="licenseNumberLabel" for="environmental-license-licenseNumber">
                    <Translate contentKey="resitrackApp.environmentalLicense.licenseNumber">License Number</Translate>
                  </Label>
                  <AvField
                    id="environmental-license-licenseNumber"
                    type="text"
                    name="licenseNumber"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="expireDateLabel" for="environmental-license-expireDate">
                    <Translate contentKey="resitrackApp.environmentalLicense.expireDate">Expire Date</Translate>
                  </Label>
                  <AvField
                    id="environmental-license-expireDate"
                    type="date"
                    className="form-control"
                    name="expireDate"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="issuingBodyLabel" for="environmental-license-issuingBody">
                    <Translate contentKey="resitrackApp.environmentalLicense.issuingBody">Issuing Body</Translate>
                  </Label>
                  <AvInput
                    id="environmental-license-issuingBody"
                    type="select"
                    className="form-control"
                    name="issuingBody"
                    value={(!isNew && environmentalLicenseEntity.issuingBody) || 'IAP'}
                  >
                    <option value="IAP">{translate('resitrackApp.IssuingBody.IAP')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="environmentalLicenseTypeLabel" for="environmental-license-environmentalLicenseType">
                    <Translate contentKey="resitrackApp.environmentalLicense.environmentalLicenseType">
                      Environmental License Type
                    </Translate>
                  </Label>
                  <AvInput
                    id="environmental-license-environmentalLicenseType"
                    type="select"
                    className="form-control"
                    name="environmentalLicenseType"
                    value={(!isNew && environmentalLicenseEntity.environmentalLicenseType) || 'LO'}
                  >
                    <option value="LO">{translate('resitrackApp.EnvironmentalLicenseType.LO')}</option>
                    <option value="AFU">{translate('resitrackApp.EnvironmentalLicenseType.AFU')}</option>
                    <option value="DLAE">{translate('resitrackApp.EnvironmentalLicenseType.DLAE')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="environmental-license-licenseDocument">
                    <Translate contentKey="resitrackApp.environmentalLicense.licenseDocument">License Document</Translate>
                  </Label>
                  <AvInput id="environmental-license-licenseDocument" type="select" className="form-control" name="licenseDocument.id">
                    <option value="" key="0" />
                    {documents
                      ? documents.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="environmental-license-producer">
                    <Translate contentKey="resitrackApp.environmentalLicense.producer">Producer</Translate>
                  </Label>
                  <AvInput id="environmental-license-producer" type="select" className="form-control" name="producer.id">
                    <option value="" key="0" />
                    {producers
                      ? producers.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/environmental-license" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  documents: storeState.document.entities,
  producers: storeState.producer.entities,
  environmentalLicenseEntity: storeState.environmentalLicense.entity,
  loading: storeState.environmentalLicense.loading,
  updating: storeState.environmentalLicense.updating,
  updateSuccess: storeState.environmentalLicense.updateSuccess,
});

const mapDispatchToProps = {
  getDocuments,
  getProducers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentalLicenseUpdate);

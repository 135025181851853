import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MtrModelCreate from '../../producer/mtr-model/create-mtr-model/create-mtr-model';
import MtrModelList from '../../producer/mtr-model/mtr-model-list';

const Routes = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.url}`} component={MtrModelList} />
      <Route path={`${match.url}/new`} component={MtrModelCreate} />
      <Route path={`${match.url}/edit`} component={MtrModelCreate} />
      <Route path={`${match.url}/details`} component={MtrModelCreate} />
    </Switch>
  </>
);

export default Routes;

import Loading from 'app/components/loading/loading';
import { ALERT_ICONS, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './manage-users-edit.scss';
import { Pageable } from 'app/shared/model/pageable';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IUser } from 'app/shared/model/user.model';
import { maskCPF, maskPhone, unmask, maskName } from 'app/shared/util/mask-utils';
import { Col, Row } from 'reactstrap';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import { createUser, getUser, reset, updateUser } from '../../profile-reducer';
import {
  checkStatusOnGoing,
  translateErrorMessage,
  ValidationResult,
  ValidationResultInstance
} from 'app/shared/util/validation-utils';
import AlertModal from 'app/components/alert-modal/alert-modal';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import {
  validateCPF,
  validateEmail,
  validatePersonName,
  validatePhoneNumber
} from 'app/modules/validation/validation-constants';
import { AlertModalPlanRestricted } from 'app/components/alertModalPlanRestricted/alert-modal';

export interface IManagerUsersEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IManagerUsersEditState {
  page: Pageable;
  isNew: boolean;
  showErrorModal: boolean;
  name: string;
  email: string;
  cpf: string;
  phone: string;
  nameError: ValidationResult;
  emailError: ValidationResult;
  cpfError: ValidationResult;
  phoneError: ValidationResult;
  showErrorModalPlan: boolean;
}

export class ManagerUsersEdit extends React.Component<IManagerUsersEditProps, IManagerUsersEditState> {
  constructor(props: Readonly<IManagerUsersEditProps>) {
    super(props);
    this.state = {
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      isNew: null,
      showErrorModal: false,
      email: null,
      name: null,
      cpf: null,
      phone: null,
      nameError: ValidationResultInstance,
      emailError: ValidationResultInstance,
      cpfError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
      showErrorModalPlan: false,
    };
  }

  async componentDidMount() {
    this.props.reset();
    const { id } = (this.props.location.state as any) || { id: null };
    if (id) {
      this.props.getUser(id);
    } else {
      this.setState({
        isNew: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    const messageErrorPlanFree = "Você está com o plano free ativo, e tem direito a apenas 1 usuário extra. Para habilitar mais usuários, é necessário assinar o plano Premium."
    if (
      newProps.user != null &&
      newProps.fetchUserStatus != this.props.fetchUserStatus &&
      newProps.fetchUserStatus === HttpRequestStatus.SUCCESS
    ) {
      this.mapUserToField(newProps.user);
    }

    if (newProps.createUserStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    }

    if (newProps.updateUserStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessUpdateAlertPage();
      this.props.reset();
    }

    console.log(newProps)

    if (newProps.createUserStatus === HttpRequestStatus.ERROR && !newProps.createUserErrorMessage?.message?.includes(messageErrorPlanFree)) {
      this.toggleErrorModal();
    }

    if (newProps?.createUserErrorMessage?.message?.includes(messageErrorPlanFree)) {
      this.toggleErrorModalPlan();
    }

    if (newProps.updateUserStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  toggleErrorModalPlan = () => {
    this.setState({
      showErrorModalPlan: !this.state.showErrorModalPlan,
    });
  };

  closeModal = () => {
    this.setState({
      showErrorModalPlan: !this.state.showErrorModalPlan,
    });
  };

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateEmail()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    if (this.onValidateCPF()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }

    return hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateCPF = () => {
    const { cpf } = this.state;
    const validate = validateCPF(cpf);
    this.setState({
      cpfError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  mapUserToField = (user: IUser) => {
    this.setState(
      {
        name: user.name,
        cpf: user.cpf,
        phone: user.phone,
        email: user.email,
      },
      () => this.onValidateAll()
    );
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.userCreate',
        buttonUrl: '/me/profile/user-manage',
      },
    });
  };

  goToSuccessUpdateAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.userUpdate',
        buttonUrl: '/me/profile/user-manage',
      },
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onSaveHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const { user, isTransporterAdmin, isFinalDestinationAdmin, isManagerAdmin, isProducerAdmin, isTransshipment } = this.props;
    const { email, name, cpf, phone } = this.state;

    let role = '';
    if (isTransporterAdmin) {
      role = AUTHORITIES.TRANSPORTER_USER;
    } else if (isFinalDestinationAdmin) {
      role = AUTHORITIES.FINAL_DESTINATION_USER;
    } else if (isManagerAdmin) {
      role = AUTHORITIES.MANAGER_USER;
    } else if (isProducerAdmin) {
      role = AUTHORITIES.PRODUCER_USER;
    } else if (isTransshipment) {
      role = AUTHORITIES.TRANSSHIPMENT_USER;
    }

    const newUser: IUser = {
      ...user,
      email,
      name,
      cpf: unmask(cpf),
      phone: unmask(phone),
    };

    const { isNew } = this.state;
    if (isNew) {
      const authorities = ['ROLE_USER'];
      authorities.push(role);
      newUser.authorities = authorities;
      this.props.createUser(newUser);
    } else {
      this.props.updateUser(newUser);
    }
  };

  onGoBack = () => {
    this.props.history.push('/me/profile/user-manage');
  };

  render() {
    const { createUserStatus, fetchUserStatus, updateUserStatus } = this.props;
    const { name, nameError, cpf, cpfError, phone, phoneError, email, emailError } = this.state;
    if (checkStatusOnGoing([createUserStatus, fetchUserStatus, updateUserStatus])) {
      return <Loading />;
    }
    return (
      <div className="new-user__items">
        <BackHeaderComponent onBack={this.onGoBack} />
        <div style={{ marginBottom: '35px' }}>
          <h1>
            {' '}
            {this.state.isNew ? (
              <Translate contentKey={'profile.manage-users.title-second'} />
            ) : (
              <span>{translate('user.form.updateTitle')}</span>
            )}
          </h1>
        </div>
        <Row>
          <Col style={{ margin: 'auto' }} md="4">
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  name: text,
                })
              }
              id="name"
              style={{ marginTop: '20px' }}
              value={name}
              onMask={maskName}
              label={translate('user.form.name')}
              placeholder={translate('user.form.name')}
              error={nameError.hasError}
              errorText={translateErrorMessage(nameError.errorMessage)}
              onBlur={this.onValidateName}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  email: text,
                })
              }
              id="email"
              style={{ marginTop: '20px' }}
              value={email}
              label={translate('user.form.email')}
              placeholder={translate('user.form.email')}
              error={emailError.hasError}
              errorText={translateErrorMessage(emailError.errorMessage)}
              onBlur={this.onValidateEmail}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  cpf: text,
                })
              }
              id="cpf"
              style={{ marginTop: '20px' }}
              value={cpf}
              onMask={maskCPF}
              label={translate('user.form.cpf')}
              placeholder={translate('user.form.cpf')}
              error={cpfError.hasError}
              errorText={translateErrorMessage(cpfError.errorMessage)}
              onBlur={this.onValidateCPF}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  phone: text,
                })
              }
              id="phone"
              style={{ marginTop: '20px' }}
              value={phone}
              onMask={maskPhone}
              label={translate('user.form.phone')}
              placeholder={translate('user.form.phone')}
              error={phoneError.hasError}
              errorText={translateErrorMessage(phoneError.errorMessage)}
              onBlur={this.onValidatePhone}
            />
          </Col>
        </Row>
        <AlertModal
          showModal={this.state.showErrorModal}
          buttonTwoActionMessage={'entity.action.ok'}
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage={this.state.isNew ? 'errorMessages.userCreate' : 'errorMessages.userUpdate'}
        />
        <AlertModalPlanRestricted closeModal={() => this.closeModal()} showModal={this.state.showErrorModalPlan} title='exceededUsers' description={this.props?.createUserErrorMessage?.message} premium={false} history={() => this.props.history.push('/full-register/plans')} />
        <div className="new-user--buttons">
          <button style={{ width: '172px' }} className={'button third'} type={'submit'} onClick={this.onGoBack}>
            <Translate contentKey={'mtr-signature.button-cancel'} />
          </button>
          <button
            style={{ width: '172px' }}
            className={'button primary'}
            type={'button'}
            onClick={() => {
              this.onSaveHandler();
            }}
          >
            <Translate contentKey={'mtr-signature.button-save'} />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  createUserErrorMessage: root.profile.createUserErrorMessage,
  fetchUserStatus: root.profile.fetchUserStatus,
  createUserStatus: root.profile.createUserStatus,
  updateUserStatus: root.profile.updateUserStatus,
  user: root.profile.user,
  isTransporterAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSPORTER_ADMIN]),
  isManagerAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
  isProducerAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN]),
  isTransshipment: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN]),
  isFinalDestinationAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN]),
});

const mapDispatchToProps = {
  getUser,
  createUser,
  updateUser,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerUsersEdit);

import _ from 'lodash';
import * as React from 'react';
import './dropdown-search.scss';

export interface IDropdownSearchProps {
    title: string;
    data: any[];
    getData: (value: string) => void;
    onClickItem: (item: any) => void;
    style?: any;
    placeholder?: string;
    label?: string;
    notAlphabeticalOrder?: boolean;
    selectedRender?: () => JSX.Element;
    close?: boolean;
    filterLocalData?: boolean;
    showInsiderSelect?: boolean;
    error?: boolean;
    selectedData?: any;
    errorMessage?: string;
    disableOpen?: boolean;
    onOpen?: () => void;
    showSelectedStyle?: boolean;
    showAllItems?: boolean;
    showSearchField?: boolean;
}
export interface IDropdownSearchState {
    isOpen: boolean;
    formattedData: any[];
    selected?: boolean;
    canShowNoResults?: boolean;
    maxHeight?: number;
    inputToFilter: string;
    selectedData: any;
}

export default class DropdownSearch extends React.Component<IDropdownSearchProps, IDropdownSearchState> {
    private currentLetter: string = '';
    private dropdownSearchRef;
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            formattedData: props.notAlphabeticalOrder ? props.data : this.mapData(props.data),
            inputToFilter: '',
            selectedData: this.props.selectedData ? this.props.selectedData : null,
            selected: this.props.selectedData ? true : !!props.selectedRender,
        };
        this.forceUpdate();
    }
    private mapData = (data: any[]): any[] => _.orderBy(data, ['name'], ['asc']);

    componentDidMount(): void {
        this.setHeight(window);
        window.addEventListener('resize', this.handleWindowResize);
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillReceiveProps(newProps: IDropdownSearchProps): void {
        if (newProps.data !== this.props.data) {
            this.setState({
                formattedData: newProps.notAlphabeticalOrder ? newProps.data : this.mapData(newProps.data),
                canShowNoResults: newProps.data.length === 0,
            });
        }
        if (newProps.close !== this.props.close && newProps.close) {
            this.setState({
                isOpen: false,
            });
        }
        if (newProps.selectedData !== this.props.selectedData) {
            this.setState({
                selectedData: newProps.selectedData ? newProps.selectedData : null,
                selected: newProps.selectedData != null,
            });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    private handleClickOutside = event => {
        if (this.dropdownSearchRef && !this.dropdownSearchRef.contains(event.target)) {
            if (this.state.inputToFilter !== '') this.props.getData(undefined);
            this.setState({
                isOpen: false,
                inputToFilter: '',
            });
        }
    };
    private setHeight = (windowParam: any) => {
        const elem = this.dropdownSearchRef;
        const { showAllItems } = this.props;
        if (elem != null) {
            const windowHeight: number = windowParam.innerHeight;
            const distanceFromBottom = windowHeight - elem.getBoundingClientRect().bottom - 20;
            this.setState({
                maxHeight: showAllItems ? 600 : distanceFromBottom < 600 ? (distanceFromBottom < 130 ? 130 : distanceFromBottom) : 600,
            });
        }
    };
    private handleWindowResize = (e: any): void => {
        this.setHeight(e.target);
    };
    private handleScroll = (e: any): void => {
        this.setHeight(window);
    };
    private onClickSelect = (): void => {
        this.setState({
            isOpen: !this.props.disableOpen && !this.state.isOpen,
            inputToFilter: '',
        });
        if (this.props.data.length <= 0) this.props.getData('');
        if ((this.props.disableOpen && this.props.onOpen) || (!this.state.isOpen && this.props.onOpen)) {
            this.props.onOpen();
        }
    };
    private onInputChange = (event): void => {
        const { filterLocalData } = this.props;
        const { value } = event.target;
        if (value && value.length >= 1) {
            this.props.getData(value);
        } else if (value != null && value === '') {
            this.props.getData(undefined);
        }
        if (filterLocalData) {
            this.setState({
                inputToFilter: value,
            });
        }
    };
    private onClickItem = (item: any): void => {
        this.setState({
            isOpen: false,
            selected: true,
            selectedData: item,
        });
        this.props.onClickItem(item);
    };

    // Renders
    private renderSectionTitle = (letter: string): JSX.Element => {
        return (
            <div className="dropdown-search__result__section">
                <span className="dropdown-search__result__section__title">{letter.toUpperCase()}</span>
            </div>
        );
    };
    private renderSectionItem = (item: any, hideBorder?: boolean): JSX.Element => {
        return (
            <div className="dropdown-search__result__item" onClick={() => this.onClickItem(item)}>
                <div className="dropdown-search__result__item__content" style={hideBorder ? { border: 'none' } : {}}>
                    <p className="dropdown-search__result__item__title">{item.name}</p>
                    {item.composition && <p className="dropdown-search__result__item__subtitle">{item.composition}</p>}
                </div>
            </div>
        );
    };
    private renderResult = (item: any, index: number): JSX.Element => {
        let letter = item.name;
        try {
            letter = (item.name as string).substr(0, 1);
        } catch (e) {
            console.log(e);
        }
        if (letter !== this.currentLetter && !this.props.notAlphabeticalOrder) {
            this.currentLetter = letter;
            return (
                <div className="dropdown-search__result" key={index}>
                    {this.renderSectionTitle(letter)}
                    {this.renderSectionItem(item, true)}
                </div>
            );
        }
        return (
            <div className="dropdown-search__result" key={index}>
                {this.renderSectionItem(item)}
            </div>
        );
    };

    getFilteredData = () => {
        const { filterLocalData } = this.props;
        const { formattedData, inputToFilter } = this.state;
        if (filterLocalData) {
            if (inputToFilter != null && inputToFilter != '') {
                return formattedData.filter(it => it.name != null).filter(it => it.name.toLowerCase().includes(inputToFilter.toLowerCase()));
            }
        }
        return formattedData;
    };

    onSelectRender = () => {
        const { showInsiderSelect, placeholder, showSelectedStyle } = this.props;
        const { isOpen, selectedData } = this.state;
        if (showInsiderSelect) {
            return (
                <div className={`dropdown-search__select ${isOpen ? 'dropdown-search__select--open' : ''}`} onClick={this.onClickSelect}>
                    <div className="dropdown-search__content--searchbar">
                        {placeholder && <span className="dropdown-search__placeholder">{placeholder}</span>}
                        <span className="dropdown-search__select__text" style={(selectedData?.name?.length ?? 0) > 70 ? { fontSize: '0.9rem' } : {}}>
                            {selectedData ? selectedData.name : ''}
                        </span>
                    </div>
                    <div className={`dropdown-search__select__icon ${showSelectedStyle ? 'active' : ''}`} />
                </div>
            );
        } else {
            return this.props.selectedRender();
        }
    };

    render() {
        const { title, style, selectedRender, showInsiderSelect, error, errorMessage, showSearchField } = this.props;
        const { isOpen, selected, canShowNoResults, maxHeight } = this.state;
        return (
            <div>
                <div id="dropdown-search" className="dropdown-search" style={style} ref={ref => (this.dropdownSearchRef = ref)}>
                    {selected && (selectedRender || showInsiderSelect) ? (
                        this.onSelectRender()
                    ) : (
                        <div className={`dropdown-search__select ${isOpen ? 'dropdown-search__select--open' : ''}`} onClick={this.onClickSelect}>
                            <span className="dropdown-search__select__text">{title}</span>
                            <div className="dropdown-search__select__icon" />
                        </div>
                    )}
                    <input
                        id="fakeTabField"
                        style={{ position: 'absolute', opacity: 0, zIndex: -100 }}
                        onFocus={e => {
                            e.preventDefault();
                            this.dropdownSearchRef.focus();
                            this.onClickSelect();
                        }}
                    />
                    {isOpen && (
                        <div className="dropdown-search__content" style={{ maxHeight }}>
                            {showSearchField ? (
                                <div className="dropdown-search__content__search">
                                    <div className="dropdown-search__content__search__icon" />
                                    <input
                                        className="dropdown-search__content__search__input"
                                        placeholder={'Digite o nome'}
                                        onChange={this.onInputChange}
                                        autoFocus
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                            {canShowNoResults ? (
                                <div className="dropdown-search__content__no-results">
                                    <span className="dropdown-search__content__no-results__text">{'Nenhum resultado encontrado'}</span>
                                </div>
                            ) : (
                                <div className="dropdown-search__content__results">
                                    {this.getFilteredData().map((it, index) => this.renderResult(it, index))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {error && <span className="dropdown__error">{errorMessage}</span>}
            </div>
        );
    }
}

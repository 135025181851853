import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { default as Col } from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './user-profile-menu.scss';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import UserProfileData from './data/user-profile-data';
import UserSignature from './signature/user-signature';
import UserSignatureEdit from './signature/edit/user-signature-edit';
import ManagerUsers from './manage-users/manage-users';
import ManagerProfiles from './manage-profiles/manage-profiles';
import UserProfileDataEdit from './data/edit/user-profile-data-edit';
import Container from 'reactstrap/lib/Container';
import UserIntegrationEdit from '../user-integration/data/user-integration-edit';
import UserIntegration from '../user-integration/user-integration';
import Plans from './plans/plans';
import UserProfilePasswordEdit from './password/edit/user-profile-password-edit';

export interface IUserProfileMenuProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IUserProfileMenuState { }

export class UserProfileMenu extends React.Component<IUserProfileMenuProps, IUserProfileMenuState> {
  constructor(props: Readonly<IUserProfileMenuProps>) {
    super(props);
    this.state = {};
  }

  async componentDidMount() { }

  componentWillUnmount() { }

  componentWillReceiveProps(newProps) { }

  onMenuItemClicked = (path: string) => {
    const { url } = this.props.match;
    this.props.history.push(`${url}/${path}`);
  };

  onExitClicked = () => {
    this.props.history.push(`/logout`);
  };

  onEditClicked = () => {
    this.props.history.push('data/edit');
  };

  onEditClickedSignature = () => {
    this.props.history.push('edit/signature');
  };

  onChangePassword = () => {
    this.props.history.push('password/edit');
  };

  render() {
    const { hasAdminAuthorities, hasProducerAdminAuthorities, hasManagerAuthorities, hasTransporterAuthorities, hasFinalDestinationAuthorities, hasTransshipmentAuthorities } = this.props;
    return (
      <div className="user-profile-menu__items" style={{ position: 'relative' }}>
        <div className="user-profile-menu__items--back">
          <Container>
            <Row>
              <Col md="10">
                <div className="confirmation--back" onClick={() => this.props.history.push('/')}>
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              { /*this.props.location.pathname.indexOf('data') != -1*/
                this.props.location.pathname.toLowerCase() == '/me/profile/data'
                && (
                  <Col md="2">
                    <div className="license-name__items--edit" onClick={this.onEditClicked}>
                      <Translate contentKey={'mtr-signature.edit'} />
                    </div>
                  </Col>
                )}
              {/*this.props.location.pathname.indexOf('signature') != -1*/
                this.props.location.pathname == '/me/profile/signature'
                && (
                  <Col md="2">
                    <div className="license-name__items--edit" onClick={this.onEditClickedSignature}>
                      <Translate contentKey={'mtr-signature.edit-signature'} />
                    </div>
                  </Col>
                )}
            </Row>
          </Container>
        </div>
        <div className="container" style={{ marginLeft: "110px", marginRight: "116px" }}>
          <Row>
            <Col md="2">
              <div className="user-profile-menu__items--sidebar">
                <div className="user-profile-menu__items--sidebar-title">
                  <Translate contentKey={'profile.personal-data.profile'} />
                </div>
                <UserProfileMenuItem
                  title={translate('profile.personal-data.personal-data')}
                  path="data"
                  url={this.props.location.pathname}
                  onClick={this.onMenuItemClicked}
                />
                <UserProfileMenuItem
                  title={translate('profile.personal-data.change-password')}
                  path="password"
                  url={this.props.location.pathname}
                  onClick={this.onMenuItemClicked}
                />
                {hasAdminAuthorities && !hasManagerAuthorities && (
                  <UserProfileMenuItem
                    title={translate('profile.personal-data.responsibleTecnican')}
                    path="signature"
                    url={this.props.location.pathname}
                    onClick={this.onMenuItemClicked}
                  />
                )}
                {(hasProducerAdminAuthorities || hasTransporterAuthorities || hasFinalDestinationAuthorities || hasTransshipmentAuthorities) && (
                  <UserProfileMenuItem
                    title={translate('profile.personal-data.integration-user')}
                    path="user-integration"
                    url={this.props.location.pathname}
                    onClick={this.onMenuItemClicked}
                  />
                )}
                {hasAdminAuthorities && !hasTransporterAuthorities && (
                  <UserProfileMenuItem
                    title={translate('profile.personal-data.manage-users')}
                    path="user-manage"
                    url={this.props.location.pathname}
                    onClick={this.onMenuItemClicked}
                  />
                )}
                {hasAdminAuthorities && (
                  <UserProfileMenuItem
                    title={translate('profile.personal-data.manage-profile')}
                    path="profiles-manage"
                    url={this.props.location.pathname}
                    onClick={this.onMenuItemClicked}
                  />
                )}
                <UserProfileMenuItem
                  title={translate('profile.personal-data.PlanTitle')}
                  path="plans"
                  url={this.props.location.pathname}
                  onClick={this.onMenuItemClicked}
                />
                {/* <UserProfileMenuItem
                  title={translate('profile.exit')}
                  path="logout"
                  url={this.props.location.pathname}
                  onClick={this.onExitClicked}
                /> */}
              </div>
            </Col>
            <Col md="10">
              <Switch>
                <Route
                  exact
                  path={`${this.props.match.url}/data`}
                  render={routeProps => <UserProfileData {...routeProps} {...this.state} />}
                />
                <Route
                  exact
                  path={`${this.props.match.url}/password`}
                  render={routeProps => <UserProfilePasswordEdit {...routeProps} {...this.state} />}
                />
                <Route
                  path={`${this.props.match.url}/data/edit`}
                  render={routeProps => <UserProfileDataEdit {...routeProps} {...this.state} />}
                />
                <Route
                  path={`${this.props.match.url}/signature`}
                  render={routeProps => <UserSignature {...routeProps} {...this.state} />}
                />
                <Route
                  path={`${this.props.match.url}/edit/signature`}
                  render={routeProps => <UserSignatureEdit {...routeProps} {...this.state} />}
                />
                <Route
                  path={`${this.props.match.url}/user-manage`}
                  render={routeProps => <ManagerUsers {...routeProps} {...this.state} />}
                />
                <Route
                  path={`${this.props.match.url}/profiles-manage`}
                  render={routeProps => <ManagerProfiles {...routeProps} {...this.state} />}
                />
                <Route
                  path={`${this.props.match.url}/plans`}
                  render={routeProps => <Plans {...routeProps} {...this.state} />}
                />
                <Route path={`${this.props.match.url}/user-integration`}
                  render={routeProps => <UserIntegration {...routeProps} {...this.state} />}
                />
              </Switch>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  hasAdminAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.PRODUCER_ADMIN,
    AUTHORITIES.TRANSPORTER_ADMIN,
    AUTHORITIES.FINAL_DESTINATION_ADMIN,
    AUTHORITIES.MANAGER_ADMIN,
    AUTHORITIES.TRANSSHIPMENT_ADMIN
  ]),
  hasProducerAdminAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.PRODUCER_ADMIN
  ]),
  hasManagerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.MANAGER_ADMIN,
    AUTHORITIES.MANAGER_USER
  ]),
  hasTransporterAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.TRANSPORTER_ADMIN,
    AUTHORITIES.TRANSPORTER_USER,
  ]),
  hasTransshipmentAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.TRANSSHIPMENT_ADMIN,
    AUTHORITIES.TRANSSHIPMENT_USER,
  ]),
  hasFinalDestinationAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.FINAL_DESTINATION_ADMIN,
    AUTHORITIES.FINAL_DESTINATION_USER,
  ]),
});

const mapDispatchToProps = {
  getSession,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileMenu);

interface UserProfileMenuItemProps {
  path: string;
  title: string;
  url: string;
  onClick: (path: string) => void;
}

class UserProfileMenuItem extends React.Component<UserProfileMenuItemProps> {
  renderClassName = () => {
    const { path, url } = this.props;

    let className = 'user-profile-menu__items--sidebar-subtitle';

    if (url.indexOf(path) != -1) {
      className = className.concat('--active');
    }
    return className;
  };

  render() {
    const { title, path } = this.props;
    return (
      <div className={this.renderClassName()} onClick={() => this.props.onClick(path)}>
        <span>{title}</span>
      </div>
    );
  }
}

import { IRootState } from 'app/shared/reducers';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Row, Container, Modal, ModalBody } from 'reactstrap';
import './alert-modal-new.scss';

export interface IAlertModalNewProps extends StateProps, DispatchProps {
    statusImage: string;
    alertMessage: string;
    hasTwoButtons?: boolean;
    hasOneButton?: boolean;
    buttonOneActionMessage?: string;
    buttonTwoActionMessage: string;
    buttonTwoAction: () => void;
    buttonOneAction?: () => void;
    params?: any;
    showModal: boolean;
}

export interface IAlertModalNewState { }

export class AlertModalNew extends React.Component<IAlertModalNewProps, IAlertModalNewState> {
    constructor(props: Readonly<IAlertModalNewProps>) {
        super(props);
        this.state = {
            statusImage: null,
            alertMessage: null,
        };
    }

    componentDidMount() { }

    componentWillReceiveProps(values) { }

    componentWillUnmount() {
        this.props.showHeader();
    }

    render() {
        const {
            statusImage,
            alertMessage,
            hasTwoButtons,
            buttonOneAction,
            buttonOneActionMessage,
            buttonTwoAction,
            buttonTwoActionMessage,
            showModal,
            params,
        } = this.props;
        if (alertMessage == null) {
            return <div />;
        }
        return (
            <Modal contentClassName="alert-modal-new__props" isOpen={showModal} fullscreen={false} centered style={{ margin: 'auto' }} backdropClassName="backdrop_modal">
                <div className="alert-modal-new-container">
                    <div className="image-wrapper">
                        <img src={`content/images/icons/${statusImage}`} />
                    </div>
                    <div className="message-wrapper">
                        <span>{translate(`${alertMessage}.message`, { params: params })}</span>
                    </div>
                    <div className={'button-container'}>
                        {hasTwoButtons && (
                            <button className={'button primary back-btn'} type={'submit'} onClick={buttonOneAction}>
                                <Translate contentKey={buttonOneActionMessage}>Back</Translate>
                            </button>
                        )}
                        <button className={'button fourth'} type={'submit'} onClick={buttonTwoAction}>
                            <Translate contentKey={buttonTwoActionMessage}>Next</Translate>
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { hideHeader, showHeader };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AlertModalNew);

import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Residue from './residue';
import ResidueDetail from './residue-detail';
import ResidueUpdate from './residue-update';
import ResidueDeleteDialog from './residue-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResidueUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResidueUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResidueDetail} />
      <ErrorBoundaryRoute path={match.url} component={Residue} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ResidueDeleteDialog} />
  </>
);

export default Routes;

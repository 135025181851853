export interface PageableResponse<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  sort: PageableSort;
  numberOfElements: number;
  first: boolean;
  size: number;
  number: number;
}

export interface Pageable {
  size?: number;
  page?: number;
  sort?: string;
  totalElements: number;
  totalPages: number;
}

export interface PageableSort {
  sorted: boolean;
  unsorted: boolean;
}

export enum SortType {
  DESC = 'desc',
  ASC = 'asc',
}

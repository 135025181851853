import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Storage, Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import ErrorBoundary from 'app/shared/error/error-boundary';
import HeaderBuilder, { HeaderType } from '../layout/header/builder/header-builder';
import './private-route.scss';
import { Button } from 'reactstrap';
import { AUTHENTICATED_KEY } from 'app/shared/reducers/authentication';

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
  headerType?: HeaderType;
}

export interface IPrivateRouteProps extends IOwnProps, StateProps {}

export const PrivateRouteComponent = ({
  component: Component,
  isAuthenticated,
  sessionHasBeenFetched,
  isAuthorized,
  hasAnyAuthorities = [],
  headerType,
  ...rest
}: IPrivateRouteProps) => {
  const checkAuthorities = props =>
    isAuthorized ? (
      <ErrorBoundary>
        <HeaderBuilder location={props.location} headerType={headerType ? headerType : HeaderType.HEADER} />
        <Component {...props} />
      </ErrorBoundary>
    ) : (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          <div style={{ fontSize: '45px', color: '#B9B9B9', marginBottom: '30px' }}>Ops!</div>
          <div style={{ marginBottom: '65px' }}>
            <Translate contentKey="error.http.message" />
          </div>
          <Button className={'button primary'} type={'submit'} onClick={() => props.history.push('/login')}>
            <Translate contentKey="error.http.button" />
          </Button>
          {/* <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate> */}
        </div>
      </div>
    );

  const renderRedirect = props => {
    if (!sessionHasBeenFetched) {
      return <div />;
    } 
    
    if (!sessionHasBeenFetched && !Storage.local.get(AUTHENTICATED_KEY)) {
      return (
        <div className="insufficient-authority">
          <div className="alert alert-danger">
            <div style={{ fontSize: '45px', color: '#B9B9B9', marginBottom: '30px' }}>Ops!</div>
            <div style={{ marginBottom: '65px' }}>
              <Translate contentKey="error.http.message" />
            </div>
            <Button className={'button primary'} type={'submit'} onClick={() => props.history.push('/login')}>
              <Translate contentKey="error.http.button" />
            </Button>
            {/* <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate> */}
          </div>
        </div>
      );
    } 
    
    return isAuthenticated ? (
        checkAuthorities(props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect} />;
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities == null) {
    return false;
  }
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

const mapStateToProps = (
  { authentication: { isAuthenticated, account, sessionHasBeenFetched } }: IRootState,
  { hasAnyAuthorities = [] }: IOwnProps
) => ({
  isAuthenticated,
  isAuthorized: hasAnyAuthority(account.authorities, hasAnyAuthorities),
  sessionHasBeenFetched,
});

type StateProps = ReturnType<typeof mapStateToProps>;

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export const PrivateRoute = connect(mapStateToProps, null, null, { pure: false })(PrivateRouteComponent);

export default PrivateRoute;

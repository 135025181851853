import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transporter-register-address.scss';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';
import AddressFormComponent from 'app/components/address-form-component/address-form-component';
import { IAddress } from 'app/shared/model/address.model';

export interface ITransporterRegisterAddressProps extends StateProps, DispatchProps {
  userTransporter: IUserTransporter;
  onNext: (userTransporter: IUserTransporter) => void;
  onBack: () => void;
}

export interface ITransporterRegisterAddressState {
  address: IAddress;
}

export class TransporterRegisterAddress extends React.Component<ITransporterRegisterAddressProps, ITransporterRegisterAddressState> {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.userTransporter.transporter
        ? this.props.userTransporter.transporter.address
          ? this.props.userTransporter.transporter.address
          : {}
        : {},
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userTransporter !== this.props.userTransporter) {
      this.setState({
        address: this.props.userTransporter.transporter
          ? this.props.userTransporter.transporter.address
            ? this.props.userTransporter.transporter.address
            : {}
          : {}
      });
    }
  }

  onNextHandle = (address: IAddress) => {
    const { userTransporter } = { ...this.props };
    userTransporter.transporter = {
      ...userTransporter.transporter,
      address: address,
    };

    this.props.onNext(userTransporter);
  };

  render() {
    const { address } = this.state;
    return (
      <div>
        <AddressFormComponent onBack={this.props.onBack} onNextHandler={this.onNextHandle} address={address} />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterAddress);

import {StorageLicense} from 'app/shared/model/storage-license.model';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import NextCancelButton from '../../next-cancel-button/next-cancel-button';
import StorageLicenseForm from '../form/storage-license-form';
import './storage-license-list.scss';

export interface IStorageLicenseListProps extends StateProps, DispatchProps {
  storageLicenses: StorageLicense[];
  onNextHandler: (storageLicenses: StorageLicense[]) => void;
  onBack: () => void;
}

export interface IStorageLicenseListState {
  storageLicenses: StorageLicense[];
  showForm: boolean;
  licenseIndex: number;
  storageLicenseToUpdate: StorageLicense;
  error: boolean;
}

export class StorageLicenseList extends React.Component<IStorageLicenseListProps, IStorageLicenseListState> {
  constructor(props) {
    super(props);
    this.state = {
      storageLicenses: this.props.storageLicenses ? this.props.storageLicenses : [],
      showForm: false,
      licenseIndex: null,
      storageLicenseToUpdate: null,
      error: false,
    };
  }

  async componentDidMount() {}

  setUpdateIndex = (storageLicense: StorageLicense, index: number) => {
    this.setState(
        {
          licenseIndex: index,
          storageLicenseToUpdate: storageLicense,
        },
        () => this.toggleShowForm()
    );
  };

  clearUpdateIndex = () => {
    this.setState({
      licenseIndex: null,
      storageLicenseToUpdate: null,
    });
  };

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  };

  onSaveStorageLicense = (storageLicense: StorageLicense) => {
    const storageLicenses = [...this.state.storageLicenses];
    storageLicenses.push(storageLicense);
    this.setState(
        {
          storageLicenses,
          error: false,
        },
        () => this.toggleShowForm()
    );
  };

  onUpdateStorageLicense = (storageLicense: StorageLicense, index: number) => {
    const storageLicenses = [...this.state.storageLicenses];
    storageLicenses[index] = storageLicense;
    this.setState(
        {
          storageLicenses,
        },
        () => {
          this.clearUpdateIndex();
          this.toggleShowForm();
        }
    );
  };

  onDeleteStorageLicense = (index: number) => {
    const storageLicenses = [...this.state.storageLicenses];
    storageLicenses.splice(index, 1);
    this.setState({
      storageLicenses,
    });
  };

  onClose = () => {
    this.clearUpdateIndex();
    this.toggleShowForm();
  };

  onNextHandler = () => {
    const { storageLicenses } = this.state;
    if (storageLicenses.length === 0) {
      this.setState({
        error: true,
      });
      return;
    }
    this.props.onNextHandler(storageLicenses);
  };

  renderStorageLicenseForm = () => {
    const { licenseIndex, storageLicenseToUpdate } = this.state;

    return (
        <StorageLicenseForm
            index={licenseIndex}
            storageLicense={storageLicenseToUpdate ? storageLicenseToUpdate : {}}
            onCreate={this.onSaveStorageLicense}
            onUpdate={this.onUpdateStorageLicense}
            onClose={this.onClose}
        />
    );
  };

  renderList = () => {
    const { storageLicenses, error } = this.state;
    return (
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <div className="enviromental-list__container">
              <h1>{translate('environmentalLicense.addTitle2')}</h1>
              <div>
                <AddLicenseButton onClick={this.toggleShowForm} />
              </div>
              {error && <span className="datepicker__error">{translate('validation.licenseList.empty')}</span>}
              <div>
                {storageLicenses &&
                storageLicenses.map((item, index) => (
                    <StorageLicenseItem
                        index={index}
                        storageLicense={item}
                        onClick={this.setUpdateIndex}
                        onDelete={this.onDeleteStorageLicense}
                    />
                ))}
              </div>
              <NextCancelButton onNextClick={this.onNextHandler} onCancelClick={this.props.onBack} />
            </div>
          </Col>
        </Row>
    );
  };

  render() {
    const { showForm } = this.state;
    return (
        <div>
          {showForm && this.renderStorageLicenseForm()}
          {!showForm && this.renderList()}
        </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StorageLicenseList);

export const AddLicenseButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
      <div className="add-license__button" onClick={onClick}>
        <div className="plus-sign__image" />
        <span>{translate('environmentalLicense.add').toUpperCase()}</span>
      </div>
  );
};

export const StorageLicenseItem = ({
                                     index,
                                     storageLicense,
                                     onClick,
                                     onDelete,
                                   }: {
  index: number;
  storageLicense: StorageLicense;
  onClick: (storageLicense: StorageLicense, index: number) => void;
  onDelete: (index: number) => void;
}): JSX.Element => {
  return (
      <div className="environmental-item__container" onClick={() => onClick(storageLicense, index)}>
        <div
            style={{
              maxWidth: '75%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
        >
          <span>{storageLicense.licenseNumber}</span>
        </div>
        <div className="invoice-table--img">
          <div
              className="invoice-table--delete-img"
              onClick={e => {
                e.stopPropagation();
                onDelete(index);
              }}
          />
          <div className="invoice-table--open-img" onClick={() => onClick(storageLicense, index)} />
        </div>
      </div>
  );
};

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './transshipment-register-menu.scss';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from '../../steps/steps';
import { UserTransshipment } from 'app/shared/model/user-transshipment.model';
import { TransshipmentRegisterCompany } from '../steps/company/transshipment-register-company';
import { TransshipmentRegisterAddress } from '../steps/address/transshipment-register-address';
import { TransshipmentRegisterResponsible } from '../steps/responsible/transshipment-register-responsible';
import { TransshipmentRegisterDocumentation } from '../steps/documentation/transshipment-register-documentation';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { initializeTransshipment, reset } from '../transshipment-register.reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS, AUTHORITIES } from 'app/config/constants';
import { changeAuthority, getSession } from 'app/shared/reducers/authentication';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getTransshipmentDetailByUuid } from 'app/entities/user-transshipment/user-transshipment.reducer';
import { updateTransshipment } from '../../transshipment/transshipment-register.reducer';

interface ITransshipmentRegisterMenuProps extends StateProps, DispatchProps, RouteComponentProps {

}

interface ITransshipmentRegisterMenuState {
  steps: StepItem[];
  currentIndex: number;
  showResponsibleModal: boolean;
  userTransshipment: UserTransshipment;
  isEdit: boolean;
}

export class TransshipmentRegisterMenu extends React.Component<ITransshipmentRegisterMenuProps, ITransshipmentRegisterMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userTransshipment: {
        user: this.props.account,
      },
      steps: this.mapSteps(),
      currentIndex: 0,
      showResponsibleModal: false,
      isEdit: false,
    };
  }

  onNext = (userTransshipment: UserTransshipment) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsibleModal: true,
        userTransshipment,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userTransshipment,
      });
    }
  };

  componentDidMount() {
    if (this.props.location.pathname.indexOf('/full-register/transshipment/edit') >= 0) {
      this.setState({ isEdit: true })
      this.props.getTransshipmentDetailByUuid()
        .then((response) => this.setState({ userTransshipment: response.value.data }))
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.initializeTransshipmentStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.initializeTransshipmentStatus === HttpRequestStatus.ERROR) {
      this.goToErrorAlertPage();
      this.props.reset();
    }
  }

  goToErrorAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_NO,
        alertMessage: 'alert.transshipmentRegisterError',
        buttonUrl: '/full-register/transshipment',
      },
    });
  }

  goToSuccessAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.transshipmentRegister',
        buttonUrl: '/login',
      },
    });
  };

  onCancelModal = () => {
    this.setState({
      showResponsibleModal: false,
    });
  };

  onConfirmModal = () => {
    const { userTransshipment, isEdit } = this.state;
    if (isEdit) {
      this.props.updateTransshipment(userTransshipment)
    } else {
      this.props.initializeTransshipment(userTransshipment);
    }


    if (this.props.hasTransshipmentAdminAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.TRANSSHIPMENT_ADMIN);
    } else if (this.props.hasTransshipmentUserAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.TRANSSHIPMENT_USER);
    }
  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  private mapSteps = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => (
        <TransshipmentRegisterCompany onNext={this.onNext} onBack={this.onBack} userTransshipment={this.state.userTransshipment} />
      ),
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => (
        <TransshipmentRegisterAddress onNext={this.onNext} onBack={this.onBack} userTransshipment={this.state.userTransshipment} />
      ),
    },
    {
      title: translate('fullRegister.header.responsible'),
      render: () => (
        <TransshipmentRegisterResponsible
          onNext={this.onNext}
          onBack={this.onBack}
          userTransshipment={this.state.userTransshipment}
        />
      ),
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => (
        <TransshipmentRegisterDocumentation
          onNext={this.onNext}
          onBack={this.onBack}
          userTransshipment={this.state.userTransshipment}
        />
      ),
    },
  ];

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  render() {
    const { steps, currentIndex } = this.state;
    const { initializeTransshipmentStatus } = this.props;
    if (initializeTransshipmentStatus === HttpRequestStatus.ONGOING) {
      return <Loading />;
    }
    return (
      <div style={{ position: 'relative' }}>
        <Steps onIndexClick={this.onIndexClick} steps={steps} currentIndex={currentIndex} />
        <AlertModal
          showModal={this.state.showResponsibleModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={this.onConfirmModal}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="alert.responsibilityTerms"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  initializeTransshipment,
  getSession,
  reset,
  changeAuthority,
  getTransshipmentDetailByUuid,
  updateTransshipment
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  initializeTransshipmentStatus: root.transshipmentRegister.initializeTransshipmentStatus,
  isCreatingNewProfile: root.authentication.isCreatingNewProfile,
  hasTransshipmentUserAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSSHIPMENT_USER]),
  hasTransshipmentAdminAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN])
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentRegisterMenu);

import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import ErrorBoundary from 'app/shared/error/error-boundary';
import HeaderBuilder, { HeaderType } from '../layout/header/builder/header-builder';

export interface ErrorBoundaryRouteProps extends RouteProps {
  headerType?: HeaderType;
}

export const ErrorBoundaryRoute = ({ component: Component, ...rest }: ErrorBoundaryRouteProps) => {
  const encloseInErrorBoundary = props => (
    <ErrorBoundary>
      <HeaderBuilder headerType={props.headerType ? props.headerType : HeaderType.SIMPLE} />
      <Component {...props} />
    </ErrorBoundary>
  );

  if (!Component) throw new Error(`A component needs to be specified for path ${(rest as any).path}`);

  return <Route {...rest} render={encloseInErrorBoundary} />;
};

export default ErrorBoundaryRoute;

import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, IUserTransporter } from 'app/shared/model/user-transporter.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
  CREATE_LEGAL: 'transporterRegister/CREATE_LEGAL',
  CREATE_NATURAL: 'transporterRegister/CREATE_NATURAL',
  RESET: 'transporterRegister/RESET',
};

const initialState = {
  errorMessage: null,
  entity: defaultValue,
  createTransporterStatus: HttpRequestStatus.NOOP,
};

export type TransporterRegisterState = Readonly<typeof initialState>;

// Reducer

export default (state: TransporterRegisterState = initialState, action): TransporterRegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_LEGAL):
    case REQUEST(ACTION_TYPES.CREATE_NATURAL):
      return {
        ...state,
        errorMessage: null,
        createTransporterStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_LEGAL):
    case FAILURE(ACTION_TYPES.CREATE_NATURAL):
      return {
        ...state,
        errorMessage: action.payload,
        createTransporterStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.CREATE_LEGAL):
    case SUCCESS(ACTION_TYPES.CREATE_NATURAL):
      return {
        ...state,
        entity: action.payload.data,
        createTransporterStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-transporters';

// Actions
export const createLegal: ICrudPutAction<IUserTransporter> = entity => async dispatch => {
  const url = `${apiUrl}/legal`;
  const entityClean = cleanEntity(entity);
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_LEGAL,
    payload: axios.post(url, entityClean),
  });
  return result;
};

export const createNatural: ICrudPutAction<IUserTransporter> = entity => async dispatch => {
  const url = `${apiUrl}/natural`;
  const entityClean = cleanEntity(entity);
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NATURAL,
    payload: axios.post(url, entityClean),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import React from 'react';
import {IDocument} from 'app/shared/model/document.model';
import {openOrDownloadDocument} from 'app/shared/util/resitrack-utils';
import './simple-file-component.scss';

export interface ISimpleFileComponentProps {
  file: IDocument;
}

export interface ISimpleFileComponentState {}

export class SimpleFileComponent extends React.Component<ISimpleFileComponentProps, ISimpleFileComponentState> {
  constructor(props) {
    super(props);
  }

  render() {
    const { file } = this.props;
    return (
      <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => openOrDownloadDocument(file)}>
        <div className="simple-file__items--img" />
        <div className="simple-file__items--text">{file ? file.fileName : ''}</div>
      </div>
    );
  }
}

export default SimpleFileComponent;

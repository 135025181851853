import React from 'react';
import Col, { default as Button } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './simple-header.scss';
import { Link } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export interface ISimpleHeaderProps {
  isAuthenticated: boolean;
  csvStatus?: HttpRequestStatus;
  downloadCsvAction?: () => void;
}

export const SimpleHeader = (props: ISimpleHeaderProps) => {
  return (
    <div className="container__header">
      <Container>
        <Row style={{ alignItems: 'center' }}>
          <Col md="9" xs="11">
            <div className="header">
              <Link to={'/'}>
                <div style={{ maxHeight: '19px' }}>
                  <div className="header--logo" />
                </div>
              </Link>
            </div>
          </Col>
          {props.downloadCsvAction && props.isAuthenticated && window.location.pathname.indexOf('user-management') !== -1 && (
            <Col md="2" xs="1">
              <div className={'generate-csv-wrapper'}>
                <Button
                  className={'button primary is-unique'}
                  type={'submit'}
                  disabled={props.csvStatus === HttpRequestStatus.ONGOING}
                  onClick={props.downloadCsvAction}
                >
                  <Translate contentKey={'mtr-list.generateCsv'} />
                </Button>
              </div>
            </Col>
          )}
          {props.isAuthenticated ? (
            <Col md="1" xs="1">
              <Link to={'/logout'} className="header" style={{ alignItems: 'center' }}>
                <span>{translate('entity.action.exit')}</span>
              </Link>
            </Col>
          ) : null}
        </Row>
      </Container>
    </div>
  );
};

export default SimpleHeader;

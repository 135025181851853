// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../content/images/icons/x-2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".plans__container {\n  font-style: \"Catamaran\";\n  background: linear-gradient(to bottom, #4e5e69 0px, #4e5e69 49%, white 0px, white 100%);\n  text-align: center;\n  padding-top: 20px;\n}\n.plans__container--title-main {\n  color: white;\n  font-size: 36px;\n}\n.plans__container--icon-close {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: absolute;\n  top: 11px;\n  right: 80px;\n  cursor: pointer;\n}\n.plans__container--profile {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n  margin-bottom: 45px;\n}\n.plans__container--profile-name {\n  font-weight: 500;\n  font-size: 14px;\n  width: 205px;\n  margin: auto 15px auto 0;\n  padding: 10px 0;\n  background: #f6f6f6;\n  border: 1px solid #e8ebec;\n  color: #2d3e46;\n  border-radius: 6px;\n}\n.plans__container--profile button {\n  background: transparent;\n  border: 1px solid #ffffff;\n  color: #ffffff;\n  border-radius: 4px;\n  padding: 0 10px;\n}", ""]);
// Exports
module.exports = exports;

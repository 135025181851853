import { IUser } from 'app/shared/model/user.model';
import { IFinalDestination } from 'app/shared/model/final-destination.model';
import { ClassCouncil } from './user-generic.model';

export const enum GeneratorUserType {
  LEGALPERSON = 'LEGALPERSON',
  PHYSICALPERSON = 'PHYSICALPERSON',
}

export interface IUserFinalDestination {
  id?: number;
  registrationNumber?: string;
  classCouncil?: ClassCouncil;
  user?: IUser;
  finalDestination?: IFinalDestination;
}

export const defaultValue: Readonly<IUserFinalDestination> = {};

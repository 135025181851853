import React from 'react';
import { connect } from 'react-redux';
import { StepsHeader } from './steps-header';

export interface Props {
  steps: StepItem[];
  currentIndex: number;
  onIndexClick: (index: number) => void;
}
export interface StepItem {
  title: string;
  render: () => JSX.Element;
}

export const Steps = (props: Props): JSX.Element => {
  const currentRender: () => JSX.Element = props.steps[props.currentIndex].render;
  return (
    <div className="steps">
      <StepsHeader currentIndex={props.currentIndex} steps={props.steps.map(it => it.title)} onIndexClick={props.onIndexClick} />
      <div className="steps__content container">{currentRender()}</div>
    </div>
  );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Steps);

import { ITransporter } from 'app/shared/model/transporter.model';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';

export enum DriverType {
  B = 'B',
  C = ' C',
  D = ' D',
  E = ' E',
}

export const enum DriverRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface IDriver {
  id?: number;
  driverLicense?: string;
  expirationDate?: string;
  driverType?: DriverType;
  driverRole?: DriverRole;
  transporter?: ITransporter;
  userTransporter?: IUserTransporter;
}

export function mapDriverToSearch(drivers: IDriver[]) {
  const objects = drivers.map(it => {
    return { name: it.userTransporter.user.name, id: it.id };
  });
  return objects.sort((a, b) => a.name.localeCompare(b.name));
}

export const defaultValue: Readonly<IDriver> = {};

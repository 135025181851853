import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { setLocale } from 'app/shared/reducers/locale';
import axios from 'axios';
import { Storage } from 'react-jhipster';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  CHANGE_AUTHORITY: 'authentication/CHANGE_AUTHORITY',
};

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
export const AUTHENTICATED_KEY = 'authenticated';

const initialState = {
  loading: false,
  isAuthenticated: false,
  getSessionStatus: HttpRequestStatus.NOOP,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: (null as unknown) as string, // Errors returned from server side
  redirectMessage: (null as unknown) as string,
  sessionHasBeenFetched: false,
  idToken: (null as unknown) as string,
  logoutUrl: (null as unknown) as string,
  needsFinishingRegister: false,
  isCreatingNewProfile: false,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
        getSessionStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.CHANGE_AUTHORITY):
      return {
        ...state,
        loading: true,
        getSessionStatus: HttpRequestStatus.ONGOING,
        sessionHasBeenFetched: true,
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload,
        getSessionStatus: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.CHANGE_AUTHORITY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        isCreatingNewProfile: false,
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION):
    case SUCCESS(ACTION_TYPES.CHANGE_AUTHORITY): {
      const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      const needsFinishingRegister = action.payload && action.payload.data && !action.payload.data.finishedRegister;
      return {
        ...state,
        isAuthenticated,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data,
        needsFinishingRegister,
        getSessionStatus: HttpRequestStatus.SUCCESS,
        isCreatingNewProfile: false,
      };
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
        getSessionStatus: HttpRequestStatus.NOOP,
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession: () => void = () => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/account'),
  });

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};

export const login: (username: string, password: string, rememberMe?: boolean) => void = (username, password, rememberMe = false) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('api/authenticate', { username, password, rememberMe }),
  });
  const bearerToken = result.value.data.tokenId;
  Storage.local.set(AUTHENTICATED_KEY, true);
  Storage.local.set(AUTH_TOKEN_KEY, bearerToken);
  Storage.session.set(AUTH_TOKEN_KEY, bearerToken);
  await dispatch(getSession());
};

export const changeAuthority: (authority: string) => void = authority => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CHANGE_AUTHORITY,
    payload: axios.post('api/account/change-authority/' + authority),
  });
  const bearerToken = result.value.headers['x-new-token'];
  clearAuthToken();
  Storage.local.set(AUTHENTICATED_KEY, true);
  Storage.local.set(AUTH_TOKEN_KEY, bearerToken);
  Storage.session.set(AUTH_TOKEN_KEY, bearerToken);
  // window.location.reload(false);
};

export const clearAuthToken = () => {
  if (Storage.local.get(AUTHENTICATED_KEY)) {
    Storage.local.remove(AUTHENTICATED_KEY);
  }
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => void = () => dispatch => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT,
  });
};

export const clearAuthentication = messageKey => (dispatch, getState) => {
  clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};

export const setCreateNewProfile: (isCreatingNewProfile: boolean) => void = isCreatingNewProfile => {
  initialState.isCreatingNewProfile = isCreatingNewProfile;
};

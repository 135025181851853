import './attachmentComponent.scss';
import React from 'react';
import {Col, Row} from 'reactstrap';
import {translate} from 'react-jhipster';
import {IDocument} from 'app/shared/model/document.model';
import {ACCEPT_IMAGE_PDF, IsFileTooLarge} from 'app/shared/util/file-utils';
import {toast} from 'react-toastify';

export interface IAttachmentComponentProps {
  onFileAttached?: (document: IDocument) => void;
  attachmentTitle: string;
  attachment: IDocument;
  error?: boolean;
  errorMessage?: string;
}

export interface IAttachmentComponentState {
  hasFileAttached: boolean;
}

export class AttachmentComponent extends React.Component<IAttachmentComponentProps, IAttachmentComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      hasFileAttached: this.props.attachment != null,
    };
  }

  addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files[0];
    if (IsFileTooLarge(selectedFile.size)) {
      toast(translate('error.image.size'));
      return;
    }
    const reader = new FileReader();
    reader.onload = (ev: any) => {
      const document: IDocument = {
        ...this.props.attachment,
      };
      const contentType = selectedFile.type;
      document.fileName = selectedFile.name;
      document.name = selectedFile.name;
      document.processed = false;
      document.fileContentType = contentType;
      document.file = ev.target.result.split(',')[1];
      this.setState(
        {
          hasFileAttached: true,
        },
        () => this.props.onFileAttached(document)
      );
    };
    reader.readAsDataURL(selectedFile);
  };

  render() {
    const { attachmentTitle, error, errorMessage, attachment } = this.props;
    const { hasFileAttached } = this.state;
    const eventClick = () => document.getElementById('fileInput').click();
    return (
      <div>
        <div className="attachment__items">
          <Row>
            <Col md="8">
              <div style={{ display: 'flex', flexDirection: 'column', wordWrap: 'break-word' }}>
                {hasFileAttached ? (
                  <div className="attachment__items--text">{attachment ? attachment.fileName : ''}</div>
                ) : (
                  <div className="attachment__items--text">{attachmentTitle}</div>
                )}
              </div>
            </Col>
            <Col style={{ margin: 'auto' }} md="4">
              <input id={'fileInput'} onChange={this.addFile} accept={ACCEPT_IMAGE_PDF} type={'file'} style={{ display: 'none' }} />
              {hasFileAttached ? (
                <div onClick={eventClick} className="attachment__items--button">
                  {translate('attachmentComponent.edit')}
                </div>
              ) : (
                <div onClick={eventClick} className="attachment__items--button">
                  {translate('attachmentComponent.attach')}
                </div>
              )}
            </Col>
          </Row>
        </div>
        {error && <span className="attachment__error">{errorMessage ? errorMessage : ''}</span>}
      </div>
    );
  }
}

export default AttachmentComponent;

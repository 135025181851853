import { IRootState } from 'app/shared/reducers';
import { maskPhone } from 'app/shared/util/mask-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './transshipment-details.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
    getEntityByTransshipment as getTransshipment,
    reset,
} from 'app/entities/user-transshipment/user-transshipment.reducer';
import Loading from 'app/components/loading/loading';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import moment from 'moment';
import { APP_ONLY_DATE_FORMAT } from 'app/config/constants';
import { formatToCEP, formatToCNPJ } from 'brazilian-values';
import SimpleFileComponent from 'app/components/simple-file-component/simple-file-component';

export interface ITransshipmentDetailsProps extends StateProps, DispatchProps, RouteComponentProps {}

export interface ITransshipmentDetailsState {}

interface DetailComponent {
  title: string;
  info: string;
}

export class TransshipmentDetails extends React.Component<ITransshipmentDetailsProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getTransshipment(id);
    }
  }

  renderComponent = (title: string, info: string) => (
      <div className="list__items--component">
        <div className="list__items--title">{title}</div>
        <div className="list__items--text">{info}</div>
      </div>
  );

  renderComponentSeries = (details: DetailComponent[]) => (
      <Row className="no-margin">
        {details &&
        details.map((item, index) => (
            <Col className={`no-padding ${index < details.length - 1 ? 'marginR1' : ''}`}>{this.renderComponent(item.title, item.info)}</Col>
        ))}
      </Row>
  );

  createDetailComponent = (title: string, info: string) => {
    return {
      title,
      info,
    };
  };

  renderTitle = (title: string) => {
    return <div className="list__items--title-second">{title}</div>;
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, userTransshipment } = this.props;
    if (loading) {
      return <Loading />;
    }
    const storageLicenses = userTransshipment.transshipment
        ? userTransshipment.transshipment.storageLicenses
            ? userTransshipment.transshipment.storageLicenses
            : null
        : null;
    return (
        <div className="list__items">
          <BackHeaderComponent onBack={this.handleGoBack} />
          <div>
            <h1 style={{ fontWeight: 550 }}>
              {userTransshipment.transshipment ? userTransshipment.transshipment.companyName : ''}
            </h1>
            <Row>
              <Col md="6" style={{ margin: 'auto' }}>
                {this.renderTitle(translate('detailsInfo.companyInfo.title'))}
                {this.renderComponentSeries([
                  this.createDetailComponent(
                      translate('detailsInfo.companyInfo.companyName'),
                      userTransshipment.transshipment ? userTransshipment.transshipment.companyName : ''
                  ),
                  this.createDetailComponent(
                      translate('detailsInfo.companyInfo.cnpj'),
                      userTransshipment.transshipment?.cnpj ? formatToCNPJ(userTransshipment.transshipment.cnpj) : ''
                  ),
                ])}
                {this.renderComponentSeries([
                  this.createDetailComponent(
                      translate('detailsInfo.licenseInfo.licenseNumber'),
                      storageLicenses?.[0]?.licenseNumber ? storageLicenses[0].licenseNumber : ''
                  ),
                  this.createDetailComponent(
                      translate('detailsInfo.licenseInfo.issuingBody'),
                      storageLicenses?.[0]?.issuingBody ? storageLicenses[0].issuingBody : ''
                  ),
                ])}
                {this.renderComponent(
                    translate('detailsInfo.licenseInfo.expirationDate'),
                    storageLicenses?.[0]?.expireDate ? moment(storageLicenses[0].expireDate).format(APP_ONLY_DATE_FORMAT) : ''
                )}
                {this.renderTitle(translate('detailsInfo.addressInfo.title'))}
                {this.renderComponentSeries([
                  this.createDetailComponent(
                      translate('detailsInfo.addressInfo.address'),
                      userTransshipment.transshipment?.address?.street ? userTransshipment.transshipment.address.street : ''
                  ),
                  this.createDetailComponent(
                      translate('detailsInfo.addressInfo.address'),
                      userTransshipment.transshipment?.address?.street ? userTransshipment.transshipment.address.complement : ''
                  ),
                ])}
                {this.renderComponentSeries([
                  this.createDetailComponent(
                      translate('detailsInfo.addressInfo.city'),
                      userTransshipment.transshipment?.address?.city?.name ? userTransshipment.transshipment.address.city.name : ''
                  ),
                  this.createDetailComponent(
                      translate('detailsInfo.addressInfo.state'),
                      userTransshipment.transshipment?.address?.city?.state?.name ? userTransshipment.transshipment.address.city.state.name : ''
                  ),
                  this.createDetailComponent(
                      translate('detailsInfo.addressInfo.zipcode'),
                      userTransshipment.transshipment?.address?.zipcode ? formatToCEP(userTransshipment.transshipment.address.zipcode) : ''
                  ),
                ])}
                {this.renderTitle(translate('detailsInfo.responsible.title'))}
                {this.renderComponent(
                    translate('detailsInfo.responsible.name'),
                    userTransshipment.user?.name ? userTransshipment.user.name : ''
                )}
                {this.renderComponent(
                    translate('detailsInfo.responsible.email'),
                    userTransshipment.user?.email ? userTransshipment.user.email : ''
                )}
                {this.renderComponent(
                    translate('detailsInfo.responsible.phone'),
                    userTransshipment.user?.phone ? maskPhone(userTransshipment.user.phone) : ''
                )}
                {this.renderTitle(translate('detailsInfo.documentation.title'))}
                <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
                  <div className="list__items--title">{translate('detailsInfo.documentation.storageLicense')}</div>
                  <SimpleFileComponent file={storageLicenses && storageLicenses[0] ? storageLicenses[0].licenseDocument : null} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  loading: root.userTransshipment.loading,
  userTransshipment: root.userTransshipment.entity,
});

const mapDispatchToProps = {
  getTransshipment,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentDetails);

import { ITransporter } from 'app/shared/model/transporter.model';

export interface ILegalPerson {
  id?: number;
  cnpj?: string;
  fantasyName?: string;
  companyName?: string;
  phone?: string;
  municipalRegistration?: string;
  transporter?: ITransporter;
}

export const defaultValue: Readonly<ILegalPerson> = {};

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MtrUnits from './mtr-units'
import MtrUnitsDetails from './units-details'
import MtrUnitsEdit from './units-edit'
import MtrUnitsNew from './units-new'

const Units = ({ match }) => {
    return (
        <>
            <Switch>
                <Route exact path={`${match.url}`} component={MtrUnits} />
                <Route path={`${match.url}/details`} component={MtrUnitsDetails} />
                <Route path={`${match.url}/new`} component={MtrUnitsNew} />
                <Route path={`${match.url}/edit`} component={MtrUnitsNew} />
                {/* <Route path={"/mtr-units/edit"} component={MtrUnitsEdit} /> */}
            </Switch>
        </>
    )
};

export default Units;
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { login, changeAuthority } from 'app/shared/reducers/authentication';
import { Col, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import './login.scss';
import ImageCarousel from 'app/components/ImageCarousel/ImageCarousel';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import { ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { validateEmail, validatePassword } from '../validation/validation-constants';
import password from '../account/password/password';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface ILoginState {
  emailError: ValidationResult;
  passwordError: ValidationResult;
  email: string;
  password: string;
}

export class UserLogin extends React.Component<ILoginProps, ILoginState> {
  constructor(props: Readonly<ILoginProps>) {
    super(props);
    this.state = {
      emailError: ValidationResultInstance,
      passwordError: ValidationResultInstance,
      email: null,
      password: null,
    };
  }

  handleSubmit = (event, errors, { rememberMe }) => {
    if (this.onValidateAll()) {
      return;
    }
    this.props.login(this.state.email, this.state.password, rememberMe);
  };

  handleToForgotPassword = () => {
    this.props.history.push(`/reset/request`);
  };

  componentDidMount() {
    this.props.hideHeader();
  }

  onChangeUserName = (value: string) => {
    this.setState({
      email: value,
    });
  };

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateLogin()) {
      hasError = true;
    }
    if (this.onValidatePassword()) {
      hasError = true;
    }
    return hasError;
  };

  onValidatePassword = () => {
    const { password } = this.state;
    const validate = validatePassword(password);
    this.setState({
      passwordError: validate,
    });
    return validate.hasError;
  };

  onValidateLogin = () => {
    const { email } = this.state;
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onChangePassword = (value: string) => {
    this.setState({
      password: value,
    });
  };

  componentWillReceiveProps(values) {
    if (values.loginError && values.loginError != this.props.loginError) {
      this.setState(prevState => ({
        emailError: {
          ...prevState.emailError,
          hasError: true,
          errorMessage: 'validation.common.login',
        },
        passwordError: {
          hasError: true,
          errorMessage: null,
        },
      }));
    }
  }

  componentWillUnmount() {
    this.props.showHeader();
  }

  goToRegister = () => {
    this.props.history.push('/register');
  };

  renderForBrowser = () => {
    const { email, password, emailError, passwordError } = this.state;
    return (
      <div className="login__container">
        <div>
          <div className="login--logo" />
          <ImageCarousel />
        </div>
        <div>
          <Row style={{ height: '100%' }}>
            <Col style={{ margin: 'auto' }} md={{ size: 6, offset: 3 }}>
              <AvForm className={'login-form'} onSubmit={this.handleSubmit}>
                <div className="login--title">
                  <span>
                    <Translate contentKey={'userLogin.title'} />
                    <span className="resitrack">
                      <Translate contentKey={'userLogin.resitrack'} />
                    </span>
                    <Translate contentKey={'userLogin.title-2'} />
                  </span>
                </div>
                <CustomTextField
                  onChange={this.onChangeUserName}
                  id="username"
                  isEmail
                  value={email}
                  label={translate('userLogin.placeholders.email')}
                  placeholder={translate('userLogin.placeholders.email')}
                  error={emailError.hasError}
                  errorText={emailError.errorMessage ? translate(emailError.errorMessage) : ''}
                  onBlur={this.onValidateLogin}
                />
                <div className={'forgot-container'}>
                  <a onClick={this.handleToForgotPassword}>
                    <Translate contentKey={'userLogin.forgotPassword'} />
                  </a>
                </div>

                <CustomTextField
                  id={'password'}
                  value={password}
                  onChange={(text: string) =>
                    this.setState({
                      password: text,
                    })
                  }
                  error={passwordError.hasError}
                  errorText={passwordError.errorMessage ? translate(passwordError.errorMessage) : ''}
                  isPassword
                  label={translate('userLogin.placeholders.password')}
                  placeholder={translate('userLogin.placeholders.password')}
                  onBlur={this.onValidatePassword}
                />
                <div className="login--buttons">
                  <button className={'button primary'} type={'submit'}>
                    <Translate contentKey={'login.form.button'}>Sign in</Translate>
                  </button>
                  <button className={'button secondary'} type={'button'} onClick={this.goToRegister}>
                    <Translate contentKey={'userLogin.register'}>Register</Translate>
                  </button>
                </div>
              </AvForm>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    const { from } = (this.props.location.state as any) || { from: { pathname: '/', search: this.props.location.search } };
    if (this.props.isAuthenticated) {
      if (this.props.needsFinishingRegister) {
        return <Redirect to={'/full-register'} />;
      } else {
        if (!this.props.hasSubscription) {
          return <Redirect to={'full-register/plans'} />
        }

        if (from.pathname !== '/')
          this.props.changeAuthority(`ROLE_${from.pathname.split('/')[1].toLocaleUpperCase().replace('-', '_')}_ADMIN`);

        return <Redirect to={from} />;
      }
    }

    return <div>{this.renderForBrowser()}</div>;
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  needsFinishingRegister: authentication.needsFinishingRegister,
  hasSubscription: authentication.account.hasSubscription,
  loginError: authentication.loginError,
  errorMessage: authentication.errorMessage,
  showModal: authentication.showModalLogin
});

const mapDispatchToProps = { login, hideHeader, showHeader, changeAuthority };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);

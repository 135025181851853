import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getAddresses } from 'app/entities/address/address.reducer';
import { getEntities as getLegalPeople } from 'app/entities/legal-person/legal-person.reducer';
import { getEntities as getNaturalPeople } from 'app/entities/natural-person/natural-person.reducer';
import { getEntities as getProducers } from 'app/entities/producer/producer.reducer';
import { IRootState } from 'app/shared/reducers';
import { mapIdList } from 'app/shared/util/entity-utils';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './manager.reducer';

export interface IManagerUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IManagerUpdateState {
  isNew: boolean;
  idsproducer: any[];
  addressId: string;
  legalPersonId: string;
  naturalPersonId: string;
}

export class ManagerUpdate extends React.Component<IManagerUpdateProps, IManagerUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      idsproducer: [],
      addressId: '0',
      legalPersonId: '0',
      naturalPersonId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAddresses();
    this.props.getLegalPeople();
    this.props.getNaturalPeople();
    this.props.getProducers();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { managerEntity } = this.props;
      const entity = {
        ...managerEntity,
        ...values,
        producers: mapIdList(values.producers),
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/manager');
  };

  render() {
    const { managerEntity, addresses, legalPeople, naturalPeople, producers, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.manager.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.manager.home.createOrEditLabel">Create or edit a Manager</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : managerEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="manager-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="manager-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="personTypeLabel" for="manager-personType">
                    <Translate contentKey="resitrackApp.manager.personType">Person Type</Translate>
                  </Label>
                  <AvInput
                    id="manager-personType"
                    type="select"
                    className="form-control"
                    name="personType"
                    value={(!isNew && managerEntity.personType) || 'NATURAL'}
                  >
                    <option value="NATURAL">{translate('resitrackApp.PersonType.NATURAL')}</option>
                    <option value="LEGAL">{translate('resitrackApp.PersonType.LEGAL')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="manager-address">
                    <Translate contentKey="resitrackApp.manager.address">Address</Translate>
                  </Label>
                  <AvInput id="manager-address" type="select" className="form-control" name="address.id">
                    <option value="" key="0" />
                    {addresses
                      ? addresses.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="manager-legalPerson">
                    <Translate contentKey="resitrackApp.manager.legalPerson">Legal Person</Translate>
                  </Label>
                  <AvInput id="manager-legalPerson" type="select" className="form-control" name="legalPerson.id">
                    <option value="" key="0" />
                    {legalPeople
                      ? legalPeople.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="manager-naturalPerson">
                    <Translate contentKey="resitrackApp.manager.naturalPerson">Natural Person</Translate>
                  </Label>
                  <AvInput id="manager-naturalPerson" type="select" className="form-control" name="naturalPerson.id">
                    <option value="" key="0" />
                    {naturalPeople
                      ? naturalPeople.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="manager-producer">
                    <Translate contentKey="resitrackApp.manager.producer">Producer</Translate>
                  </Label>
                  <AvInput
                    id="manager-producer"
                    type="select"
                    multiple
                    className="form-control"
                    name="producers"
                    value={managerEntity.producers && managerEntity.producers.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {producers
                      ? producers.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/manager" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  addresses: storeState.address.entities,
  legalPeople: storeState.legalPerson.entities,
  naturalPeople: storeState.naturalPerson.entities,
  producers: storeState.producer.entities,
  managerEntity: storeState.manager.entity,
  loading: storeState.manager.loading,
  updating: storeState.manager.updating,
  updateSuccess: storeState.manager.updateSuccess,
});

const mapDispatchToProps = {
  getAddresses,
  getLegalPeople,
  getNaturalPeople,
  getProducers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerUpdate);

import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { IResidue } from 'app/shared/model/residue.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import StringUtils from 'app/shared/util/string-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import { defaultValue, ISourceResidue } from 'app/shared/model/source-residue.model';

export const ACTION_TYPES = {
  FETCH_RESIDUE_LIST: 'residue/FETCH_RESIDUE_LIST',
  FETCH_SOURCE_RESIDUES_ENTITY: 'residue/FETCH_SOURCE_RESIDUES_ENTITY',
  FETCH_RESIDUE: 'residue/FETCH_RESIDUE',
  ADD_SOURCE_RESIDUE: 'residue/ADD_SOURCE_RESIDUE',
  DELETE_SOURCE_RESIDUE: 'residue/DELETE_SOURCE_RESIDUE',
  RESET: 'residue/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  residue: defaultValue,
  residues: [] as Array<IResidue>,
  sourceResiduesByEntity: [] as Array<ISourceResidue>,
  fetchResidueListStatus: HttpRequestStatus.NOOP,
  fetchResidueStatus: HttpRequestStatus.NOOP,
  fetchSourceResiduesByEntityStatus: HttpRequestStatus.NOOP,
  deleteSourceResidueStatus: HttpRequestStatus.NOOP,
  addSourceResidueStatus: HttpRequestStatus.NOOP,
  updateSuccess: false,
  totalItems: 0,
};

export type ResidueEntitiesState = Readonly<typeof initialState>;

// Reducer

export default (state: ResidueEntitiesState = initialState, action): ResidueEntitiesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RESIDUE_LIST):
      return {
        ...state,
        errorMessage: null,
        fetchResidueListStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.FETCH_SOURCE_RESIDUES_ENTITY):
      return {
        ...state,
        errorMessage: null,
        fetchSourceResiduesByEntityStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.FETCH_RESIDUE):
      return {
        ...state,
        errorMessage: null,
        fetchResidueStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.ADD_SOURCE_RESIDUE):
      return {
        ...state,
        errorMessage: null,
        addSourceResidueStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.DELETE_SOURCE_RESIDUE):
      return {
        ...state,
        errorMessage: null,
        deleteSourceResidueStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_RESIDUE_LIST):
      return {
        ...state,
        fetchResidueListStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_SOURCE_RESIDUES_ENTITY):
      return {
        ...state,
        fetchSourceResiduesByEntityStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_RESIDUE):
      return {
        ...state,
        fetchResidueStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.ADD_SOURCE_RESIDUE):
      return {
        ...state,
        addSourceResidueStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_SOURCE_RESIDUE):
      return {
        ...state,
        deleteSourceResidueStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_RESIDUE_LIST):
      return {
        ...state,
        fetchResidueListStatus: HttpRequestStatus.SUCCESS,
        residues: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_SOURCE_RESIDUES_ENTITY):
      return {
        ...state,
        fetchSourceResiduesByEntityStatus: HttpRequestStatus.SUCCESS,
        sourceResiduesByEntity: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_RESIDUE):
      return {
        ...state,
        fetchResidueStatus: HttpRequestStatus.SUCCESS,
        residue: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.ADD_SOURCE_RESIDUE):
      return {
        ...state,
        addSourceResidueStatus: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SOURCE_RESIDUE):
      return {
        ...state,
        deleteSourceResidueStatus: HttpRequestStatus.SUCCESS,
        residue: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/residues';
const sourceApiUrl = 'api/source-residues';
const producerApiUrl = 'api/producers';
const managerApiUrl = 'api/managers';
const finalDestinationApiUrl = 'api/final-destinations';
const transporterApiUrl = 'api/transporters';

// Actions

export const getEntities = (page?, size?, sort?, search?) => {
  // const searchParams = StringUtils.parseQueryParams(search);
  const params = {
    residueSearch: search,
  };
  const paramsOrganized = organizeSearchParameters(page, size, sort, null);
  const requestUrl = `${apiUrl}${paramsOrganized}`;
  return {
    type: ACTION_TYPES.FETCH_RESIDUE_LIST,
    payload: axios.get<IResidue>(`${requestUrl}?cacheBuster=${new Date().getTime()}`, { params }),
  };
};

export const getEntity: ICrudGetAction<IResidue> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RESIDUE,
    payload: axios.get<IResidue>(requestUrl),
  };
};

export const getSourceResidues: ICrudSearchActionCustom<ISourceResidue> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${sourceApiUrl}/entity${params}`;
  var retorno = {
    type: ACTION_TYPES.FETCH_SOURCE_RESIDUES_ENTITY,
    payload: axios.get<ISourceResidue>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
  return retorno;
};

export const addSourceResidues = (entity: ISourceResidue[]) => async dispatch => {
  const requestUrl = `${sourceApiUrl}/add`;
  const result = await dispatch({
    type: ACTION_TYPES.ADD_SOURCE_RESIDUE,
    payload: axios.post(requestUrl, entity),
  });
  return result;
};

export const deleteSourceResidue: ICrudDeleteAction<ISourceResidue> = id => async dispatch => {
  const requestUrl = `${sourceApiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SOURCE_RESIDUE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getSourceResidues());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

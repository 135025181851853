import qs from 'qs';
import { HttpStatusNumber } from '../model/enum/HttpStatusNumber';
import { Pageable } from '../model/pageable';
import { Predicate } from '../model/predicate.model';

/**
 * Verify if the Validation is valid, then if Status is 200 or equal to ExpectedStatus.
 */
export const checkApiStatus = (validation: boolean, status: number, expectedStatus?: HttpStatusNumber): boolean => {
  const compareStatus = expectedStatus || HttpStatusNumber.OK;
  return status === compareStatus && validation;
};

export const requestParamsFormatter = (predicate: Predicate, page: Pageable, predicateParams?: object) => {
  const pageParams = qs.stringify({ ...page });
  const filterParams: string = Object.keys(predicate)
    .filter(key => predicate[key] != null)
    .map(key =>
      Array.isArray(predicate[key])
        ? `${key}=${predicate[key].map(item => item[predicateParams?.[key] || 'name']).join(',')}`
        : `${key}=${predicate[key]}`
    )
    .join('&');
  return [pageParams, filterParams];
};

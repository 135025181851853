import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { IUserProducer } from 'app/shared/model/user-producer.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import StringUtils from 'app/shared/util/string-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';

export const ACTION_TYPES = {
  CREATE_SUBSIDIARY_BY_HOLDING: 'userProducer/CREATE_SUBSIDIARY_BY_HOLDING',
  GET_PRODUCER_UNITS: 'userProducer/GET_PRODUCER_UNITS',
  GET_FINALDESTINATION_UNITS: 'userProducer/GET_FINALDESTINATION_UNITS',
  GET_TRANSPORTER_UNITS: 'userProducer/GET_TRANSPORTER_UNITS',
  GET_TRANSSHIPMENT_UNITS: 'userProducer/GET_TRANSSHIPMENT_UNITS',
  UPDATE_HOLDING: 'userProducer/UPDATE_HOLDING',
  UPDATE_SUBSIDIARY: 'userProducer/UPDATE_SUBSIDIARY',
  GET_INTEGRATION_UNITS: 'userProducer/GET_INTEGRATION_UNITS',
  RESET: 'userProducer/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  createSubsidiaryStatus: HttpRequestStatus.NOOP,
  updateHoldingStatus: HttpRequestStatus.NOOP,
  updateSubsidiaryStatus: HttpRequestStatus.NOOP,
  getHoldingSubidiariesStatus: HttpRequestStatus.NOOP,
  getProducerSubidiariesStatus: HttpRequestStatus.NOOP,
  getTransporterUnitStatus: HttpRequestStatus.NOOP,
  getTransshipmentUnitStatus: HttpRequestStatus.NOOP,
  getFinalDestinationSubidiariesStatus: HttpRequestStatus.NOOP,
  units: [],
  unitsProducer: [],
  unitsTransporter: [],
  unitsFinalDestination: [],
  unitsTransshipment: [],
  totalItems: 0,
};

export type ProducerUnitsState = Readonly<typeof initialState>;

// Reducer

export default (state: ProducerUnitsState = initialState, action): ProducerUnitsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING):
      return {
        ...state,
        errorMessage: null,
        createSubsidiaryStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING):
      return {
        ...state,
        createSubsidiaryStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING):
      return {
        ...state,
        createSubsidiaryStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        errorMessage: null,
        updateHoldingStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        updateHoldingStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        updateHoldingStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        errorMessage: null,
        updateSubsidiaryStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        updateSubsidiaryStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        updateSubsidiaryStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_INTEGRATION_UNITS):
      return {
        ...state,
        errorMessage: null,
        getHoldingSubidiariesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_INTEGRATION_UNITS):
      return {
        ...state,
        getHoldingSubidiariesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_INTEGRATION_UNITS):
      return {
        ...state,
        getHoldingSubidiariesStatus: HttpRequestStatus.SUCCESS,
        units: action.payload.data.content,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.GET_PRODUCER_UNITS):
      return {
        ...state,
        errorMessage: null,
        getProducerSubidiariesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_PRODUCER_UNITS):
      return {
        ...state,
        getProducerSubidiariesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_PRODUCER_UNITS):
      return {
        ...state,
        getProducerSubidiariesStatus: HttpRequestStatus.SUCCESS,
        unitsProducer: action.payload.data.content,
      };

    case REQUEST(ACTION_TYPES.GET_TRANSPORTER_UNITS):
      return {
        ...state,
        errorMessage: null,
        getTransporterUnitStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSPORTER_UNITS):
      return {
        ...state,
        getTransporterUnitStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSPORTER_UNITS):
      return {
        ...state,
        getTransporterUnitStatus: HttpRequestStatus.SUCCESS,
        unitsTransporter: action.payload.data.content,
      };
    case REQUEST(ACTION_TYPES.GET_FINALDESTINATION_UNITS):
      return {
        ...state,
        errorMessage: null,
        getFinalDestinationSubidiariesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_FINALDESTINATION_UNITS):
      return {
        ...state,
        getFinalDestinationSubidiariesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_FINALDESTINATION_UNITS):
      return {
        ...state,
        getFinalDestinationSubidiariesStatus: HttpRequestStatus.SUCCESS,
        unitsFinalDestination: action.payload.data.content,
      };
    case REQUEST(ACTION_TYPES.GET_TRANSSHIPMENT_UNITS):
      return {
        ...state,
        errorMessage: null,
        getTransshipmentUnitStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSSHIPMENT_UNITS):
      return {
        ...state,
        getTransshipmentUnitStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSSHIPMENT_UNITS):
      return {
        ...state,
        getTransshipmentUnitStatus: HttpRequestStatus.SUCCESS,
        unitsTransshipment: action.payload.data.content,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-producers';

// Actions

export const getIntegrationUnits: ICrudSearchActionCustom<IUserProducer> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `api/integration-units/me${params}`;
  return {
    type: ACTION_TYPES.GET_INTEGRATION_UNITS,
    payload: axios.get<IUserProducer>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getIntegrationUnitsProducer = (id: string) => {
  const requestUrl = `api/integration-units/producer/${id}`;
  return {
    type: ACTION_TYPES.GET_PRODUCER_UNITS,
    payload: axios.get<IUserProducer>(`${requestUrl}`),
  };
};

export const getIntegrationUnitsFinalDestiny = (id: string) => {
  const requestUrl = `api/integration-units/final-destination/${id}`;
  return {
    type: ACTION_TYPES.GET_FINALDESTINATION_UNITS,
    payload: axios.get<IUserProducer>(`${requestUrl}`),
  };
};

export const getIntegrationUnitsTransporter = (id: string) => {
  const requestUrl = `api/integration-units/transporter/${id}`;
  return {
    type: ACTION_TYPES.GET_TRANSPORTER_UNITS,
    payload: axios.get<IUserProducer>(`${requestUrl}`),
  };
};

export const getIntegrationUnitsTransshipment = (id: string) => {
  const requestUrl = `api/integration-units/transshipment/${id}`;
  return {
    type: ACTION_TYPES.GET_TRANSSHIPMENT_UNITS,
    payload: axios.get<IUserProducer>(`${requestUrl}`),
  };
};

export const createSubidiaryByHolding: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/holding/subsidiary`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SUBSIDIARY_BY_HOLDING,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateSubsidiary: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/subsidiary`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SUBSIDIARY,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateHolding: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/holding`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOLDING,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updatePhyscalPerson: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/natural`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOLDING,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

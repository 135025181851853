import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LegalPerson from './legal-person';
import LegalPersonDetail from './legal-person-detail';
import LegalPersonUpdate from './legal-person-update';
import LegalPersonDeleteDialog from './legal-person-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LegalPersonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LegalPersonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LegalPersonDetail} />
      <ErrorBoundaryRoute path={match.url} component={LegalPerson} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={LegalPersonDeleteDialog} />
  </>
);

export default Routes;

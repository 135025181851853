import { IAddress } from 'app/shared/model/address.model';
import { StorageLicense } from 'app/shared/model/storage-license.model';

export interface Transshipment {
  id?: number;
  cnpj?: string;
  phone?: string;
  address?: IAddress;
  companyName?: string;
  fantasyName?: string;
  storageLicenses?: StorageLicense[];
}

export interface ITransshipmentMtr extends Omit<Transshipment, 'phone'> {
  addressLabel?: string;
  addressZipcode?: string;
}

export const defaultValue: ITransshipmentMtr = {
  storageLicenses: [],
};

export function mapTransshipmentToSearch(props) {
  const { transshipments } = props;
  const objects = transshipments?.map(transshipment => {
    return { name: transshipment.companyName, id: transshipment.id };
  });
  return objects?.sort((a, b) => a.name.localeCompare(b.name)) || objects || [];
}

import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { Pageable } from 'app/shared/model/pageable';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/shared/util/string-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { deleteSourceResidue, getSourceResidues, reset } from 'app/entities/residue/residue-entities.reducer';
import './manager-residues.scss';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { ISourceResidue } from 'app/shared/model/source-residue.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { ALL_ADMIN_AUTHORITIES } from 'app/config/constants';

export interface IManagerResiduesProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IManagerResiduesState {
  filterName: string;
  page: Pageable;
  showDeleteModal: boolean;
  deleteItemId: number;
}

export class ManagerResidues extends React.Component<IManagerResiduesProps, IManagerResiduesState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('residue.headers.code'), sortCode: 'residue.code' },
    { name: translate('residue.headers.description'), sortCode: 'residue.description' },
    { name: translate('residue.headers.specificResidue'), sortCode: 'specificResidue.name' },
    { name: translate('residue.headers.classification'), sortCode: 'residue.residueClassification' },
    { name: translate('residue.headers.measurementType'), sortCode: 'residue.measurementType' },
    { name: translate('residue.headers.producer'), sortCode: 'sourceName' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IManagerResiduesProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      showDeleteModal: false,
      deleteItemId: null,
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getResidues();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      this.closeDeleteModal();
      this.getResidues();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getResidues = () => {
    const { page } = this.state;
    this.props.getSourceResidues(page.page, page.size, page.sort, StringUtils.toObject('specificResidue.name', this.state.filterName));
  };

  private handleTransformToTableContent = (content?: ISourceResidue[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: ISourceResidue) => {
      result.push([
        item.residue.code != null ? item.residue.code ?? '' : '',
        item.residue.description != null ? item.residue.description : '',
        item.specificResidue.name != null ? item.specificResidue.name : '',
        item.residue.residueClassification != null ? translate(`enums.residueClassification.${item.residue.residueClassification}`) : '',
        item.residue.measurementType != null ? translate(`enums.measurementType.${item.residue.measurementType}`) : '',
        item.sourceName != null ? item.sourceName : '',
        <DeleteEditButton id={item.id} onDelete={this.onDeleteResidue} hideEdit hideDelete={!this.props.hasAnyAdminAuthority} />,
      ]);
    });

    return result;
  };

  onDeleteResidue = (id: number) => {
    this.setState({
      deleteItemId: id,
      showDeleteModal: true,
    });
  };

  chooseDelete = () => {
    this.props.deleteSourceResidue(this.state.deleteItemId);
  };

  closeDeleteModal = () => {
    this.setState({
      deleteItemId: null,
      showDeleteModal: false,
    });
  };

  onCreateResidue = () => {
    this.props.history.push(`${this.props.match.url}/add`);
  };

  componentWillUnmount() {
    this.props.reset();
  }

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getResidues()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getResidues()
    );
  };

  onHandleFilterChanged = value => {
    this.setState({ filterName: value }, () => this.getResidues());
  };

  render() {
    const { sourceResiduesByEntity, hasAnyAdminAuthority } = this.props;
    const { showDeleteModal } = this.state;
    const rows = this.handleTransformToTableContent(sourceResiduesByEntity);
    return (
      <div className="mtr-unity__items">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('residue.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px' }}>
            <Col md="6">
              <SimpleSearchField placeholder={translate('residue.search')} onChange={this.onHandleFilterChanged} />
            </Col>
            {hasAnyAdminAuthority && (
              <Col md={{ size: 2, offset: 4 }}>
                <Button className={'button primary'} type={'submit'} onClick={this.onCreateResidue}>
                  <Translate contentKey={'residue.add'} />
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
              />
            </Col>
          </Row>
        </Container>

        <AlertModal
          showModal={showDeleteModal}
          buttonOneActionMessage={'mtr-signature.button-no'}
          buttonTwoActionMessage={'mtr-signature.button-yes'}
          hasTwoButtons
          buttonTwoAction={this.chooseDelete}
          buttonOneAction={this.closeDeleteModal}
          statusImage="money-bag.svg"
          alertMessage="modal-transporter-add-residue-agree"
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchSourceResiduesByEntityStatus: root.residuesEntities.fetchSourceResiduesByEntityStatus,
  sourceResiduesByEntity: root.residuesEntities.sourceResiduesByEntity,
  deleteStatus: root.residuesEntities.deleteSourceResidueStatus,
  totalItems: root.residuesEntities.totalItems,
  hasAnyAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_ADMIN_AUTHORITIES),
});

const mapDispatchToProps = {
  getSourceResidues,
  deleteSourceResidue,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerResidues);

import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import Loading from 'app/components/loading/loading';
import {
  validateEmail,
  validatePassword,
  validatePasswordMatch,
  validatePersonName
} from 'app/modules/validation/validation-constants';
import {IRootState} from 'app/shared/reducers';
import {getSession} from 'app/shared/reducers/authentication';
import {checkStatusOnGoing, ValidationResult, ValidationResultInstance} from 'app/shared/util/validation-utils';
import React from 'react';
import {translate, Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import './user-profile-password-edit.scss';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import {reset} from '../../profile-reducer';
import {IUser} from 'app/shared/model/user.model';
import AlertModal from 'app/components/alert-modal/alert-modal';
import {HttpRequestStatus} from 'app/shared/model/enum/HttpRequestStatus';
import {savePassword} from "app/modules/account/password/password.reducer";

export interface IUserProfileDataEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
}

export interface IUserProfileDataEditState {
  name: string;
  email: string;
  password: string;
  newPassword: string;
  confirmPassword: string;

  nameError: ValidationResult;
  emailError: ValidationResult;
  passwordError: ValidationResult;
  newPasswordError: ValidationResult;
  confirmPasswordError: ValidationResult;

  showSuccess: boolean;
  showErrorModal: boolean;
}

export class UserProfilePasswordEdit extends React.Component<IUserProfileDataEditProps, IUserProfileDataEditState> {
  constructor(props: Readonly<IUserProfileDataEditProps>) {
    super(props);
    this.state = {
      name: null,
      email: null,
      password: this.props.account.password? this.props.account.password : null,
      newPassword: null,
      confirmPassword: null,

      nameError: ValidationResultInstance,
      emailError: ValidationResultInstance,
      newPasswordError: ValidationResultInstance,
      passwordError: ValidationResultInstance,
      confirmPasswordError: ValidationResultInstance,

      showErrorModal: false,
      showSuccess: false,
    };
  }

  async componentDidMount() {
    this.props.getSession();
  }

  componentWillUnmount() { }

  componentWillReceiveProps(newProps) {
    if (newProps.account != null && newProps.account != this.props.account) {
      this.setState({
        name: newProps.account.name,
        email: newProps.account.email,
        password: newProps.account.password,
        newPassword: newProps.account.newPassword
      });
    }
    if (newProps.updatePasswordStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
      this.props.getSession();
      this.props.reset();
    }
  }

  search = () => { };

  validateAllFields = () => {
    let hasError = false;
    if (this.onValidateEmail()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    if (this.onValidatePassword()) {
      hasError = true;
    }
    if (this.onValidateConfirmPassword()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    if (name == null || name.length === 0) {
      return;
    }
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    if (email == null || email.length === 0) {
      return;
    }
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onValidateNewPassword = () => {
    const { newPassword } = this.state;
    if (newPassword == null || newPassword.length === 0) {
      return;
    }
    const validate = validatePassword(newPassword);
    this.setState({
      newPasswordError: validate,
    });
    return validate.hasError;
  };

  onValidatePassword = () => {
    const { password } = this.state;
    if (password == null || password.length === 0) {
      return;
    }
    const validate = validatePassword(password);
    this.setState({
      passwordError: validate,
    });
    return validate.hasError;
  };

  onValidateConfirmPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    const password = newPassword;
    if (newPassword == null || newPassword.length === 0) {
      return;
    }
    const validate = validatePasswordMatch({ password, match: confirmPassword });
    this.setState({
      confirmPasswordError: validate,
    });
    return validate.hasError;
  };

  onNextClick = () => {
    if (this.validateAllFields()) {
      return;
    }
    const {newPassword, password} = this.state;
    this.props.savePassword(password, newPassword);
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  toggleSuccessModal = () => {
    this.setState({
      showSuccess: !this.state.showSuccess,
    });
  };

  onBackHandler = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, updateAccountStatus} = this.props;
    const {name,email,password,newPassword,confirmPassword, nameError,emailError,passwordError,confirmPasswordError, newPasswordError
      ,showErrorModal,showSuccess
    } = this.state;
    if (loading || checkStatusOnGoing([updateAccountStatus])) {
      return <Loading />;
    }
    return (
      <div>
        <div className="user-profile-menu__items--title">
          <Translate contentKey={'global.menu.account.change-password'} />
        </div>
        <CustomTextField
          id={'name'}
          style={{ marginTop: '10px' }}
          value={name}
          onChange={(text: string) =>
            this.setState({
              name: text,
            })
          }
          error={nameError.hasError}
          errorText={nameError.errorMessage ? translate(nameError.errorMessage) : ''}
          label={translate('global.account.edit.name')}
          placeholder={translate('global.account.edit.name')}
          readonly
        />
        <CustomTextField
          id={'email'}
          style={{ marginTop: '10px' }}
          value={email}
          onChange={(text: string) =>
            this.setState({
              email: text,
            })
          }
          error={emailError.hasError}
          errorText={emailError.errorMessage ? translate(emailError.errorMessage) : ''}
          label={translate('global.account.edit.email')}
          placeholder={translate('global.account.edit.email')}
          readonly
        />

        <CustomTextField
          id={'current-password'}
          style={{ marginTop: '10px' }}
          value={password}
          onChange={(text: string) =>
            this.setState({
              password: text,
            })
          }
          error={passwordError.hasError}
          errorText={passwordError.errorMessage ? translate(passwordError.errorMessage) : ''}
          isPassword
          label={translate('global.account.edit.current-password')}
          placeholder={translate('global.account.edit.current-password')}
          onBlur={this.onValidatePassword}
        />

        <CustomTextField
          id={'firstPassword'}
          style={{ marginTop: '10px' }}
          value={newPassword}
          onChange={(text: string) =>
            this.setState({
              newPassword: text,
            })
          }
          error={newPasswordError.hasError}
          errorText={newPasswordError.errorMessage ? translate(newPasswordError.errorMessage) : ''}
          isPassword
          label={translate('global.account.edit.password')}
          placeholder={translate('global.account.edit.password')}
          onBlur={this.onValidateNewPassword}
        />
        <CustomTextField
          id={'secondPassword'}
          value={confirmPassword}
          style={{ marginTop: '10px' }}
          onChange={(text: string) =>
            this.setState({
              confirmPassword: text,
            })
          }
          error={confirmPasswordError.hasError}
          errorText={confirmPasswordError.errorMessage ? translate(confirmPasswordError.errorMessage) : ''}
          isPassword
          label={translate('global.account.edit.confirmPassword')}
          placeholder={translate('global.account.edit.confirmPassword')}
          onBlur={this.onValidateConfirmPassword}
        />

        <NextCancelButton
          onCancelClick={() => this.props.history.goBack()}
          onNextClick={this.onNextClick}
          nextButtonName={translate('entity.action.save')}
          noArrow
        />

        <AlertModal
          showModal={showSuccess}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.onBackHandler}
          statusImage="garbage.svg"
          alertMessage="alert.accountUpdate"
        />
        <AlertModal
          showModal={showErrorModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.account"
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  loading: root.authentication.loading,
  updateAccountStatus: root.profile.updateAccountStatus,
});

const mapDispatchToProps = {
  getSession,
  savePassword,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePasswordEdit);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { byteSize, openFile, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './document.reducer';

export interface IDocumentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class DocumentDetail extends React.Component<IDocumentDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { documentEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.document.detail.title">Document</Translate> [<b>{documentEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="url">
                <Translate contentKey="resitrackApp.document.url">Url</Translate>
              </span>
            </dt>
            <dd>{documentEntity.url}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="resitrackApp.document.description">Description</Translate>
              </span>
            </dt>
            <dd>{documentEntity.description}</dd>
            <dt>
              <span id="fileName">
                <Translate contentKey="resitrackApp.document.fileName">File Name</Translate>
              </span>
            </dt>
            <dd>{documentEntity.fileName}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="resitrackApp.document.name">Name</Translate>
              </span>
            </dt>
            <dd>{documentEntity.name}</dd>
            <dt>
              <span id="s3Name">
                <Translate contentKey="resitrackApp.document.s3Name">S 3 Name</Translate>
              </span>
            </dt>
            <dd>{documentEntity.s3Name}</dd>
            <dt>
              <span id="processed">
                <Translate contentKey="resitrackApp.document.processed">Processed</Translate>
              </span>
            </dt>
            <dd>{documentEntity.processed ? 'true' : 'false'}</dd>
            <dt>
              <span id="file">
                <Translate contentKey="resitrackApp.document.file">File</Translate>
              </span>
            </dt>
            <dd>
              {documentEntity.file ? (
                <div>
                  <a onClick={openFile(documentEntity.fileContentType, documentEntity.file)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                  <span>
                    {documentEntity.fileContentType}, {byteSize(documentEntity.file)}
                  </span>
                </div>
              ) : null}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/document" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/document/${documentEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ document }: IRootState) => ({
  documentEntity: document.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetail);

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RegisterChoose from './register-choose/register-choose';
import RegisterInit from './register-init/register-init';
import Producer from './producer/index';
import Transporter from './transporter/index';
import Transshipment from './transshipment/index';
import FinalDestination from './final-destination/index';
import Manager from './manager/index';
import ProducerRegisterMenu from './producer/menu/producer-register-menu';
import TransporterRegisterMenu from './transporter/menu/transporter-register-menu';
import TransshipmentRegisterMenu from './transshipment/menu/transshipment-register-menu';
import FinalDestinationRegisterMenu from './final-destination/menu/final-destination-register-menu';
import ManagerRegisterMenu from './manager/menu/manager-register-menu';
import OurPlans from './plans/our-plans/OurPlans';
import PaymentMethod from './plans/payment-method/PaymentMethod';
import PurchaseSummary from './plans/purchase-summary/PurchaseSummary';
import bankslipSummary from './plans/bankslip/bankslip';

const Routes = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}`} component={RegisterInit} />
        <Route exact path={`${match.url}/choose`} component={RegisterChoose} />
        <Route exact path={`${match.url}/plans`} component={OurPlans} />
        <Route exact path={`${match.url}/payment-method`} component={PaymentMethod} />
        <Route exact path={`${match.url}/purchase-summary`} component={PurchaseSummary} />
        <Route exact path={`${match.url}/bankslip-summary`} component={bankslipSummary} />
        <Route exact path={`${match.url}/producer`} component={Producer} />
        <Route exact path={`${match.url}/producer/edit`} component={ProducerRegisterMenu} />
        <Route exact path={`${match.url}/transporter`} component={Transporter} />
        <Route exact path={`${match.url}/transporter/edit`} component={TransporterRegisterMenu} />
        <Route exact path={`${match.url}/transshipment`} component={Transshipment} />
        <Route exact path={`${match.url}/transshipment/edit`} component={TransshipmentRegisterMenu} />
        <Route exact path={`${match.url}/final-destination`} component={FinalDestination} />
        <Route exact path={`${match.url}/final-destination/edit`} component={FinalDestinationRegisterMenu} />
        <Route exact path={`${match.url}/manager`} component={Manager} />
        <Route exact path={`${match.url}/manager/edit`} component={ManagerRegisterMenu} />
    </Switch>
);

export default Routes;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './producer-update-holding.scss';
import { translate } from 'react-jhipster';
import { GeneratorType, IProducer } from 'app/shared/model/producer.model';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import { IDocument } from 'app/shared/model/document.model';
import {
  validateCNPJ,
  validateGenericField,
  validateGenericFieldList,
  validateObject,
  validatePhoneNumber,
} from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { getEntities as getCnaeCodes } from 'app/entities/cnae-code/cnae-code.reducer';
import InlineSelect from 'app/components/inline-select/inline-select';
import { ICnaeCode } from 'app/shared/model/cnae-code.model';
import CnaeCodeComponent from 'app/components/cnae-code-component/cnaeCodeComponent';
import AttachmentComponent from 'app/components/attachment-component/attachmentComponent';
import { maskCnpj, maskNumber, maskPhone, unmask } from 'app/shared/util/mask-utils';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { Col, Row } from 'reactstrap';

export interface IProducerUpdateHoldingProps extends StateProps, DispatchProps {
  userProducer: IUserProducer;
  onNext: (userProducer: IUserProducer) => void;
  onBack: () => void;
}

export interface IProducerUpdateHoldingState {
  cnpj: string;
  companyName: string;
  fantasyName: string;
  municipalRegistration: string;
  phone: string;
  cnaeCodes: ICnaeCode[];
  // businessLicense: IDocument;
  generatorType: GeneratorType;
  cnpjError: ValidationResult;
  companyNameError: ValidationResult;
  fantasyNameError: ValidationResult;
  municipalRegistrationError: ValidationResult;
  phoneError: ValidationResult;
  // businessLicenseError: ValidationResult;
  cnaeCodeError: ValidationResult;
  generatorTypeError: ValidationResult;
}

export class ProducerUpdateHolding extends React.Component<IProducerUpdateHoldingProps, IProducerUpdateHoldingState> {
  constructor(props) {
    super(props);
    const { producer } = this.props.userProducer;
    this.state = {
      cnpj: producer.cnpj ? maskCnpj(producer.cnpj) : null,
      companyName: producer.companyName ? producer.companyName : null,
      fantasyName: producer.fantasyName ? producer.fantasyName : null,
      municipalRegistration: producer.municipalRegistration ? producer.municipalRegistration : null,
      phone: producer.phone ? maskPhone(producer.phone) : null,
      cnaeCodes: producer.cnaeCodes ? producer.cnaeCodes : [],
      // businessLicense: producer.businessLicense ? producer.businessLicense : null,
      generatorType: producer.generatorType ? producer.generatorType : null,
      cnpjError: ValidationResultInstance,
      companyNameError: ValidationResultInstance,
      fantasyNameError: ValidationResultInstance,
      municipalRegistrationError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
      // businessLicenseError: ValidationResultInstance,
      cnaeCodeError: ValidationResultInstance,
      generatorTypeError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    await this.props.getCnaeCodes();
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateCnpj()) {
      hasError = true;
    }
    if (this.onValidateFantasyName()) {
      hasError = true;
    }
    if (this.onValidateCompanyName()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    if (this.onValidateCnaeCodes()) {
      hasError = true;
    }
    // if (this.onValidateBusinessLicense()) {
    //   hasError = true;
    // }
    if (this.onValidateGeneratorType()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateCnpj = () => {
    const { cnpj } = this.state;
    const validate = validateCNPJ(cnpj);
    this.setState({
      cnpjError: validate,
    });
    return validate.hasError;
  };

  onValidateFantasyName = () => {
    const { fantasyName } = this.state;
    const validate = validateGenericField(fantasyName);
    this.setState({
      fantasyNameError: validate,
    });
    return validate.hasError;
  };

  onValidateCompanyName = () => {
    const { companyName } = this.state;
    const validate = validateGenericField(companyName);
    this.setState({
      companyNameError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  // onValidateBusinessLicense = () => {
  //   const { businessLicense } = this.state;
  //   const validate = validateObject(businessLicense);
  //   this.setState({
  //     businessLicenseError: validate,
  //   });
  //   return validate.hasError;
  // };

  onValidateGeneratorType = () => {
    const { generatorType } = this.state;
    const validate = validateObject(generatorType);
    this.setState({
      generatorTypeError: validate,
    });
    return validate.hasError;
  };

  onValidateCnaeCodes = () => {
    const { cnaeCodes } = this.state;
    const validate = validateGenericFieldList(cnaeCodes);
    this.setState({
      cnaeCodeError: validate,
    });
    return validate.hasError;
  };

  mapCnaeCodesToOptions = () => {
    const { cnaeCodes } = this.props;
    const optionsList = [];
    if (cnaeCodes == null || cnaeCodes.length === 0) {
      return optionsList;
    } else {
      cnaeCodes.forEach((item, index) => {
        optionsList.push({ label: `${item.code} - ${item.description}`, value: item.id });
      });
      return optionsList;
    }
  };

  onSelectCnaeCode = value => {
    if (value === -1) {
      return;
    }
    const cnaeCodeSelected = this.props.cnaeCodes.filter(it => it.id === value)[0];
    const cnaeCodes = [];
    if (cnaeCodes.filter(it => it.id === value).length === 0) {
      cnaeCodes.push(cnaeCodeSelected);
      this.setState(
        {
          cnaeCodes,
        },
        () => this.onValidateCnaeCodes()
      );
    }
  };

  onDeleteCnaeCode = value => {
    const cnaeCodes = [...this.state.cnaeCodes];
    cnaeCodes.splice(value, 1);
    this.setState(
      {
        cnaeCodes,
      },
      () => this.onValidateCnaeCodes()
    );
  };

  // onAttachBusinessLicense = (document: IDocument) => {
  //   this.setState(
  //     {
  //       businessLicense: document,
  //     },
  //     () => this.onValidateBusinessLicense()
  //   );
  // };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const userProducer = { ...this.props.userProducer };
    const { cnpj, fantasyName, municipalRegistration, phone, cnaeCodes, generatorType, companyName } = this.state;

    const producer: IProducer = {
      ...userProducer.producer,
      cnpj: unmask(cnpj),
      companyName,
      fantasyName,
      generatorType,
      municipalRegistration,
      phone: unmask(phone),
      cnaeCodes,
      // businessLicense,
    };

    userProducer.producer = producer;

    this.props.onNext(userProducer);
  };

  mapType = () => {
    const objects = Object.keys(GeneratorType).map(key => {
      return { name: translate(`enums.generatorType.${key}`), keyEnum: key };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  search = () => { };

  onClickItem = (item: any) => {
    this.setState(
      {
        generatorType: item.keyEnum,
      },
      () => this.forceUpdate()
    );
  };

  mapSelectedProducerTypeToSelect = () => {
    const { generatorType } = this.state;

    return generatorType
      ? {
        name: translate(`enums.generatorType.${generatorType}`),
        keyEnum: generatorType,
      }
      : null;
  };

  render() {
    const {
      cnpj,
      cnpjError,
      fantasyName,
      fantasyNameError,
      phone,
      phoneError,
      municipalRegistration,
      municipalRegistrationError,
      companyName,
      companyNameError,
      cnaeCodes,
      cnaeCodeError,
      // businessLicenseError,
    } = this.state;
    return (
      <div className="producer-register-holding__items">
        <Row>
          <Col md={{ size: '6', offset: '3' }}>
            <h1 style={{ marginBottom: '30px' }}>{translate('producerRegister.company.subsidiary.form.title')}</h1>
            <DropdownSearch
              data={this.mapType()}
              getData={this.search}
              onClickItem={this.onClickItem}
              selectedData={this.mapSelectedProducerTypeToSelect()}
              placeholder={translate('producerRegister.company.generator')}
              title={translate('producerRegister.company.selectGenerator')}
              style={{ backgroundColor: '#f6f6f6' }}
              showInsiderSelect
              notAlphabeticalOrder
              filterLocalData
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  cnpj: text,
                })
              }
              id="cnpj"
              style={{ marginTop: '20px' }}
              value={cnpj}
              onMask={maskCnpj}
              label={translate('producerRegister.company.holding.form.cnpj')}
              placeholder={translate('producerRegister.company.holding.form.cnpj')}
              error={cnpjError.hasError}
              errorText={translateErrorMessage(cnpjError.errorMessage)}
              onBlur={this.onValidateCnpj}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  companyName: text,
                })
              }
              id="companyName"
              style={{ marginTop: '20px' }}
              value={companyName}
              label={translate('producerRegister.company.holding.form.companyName')}
              placeholder={translate('producerRegister.company.holding.form.companyName')}
              error={companyNameError.hasError}
              errorText={translateErrorMessage(companyNameError.errorMessage)}
              onBlur={this.onValidateCompanyName}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  fantasyName: text,
                })
              }
              id="fantasyName"
              style={{ marginTop: '20px' }}
              value={fantasyName}
              label={translate('producerRegister.company.holding.form.fantasyName')}
              placeholder={translate('producerRegister.company.holding.form.fantasyName')}
              error={fantasyNameError.hasError}
              errorText={translateErrorMessage(fantasyNameError.errorMessage)}
              onBlur={this.onValidateFantasyName}
            />
            {/* <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  municipalRegistration: text,
                })
              }
              id="municipalRegistration"
              onMask={maskNumber}
              style={{ marginTop: '20px' }}
              value={municipalRegistration}
              label={translate('producerRegister.company.holding.form.municipalRegistration')}
              placeholder={translate('producerRegister.company.holding.form.municipalRegistration')}
              error={municipalRegistrationError.hasError}
              errorText={translateErrorMessage(municipalRegistrationError.errorMessage)}
            /> */}
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  phone: text,
                })
              }
              onMask={maskPhone}
              id="phone"
              style={{ marginTop: '20px' }}
              value={phone}
              label={translate('producerRegister.company.holding.form.phone')}
              placeholder={translate('producerRegister.company.holding.form.phone')}
              error={phoneError.hasError}
              errorText={translateErrorMessage(phoneError.errorMessage)}
              onBlur={this.onValidatePhone}
            />
            {cnaeCodes != null &&
              cnaeCodes.length > 0 &&
              cnaeCodes.map((item, index) => (
                <CnaeCodeComponent key={`cnaeCode-${item.id}-${index}`} cnaeCode={item} index={index} onDelete={this.onDeleteCnaeCode} />
              ))}
            {(cnaeCodes == null || cnaeCodes.length == 0) && (
              <div>
                <InlineSelect
                  placeholder={translate('producerRegister.company.holding.form.selectCnae')}
                  error={cnaeCodeError.hasError}
                  errorMessage={translateErrorMessage(cnaeCodeError.errorMessage)}
                  dontAttachSelected
                  options={this.mapCnaeCodesToOptions()}
                  onChange={this.onSelectCnaeCode}
                  startIndex={0}
                />
              </div>
            )}
            <div>
              {/* <AttachmentComponent
                attachment={this.state.businessLicense}
                error={businessLicenseError.hasError}
                errorMessage={translateErrorMessage(businessLicenseError.errorMessage)}
                attachmentTitle={translate('producerRegister.company.holding.form.businessLicense')}
                onFileAttached={this.onAttachBusinessLicense}
              /> */}
            </div>

            <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCnaeCodes,
};

const mapStateToProps = (root: IRootState) => ({
  cnaeCodes: root.cnaeCode.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerUpdateHolding);

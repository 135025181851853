import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { getEntitiesByStateId as getCitiesByState } from 'app/entities/city/city.reducer';
import { getEntities as getStates } from 'app/entities/state/state.reducer';
import {
  validateCPF,
  validateEmail,
  validateGenericField,
  validateObject,
  validatePersonName,
  validatePhoneNumber,
} from 'app/modules/validation/validation-constants';
import { ClassCouncil, IUserGeneric } from 'app/shared/model/user-generic.model';
import { IUser } from 'app/shared/model/user.model';
import { IRootState } from 'app/shared/reducers';
import { maskCPF, maskPhone, unmask } from 'app/shared/util/mask-utils';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import React, { Fragment } from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import InlineSelect from '../inline-select/inline-select';
import './responsible-form-component.scss';
import { Col, Row } from 'reactstrap';
import NextCancelButton from '../next-cancel-button/next-cancel-button';

export interface IResponsibleFormComponentProps extends StateProps, DispatchProps {
  userGeneric: IUserGeneric;
  onNextHandler: (userGeneric: IUserGeneric) => void;
  onBack: () => void;
  isNew?: boolean;
  ignoreClassCouncilAndRegistrationNumber?: boolean;
  validationIgnore?: Map<string, boolean>;
  isResponsibleRequired?: boolean;
  isManager?: boolean;
  isFinalDestination?: boolean;
}

export interface IResponsibleFormComponentState {
  name: string;
  nameTechnical: string;
  email: string;
  occupation: string;
  cpf: string;
  phone: string;
  classCouncil: ClassCouncil;
  registrationNumber: string;
  occupationError: ValidationResult;
  cpfError: ValidationResult;
  phoneError: ValidationResult;
  classCouncilError: ValidationResult;
  registrationNumberError: ValidationResult;
  nameError: ValidationResult;
  nameTechnicalError: ValidationResult;
  emailError: ValidationResult;
}

export class ResponsibleFormComponent extends React.Component<IResponsibleFormComponentProps, IResponsibleFormComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      // name: this.props.userGeneric.user && !this.props.isManager && !this.props.isFinalDestination ? this.props.userGeneric.user.name : "aaaaa",
      // email: this.props.userGeneric.user && !this.props.isManager && !this.props.isFinalDestination ? this.props.userGeneric.user.email : "bbbb",
      name: this.props.userGeneric.user ? this.props.userGeneric.user.name : null,
      email: this.props.userGeneric.user ? this.props.userGeneric.user.email : null,
      nameTechnical: this.props.userGeneric.user ? this.props.userGeneric.user.nameTechnical : null,
      occupation: this.props.userGeneric.user ? this.props.userGeneric.user.occupation : null,
      cpf: this.props.userGeneric.user ? this.props.userGeneric.user.cpf : null,
      phone: this.props.userGeneric.user ? this.props.userGeneric.user.phone : null,
      classCouncil: this.props.userGeneric.classCouncil,
      registrationNumber: this.props.userGeneric.registrationNumber,
      occupationError: ValidationResultInstance,
      cpfError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
      classCouncilError: ValidationResultInstance,
      registrationNumberError: ValidationResultInstance,
      nameError: ValidationResultInstance,
      emailError: ValidationResultInstance,
      nameTechnicalError: ValidationResultInstance
    };
  }

  async componentDidMount() {
    this.props.getStates();
  }

  onValidateAll = () => {
    const { isNew } = this.props;
    let hasError = false;
    if (this.onValidateOccupation()) {
      hasError = true;
    }
    if (this.onValidateCPF()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    if (this.onValidateRegistrationNumber()) {
      hasError = true;
    }
    if (this.onValidateClassCouncil()) {
      hasError = true;
    }
    if (isNew && this.onValidateName()) {
      hasError = true;
    }
    if (isNew && this.onValidateEmail()) {
      hasError = true;
    }
    if (isNew && this.onValidateTechnicalName()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateTechnicalName = () => {
    const { nameTechnical } = this.state;
    const validate = validatePersonName(nameTechnical);
    this.setState({
      nameTechnicalError: validate,
    });
    return validate.hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onValidateOccupation = () => {
    if (this.props.isResponsibleRequired) {
      return false
    }
    const { occupation } = this.state;
    const validate = validateGenericField(occupation);
    this.setState({
      occupationError: validate,
    });
    return validate.hasError;
  };

  onValidateCPF = () => {
    if (this.props.isResponsibleRequired) {
      return false
    }
    const { cpf } = this.state;
    const validate = validateCPF(cpf);
    this.setState({
      cpfError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    if (this.props.isResponsibleRequired) {
      return false
    }
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  onValidateRegistrationNumber = () => {
    const { validationIgnore } = this.props;
    if (validationIgnore && validationIgnore.get('registrationNumber')) {
      return false;
    }
    const { registrationNumber } = this.state;
    const validate = validateGenericField(registrationNumber);
    this.setState({
      registrationNumberError: validate,
    });
    return validate.hasError;
  };

  onValidateClassCouncil = () => {
    const { validationIgnore } = this.props;
    if (validationIgnore && validationIgnore.get('classCouncil')) {
      return false;
    }
    const { classCouncil } = this.state;
    const validate = validateObject(classCouncil);
    this.setState({
      classCouncilError: validate,
    });
    return validate.hasError;
  };

  formatClassCouncilLabel = (classCouncil) => {
    return classCouncil.replace(/_/g, ' ');
  };

  mapClassCouncilToOptions = () => {
    const optionsList = [];
    Object.keys(ClassCouncil).forEach((item, index) => {
      optionsList.push({ label: this.formatClassCouncilLabel(item), value: item });
    });
    return optionsList;
  };

  onSelectClassCouncil = (item: any) => {
    this.setState(
      {
        classCouncil: item,
      },
      () => this.onValidateClassCouncil()
    );
  };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const userGeneric = { ...this.props.userGeneric };
    const { name, nameTechnical, email, phone, cpf, occupation, classCouncil, registrationNumber } = this.state;
    const user: IUser = {
      ...userGeneric.user,
      name,
      nameTechnical,
      email,
      phone: unmask(phone),
      cpf: unmask(cpf),
      occupation,
    };
    const userGenericUpdated: IUserGeneric = {
      ...userGeneric,
      classCouncil,
      registrationNumber,
      user,
    };

    this.props.onNextHandler(userGenericUpdated);
  };

  mapSelectedClassCouncilToSelect = () => {
    const { classCouncil } = this.state;

    return classCouncil ? { label: `${classCouncil}`, value: classCouncil } : null;
  };

  renderReadOnlyNameEmailField = () => (
    <div>
      <CustomTextField
        onChange={(text: string) =>
          this.setState({
            name: text,
          })
        }
        id="name"
        style={{ marginTop: '20px' }}
        value={this.state.name}
        label={translate('responsible.form.name')}
        placeholder={translate('responsible.form.name')}
        readonly
      />
      <CustomTextField
        onChange={(text: string) =>
          this.setState({
            email: text,
          })
        }
        id="email"
        style={{ marginTop: '20px' }}
        value={this.state.email}
        label={translate('responsible.form.email')}
        placeholder={translate('responsible.form.email')}
        readonly
      />
    </div>
  );

  renderEditTechnicalNameField = () => {
    const { nameTechnical, nameTechnicalError } = this.state;
    return (
      <div>
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              nameTechnical: text,
            })
          }
          id="name-technical"
          style={{ marginTop: '20px' }}
          value={nameTechnical}
          label={translate('responsible.form.nameTechnical')}
          placeholder={translate('responsible.form.nameTechnical')}
          error={nameTechnicalError.hasError}
          errorText={translateErrorMessage(nameTechnicalError.errorMessage)}
          onBlur={this.onValidateTechnicalName}
        />
      </div>
    );
  };

  renderEditNameEmailField = () => {
    const { name, nameError, email, emailError } = this.state;
    return (
      <div>
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              name: text,
            })
          }
          id="name"
          style={{ marginTop: '20px' }}
          value={name}
          label={translate('responsible.form.name')}
          placeholder={translate('responsible.form.name')}
          error={nameError.hasError}
          errorText={translateErrorMessage(nameError.errorMessage)}
          onBlur={this.onValidateName}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              email: text,
            })
          }
          id="email"
          style={{ marginTop: '20px' }}
          value={email}
          label={translate('responsible.form.email')}
          placeholder={translate('responsible.form.email')}
          error={emailError.hasError}
          errorText={translateErrorMessage(emailError.errorMessage)}
          onBlur={this.onValidateEmail}
        />
      </div>
    );
  };

  renderEditTechNameEmailField = () => {
    const { name, nameError, email, emailError } = this.state;
    return (
      <div>
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              name: text,
            })
          }
          id="name"
          style={{ marginTop: '20px' }}
          value={name}
          label={translate('responsible.form.name')}
          placeholder={translate('responsible.form.name')}
          error={nameError.hasError}
          errorText={translateErrorMessage(nameError.errorMessage)}
          onBlur={this.onValidateName}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              email: text,
            })
          }
          id="email"
          style={{ marginTop: '20px' }}
          value={email}
          label={translate('responsible.form.email')}
          placeholder={translate('responsible.form.email')}
          error={emailError.hasError}
          errorText={translateErrorMessage(emailError.errorMessage)}
          onBlur={this.onValidateEmail}
        />
      </div>
    );
  };

  render() {
    const {
      name,
      email,
      nameTechnical,
      occupation,
      registrationNumber,
      classCouncil,
      cpf,
      phone,
      occupationError,
      registrationNumberError,
      cpfError,
      phoneError,
      classCouncilError,
    } = this.state;
    const { isNew, ignoreClassCouncilAndRegistrationNumber, isManager, isFinalDestination } = this.props;

    return (
      <div className="responsible__items">
        <h1>{(isManager || isFinalDestination) ? translate('responsible.techTitle') : translate('responsible.title')}</h1>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            {this.renderReadOnlyNameEmailField()}
            {/*isNew ? this.renderEditNameEmailField() : (
                (isManager || isFinalDestination) ? (
                  this.renderEditTechNameEmailField()
                ) : (
                  this.renderReadOnlyNameEmailField()
                )
              )*/}
            {this.renderEditTechnicalNameField()}
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  occupation: text,
                })
              }
              id="occupation"
              style={{ marginTop: '20px' }}
              value={occupation}
              label={translate('responsible.form.occupation')}
              placeholder={translate('responsible.form.occupation')}
              error={occupationError.hasError}
              errorText={translateErrorMessage(occupationError.errorMessage)}
              onBlur={this.onValidateOccupation}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  cpf: text,
                })
              }
              id="cpf"
              style={{ marginTop: '20px' }}
              value={cpf}
              onMask={maskCPF}
              label={translate('responsible.form.cpf')}
              placeholder={translate('responsible.form.cpf')}
              error={cpfError.hasError}
              errorText={translateErrorMessage(cpfError.errorMessage)}
              onBlur={this.onValidateCPF}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  phone: text,
                })
              }
              id="phone"
              style={{ marginTop: '20px' }}
              value={phone}
              onMask={maskPhone}
              label={translate('responsible.form.phone')}
              placeholder={translate('responsible.form.phone')}
              error={phoneError.hasError}
              errorText={translateErrorMessage(phoneError.errorMessage)}
              onBlur={this.onValidatePhone}
            />
            {!ignoreClassCouncilAndRegistrationNumber && (
              <Fragment>
                <InlineSelect
                  placeholder={translate('responsible.form.classCouncil')}
                  error={classCouncilError.hasError}
                  errorMessage={translateErrorMessage(classCouncilError.errorMessage)}
                  options={this.mapClassCouncilToOptions()}
                  onChange={this.onSelectClassCouncil}
                  selectedOption={this.mapSelectedClassCouncilToSelect()}
                  startIndex={0}
                />
                <CustomTextField
                  onChange={(text: string) =>
                    this.setState({
                      registrationNumber: text,
                    })
                  }
                  id="registrationNumber"
                  style={{ marginTop: '20px' }}
                  value={registrationNumber}
                  label={translate('responsible.form.registrationNumber')}
                  placeholder={translate('responsible.form.registrationNumber')}
                  error={registrationNumberError.hasError}
                  errorText={translateErrorMessage(registrationNumberError.errorMessage)}
                  onBlur={this.onValidateRegistrationNumber}
                />
              </Fragment>
            )}
          </Col>
        </Row>

        <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCitiesByState,
  getStates,
};

const mapStateToProps = (root: IRootState) => ({
  states: root.state.entities,
  cities: root.city.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleFormComponent);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../content/images/icons/Path.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".simple-file__items--text {\n  font-size: 16px;\n  color: #30ab64;\n}\n\n.simple-file__items--img {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 6px;\n}", ""]);
// Exports
module.exports = exports;

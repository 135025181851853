import { IUser } from './user.model';
import { Transshipment } from './transshipment.model';

export interface UserTransshipment {
  id?: number;
  user?: IUser;
  responsible?: boolean;
  transshipment?: Transshipment;
}

export const defaultValue: UserTransshipment = {
  responsible: false,
};

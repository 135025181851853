import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';

import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getDocuments } from 'app/entities/document/document.reducer';
import { getEntities as getProducers } from 'app/entities/producer/producer.reducer';
import { createEntity, getEntity, reset, updateEntity } from './authorization.reducer';


export interface IAuthorizationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export interface IAuthorizationUpdateState {
  isNew: boolean;
  authorizationDocumentId: string;
  producerId: string;
}

export class AuthorizationUpdate extends React.Component<IAuthorizationUpdateProps, IAuthorizationUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      authorizationDocumentId: '0',
      producerId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getDocuments();
    this.props.getProducers();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { authorizationEntity } = this.props;
      const entity = {
        ...authorizationEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/authorization');
  };

  render() {
    const { authorizationEntity, documents, producers, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.authorization.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.authorization.home.createOrEditLabel">Create or edit a Authorization</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : authorizationEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="authorization-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="authorization-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="amountLabel" for="authorization-amount">
                    <Translate contentKey="resitrackApp.authorization.amount">Amount</Translate>
                    <Translate contentKey="resitrackApp.authorization.amountReceipt">amountReceipt</Translate>
                  </Label>
                  <AvField
                    id="authorization-amount"
                    type="text"
                    name="amount"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="expirationDateLabel" for="authorization-expirationDate">
                    <Translate contentKey="resitrackApp.authorization.expirationDate">Expiration Date</Translate>
                  </Label>
                  <AvField
                    id="authorization-expirationDate"
                    type="date"
                    className="form-control"
                    name="expirationDate"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="measurementTypeLabel" for="authorization-measurementType">
                    <Translate contentKey="resitrackApp.authorization.measurementType">Measurement Type</Translate>
                  </Label>
                  <AvInput
                    id="authorization-measurementType"
                    type="select"
                    className="form-control"
                    name="measurementType"
                    value={(!isNew && authorizationEntity.measurementType) || 'KG'}
                  >
                    <option value="KG">{translate('resitrackApp.MeasurementType.KG')}</option>
                    <option value="TONS">{translate('resitrackApp.MeasurementType.TONS')}</option>
                    <option value="GRAMS">{translate('resitrackApp.MeasurementType.GRAMS')}</option>
                    <option value="CUBIC_METERS">{translate('resitrackApp.MeasurementType.CUBIC_METERS')}</option>
                    <option value="UNITS">{translate('resitrackApp.MeasurementType.UNITS')}</option>
                    <option value="LITERS">{translate('resitrackApp.MeasurementType.LITERS')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="residueTypeLabel" for="authorization-residueType">
                    <Translate contentKey="resitrackApp.authorization.residueType">Residue Type</Translate>
                  </Label>
                  <AvInput
                    id="authorization-residueType"
                    type="select"
                    className="form-control"
                    name="residueType"
                    value={(!isNew && authorizationEntity.residueType) || 'TOXIC'}
                  >
                    <option value="TOXIC">{translate('resitrackApp.ResidueType.TOXIC')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="authorization-authorizationDocument">
                    <Translate contentKey="resitrackApp.authorization.authorizationDocument">Authorization Document</Translate>
                  </Label>
                  <AvInput id="authorization-authorizationDocument" type="select" className="form-control" name="authorizationDocument.id">
                    <option value="" key="0" />
                    {documents
                      ? documents.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="authorization-producer">
                    <Translate contentKey="resitrackApp.authorization.producer">Producer</Translate>
                  </Label>
                  <AvInput id="authorization-producer" type="select" className="form-control" name="producer.id">
                    <option value="" key="0" />
                    {producers
                      ? producers.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/authorization" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  documents: storeState.document.entities,
  producers: storeState.producer.entities,
  authorizationEntity: storeState.authorization.entity,
  loading: storeState.authorization.loading,
  updating: storeState.authorization.updating,
  updateSuccess: storeState.authorization.updateSuccess,
});

const mapDispatchToProps = {
  getDocuments,
  getProducers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationUpdate);

import AlertModal from 'app/components/alert-modal/alert-modal';
import AttachmentComponent from 'app/components/attachment-component/attachmentComponent';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import InlineSelect from 'app/components/inline-select/inline-select';
import Loading from 'app/components/loading/loading';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import { ALERT_ICONS, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { validateGenericField, validateObject } from 'app/modules/validation/validation-constants';
import { IAuthorization } from 'app/shared/model/Authorization.model';
import { MeasurementType, ResidueType } from 'app/shared/model/authorization.model';
import { IDocument } from 'app/shared/model/document.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IRootState } from 'app/shared/reducers';
import {
  checkStatusOnGoing,
  translateErrorMessage,
  ValidationResult,
  ValidationResultInstance
} from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import {
  createEntity as createAuthorization,
  getEntity as getAuthorization,
  reset,
  updateEntity as updateAuthorization,
} from '../generic-authorization.reducer';
import './authorization-edit.scss';
import { maskNumber } from 'app/shared/util/mask-utils';
import { ISourceResidue } from 'app/shared/model/source-residue.model';
import { getSourceResidues, reset as resetResidues } from 'app/entities/residue/residue-entities.reducer';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';

export interface IAuthorizationEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IAuthorizationEditState {
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
  isNew: boolean;
  authorizationDocument: IDocument;
  sourceResidue: ISourceResidue;
  amount: string;
  amountReceipt: string;
  measurementType: MeasurementType;
  expirationDate: string;
  authorizationDocumentError: ValidationResult;
  sourceResidueError: ValidationResult;
  amountError: ValidationResult;
  amountReceiptError: ValidationResult;
  expirationDateError: ValidationResult;
  measurementTypeError: ValidationResult;
}

export class AuthorizationEdit extends React.Component<IAuthorizationEditProps, IAuthorizationEditState> {
  constructor(props: Readonly<IAuthorizationEditProps>) {
    super(props);
    this.state = {
      showErrorModal: false,
      showResponsabilityModal: false,
      isNew: null,
      authorizationDocument: null,
      sourceResidue: null,
      amount: null,
      amountReceipt: null,
      measurementType: null,
      expirationDate: null,
      authorizationDocumentError: ValidationResultInstance,
      sourceResidueError: ValidationResultInstance,
      amountError: ValidationResultInstance,
      amountReceiptError: ValidationResultInstance,
      expirationDateError: ValidationResultInstance,
      measurementTypeError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    this.props.reset();
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.getSourceResidues();
    if (id) {
      this.props.getAuthorization(id);
    } else {
      this.setState({
        isNew: true,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.authorization != null &&
      newProps.fetchAuthorizationStatus != this.props.fetchAuthorizationStatus &&
      newProps.fetchAuthorizationStatus === HttpRequestStatus.SUCCESS
    ) {
      this.mapAuthorizationToField(newProps.authorization);
    }

    if (newProps.createAuthorizationStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    }

    if (newProps.updateAuthorizationStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessUpdateAlertPage();
      this.props.reset();
    }

    if (newProps.createAuthorizationStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }

    if (newProps.updateAuthorizationStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.resetResidues();
  }

  mapAuthorizationToField = (authorization: IAuthorization) => {
    this.setState({
      authorizationDocument: authorization.authorizationDocument,
      sourceResidue: authorization.sourceResidue,
      measurementType: authorization.measurementType,
      amount: authorization.amount.toString(),
      amountReceipt: authorization.amountReceipt.toString(),
      expirationDate: authorization.expirationDate,
    });
  };

  toggleResponsabilityModal = () => {
    this.setState({
      showResponsabilityModal: !this.state.showResponsabilityModal,
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateAuthorizationDocument()) {
      hasError = true;
    }
    if (this.onValidateExpirationDate()) {
      hasError = true;
    }
    if (this.onValidateAmount()) {
      hasError = true;
    }
    if (this.onValidateAmountReceipt()) {
      hasError = true;
    }
    if (this.onValidateMeasurementType()) {
      hasError = true;
    }
    if (this.onValidateSourceResidue()) {
      hasError = true;
    }

    return hasError;
  };

  onValidateAuthorizationDocument = () => {
    const { authorizationDocument } = this.state;
    const validate = validateObject(authorizationDocument);
    this.setState({
      authorizationDocumentError: validate,
    });
    return validate.hasError;
  };

  onValidateSourceResidue = () => {
    const { sourceResidue } = this.state;
    const validate = validateObject(sourceResidue);
    this.setState({
      sourceResidueError: validate,
    });
    return validate.hasError;
  };

  onValidateMeasurementType = () => {
    const { measurementType } = this.state;
    const validate = validateObject(measurementType);
    this.setState({
      measurementTypeError: validate,
    });
    return validate.hasError;
  };

  onValidateAmount = () => {
    const { amount } = this.state;
    const validate = validateGenericField(amount);
    this.setState({
      amountError: validate,
    });
    return validate.hasError;
  };

  onValidateAmountReceipt = () => {
    const { amountReceipt } = this.state;
    const validate = validateGenericField(amountReceipt);
    this.setState({
      amountReceiptError: validate,
    });
    return validate.hasError;
  };

  onValidateExpirationDate = () => {
    const { expirationDate } = this.state;
    const validate = validateGenericField(expirationDate);
    this.setState({
      expirationDateError: validate,
    });
    return validate.hasError;
  };

  mapResidueTypeToOptions = () => {
    const optionsList = [];
    Object.keys(ResidueType).forEach((item, index) => {
      optionsList.push({ label: translate(`enums.residueType.${item}`), value: item });
    });
    return optionsList;
  };

  mapSelectedMeasurementTypeToSelect = () => {
    const { measurementType } = this.state;

    return measurementType ? { label: translate(`enums.measurementType.${measurementType}`), value: measurementType } : null;
  };

  mapMeasurementTypeToOptions = () => {
    const optionsList = [];
    Object.keys(MeasurementType).forEach((item, index) => {
      optionsList.push({ label: translate(`enums.measurementType.${item}`), value: item });
    });
    return optionsList;
  };

  onSelectMeasurementType = (item: any) => {
    this.setState(
      {
        measurementType: item,
      },
      () => this.onValidateMeasurementType()
    );
  };

  onSaveHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const { authorization } = this.props;
    const { authorizationDocument, sourceResidue, measurementType, expirationDate, amount, amountReceipt } = this.state;

    const Authorization: IAuthorization = {
      ...authorization,
      authorizationDocument,
      sourceResidue,
      measurementType,
      expirationDate,
      amount: Number(amount),
      amountReceipt: Number(amountReceipt),
    };

    const { isNew } = this.state;
    if (isNew) {
      this.props.createAuthorization(Authorization);
    } else {
      this.props.updateAuthorization(Authorization);
    }
  };

  onBackHandler = () => {
    const { isNew } = this.state;
    if (isNew) {
      this.props.history.replace(`${this.props.match.url.replace('/new', '')}`);
    } else {
      this.props.history.replace(`${this.props.match.url.replace('/edit', '')}`);
    }
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.authorizationRegister',
        buttonUrl: this.props.match.url.replace('/new', ''),
      },
    });
  };

  goToSuccessUpdateAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.authorizationUpdate',
        buttonUrl: this.props.match.url.replace('/edit', ''),
      },
    });
  };

  onStartSaving = () => {
    if (this.onValidateAll()) {
      return;
    }

    this.toggleResponsabilityModal();
  };

  onAttachAuthorization = (document: IDocument) => {
    this.setState(
      {
        authorizationDocument: document,
      },
      () => this.onValidateAuthorizationDocument()
    );
  };

  mapSourceResiduesToSearch = () => {
    const { sourceResiduesByEntity } = this.props;
    const objects = sourceResiduesByEntity.map(sourceResidue => {
      return { name: sourceResidue.specificResidue.name, id: sourceResidue.id };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  onClickSourceResidue = (item: any) => {
    const { sourceResiduesByEntity } = this.props;
    this.setState(
      {
        sourceResidue: sourceResiduesByEntity.filter(it => it.id === item.id)[0],
      },
      () => {
        this.onValidateSourceResidue();
      }
    );
  };

  mapSelectedSourceResidueToSelect = () => {
    const { sourceResidue } = this.state;

    return sourceResidue
      ? {
        name: sourceResidue.specificResidueDescription,
        id: sourceResidue.id,
      }
      : null;
  };

  renderForm = () => {
    const {
      authorizationDocument,
      authorizationDocumentError,
      sourceResidueError,
      measurementTypeError,
      amount,
      amountError,
      amountReceipt,
      amountReceiptError,
      expirationDate,
      expirationDateError,
    } = this.state;

    return (
      <Row>
        <Col md={{ size: 4, offset: 4 }}>
          <AttachmentComponent
            attachment={authorizationDocument}
            error={authorizationDocumentError.hasError}
            errorMessage={translateErrorMessage(authorizationDocumentError.errorMessage)}
            attachmentTitle={translate('authorization.form.authorizationDocument')}
            onFileAttached={this.onAttachAuthorization}
          />
          <div style={{ marginTop: '20px' }}>
            <DropdownSearch
              data={this.mapSourceResiduesToSearch()}
              getData={() => { }}
              onClickItem={this.onClickSourceResidue}
              title={translate('authorization.form.sourceResidue')}
              style={{ backgroundColor: '#f6f6f6' }}
              notAlphabeticalOrder
              showInsiderSelect
              filterLocalData
              error={sourceResidueError.hasError}
              selectedData={this.mapSelectedSourceResidueToSelect()}
              errorMessage={translateErrorMessage(sourceResidueError.errorMessage)}
            />
          </div>
          <Row>
            <Col>
              <CustomTextField
                onChange={(text: string) =>
                  this.setState({
                    amount: text,
                  })
                }
                id="amount"
                style={{ marginTop: '20px' }}
                value={amount}
                onMask={maskNumber}
                label={translate('authorization.form.amount')}
                placeholder={translate('authorization.form.amount')}
                error={amountError.hasError}
                errorText={translateErrorMessage(amountError.errorMessage)}
                onBlur={this.onValidateAmount}
              />
              <CustomTextField
                onChange={(text: string) =>
                  this.setState({
                    amountReceipt: text,
                  })
                }
                id="amountReceipt"
                style={{ marginTop: '20px', marginBottom: '20px' }}
                value={amountReceipt}
                onMask={maskNumber}
                label={translate('authorization.form.amountReceipt')}
                placeholder={translate('authorization.form.amountReceipt')}
                error={amountReceiptError.hasError}
                errorText={translateErrorMessage(amountReceiptError.errorMessage)}
                onBlur={this.onValidateAmountReceipt}
              />
            </Col>
            <Col>
              <InlineSelect
                placeholder={translate('authorization.form.measurementType')}
                error={measurementTypeError.hasError}
                errorMessage={translateErrorMessage(measurementTypeError.errorMessage)}
                options={this.mapMeasurementTypeToOptions()}
                onChange={this.onSelectMeasurementType}
                startIndex={0}
                selectedOption={this.mapSelectedMeasurementTypeToSelect()}
              />
            </Col>
          </Row>

          <DatePickerComponent
            dateFormat={APP_LOCAL_DATE_FORMAT}
            onDateChange={value => {
              this.setState(
                {
                  expirationDate: value,
                },
                () => this.onValidateExpirationDate()
              );
            }}
            dateSelected={expirationDate}
            placeholder={translate('authorization.form.expirationDate')}
            error={expirationDateError.hasError}
            errorMessage={translateErrorMessage(expirationDateError.errorMessage)}
          />

          <NextCancelButton
            onCancelClick={this.onBackHandler}
            onNextClick={this.onStartSaving}
            nextButtonName={translate('entity.action.save')}
            noArrow
          />
        </Col>
      </Row>
    );
  };

  render() {
    const {
      fetchAuthorizationStatus,
      createAuthorizationStatus,
      updateAuthorizationStatus,
      authorization,
      fetchSourceResiduesByEntityStatus,
    } = this.props;
    const { isNew } = this.state;
    if (
      checkStatusOnGoing([
        fetchAuthorizationStatus,
        createAuthorizationStatus,
        updateAuthorizationStatus,
        fetchSourceResiduesByEntityStatus,
      ])
    ) {
      return <Loading />;
    }
    return (
      <div>
        <div style={{ paddingTop: '70px' }}>
          <BackHeaderComponent hasButton={false} onBack={this.onBackHandler} />
          <h1>{isNew ? translate('authorization.form.title') : translate('authorization.form.editTitle')}</h1>
          {isNew && this.renderForm()}
          {!isNew && authorization.id && this.renderForm()}

          <AlertModal
            showModal={this.state.showResponsabilityModal}
            buttonOneActionMessage={'entity.action.cancel'}
            buttonTwoActionMessage={'entity.action.agree'}
            hasTwoButtons
            buttonTwoAction={this.onSaveHandler}
            buttonOneAction={this.toggleResponsabilityModal}
            statusImage="paper-question-mark.svg"
            alertMessage="alert.responsibilityTerms"
          />

          <AlertModal
            showModal={this.state.showErrorModal}
            buttonTwoActionMessage={'entity.action.ok'}
            buttonTwoAction={this.toggleErrorModal}
            statusImage="bag-error.svg"
            alertMessage={this.state.isNew ? 'errorMessages.authorizationRegister' : 'errorMessages.authorizationUpdate'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  authorization: root.genericAuthorization.authorization,
  fetchAuthorizationStatus: root.genericAuthorization.fetchAuthorizationStatus,
  createAuthorizationStatus: root.genericAuthorization.createAuthorizationStatus,
  updateAuthorizationStatus: root.genericAuthorization.updateAuthorizationStatus,
  fetchSourceResiduesByEntityStatus: root.residuesEntities.fetchSourceResiduesByEntityStatus,
  sourceResiduesByEntity: root.residuesEntities.sourceResiduesByEntity,
});

const mapDispatchToProps = {
  getSourceResidues,
  getAuthorization,
  createAuthorization,
  updateAuthorization,
  reset,
  resetResidues,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationEdit);

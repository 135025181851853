import {
    createMuiTheme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    ThemeProvider,
} from '@material-ui/core';
import { ptPT } from '@material-ui/core/locale';
import * as React from 'react';
import ClickableIcon from '../clickable-icon/clickable-icon';
import './simple-ordered-table.scss';
import { Pageable } from 'app/shared/model/pageable';
import EmptyList from '../empty-list/empty-list';

interface Props {
    emptyTableMessage?: string;
    columNameKeys: ColumnSort[];
    rows?: (string | JSX.Element)[][];
    emptyContent?: JSX.Element;
    textOverflow?: boolean;
    hideLongText?: boolean;
    onSort?: (code: string) => void;
    onChangePage: (newPage: number) => void;
    onClick?: (index: number) => void;
    hidePagination?: boolean;
    page: Pageable;
}

interface State {
    columNameKeys: ColumnSort[];
    emptyTable: boolean;
}

export interface ColumnSort {
    name?: string;
    sortCode?: string;
    type?: 'asc' | 'desc';
    sortActivated?: boolean;
    sortDisabled?: boolean;
    transparent?: boolean;
}

export class SimpleOrderedTable extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            columNameKeys: props.columNameKeys,
            emptyTable: props.rows ? props.rows.length === 0 : true,
        };
    }

    private renderCells = (row: (string | JSX.Element)[], rowKey: string): JSX.Element[] => {
        const result: JSX.Element[] = [];
        row.map((item: string | JSX.Element, index: number) => {
            const style =
                index === 0
                    ? 'generic-table--rows generic-table--radius-first'
                    : index === row.length - 1
                        ? 'generic-table--rows  generic-table--radius-last generic-table--last-cel'
                        : 'generic-table--rows';
            result.push(
                <TableCell align="left" key={`${rowKey}[cell-${index}]`}>
                    <span className="generic-table--rows generic-table--hidden-content">
                        {item}
                    </span>
                    <div data-table-cell-name={index} className={style} style={{ position: 'absolute' }}>
                        {typeof item == 'string' && this.props.hideLongText && item.length > 45 ?
                            <Tooltip title={item ?? ''} placement={'top'} arrow>
                                <span className="generic-table--cell">
                                    {`${item.substr(0, 45)}${item.length > 45 ? '\u2026' : ''}`}
                                </span>
                            </Tooltip>
                            :
                            <span className="generic-table--cell">
                                {item}
                            </span>
                        }
                    </div>
                </TableCell>
            );
        });

        return result;
    };

    componentWillReceiveProps(newProps) {
        if (this.props.columNameKeys == null) {
            this.setState({
                columNameKeys: newProps.columNameKeys,
            });
        }
        if (newProps.rows != this.props.rows) {
            this.setState({
                emptyTable: newProps.rows ? newProps.rows.length === 0 : true,
            });
        }
    }

    private renderEmptyContentMessage = () => {
        const { emptyContent } = this.props;
        return emptyContent ? { emptyContent } : <EmptyList />;
    };

    private onSort = (column: ColumnSort) => {
        const columNameKeys = [...this.state.columNameKeys];
        const type = column.sortActivated ? (column.type === 'asc' ? 'desc' : 'asc') : 'asc';
        const code = `${column.sortCode ?? ''},${type}`;

        columNameKeys
            .filter(it => !it.sortDisabled)
            .forEach(it => {
                if (column.name === it.name) {
                    it.sortActivated = true;
                    it.type = type;
                } else if (column.name !== it.name && it.sortActivated) {
                    it.sortActivated = false;
                }
            });
        if (this.props.onSort) {
            this.setState({
                columNameKeys,
            });
            this.props.onSort(code);
        }
    };

    private renderSortIcon = (column: ColumnSort) => {
        const iconSortActivated = 'content/images/icons/flechas.svg';
        return column.sortActivated ? (
            <span>
                <ClickableIcon style={'sort-icon'} iconPath={iconSortActivated} onClick={() => this.onSort(column)} />
            </span>
        ) : (
            <span>
                <ClickableIcon style={'sort-default-icon'} iconPath={'content/images/icons/flechas.svg'} onClick={() => this.onSort(column)} />
            </span>
        );
    };

    private renderPagination = () => {
        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => `Exibindo ${from}-${to} de ${count !== -1 ? count : 0} itens`}
                        count={this.props.page.totalElements}
                        rowsPerPage={this.props.page.size ?? 20}
                        page={this.props.page.page ?? 0}
                        onPageChange={(event, page) => this.props.onChangePage(page)}
                    />
                </TableRow>
            </TableFooter>
        );
    };

    handleClick = (index: number) => {
        const { onClick } = this.props;
        if (onClick) {
            onClick(index);
        }
    };

    render() {
        return (
            <ThemeProvider theme={createMuiTheme(ptPT)}>
                <div className="generic-table__items">
                    <div className={'root'}>
                        <Paper className={'paper'}>
                            <TableContainer>
                                <Table className={'table'} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                                    <TableHead>
                                        {!this.state.emptyTable ? (
                                            <TableRow>
                                                {this.state.columNameKeys != null &&
                                                    this.state.columNameKeys.map((column: ColumnSort, index: number) => (
                                                        <TableCell align={'left'} key={`column-header-${index}`}>
                                                            <div className={`generic-table--head ${column.transparent ? 'opacity' : ''}`}>
                                                                {column.sortDisabled ? (
                                                                    column.name ?? ''
                                                                ) : (
                                                                    <div>
                                                                        <span>{column.name ? column.name.toUpperCase() : ''}</span>
                                                                        {!column.sortDisabled && this.renderSortIcon(column)}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <TableCell>
                                                    <div className="generic-table--head" />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableHead>
                                    <TableBody>
                                        {this.props.rows != null && this.props.rows.length !== 0
                                            ? this.props.rows.map((row: (string | JSX.Element)[], index: number) => (
                                                <TableRow onClick={() => this.handleClick(index)} style={{ cursor: 'pointer', position: 'relative' }} key={`row-${index}`}>
                                                    {this.renderCells(row, `row-${index}`)}
                                                </TableRow>
                                            ))
                                            : this.renderEmptyContentMessage()}
                                    </TableBody>
                                    {!this.props.hidePagination && this.renderPagination()}
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

export default SimpleOrderedTable;

import AlertModal from 'app/components/alert-modal/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import Loading from 'app/components/loading/loading';
import { EnvironmentalLicenseForm } from 'app/components/environmental-license-form/form/environmental-license-form';
import { ALERT_ICONS } from 'app/config/constants';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IEnvironmentalLicense } from 'app/shared/model/environmental-license.model';
import { IRootState } from 'app/shared/reducers';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
    createEnvironmentalLicense,
    getEnvironmentalLicenseDetail,
    reset,
    updateEnvironmentalLicense
} from 'app/entities/licenses/licenses-reducer';
import './environmental-license-edit.scss';
import { Col, Row } from 'reactstrap';

export interface IEnvironmentalLicenseEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IEnvironmentalLicenseEditState {
  environmentalLicense: IEnvironmentalLicense;
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
  isNew: boolean;
}

export class EnvironmentalLicenseEdit extends React.Component<IEnvironmentalLicenseEditProps, IEnvironmentalLicenseEditState> {
  constructor(props: Readonly<IEnvironmentalLicenseEditProps>) {
    super(props);
    this.state = {
      environmentalLicense: null,
      showErrorModal: false,
      showResponsabilityModal: false,
      isNew: null,
    };
  }

  async componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getEnvironmentalLicenseDetail(id);
    } else {
      this.setState({
        isNew: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.environmentalLicenseDetail != null &&
      newProps.getEnvironmentalLicenseDetailStatus != this.props.getEnvironmentalLicenseDetailStatus &&
      newProps.getEnvironmentalLicenseDetailStatus === HttpRequestStatus.SUCCESS
    ) {
      this.setState({
        environmentalLicense: newProps.environmentalLicenseDetail,
      });
    }

    if (newProps.createEnvironmentalLicenseStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    }

    if (newProps.updateEnvironmentalLicenseStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessUpdateAlertPage();
      this.props.reset();
    }

    if (newProps.createEnvironmentalLicenseStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }

    if (newProps.updateEnvironmentalLicenseStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  toggleResponsabilityModal = () => {
    this.setState({
      showResponsabilityModal: !this.state.showResponsabilityModal,
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onSaveHandler = () => {
    const { isNew, environmentalLicense } = this.state;
    if (isNew) {
      this.props.createEnvironmentalLicense(environmentalLicense);
    } else {
      this.props.updateEnvironmentalLicense(environmentalLicense);
    }
  };

  onBackHandler = () => {
    this.props.history.replace('/final-destination/licenses');
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.licenseRegister',
        buttonUrl: '/final-destination/licenses',
      },
    });
  };

  goToSuccessUpdateAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.licenseUpdate',
        buttonUrl: '/final-destination/licenses',
      },
    });
  };

  onStartSaving = (environmentalLicense: IEnvironmentalLicense) => {
    this.setState(
      {
        environmentalLicense,
      },
      () => this.toggleResponsabilityModal()
    );
  };

  renderForm = () => {
    const { environmentalLicense, isNew } = this.state;

    return (
      <Row>
        <Col style={{ margin: 'auto' }} md="8">
          <EnvironmentalLicenseForm
            environmentalLicense={environmentalLicense ? environmentalLicense : {}}
            defaultForm
            onCreate={this.onStartSaving}
            onClose={this.onBackHandler}
            title={isNew ? translate('licenses.formTitleRegister') : translate('licenses.formTitleUpdate')}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const {
      getEnvironmentalLicenseDetailStatus,
      createEnvironmentalLicenseStatus,
      updateEnvironmentalLicenseStatus,
      environmentalLicenseDetail,
    } = this.props;
    const { isNew } = this.state;
    if (checkStatusOnGoing([getEnvironmentalLicenseDetailStatus, createEnvironmentalLicenseStatus, updateEnvironmentalLicenseStatus])) {
      return <Loading />;
    }
    return (
      <div>
        <div style={{ paddingTop: '70px' }}>
          <BackHeaderComponent hasButton={false} onBack={this.onBackHandler} />
          {isNew && this.renderForm()}
          {!isNew && environmentalLicenseDetail.id && this.renderForm()}

          <AlertModal
            showModal={this.state.showResponsabilityModal}
            buttonOneActionMessage={'entity.action.cancel'}
            buttonTwoActionMessage={'entity.action.agree'}
            hasTwoButtons
            buttonTwoAction={this.onSaveHandler}
            buttonOneAction={this.toggleResponsabilityModal}
            statusImage="paper-question-mark.svg"
            alertMessage="alert.responsibilityTerms"
          />

          <AlertModal
            showModal={this.state.showErrorModal}
            buttonTwoActionMessage={'entity.action.ok'}
            buttonTwoAction={this.toggleErrorModal}
            statusImage="bag-error.svg"
            alertMessage={this.state.isNew ? 'errorMessages.licenseRegister' : 'errorMessages.licenseUpdate'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  environmentalLicenseDetail: root.licenses.environmentalLicenseDetail,
  getEnvironmentalLicenseDetailStatus: root.licenses.getEnvironmentalLicenseDetailStatus,
  createEnvironmentalLicenseStatus: root.licenses.createEnvironmentalLicenseStatus,
  updateEnvironmentalLicenseStatus: root.licenses.updateEnvironmentalLicenseStatus,
});

const mapDispatchToProps = {
  getEnvironmentalLicenseDetail,
  createEnvironmentalLicense,
  updateEnvironmentalLicense,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentalLicenseEdit);

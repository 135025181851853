import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthorizationDetails from './detail/authorization-details';
import AuthorizationEdit from './edit/authorization-edit';
import Authorization from './generic-authorization';

const Routes = ({ match }) => (
  <>
    <Switch>
      {/* <Route exact path={`${match.url}`} component={TransporterLicensesList} /> */}
      <Route exact path={`${match.url}`} component={Authorization} />
      <Route path={`${match.url}/edit`} component={AuthorizationEdit} />
      <Route path={`${match.url}/new`} component={AuthorizationEdit} />
      <Route path={`${match.url}/details`} component={AuthorizationDetails} />
    </Switch>
  </>
);

export default Routes;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './subsidiary-create-menu.scss';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from 'app/modules/account/register/full-register/steps/steps';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS, AUTHORITIES } from 'app/config/constants';
import { getSession } from 'app/shared/reducers/authentication';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import {
  getFinalDestinationDetailByUuid,
  reset as resetFinalDestination
} from 'app/entities/user-final-destination/user-final-destination.reducer';
import { createSubidiaryByHolding, reset } from '../../../units/final-destination-units-reducer';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import FinalDestinationRegisterAddress from '../../steps/address/final-destination-register-address';
import FinalDestinationRegisterResponsible from '../../steps/responsible/final-destination-register-responsible';
import FinalDestinationRegisterDocumentation from '../../steps/documentation/final-destination-register-documentation';

export interface ISubsidiaryCreateMenuProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface ISubsidiaryCreateMenuState {
  isSubsidiary: boolean;
  steps: StepItem[];
  currentIndex: number;
  userFinalDestination: IUserFinalDestination;
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
}

export class SubsidiaryCreateMenu extends React.Component<ISubsidiaryCreateMenuProps, ISubsidiaryCreateMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userFinalDestination: {},
      isSubsidiary: null,
      steps: this.mapStepsSubsidiary(),
      currentIndex: 0,
      showResponsabilityModal: false,
      showErrorModal: false
    };
  }

  componentDidMount() {
    this.props.resetFinalDestination();
    this.props.getFinalDestinationDetailByUuid();
  }

  onNext = (userFinalDestination: IUserFinalDestination) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsabilityModal: true,
        userFinalDestination,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userFinalDestination,
      });
    }
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  componentWillReceiveProps(newProps: ISubsidiaryCreateMenuProps) {
    if (
      newProps.finalDestinationUuidDetail != null &&
      newProps.finalDestinationDetailUuidStatus != this.props.finalDestinationDetailUuidStatus &&
      newProps.finalDestinationDetailUuidStatus === HttpRequestStatus.SUCCESS
    ) {
      this.setState(
        {
          userFinalDestination: {
            finalDestination: {
              holdingCnpj: newProps.finalDestinationUuidDetail.finalDestination.legalPerson?.cnpj,
              isSubsidiary: true,
              companyName: newProps.finalDestinationUuidDetail.finalDestination.legalPerson?.companyName
            },
            user: {
              authorities: [AUTHORITIES.USER],
            },
          },
        },
        () => this.props.resetFinalDestination()
      );
    }
    if (newProps.createSubsidiaryStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
  }

  goToSuccessAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.subsidiaryRegister',
        buttonUrl: '/final-destination/destination',
      },
    });
  };

  onCancelModal = () => {
    this.setState({
      showResponsabilityModal: false,
    });
  };

  onConfirmModal = () => {
    const { userFinalDestination } = this.state;
    this.props.createSubidiaryByHolding(userFinalDestination);
  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  private mapStepsSubsidiary = (): StepItem[] => [
    {
      title: translate('fullRegister.header.responsible'),
      render: () => (
        <FinalDestinationRegisterResponsible isNew={true} onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />
      ),
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <FinalDestinationRegisterAddress onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />,
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => <FinalDestinationRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />,
    },
  ];

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  handleGoBack = () => {
    this.props.history.replace('/final-destination/destination');
  };

  render() {
    const { steps, currentIndex, userFinalDestination } = this.state;
    const { finalDestinationDetailUuidStatus, createSubsidiaryStatus } = this.props;
    if (checkStatusOnGoing([finalDestinationDetailUuidStatus, createSubsidiaryStatus])) {
      return <Loading />;
    }
    return (
      <div className="subsidiary-create-menu__items" style={{ position: 'relative' }}>
        <BackHeaderComponent onBack={this.handleGoBack} />
        {steps && userFinalDestination.finalDestination && <Steps steps={steps} onIndexClick={this.onIndexClick} currentIndex={currentIndex} />}
        <AlertModal
          showModal={this.state.showResponsabilityModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={this.onConfirmModal}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="producerRegister.responsibilityTerms"
        />
        <AlertModal
          showModal={this.state.showErrorModal}
          buttonTwoActionMessage={'entity.action.ok'}
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.producerRegister"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSession,
  createSubidiaryByHolding,
  getFinalDestinationDetailByUuid,
  reset,
  resetFinalDestination,
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  finalDestinationDetailUuidStatus: root.userFinalDestination.finalDestinationDetailUuidStatus,
  finalDestinationUuidDetail: root.userFinalDestination.finalDestinationUuidDetail,
  createSubsidiaryStatus: root.finalDestinationUnits.createSubsidiaryStatus,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubsidiaryCreateMenu);

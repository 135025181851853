import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import './back-header-component.scss';

export interface IBackHeaderComponentProps {
  onBack: () => void;
  hasButton?: boolean;
  buttonLabel?: string;
  onButtonClick?: () => void;
}

export interface IBackHeaderComponentState {}

export class BackHeaderComponent extends React.Component<IBackHeaderComponentProps, IBackHeaderComponentState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    const { onButtonClick } = this.props;
    if (onButtonClick) {
      onButtonClick();
    }
  };

  render() {
    const { hasButton, buttonLabel } = this.props;
    return (
      <div className="list__items--back">
        <Container>
          <Row>
            <Col md="10">
              <div className="confirmation--back" onClick={this.props.onBack}>
                <div className="confirmation--back-icon" />
                <span>
                  <Translate contentKey={'mtr-signature.back'} />
                </span>
              </div>
            </Col>
            {hasButton && (
              <Col md="2">
                <div className="list__items--edit" onClick={this.handleClick}>
                  {buttonLabel ? <span>{buttonLabel}</span> : <Translate contentKey={'mtr-signature.edit'} />}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

export default BackHeaderComponent;

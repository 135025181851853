import { GenericFavorite } from 'app/modules/generic/favorite-list/generic-favorite';
import GenericFavoriteList from 'app/modules/generic/favorite-list/generic-favorite-list';
import { FavoriteType } from 'app/shared/model/favorite.model';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import './manager-favorite-summary.scss';

export interface IManagerFavoriteSummaryProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IManagerFavoriteSummaryState { }

export class ManagerFavoriteSummary extends GenericFavorite {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="mtr-invite__items mtr-invite__table">
                <Container>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }} onClick={this.goToInvitationPage}>
                        <div style={{ position: 'absolute', top: 30, zIndex: 1 }}>
                            <Button className={'button primary'} type={'submit'}>
                                <span className="favorite-button__text">{this.inviteFinalDestinationOrTransporter}</span>
                            </Button>
                        </div>
                    </div>
                    <GenericFavoriteList
                        title={this.titleFinalDestination}
                        page={this.pageSummary}
                        headers={this.tableFinalDestinationHeaders.map(item => {
                            item.sortDisabled = true;
                            return item;
                        })}
                        isSummary
                        seAllTitle={this.seeAllFinalDestinations}
                        favoriteType={FavoriteType.FINAL_DESTINATION}
                        onListClick={this.handleListClick}
                        onItemClick={this.onItemClicked}
                    />
                    <GenericFavoriteList
                        title={this.titleTransporter}
                        page={this.pageSummary}
                        headers={this.tableTransporterHeaders.map(item => {
                            item.sortDisabled = true;
                            return item;
                        })}
                        isSummary
                        seAllTitle={this.seeAllTransporters}
                        favoriteType={FavoriteType.TRANSPORTER}
                        onListClick={this.handleListClick}
                        onItemClick={this.onItemClicked}
                    />
                    <GenericFavoriteList
                        title={this.titleTransshipment}
                        page={this.pageSummary}
                        headers={this.tableTransshipmentHeaders.map(item => {
                            item.sortDisabled = true;
                            return item;
                        })}
                        isSummary
                        seAllTitle={this.seeAllTransshipments}
                        favoriteType={FavoriteType.TRANSSHIPMENT}
                        onListClick={this.handleListClick}
                        onItemClick={this.onItemClicked}
                    />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (root: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerFavoriteSummary);

import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession, logout } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import '../i18n/yup-translate'

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps { }

export const App = (props: IAppProps) => {
  useEffect(() => {
    props.getSession();
    props.getProfile();
  }, []);

  useEffect(() => {
    if (props.getSessionStatus === HttpRequestStatus.ERROR) props.logout();
  }, [props.getSessionStatus])

  return (
    <Router basename={baseHref}>
      <div className="app-container" style={{ height: '100vh' }}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <div className="container-fluid view-container" id="app-view-container">
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
  getSessionStatus: authentication.getSessionStatus,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.USER]),
  isTransporterAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSPORTER_ADMIN]),
  isTransporterUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSPORTER_USER]),
  isTransshipmentAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN]),
  isTransshipmentUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_USER]),
  isManagerAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
  isManagerUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER_USER]),
  isProducerAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN]),
  isProducerUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.PRODUCER_USER]),
  isFinalDestinationAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN]),
  isFinalDestinationUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_USER]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  hideHeader: applicationProfile.hideHeader,
});

const mapDispatchToProps = { setLocale, getSession, getProfile, logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));

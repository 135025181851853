import { defaultValue, ITransshipmentMtr, Transshipment } from 'app/shared/model/transshipment.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import StringUtils from 'app/shared/util/string-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';

export const ACTION_TYPES = {
  FETCH_TRANSSHIPMENT_LIST: 'transshipment/FETCH_TRANSSHIPMENT_LIST',
  SEARCH_TRANSSHIPMENT: 'transshipment/SEARCH_TRANSSHIPMENT',
  FETCH_TRANSSHIPMENT: 'transshipment/FETCH_TRANSSHIPMENT',
  CREATE_TRANSSHIPMENT: 'transshipment/CREATE_TRANSSHIPMENT',
  UPDATE_TRANSSHIPMENT: 'transshipment/UPDATE_TRANSSHIPMENT',
  DELETE_TRANSSHIPMENT: 'transshipment/DELETE_TRANSSHIPMENT',
  RESET: 'transshipment/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<Transshipment>,
  transshipments: [] as ReadonlyArray<ITransshipmentMtr>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type TransshipmentState = Readonly<typeof initialState>;

// Reducer

export default (state: TransshipmentState = initialState, action): TransshipmentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TRANSSHIPMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TRANSSHIPMENT):
    case REQUEST(ACTION_TYPES.SEARCH_TRANSSHIPMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_TRANSSHIPMENT):
    case REQUEST(ACTION_TYPES.UPDATE_TRANSSHIPMENT):
    case REQUEST(ACTION_TYPES.DELETE_TRANSSHIPMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_TRANSSHIPMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.CREATE_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.UPDATE_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.DELETE_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.SEARCH_TRANSSHIPMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRANSSHIPMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRANSSHIPMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_TRANSSHIPMENT):
      return {
        ...state,
        loading: false,
        transshipments: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_TRANSSHIPMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_TRANSSHIPMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_TRANSSHIPMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/transshipments';

// Actions

export const getEntities: ICrudGetAllAction<Transshipment> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_TRANSSHIPMENT_LIST,
  payload: axios.get<Transshipment>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const searchTransshipments: ICrudSearchActionCustom<ITransshipmentMtr> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}/search${params}`;
  return {
    type: ACTION_TYPES.SEARCH_TRANSSHIPMENT,
    payload: axios.get<ITransshipmentMtr>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<Transshipment> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TRANSSHIPMENT,
    payload: axios.get<Transshipment>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<Transshipment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSSHIPMENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<Transshipment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TRANSSHIPMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<Transshipment> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TRANSSHIPMENT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { translate, Translate } from 'react-jhipster';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { GeneratorCompanyType, GeneratorType, IProducer } from 'app/shared/model/producer.model';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import GenericCardChoice from 'app/components/generic-card-choice/generic-card-choice';
import ProducerRegisterHolding from './holding/producer-register-holding';
import ProducerRegisterSubsidiary from './subsidiary/producer-register-subsidiary';
import ProducerRegisterPhysicalPerson from './physical-person/ProducerRegisterPhysicalPerson';
import { Col, Row } from 'reactstrap';
import { isEmpty } from 'lodash';

export interface IProducerRegisterCompanyProps extends StateProps, DispatchProps {
  userProducer: IUserProducer;
  onNext: (userProducer: IUserProducer) => void;
  onBack: () => void;
}

export interface IProducerRegisterCompanyState {
  selectedProducerType: GeneratorType;
  isProducerTypeSelected: boolean;
  isCompanyTypeSelected: boolean;
  selectedCompanyType: GeneratorCompanyType;
  producer: IProducer;
}

export class ProducerRegisterCompany extends React.Component<IProducerRegisterCompanyProps, IProducerRegisterCompanyState> {
  constructor(props) {
    super(props);
    const producer = this.props.userProducer.producer;
    this.state = {
      selectedProducerType: producer ? producer.generatorType : null,
      isProducerTypeSelected: producer ? producer.generatorType != null : false,
      isCompanyTypeSelected: producer ? producer.generatorCompanyType != null : false,
      selectedCompanyType: producer ? producer.generatorCompanyType : null,
      producer: producer ? producer : {},
    };
  }

  mapType = () => {
    const objects = Object.keys(GeneratorType).map(key => {
      return { name: translate(`enums.generatorType.${key}`), keyEnum: key };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  search = () => { };

  onClickItem = (item: any) => {
    this.setState(
      {
        selectedProducerType: item.keyEnum,
        isProducerTypeSelected: true,
      },
      () => this.forceUpdate()
    );
  };

  onBack = () => {
    this.props.onBack();
  };

  onSelectCompanyType = (index: GeneratorCompanyType) => {
    this.setState({
      isCompanyTypeSelected: true,
      selectedCompanyType: index,
    });
  };

  componentWillReceiveProps(newProps: IProducerRegisterCompanyProps) {
    if (newProps.userProducer != null && !isEmpty(newProps.userProducer) && !isEmpty(this.state.producer)) {
      this.setState({ selectedProducerType: newProps.userProducer.producer.generatorType, })
    }
  }

  mapSelectedProducerTypeToSelect = () => {
    const { selectedProducerType } = this.state;

    return selectedProducerType
      ? {
        name: translate(`enums.generatorType.${selectedProducerType}`),
        keyEnum: selectedProducerType,
      }
      : null;
  };

  renderDropDownSearch = () => (
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <h1 style={{ fontWeight: 500, marginLeft: '-40px', marginRight: '-40px', marginBottom: '35px' }}>
          <Translate contentKey={'producerRegister.company.register-title'} />
        </h1>
        <DropdownSearch
          data={this.mapType()}
          getData={this.search}
          onClickItem={this.onClickItem}
          // selectedData={this.props?.userProducer?.producer?.generatorType}
          title={translate('producerRegister.company.selectGenerator')}
          style={{ backgroundColor: '#f6f6f6' }}
          showInsiderSelect
          notAlphabeticalOrder
          filterLocalData
        />
      </Col>
    </Row>
  );

  onNext = (companyProducer: IProducer) => {
    const { selectedProducerType, selectedCompanyType } = this.state;
    let updatedProducer: IProducer = {};

    if (selectedCompanyType === GeneratorCompanyType.NATURAL) {
      updatedProducer = {
        ...this.props.userProducer.producer,
        generatorType: selectedProducerType,
        generatorCompanyType: selectedCompanyType,
        naturalPerson: {
          cpf: companyProducer.naturalPerson.cpf,
          name: companyProducer.naturalPerson.name,
          phone: companyProducer.naturalPerson.phone,
          municipalRegistration: companyProducer.naturalPerson?.municipalRegistration,
        }
      }

      delete updatedProducer.legalPerson;
    }

    if (selectedCompanyType === GeneratorCompanyType.HOLDING) {
      updatedProducer = {
        ...this.props.userProducer.producer,
        registrationNumber: companyProducer.registrationNumber,
        cnaeCodes: companyProducer.cnaeCodes,
        businessLicense: companyProducer.businessLicense,
        generatorType: selectedProducerType,
        generatorCompanyType: selectedCompanyType,
        activityType: companyProducer.activityType ?? null,
        legalPerson: {
          cnpj: companyProducer.legalPerson.cnpj,
          fantasyName: companyProducer.legalPerson.fantasyName,
          companyName: companyProducer.legalPerson.companyName,
          phone: companyProducer.legalPerson?.phone,
          municipalRegistration: companyProducer.legalPerson?.municipalRegistration,
        }
      }

      delete updatedProducer.naturalPerson;
    }

    if (selectedCompanyType === GeneratorCompanyType.SUBSIDIARY) {
      updatedProducer = {
        ...this.props.userProducer.producer,
        registrationNumber: companyProducer.registrationNumber,
        cnaeCodes: companyProducer.cnaeCodes,
        businessLicense: companyProducer.businessLicense,
        generatorType: selectedProducerType,
        generatorCompanyType: selectedCompanyType,
        holdingCnpj: companyProducer.holdingCnpj ?? '',
        activityType: companyProducer.activityType ?? null,
        legalPerson: {
          cnpj: companyProducer.legalPerson.cnpj,
          fantasyName: companyProducer.legalPerson.fantasyName,
          companyName: companyProducer.legalPerson.companyName,
          phone: companyProducer.legalPerson?.phone,
          municipalRegistration: companyProducer.legalPerson?.municipalRegistration,
        }
      }

      delete updatedProducer.naturalPerson;
    }

    const userProducer = { ...this.props.userProducer };
    userProducer.producer = updatedProducer;
    this.props.onNext(userProducer);
  };

  renderForms = () => {
    const { selectedCompanyType, producer } = this.state;

    return (
      <div>
        {selectedCompanyType == GeneratorCompanyType.HOLDING && (
          <ProducerRegisterHolding producer={producer} onNext={this.onNext} onBack={this.onBack} />
        )}
        {selectedCompanyType == GeneratorCompanyType.SUBSIDIARY && (
          <ProducerRegisterSubsidiary producer={producer} onNext={this.onNext} onBack={this.onBack} />
        )}
        {selectedCompanyType == GeneratorCompanyType.NATURAL && (
          <ProducerRegisterPhysicalPerson producer={producer} onNext={this.onNext} onBack={this.onBack} />
        )}
      </div>
    );
  };

  renderChoice = () => {
    const { selectedCompanyType, isCompanyTypeSelected } = this.state;
    const producerRegister = translate('producerRegister.company')
    return (
      <div className="producer-register-company__items">
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <DropdownSearch
              data={this.mapType()}
              getData={this.search}
              onClickItem={this.onClickItem}
              selectedData={this.mapSelectedProducerTypeToSelect()}
              placeholder={translate('producerRegister.company.generator')}
              title={translate('producerRegister.company.selectGenerator')}
              style={{ backgroundColor: '#f6f6f6' }}
              showInsiderSelect
              notAlphabeticalOrder
              filterLocalData
            />
            <div className="producer-register-company__items--subtitle">
              {' '}
              <span>{translate('producerRegister.company.subsidiaryHoldingOrPhysicalPerson')}</span>
            </div>
            <div className="producer-register-company__items--cards">
              <Row>
                <GenericCardChoice
                  index={GeneratorCompanyType.NATURAL}
                  title={producerRegister.physicalPerson.title}
                  text={producerRegister.physicalPerson.text}
                  onSelect={this.onSelectCompanyType}
                  selected={selectedCompanyType === GeneratorCompanyType.NATURAL}
                  hideText={isCompanyTypeSelected}
                />

                <GenericCardChoice
                  index={GeneratorCompanyType.HOLDING}
                  title={producerRegister.holding.title}
                  text={producerRegister.holding.text}
                  onSelect={this.onSelectCompanyType}
                  selected={selectedCompanyType === GeneratorCompanyType.HOLDING}
                  hideText={isCompanyTypeSelected}
                />
                {/* <GenericCardChoice
                  index={GeneratorCompanyType.SUBSIDIARY}
                  title={producerRegister.subsidiary.title}
                  text={producerRegister.subsidiary.text}
                  onSelect={this.onSelectCompanyType}
                  selected={selectedCompanyType === GeneratorCompanyType.SUBSIDIARY}
                  hideText={isCompanyTypeSelected}
                /> */}
              </Row>
            </div>
            {isCompanyTypeSelected && this.renderForms()}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { isProducerTypeSelected } = this.state;

    return (
      <div>
        {!isProducerTypeSelected && this.renderDropDownSearch()}
        {isProducerTypeSelected && this.renderChoice()}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerRegisterCompany);

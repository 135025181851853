import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getTransporters } from 'app/entities/transporter/transporter.reducer';
import { getEntities as getUserTransporters } from 'app/entities/user-transporter/user-transporter.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './driver.reducer';

export interface IDriverUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IDriverUpdateState {
  isNew: boolean;
  transporterId: string;
  userTransporterId: string;
}

export class DriverUpdate extends React.Component<IDriverUpdateProps, IDriverUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      transporterId: '0',
      userTransporterId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getTransporters();
    this.props.getUserTransporters();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { driverEntity } = this.props;
      const entity = {
        ...driverEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/driver');
  };

  render() {
    const { driverEntity, transporters, userTransporters, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.driver.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.driver.home.createOrEditLabel">Create or edit a Driver</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : driverEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="driver-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="driver-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="driverLicenseLabel" for="driver-driverLicense">
                    <Translate contentKey="resitrackApp.driver.driverLicense">Driver License</Translate>
                  </Label>
                  <AvField
                    id="driver-driverLicense"
                    type="text"
                    name="driverLicense"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="expirationDateLabel" for="driver-expirationDate">
                    <Translate contentKey="resitrackApp.driver.expirationDate">Expiration Date</Translate>
                  </Label>
                  <AvField
                    id="driver-expirationDate"
                    type="date"
                    className="form-control"
                    name="expirationDate"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="driverTypeLabel" for="driver-driverType">
                    <Translate contentKey="resitrackApp.driver.driverType">Driver Type</Translate>
                  </Label>
                  <AvInput
                    id="driver-driverType"
                    type="select"
                    className="form-control"
                    name="driverType"
                    value={(!isNew && driverEntity.driverType) || 'B'}
                  >
                    <option value="B">{translate('resitrackApp.DriverType.B')}</option>
                    <option value="C">{translate('resitrackApp.DriverType.C')}</option>
                    <option value="D">{translate('resitrackApp.DriverType.D')}</option>
                    <option value="E">{translate('resitrackApp.DriverType.E')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="driver-transporter">
                    <Translate contentKey="resitrackApp.driver.transporter">Transporter</Translate>
                  </Label>
                  <AvInput
                    id="driver-transporter"
                    type="select"
                    className="form-control"
                    name="transporter.id"
                    value={isNew ? transporters[0] && transporters[0].id : driverEntity.transporter.id}
                    required
                  >
                    {transporters
                      ? transporters.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="driver-userTransporter">
                    <Translate contentKey="resitrackApp.driver.userTransporter">User Transporter</Translate>
                  </Label>
                  <AvInput id="driver-userTransporter" type="select" className="form-control" name="userTransporter.id">
                    <option value="" key="0" />
                    {userTransporters
                      ? userTransporters.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/driver" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  transporters: storeState.transporter.entities,
  userTransporters: storeState.userTransporter.entities,
  driverEntity: storeState.driver.entity,
  loading: storeState.driver.loading,
  updating: storeState.driver.updating,
  updateSuccess: storeState.driver.updateSuccess,
});

const mapDispatchToProps = {
  getTransporters,
  getUserTransporters,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverUpdate);

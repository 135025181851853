import { Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import AlertModal from 'app/components/alert-modal/alert-modal';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { searchProducers } from 'app/entities/producer/producer.reducer';
import { searchFinalDestinations } from 'app/entities/final-destination/final-destination.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Pageable } from 'app/shared/model/pageable';
import { IMtr, MtrStatus } from 'app/shared/model/mtr.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/shared/util/string-utils';
import React, { Fragment } from 'react';
import { createEntity as createCdf, reset as resetCdf } from '../../entities/cdf/cdf.reducer';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './custom-cdf-create.scss';
import StaticTableBar, { StaticTableBarItem } from 'app/components/residues-components/add/static-table-bar/static-table-bar';
import moment from 'moment';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { findMtr, reset, resetStatus, searchMtrs } from 'app/modules/generic/mtr/mtr-reducer';
import { IProducer } from 'app/shared/model/producer.model';
import { IFinalDestination } from 'app/shared/model/final-destination.model';
import { isEmpty, isEqual } from 'lodash';
import { Cdf } from 'app/shared/model/cdf.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import EmptyList from '../empty-list/empty-list';

interface ICustomCdfCreateProps extends StateProps, DispatchProps, RouteComponentProps {
    // TODO
}

interface ICustomCdfCreateState {
    page: Pageable;
    endDate?: string;
    startDate?: string;
    filterName: string;
    showFilter: boolean;
    isFirstGet: boolean;
    selectedMtrs: IMtr[];
    showSuccess: boolean;
    producers: IProducer[];
    showErrorModal: boolean;
    producerOrFinalDestinationText: string;
    finalDestinations: IFinalDestination[];
    hasChangedProducerOrFinalDestination: boolean;
    selectedProducerOrFinalDestination: { id: number | null, name: string };
    goToCdfNumber: string;
}

class CustomCdfCreate extends React.Component<ICustomCdfCreateProps, ICustomCdfCreateState> {
    timer = null;

    private tableHeaders: StaticTableBarItem[] = [
        { name: translate('cdf.mtrList.date'), width: '17%' },
        { name: translate('cdf.mtrList.mtrCode'), width: '17%' },
        { name: translate('cdf.mtrList.transporter'), width: '17%' },
        { name: translate(`cdf.mtrList.${this.props.hasFinalDestinationAuthorities ? 'producer' : 'finalDestination'}`), width: '17%' },
        { name: translate('cdf.mtrList.status'), width: '17%' },
        { name: translate('cdf.mtrList.selectAll'), width: '17%', color: '#30AB64', checkbox: true, onClick: (event) => this.selectAllMtrs(event) }
    ];

    constructor(props: Readonly<ICustomCdfCreateProps>) {
        super(props);
        this.state = {
            producers: [],
            filterName: '',
            selectedMtrs: [],
            showFilter: true,
            isFirstGet: false,
            showSuccess: false,
            showErrorModal: false,
            finalDestinations: [],
            producerOrFinalDestinationText: '',
            hasChangedProducerOrFinalDestination: false,
            selectedProducerOrFinalDestination: { id: null, name: '' },
            page: { totalElements: 0, size: 15, totalPages: 1, page: 0 },
            goToCdfNumber: null
        };
    }

    componentDidMount() {
        this.resetAll();
        this.getAllMtrs();
        this.getAllProducersOrFinalDestinations();
    }

    componentWillUnmount() {
        this.resetAll();
    }

    componentWillReceiveProps(newProps: Readonly<ICustomCdfCreateProps>) {
        if (newProps.totalItems !== this.props.totalItems) {
            this.setState({
                page: {
                    ...this.state.page,
                    totalElements: newProps.totalItems
                },
            });
        }

        if (!isEqual(newProps.producers, this.state.producers) && newProps.producers.length !== 0) {
            // @ts-ignore
            this.setState({ producers: newProps.producers }, async () => {
                if (!this.state.isFirstGet) {
                    await this.setSelectedProducerOrFinalDestination(this.state.producers[0]);
                    this.isFirstGet(true);
                    this.getAllMtrs();
                }
            });
        }

        if (!isEqual(newProps.finalDestinations, this.state.finalDestinations) && newProps.finalDestinations.length !== 0) {
            // @ts-ignore
            this.setState({ finalDestinations: newProps.finalDestinations }, async () => {
                if (!this.state.isFirstGet) {
                    await this.setSelectedProducerOrFinalDestination(this.state.finalDestinations[0]);
                    this.isFirstGet(true);
                    this.getAllMtrs();
                }
            });
        }

        if (newProps.createCdfStatus === HttpRequestStatus.SUCCESS) {
            this.toggleSuccessModal();
        }

        if (newProps.createCdfStatus === HttpRequestStatus.ERROR) {
            this.toggleErrorModal();
        }

        if (!isEqual(newProps.entity, this.props.entity)) {
            this.setState({
                goToCdfNumber: newProps.entity.cdfNumber
            });
        }
    }

    resetAll = () => {
        this.props.reset();
        this.props.resetCdf();
    };

    isFirstGet = (isFirstGet: boolean) => {
        this.setState({
            isFirstGet
        });
    };


    isDateValid = (date: string | undefined): boolean => {
        return date ? moment(date, APP_LOCAL_DATE_FORMAT, true).isValid() : false;
    };

    getAllMtrs = () => {
        const { searchMtrs, hasFinalDestinationAuthorities } = this.props;
        const { page, filterName, startDate, endDate, selectedProducerOrFinalDestination, hasChangedProducerOrFinalDestination } = this.state;

        const isStartDateValid = this.isDateValid(startDate);
        const isEndDateValid = this.isDateValid(endDate);

        const validStartDate = isStartDateValid ? startDate : undefined;
        const validEndDate = isEndDateValid ? endDate : undefined;
        console.log("Valores em getAllMtrs - startDate:", startDate, "endDate:", endDate);

        searchMtrs(
            page.page, page.size, page.sort, !StringUtils.isStringInvalid(filterName) ? { numberMtr: filterName } : null,
            MtrStatus.COMPLETE, validStartDate, validEndDate, hasFinalDestinationAuthorities, selectedProducerOrFinalDestination.id);

        if (hasChangedProducerOrFinalDestination) {
            this.hasChangedProducerOrFinalDestination(false);
            this.selectAllMtrs(false);
        }
    };

    isSelectedProducerOrFinalDestinationEmpty = () => {
        const { selectedProducerOrFinalDestination } = this.state;
        return selectedProducerOrFinalDestination.id == null;
    };

    setSelectedProducerOrFinalDestination = async (selectedProducerOrFinalDestination: IProducer | IFinalDestination) => {
        if (selectedProducerOrFinalDestination.id === this.state.selectedProducerOrFinalDestination.id) return;
        this.setState({
            hasChangedProducerOrFinalDestination: true,
            selectedProducerOrFinalDestination: {
                id: selectedProducerOrFinalDestination.id,
                name: selectedProducerOrFinalDestination['name'] || selectedProducerOrFinalDestination?.legalPerson.companyName || ''
            }
        });
    };

    hasChangedProducerOrFinalDestination = (hasChangedProducerOrFinalDestination: boolean) => {
        this.setState({
            hasChangedProducerOrFinalDestination
        });
    };

    showFilter = (showFilter: boolean) => {
        this.setState({
            showFilter
        }, () => this.getAllProducersOrFinalDestinations());
    };

    selectAllMtrs = (event: boolean) => {
        const { mtrs } = this.props;
        let selectedMtrs = [...this.state.selectedMtrs];
        if (event) {
            mtrs.forEach(mtr => selectedMtrs.push(mtr));
        } else {
            selectedMtrs = [];
        }
        this.setState({ selectedMtrs });
    };

    selectedMtr = (mtr: IMtr) => {
        const selectedMtrs = [...this.state.selectedMtrs];
        const selectedMtr = selectedMtrs.filter(it => it.id === mtr.id)[0];
        if (selectedMtr != null) {
            selectedMtrs.splice(selectedMtrs.indexOf(selectedMtr), 1);
        } else {
            selectedMtrs.push(mtr);
        }
        this.setState({
            selectedMtrs
        });
    };


    handleTransformToTableContent = (content?: IMtr): (string | JSX.Element)[][] => {
        const { selectedMtrs } = this.state;
        const { hasFinalDestinationAuthorities } = this.props;

        const result: (string | JSX.Element)[][] = [];

        result.push([
            content.collectionDate != null ? moment(content.collectionDate).format(APP_LOCAL_DATE_FORMAT) : '',
            content.numberMtr || '',
            content.transporter != null ? content.transporter?.legalPerson?.companyName ?? content.transporter?.naturalPerson?.name : '',
            hasFinalDestinationAuthorities ? content.producer?.legalPerson?.companyName ?? content.producer?.naturalPerson?.name : content.finalDestination?.legalPerson?.companyName || '',
            this.getMtrStatusStyle(content.status || MtrStatus.CANCELLED),
            <Checkbox
                icon={<img src={'content/images/icons/check-gray.svg'} alt='' />}
                checkedIcon={<img src={'content/images/icons/check-green.svg'} alt='' />}
                checked={selectedMtrs ? selectedMtrs.filter(it => it.id === content.id).length > 0 : false}
            />
        ]);

        return result;
    };

    getMtrStatusStyle = (mtrStatus: MtrStatus) => {
        if (mtrStatus == null) return <div />;

        let style = 'status-tag ';

        switch (mtrStatus) {
            case MtrStatus.COMPLETE:
                style = style.concat('complete');
                break;

            case MtrStatus.INCOMPLETE:
                style = style.concat('incomplete');
                break;

            case MtrStatus.CANCELLED:
                style = style.concat('cancelled');
                break;

            case MtrStatus.PENDING:
                style = style.concat('pending');
                break;
        }

        return (
            <div className={style}>
                <span>{translate(`mtr-list.status.${mtrStatus}`).toUpperCase()}</span>
            </div>
        );
    };

    onCreateMtr = () => {
        this.props.history.push(`${this.props.match.url}/new`);
    };

    onHandleFilterChanged = (filterName: string) => {
        this.setState({
            filterName,
            page: {
                ...this.state.page,
                page: 0,
            }
        }, () => this.getAllMtrs());
    };

    onMtrClick = (index: number) => {
        const { mtrs } = this.props;
        const mtr = mtrs[index];
        this.selectedMtr(mtr);
    };

    onFinishAdding = () => {
        const { createCdf } = this.props;
        const { selectedMtrs, startDate, endDate } = this.state;

        // Adicionando validação para startDate e endDate
        if (!startDate || !endDate) {
            // Exibir uma mensagem de erro ou alerta informando que as datas são obrigatórias
            alert("As datas de início e término são obrigatórias.");
            return;
        }

        console.log("Valores ao enviar para o backend - startDate:", startDate, "endDate:", endDate);

        const cdf = {
            mtrs: selectedMtrs,
            startDate: startDate,
            endDate: endDate
        };

        console.log("Dados enviados ao backend:", cdf);
        createCdf(cdf);
    };


    onBackHandler = () => {
        this.props.history.goBack();
    };

    onCdfCreatedCorrectly = () => {
        if (this.state.goToCdfNumber) {
            this.props.history.push(
                `/${this.props.hasFinalDestinationAuthorities ? 'final-destination' : this.props.hasManagerAuthorities ? 'manager' : 'producer'}/cdf/${this.state.goToCdfNumber}/manage`)
        }
    };

    toggleErrorModal = () => {
        this.setState({
            showErrorModal: !this.state.showErrorModal
        });
    };

    toggleSuccessModal = () => {
        this.setState({
            showSuccess: !this.state.showSuccess
        });
    };

    cleanFilter = () => {
        this.setState({
            endDate: undefined,
            startDate: undefined,
            selectedProducerOrFinalDestination: { id: null, name: '' }
        }, () => this.getAllMtrs()
        );
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: {
                    ...this.state.page,
                    page: newPage
                }
            },
            () => this.getAllMtrs()
        );
    };

    getAllProducersOrFinalDestinations = (searchText?: string) => {
        const { mtrs, hasFinalDestinationAuthorities } = this.props;
        if (mtrs.length > 0) {
            const typeAuthoritie = hasFinalDestinationAuthorities ? 'producers' : 'finalDestinations';
            let objects = mtrs
                .map(({ finalDestination, producer }) => hasFinalDestinationAuthorities ? producer : finalDestination)
                .filter(item => item != null) // Filtrar itens nulos
                .filter((item, index, array) => {
                    const matchesSearchText = searchText ? item?.companyName?.toLowerCase().includes(searchText.toLowerCase()) : true;
                    return matchesSearchText;
                });
            // Remover duplicados com base no ID do produtor ou destino final
            objects = objects.filter((item, index, array) => index === array.findIndex(t => t?.id === item?.id));

            // @ts-ignore
            this.setState({ [`${typeAuthoritie}`]: objects });
        }
    };




    mapAllProducersOrFinalDestinations = () => {
        const { producers, finalDestinations } = this.state;
        const { hasFinalDestinationAuthorities } = this.props;
        let result;
        if (hasFinalDestinationAuthorities) {
            result = producers.map(item => ({ name: item?.legalPerson?.companyName ?? item?.naturalPerson?.name, id: item?.id }))
                .filter((item, index, array) => index === array.findIndex(t => t.name === item.name));
        } else {
            result = finalDestinations?.map(item => ({ name: item?.legalPerson?.companyName, id: item?.id }))
                .filter((item, index, array) => index === array.findIndex(t => t.name === item.name));
        }
        result = result.length > 0 ? result : [];
        console.log('Mapped Producers or Final Destinations:', result);
        return result;
    };

    renderRowItem = (info: string | JSX.Element, index: number) => (
        <div className='add-mtrs-list__item' style={{ width: this.tableHeaders[index].width }}>
            {info}
        </div>
    );

    renderRow = (mtr: IMtr) => {
        const row = this.handleTransformToTableContent(mtr);
        return (
            <div className='add-mtrs-list__row' onClick={() => this.selectedMtr(mtr)}>
                {this.renderRowItem(row[0][0], 0)}
                {this.renderRowItem(row[0][1], 1)}
                {this.renderRowItem(row[0][2], 2)}
                {this.renderRowItem(row[0][3], 3)}
                {this.renderRowItem(row[0][4], 4)}
                {this.renderRowItem(row[0][5], 5)}
            </div>
        );
    };

    renderMtrFilter = () => {
        const { hasFinalDestinationAuthorities } = this.props;
        const { startDate, endDate, selectedProducerOrFinalDestination } = this.state;
        // @ts-ignore
        return (
            <div className='mtr-list__filter-open' style={{ marginBottom: '0', paddingTop: '0' }}>
                <Row>
                    <Col md='5'>
                        <div className='mtr-list__filter-open--title'>{translate('cdf.period')}</div>
                        <div className='mtr-list__filter-open--items'>
                            <div className={'cdf-date-picker-wrapper'}>
                                <DatePickerComponent
                                    dateSelected={startDate}
                                    dateFormat={APP_LOCAL_DATE_FORMAT}
                                    placeholder={translate('mtr-list.filter-open.if')}
                                    onDateChange={value => this.setState({ startDate: value })}
                                />
                                <DatePickerComponent
                                    dateSelected={endDate}
                                    dateFormat={APP_LOCAL_DATE_FORMAT}
                                    onDateChange={value => this.setState({ endDate: value })}
                                    placeholder={translate('mtr-list.filter-open.until')}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md="7">
                        <div className="mtr-list__filter-open--title">
                            {translate(`cdf.${hasFinalDestinationAuthorities ? 'producer' : 'finalDestination'}`)}
                        </div>
                        <div className={'cdf-dropdown-search'}>
                            <div className="mtr-list__filter-open--items">
                                <DropdownSearch
                                    showSearchField
                                    filterLocalData
                                    showInsiderSelect
                                    notAlphabeticalOrder
                                    style={{ backgroundColor: '#f6f6f6' }}
                                    title={hasFinalDestinationAuthorities ? translate('cdf.selectGenerator') : translate('cdf.selectFinalDestination')}
                                    data={this.mapAllProducersOrFinalDestinations()}
                                    getData={this.getAllProducersOrFinalDestinations}
                                    onClickItem={this.setSelectedProducerOrFinalDestination}
                                    selectedData={selectedProducerOrFinalDestination.id != null ? selectedProducerOrFinalDestination : undefined}
                                />
                                <div className="mtr-list__filter-open--button" onClick={this.getAllMtrs}>
                                    <span>{translate('mtr-list.filter-open.button')}</span>
                                    <div className="mtr-list__filter-open--img" />
                                </div>
                                <div className="mtr-list__filter-open--clear-filter" onClick={this.cleanFilter}>
                                    {translate('mtr-list.filter-open.clear-filter')}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="mtr-list__filter-open--close" onClick={() => this.showFilter(false)} />
            </div>
        );
    };

    renderAddPage = () => {
        const { mtrs, createCdfStatus } = this.props;
        const { showErrorModal, showSuccess, showFilter, selectedMtrs, startDate, endDate } = this.state;
        return (
            <Fragment>
                <Container>
                    <div>
                        <h1 style={{ marginTop: '28px', marginBottom: '15px', fontWeight: 600 }}>
                            {translate('cdf.requestCdf')}
                        </h1>
                        <div className={'custom-cdf-create'}>
                            <span style={{ marginBottom: '15px', fontSize: '18px' }}>
                                {translate('cdf.selectMtr')}
                            </span>
                        </div>
                    </div>
                    <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <Col md={{ size: '6', offset: '3' }}>
                            <SimpleSearchField placeholder={translate('cdf.searchMtr')} onChange={this.onHandleFilterChanged} />
                        </Col>
                        <div className={'mtr-list__items'}>
                            <div className='mtr-list__items--filter' onClick={() => this.showFilter(!showFilter)}>
                                <div className='mtr-list__items--filter-img' />
                                <span>{translate('mtr-list.filter')}</span>
                            </div>
                        </div>
                    </Row>
                    {showFilter && (
                        <Row style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                            {this.renderMtrFilter()}
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <StaticTableBar staticItems={this.tableHeaders} />
                            <div className={'add-mtrs-list__container'}>
                                <div className='add-mtrs-list-item__container'>
                                    {!isEmpty(mtrs) ? mtrs.map(mtr => this.renderRow(mtr)) : <EmptyList />}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '40px' }} />
                        </Col>
                    </Row>
                </Container>
                <div className='mtr-add-mtrs__items--modal'>
                    <Container>
                        <div className='mtr-add-mtrs__items--modal-buttons'>
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col md='5'>
                                    <Pagination
                                        color='primary'
                                        page={this.state.page.page + 1}
                                        count={Math.floor(this.state.page.totalElements / this.state.page.size)}
                                        onChange={(e, page) => this.handleChangePage(page - 1)}
                                    />
                                </Col>
                                <Col md={{ size: 2, offset: 2 }}>
                                    <Button className={'button third'} type={'submit'} onClick={this.onBackHandler}>
                                        <Translate contentKey={'entity.action.cancel'} />
                                    </Button>
                                </Col>
                                <Col md='2'>
                                    <Button
                                        onClick={this.onFinishAdding}
                                        className={'button fourth button-fourth'}
                                        disabled={isEmpty(selectedMtrs) || createCdfStatus === HttpRequestStatus.ONGOING || isEmpty(startDate && endDate)}
                                    >
                                        <Translate contentKey={'cdf.continue'} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <AlertModal
                    hasOneButton
                    showModal={showSuccess}
                    statusImage='garbage.svg'
                    alertMessage='cdf.success'
                    buttonTwoAction={this.onCdfCreatedCorrectly}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                />
                <AlertModal
                    hasOneButton
                    alertMessage='cdf.error'
                    showModal={showErrorModal}
                    statusImage='bag-error.svg'
                    buttonTwoAction={this.toggleErrorModal}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                />
            </Fragment>
        );
    };

    render() {
        return (
            <div className={'mtr-add-mtrs__items'}>
                {this.renderAddPage()}
            </div>
        );
    }
}

const mapStateToProps = (root: IRootState) => ({
    entity: root.cdf.entity,
    mtrs: root.mtr.mtrs,
    totalItems: root.mtr.totalItems,
    producers: root.producer.producers,
    createCdfStatus: root.cdf.createCdfStatus,
    finalDestinations: root.finalDestination.finalDestinations,
    hasManagerAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
    hasProducerAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER]),
    hasFinalDestinationAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER])
});

const mapDispatchToProps = {
    reset,
    findMtr,
    resetCdf,
    createCdf,
    searchMtrs,
    resetStatus,
    searchProducers,
    searchFinalDestinations
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(CustomCdfCreate);

import { IRootState } from 'app/shared/reducers';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import React, { useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import close from '../../../content/images/icons/close.svg'
import './email-modal.scss';

export interface IEmailModalProps extends StateProps, DispatchProps {
    showModal: boolean;
    closeModal: () => void,
    email: string;
}

export interface IEmailodalState { }

export class EmailModal extends React.Component<IEmailModalProps, IEmailodalState> {
    constructor(props: Readonly<IEmailModalProps>) {
        super(props);
        this.state = {
            email: null,
        };
    }

    componentWillUnmount() {
        this.props.showHeader();
    }

    render() {
        const {
            showModal,
            closeModal,
            email,
        } = this.props;

        if (showModal) {
            return (
                <div className={'modal'}>
                    <div className='modal__overlay'>
                        <div className={'modal__box'}>
                            <div className={'modal__close-btn'}>
                                <img src={close} onClick={() => closeModal()} />
                            </div>
                            <div className={'modal__content'}>
                                <div className="image-wrapper">
                                    <img src={`content/images/icons/paper-no.svg`} />
                                </div>
                                <div>
                                    <p><Translate contentKey={'email.emailExists'} /><span className='email-color'>{email}</span><Translate contentKey='email.message' /></p>
                                    <p><Translate contentKey='email.another' /></p>
                                </div>
                                <button className={'modal-btn'}
                                    onClick={() => closeModal()}>
                                    <Translate contentKey={'email.ok'} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = (root: IRootState) => ({
});

const mapDispatchToProps = { hideHeader, showHeader };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmailModal);

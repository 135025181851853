import { IUser } from 'app/shared/model/user.model';
import { ITransporter } from 'app/shared/model/transporter.model';
import { ClassCouncil } from './user-generic.model';
import { IDriver } from './driver.model';

export interface IUserTransporter {
  id?: number;
  registrationNumber?: string;
  classCouncil?: ClassCouncil;
  user?: IUser;
  transporter?: ITransporter;
  driver?: IDriver;
}

export const defaultValue: Readonly<IUserTransporter> = {};

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transporter-register-natural.scss';
import { translate } from 'react-jhipster';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import { validateCPF, validatePersonName, validatePhoneNumber } from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { maskCPF, maskPhone, unmask } from 'app/shared/util/mask-utils';
import { ITransporter } from 'app/shared/model/transporter.model';
import { INaturalPerson } from 'app/shared/model/natural-person.model';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface ITransporterRegisterNaturalProps extends StateProps, DispatchProps {
  transporter: ITransporter;
  onNext: (transporter: ITransporter) => void;
  onBack: () => void;
}

export interface ITransporterRegisterNaturalState {
  cpf: string;
  name: string;
  phone: string;
  cpfError: ValidationResult;
  nameError: ValidationResult;
  phoneError: ValidationResult;
}

export class TransporterRegisterNatural extends React.Component<ITransporterRegisterNaturalProps, ITransporterRegisterNaturalState> {
  constructor(props) {
    super(props);
    const naturalPerson = this.props.transporter.naturalPerson;
    this.state = {
      cpf: naturalPerson ? maskCPF(naturalPerson.cpf) : null,
      name: naturalPerson ? naturalPerson.name : null,
      phone: naturalPerson ? maskPhone(naturalPerson.phone) : null,
      cpfError: ValidationResultInstance,
      nameError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
    };
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateCpf()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateCpf = () => {
    const { cpf } = this.state;
    const validate = validateCPF(cpf);
    this.setState({
      cpfError: validate,
    });
    return validate.hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }
    const { cpf, name, phone } = this.state;
    const { transporter } = this.props;

    const naturalPerson: INaturalPerson = {
      cpf: unmask(cpf),
      name,
      phone: unmask(phone),
    };

    const transporterUpdated: ITransporter = {
      ...transporter,
      naturalPerson,
    };
    this.props.onNext(transporterUpdated);
  };

  render() {
    const { cpf, cpfError, name, nameError, phone, phoneError } = this.state;
    return (
      <div className="transporter-register-natural__items">
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              cpf: text,
            })
          }
          id="cpf"
          style={{ marginTop: '20px' }}
          value={cpf}
          onMask={maskCPF}
          label={translate('transporterRegister.person.natural.form.cpf')}
          placeholder={translate('transporterRegister.person.natural.form.cpf')}
          error={cpfError.hasError}
          errorText={translateErrorMessage(cpfError.errorMessage)}
          onBlur={this.onValidateCpf}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              name: text,
            })
          }
          id="name"
          style={{ marginTop: '20px' }}
          value={name}
          label={translate('transporterRegister.person.natural.form.name')}
          placeholder={translate('transporterRegister.person.natural.form.name')}
          error={nameError.hasError}
          errorText={translateErrorMessage(nameError.errorMessage)}
          onBlur={this.onValidateName}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              phone: text,
            })
          }
          id="phone"
          style={{ marginTop: '20px' }}
          onMask={maskPhone}
          value={phone}
          label={translate('transporterRegister.person.natural.form.phone')}
          placeholder={translate('transporterRegister.person.natural.form.phone')}
          error={phoneError.hasError}
          errorText={translateErrorMessage(phoneError.errorMessage)}
          onBlur={this.onValidatePhone}
        />
        <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterNatural);

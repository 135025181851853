import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransporterRegisterMenu from './menu/transporter-register-menu';

const Routes = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.url}`} component={TransporterRegisterMenu} />
    </Switch>
  </>
);

export default Routes;

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate } from 'react-jhipster';
import './manage-users.scss';
import TableList from '../../generator/unity/table-list';

export const MtrManageUsers = () => {
  return (
    <div className="manage-users__items">
      <div className="manage-users__items--back">
        <Container>
          <Row>
            <Col md="12">
              <div className="confirmation--back">
                <div className="confirmation--back-icon" />
                <span>
                  <Translate contentKey={'mtr-signature.back'} />
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="container">
        <Row>
          <Col md="2">
            <div className="manage-users__items--sidebar">
              <div className="manage-users__items--sidebar-title">
                <Translate contentKey={'profile.personal-data.profile'} />
              </div>
              <div className="manage-users__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.personal-data'} />
              </div>
              <div className="manage-users__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.digital-signature'} />
              </div>
              <div className="manage-users__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.manage-users'} />
              </div>
            </div>
          </Col>
          <Col md="10">
            <div className="manage-users__items--title">
              <Translate contentKey={'profile.manage-users.title'} />
              <button className={'button fourth'} type={'submit'}>
                <Translate contentKey={'profile.manage-users.button'} />
              </button>
            </div>
            <div style={{ marginTop: '20px' }}>
              <TableList />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MtrManageUsers;

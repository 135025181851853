import * as React from 'react';
import {Translate} from 'react-jhipster';

export interface INextCancelButtonProps {
  onCancelClick: () => void;
  onNextClick: () => void;
  cancelButtonName?: string;
  nextButtonName?: string;
  disableNextButton?: boolean;
  disableCancelButton?: boolean;
  noArrow?: boolean;
  noLeftArrow?: boolean;
}

export default class NextCancelButton extends React.Component<INextCancelButtonProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { cancelButtonName, nextButtonName, noArrow, disableNextButton, disableCancelButton, noLeftArrow } = this.props;
    return (
      <div className={'button-container'}>
        <button className={'button third'} type={'submit'} onClick={this.props.onCancelClick}>
          {!noArrow && !noLeftArrow && <div className="back-arrow" />}
          {cancelButtonName ? <span>{cancelButtonName}</span> : <Translate contentKey={'entity.action.back'}>Back</Translate>}
        </button>
        <button className={'button fourth'} type={'submit'} disabled={disableNextButton} onClick={this.props.onNextClick}>
          {nextButtonName ? <span>{nextButtonName}</span> : <Translate contentKey={'entity.action.next'}>Next</Translate>}
          {!noArrow && <div className="next-arrow" />}
        </button>
      </div>
    );
  }
}

import Loading from 'app/components/loading/loading';
import { getEntity, reset } from 'app/entities/vehicle/vehicle.reducer';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { VehicleType } from 'app/shared/model/vehicle.model';

export interface IVehicleDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IVehicleDetailsState {}

export class VehicleDetails extends React.Component<IVehicleDetailsProps, IVehicleDetailsState> {
  constructor(props: Readonly<IVehicleDetailsProps>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getEntity(id);
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {}

  handleGoBack = () => {
    this.props.history.replace('/transporter/transport/vehicles');
  };

  onEditVehicle = (id: number) => {
    this.props.history.push({
      pathname: `/transporter/transport/vehicles/edit`,
      state: { id: id },
    });
  };

  renderComponent = (title: string, info: string) => (
    <div className="list__items--component">
      <div className="list__items--title">{title}</div>
      <div className="list__items--text">{info}</div>
    </div>
  );

  render() {
    const { vehicleDetail, loading } = this.props;
    if (loading) {
      return <Loading />;
    }
    return (
      <div className="list__items">
        <div className="list__items--back">
          <Container>
            <Row>
              <Col md="10">
                <div className="confirmation--back" onClick={this.handleGoBack}>
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              <Col md="2">
                <div className="list__items--edit" onClick={() => this.onEditVehicle(vehicleDetail.id)}>
                  <Translate contentKey={'mtr-signature.edit'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <h1 style={{ fontWeight: 550 }}>{vehicleDetail ? vehicleDetail.name : ''}</h1>
        </div>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('transporter.transport.vehicle.data')}</div>
            {this.renderComponent(translate('transporter.transport.vehicle.info.model'), vehicleDetail ? vehicleDetail.model : '')}
            {this.renderComponent(translate('transporter.transport.vehicle.info.plate'), vehicleDetail ? vehicleDetail.plate : '')}
            {this.renderComponent(translate('transporter.transport.vehicle.info.year'), vehicleDetail ? vehicleDetail.year : '')}
            {vehicleDetail.vehicleType &&
              vehicleDetail.vehicleType !== VehicleType.OTHERS &&
              this.renderComponent(
                translate('transporter.transport.vehicle.info.type'),
                vehicleDetail.vehicleType ? translate(`enums.vehicleType.${vehicleDetail.vehicleType}`) : ''
              )}
            {vehicleDetail.vehicleType &&
              vehicleDetail.vehicleType === VehicleType.OTHERS &&
              this.renderComponent(
                translate('transporter.transport.vehicle.info.type'),
                vehicleDetail.otherVehicleType ? vehicleDetail.otherVehicleType : ''
              )}
            {this.renderComponent(
              translate('transporter.transport.vehicle.info.capacity'),
              vehicleDetail.capacity
                ? `${vehicleDetail.capacity.toString()} ${
                    vehicleDetail.measurementType ? translate(`enums.measurementType.${vehicleDetail.measurementType}`) : ''
                  }`
                : ''
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  vehicleDetail: root.vehicle.entity,
  loading: root.vehicle.loading,
});

const mapDispatchToProps = {
  getEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './select-payment-method.scss';
import { Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import ButtonPayment from '../button-payment/ButtonPayment';
import CreditCard from '../credit-card/CreditCard';
import AddCreditCard from '../credit-card/AddCreditCard';
import { useHistory } from 'react-router-dom';
import ICreditCard from 'app/shared/model/credit-card.model';
import CreditCardService from 'app/services/creditCardService';
import PaymentMethodType from 'app/shared/model/enum/paymentMethodType';
import IBankSlip from 'app/shared/model/bank-slip.model';
import { currentDatePlusTenDays } from 'app/shared/util/date-utils';

export interface IRegisterChooseProps extends StateProps {
    selectedPaymentMethod: PaymentMethodType;
    creditCard: ICreditCard;
    bankSlip: IBankSlip;
    setSelectedPaymentMethod: (paymentMethod: React.SetStateAction<PaymentMethodType>) => void;
    handleAddNewCard: () => void;
    setBankSlip: React.Dispatch<IBankSlip>;
    setCreditCard: React.Dispatch<ICreditCard>;
}

const SelectPaymentMethod = (props: IRegisterChooseProps) => {
    const history = useHistory();

    const [myCreditCards, setMyCreditCards] = useState<ICreditCard[]>();

    useEffect(() => {
        if (props.selectedPaymentMethod === PaymentMethodType.BOLETO) {
            const dueDate = currentDatePlusTenDays();

            props.setCreditCard(null);
            props.setBankSlip({ dueDate });
        } else {
            props.setBankSlip(null);
            getSelectedCard();
        }
    }, [props.selectedPaymentMethod]);

    useEffect(() => {
        getCreditCards();
    }, []);

    useEffect(() => {
        if (myCreditCards != null) {
            getSelectedCard();
        }
    }, [myCreditCards]);

    const getCreditCards = () => {
        CreditCardService.getAllCreditCards().then(setMyCreditCards);
    };

    const handleSelectCreditCard = (creditCard: ICreditCard) => {
        props.setBankSlip(null);

        // TODO: selecionar um cartão de crédito na API
        props.setCreditCard(creditCard);
    };

    const handleDeleteCreditCard = (creditCard: ICreditCard) => {
        CreditCardService.deleteCreditCard(creditCard.id).then(() => {
            getCreditCards();
            props.setCreditCard(null);
        });
    };

    const getSelectedCard = () => {
        myCreditCards?.map((creditCard) => {
            if (creditCard.isDefault) {
                props.setCreditCard(creditCard);
            }
        });
    };

    return (
        <div className='select-payment__container' style={{ position: 'relative' }}>
            <div className='select-payment__container-payment'>
                <Container style={{ maxHeight: 'calc(100vh - 230px)', overflow: 'auto' }}>
                    <Row>
                        <Col style={{ margin: 'auto', position: 'relative' }} md='5' >
                            <div className='select-payment__container-payment--icon-close' onClick={() => history.goBack()} />
                            <div className='select-payment__container-payment--title'>
                                <Translate contentKey={'register.select-payment.title'} />
                                <span className='select-payment__container-payment--title-bold'>
                                    <Translate contentKey={'register.select-payment.title-second'} />
                                </span>
                            </div>

                            <span className='select-payment__container-payment--logged-user'>
                                <Translate contentKey={'register.select-payment.title-three'} />
                                <span>{props.account.email}</span>
                            </span>

                            <div className='select-payment__container-payment--buttons-methods'>
                                <ButtonPayment
                                    isSelect={props.selectedPaymentMethod === PaymentMethodType.BOLETO}
                                    onClick={() => props.setSelectedPaymentMethod(PaymentMethodType.BOLETO)}
                                />
                                <ButtonPayment
                                    isTypeButtonCreditCard
                                    isSelect={props.selectedPaymentMethod === PaymentMethodType.CREDIT_CARD}
                                    onClick={() => props.setSelectedPaymentMethod(PaymentMethodType.CREDIT_CARD)}
                                />
                            </div>

                            {props.selectedPaymentMethod === PaymentMethodType.BOLETO && (
                                <>
                                    <div className='select-payment__container-payment--ticket'>
                                        <Translate contentKey={'register.select-payment.printTicket'} />
                                        <span className='bold'>
                                            <Translate contentKey={'register.select-payment.payBank'} />
                                        </span>
                                        <Translate contentKey={'register.select-payment.or'} />
                                        <span className='bold'>
                                            <Translate contentKey={'register.select-payment.payOnline'} />
                                        </span>
                                        <Translate contentKey={'register.select-payment.usingBarcode'} />
                                    </div>

                                    <div className='select-payment__container-payment--ticket' style={{ marginTop: '4px' }}>
                                        <Translate contentKey={'register.select-payment.deadlinePaymentTicket'} />
                                        <span className='bold'>{`${props.bankSlip?.dueDate}.`}</span>
                                    </div>
                                </>
                            )}

                            {props.selectedPaymentMethod === PaymentMethodType.CREDIT_CARD && (
                                <div className='select-payment__container-payment--credit-card'>
                                    <div className='select-payment__container-payment--credit-card-list'>
                                        {
                                            myCreditCards?.map((creditCard) => (
                                                <CreditCard
                                                    key={`credit-card-${creditCard.id}`}
                                                    isSelected={creditCard.id === props.creditCard?.id}
                                                    creditCard={creditCard}
                                                    handleSelectCard={() => handleSelectCreditCard(creditCard)}
                                                    handleDeleteCard={() => handleDeleteCreditCard(creditCard)}
                                                />
                                            ))
                                        }
                                    </div>

                                    <AddCreditCard handleAddCard={props.handleAddNewCard} />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    account: authentication.account,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(SelectPaymentMethod);
import { ICountry } from 'app/shared/model/country.model';

export interface IState {
  id?: number;
  name?: string;
  acronym?: string;
  active?: boolean;
  country?: ICountry;
}

export const defaultValue: Readonly<IState> = {
  active: false,
};

import { IRootState } from 'app/shared/reducers';
import { changeAuthority, getSession, setCreateNewProfile } from 'app/shared/reducers/authentication';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './manage-profiles.scss';
import { ColumnSort } from 'app/components/simple-ordered-table/simple-ordered-table';
import { Pageable } from 'app/shared/model/pageable';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { deleteUser, getUsersByEntity, reset, activateDeactivateProfile, getProfilesStatus } from '../profile-reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import DestinoFinalImg from '../../../../../content/images/icons/destino-final.svg';
import GeradorImg from '../../../../../content/images/icons/gerador-verde.svg';
import GerenciadorImg from '../../../../../content/images/icons/gerenciador-verde.svg';
import TransbordoImg from '../../../../../content/images/icons/transbordo-verde.svg';
import TransportadorImg from '../../../../../content/images/icons/transportador-verde.svg';
import AlertModal from 'app/components/alert-modal/alert-modal';

export interface IManagerProfilesProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IManagerProfilesState {
  page: Pageable;
  showDeleteModal: boolean;
  deleteItemId: number;
  modalItem: string;
  imagePath: string;
  isModalOpen: boolean;
}

export class ManagerProfiles extends React.Component<IManagerProfilesProps, IManagerProfilesState> {
  timer = null;

  constructor(props: Readonly<IManagerProfilesProps>) {
    super(props);
    this.state = {
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      showDeleteModal: false,
      deleteItemId: null,
      modalItem: null,
      imagePath: "", 
      isModalOpen: false
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getUsersByEntity();
    this.props.getProfilesStatus();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS && newProps.deleteStatus !== this.props.deleteStatus) {
      this.closeDeleteModal();
      this.props.getUsersByEntity();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getUsersByEntity = () => {
    const { page } = this.state;
    this.props.getUsersByEntity(page.page, page.size, page.sort);
  };

  onCreateUser = () => {
    setCreateNewProfile(true);
    this.props.history.push(`/full-register/choose`);
  };

  onEditUser = (profileType: string) => {
    this.props.history.push({
      pathname: `/full-register/${profileType}/edit`
    });
  };

  onUserClick = (authority: string) => {
    this.props.changeAuthority(authority);
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      deleteItemId: null,
    });
  };

  cancelModal = () => {
    this.setState({
      isModalOpen: false
    });
  }

  openModal = (authorities: string, imagePath: string) => {
    this.setState({
      modalItem: authorities,
      imagePath: imagePath,
      isModalOpen: true
    });
  }

  activateDeactivate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, profileType: string, profile) => {
    e.stopPropagation();
    this.props.activateDeactivateProfile(profileType, profile.id);
    profile.active = !profile.active;
  }

  renderCard = (authority, adminAuthority, icon, styleAuthority, srcImage, cardName, profileType) => {
    return (
      authority &&
        <div className="card__manage-profiles" onClick={() => profileType.active && this.openModal(adminAuthority, `../../../../../content/images/icons/${icon}.svg`)}
        style={styleAuthority ? {pointerEvents: "none", opacity: "0.5"} : {}}>
          <div style={{ display:'flex', alignItems:'center', flex:'1 1 50%'}}>
            <img src={srcImage}/>
            <div className="card__manage-profiles--text">
            <Translate contentKey={`profile.manage-profiles.${cardName}`} />
            </div>
          </div>
          <div style={{ display:'flex', alignItems:'center', flex:'1 1 50%', justifyContent:'flex-end'}}>
            <div className={`card__manage-profiles--toggle-${profileType?.active ? "on" : "off"}`} onClick={e => this.activateDeactivate(e, cardName, profileType)} />
            <div className="card__manage-profiles--rename" onClick={() => this.onEditUser(cardName)} />
          </div>
        </div>
    )
  }

  render() {
    const { hasFinalDestinationAuthority, 
      isAtFinalDestinationAuthority, 
      hasProducerAuthority,
      isAtProducerAuthority,
      hasManagerAuthority,
      isAtManagerAuthority,
      hasTransshipmentAuthority,
      isAtTransshipmentAuthority,
      hasTransporterAuthority,
      isAtTransporterAuthority,
      profiles } = this.props;

    return (
      <div>
        <div className="manage-users__items--title">
          <Translate contentKey={'profile.personal-data.manage-profile'} />
          <button style={{ width: '218px', padding: '0 15px', margin: '0' }} className={'button fourth'} type={'submit'}
          onClick={this.onCreateUser}>
            <Translate contentKey={'profile.manage-profiles.button'} />
          </button>
        </div>
        <div style={{ marginTop: '20px' }}>
        {
        this.renderCard(hasFinalDestinationAuthority, AUTHORITIES.FINAL_DESTINATION_ADMIN, 
        'destino-final', isAtFinalDestinationAuthority, DestinoFinalImg, 'final-destination', profiles.userFinalDestination)
        }
        {
        this.renderCard(hasProducerAuthority, AUTHORITIES.PRODUCER_ADMIN, 
        'gerador-verde', isAtProducerAuthority, GeradorImg, 'producer', profiles.userProducer)
        }
        {
        this.renderCard(hasManagerAuthority, AUTHORITIES.MANAGER_ADMIN, 
        'gerenciador-verde', isAtManagerAuthority, GerenciadorImg, 'manager', profiles.userManager)
        }
        {
        this.renderCard(hasTransshipmentAuthority, AUTHORITIES.TRANSSHIPMENT_ADMIN, 
        'transbordo', isAtTransshipmentAuthority, TransbordoImg, 'transshipment', profiles.userTransshipment)
        }
        {
        this.renderCard(hasTransporterAuthority, AUTHORITIES.TRANSPORTER_ADMIN, 
        'transportador-verde', isAtTransporterAuthority, TransportadorImg,'transporter', profiles.userTransporter)
        }
        </div>
        <AlertModal
              showModal={this.state.isModalOpen ? true : false}
              buttonOneActionMessage={'mtr-signature.button-no'}
              buttonTwoActionMessage={'mtr-signature.button-yes'}
              hasTwoButtons
              buttonTwoAction={() => {
                this.onUserClick(this.state.modalItem)
                this.cancelModal()
              }}
              buttonOneAction={() => {
                this.cancelModal()
              }}
              statusImage={this.state.imagePath}
              alertMessage="profile.manage-profiles.modal"
              params={translate(`mtr-signature.${this.state.modalItem}`)}
          />
      </div>
     )
    }
}

const mapStateToProps = (root: IRootState) => ({
  fetchSignatureStatus: root.profile.fetchSignatureStatus,
  deleteStatus: root.profile.deleteUserStatus,
  users: root.profile.users,
  profiles: root.profile.profiles,
  profilesStatus: root.profile.profilesStatus,
  totalItems: root.profile.totalItems,
  account: root.authentication.account,
  hasManagerAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
  hasProducerAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]),
  hasTransporterAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSPORTER_ADMIN, AUTHORITIES.TRANSPORTER_USER]),
  hasTransshipmentAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER]),
  hasFinalDestinationAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER]),
  isAtManagerAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
  isAtProducerAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]),
  isAtTransporterAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSPORTER_ADMIN, AUTHORITIES.TRANSPORTER_USER]),
  isAtTransshipmentAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER]),
  isAtFinalDestinationAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER]),
});

const mapDispatchToProps = {
  getSession,
  getUsersByEntity,
  activateDeactivateProfile,
  deleteUser,
  reset,
  setCreateNewProfile,
  changeAuthority,
  getProfilesStatus
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerProfiles);
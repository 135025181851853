import { AxiosError } from 'axios';
import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';

const toastUtils = () => {
    const handleErrorMessage = (error: AxiosError) => {
        const messageError = error.response?.data?.message.split('=');
        const formatMessage = messageError[1]?.replace('}', '');

        return formatMessage ?? translate('errorMessages.errorOperation');
    };

    const emitErrorToast = (error: AxiosError) => toast.error(handleErrorMessage(error));

    return {
        emitErrorToast,
    };
};

export default toastUtils();
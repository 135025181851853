import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './signature.reducer';

// tslint:disable-next-line:no-unused-variable

export interface ISignatureProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class Signature extends React.Component<ISignatureProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { signatureList, match } = this.props;
    return (
      <div>
        <h2 id="signature-heading">
          <Translate contentKey="resitrackApp.signature.home.title">Signatures</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="resitrackApp.signature.home.createLabel">Create new Signature</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {signatureList && signatureList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.signature.signatureDocument">Signature Document</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.signature.user">User</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {signatureList.map((signature, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${signature.id}`} color="link" size="sm">
                        {signature.id}
                      </Button>
                    </td>
                    <td>
                      {signature.signatureDocument ? (
                        <Link to={`document/${signature.signatureDocument.id}`}>{signature.signatureDocument.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{signature.user ? signature.user.login : ''}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${signature.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${signature.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${signature.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="resitrackApp.signature.home.notFound">No Signatures found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ signature }: IRootState) => ({
  signatureList: signature.entities,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Signature);

import { ICity } from './city.model';

export interface IUnit {
  id?: number;
  address: {
    id?: number;
    description: string;
    district: string;
    street: string;
    name: string;
    number: string;
    zipcode: string;
    complement: string;
    city?: ICity;
  };
  producer?: { id: number };
  fantasyName: string;
  unitCode: string;
  cnpj?: string;
}

export interface UnitProducerOrFinalDestination {
  name: string;
  unitCode: string;
  unitAddressLabel: string;
  unitZipCode: string;
  cnpj?: string;
}

export interface ProducerUnit {
  id: string;
  cnpj?: string;
}

export interface TransporterUnit {
  name: string;
  unitCode: string;
  unitAddressLabel: string;
  unitZipCode: string;
  cnpj?: string;
}

export interface TransshipmentUnit {
  name: string;
  unitCode: string;
  unitAddressLabel: string;
  unitZipCode: string;
  cnpj?: string;
}

export const MOCK_UNIT = {
  // id: 17,
  unitCode: '63721',
  fantasyName: 'teste',
  cnpj: 'teste',
  cpf: 'teste',
  login: 'teste',
  password: 'teste',
  type: 'FEPAM',
  address: {
    street: 'teste',
    name: 'teste',
    description: 'teste',
    number: 'teste',
    zipcode: 'teste',
    district: 'teste',
    complement: 'teste',
    city: {
      id: 1,
    },
  },
};

export function mapUnitToSearch(units) {
  if (units.length > 0) {
    const objects = units.map(it => {
      let unitAddressLabelValue = it.address.street;
      if (it.address.complement) {
        unitAddressLabelValue = unitAddressLabelValue + ', ' + it.address.complement;
      }
      unitAddressLabelValue = unitAddressLabelValue + '. ' + it.address.city.name + '/' + it.address.city.state.name;
      return {
        name: it.fantasyName ?? '',
        unitCode: it.unitCode ?? '',
        id: it.id,
        unitAddressLabel: unitAddressLabelValue,
        unitZipCode: it.address.zipcode,
      };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  } else {
    return [];
  }
}

export function mapUnitToSearchUserIntegration(units) {
  if (units.length > 0) {
    const objects = units.map(it => {
      let unitAddressLabelValue = it.address.street;
      if (it.address.complement) {
        unitAddressLabelValue = unitAddressLabelValue + ', ' + it.address.complement;
      }
      unitAddressLabelValue = unitAddressLabelValue + '. ' + it.address.city.name + '/' + it.address.city.state.name;

      const name = `${it.unitCode} - ${it.fantasyName || ''}`; // Criando o label desejado

      return {
        name,
        value: it.id, // Adicione o valor que deseja associar ao item (por exemplo, o ID)
        unitCode: it.unitCode ?? '',
        id: it.id,
        unitAddressLabel: unitAddressLabelValue,
        unitZipCode: it.address.zipcode,
      };
    });

    return objects.sort((a, b) => a.name.localeCompare(b.name)); // Ordenar pelo label
  } else {
    return [];
  }
}

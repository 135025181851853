import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { byteSize, openFile, setFileData, translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, setBlob, updateEntity } from './document.reducer';

export interface IDocumentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IDocumentUpdateState {
  isNew: boolean;
}

export class DocumentUpdate extends React.Component<IDocumentUpdateProps, IDocumentUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { documentEntity } = this.props;
      const entity = {
        ...documentEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/document');
  };

  render() {
    const { documentEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const { file, fileContentType } = documentEntity;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.document.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.document.home.createOrEditLabel">Create or edit a Document</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : documentEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="document-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="document-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="urlLabel" for="document-url">
                    <Translate contentKey="resitrackApp.document.url">Url</Translate>
                  </Label>
                  <AvField id="document-url" type="text" name="url" />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="document-description">
                    <Translate contentKey="resitrackApp.document.description">Description</Translate>
                  </Label>
                  <AvField id="document-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label id="fileNameLabel" for="document-fileName">
                    <Translate contentKey="resitrackApp.document.fileName">File Name</Translate>
                  </Label>
                  <AvField
                    id="document-fileName"
                    type="text"
                    name="fileName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="nameLabel" for="document-name">
                    <Translate contentKey="resitrackApp.document.name">Name</Translate>
                  </Label>
                  <AvField id="document-name" type="text" name="name" />
                </AvGroup>
                <AvGroup>
                  <Label id="s3NameLabel" for="document-s3Name">
                    <Translate contentKey="resitrackApp.document.s3Name">S 3 Name</Translate>
                  </Label>
                  <AvField id="document-s3Name" type="text" name="s3Name" />
                </AvGroup>
                <AvGroup>
                  <Label id="processedLabel" check>
                    <AvInput id="document-processed" type="checkbox" className="form-control" name="processed" />
                    <Translate contentKey="resitrackApp.document.processed">Processed</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <AvGroup>
                    <Label id="fileLabel" for="file">
                      <Translate contentKey="resitrackApp.document.file">File</Translate>
                    </Label>
                    <br />
                    {file ? (
                      <div>
                        <a onClick={openFile(fileContentType, file)}>
                          <Translate contentKey="entity.action.open">Open</Translate>
                        </a>
                        <br />
                        <Row>
                          <Col md="11">
                            <span>
                              {fileContentType}, {byteSize(file)}
                            </span>
                          </Col>
                          <Col md="1">
                            <Button color="danger" onClick={this.clearBlob('file')}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <input id="file_file" type="file" onChange={this.onBlobChange(false, 'file')} />
                    <AvInput type="hidden" name="file" value={file} />
                  </AvGroup>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/document" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  documentEntity: storeState.document.entity,
  loading: storeState.document.loading,
  updating: storeState.document.updating,
  updateSuccess: storeState.document.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpdate);

import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import {Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getResidues} from 'app/entities/residue/residue.reducer';
import {getEntities as getSpecificResidues} from 'app/entities/specific-residue/specific-residue.reducer';
import {createEntity, getEntity, reset, updateEntity} from './source-residue.reducer';

// tslint:disable-next-line:no-unused-variable

export interface ISourceResidueUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISourceResidueUpdateState {
  isNew: boolean;
  residueId: string;
  specificResidueId: string;
}

export class SourceResidueUpdate extends React.Component<ISourceResidueUpdateProps, ISourceResidueUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      residueId: '0',
      specificResidueId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getResidues();
    this.props.getSpecificResidues();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { sourceResidueEntity } = this.props;
      const entity = {
        ...sourceResidueEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/source-residue');
  };

  render() {
    const { sourceResidueEntity, residues, specificResidues, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.sourceResidue.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.sourceResidue.home.createOrEditLabel">Create or edit a SourceResidue</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : sourceResidueEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="source-residue-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="source-residue-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="sourceIdLabel" for="source-residue-sourceId">
                    <Translate contentKey="resitrackApp.sourceResidue.sourceId">Source Id</Translate>
                  </Label>
                  <AvField
                    id="source-residue-sourceId"
                    type="string"
                    className="form-control"
                    name="sourceId"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="sourceTypeLabel" for="source-residue-sourceType">
                    <Translate contentKey="resitrackApp.sourceResidue.sourceType">Source Type</Translate>
                  </Label>
                  <AvInput
                    id="source-residue-sourceType"
                    type="select"
                    className="form-control"
                    name="sourceType"
                    value={(!isNew && sourceResidueEntity.sourceType) || 'PRODUCER'}
                  >
                    <option value="PRODUCER">{translate('resitrackApp.ResidueSourceType.PRODUCER')}</option>
                    <option value="MANAGER">{translate('resitrackApp.ResidueSourceType.MANAGER')}</option>
                    <option value="TRANSPORTER">{translate('resitrackApp.ResidueSourceType.TRANSPORTER')}</option>
                    <option value="FINAL_DESTINATION">{translate('resitrackApp.ResidueSourceType.FINAL_DESTINATION')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="source-residue-residue">
                    <Translate contentKey="resitrackApp.sourceResidue.residue">Residue</Translate>
                  </Label>
                  <AvInput
                    id="source-residue-residue"
                    type="select"
                    className="form-control"
                    name="residue.id"
                    value={isNew ? residues[0] && residues[0].id : sourceResidueEntity.residue.id}
                    required
                  >
                    {residues
                      ? residues.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="source-residue-specificResidue">
                    <Translate contentKey="resitrackApp.sourceResidue.specificResidue">Specific Residue</Translate>
                  </Label>
                  <AvInput
                    id="source-residue-specificResidue"
                    type="select"
                    className="form-control"
                    name="specificResidue.id"
                    value={isNew ? specificResidues[0] && specificResidues[0].id : sourceResidueEntity.specificResidue.id}
                    required
                  >
                    {specificResidues
                      ? specificResidues.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/source-residue" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  residues: storeState.residue.entities,
  specificResidues: storeState.specificResidue.entities,
  sourceResidueEntity: storeState.sourceResidue.entity,
  loading: storeState.sourceResidue.loading,
  updating: storeState.sourceResidue.updating,
  updateSuccess: storeState.sourceResidue.updateSuccess,
});

const mapDispatchToProps = {
  getResidues,
  getSpecificResidues,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SourceResidueUpdate);

import { ICity } from 'app/shared/model/city.model';
import { IState } from 'app/shared/model/state.model';
import { Moment } from 'moment';
import { Transshipment } from 'app/shared/model/transshipment.model';
import { IDocument } from 'app/shared/model/document.model';

export enum StorageLicenseType {
  PRELIMINARY = 'PRELIMINARY',
  INSTALLATION = 'INSTALLATION',
  OPERATION = 'OPERATION',
  DISMISS = 'DISMISS',
  AUTHORIZATION = 'AUTHORIZATION',
  SIMPLIFIED = 'SIMPLIFIED',
}

export interface StorageLicense {
  id?: number;
  city?: ICity;
  state?: IState;
  issuingBody?: string;
  licenseNumber?: string;
  licenseDocument?: IDocument;
  transshipment?: Transshipment;
  expireDate?: string | Date | Moment;
  storageLicenseType?: StorageLicenseType;
}

export const defaultValue: Readonly<StorageLicense> = {};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './legal-person.reducer';

export interface ILegalPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class LegalPersonDetail extends React.Component<ILegalPersonDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { legalPersonEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.legalPerson.detail.title">LegalPerson</Translate> [<b>{legalPersonEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="cnpj">
                <Translate contentKey="resitrackApp.legalPerson.cnpj">Cnpj</Translate>
              </span>
            </dt>
            <dd>{legalPersonEntity.cnpj}</dd>
            <dt>
              <span id="fantasyName">
                <Translate contentKey="resitrackApp.legalPerson.fantasyName">Fantasy Name</Translate>
              </span>
            </dt>
            <dd>{legalPersonEntity.fantasyName}</dd>
            <dt>
              <span id="companyName">
                <Translate contentKey="resitrackApp.legalPerson.companyName">Company Name</Translate>
              </span>
            </dt>
            <dd>{legalPersonEntity.companyName}</dd>
            <dt>
              <span id="phone">
                <Translate contentKey="resitrackApp.legalPerson.phone">Phone</Translate>
              </span>
            </dt>
            <dd>{legalPersonEntity.phone}</dd>
            <dt>
              <span id="municipalRegistration">
                <Translate contentKey="resitrackApp.legalPerson.municipalRegistration">Municipal Registration</Translate>
              </span>
            </dt>
            <dd>{legalPersonEntity.municipalRegistration}</dd>
          </dl>
          <Button tag={Link} to="/entity/legal-person" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/legal-person/${legalPersonEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ legalPerson }: IRootState) => ({
  legalPersonEntity: legalPerson.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LegalPersonDetail);

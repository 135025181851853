import { Checkbox } from '@material-ui/core';
import ICreditCard from 'app/shared/model/credit-card.model';
import React from 'react';
import { Translate } from 'react-jhipster';
import './select-credit-card.scss';

interface Props {
    creditCard: ICreditCard;
    isSelected: boolean;
    handleSelectCard: () => void;
    handleDeleteCard: () => void;
}

const CreditCard = ({ creditCard, isSelected, handleSelectCard, handleDeleteCard }: Props) => {
    return (
        <div className={`credit-card-container${isSelected ? '-active' : ''}`}>
            <div className='credit-card-container--card'>
                <div className='credit-card-container--icon-credit-card' />
                <div className='credit-card-container--card-data'>
                    <span className='credit-card-container--card-credit'>
                        <Translate contentKey={'register.select-payment.creditCard'} />
                    </span>
                    {`${creditCard.brand} ${creditCard.lastFourDigits}`}
                </div>
            </div>

            <div className='credit-card-container--icon-trash' onClick={handleDeleteCard} />
            <Checkbox
                className='icon-check'
                icon={<img src={'content/images/icons/check-gray.svg'} alt='imagem cartão não selecionado' />}
                checkedIcon={<img src={'content/images/icons/check-green.svg'} alt='imagem cartão selecionado' />}
                checked={isSelected}
                onClick={handleSelectCard}
            />
        </div>
    );
};

export default CreditCard;

import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CnaeCode from './cnae-code';
import CnaeCodeDetail from './cnae-code-detail';
import CnaeCodeUpdate from './cnae-code-update';
import CnaeCodeDeleteDialog from './cnae-code-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CnaeCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CnaeCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CnaeCodeDetail} />
      <ErrorBoundaryRoute path={match.url} component={CnaeCode} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CnaeCodeDeleteDialog} />
  </>
);

export default Routes;

import AlertModal from 'app/components/alert-modal/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import InlineSelect from 'app/components/inline-select/inline-select';
import Loading from 'app/components/loading/loading';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import { ALERT_ICONS } from 'app/config/constants';
import { validateGenericField, validateObject } from 'app/modules/validation/validation-constants';
import { MeasurementType } from 'app/shared/model/authorization.model';
import { IVehicle, VehicleType } from 'app/shared/model/vehicle.model';
import { IRootState } from 'app/shared/reducers';
import { maskAlphaNumeric, maskNumber } from 'app/shared/util/mask-utils';
import { InvalidReulst, translateErrorMessage, ValidationResult, ValidationResultInstance, ValidResult } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './vehicle-edit.scss';
import {
    createEntity as createVehicle,
    getEntity as getVehicleDetail,
    reset,
    updateEntity as updateVehicle,
} from 'app/entities/vehicle/vehicle.reducer';
import { CodeOutlined } from '@material-ui/icons';
import moment from 'moment';

export interface IVehicleEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IVehicleEditState {
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
  isNew: boolean;
  model: string;
  plate: string;
  year: string;
  vehicleType: VehicleType;
  otherVehicleType: string;
  capacity: string;
  measurementType: MeasurementType;
  modelError: ValidationResult;
  plateError: ValidationResult;
  yearError: ValidResult | InvalidReulst;
  vehicleTypeError: ValidationResult;
  otherVehicleTypeError: ValidationResult;
  capacityError: ValidationResult;
  measurementTypeError: ValidationResult;
}

export class VehicleEdit extends React.Component<IVehicleEditProps, IVehicleEditState> {
  constructor(props: Readonly<IVehicleEditProps>) {
    super(props);
    this.state = {
      showErrorModal: false,
      showResponsabilityModal: false,
      isNew: null,
      otherVehicleType: null,
      model: null,
      plate: null,
      year: null,
      vehicleType: null,
      capacity: null,
      measurementType: null,
      measurementTypeError: ValidationResultInstance,
      capacityError: ValidationResultInstance,
      vehicleTypeError: ValidationResultInstance,
      plateError: ValidationResultInstance,
      modelError: ValidationResultInstance,
      yearError: ValidationResultInstance,
      otherVehicleTypeError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getVehicleDetail(id);
    } else {
      this.setState({
        isNew: true,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.vehicleDetail != null && newProps.vehicleDetail != this.props.vehicleDetail) {
      this.mapVehicleToField(newProps.vehicleDetail);
    }

    if (newProps.createSuccess) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    }

    if (newProps.updateSuccess) {
      this.goToSuccessUpdateAlertPage();
      this.props.reset();
    }

    if (newProps.createError) {
      this.toggleErrorModal();
    }

    if (newProps.updateError) {
      this.toggleErrorModal();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  mapVehicleToField = (vehicle: IVehicle) => {
    this.setState({
      model: vehicle.model,
      plate: vehicle.plate,
      year: vehicle.year,
      vehicleType: vehicle.vehicleType,
      measurementType: vehicle.measurementType,
      capacity: vehicle?.capacity?.toString(),
      otherVehicleType: vehicle.otherVehicleType,
    });
  };

  toggleResponsabilityModal = () => {
    this.setState({
      showResponsabilityModal: !this.state.showResponsabilityModal,
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateModel()) {
      hasError = true;
    }
    if (this.onValidateYear()) {
      hasError = true;
    }
    if (this.onValidatePlate()) {
      hasError = true;
    }
    if (this.onValidateMeasurementType()) {
      hasError = true;
    }
    if (this.onValidateVehicleType()) {
      hasError = true;
    }
    if (this.onValidateCapacity()) {
      hasError = true;
    }
    if (this.state.vehicleType === VehicleType.OTHERS && this.onValidateOtherVehicleType()) {
      hasError = true;
    }

    return hasError;
  };

  onValidateOtherVehicleType = () => {
    const { otherVehicleType } = this.state;
    const validate = validateGenericField(otherVehicleType);
    this.setState({
      otherVehicleTypeError: validate,
    });
    return validate.hasError;
  };

  onValidatePlate = () => {
    const { plate } = this.state;
    const validate = validateGenericField(plate);
    this.setState({
      plateError: validate,
    });
    return validate.hasError;
  };

  validateYear = (year: string) => {
    if(year !== undefined && moment().isSameOrAfter(year, 'year')){
      return {
        hasError: false,
        errorMessage: undefined
      }
    } else{
      return {
        hasError: true,
        errorMessage: "transporter.transport.vehicle.form.valid_year"
      }
    }
  }

  onValidateYear = () => {
    const { year } = this.state;
    const validate = this.validateYear(year);
    this.setState({
      yearError: validate,
    });
    return validate.hasError;
  };

  onValidateCapacity = () => {
    const { capacity } = this.state;
    const validate = validateGenericField(capacity);
    this.setState({
      capacityError: validate,
    });
    return validate.hasError;
  };

  onValidateVehicleType = () => {
    const { vehicleType } = this.state;
    const validate = validateObject(vehicleType);
    this.setState({
      vehicleTypeError: validate,
    });
    return validate.hasError;
  };

  onValidateMeasurementType = () => {
    const { measurementType } = this.state;
    const validate = validateObject(measurementType);
    this.setState({
      measurementTypeError: validate,
    });
    return validate.hasError;
  };

  onValidateModel = () => {
    const { model } = this.state;
    const validate = validateGenericField(model);
    this.setState({
      modelError: validate,
    });
    return validate.hasError;
  };

  mapVehicleTypeToOptions = () => {
    const optionsList = [];
    Object.keys(VehicleType).forEach((item, index) => {
      optionsList.push({ label: translate(`enums.vehicleType.${item}`), value: item });
    });
    return optionsList;
  };

  onSelectVehicleType = (item: any) => {
    if (item !== VehicleType.OTHERS) {
      this.setState({
        otherVehicleType: null,
      });
    }

    this.setState(
      {
        vehicleType: item,
      },
      () => this.onValidateVehicleType()
    );
  };

  mapSelectedVehicleTypeToSelect = () => {
    const { vehicleType } = this.state;

    return vehicleType ? { label: translate(`enums.vehicleType.${vehicleType}`), value: vehicleType } : null;
  };

  mapMearumentTypeTypeToOptions = () => {
    const optionsList = [];
    [MeasurementType.TONS, MeasurementType.LITERS, MeasurementType.CUBIC_METERS].forEach((item, index) => {
      optionsList.push({ label: translate(`enums.measurementType.${item}`), value: item });
    });
    return optionsList;
  };

  onSelectMeasurementType = (item: any) => {
    this.setState(
      {
        measurementType: item,
      },
      () => this.onValidateVehicleType()
    );
  };

  mapSelectedMeasurementTypeToSelect = () => {
    const { measurementType } = this.state;

    return measurementType ? { label: translate(`enums.measurementType.${measurementType}`), value: measurementType } : null;
  };

  onStartSaving = () => {
    if (this.onValidateAll()) {
      return;
    }

    this.toggleResponsabilityModal();
  };

  onSaveHandler = () => {
    const { vehicleDetail } = this.props;
    const { plate, year, model, capacity, measurementType, vehicleType, otherVehicleType } = this.state;

    const vehicle: IVehicle = {
      ...vehicleDetail,
      plate,
      year,
      model,
      capacity: Number(capacity),
      measurementType,
      vehicleType,
      otherVehicleType,
    };

    const { isNew } = this.state;
    if (isNew) {
      this.props.createVehicle(vehicle);
    } else {
      this.props.updateVehicle(vehicle);
    }
  };

  onBackHandler = () => {
    this.props.history.replace('/transporter/transport/vehicles');
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.vehicleRegister',
        buttonUrl: '/transporter/transport/vehicles',
      },
    });
  };

  goToSuccessUpdateAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.vehicleUpdate',
        buttonUrl: '/transporter/transport/vehicles',
      },
    });
  };

  renderForm = () => {
    const {
      model,
      modelError,
      capacity,
      capacityError,
      otherVehicleType,
      otherVehicleTypeError,
      vehicleType,
      year,
      yearError,
      plate,
      plateError,
      measurementTypeError,
      vehicleTypeError,
    } = this.state;

    return (
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                model: text,
              })
            }
            id="model"
            style={{ marginTop: '20px' }}
            value={model}
            label={translate('transporter.transport.vehicle.form.model')}
            placeholder={translate('transporter.transport.vehicle.form.model')}
            error={modelError.hasError}
            errorText={translateErrorMessage(modelError.errorMessage)}
            onBlur={this.onValidateModel}
          />
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                plate: text,
              })
            }
            id="plate"
            style={{ marginTop: '20px' }}
            value={plate}
            label={translate('transporter.transport.vehicle.form.plate')}
            placeholder={translate('transporter.transport.vehicle.form.plate')}
            error={plateError.hasError}
            errorText={translateErrorMessage(plateError.errorMessage)}
            onBlur={this.onValidatePlate}
          />
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                year: text,
              })
            }
            id="year"
            onMask={maskNumber}
            style={{ marginTop: '20px' }}
            value={year}
            label={translate('transporter.transport.vehicle.form.year')}
            placeholder={translate('transporter.transport.vehicle.form.year')}
            error={yearError.hasError}
            errorText={translateErrorMessage(yearError.errorMessage)}
            onBlur={this.onValidateYear}
          />
          <InlineSelect
            placeholder={translate('transporter.transport.vehicle.form.type')}
            error={vehicleTypeError.hasError}
            errorMessage={translateErrorMessage(vehicleTypeError.errorMessage)}
            options={this.mapVehicleTypeToOptions()}
            onChange={this.onSelectVehicleType}
            startIndex={0}
            selectedOption={this.mapSelectedVehicleTypeToSelect()}
          />
          {vehicleType && vehicleType === VehicleType.OTHERS && (
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  otherVehicleType: text,
                })
              }
              id="otherVehicleType"
              style={{ marginTop: '20px' }}
              value={otherVehicleType}
              onMask={maskAlphaNumeric}
              label={translate('transporter.transport.vehicle.form.otherVehicleType')}
              placeholder={translate('transporter.transport.vehicle.form.otherVehicleType')}
              error={otherVehicleTypeError.hasError}
              errorText={translateErrorMessage(otherVehicleTypeError.errorMessage)}
              onBlur={this.onValidateOtherVehicleType}
            />
          )}
          <Row>
            <Col md="8">
              <CustomTextField
                onChange={(text: string) =>
                  this.setState({
                    capacity: text,
                  })
                }
                id="capacity"
                style={{ marginTop: '20px' }}
                value={capacity}
                onMask={maskNumber}
                label={translate('transporter.transport.vehicle.form.capacity')}
                placeholder={translate('transporter.transport.vehicle.form.capacity')}
                error={capacityError.hasError}
                errorText={translateErrorMessage(capacityError.errorMessage)}
                onBlur={this.onValidateCapacity}
              />
            </Col>
            <Col>
              <InlineSelect
                placeholder={translate('transporter.transport.vehicle.form.measurementType')}
                error={measurementTypeError.hasError}
                errorMessage={translateErrorMessage(measurementTypeError.errorMessage)}
                options={this.mapMearumentTypeTypeToOptions()}
                onChange={this.onSelectMeasurementType}
                startIndex={0}
                selectedOption={this.mapSelectedMeasurementTypeToSelect()}
              />
            </Col>
          </Row>
          <NextCancelButton
            onCancelClick={this.onBackHandler}
            onNextClick={this.onStartSaving}
            nextButtonName={translate('entity.action.save')}
            noArrow
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { loading, vehicleDetail } = this.props;
    const { isNew } = this.state;
    if (loading) {
      return <Loading />;
    }
    return (
      <div>
        <div style={{ paddingTop: '70px' }}>
          <BackHeaderComponent hasButton={false} onBack={this.onBackHandler} />
          <h1>{translate('mtr-transporter.driver.vehicle.title')}</h1>
          {isNew && this.renderForm()}
          {!isNew && vehicleDetail && vehicleDetail.transporter && this.renderForm()}

          <AlertModal
            showModal={this.state.showResponsabilityModal}
            buttonOneActionMessage={'entity.action.cancel'}
            buttonTwoActionMessage={'entity.action.agree'}
            hasTwoButtons
            buttonTwoAction={this.onSaveHandler}
            buttonOneAction={this.toggleResponsabilityModal}
            statusImage="paper-question-mark.svg"
            alertMessage="alert.responsibilityTerms"
          />

          <AlertModal
            showModal={this.state.showErrorModal}
            buttonTwoActionMessage={'entity.action.ok'}
            buttonTwoAction={this.toggleErrorModal}
            statusImage="bag-error.svg"
            alertMessage={this.state.isNew ? 'errorMessages.vehicleRegister' : 'errorMessages.vehicleUpdate'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  vehicleDetail: root.vehicle.entity,
  loading: root.vehicle.loading,
  createError: root.vehicle.createError,
  createSuccess: root.vehicle.createSuccess,
  updateSuccess: root.vehicle.updateSuccess,
  updateError: root.vehicle.updateError,
});

const mapDispatchToProps = {
  getVehicleDetail,
  createVehicle,
  updateVehicle,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleEdit);

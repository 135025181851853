import React from 'react';
import { NavLink } from 'react-router-dom';

export interface ItemProps {
    show?: boolean;
    to: string;
    title: string;
    style?: React.CSSProperties;
    className?: string;
}

export class Item extends React.Component<ItemProps> {
    render() {
        return this.props.show === true ? (
            <li className={this.props.className ?? ''}>
                <NavLink style={this.props.style ?? {}} to={this.props.to}>
                    {this.props.title}
                </NavLink>
            </li>
        ) : (
            <div style={{display: 'none'}}></div>
        )
    }
}
import { IRootState } from 'app/shared/reducers';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import close from '../../../content/images/icons/close.svg'
import './alert-modal.scss';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface IAlertModalProps extends StateProps, DispatchProps {
  showModal: boolean;
  closeModal: () => void,
  history: () => void,
  title: string,
  description: String,
  premium: boolean,
}

export interface IAlertModalState { }

export class AlertModalPlanRestricted extends React.Component<IAlertModalProps, IAlertModalState> {

  render() {
    const {
      showModal,
      closeModal,
      title,
      description,
      premium,
      history,
    } = this.props;

    return (
      <Modal className={'alert-modal__props'} isOpen={showModal}>
        <ModalBody>
          <Row style={{ height: 'calc(100vh - 130px)', margin: 'auto' }}>
            <Col >
              <div className='containerAlert'>
                <img src={close} onClick={() => closeModal()} />
                <Translate contentKey={`profile.manage-plans.${title}`} />
                <span>{description}</span>
                {
                  premium ?

                    <button style={{ width: '172px', padding: '0 15px', margin: '0' }} className={'button fourth'}
                      onClick={() => closeModal()}>
                      <Translate contentKey={'profile.manage-plans.change-plans-buttom'} />
                    </button>
                    :
                    <Row >
                      <button style={{ width: '172px', padding: '0 15px', margin: '0', background: 'white', border: "1px solid#30AB64" }} className={'button fourth button-back'}
                        onClick={() => closeModal()}>
                        <Translate contentKey={'mtr-signature.back'} />
                      </button>
                      <button style={{ width: '172px', padding: '0 15px', margin: '0' }} className={'button fourth'}
                        onClick={() => history()}>
                        <Translate contentKey={'mtr-signature.button-sign'} />
                      </button>
                    </Row>
                }
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  hasManagerAuthorities: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
});

const mapDispatchToProps = { hideHeader, showHeader };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AlertModalPlanRestricted);

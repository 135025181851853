import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import '../purchase-summary/purchase-summary.scss';
import { connect } from 'react-redux';
import { convertBrazilianStandardDate } from 'app/shared/util/date-utils';
import IBilling from 'app/shared/model/billing.model';
import { IRootState } from 'app/shared/reducers';
import { realCoinMask } from 'app/shared/util/mask-utils';
import { useHistory } from 'react-router-dom';
import PlanService from 'app/services/PlanService';


const supportEmail = 'suporte@resitrack.com.br';
const supportCel = '+55 41 98860-0819';

const BankslipSummary = (props) => {
    const [plan, setPlan] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const fetchPlan = async () => {
            const planData = await PlanService.getPlan();
            setPlan(planData);
        };
        fetchPlan();
    }, []);

    const value = Number(plan?.plan?.price)

    return (
        <div className="purchase-summary__container">
            <Container style={{ maxWidth: '786px', overflow: 'auto' }}>
                <Row>
                    <Col>
                        <div className="purchase-summary__container--title" style={{ marginTop: "30px" }}>
                            <p>Boleto gerado com <span style={{ color: '#00A038' }}> sucesso!</span></p>
                            <p>
                                A cobrança é mensal e os boletos serão enviados para o seu e-mail.{' '}
                                <span style={{ color: '#00A038' }}>VISUALIZE AGORA SEU PRIMEIRO BOLETO</span> e depois clique em Entrar na Resitrack!{' '}
                                Para começar a utilizar a nossa ferramenta!
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row style={{ backgroundColor: '#FFFFFF', padding: '40px 80px' }}>
                    <Col>
                        <div style={{ display: 'flex' }}>
                            <p style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                <Translate contentKey={'register.purchaseSummary.totalWithSymbol'} />
                            </p>
                            <p> {realCoinMask(value)}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                <Translate contentKey={'register.purchaseSummary.dueDateWithSymbol'} />
                            </span>
                            <p>{convertBrazilianStandardDate(plan?.expireDate)}</p>
                        </div>


                        <div className="purchase-summary__container--button-container">
                            <Button onClick={() => history.replace('/me/profile/plans')} className={'button fifth'}>
                                <Translate contentKey={'register.purchaseSummary.enterResitrack'} />
                            </Button>
                            <a href={`${plan?.billing?.bankSlipUrl}`} target="_blank" style={{ textDecoration: 'none' }}>
                                <Button className={'button fourth button-fourth'}>
                                    <Translate contentKey={'register.purchaseSummary.printTicket'} />
                                </Button>
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="purchase-summary__container--informational-bank-slip">
                            <Translate contentKey={'register.purchaseSummary.informationalTextBankSlip'} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="purchase-summary__container--support-text">
                            <Translate contentKey={'register.purchaseSummary.supportText'} />
                            <div className="purchase-summary__container--support-text-support-email">{supportEmail}</div>
                            <div className="purchase-summary__container--support-text-support-email">
                                <img className="whats" />
                                {supportCel}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account.hasSubscription,
});

export default connect(mapStateToProps)(BankslipSummary);

import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './manager-producer-invitation.reducer';

// tslint:disable-next-line:no-unused-variable

export interface IManagerProducerInvitationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ManagerProducerInvitationDetail extends React.Component<IManagerProducerInvitationDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { managerProducerInvitationEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.managerProducerInvitation.detail.title">ManagerProducerInvitation</Translate> [
            <b>{managerProducerInvitationEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="invitationStatus">
                <Translate contentKey="resitrackApp.managerProducerInvitation.invitationStatus">Invitation Status</Translate>
              </span>
            </dt>
            <dd>{managerProducerInvitationEntity.invitationStatus}</dd>
            <dt>
              <Translate contentKey="resitrackApp.managerProducerInvitation.producer">Producer</Translate>
            </dt>
            <dd>{managerProducerInvitationEntity.producer ? managerProducerInvitationEntity.producer.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.managerProducerInvitation.manager">Manager</Translate>
            </dt>
            <dd>{managerProducerInvitationEntity.manager ? managerProducerInvitationEntity.manager.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/manager-producer-invitation" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/manager-producer-invitation/${managerProducerInvitationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ managerProducerInvitation }: IRootState) => ({
  managerProducerInvitationEntity: managerProducerInvitation.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerProducerInvitationDetail);

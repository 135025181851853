import React from 'react';
import MenuItem from 'app/shared/layout/menus/common/menu-item';
import CustomMenuItem from 'app/shared/layout/menus/common/custom-menu-item/custom-menu-item';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { MenuDropDown } from '../producer/producer-menu-dropdonw';
import { ProfileType } from 'app/shared/model/enum/Profile-dropdonw';
import PlanService from 'app/services/PlanService';
import { ITypePlan } from 'app/shared/model/plan.model';
import { TransporterMenuDropDown } from './transporter-menu-dropdown';

export interface ITransporterMenuProps {
  isAdmin: boolean;
  isUser: boolean;
  currentUrl: string;
}

export interface ITransporterMenuState {
  subCategorySelected: boolean;
  isTransportSelected: boolean;
  plan: ITypePlan | void;
}

export class TransporterMenu extends React.Component<ITransporterMenuProps, ITransporterMenuState> {
  $collapseRef: any;
  constructor(props) {
    super(props);
    this.state = {
      isTransportSelected: false,
      subCategorySelected: false,
      plan: null,
    };
    this.$collapseRef = React.createRef();
  }

  isTransportSelected = () => {
    this.setState({
      isTransportSelected: !this.state.isTransportSelected,
    });
  };

  onInsideClick = () => {
    if (!this.state.isTransportSelected) {
      document.addEventListener('click', this.onOutsideClick, false);
    } else {
      document.removeEventListener('click', this.onOutsideClick, false);
    }
    this.isTransportSelected();
  };

  onOutsideClick = (event: any) => {
    if (this.$collapseRef.current.contains(event.target)) return;
    this.onInsideClick();
  };

  checkSubcategorySelected = (path: string) => {
    const { currentUrl } = this.props;
    const selected = currentUrl.indexOf(path) != -1;
    return selected;
  };

  async componentDidMount() {
    await this.setState({
      plan: await PlanService.getPlan().then((res) => {
        return res
      }).catch((error) => console.log(error))
    })
  }

  render() {
    const { isAdmin, isUser, currentUrl } = this.props;
    // const active = this.checkSubcategorySelected('/contractor/rides');
    return (
      <div style={{ display: 'flex', alignItems: `center` }}>
        <MenuDropDown currentUrl={this.props.currentUrl} profile={ProfileType.TRANSPORTER} />
        <CustomMenuItem id={'units'} to="/mtr-units" active={this.checkSubcategorySelected("/mtr-units")}>
          <Translate contentKey={'header.producer.units'}>Units</Translate>
        </CustomMenuItem>
        <TransporterMenuDropDown currentUrl={this.props.currentUrl} />
        <CustomMenuItem id={'residues'} to="/transporter/residues" active={this.checkSubcategorySelected('/transporter/residues')}>
          <Translate contentKey={'header.common.residues'}>Residues</Translate>
        </CustomMenuItem>
        <CustomMenuItem id={'licenses'} to="/transporter/licenses" active={this.checkSubcategorySelected('/transporter/licenses')}>
          <Translate contentKey={'header.common.licenses'}>Licenses</Translate>
        </CustomMenuItem>
        <CustomMenuItem id={'invite'} to="/transporter/summary" active={this.checkSubcategorySelected('/transporter/summary')}>
          <Translate contentKey={'header.common.invite'}>Invite</Translate>
        </CustomMenuItem>
      </div>
    );
  }
}

export default TransporterMenu;

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './list.scss';

export const MtrManagerList = () => {
  return (
    <div className="list__items container">
      <div className="list__items--back">
        <Container>
          <Row>
            <Col md="10">
              <div className="confirmation--back">
                <div className="confirmation--back-icon" />
                <span>
                  <Translate contentKey={'mtr-signature.back'} />
                </span>
              </div>
            </Col>
            <Col md="2">
              <div className="list__items--edit">
                <Translate contentKey={'mtr-signature.edit'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <h1 style={{ fontWeight: 550 }}>{translate('mtr-manager.list.title')}</h1>
      </div>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <div className="list__items--title-second">{translate('mtr-manager.list.company-data')}</div>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.cnpj')}</div>
            <div className="list__items--text">000.000.0000/000</div>
          </div>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.companyName')}</div>
            <div className="list__items--text">Lorem Ipsum Dolor</div>
          </div>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.activityType')}</div>
            <div className="list__items--text">Lorem Ipsum Dolor</div>
          </div>
        </Col>
      </Row>
      <Row style={{width:'100%'}}>
        <Col md={{ size: 3, offset: 3 }}>
          <div className="list__items--title-second">{translate('mtr-manager.list.adress')}</div>
        </Col>
      </Row>
      <Row>
      <Col md={{ size: 4, offset: 2 }}  style={{ paddingRight: '0' }}>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.address')}</div>
            <div className="list__items--text">Rua Lorem Ipsum Dolor, 000</div>
          </div>
        </Col>
        <Col md="4" style={{ paddingLeft: '0' }}>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.complement')}</div>
            <div className="list__items--text">Lorem Ipsum</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 4, offset: 2 }} style={{ paddingRight: '0' }}>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.city')}</div>
            <div className="list__items--text">Curitiba</div>
          </div>
        </Col>
        <Col md="2" style={{ paddingLeft: '0', paddingRight: '0' }}>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.state')}</div>
            <div className="list__items--text">Paraná</div>
          </div>
        </Col>
        <Col md="2" style={{ paddingLeft: '0' }}>
          <div className="list__items--component">
            <div className="list__items--title">{translate('mtr.zipcode')}</div>
            <div className="list__items--text">000.00-000</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <div className="list__items--component">
            <div className="list__items--title">Telefone</div>
            <div className="list__items--text">(00)0000-0000</div>
          </div>
          <div className="list__items--component">
            <div className="list__items--title">Email</div>
            <div className="list__items--text">lorem@lorem.com</div>
          </div>
          <div className="list__items--title-second">{translate('mtr-manager.list.responsible')}</div>
          <div className="list__items--component">
            <div className="list__items--title">Nome</div>
            <div className="list__items--text">Lorem Ipsum</div>
          </div>
          <div className="list__items--component">
            <div className="list__items--title">Email</div>
            <div className="list__items--text">lorem@lorem.com</div>
          </div>
          <div className="list__items--subtitle">{translate('mtr-transporter.license.subtitle-second')}</div>
          <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
            <div className="list__items--title">Licença de transporte</div>
            <div style={{ display: 'flex' }}>
              <div className="list__items--img" />
              <div className="list__items--text">Nome-do-arquivo.pdf</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MtrManagerList;

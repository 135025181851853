import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './add-new-credit-card.scss';
import { Translate, translate } from 'react-jhipster';
import { Button } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import CustomTextFieldForPlansScreen, { TypeMask } from 'app/components/custom-text-field-for-plans-screen/CustomTextFieldForPlansScreen';
import Cardholder from './Cardholder';
import ICreditCard from 'app/shared/model/credit-card.model';

interface Props {
    hadleCloseAddNew: () => void;
}

const AddNewCreditCard = ({ hadleCloseAddNew }: Props) => {
    const [isAddCardholder, setIsAddCardholder] = useState<boolean>(false);

    const [creditCardData, setCreditCardData] = useState<ICreditCard>({
        creditCardNumber: '',
        name: '',
        validity: '',
        ccv: '',
    });

    const schema = yup.object().shape({
        creditCardNumber: yup
            .string()
            .required(translate('register.errors.requiredField'))
            .min(19, translate('register.errors.invalidNumber')),
        name: yup.string().required(translate('register.errors.requiredField')),
        validity: yup
            .string()
            .required(translate('register.errors.requiredField'))
            .matches(/([0-9]{2})\/([0-9]{2})/, translate('register.errors.invalidDate')),
        ccv: yup
            .string()
            .required(translate('register.errors.requiredField'))
            .min(3, translate('register.errors.invalidNumber')),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        shouldFocusError: true,
    });

    const handleOnSubmitDataForm = () => {
        setIsAddCardholder(true);
    };

    return (
        !isAddCardholder ? (
            <div className='add-new-card__container' style={{ position: 'relative' }}>
                <Container style={{ maxHeight: 'calc(100vh - 230px)', overflow: 'auto' }}>
                    <Row>
                        <Col style={{ margin: 'auto', position: 'relative' }} md='5'>
                            <div className='add-new-card__container--icon-close' onClick={() => hadleCloseAddNew()} />
                            <div className='add-new-card__container--title'>
                                <span>
                                    <Translate contentKey={'register.select-payment.addCreditCard'} />
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '440px', backgroundColor: '#ededed' }} md='5'>
                            <div className='add-new-card__container--card-container'>
                                <span className='add-new-card__container--card-container-card-number'>{creditCardData?.creditCardNumber}</span>
                                <div className='add-new-card__container--card-container-name-and-valididty-container'>
                                    <span>{creditCardData?.name}</span>
                                    <span>{creditCardData?.validity}</span>
                                </div>
                            </div>
                        </Col>
                        <Col style={{ minWidth: '458px', backgroundColor: '#FFFFFF', padding: '32px 45px 39px 45px' }} md='5'>
                            <div className='add-new-card__container--form'>
                                <span className='add-new-card__container--form-title'>
                                    <Translate contentKey={'register.select-payment.cardData'} />
                                </span>

                                <FormProvider {...methods}>
                                    <form onSubmit={methods.handleSubmit(handleOnSubmitDataForm)}>
                                        <fieldset>
                                            <CustomTextFieldForPlansScreen
                                                inputName='creditCardNumber'
                                                isHidenInputValue
                                                value={creditCardData.creditCardNumber}
                                                label={translate('register.select-payment.form.cardNumber')}
                                                errorText={methods.formState.errors.creditCardNumber?.message}
                                                mask={TypeMask.CREDIT_CARD_NUMBER}
                                                onChange={(value) => setCreditCardData({ ...creditCardData, creditCardNumber: value })}
                                            />
                                            <CustomTextFieldForPlansScreen
                                                inputName='name'
                                                label={translate('register.select-payment.form.cardholder')}
                                                errorText={methods.formState.errors.name?.message}
                                                onChange={(value) => setCreditCardData({ ...creditCardData, name: value })}
                                            />
                                            <div className='add-new-card__container--form-fields-bottom-container'>
                                                <CustomTextFieldForPlansScreen
                                                    inputName='validity'
                                                    notIsFullWidth
                                                    label={translate('register.select-payment.form.validity')}
                                                    errorText={methods.formState.errors.validity?.message}
                                                    mask={TypeMask.CREDIT_CARD_VALIDITY}
                                                    onChange={(value) => setCreditCardData({ ...creditCardData, validity: value })}
                                                />
                                                <CustomTextFieldForPlansScreen
                                                    inputName='ccv'
                                                    type='password'
                                                    notIsFullWidth
                                                    label={translate('register.select-payment.form.cardVerificationValue')}
                                                    errorText={methods.formState.errors.ccv?.message}
                                                    mask={TypeMask.CARD_VERIFICATION_VALUE}
                                                    onChange={(value) => setCreditCardData({ ...creditCardData, ccv: value })}
                                                />
                                            </div>
                                        </fieldset>
                                        <div className='add-new-card__container--form-buttons-container'>
                                            <Button
                                                className={'button third'}
                                                style={{ maxWidth: '138px' }}
                                                onClick={() => hadleCloseAddNew()}
                                            >
                                                <Translate contentKey={'register.select-payment.cancel'} />
                                            </Button>
                                            <Button
                                                className={'button fourth button-fourth'}
                                                type={'submit'}
                                            >
                                                <Translate contentKey={'register.select-payment.save'} />
                                            </Button>
                                        </div>
                                    </form>
                                </FormProvider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        ) : (
            <Cardholder handleShowFormCardholder={setIsAddCardholder} creditCardData={creditCardData} hadleCloseAddNew={hadleCloseAddNew} />
        )
    );
}

export default AddNewCreditCard;

import axios from 'axios';
import { Storage } from 'react-jhipster';

import { SERVER_API_URL } from 'app/config/constants';

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = response => {
    if (response.headers != null) {
      const newToken = response.headers['x-new-token'];
      if (newToken != null && newToken !== '') {
        Storage.local.set('jhi-authenticationToken', newToken);
        Storage.session.set('jhi-authenticationToken', newToken);
      }
    }
    return response;
  };
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 401) {
      onUnauthenticated();
    }
    if (status === 403) {
      history.back();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

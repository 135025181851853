import './simple-search-field.scss';
import React from 'react';

export interface Props {
  placeholder?: string;
  value?: string;
  notimer?: boolean;
  onChange?: (text: string) => void;
}

export interface State {
  text?: string;
}

export class SimpleSearchField extends React.Component<Props, State> {
  timer = null;
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  componentWillReceiveProps(newProps: Readonly<Props>) {
    if (newProps.value !== this.state.text) {
      this.setState({
        text: newProps.value,
      });
    }
  }

  onChangeText = (newText: string) => {
    this.setState(
      {
        text: newText,
      },
      () => this.onChangeTimer()
    );
  };

  onChangeTimer = () => {
    if (this.props.notimer) {
      this.props.onChange(this.state.text);
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.onChange(this.state.text);
      }, 500);
    }
  };

  render() {
    return (
      <div className="mtr-list__search">
        <div className="form-inline md-form">
          <input
            onChange={e => this.onChangeText(e.target.value)}
            className="mtr-list__search--form"
            type="text"
            value={this.state.text}
            placeholder={this.props.placeholder ? this.props.placeholder : ''}
            aria-label="Search"
            onSubmit={e => e.preventDefault()}
            onKeyDown={e => {
              if (e.keyCode == 13) {
                e.preventDefault();
              }
            }}
          />
          <div className="mtr-list__search--icon" />
        </div>
      </div>
    );
  }
}

export default SimpleSearchField;

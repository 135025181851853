import axios, { AxiosResponse } from 'axios'; /* TODO:: Extrair diretamente de ./api */
import { Pageable, PageableResponse } from '../shared/model/pageable';
import { IUnit } from '../shared/model/unit';

const baseURL = '/api/'; /* TODO :: Refatorar para não precisar disso aqui. */

export class UnitApi {
  static getAllUnits = (page: Pageable, searchString?: string): Promise<AxiosResponse<PageableResponse<IUnit>>> => {
    const reqPage = page.page;
    const reqSize = page.size;
    const _sort = page.sort ? `&sort=${page.sort}` : '';
    const _search = searchString ? `&search=${searchString}` : '';
    const pageParams = `?page=${reqPage}&size=${reqSize}${_search}${_sort}`;

    return axios.get<PageableResponse<IUnit>>(`${baseURL}integration-units/me${pageParams}`);
  };

  static getUnit = (unitId: number): Promise<AxiosResponse<IUnit>> => {
    return axios.get<IUnit>(`${baseURL}integration-units/${unitId}`);
  };

  static createUnit = (unit: IUnit): Promise<AxiosResponse<IUnit>> => {
    return axios.post<IUnit>(`${baseURL}integration-units`, unit);
  };

  static updateUnit = (unit: IUnit): Promise<AxiosResponse<IUnit>> => {
    return axios.put<IUnit>(`${baseURL}integration-units`, unit);
  };

  static deleteUnit = (unitId: number): Promise<AxiosResponse<IUnit>> => {
    return axios.delete<IUnit>(`${baseURL}integration-units/${unitId}`);
  };
}

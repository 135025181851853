import { IRootState } from 'app/shared/reducers';
import { changeAuthority, getSession, setCreateNewProfile } from 'app/shared/reducers/authentication';
import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './manage-profiles.scss';
import { Pageable } from 'app/shared/model/pageable';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { getUsersByEntity, reset, getProfilesStatus } from '../profile-reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import PlanFree from '../../../../../content/images/icons/planFree.svg';
import PlanPremium from '../../../../../content/images/icons/planPremium.svg';
import Ticket from '../../../../../content/images/icons/Ticket.svg';
import Info from '../../../../../content/images/icons/info.svg';
import AlertModalPlan from 'app/components/alertModalPlan/alert-modal';
import InfoPlans from '../../../../components/InfoPlans/infoPlans'
import { IBankSlipType, ITypePlan } from 'app/shared/model/plan.model';
import TypesOfPlans from 'app/shared/model/enum/typeOfPlans';
import PaymentStatusType from 'app/shared/model/enum/paymentStatusType';
import PlanService from 'app/services/PlanService';
import IBankSlip from 'app/shared/model/bank-slip.model';
import { convertBrazilianStandardDate } from 'app/shared/util/date-utils';

export interface IManagerProfilesProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IManagerProfilesState {
  page: Pageable;
  showDeleteModal: boolean;
  deleteItemId: number;
  modalItem: string;
  imagePath: string;
  isModalOpen: boolean;
  showTips?: boolean;
  plan?: ITypePlan;
  typePlan?: string,
  status?: string,
  messageOfCard?: string,
  modalAlertPlan?: boolean,
  boleto?: IBankSlip,
}

export class Plans extends React.Component<IManagerProfilesProps, IManagerProfilesState> {
  timer = null;

  constructor(props: Readonly<IManagerProfilesProps>) {
    super(props);
    this.state = {
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      showDeleteModal: false,
      deleteItemId: null,
      modalItem: null,
      imagePath: "",
      isModalOpen: false,
      showTips: false,
      plan: null,
      typePlan: '',
      status: '',
      messageOfCard: '',
      modalAlertPlan: false,
    };
  }

  async componentDidMount() {
    await this.setState({ plan: await PlanService.getPlan() })
    this.setState({ typePlan: this.state.plan?.plan?.planType })
    this.checkStatusPlans()
    console.log("URL DO BOLETO", this.state.plan?.billing?.bankSlipUrl)
  }

  checkStatusPlans = () => {
    const plan = this.state.plan;
    if (plan.status === PaymentStatusType.AWAITING_PAYMENT) {
      this.setState({ status: 'pending', messageOfCard: 'EM PROCESSAMENTO' })
    } else if (plan.status === PaymentStatusType.PAYMENT_ERROR) {
      this.setState({ status: 'processing', messageOfCard: 'ERRO PAGAMENTO' })
    } else if (plan.status === PaymentStatusType.ACTIVE || plan.status === PaymentStatusType.TRIAL_ACTIVE) {
      this.setState({ status: 'active', messageOfCard: 'ATIVO' })
    } else if (plan.status === PaymentStatusType.AWAITING_EXPIRATION) {
      this.setState({ status: 'inactive', messageOfCard: 'INATIVO' })
    }

  }

  onHoverTips = (state: boolean) => {
    this.setState({ showTips: state })
  }

  handlechangePlan = () => {
    if (this.state.plan.status === PaymentStatusType.AWAITING_PAYMENT || this.state.plan.status === PaymentStatusType.AWAITING_EXPIRATION) {
      this.setState({ modalAlertPlan: true })
    } else {
      this.props.history.push('/full-register/plans')
    }
  }

  closeModalPlan = () => {
    this.setState({ modalAlertPlan: false })
  }

  renderCardInfos = (cardInfo: string, text: string) => {
    const classCard = `Card__info--${cardInfo}`
    return (
      <div className={`Card__info ${classCard}`}>
        <span>{text}</span>
      </div>
    )
  }

  renderCard = (srcImage, cardName, payment: string) => {
    return (
      <div className="card__manage-profiles-plans">
        <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 98%' }}>
          <img src={srcImage} />
          <div className="card__manage-profiles-plans--containerText">
            <div className="card__manage-profiles-plans--text">
              <Translate contentKey={`profile.manage-plans.${cardName}`} />
            </div>
            {
              payment === 'ticket' ?
                <div className="card__manage-profiles-plans--subText">
                  <Translate contentKey={`profile.manage-plans.Maturity`} />
                  <span>{convertBrazilianStandardDate(this.state.plan.expireDate)}</span>
                </div>
                :
                <></>
            }
          </div>
          {
            payment === 'ticket' ?
              <div className="card__manage-profiles-plans--paymentTicket">
                <Translate contentKey={`profile.manage-plans.${payment}`} />
                <img src={Ticket} />
              </div>
              :
              payment === 'Card' ?
                <div className="card__manage-profiles-plans--paymentCard">
                  <Translate contentKey={`profile.manage-plans.${payment}`} />
                  <span>{this.state.plan.brand} {this.state.plan.lastFourDigits}</span>
                </div>
                :
                <div className="card__manage-profiles-plans--paymentCard" style={{ width: '150px', marginLeft: '35px' }}>

                </div>
          }
          {
            this.renderCardInfos(this.state.status, this.state.messageOfCard)
          }
          <div className='card__manage-profiles-plans--containerInfoTips'>
            <img src={Info} className='card__manage-profiles-plans--infoTips' onMouseOver={() => this.onHoverTips(true)} onMouseOut={() => this.onHoverTips(false)} />
            {
              this.state.showTips && <InfoPlans />
            }
          </div>
        </div>
        {
          this.state.plan?.billing?.bankSlipUrl ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <button style={{}} className='boleto'
                onClick={() => {
                  const bankSlipUrl = this.state.plan?.billing?.bankSlipUrl;
                  if (bankSlipUrl) {
                    window.open(bankSlipUrl, '_blank');
                  }
                }}>
                <Translate contentKey={'profile.manage-plans.boleto'} />
              </button>
            </div>
            :
            <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 50%', justifyContent: 'flex-end' }}>
            </div>
        }
        {
          payment === 'ticket' || payment === 'Card' ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div className="card__manage-profiles-plans--rename" onClick={() => this.props.history.push('/full-register/payment-method',
                {
                  price: this.state.plan?.plan?.price,
                  planName: TypesOfPlans.PREMIUM,
                  subscriptionId: this.state.plan?.plan?.id,
                })} />
            </div>
            :
            <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 50%', justifyContent: 'flex-end' }}>
            </div>
        }
      </div>
    )
  }
  render() {
    return (
      <div className='bodyPlan'>
        <div className="manage-users__items--title">
          <Translate contentKey={'profile.personal-data.Plan'} />
          <button style={{ width: '218px', padding: '0 15px', margin: '0' }} className={'button fourth'} type={'submit'}
            onClick={this.handlechangePlan}>
            <Translate contentKey={'profile.manage-plans.button'} />
          </button>
        </div>
        <div style={{ marginTop: '20px' }}>
          {
            this.state.typePlan === 'FREE' &&
            this.renderCard(PlanFree, 'Free', '')
          }
          {
            this.state.typePlan === 'PREMIUM' &&
            this.renderCard(PlanPremium, 'Paid', this.state.plan.billingType === 'BOLETO' ? 'ticket' : 'Card')
          }
        </div>
        <AlertModalPlan closeModal={() => this.closeModalPlan()} showModal={this.state.modalAlertPlan} />
      </div>
    )
  }
}

const mapStateToProps = (root: IRootState) => ({
});

const mapDispatchToProps = {
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default Plans;
import GenericCardChoice from 'app/components/generic-card-choice/generic-card-choice';
import { IManager, PersonType } from 'app/shared/model/manager.model';
import { IUserManager } from 'app/shared/model/user-manager.model';
import { IUser } from 'app/shared/model/user.model';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ManagerRegisterLegal from './legal/manager-register-legal';
import ManagerRegisterNatural from './natural/manager-register-natural';
import './manager-register-person.scss';

export interface IManagerRegisterPersonProps extends StateProps, DispatchProps {
  userManager: IUserManager;
  onNext: (userManager: IUserManager) => void;
  onBack: () => void;
}

export interface IManagerRegisterPersonState {
  selectedPersonType: PersonType;
  isPersonSelected: boolean;
  manager: IManager;
}

export class ManagerRegisterPerson extends React.Component<IManagerRegisterPersonProps, IManagerRegisterPersonState> {
  constructor(props) {
    super(props);
    const { manager } = this.props.userManager;
    this.state = {
      selectedPersonType: manager ? manager.personType : null,
      isPersonSelected: manager ? manager.personType != null : false,
      manager: manager ? manager : {},
    };
  }

  mapType = () => {
    const objects = Object.keys(PersonType).map(key => {
      return { name: translate(`enums.personType.${key}`), keyEnum: key };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  search = () => {};

  onBack = () => {
    this.props.onBack();
  };

  onSelectPersonType = (index: PersonType) => {
    this.setState({
      selectedPersonType: index,
      isPersonSelected: true,
    });
  };

  onNext = (personManager: IManager) => {
    const { selectedPersonType } = this.state;
    const userManager = { ...this.props.userManager };
    const user: IUser = {
      ...userManager.user,
    };
    const updatedManager: IManager = {
      ...userManager.manager,
      legalPerson: personManager.legalPerson,
      naturalPerson: personManager.naturalPerson,
      personType: selectedPersonType,
    };
    userManager.manager = updatedManager;
    userManager.user = user;
    this.props.onNext(userManager);
  };

  renderForms = () => {
    const { selectedPersonType, manager } = this.state;

    return (
      <div>
        {selectedPersonType == PersonType.LEGAL && <ManagerRegisterLegal manager={manager} onNext={this.onNext} onBack={this.onBack} />}
        {selectedPersonType == PersonType.NATURAL && <ManagerRegisterNatural manager={manager} onNext={this.onNext} onBack={this.onBack} />}
      </div>
    );
  };

  renderChoice = () => {
    const { isPersonSelected, selectedPersonType } = this.state;
    const managerRegister = translate('managerRegister.person');
    return (
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <div className="manager-register-person__items">
            <h1>{translate('managerRegister.person.title')}</h1>
            <div className="manager-register-person__items--subtitle">
              {' '}
              <span>{translate('managerRegister.person.selectPerson')}</span>
            </div>
            <div className="manager-register-person__items--cards">
              <Row>
                <GenericCardChoice
                  index={PersonType.NATURAL}
                  title={managerRegister.natural.title}
                  text={managerRegister.natural.text}
                  onSelect={this.onSelectPersonType}
                  selected={selectedPersonType === PersonType.NATURAL}
                  hideText={isPersonSelected}
                />
                <GenericCardChoice
                  index={PersonType.LEGAL}
                  title={managerRegister.legal.title}
                  text={managerRegister.legal.text}
                  onSelect={this.onSelectPersonType}
                  selected={selectedPersonType === PersonType.LEGAL}
                  hideText={isPersonSelected}
                />
              </Row>
            </div>
            {isPersonSelected && this.renderForms()}
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    return <div>{this.renderChoice()}</div>;
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerRegisterPerson);

import axios from 'axios';
import { ICrudDeleteAction, ICrudPutAction } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { FavoriteType, IFavorite } from 'app/shared/model/favorite.model';
import { IFavoriteItemVM } from 'app/shared/model/favorite-item-vm.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import { IInvitationVM } from 'app/shared/model/invitation-vm.model';
import StringUtils from 'app/shared/util/string-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';

export const ACTION_TYPES = {
  ADD_FAVORITE: 'favorite/ADD_FAVORITE',
  FETCH_FAVORITE_PRODUCER: 'favorite/FETCH_FAVORITE_PRODUCER',
  FETCH_FAVORITE_FINAL_DESTINATION: 'favorite/FETCH_FAVORITE_FINAL_DESTINATION',
  FETCH_FAVORITE_TRANSPORTER: 'favorite/FETCH_FAVORITE_TRANSPORTER',
  FETCH_FAVORITE_TRANSSHIPMENT: 'favorite/FETCH_FAVORITE_TRANSSHIPMENT',
  DELETE_FAVORITE: 'favorite/DELETE_FAVORITE',
  INVITE: 'favorite/INVITE',
  RESET: 'favorite/RESET',
};

const initialState = {
  errorMessage: null,
  producers: [] as Array<IFavoriteItemVM>,
  transporters: [] as Array<IFavoriteItemVM>,
  transshipments: [] as Array<IFavoriteItemVM>,
  finalDestinations: [] as Array<IFavoriteItemVM>,
  favoritedEntity: {} as IFavorite,
  addFavoriteStatus: HttpRequestStatus.NOOP,
  fetchFavoriteProducerStatus: HttpRequestStatus.NOOP,
  fetchFavoriteTransporterStatus: HttpRequestStatus.NOOP,
  fetchFavoriteTransshipmentStatus: HttpRequestStatus.NOOP,
  fetchFavoriteFinalDestinationStatus: HttpRequestStatus.NOOP,
  deleteFavoriteStatus: HttpRequestStatus.NOOP,
  inviteStatus: HttpRequestStatus.NOOP,
  totalItems: 0,
};

export type GenericFavoriteState = Readonly<typeof initialState>;

// Reducer

export default (state: GenericFavoriteState = initialState, action): GenericFavoriteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.ADD_FAVORITE):
      return {
        ...state,
        addFavoriteStatus: HttpRequestStatus.ONGOING,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.ADD_FAVORITE):
      return {
        ...state,
        addFavoriteStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.ADD_FAVORITE):
      return {
        ...state,
        addFavoriteStatus: HttpRequestStatus.SUCCESS,
        favoritedEntity: action.payload.data
      };
    case REQUEST(ACTION_TYPES.FETCH_FAVORITE_PRODUCER):
      return {
        ...state,
        fetchFavoriteProducerStatus: HttpRequestStatus.ONGOING,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.FETCH_FAVORITE_PRODUCER):
      return {
        ...state,
        fetchFavoriteProducerStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_FAVORITE_PRODUCER):
      return {
        ...state,
        fetchFavoriteProducerStatus: HttpRequestStatus.SUCCESS,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        producers: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.FETCH_FAVORITE_FINAL_DESTINATION):
      return {
        ...state,
        fetchFavoriteFinalDestinationStatus: HttpRequestStatus.ONGOING,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.FETCH_FAVORITE_FINAL_DESTINATION):
      return {
        ...state,
        fetchFavoriteFinalDestinationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_FAVORITE_FINAL_DESTINATION):
      return {
        ...state,
        fetchFavoriteFinalDestinationStatus: HttpRequestStatus.SUCCESS,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        finalDestinations: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.FETCH_FAVORITE_TRANSSHIPMENT):
      return {
        ...state,
        fetchFavoriteTransshipmentStatus: HttpRequestStatus.ONGOING,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.FETCH_FAVORITE_TRANSSHIPMENT):
      return {
        ...state,
        fetchFavoriteTransshipmentStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_FAVORITE_TRANSSHIPMENT):
      return {
        ...state,
        fetchFavoriteTransshipmentStatus: HttpRequestStatus.SUCCESS,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        transshipments: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.FETCH_FAVORITE_TRANSPORTER):
      return {
        ...state,
        fetchFavoriteTransporterStatus: HttpRequestStatus.ONGOING,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.FETCH_FAVORITE_TRANSPORTER):
      return {
        ...state,
        fetchFavoriteTransporterStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_FAVORITE_TRANSPORTER):
      return {
        ...state,
        fetchFavoriteTransporterStatus: HttpRequestStatus.SUCCESS,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        transporters: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.DELETE_FAVORITE):
      return {
        ...state,
        deleteFavoriteStatus: HttpRequestStatus.ONGOING,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.DELETE_FAVORITE):
      return {
        ...state,
        deleteFavoriteStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DELETE_FAVORITE):
      return {
        ...state,
        deleteFavoriteStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.INVITE):
      return {
        ...state,
        inviteStatus: HttpRequestStatus.ONGOING,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.INVITE):
      return {
        ...state,
        inviteStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.INVITE):
      return {
        ...state,
        inviteStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/favorites';

// Actions

export const getFavoriteEntities = (page, size, sort, type, searchValue) => {
  const generatedParams = organizeSearchParameters(page, size, sort, searchValue);
  let actionType = null;
  switch (type) {
    case FavoriteType.PRODUCER:
      actionType = ACTION_TYPES.FETCH_FAVORITE_PRODUCER;
      break;
    case FavoriteType.TRANSPORTER:
      actionType = ACTION_TYPES.FETCH_FAVORITE_TRANSPORTER;
      break;
    case FavoriteType.FINAL_DESTINATION:
      actionType = ACTION_TYPES.FETCH_FAVORITE_FINAL_DESTINATION;
      break;
    case FavoriteType.TRANSSHIPMENT:
      actionType = ACTION_TYPES.FETCH_FAVORITE_TRANSSHIPMENT;
      break;
  }
  const params = {
    type,
  };
  const requestUrl = `${apiUrl}/entity${generatedParams}`;
  return {
    type: actionType,
    payload: axios.get<IFavoriteItemVM>(`${requestUrl}&cacheBuster=${new Date().getTime()}`, { params }),
  };
};



export const addFavorite: ICrudPutAction<IFavorite> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/add`;
  const result = await dispatch({
    type: ACTION_TYPES.ADD_FAVORITE,
    payload: axios.post(requestUrl, entity),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IFavorite> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_FAVORITE,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const inviteFavorite: ICrudPutAction<IInvitationVM> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/invite`;
  const result = await dispatch({
    type: ACTION_TYPES.INVITE,
    payload: axios.post(requestUrl, entity),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { deleteEntity as deleteDriver, reset as resetDriver } from 'app/entities/driver/driver.reducer';
import { DriverRole, IDriver } from 'app/shared/model/driver.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { Pageable } from 'app/shared/model/pageable';
import { IRootState } from 'app/shared/reducers';
import { maskPhone } from 'app/shared/util/mask-utils';
import StringUtils from 'app/shared/util/string-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { getDriversByTransporter, reset } from '../../transport-reducer';
import './driver-list.scss';

export interface IDriverListProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IDriverListState {
  filterName: string;
  page: Pageable;
}

export class DriverList extends React.Component<IDriverListProps, IDriverListState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('transporter.transport.driver.headers.name'), sortCode: 'userTransporter.user.name' },
    { name: translate('transporter.transport.driver.headers.cpf'), sortCode: 'userTransporter.user.cpf' },
    { name: translate('transporter.transport.driver.headers.phone'), sortCode: 'userTransporter.user.phone' },
    { name: translate('transporter.transport.driver.headers.driverLicense'), sortCode: 'driverLicense' },
    { name: translate('transporter.transport.driver.headers.driverType'), sortCode: 'driverType' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IDriverListProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getDriversByTransporter();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS) {
      this.props.getDriversByTransporter();
      this.props.resetDriver();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getDriversByTransporter = () => {
    const { page } = this.state;
    this.props.getDriversByTransporter(
      page.page,
      page.size,
      page.sort,
      StringUtils.toObject('userTransporter.user.name', this.state.filterName)
    );
  };

  private handleTransformToTableContent = (content?: IDriver[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IDriver) => {
      result.push([
        item.userTransporter != null ? item.userTransporter.user.name ?? '' : '',
        item.userTransporter.user.cpf ?? '',
        item.userTransporter.user.phone != null ? maskPhone(item.userTransporter.user.phone) : '',
        item.driverLicense,
        item.driverType ? item.driverType : '',
        <DeleteEditButton
          id={item.id}
          hideDelete={item.driverRole === DriverRole.ADMIN}
          onDelete={this.onDeleteDriver}
          onEdit={this.onEditDriver}
        />,
      ]);
    });

    return result;
  };

  onDeleteDriver = (id: number) => {
    this.props.deleteDriver(id);
  };

  onCreateDriver = () => {
    this.props.history.push(`${this.props.match.url}/new`);
  };

  onEditDriver = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/edit`,
      state: { id: id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getDriversByTransporter()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getDriversByTransporter()
    );
  };

  onHandleFilterChanged = value => {
    this.setState({ filterName: value }, () => this.getDriversByTransporter());
  };

  onDriverClick = (index: number) => {
    const { drivers } = this.props;
    const driver = drivers[index];
    this.props.history.push({
      pathname: `${this.props.match.url}/details`,
      state: { id: driver.id },
    });
  };

  render() {
    const { drivers } = this.props;
    const rows = this.handleTransformToTableContent(drivers);
    return (
      <div className="mtr-unity__items mtr-invite__table">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('transporter.transport.driver.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px', display:'flex', justifyContent:'space-between' }}>
            <Col md="6">
              <SimpleSearchField placeholder={translate('transporter.transport.driver.search')} onChange={this.onHandleFilterChanged} />
            </Col>
            <Col style={{
              justifyContent: 'flex-end',
              display: 'flex',
              maxWidth: '233px'
            }}>
              <Button className={'button primary'} type={'submit'} onClick={this.onCreateDriver}>
                <Translate contentKey={'transporter.transport.driver.add'} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onDriverClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  getTransporterDriversStatus: root.transport.getTransporterDriversStatus,
  totalItems: root.transport.totalItems,
  drivers: root.transport.drivers,
  deleteStatus: root.driver.deleteDriverStatus,
});

const mapDispatchToProps = {
  getDriversByTransporter,
  deleteDriver,
  reset,
  resetDriver,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverList);

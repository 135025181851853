import { IEnvironmentalLicense } from 'app/shared/model/environmental-license.model';
import { GeneratorCompanyType } from 'app/shared/model/producer.model';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import NextCancelButton from '../../next-cancel-button/next-cancel-button';
import EnvironmentalLicenseForm from '../form/environmental-license-form';
import './environmental-license-list.scss';

export interface IEnvironmentalLicenseListProps extends StateProps, DispatchProps {
  environmentalLicenses: IEnvironmentalLicense[];
  onNextHandler: (environmentalLicenses: IEnvironmentalLicense[]) => void;
  onBack: () => void;
  userProducer?: IUserProducer;
}

export interface IEnvironmentalLicenseListState {
  environmentalLicenses: IEnvironmentalLicense[];
  showForm: boolean;
  licenseIndex: number;
  environmentalLicenseToUpdate: IEnvironmentalLicense;
  error: boolean;
  userProducer?: IUserProducer;
}

export class EnvironmentalLicenseList extends React.Component<IEnvironmentalLicenseListProps, IEnvironmentalLicenseListState> {
  constructor(props) {
    super(props);
    this.state = {
      environmentalLicenses: this.props.environmentalLicenses ? this.props.environmentalLicenses : [],
      showForm: false,
      licenseIndex: null,
      environmentalLicenseToUpdate: null,
      error: false,
      userProducer: this.props.userProducer
    };
  }

  async componentDidMount() { }

  setUpdateIndex = (environmentalLicense: IEnvironmentalLicense, index: number) => {
    this.setState(
      {
        licenseIndex: index,
        environmentalLicenseToUpdate: environmentalLicense,
      },
      () => this.toggleShowForm()
    );
  };

  clearUpdateIndex = () => {
    this.setState({
      licenseIndex: null,
      environmentalLicenseToUpdate: null,
    });
  };

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  };

  onSaveEnvironmentalLicense = (environmentalLicense: IEnvironmentalLicense) => {
    const environmentalLicenses = [...this.state.environmentalLicenses];
    environmentalLicenses.push(environmentalLicense);
    this.setState(
      {
        environmentalLicenses,
        error: false,
      },
      () => this.toggleShowForm()
    );
  };

  onUpdateEnviromentalLicense = (environmentalLicense: IEnvironmentalLicense, index: number) => {
    const environmentalLicenses = [...this.state.environmentalLicenses];
    environmentalLicenses[index] = environmentalLicense;
    this.setState(
      {
        environmentalLicenses,
      },
      () => {
        this.clearUpdateIndex();
        this.toggleShowForm();
      }
    );
  };

  onDeleteEnvironmentalLicense = (index: number) => {
    const environmentalLicenses = [...this.state.environmentalLicenses];
    environmentalLicenses.splice(index, 1);
    this.setState({
      environmentalLicenses,
    });
  };

  onClose = () => {
    this.clearUpdateIndex();
    this.toggleShowForm();
  };

  onNextHandler = () => {
    const { environmentalLicenses, userProducer } = this.state;

    if (userProducer?.producer.generatorCompanyType !== GeneratorCompanyType.NATURAL && this.state.environmentalLicenses.length === 0) {
      this.setState({
        error: true,
      });
      return;
    }
    this.props.onNextHandler(environmentalLicenses);
  };

  renderEnvironmentalLicenseForm = () => {
    const { licenseIndex, environmentalLicenseToUpdate } = this.state;

    return (
      <EnvironmentalLicenseForm
        index={licenseIndex}
        environmentalLicense={environmentalLicenseToUpdate ? environmentalLicenseToUpdate : {}}
        onCreate={this.onSaveEnvironmentalLicense}
        onUpdate={this.onUpdateEnviromentalLicense}
        onClose={this.onClose}
      />
    );
  };

  renderList = () => {
    const { environmentalLicenses, error } = this.state;
    return (
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <div className="enviromental-list__container">
            <h1>{translate('environmentalLicense.addTitle')}</h1>
            <span>{translate('environmentalLicense.addText')}</span>
            <div>
              <AddLicenseButton onClick={this.toggleShowForm} />
            </div>
            {error && <span className="datepicker__error">{translate('validation.licenseList.empty')}</span>}
            <div>
              {environmentalLicenses &&
                environmentalLicenses.map((item, index) => (
                  <EnviromentalLicenseItem
                    index={index}
                    environmentalLicense={item}
                    onClick={this.setUpdateIndex}
                    onDelete={this.onDeleteEnvironmentalLicense}
                  />
                ))}
            </div>
            <NextCancelButton onNextClick={this.onNextHandler} onCancelClick={this.props.onBack} />
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const { showForm } = this.state;
    return (
      <div>
        {showForm && this.renderEnvironmentalLicenseForm()}
        {!showForm && this.renderList()}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentalLicenseList);

export const AddLicenseButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <div className="add-license__button" onClick={onClick}>
      <div className="plus-sign__image" />
      <span>{translate('environmentalLicense.add').toUpperCase()}</span>
    </div>
  );
};

export const EnviromentalLicenseItem = ({
  index,
  environmentalLicense,
  onClick,
  onDelete,
}: {
  index: number;
  environmentalLicense: IEnvironmentalLicense;
  onClick: (environmentalLicense: IEnvironmentalLicense, index: number) => void;
  onDelete: (index: number) => void;
}): JSX.Element => {
  return (
    <div className="environmental-item__container" onClick={() => onClick(environmentalLicense, index)}>
      <div
        style={{
          maxWidth: '75%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <span>{environmentalLicense.licenseNumber}</span>
      </div>
      <div className="invoice-table--img">
        <div
          className="invoice-table--delete-img"
          onClick={e => {
            e.stopPropagation();
            onDelete(index);
          }}
        />
        <div className="invoice-table--open-img" onClick={() => onClick(environmentalLicense, index)} />
      </div>
    </div>
  );
};

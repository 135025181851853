import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './producer-update-subsidiary.scss';
import { translate } from 'react-jhipster';
import { GeneratorType, IProducer } from 'app/shared/model/producer.model';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import {
  validateCNPJ,
  validateGenericField,
  validateGenericFieldList,
  validateObject
} from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { maskCnpj, unmask } from 'app/shared/util/mask-utils';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import AttachmentComponent from 'app/components/attachment-component/attachmentComponent';
import { IDocument } from 'app/shared/model/document.model';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { ICnaeCode } from 'app/shared/model/cnae-code.model';
import { getEntities as getCnaeCodes } from 'app/entities/cnae-code/cnae-code.reducer';
import InlineSelect from 'app/components/inline-select/inline-select';
import CnaeCodeComponent from 'app/components/cnae-code-component/cnaeCodeComponent';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

export interface IProducerUpdateSubsidiaryProps extends StateProps, DispatchProps {
  userProducer: IUserProducer;
  onNext: (userProducer: IUserProducer) => void;
  onBack: () => void;
}

export interface IProducerUpdateSubsidiaryState {
  cnpj: string;
  generatorType: GeneratorType;
  fantasyName: string;
  companyName: string;
  activityType: string;
  // businessLicense: IDocument;
  cnpjError: ValidationResult;
  cnaeCodes: ICnaeCode[];
  fantasyNameError: ValidationResult;
  companyNameError: ValidationResult;
  activityTypeError: ValidationResult;
  // businessLicenseError: ValidationResult;
  generatorTypeError: ValidationResult;
  cnaeCodeError: ValidationResult;
}

export class ProducerUpdateSubsidiary extends React.Component<IProducerUpdateSubsidiaryProps, IProducerUpdateSubsidiaryState> {
  constructor(props) {
    super(props);
    const { producer } = this.props.userProducer;
    this.state = {
      cnpj: producer.cnpj ? maskCnpj(producer.cnpj) : null,
      fantasyName: producer.fantasyName ? producer.fantasyName : null,
      companyName: producer.companyName ? producer.companyName : null,
      activityType: producer.activityType ? producer.activityType : null,
      // businessLicense: producer.businessLicense ? producer.businessLicense : null,
      generatorType: producer.generatorType ? producer.generatorType : null,
      cnaeCodes: producer.cnaeCodes ? producer.cnaeCodes : null,
      cnpjError: ValidationResultInstance,
      fantasyNameError: ValidationResultInstance,
      companyNameError: ValidationResultInstance,
      activityTypeError: ValidationResultInstance,
      // businessLicenseError: ValidationResultInstance,
      generatorTypeError: ValidationResultInstance,
      cnaeCodeError: ValidationResultInstance,
    };
  }

  componentDidMount() {
    this.props.getCnaeCodes();
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateCnpj()) {
      hasError = true;
    }
    if (this.onValidateFantasyName()) {
      hasError = true;
    }
    if (this.onValidateCompanyName()) {
      hasError = true;
    }
    if (this.onValidateActivityType()) {
      hasError = true;
    }
    // if (this.onValidateBusinessLicense()) {
    //   hasError = true;
    // }
    if (this.onValidateGeneratorType()) {
      hasError = true;
    }
    if (this.onValidateCnaeCodes()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateCnpj = () => {
    const { cnpj } = this.state;
    const validate = validateCNPJ(cnpj);
    this.setState({
      cnpjError: validate,
    });
    return validate.hasError;
  };

  onValidateFantasyName = () => {
    const { fantasyName } = this.state;
    const validate = validateGenericField(fantasyName);
    this.setState({
      fantasyNameError: validate,
    });
    return validate.hasError;
  };

  onValidateCompanyName = () => {
    const { companyName } = this.state;
    const validate = validateGenericField(companyName);
    this.setState({
      companyNameError: validate,
    });
    return validate.hasError;
  };

  onValidateActivityType = () => {
    const { activityType } = this.state;
    const validate = validateGenericField(activityType);
    this.setState({
      activityTypeError: validate,
    });
    return validate.hasError;
  };

  // onValidateBusinessLicense = () => {
  //   const { businessLicense } = this.state;
  //   const validate = validateObject(businessLicense);
  //   this.setState({
  //     businessLicenseError: validate,
  //   });
  //   return validate.hasError;
  // };

  onValidateGeneratorType = () => {
    const { generatorType } = this.state;
    const validate = validateObject(generatorType);
    this.setState({
      generatorTypeError: validate,
    });
    return validate.hasError;
  };

  onValidateCnaeCodes = () => {
    const { cnaeCodes } = this.state;
    const validate = validateGenericFieldList(cnaeCodes);
    this.setState({
      cnaeCodeError: validate,
    });
    return validate.hasError;
  };

  mapCnaeCodesToOptions = () => {
    const { cnaeCodes } = this.props;
    const optionsList = [];
    if (cnaeCodes == null || cnaeCodes.length === 0) {
      return optionsList;
    } else {
      cnaeCodes.forEach((item, index) => {
        optionsList.push({ label: `${item.code} - ${item.description}`, value: item.id });
      });
      return optionsList;
    }
  };

  onSelectCnaeCode = value => {
    if (value === -1) {
      return;
    }
    const cnaeCodeSelected = this.props.cnaeCodes.filter(it => it.id === value)[0];
    const cnaeCodes = [];
    if (cnaeCodes.filter(it => it.id === value).length === 0) {
      cnaeCodes.push(cnaeCodeSelected);
      this.setState(
        {
          cnaeCodes,
        },
        () => this.onValidateCnaeCodes()
      );
    }
  };

  onDeleteCnaeCode = value => {
    const cnaeCodes = [...this.state.cnaeCodes];
    cnaeCodes.splice(value, 1);
    this.setState(
      {
        cnaeCodes,
      },
      () => this.onValidateCnaeCodes()
    );
  };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }
    const { cnpj, fantasyName, companyName, activityType, generatorType, cnaeCodes } = this.state;

    const userProducer = { ...this.props.userProducer };
    const producer: IProducer = {
      ...userProducer.producer,
      cnpj: unmask(cnpj),
      fantasyName,
      companyName,
      activityType,
      // businessLicense,
      generatorType,
      cnaeCodes,
    };

    userProducer.producer = producer;
    this.props.onNext(userProducer);
  };

  // onAttachBusinessLicense = (document: IDocument) => {
  //   this.setState(
  //     {
  //       businessLicense: document,
  //     },
  //     () => this.onValidateBusinessLicense()
  //   );
  // };

  mapType = () => {
    const objects = Object.keys(GeneratorType).map(key => {
      return { name: translate(`enums.generatorType.${key}`), keyEnum: key };
    });
    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  search = () => { };

  onClickItem = (item: any) => {
    this.setState(
      {
        generatorType: item.keyEnum,
      },
      () => this.forceUpdate()
    );
  };

  mapSelectedProducerTypeToSelect = () => {
    const { generatorType } = this.state;

    return generatorType
      ? {
        name: translate(`enums.generatorType.${generatorType}`),
        keyEnum: generatorType,
      }
      : null;
  };

  render() {
    const {
      cnpj,
      fantasyName,
      companyName,
      activityType,
      cnpjError,
      // businessLicense,
      fantasyNameError,
      companyNameError,
      activityTypeError,
      // businessLicenseError,
      cnaeCodes,
      cnaeCodeError,
    } = this.state;
    return (
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <h1 style={{ marginBottom: '35px' }}>{translate('producerRegister.company.subsidiary.form.title')}</h1>
          <div className="producer-register-subsidiary__items">
            <DropdownSearch
              data={this.mapType()}
              getData={this.search}
              onClickItem={this.onClickItem}
              selectedData={this.mapSelectedProducerTypeToSelect()}
              placeholder={translate('producerRegister.company.generator')}
              title={translate('producerRegister.company.selectGenerator')}
              style={{ backgroundColor: '#f6f6f6' }}
              showInsiderSelect
              notAlphabeticalOrder
              filterLocalData
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  cnpj: text,
                })
              }
              id="cnpj"
              style={{ marginTop: '20px' }}
              value={cnpj}
              onMask={maskCnpj}
              label={translate('producerRegister.company.subsidiary.form.cnpj')}
              placeholder={translate('producerRegister.company.subsidiary.form.cnpj')}
              error={cnpjError.hasError}
              errorText={translateErrorMessage(cnpjError.errorMessage)}
              onBlur={this.onValidateCnpj}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  companyName: text,
                })
              }
              id="companyName"
              style={{ marginTop: '20px' }}
              value={companyName}
              label={translate('producerRegister.company.subsidiary.form.companyName')}
              placeholder={translate('producerRegister.company.subsidiary.form.companyName')}
              error={companyNameError.hasError}
              errorText={translateErrorMessage(companyNameError.errorMessage)}
              onBlur={this.onValidateCompanyName}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  fantasyName: text,
                })
              }
              id="fantasyName"
              style={{ marginTop: '20px' }}
              value={fantasyName}
              label={translate('producerRegister.company.subsidiary.form.fantasyName')}
              placeholder={translate('producerRegister.company.subsidiary.form.fantasyName')}
              error={fantasyNameError.hasError}
              errorText={translateErrorMessage(fantasyNameError.errorMessage)}
              onBlur={this.onValidateFantasyName}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  activityType: text,
                })
              }
              id="activityType"
              style={{ marginTop: '20px' }}
              value={activityType}
              label={translate('producerRegister.company.subsidiary.form.activityType')}
              placeholder={translate('producerRegister.company.subsidiary.form.activityType')}
              error={activityTypeError.hasError}
              errorText={translateErrorMessage(activityTypeError.errorMessage)}
              onBlur={this.onValidateActivityType}
            />

            {cnaeCodes != null &&
              cnaeCodes.length > 0 &&
              cnaeCodes.map((item, index) => (
                <CnaeCodeComponent key={`cnaeCode-${item.id}-${index}`} cnaeCode={item} index={index} onDelete={this.onDeleteCnaeCode} />
              ))}
            {(cnaeCodes == null || cnaeCodes.length == 0) && (
              <div>
                <InlineSelect
                  placeholder={translate('producerRegister.company.holding.form.selectCnae')}
                  error={cnaeCodeError.hasError}
                  errorMessage={translateErrorMessage(cnaeCodeError.errorMessage)}
                  dontAttachSelected
                  options={this.mapCnaeCodesToOptions()}
                  onChange={this.onSelectCnaeCode}
                  startIndex={0}
                />
              </div>
            )}
            <div>
              {/* <AttachmentComponent
                attachment={businessLicense}
                error={businessLicenseError.hasError}
                errorMessage={translateErrorMessage(businessLicenseError.errorMessage)}
                attachmentTitle={translate('producerRegister.company.subsidiary.form.businessLicense')}
                onFileAttached={this.onAttachBusinessLicense}
              /> */}
            </div>
            <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = {
  getCnaeCodes,
};

const mapStateToProps = (root: IRootState) => ({
  cnaeCodes: root.cnaeCode.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerUpdateSubsidiary);

import React from 'react';
import './holding-detail-component.scss';
import { translate } from 'react-jhipster';
import { Row } from 'reactstrap';
import { maskCnpj, maskPhone } from 'app/shared/util/mask-utils';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';


export interface IHoldingDetailComponentProps {
  userFinalDestination: IUserFinalDestination;
  isAdmin: boolean;
  onEditHolding: (id: number) => void;
}

export interface IHoldingDetailComponentState {
  open: boolean;
  holding: IUserFinalDestination;
}

export class HoldingDetailComponent extends React.Component<IHoldingDetailComponentProps, IHoldingDetailComponentState> {
  constructor(props: Readonly<IHoldingDetailComponentProps>) {
    super(props);
    const { userFinalDestination } = this.props;
    this.state = {
      open: false,
      holding:
        userFinalDestination && userFinalDestination.finalDestination
          ? !userFinalDestination.finalDestination.isSubsidiary
            ? userFinalDestination
            : {
              finalDestination: userFinalDestination.finalDestination.holdingCompany,
              }
          : null,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userFinalDestination != this.props.userFinalDestination) {
      const userFinalDestination = newProps.userFinalDestination;
      this.setState({
        holding:
        userFinalDestination && userFinalDestination.finalDestination
            ? !userFinalDestination.finalDestination.isSubsidiary
              ? userFinalDestination
              : userFinalDestination.finalDestination.holdingCompany
            : null,
      });
    }
  }

  toggleCollapse = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderClosed = () => {
    const { holding } = this.state;
    return (
      <div className="mtr-unity__items--matrix-collapsed" onClick={this.toggleCollapse}>
        <div className="mtr-unity__items--text">{holding ? holding.finalDestination.fantasyName : ''}</div>
        <div className="mtr-unity__items--img --collapsed" />
      </div>
    );
  };

  renderOpen = () => {
    const { isAdmin } = this.props;
    const { holding } = this.state;
    return (
      <div>
        <div className="mtr-unity__items--matrix-open">
          <div className="mtr-unity__info--container">
            <div className="mtr-unity__title-button--container">
              <span className="mtr-unity__title-button--title">{holding ? holding.finalDestination.fantasyName.toUpperCase() : ''}</span>
              {!this.props.userFinalDestination.finalDestination.isSubsidiary && isAdmin && (
                <span className="mtr-unity__title-button--button" onClick={() => this.props.onEditHolding(holding.id)}>
                  {translate('entity.action.edit').toUpperCase()}
                </span>
              )}
            </div>
            <Row className="mtr-unity__items--matrix-line-info">
              <div style={{borderRight:'none', borderBottom:'none'}} className="mtr-unity__items--matrix-info">
                <span className="span--title">{translate('resitrackApp.finalDestination.locations.location')}</span>
                <span className="span--info">{holding ? holding.finalDestination?.companyName : ''}</span>
              </div>
              <div style={{borderBottom:'none'}} className="mtr-unity__items--matrix-info">
                <span className="span--title">{translate('producer.units.holding.cnpj')}</span>
                <span className="span--info">{holding ? maskCnpj(holding.finalDestination?.cnpj) : ''}</span>
              </div>
            </Row>
            <Row className="mtr-unity__items--matrix-line-info">
              <div className="mtr-unity__items--matrix-info --no-right-border">
                <span className="span--title">{translate('resitrackApp.finalDestination.locations.responsible')}</span>
                <span className="span--info">{holding ? holding.finalDestination?.responsible : ''}</span>
              </div>
              <div style={{borderRight:'none'}} className="mtr-unity__items--matrix-info">
                <span className="span--title">{translate('producer.units.holding.phone')}</span>
                <span className="span--info">{holding ? maskPhone(holding.finalDestination?.phone) : ''}</span>
              </div>
              <div className="mtr-unity__items--matrix-info">
                <span className="span--title">{translate('resitrackApp.finalDestination.locations.city')}</span>
                <span className="span--info">{holding ? `${holding.finalDestination?.address?.city?.name}` : ''}</span>
              </div>
            </Row>
          </div>
          <div className="mtr-unity__items--img" onClick={this.toggleCollapse} />
        </div>
      </div>
    );
  };

  render() {
    const { open } = this.state;
    const { holding } = this.state;
    if (holding == null || holding.finalDestination == null) {
      return <div />;
    }
    return (
      <div>
        {!open && this.renderClosed()}
        {open && this.renderOpen()}
      </div>
    );
  }
}

export default HoldingDetailComponent;

import { IRootState } from 'app/shared/reducers';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import './refuse-page.scss';

export interface IRefusePageProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IRefusePageState {
    statusImage: string;
    alertMessage: string;
    buttonUrl: string;
    messageParams: any;
}

export class RefusePage extends React.Component<IRefusePageProps, IRefusePageState> {
    constructor(props: Readonly<IRefusePageProps>) {
        super(props);
        this.state = {
            statusImage: null,
            alertMessage: null,
            buttonUrl: null,
            messageParams: {},
        };
    }

    componentDidMount() {
        this.props.hideHeader();
        if (!this.props.location || !this.props.location.state) {
            this.setInvalidPageProps();
        } else {
            const { statusImage, alertMessage, buttonUrl, messageParams } = this.props.location.state as any;

            if (!statusImage || !alertMessage) {
                this.setInvalidPageProps();
            } else {
                this.setState({
                    statusImage,
                    alertMessage,
                    buttonUrl,
                    messageParams: messageParams ? messageParams : {},
                });
            }
        }
    }

    setInvalidPageProps = () => {
        this.setState({
            statusImage: 'bag-error.svg',
            alertMessage: 'alert.invalidPage',
            buttonUrl: null,
        });
    };

    onClickButton = () => {
        const { buttonUrl } = this.state;

        if (buttonUrl == null) {
            this.props.history.goBack();
        } else {
            this.props.history.replace(buttonUrl);
        }
    };

    componentWillReceiveProps(values) { }

    componentWillUnmount() {
        this.props.showHeader();
    }

    render() {
        const { statusImage, alertMessage, messageParams } = this.state;
        if (alertMessage == null) {
            return <div />;
        }
        return (
            <Container>
                <Row style={{ height: 'calc(100vh - 130px)', margin: 'auto' }}>
                    <Col style={{ margin: 'auto' }}>
                        <div className="alert-page-container">
                            <div className="image-wrapper">
                                <img src={`content/images/icons/${statusImage}`} />
                            </div>
                            <div className="message-wrapper">
                                <span>{translate(`${alertMessage}.message`, messageParams)}</span>
                            </div>
                            <div className={'button-container'}>
                                <button className={'button primary'} type={'button'} onClick={this.onClickButton}>
                                    <span>{translate(`${alertMessage}.buttonMessage`)}</span>
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { hideHeader, showHeader };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RefusePage);

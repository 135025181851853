import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserProfileMenu from './user-profile-menu';
import ManagerUsersEdit from './manage-users/edit/manage-users-edit';
import ManagerUsersDetails from './manage-users/details/manage-details';
import UserIntegrationNew from '../user-integration/data/user-integration-new';
import UserIntegrationEdit from '../user-integration/data/user-integration-edit';
import userIntegrationDetails from '../user-integration/data/user-integration-details';

const Routes = ({ match }) => {
  return (
    <>
      <Switch>
        <Route path={`${match.url}/profile`} component={UserProfileMenu} />
        <Route exact path={`${match.url}/user-manage/details`} component={ManagerUsersDetails} />
        <Route path={`${match.url}/user-integration/new`} component={UserIntegrationNew} />
        <Route path={`${match.url}/user-integration/edit`} component={UserIntegrationEdit} />
        <Route exact path={`${match.url}/user-integration/details`} component={userIntegrationDetails} />
        <Route path={`${match.url}/user-manage/new`} component={ManagerUsersEdit} />
        <Route path={`${match.url}/user-manage/edit`} component={ManagerUsersEdit} />
      </Switch>
    </>
  );
};

export default Routes;

import { IRootState } from 'app/shared/reducers';
import { maskPhone } from 'app/shared/util/mask-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './transporter-details.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  getEntityByTransporter as getTransporter,
  reset
} from 'app/entities/user-transporter/user-transporter.reducer';
import Loading from 'app/components/loading/loading';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import moment from 'moment';
import { APP_ONLY_DATE_FORMAT } from 'app/config/constants';
import { formatToCEP, formatToCNPJ, formatToCPF } from 'brazilian-values';
import SimpleFileComponent from 'app/components/simple-file-component/simple-file-component';
import { ILegalPerson } from 'app/shared/model/legal-person.model';
import { INaturalPerson } from 'app/shared/model/natural-person.model';
import { PersonType } from 'app/shared/model/transporter.model';

export interface ITransporterDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface ITransporterDetailsState { }

interface DetailComponent {
  title: string;
  info: string;
}

export class TransporterDetails extends React.Component<ITransporterDetailsProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getTransporter(id);
    }
  }

  renderComponent = (title: string, info: string) => (
    <div className="list__items--component">
      <div className="list__items--title">{title}</div>
      <div className="list__items--text">{info}</div>
    </div>
  );

  renderComponentSeries = (details: DetailComponent[]) => (
    <Row className="no-margin">
      {details &&
        details.map((item, index) => (
          <Col className={`no-padding ${index < details.length - 1 ? 'marginR1' : ''}`}>{this.renderComponent(item.title, item.info)}</Col>
        ))}
    </Row>
  );

  createDetailComponent = (title: string, info: string) => {
    return {
      title,
      info,
    };
  };

  renderTitle = (title: string) => {
    return <div className="list__items--title-second">{title}</div>;
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  renderLegalInfo = (legalPerson: ILegalPerson) => {
    return (
      <div>
        {this.renderComponentSeries([
          this.createDetailComponent(
            translate('detailsInfo.companyInfo.companyName'),
            legalPerson.companyName ? legalPerson.companyName : ''
          ),
          this.createDetailComponent(translate('detailsInfo.companyInfo.cnpj'), legalPerson.cnpj ? formatToCNPJ(legalPerson.cnpj) : ''),
        ])}
      </div>
    );
  };

  renderNaturalInfo = (naturalPerson: INaturalPerson) => (
    <div>
      {this.renderComponentSeries([
        this.createDetailComponent(translate('detailsInfo.companyInfo.name'), naturalPerson.name ? naturalPerson.name : ''),
        this.createDetailComponent(translate('detailsInfo.companyInfo.cpf'), naturalPerson.cpf ? formatToCPF(naturalPerson.cpf) : ''),
      ])}
    </div>
  );
  render() {
    const { loading, userTransporter } = this.props;
    if (loading) {
      return <Loading />;
    }
    const transportLicenses = userTransporter.transporter
      ? userTransporter.transporter.transportLicenses
        ? userTransporter.transporter.transportLicenses
        : null
      : null;
    return (
      <div className="list__items">
        <BackHeaderComponent onBack={this.handleGoBack} />
        <div>
          <h1 style={{ fontWeight: 550 }}>{userTransporter.transporter ? userTransporter.transporter.companyName : ''}</h1>
          <Row>
            <Col md="6" style={{ margin: 'auto' }}>
              {this.renderTitle(translate('detailsInfo.companyInfo.title'))}
              {userTransporter.transporter &&
                userTransporter.transporter.personType === PersonType.LEGAL &&
                this.renderLegalInfo(userTransporter.transporter.legalPerson)}
              {userTransporter.transporter &&
                userTransporter.transporter.personType === PersonType.NATURAL &&
                this.renderNaturalInfo(userTransporter.transporter.naturalPerson)}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.licenseNumber'),
                  transportLicenses ? transportLicenses[0]?.licenseNumber : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.issuingBody'),
                  transportLicenses ? transportLicenses[0]?.issuingBody : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.licenseType'),
                  transportLicenses && transportLicenses[0] ? translate(`enums.transportLicenseType.${transportLicenses[0]?.transportLicenseType}`) : ''
                ),
              ])}
              {this.renderComponent(
                translate('detailsInfo.licenseInfo.expirationDate'),
                transportLicenses ? moment(transportLicenses[0]?.expireDate).format(APP_ONLY_DATE_FORMAT) : ''
              )}
              {this.renderTitle(translate('detailsInfo.addressInfo.title'))}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.address'),
                  userTransporter.transporter ? userTransporter.transporter.address.street : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.address'),
                  userTransporter.transporter ? userTransporter.transporter.address.complement : ''
                ),
              ])}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.city'),
                  userTransporter.transporter ? userTransporter.transporter.address.city.name : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.state'),
                  userTransporter.transporter ? userTransporter.transporter.address.city.state.name : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.zipcode'),
                  userTransporter.transporter ? formatToCEP(userTransporter.transporter.address.zipcode) : ''
                ),
              ])}
              {this.renderTitle(translate('detailsInfo.responsible.title'))}
              {this.renderComponent(translate('detailsInfo.responsible.name'), userTransporter.user ? userTransporter.user.name : '')}
              {this.renderComponent(translate('detailsInfo.responsible.email'), userTransporter.user ? userTransporter.user.email : '')}
              {this.renderComponent(
                translate('detailsInfo.responsible.phone'),
                userTransporter.user ? maskPhone(userTransporter.user.phone) : ''
              )}
              {this.renderTitle(translate('detailsInfo.documentation.title'))}
              <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
                <div className="list__items--title">{translate('detailsInfo.documentation.transportLicense')}</div>
                <SimpleFileComponent file={transportLicenses ? transportLicenses[0]?.licenseDocument : null} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  loading: root.userTransporter.loading,
  userTransporter: root.userTransporter.entity,
});

const mapDispatchToProps = {
  getTransporter,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterDetails);

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransshipmentRegisterMenu from './menu/transshipment-register-menu';

const Routes = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}`} component={TransshipmentRegisterMenu} />
    </Switch>
);

export default Routes;

import axios from 'axios';
import { translate } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue } from 'app/shared/model/resetkey.model';

export const ACTION_TYPES = {
  RESET_PASSWORD_INIT: 'passwordReset/RESET_PASSWORD_INIT',
  RESET_PASSWORD_FINISH: 'passwordReset/RESET_PASSWORD_FINISH',
  CHECK_RESET_STATUS: 'passwordReset/CHECK_RESET_STATUS',
  CHECK_ACTIVATION_STATUS: 'passwordReset/CHECK_ACTIVATION_STATUS',
  CHANGE_PASSWORD_ACTIVATION: 'passwordReset/CHANGE_PASSWORD_ACTIVATION',
  RESET: 'passwordReset/RESET',
};

const initialState = {
  loading: false,
  resetPasswordSuccess: false,
  resetPasswordFailure: false,
  checkResetStatus: HttpRequestStatus.NOOP,
  checkActivationStatus: HttpRequestStatus.NOOP,
  checkResetKeyEntity: defaultValue,
  checkResetActivationEntity: defaultValue,
  resetPasswordFinishStatus: HttpRequestStatus.NOOP,
  changePasswordStatus: HttpRequestStatus.NOOP,
};

export type PasswordResetState = Readonly<typeof initialState>;

// Reducer
export default (state: PasswordResetState = initialState, action): PasswordResetState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.RESET_PASSWORD_FINISH):
    case REQUEST(ACTION_TYPES.RESET_PASSWORD_INIT):
      return {
        ...state,
        loading: true,
        resetPasswordFinishStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.CHANGE_PASSWORD_ACTIVATION):
      return {
        ...state,
        loading: true,
        changePasswordStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.CHECK_RESET_STATUS):
      return {
        ...initialState,
        checkResetStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.CHECK_ACTIVATION_STATUS):
      return {
        ...initialState,
        checkActivationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.RESET_PASSWORD_FINISH):
    case FAILURE(ACTION_TYPES.RESET_PASSWORD_INIT):
      return {
        ...initialState,
        loading: false,
        resetPasswordFailure: true,
        resetPasswordFinishStatus: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.CHANGE_PASSWORD_ACTIVATION):
      return {
        ...initialState,
        loading: false,
        changePasswordStatus: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.CHECK_RESET_STATUS):
      return {
        ...initialState,
        checkResetStatus: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.CHECK_ACTIVATION_STATUS):
      return {
        ...initialState,
        checkActivationStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.RESET_PASSWORD_FINISH):
    case SUCCESS(ACTION_TYPES.RESET_PASSWORD_INIT):
      return {
        ...initialState,
        loading: false,
        resetPasswordSuccess: true,
        resetPasswordFinishStatus: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.CHANGE_PASSWORD_ACTIVATION):
      return {
        ...initialState,
        loading: false,
        changePasswordStatus: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.CHECK_RESET_STATUS):
      return {
        ...initialState,
        checkResetStatus: HttpRequestStatus.SUCCESS,
        checkResetKeyEntity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CHECK_ACTIVATION_STATUS):
      return {
        ...initialState,
        checkActivationStatus: HttpRequestStatus.SUCCESS,
        checkResetActivationEntity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/account/reset-password';

export const handlePasswordResetInit = mail => ({
  type: ACTION_TYPES.RESET_PASSWORD_INIT,
  payload: axios.post(`${apiUrl}/${mail}/init`, { headers: { ['Content-Type']: 'text/plain' } }),
  meta: {
    errorMessage: translate('reset.request.messages.notfound'),
  },
});

export const handlePasswordResetFinish = (key, newPassword) => ({
  type: ACTION_TYPES.RESET_PASSWORD_FINISH,
  payload: axios.post(`${apiUrl}/finish`, { key, newPassword }),
  meta: {
    successMessage: translate('reset.finish.messages.success'),
  },
});

export const checkResetKey = (key: string) => ({
  type: ACTION_TYPES.CHECK_RESET_STATUS,
  payload: axios.get(`api/account/${key}/check`),
});

export const checkActivationKey = (key: string) => ({
  type: ACTION_TYPES.CHECK_ACTIVATION_STATUS,
  payload: axios.get(`api/account/activation/${key}`),
});

export const handleChangePasswordByActivationKey = (key, newPassword) => ({
  type: ACTION_TYPES.CHANGE_PASSWORD_ACTIVATION,
  payload: axios.post(`api/account/change-password/activation`, { key, newPassword }),
  meta: {
    successMessage: translate('reset.finish.messages.success'),
  },
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './mtr-add-residues.scss';
import TableList from '../add-residues/table-list';
import Search from '../add-residues/search';
import AlertModal from 'app/components/alert-modal/alert-modal';

export const MtrAddResidues = () => {
  return (
    <div className="mtr-add-residues__items">
      <Container>
        <div>
          <h1 style={{ marginTop: '28px', marginBottom:'15px', fontWeight: 600 }}>{translate('mtr-transporter.residues.title-second')}</h1>
        </div>
        <Row style={{marginBottom:'5px'}}>
          <Col md={{ size:'6', offset:'3'}}>
            <Search />
          </Col>
        </Row>
        <Row>
          <Col>
            <TableList />
          </Col>
        </Row>
      </Container>
      <div className="mtr-add-residues__items--modal">
        <Container>
          <div className="mtr-add-residues__items--modal-buttons">
            <Row>
              <Col md={{ size: 2, offset: 7 }}>
                <Button className={'button third'} type={'submit'}>
                  <Translate contentKey={'entity.action.cancel'} />
                </Button>
              </Col>
              <Col md="3">
                <Button className={'button fourth button-fourth'} type={'submit'}>
                  <Translate contentKey={'mtr-transporter.residues.button'} />
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* ==========================================================================
                            MODAIS
      ==========================================================================  */}

      <AlertModal
        showModal={false}
        buttonOneActionMessage={'mtr-signature.button-no'}
        buttonTwoActionMessage={'mtr-signature.button-yes'}
        hasTwoButtons
        buttonTwoAction={() => {}}
        buttonOneAction={() => {}}
        statusImage="money-bag.svg"
        alertMessage="modal-transporter-add-residue-agree"
      />

      <AlertModal
        showModal={false}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="garbage.svg"
        alertMessage="modal-transporter-add-residue"
      />
    </div>
  );
};

export default MtrAddResidues;

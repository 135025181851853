const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  MANAGER_ADMIN: 'ROLE_MANAGER_ADMIN',
  MANAGER_USER: 'ROLE_MANAGER_USER',
  PRODUCER_ADMIN: 'ROLE_PRODUCER_ADMIN',
  PRODUCER_USER: 'ROLE_PRODUCER_USER',
  TRANSPORTER_ADMIN: 'ROLE_TRANSPORTER_ADMIN',
  TRANSPORTER_USER: 'ROLE_TRANSPORTER_USER',
  TRANSSHIPMENT_ADMIN: 'ROLE_TRANSSHIPMENT_ADMIN',
  TRANSSHIPMENT_USER: 'ROLE_TRANSSHIPMENT_USER',
  FINAL_DESTINATION_ADMIN: 'ROLE_FINAL_DESTINATION_ADMIN',
  FINAL_DESTINATION_USER: 'ROLE_FINAL_DESTINATION_USER',
  DRIVER_USER: 'ROLE_DRIVER_USER',
};

export const ALL_WEB_AUTHORITIES = [
  AUTHORITIES.PRODUCER_ADMIN,
  AUTHORITIES.PRODUCER_USER,
  AUTHORITIES.TRANSPORTER_ADMIN,
  AUTHORITIES.TRANSPORTER_USER,
  AUTHORITIES.TRANSSHIPMENT_USER,
  AUTHORITIES.TRANSSHIPMENT_ADMIN,
  AUTHORITIES.MANAGER_ADMIN,
  AUTHORITIES.MANAGER_USER,
  AUTHORITIES.FINAL_DESTINATION_ADMIN,
  AUTHORITIES.FINAL_DESTINATION_USER,
  AUTHORITIES.DRIVER_USER,
];

export const ALL_ADMIN_AUTHORITIES = [
  AUTHORITIES.PRODUCER_ADMIN,
  AUTHORITIES.TRANSPORTER_ADMIN,
  AUTHORITIES.TRANSSHIPMENT_ADMIN,
  AUTHORITIES.MANAGER_ADMIN,
  AUTHORITIES.FINAL_DESTINATION_ADMIN,
];

export const ALL_USER_AUTHORITIES = [
  AUTHORITIES.PRODUCER_USER,
  AUTHORITIES.TRANSPORTER_USER,
  AUTHORITIES.TRANSSHIPMENT_USER,
  AUTHORITIES.MANAGER_USER,
  AUTHORITIES.FINAL_DESTINATION_USER,
];

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const ALERT_ICONS = {
  TRASH_CAN_OK: 'trash-can-ok.svg',
  HAND_TRASH: 'hand-trash.svg',
  MANAGER_GREEN: 'manager-green.svg',
  MANAGER_RED: 'manager-red.svg',
  FINAL_DESTINATION_GREEN: 'final-destination-green.svg',
  PAPER_QUESTION_MARK: 'paper-question-mark.svg',
  PAPER_SIGNATURE: 'paper-signature.svg',
  PRODUCER_GREEN: 'producer-green.svg',
  EMPTY_LIST: 'empty-list.svg',
  PAPER_OK: 'paper-ok.svg',
  PAPER_NO: 'paper-no.svg',
  BAG_ERROR: 'bag-error.svg',
  TRANSPORTER_GREEN: 'transporter-green.svg',
};

export const EMAIL_REGEX: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PHONE_REGEX: RegExp = /^[1-9]{2}9?\d{8}$/;

export const APP_DEFAULT_LIST_SIZE = 10;
export const APP_FILE_SIZE_LIMIT = 100555555;
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_ONLY_DATE_FORMAT = 'DD/MM/YY';
export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATE_FORMAT_US = 'YYYY-MM-DD';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT_ORDER_BY = 'YYYYMMDD';
export const APP_LOCAL_DATE_FORMAT_MONTH_YEAR = 'MMM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_LOCAL_DATE_FORMAT_MONTH_YEAR_SMALL = 'MMM/YYYY';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../content/images/icons/container-back.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".new-user__items {\n  padding-top: 79px;\n}\n.new-user__items .new-user__items--back {\n  height: 45px;\n  border: solid 1px #e3e3e3;\n  position: fixed;\n  background: white;\n  top: 79px;\n  left: 0;\n  right: 0;\n  z-index: 10;\n  overflow: hidden;\n}\n.new-user__items .new-user__items--back .confirmation--back {\n  display: flex;\n  align-items: center;\n  height: 46px;\n}\n.new-user__items .new-user__items--back .confirmation--back .confirmation--back-icon {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 10px;\n  cursor: pointer;\n}\n.new-user__items .new-user__items--back .confirmation--back span {\n  font-size: 0.875rem;\n  font-weight: 300;\n  font-family: Catamaran;\n  color: #30ab64;\n  cursor: pointer;\n}\n.new-user__items .new-user__items--component {\n  height: 56px;\n  border-radius: 6px;\n  border: solid 1px #e8ebec;\n  background-color: #f6f6f6;\n  font-family: Catamaran;\n  font-size: 18px;\n  font-weight: 300;\n  letter-spacing: 0.75px;\n  color: #9199a1;\n  margin-bottom: 20px;\n  display: flex;\n  padding-left: 16px;\n  align-items: center;\n}\n.new-user__items .new-user--buttons {\n  display: flex;\n  justify-content: center;\n  margin-top: 23px;\n}", ""]);
// Exports
module.exports = exports;

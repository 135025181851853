// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../content/images/icons/x-2.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../../../../content/images/card.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".add-new-card__container {\n  font-family: \"Catamaran\";\n  box-sizing: border-box;\n  padding-top: 10px;\n  margin: 0 auto;\n  max-width: 898px;\n}\n.add-new-card__container--icon-close {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: absolute;\n  top: 11px;\n  right: -250px;\n  cursor: pointer;\n}\n.add-new-card__container--title {\n  color: #2d3e46;\n  font-weight: 600;\n  font-size: 24px;\n  text-align: center;\n  margin-bottom: 11px;\n}\n.add-new-card__container--card-container {\n  position: relative;\n  width: 297px;\n  height: 176px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  color: #fff;\n  font-weight: 300;\n}\n.add-new-card__container--card-container-card-number {\n  position: absolute;\n  top: 70px;\n  left: 28px;\n  font-size: 22px;\n}\n.add-new-card__container--card-container-name-and-valididty-container {\n  display: flex;\n  justify-content: space-between;\n  width: 158px;\n  position: absolute;\n  font-size: 14px;\n  bottom: 18px;\n  left: 28px;\n}\n.add-new-card__container--form-title {\n  font-weight: 600;\n  font-size: 20px;\n  color: #4a4a4a;\n}\n.add-new-card__container--form-buttons-container {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 20px;\n}\n.add-new-card__container--form-buttons-container button.MuiButtonBase-root.MuiButton-root.MuiButton-text.button.third {\n  border-color: #30ab64 !important;\n}\n.add-new-card__container--form-buttons-container .button.third span {\n  color: #30ab64 !important;\n}\n.add-new-card__container--form form {\n  margin-top: 16px;\n}\n.add-new-card__container--form-fields-bottom-container {\n  display: flex;\n  justify-content: space-between;\n}", ""]);
// Exports
module.exports = exports;

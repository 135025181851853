import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {getEntities} from './user-transporter.reducer';

export interface IUserTransporterProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class UserTransporter extends React.Component<IUserTransporterProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { userTransporterList, match } = this.props;
    return (
      <div>
        <h2 id="user-transporter-heading">
          <Translate contentKey="resitrackApp.userTransporter.home.title">User Transporters</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="resitrackApp.userTransporter.home.createLabel">Create new User Transporter</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {userTransporterList && userTransporterList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userTransporter.registrationNumber">Registration Number</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userTransporter.classCouncil">Class Council</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userTransporter.user">User</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.userTransporter.transporter">Transporter</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {userTransporterList.map((userTransporter, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${userTransporter.id}`} color="link" size="sm">
                        {userTransporter.id}
                      </Button>
                    </td>
                    <td>{userTransporter.registrationNumber}</td>
                    <td>
                      <Translate contentKey={`resitrackApp.ClassCouncil.${userTransporter.classCouncil}`} />
                    </td>
                    <td>{userTransporter.user ? userTransporter.user.id : ''}</td>
                    <td>
                      {userTransporter.transporter ? (
                        <Link to={`transporter/${userTransporter.transporter.id}`}>{userTransporter.transporter.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${userTransporter.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${userTransporter.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${userTransporter.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="resitrackApp.userTransporter.home.notFound">No User Transporters found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userTransporter }: IRootState) => ({
  userTransporterList: userTransporter.entities,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserTransporter);

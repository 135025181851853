import { IBankSlipType, ITypePlan, ITypeSendPlan } from 'app/shared/model/plan.model';
import axios from 'axios';

const basePath = 'api/subscriptions';

const planApi = () => {
  const createPlan = (plan: ITypeSendPlan) => {
    return axios.post<ITypeSendPlan>(`${basePath}`, plan);
  };

  const getPlan = () => {
    return axios.get<ITypePlan>(`${basePath}`);
  };

  const getBankSlip = (planID: number) => {
    return axios.get<IBankSlipType>(`${basePath}/${planID}payments`);
  };

  const updatePlan = (plan: ITypeSendPlan) => {
    return axios.put<ITypeSendPlan>(`${basePath}`, plan);
  };

  const updatePaymentMethod = (plan: ITypeSendPlan) => {
    return axios.put<ITypeSendPlan>(`${basePath}/payment/change`, plan);
  };

  return {
    createPlan,
    getPlan,
    getBankSlip,
    updatePlan,
    updatePaymentMethod,
  };
};

export default planApi();

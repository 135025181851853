import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import Row from 'reactstrap/lib/Row';
import './generic-invite.scss';
import { inviteFavorite, reset } from '../favorite-list/generic-favorite.reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import {
    checkStatusOnGoing,
    translateErrorMessage,
    ValidationResult,
    ValidationResultInstance
} from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import AlertModal from 'app/components/alert-modal/alert-modal';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { validateEmail, validatePersonName } from 'app/modules/validation/validation-constants';
import { IInvitationVM } from 'app/shared/model/invitation-vm.model';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '@material-ui/core';

export interface IGenericFavoriteListProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IGenericFavoriteListState {
  name: string;
  email: string;
  nameError: ValidationResult;
  emailError: ValidationResult;
  showSuccessModal: boolean;
  showErrorModal: boolean;
}

export class GenericFavoriteList extends React.Component<IGenericFavoriteListProps, IGenericFavoriteListState> {
  constructor(props: Readonly<IGenericFavoriteListProps>) {
    super(props);
    this.state = {
      name: null,
      email: null,
      emailError: ValidationResultInstance,
      nameError: ValidationResultInstance,
      showErrorModal: false,
      showSuccessModal: false,
    };
  }

  async componentDidMount() {}

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    if (newProps.inviteStatus === HttpRequestStatus.SUCCESS) {
      this.setState({
        showSuccessModal: true,
      });
      this.props.reset();
    }
    if (newProps.inviteStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
      this.props.reset();
    }
  }

  onValidateName = () => {
    const { name } = this.state;
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateName()) {
      hasError = true;
    }
    if (this.onValidateEmail()) {
      hasError = true;
    }
    return hasError;
  };

  onSendInvite = () => {
    if (this.onValidateAll()) {
      return;
    }
    const { email, name } = this.state;
    const invitation: IInvitationVM = {
      name,
      email,
    };

    this.props.inviteFavorite(invitation);
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { inviteStatus } = this.props;
    const { name, nameError, email, emailError } = this.state;
    if (checkStatusOnGoing([inviteStatus])) {
      return <Loading />;
    }
    return (
        <Container style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="invite-second__items">
            <h1>{translate('mtr-transporter.inviteTitle')}</h1>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="invite-second__items--subtitle">{translate('mtr-transporter.inviteSubtitle')}</div>
            </div>
            <Row>
              <CustomTextField
                  onChange={(text: string) =>
                      this.setState({
                        name: text,
                      })
                  }
                  id="producerName"
                  style={{ marginTop: '20px' }}
                  value={name}
                  label={translate('favorite.invite.name')}
                  placeholder={translate('favorite.invite.name')}
                  error={nameError.hasError}
                  errorText={translateErrorMessage(nameError.errorMessage)}
              />
              <CustomTextField
                  onChange={(text: string) =>
                      this.setState({
                        email: text,
                      })
                  }
                  id="producerEmail"
                  style={{ marginTop: '20px' }}
                  value={email}
                  label={translate('favorite.invite.email')}
                  placeholder={translate('favorite.invite.email')}
                  error={emailError.hasError}
                  errorText={translateErrorMessage(emailError.errorMessage)}
              />
            </Row>
            <div className={'button-container'} style={{ marginTop: '40px' }}>
              <button className={'button third'} type={'submit'} onClick={this.onGoBack}>
                <Translate contentKey={'mtr-signature.button-cancel'} />
              </button>
              <button className={'button fourth'} type={'submit'} onClick={this.onSendInvite}>
                <Translate contentKey={'mtr-transporter.inviteButtonInvite'} />
              </button>
            </div>

            <AlertModal
                showModal={this.state.showErrorModal}
                buttonTwoActionMessage={'mtr-signature.button-ok'}
                hasOneButton
                buttonTwoAction={() => this.toggleErrorModal()}
                statusImage="bag-error.svg"
                alertMessage="errorMessages.invite"
            />

            <AlertModal
                showModal={this.state.showSuccessModal}
                buttonTwoActionMessage={'mtr-signature.button-ok'}
                hasOneButton
                buttonTwoAction={() => this.props.history.goBack()}
                statusImage="paper-ok.svg"
                alertMessage="favorite.invite.success"
            />
          </div>
        </Container>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  inviteStatus: root.genericFavorite.inviteStatus,
});

const mapDispatchToProps = {
  inviteFavorite,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GenericFavoriteList);

import React, { useEffect, useState } from 'react';
import '../style-for-all-cards.scss';
import { Col, Row } from 'reactstrap';
import GenericCardForPlans from '../GenericCardForPlans';
import { Translate } from 'react-jhipster';
import PlanService from 'app/services/PlanService';
import TypesOfPlans from 'app/shared/model/enum/typeOfPlans';
import Loading from 'app/components/loading/loading';

const dataPlans = {
    free: {
        numberGenerators: '03',
        numberUsers: '01'
    },
    premium: {
        numberGenerators: 'Ilimitado',
        price: 259.00
    }
};

interface IProps {
    hasSubscription: boolean;
}

const ManagerProfileCards = ({ hasSubscription }: IProps) => {
    const [planType, setPlanType] = useState<TypesOfPlans>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (hasSubscription) {
            PlanService.getPlan().then(plan => {
                setPlanType(plan?.plan.planType);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, []);

    return (
        isLoading ? (
            <Row style={{ height: "150px" }}>
                <Col>
                    <Loading />
                </Col>
            </Row>
        ) : (
            <Row>
                {(planType == null || planType === TypesOfPlans.PREMIUM) && (
                    <Col>
                        <GenericCardForPlans isCardFree isJustCard={planType === TypesOfPlans.PREMIUM}>
                            <ul className='manager-card__container'>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.firstText" />
                                        <div>
                                            <span>{dataPlans.free.numberGenerators}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.secondText" />
                                        <div>
                                            <span>{dataPlans.free.numberUsers}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.monthMtrs" />
                                        <div>
                                            <Translate contentKey="register.plans.cards.manager.numberMtrs" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.thirdText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-check' />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.eighthText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-check' />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.sixthText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-x' />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.seventhText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-x' />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </GenericCardForPlans>
                    </Col>
                )}

                {(planType == null || planType === TypesOfPlans.FREE) && (
                    <Col>
                        <GenericCardForPlans pricePlanPremium={dataPlans.premium.price} isJustCard={planType === TypesOfPlans.FREE}>
                            <ul className='manager-card__container'>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.firstText" />
                                        <div>
                                            <span>{dataPlans.premium.numberGenerators}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.secondText" />
                                        <div>
                                            <Translate contentKey="register.plans.cards.manager.tenthText" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.monthMtrs" />
                                        <div>
                                            <Translate contentKey="register.plans.cards.manager.tenthText" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.eighthText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-check' />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.thirdText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-check' />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.sixthText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-check' />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='manager-card__container--list-items'>
                                        <Translate contentKey="register.plans.cards.manager.seventhText" />
                                        <div>
                                            <span className='manager-card__container--list-items-icon-check' />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </GenericCardForPlans>
                    </Col>
                )}
            </Row>
        )
    );
}

export default ManagerProfileCards;

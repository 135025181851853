import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { validateGenericField, validateObject } from 'app/modules/validation/validation-constants';
import { IDocument } from 'app/shared/model/document.model';
import { EnvironmentalLicenseType, IEnvironmentalLicense } from 'app/shared/model/environmental-license.model';
import { IRootState } from 'app/shared/reducers';
import { maskAlphaNumeric } from 'app/shared/util/mask-utils';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import AttachmentComponent from '../../attachment-component/attachmentComponent';
import InlineSelect from '../../inline-select/inline-select';
import DatePickerComponent from '../../date-picker-component/date-picker-component';
import './environmental-license-form.scss';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Col, Row } from 'reactstrap';
import NextCancelButton from '../../next-cancel-button/next-cancel-button';
import ButtonComponent from 'app/components/button-component/button-component';

export interface IEnvironmentalLicenseFormProps extends StateProps, DispatchProps {
  index?: number;
  environmentalLicense: IEnvironmentalLicense;
  onCreate: (environmentalLicense: IEnvironmentalLicense) => void;
  onUpdate?: (environmentalLicense: IEnvironmentalLicense, index: number) => void;
  onClose?: () => void;
  defaultForm?: boolean;
  title?: string;
}

export interface IEnvironmentalLicenseFormState {
  licenseDocument: IDocument;
  licenseNumber: string;
  expireDate: string;
  issuingBody: string;
  environmentalLicenseType: EnvironmentalLicenseType;
  licenseDocumentError: ValidationResult;
  licenseNumberError: ValidationResult;
  expireDateError: ValidationResult;
  issuingBodyError: ValidationResult;
  environmentalLicenseTypeError: ValidationResult;
}

export class EnvironmentalLicenseForm extends React.Component<IEnvironmentalLicenseFormProps, IEnvironmentalLicenseFormState> {
  constructor(props) {
    super(props);
    this.state = {
      licenseDocument: this.props.environmentalLicense.licenseDocument,
      licenseNumber: this.props.environmentalLicense.licenseNumber,
      expireDate: this.props.environmentalLicense.expireDate,
      issuingBody: this.props.environmentalLicense.issuingBody,
      environmentalLicenseType: this.props.environmentalLicense.environmentalLicenseType,
      licenseDocumentError: ValidationResultInstance,
      licenseNumberError: ValidationResultInstance,
      expireDateError: ValidationResultInstance,
      issuingBodyError: ValidationResultInstance,
      environmentalLicenseTypeError: ValidationResultInstance,
    };
  }

  async componentDidMount() { }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateLicenseDocument()) {
      hasError = true;
    }
    if (this.onValidateLicenseNumber()) {
      hasError = true;
    }
    if (this.onValidateExpireDate()) {
      hasError = true;
    }
    if (this.onValidateIssuingBody()) {
      hasError = true;
    }
    if (this.onValidateEnvironmentalLicenseType()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateLicenseDocument = () => {
    const { licenseDocument } = this.state;
    const validate = validateObject(licenseDocument);
    this.setState({
      licenseDocumentError: validate,
    });
    return validate.hasError;
  };

  onValidateLicenseNumber = () => {
    const { licenseNumber } = this.state;
    const validate = validateGenericField(licenseNumber);
    this.setState({
      licenseNumberError: validate,
    });
    return validate.hasError;
  };

  onValidateExpireDate = () => {
    const { expireDate } = this.state;
    const validate = validateGenericField(expireDate);
    this.setState({
      expireDateError: validate,
    });
    return validate.hasError;
  };

  onValidateIssuingBody = () => {
    const { issuingBody } = this.state;
    const validate = validateGenericField(issuingBody);
    this.setState({
      issuingBodyError: validate,
    });
    return validate.hasError;
  };

  onValidateEnvironmentalLicenseType = () => {
    const { environmentalLicenseType } = this.state;
    const validate = validateObject(environmentalLicenseType);
    this.setState({
      environmentalLicenseTypeError: validate,
    });
    return validate.hasError;
  };

  mapEnvironmentalTypeToOptions = () => {
    const optionsList = [];
    Object.keys(EnvironmentalLicenseType).forEach((item, index) => {
      optionsList.push({ label: translate(`enums.environmentalLicenseType.${item}`), value: item });
    });
    return optionsList;
  };

  onSelectEnvironmentalLicenseType = (item: any) => {
    this.setState(
      {
        environmentalLicenseType: item,
      },
      () => this.onValidateEnvironmentalLicenseType()
    );
  };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const { index, environmentalLicense } = this.props;
    const { licenseDocument, licenseNumber, environmentalLicenseType, expireDate, issuingBody } = this.state;
    const enviromentalLicenseUpdate: IEnvironmentalLicense = {
      ...environmentalLicense,
      licenseDocument,
      licenseNumber,
      environmentalLicenseType,
      expireDate,
      issuingBody,
    };

    if (index == null) {
      this.props.onCreate(enviromentalLicenseUpdate);
    } else {
      this.props.onUpdate(enviromentalLicenseUpdate, index);
    }
  };

  onAttachLicense = (document: IDocument) => {
    this.setState(
      {
        licenseDocument: document,
      },
      () => this.onValidateLicenseDocument()
    );
  };

  mapSelectedLicenseTypeToSelect = () => {
    const { environmentalLicenseType } = this.state;

    return environmentalLicenseType
      ? { label: translate(`enums.environmentalLicenseType.${environmentalLicenseType}`), value: environmentalLicenseType }
      : null;
  };

  render() {
    const {
      licenseDocument,
      licenseNumber,
      expireDate,
      issuingBody,
      licenseDocumentError,
      licenseNumberError,
      environmentalLicenseTypeError,
      expireDateError,
      issuingBodyError,
    } = this.state;
    const { defaultForm, title } = this.props;
    return (
      <div className="enviromental-license__items">
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <div style={{ position: 'relative' }}>
              <h1>{title ? title : translate('environmentalLicense.title')}</h1>
              {!defaultForm && <div className="form__filter-open--close" onClick={this.props.onClose} />}
            </div>
            <AttachmentComponent
              attachment={licenseDocument}
              error={licenseDocumentError.hasError}
              errorMessage={translateErrorMessage(licenseDocumentError.errorMessage)}
              attachmentTitle={translate('environmentalLicense.form.license')}
              onFileAttached={this.onAttachLicense}
            />

            <InlineSelect
              placeholder={translate('environmentalLicense.form.licenseType')}
              error={environmentalLicenseTypeError.hasError}
              errorMessage={translateErrorMessage(environmentalLicenseTypeError.errorMessage)}
              options={this.mapEnvironmentalTypeToOptions()}
              onChange={this.onSelectEnvironmentalLicenseType}
              startIndex={0}
              selectedOption={this.mapSelectedLicenseTypeToSelect()}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  licenseNumber: text,
                })
              }
              id="licenseNumber"
              style={{ marginTop: '20px' }}
              value={licenseNumber}
              label={translate('environmentalLicense.form.licenseNumber')}
              placeholder={translate('environmentalLicense.form.licenseNumber')}
              error={licenseNumberError.hasError}
              errorText={translateErrorMessage(licenseNumberError.errorMessage)}
              onBlur={this.onValidateLicenseNumber}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  issuingBody: text,
                })
              }
              id="issuingBody"
              style={{ marginTop: '20px' }}
              onMask={maskAlphaNumeric}
              value={issuingBody}
              label={translate('environmentalLicense.form.issuingBody')}
              placeholder={translate('environmentalLicense.form.issuingBody')}
              error={issuingBodyError.hasError}
              errorText={translateErrorMessage(issuingBodyError.errorMessage)}
              onBlur={this.onValidateIssuingBody}
            />
            <div style={{ marginTop: '20px' }}>
              <DatePickerComponent
                dateFormat={APP_LOCAL_DATE_FORMAT}
                onDateChange={value => {
                  this.setState(
                    {
                      expireDate: value,
                    },
                    () => this.onValidateExpireDate()
                  );
                }}
                dateSelected={expireDate}
                placeholder={translate('environmentalLicense.form.expireDate')}
                error={expireDateError.hasError}
                errorMessage={translateErrorMessage(expireDateError.errorMessage)}
              />
            </div>
            {!defaultForm && <ButtonComponent title={translate('entity.action.save')} onClick={this.onNextHandler} />}
            {defaultForm && (
              <NextCancelButton
                nextButtonName={translate('entity.action.save')}
                cancelButtonName={translate('entity.action.cancel')}
                onNextClick={this.onNextHandler}
                onCancelClick={this.props.onClose}
                noArrow
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentalLicenseForm);

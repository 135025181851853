import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {TextFormat, Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {getEntities} from './environmental-license.reducer';

export interface IEnvironmentalLicenseProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class EnvironmentalLicense extends React.Component<IEnvironmentalLicenseProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { environmentalLicenseList, match } = this.props;
    return (
      <div>
        <h2 id="environmental-license-heading">
          <Translate contentKey="resitrackApp.environmentalLicense.home.title">Environmental Licenses</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="resitrackApp.environmentalLicense.home.createLabel">Create new Environmental License</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {environmentalLicenseList && environmentalLicenseList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.environmentalLicense.licenseNumber">License Number</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.environmentalLicense.expireDate">Expire Date</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.environmentalLicense.issuingBody">Issuing Body</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.environmentalLicense.environmentalLicenseType">
                      Environmental License Type
                    </Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.environmentalLicense.licenseDocument">License Document</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.environmentalLicense.producer">Producer</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {environmentalLicenseList.map((environmentalLicense, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${environmentalLicense.id}`} color="link" size="sm">
                        {environmentalLicense.id}
                      </Button>
                    </td>
                    <td>{environmentalLicense.licenseNumber}</td>
                    <td>
                      <TextFormat type="date" value={environmentalLicense.expireDate} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                    <td>
                      <Translate contentKey={`resitrackApp.IssuingBody.${environmentalLicense.issuingBody}`} />
                    </td>
                    <td>
                      <Translate contentKey={`resitrackApp.EnvironmentalLicenseType.${environmentalLicense.environmentalLicenseType}`} />
                    </td>
                    <td>
                      {environmentalLicense.licenseDocument ? (
                        <Link to={`document/${environmentalLicense.licenseDocument.id}`}>{environmentalLicense.licenseDocument.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {environmentalLicense.producer ? (
                        <Link to={`producer/${environmentalLicense.producer.id}`}>{environmentalLicense.producer.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${environmentalLicense.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${environmentalLicense.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${environmentalLicense.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="resitrackApp.environmentalLicense.home.notFound">No Environmental Licenses found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ environmentalLicense }: IRootState) => ({
  environmentalLicenseList: environmentalLicense.entities,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentalLicense);

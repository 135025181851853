import React from 'react';
import { translate, Translate } from 'react-jhipster';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { UnitService } from 'app/services/unit';
import { IUnit } from 'app/shared/model/unit';

import { getEntitiesByStateId as getCitiesByState } from 'app/entities/city/city.reducer';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

interface IMtrUnitsDetailsProps extends StateProps, DispatchProps { }


const MtrUnitsDetails = (props: IMtrUnitsDetailsProps) => {
    const history = useHistory();

    const [unit, setUnit] = useState<IUnit>({} as IUnit);

    const getUnit = () => {
        const { id } = (history.location.state as any) || { id: null };

        UnitService.getUnit(id)
            .then((response) => {
                setUnit(response);
            })
    }

    const handleGoToEdit = () => {
        history.push({
            pathname: "/mtr-units/edit",
            state: { id: unit.id },
        });
    }

    const renderComponent = (title: string, info: string) => {
        if (info === '' || info == null) return;

        return (
            <div className="list__items--component">
                <div className="list__items--title">{title}</div>
                <div className="list__items--text">{info}</div>
            </div>
        )
    };

    const renderComponentGroupTitle = (text: string) => (
        <div className="list__items--title-second">{text}</div>
    )

    const getCityName = (cityId: number) => {
        const { cities } = props;

        const cityName = cities.find(city => city.id === cityId)?.name
        return cityName;
    }

    const renderAddressTitleAndFields = () => {
        return (
            <>
                {renderComponentGroupTitle(translate('unitTranslation.address'))}

                {renderComponent(translate('unitTranslation.form.street'), unit?.address?.street)}
                {renderComponent(translate('unitTranslation.form.complement'), unit?.address?.complement)}
                {renderComponent(translate('unitTranslation.form.city'), getCityName(unit?.address?.city.id))}
                {renderComponent(translate('unitTranslation.form.zipcode'), unit?.address?.zipcode)}
            </>
        )
    }

    useEffect(() => {
        getUnit();
    }, []);

    return (
        <div className="list__items">
            <div className="list__items--back">
                <Container>
                    <Row>
                        <Col md="10">
                            <Link to="/mtr-units">
                                <div className="confirmation--back" >
                                    <div className="confirmation--back-icon" />
                                    <span>
                                        <Translate contentKey={'mtr-signature.back'} />
                                    </span>
                                </div>
                            </Link>
                        </Col>
                        <Col md="2">
                            <div className="list__items--edit" onClick={handleGoToEdit}>
                                <Translate contentKey={'mtr-signature.edit'} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <h1 style={{ fontWeight: 550 }}>{translate('unitTranslation.unit')}</h1>
            </div>

            <Col md="6" style={{ margin: '0 auto' }}>
                {renderComponent(translate('unitTranslation.form.fantasyName'), unit?.fantasyName)}
                {renderComponent(translate('unitTranslation.form.unitCode'), unit?.unitCode)}
                {renderComponent(translate('unitTranslation.form.cnpj'), unit?.cnpj)}
                {renderComponent(translate('unitTranslation.form.cpf'), unit?.cpf)}
                {renderComponent(translate('unitTranslation.form.login'), unit?.login)}
                {renderComponent(translate('unitTranslation.form.password'), unit?.password)}
                {renderComponent(translate('unitTranslation.form.environmentalAgency'), unit?.type)}

                {renderAddressTitleAndFields()}
            </Col>
        </div>
    );
}

const mapDispatchToProps = {
    getCitiesByState,
};

const mapStateToProps = (root: IRootState) => ({
    cities: root.city.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MtrUnitsDetails);
import { Cdf, defaultValue } from 'app/shared/model/cdf.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import { PageableResponse } from 'app/shared/model/pageable';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import StringUtils from 'app/shared/util/string-utils';

export const ACTION_TYPES = {
  RESET: 'cdf/RESET',
  FETCH_CDF: 'cdf/FETCH_CDF',
  CREATE_CDF: 'cdf/CREATE_CDF',
  UPDATE_CDF: 'cdf/UPDATE_CDF',
  DELETE_CDF: 'cdf/DELETE_CDF',
  SEARCH_CDF: 'cdf/SEARCH_CDF',
  GENERATE_CDF_REPORT: 'cdf/GENERATE_CDF_REPORT',
};

const initialState = {
  totalItems: 0,
  loading: false,
  updating: false,
  errorMessage: null,
  entity: defaultValue,
  updateSuccess: false,
  cdfs: [] as ReadonlyArray<Cdf>,
  entities: [] as ReadonlyArray<Cdf>,
  findCdfStatus: HttpRequestStatus.NOOP,
  createCdfStatus: HttpRequestStatus.NOOP,
  generateCdfReportStatus: HttpRequestStatus.NOOP,
};

export type CdfState = Readonly<typeof initialState>;

export default (state: CdfState = initialState, action): CdfState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GENERATE_CDF_REPORT):
      return {
        ...state,
        generateCdfReportStatus: HttpRequestStatus.ONGOING,
      };

    case REQUEST(ACTION_TYPES.FETCH_CDF):
    case REQUEST(ACTION_TYPES.SEARCH_CDF):
      return {
        ...state,
        loading: true,
        totalItems: 0,
        errorMessage: null,
        updateSuccess: false,
        findCdfStatus: HttpRequestStatus.ONGOING,
      };

    case REQUEST(ACTION_TYPES.CREATE_CDF):
    case REQUEST(ACTION_TYPES.UPDATE_CDF):
    case REQUEST(ACTION_TYPES.DELETE_CDF):
      return {
        ...state,
        updating: true,
        errorMessage: null,
        updateSuccess: false,
        createCdfStatus: HttpRequestStatus.ONGOING,
      };

    case FAILURE(ACTION_TYPES.GENERATE_CDF_REPORT):
      return {
        ...state,
        generateCdfReportStatus: HttpRequestStatus.ERROR,
      };

    case FAILURE(ACTION_TYPES.FETCH_CDF):
    case FAILURE(ACTION_TYPES.SEARCH_CDF):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        findCdfStatus: HttpRequestStatus.ERROR,
      };

    case FAILURE(ACTION_TYPES.CREATE_CDF):
    case FAILURE(ACTION_TYPES.UPDATE_CDF):
    case FAILURE(ACTION_TYPES.DELETE_CDF):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        createCdfStatus: HttpRequestStatus.ERROR,
      };

    case SUCCESS(ACTION_TYPES.GENERATE_CDF_REPORT):
      return {
        ...state,
        generateCdfReportStatus: HttpRequestStatus.SUCCESS,
      };

    case SUCCESS(ACTION_TYPES.FETCH_CDF):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
        findCdfStatus: HttpRequestStatus.SUCCESS,
      };

    case SUCCESS(ACTION_TYPES.SEARCH_CDF):
      return {
        ...state,
        loading: false,
        cdfs: action.payload.data?.content,
        findCdfStatus: HttpRequestStatus.SUCCESS,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };

    case SUCCESS(ACTION_TYPES.CREATE_CDF):
    case SUCCESS(ACTION_TYPES.UPDATE_CDF):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        createCdfStatus: HttpRequestStatus.SUCCESS,
      };

    case SUCCESS(ACTION_TYPES.DELETE_CDF):
      return {
        ...state,
        entity: {},
        updating: false,
        updateSuccess: true,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const apiUrl = 'api/cdfs';

export const searchCdfs: ICrudSearchActionCustom<PageableResponse<Cdf>> = (page: number, size: number, sort: string, search?: string) => {
  const pageableAndSearchText = `?page=${page ?? 0}&size=${size ?? 0}&sort=${sort ?? ''}&searchText=${search ?? ''}`;
  return {
    type: ACTION_TYPES.SEARCH_CDF,
    payload: axios.get<PageableResponse<Cdf>>(`${apiUrl}${pageableAndSearchText}&cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<Cdf> = cdfNumber => {
  const requestUrl = `${apiUrl}/cdf-number/${cdfNumber}`;
  return {
    type: ACTION_TYPES.FETCH_CDF,
    payload: axios.get<Cdf>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<Cdf> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CDF,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<Cdf> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CDF,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const generateCdfReport = (cdfNumber: string) => async dispatch => {
  const result = await dispatch({
    responseType: 'blob',
    type: ACTION_TYPES.GENERATE_CDF_REPORT,
    payload: axios.get<any>(`${apiUrl}/report/${cdfNumber}`),
  });

  let fileName = 'cdf.pdf';

  if (result.action.payload.headers['content-disposition'] != null) {
    fileName = String(result.action.payload.headers['content-disposition']).split('filename=')[1];
  }

  const blobUrl = StringUtils.generateFileUrl(result.action.payload.data, 'application/pdf');
  const link = document.createElement('a');

  link.href = blobUrl;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  return result;
};

export const deleteEntity: ICrudDeleteAction<Cdf> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CDF,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './custom-cdf-list.scss';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import { translate, Translate } from 'react-jhipster';
import { searchCdfs } from 'app/entities/cdf/cdf.reducer';
import { Pageable } from 'app/shared/model/pageable';
import moment from 'moment';
import { Cdf } from 'app/shared/model/cdf.model';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATE_FORMAT_ORDER_BY, AUTHORITIES } from 'app/config/constants';
import { CdfStatus } from 'app/shared/model/enum/cdfStatus';
import { IMtr } from 'app/shared/model/mtr.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';

interface ICustomCdfListProps extends StateProps, DispatchProps, RouteComponentProps {
    // TODO
    cdf: Cdf;
}

interface ICustomCdfListState {
    page: Pageable;
    searchText: string;
}

class CustomCdfList extends React.Component<ICustomCdfListProps, ICustomCdfListState> {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            page: {
                size: 5,
                page: 0,
                totalPages: 0,
                totalElements: 0,
                sort: 'emissionDate,desc'
            }
        }
    }

    componentDidMount() {
        this.getAllCdfs();
    }

    componentWillReceiveProps(nextProps: Readonly<ICustomCdfListProps>) {
        if (nextProps.totalItems !== this.state.page.totalElements && this.state.page.totalElements == 0) {
            this.setState({
                page: {
                    ...this.state.page,
                    totalElements: nextProps.totalItems,
                },
            });
        }
    }

    getAllCdfs = () => {
        const { searchCdfs } = this.props;
        const { page, searchText } = this.state;
        searchCdfs(page.page, page.size, page.sort, searchText);
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: {
                    ...this.state.page,
                    page: newPage
                },
            },
            () => this.getAllCdfs()
        );
    };

    handleSort = (sortCode: string) => {
        this.setState(
            {
                page: {
                    ...this.state.page,
                    sort: sortCode
                },
            },
            () => this.getAllCdfs()
        );
    };

    onHandleFilterChanged = (searchText: string) => {
        this.setState({
            searchText
        }, () => this.getAllCdfs());
    };

    resetPage = () => {
        this.setState({
            page: {
                size: 5,
                page: 0,
                totalPages: 0,
                totalElements: 0,
                sort: 'emissionDate,desc'
            }
        });
    };

    tableHeaders: ColumnSort[] = [
        { name: translate('cdf.list.emissionDate'), sortCode: 'emissionDate' },
        this.props.hasFinalDestinationAuthorities
            ? { name: translate('cdf.list.producer'), sortCode: 'producer.companyName' }
            : { name: translate('cdf.list.finalDestination'), sortCode: 'finalDestination.legalPerson.companyName' },
        { name: translate('cdf.list.cdfNumber'), sortCode: 'cdfNumber' },
        { name: translate('cdf.list.period'), sortCode: '' },
        { name: translate('mtr-list.table.status'), sortCode: 'cdfStatus' },
    ];

    handleTransformToTableContent = (content?: Cdf[]) => {
        const { hasFinalDestinationAuthorities } = this.props;

        if (isEmpty(content)) return [];

        const result: (string | JSX.Element)[][] = [];

        content?.forEach((item: Cdf) => {
            result.push([
                moment(item.emissionDate).format(APP_LOCAL_DATE_FORMAT),
                hasFinalDestinationAuthorities ? item.producer.legalPerson?.companyName || item.producer.naturalPerson?.name : item.finalDestination.legalPerson?.companyName || item.finalDestination.naturalPerson?.name,
                item.cdfNumber,
                this.getCdfPeriod(item.mtrs || []),
                this.getCdfStatusStyle(item.cdfStatus)
            ]);
        });

        return result;
    };

    getCdfPeriod = (mtrs: IMtr[]) => {
        const { cdf } = this.props;
        if (cdf?.startDate == null) {
            const mtrsCopy = cloneDeep(mtrs);
            const orderedMtrsCopy: IMtr[] = orderBy(mtrsCopy, (item: IMtr) => moment(item.collectionDate).format(APP_LOCAL_DATE_FORMAT_ORDER_BY), ['asc']);
            const initialDate = orderedMtrsCopy?.[0]?.collectionDate ? moment(orderedMtrsCopy[0].collectionDate).format(APP_LOCAL_DATE_FORMAT) : '';
            const finalDate = orderedMtrsCopy?.[orderedMtrsCopy.length - 1]?.collectionDate ? moment(orderedMtrsCopy[orderedMtrsCopy.length - 1].collectionDate).format(APP_LOCAL_DATE_FORMAT) : '';
            return initialDate && finalDate ? `${initialDate} a ${finalDate}` : '';
        }
        const initialDate = cdf.startDate ? moment(cdf.startDate).format(APP_LOCAL_DATE_FORMAT) : '';
        const finalDate = cdf.endDate ? moment(cdf.endDate).format(APP_LOCAL_DATE_FORMAT) : '';
        return initialDate && finalDate ? `${initialDate} - ${finalDate}` : '';
    };

    getCdfStatusStyle = (cdfStatus: CdfStatus) => {
        if (cdfStatus == null) return <div />;

        let style = 'status-tag ';

        switch (cdfStatus) {
            case CdfStatus.APPROVED:
                style = style.concat('complete');
                break;

            case CdfStatus.CANCELLED:
                style = style.concat('cancelled');
                break;

            case CdfStatus.PENDING:
                style = style.concat('pending');
                break;
        }

        return (
            <div className={style}>
                <span>{translate(`cdf.status.${cdfStatus}`)?.toUpperCase()}</span>
            </div>
        );
    };

    onClickMtr = (cdfNumber: number) => {
        this.props.history.push(`${this.props.match.url}/${cdfNumber}/manage`);
    };

    redirectToCreateCdf = () => {
        this.props.history.push(`${this.props.match.url}/create`);
    };

    redirectToManageCdf = (index: number) => {
        const { cdfs } = this.props;
        const indexCdfNumber = cdfs[index].cdfNumber;
        this.props.history.push(`${this.props.match.url}/${indexCdfNumber}/manage`);
    };

    render() {
        const { page } = this.state;
        const { cdfs } = this.props;
        // @ts-ignore
        const rows: (string | JSX.Element)[][] = this.handleTransformToTableContent(cdfs);
        return (
            <div className="mtr-list__items">
                <Container>
                    <Row>
                        <Col style={{ margin: 'auto' }} md="12">
                            <h1 style={{ textAlign: 'left', marginTop: '28px' }}>{translate('cdf.title')}</h1>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md="6">
                            <div className="mtr-list__search is-cdf">
                                <div className="form-inline md-form">
                                    <SimpleSearchField placeholder={translate('cdf.search')} onChange={this.onHandleFilterChanged} />
                                    <div className="mtr-list__search--icon" />
                                </div>
                            </div>
                        </Col>
                        <Col md={{ offset: '4', span: '2' }}>
                            <Button className={'button primary'} type={'submit'} onClick={this.redirectToCreateCdf}>
                                <Translate contentKey={'cdf.button'} />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SimpleOrderedTable
                                rows={rows}
                                page={page}
                                onSort={this.handleSort}
                                columNameKeys={this.tableHeaders}
                                onClick={this.redirectToManageCdf}
                                onChangePage={this.handleChangePage}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (root: IRootState) => ({
    cdfs: root.cdf.cdfs,
    totalItems: root.cdf.totalItems,
    hasProducerAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER, AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
    hasFinalDestinationAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER,])
});

const mapDispatchToProps = {
    searchCdfs
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomCdfList);

import React, { Component } from 'react';
import './import-mtr-modal.scss';
import DatePickerComponent from '../date-picker-component/date-picker-component';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { getIntegrationUsers } from 'app/modules/generic/user-integration/user-integration.reducer';
import { IIntegrationUser } from 'app/shared/model/integration-user.model';
import { toast } from 'react-toastify';
import axios from 'axios';
import { IRootState } from 'app/shared/reducers';
import { IMtr } from 'app/shared/model/mtr.model';
import moment from 'moment';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    totalItems: number;
    integrationUsers: IIntegrationUser[];
    mtrs: IMtr[];
    getIntegrationUsers: () => void;
    children?: React.ReactNode;
}

interface ModalState {
    isModalOpen: boolean;
    startDate: string;
    endDate: string;
    errorMessage: string;
    selectedUserId: number | null;
    integrationUsers: IIntegrationUser[];
    mtrs: IMtr[];
    isLoading: boolean;
    isCompleted: boolean;
}

class ImportMtrModal extends Component<ModalProps, ModalState> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
            isModalOpen: false,
            startDate: '',
            endDate: '',
            errorMessage: '',
            selectedUserId: null,
            integrationUsers: [],
            mtrs: [],
            isLoading: false,
            isCompleted: false,
        };
    }

    componentDidUpdate(prevProps: ModalProps) {
        if (prevProps.integrationUsers !== this.props.integrationUsers) {
            this.setState({ integrationUsers: this.props.integrationUsers });
        }
        if (prevProps.mtrs !== this.props.mtrs) {
            this.setState({ mtrs: this.props.mtrs });
        }
    }

    handleOpenModal = async () => {
        this.setState({ isModalOpen: true });
        try {
            await this.props.getIntegrationUsers();
        } catch (error) {
            console.error('Erro ao obter usuários de integração:', error);
        }
    };

    handleImport = async () => {
        const { startDate, selectedUserId, endDate } = this.state;
        if (startDate.trim() === '') {
            this.setErrorMessage('Por favor, selecione uma Data Inicial!');
            return;
        }
        if (selectedUserId === null) {
            this.setErrorMessage('Por favor, selecione um usuário!');
            return;
        }
        if (endDate === null) {
            this.setErrorMessage('Por favor, Selecione uma Data Final!');
            return;
        }

        this.setState({ isLoading: true, errorMessage: '', isCompleted: false });

        try {
            const response = await axios.get(`/consulta-mtr-sinir/${startDate}/${endDate}/${selectedUserId}`);
            console.log('Mtr status retrieved:', response.data);
            console.log(`Data Inicial selecionada: ${startDate}`);
            console.log(`Data Final selecionada: ${endDate}`)
            console.log(`ID do usuário selecionado: ${selectedUserId}`);
            console.log(`Total de itens: ${this.props.totalItems}`);

            this.setState({ isLoading: false, isCompleted: true });
            toast.success("MTRS importados com sucesso!");
            // Aqui você pode fechar o modal após a conclusão da chamada
            setTimeout(() => {
                this.handleCancel(); // Exemplo de como fechar o modal após 2 segundos
                window.location.reload();
            }, 2000); // Ajuste conforme necessário

        } catch (error) {
            console.error('Erro na importação!', error);
            toast.warn(
                'O sistema governamental está passando por instabilidades, aguarde 30 minutos e recarregue a página, caso os MTR não tenham sido importados, tente novamente!',
                { autoClose: 60000 } // 60000 milissegundos = 1 minuto
            );
            this.setState({ isLoading: false });
        }
    };

    handleCancel = () => {
        this.setState({ startDate: '', endDate: '', errorMessage: '', selectedUserId: null, isModalOpen: false, isLoading: false, isCompleted: false });
        this.props.onClose();
    };

    setErrorMessage = (message: string) => {
        this.setState({ errorMessage: message });
    };

    handleUserSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ selectedUserId: parseInt(event.target.value) });
    };

    render() {
        const { isOpen, children, integrationUsers } = this.props;
        const { startDate, endDate, errorMessage, isLoading, isCompleted } = this.state;

        if (!isOpen) {
            return null;
        }

        // Calcula a data máxima como o último dia do mês da startDate
        const maxEndDate = startDate ? moment(startDate).endOf('month').format('YYYY-MM-DD') : null;

        // Mantém a data mínima como a data inicial
        const minEndDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;

        // Define o maxStartDate como o mesmo que o endDate
        const maxStartDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;

        // Calcula a data mínima como o primeiro dia do mês da endDate
        const minStartDate = endDate ? moment(endDate).startOf('month').format('YYYY-MM-DD') : null;

        console.log(`startDate: ${startDate}, minEndDate: ${minEndDate}, maxEndDate: ${maxEndDate}`);
        console.log(`endDate: ${endDate}, minStartDate: ${minStartDate}, maxStartDate: ${maxStartDate}`);

        return (
            <div className="modal-overlay">
                <div className="modal-contents">
                    {children}
                    <div className='dates'>
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={(date) => this.setState({ startDate: date })}
                            dateSelected={startDate}
                            placeholder={translate('mtr-list.filter-open.startDate')}
                            minDate={minStartDate}
                            maxDate={maxStartDate}
                        />
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={(date) => this.setState({ endDate: date })}
                            dateSelected={endDate}
                            placeholder={translate('mtr-list.filter-open.endDate')}
                            minDate={minEndDate}
                            maxDate={maxEndDate}
                        />
                    </div>

                    {integrationUsers.length > 0 ? (
                        <select className='dropdown-integration' onChange={this.handleUserSelection} defaultValue="">
                            <option value="" disabled>
                                Selecione um usuário de integração
                            </option>
                            {integrationUsers.map((user) => (
                                // Verifica se unitCode e fantasyName estão definidos e não são vazios
                                (user.unit?.unitCode && user.unit?.fantasyName) && (
                                    <option key={user.id} value={user.id}>
                                        {`${user.unit.unitCode} - ${user.unit.fantasyName}`}
                                    </option>
                                )
                            ))}
                        </select>
                    ) : (
                        <p>Nenhum usuário de integração disponível</p>
                    )}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    {isLoading && <p>Importando MTRS, aguarde...</p>}
                    {isCompleted && <p>Importação concluída!</p>}
                    <div className="modal-buttons">
                        <button className="button-cancel" onClick={this.handleCancel}>
                            CANCELAR
                        </button>
                        <button className="button-import" onClick={this.handleImport}>
                            IMPORTAR
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (root: IRootState) => ({
    integrationUsers: root.integrationUser.integrationUsers,
    mtrs: root.mtr.mtrs,
});

const mapDispatchToProps = {
    getIntegrationUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportMtrModal);

import React from 'react';
import {connect} from 'react-redux';
import './user-signature-edit.scss';
import {checkStatusOnGoing, ValidationResult, ValidationResultInstance} from "app/shared/util/validation-utils";
import {IRootState} from "app/shared/reducers";
import {getSession} from "app/shared/reducers/authentication";
import {
  createSignature,
  getSignature,
  reset,
} from "app/modules/generic/profile/profile-reducer";
import {RouteComponentProps} from 'react-router-dom';
import Loading from "app/components/loading/loading";
import {Translate, translate} from 'react-jhipster';
import AlertModal from "app/components/alert-modal/alert-modal";
import {HttpRequestStatus} from "app/shared/model/enum/HttpRequestStatus";
import CustomTextField from "app/components/custom-text-field/custom-text-field";
import NextCancelButton from "app/components/next-cancel-button/next-cancel-button";
import {IUser} from "app/shared/model/user.model";
import {validateEmail, validatePersonName} from "app/modules/validation/validation-constants";
import {maskCnpj, maskCnpjOrCpf, maskCPF, maskPhone} from 'app/shared/util/mask-utils';
import {ISignature} from "app/shared/model/signature.model";
import {IDocument} from "app/shared/model/document.model";
import SignatureComponent from "app/components/signature-component/signature-component";
import {ALERT_ICONS} from "app/config/constants";


export interface UserSignatureEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface UserSignatureEditState {
  signature: ISignature;
  nameTechnical: string;
  email: string;
  cpfTechnical: string;
  phone: string;
  occupation: string;

  nameTechnicalError: ValidationResult;
  emailError: ValidationResult;
  cpfTechnicalError: ValidationResult;
  phoneError: ValidationResult;
  occupationError: ValidationResult;

  showSuccess: boolean;
  showErrorModal: boolean;
}

export class UserSignatureEdit extends React.Component<UserSignatureEditProps, UserSignatureEditState> {
  constructor(props: Readonly<UserSignatureEditProps>) {
    super(props);
    this.state = {
      signature: null,
      nameTechnical: null,
      email: null,
      cpfTechnical: null,
      phone: null,
      occupation: null,

      nameTechnicalError: ValidationResultInstance,
      emailError: ValidationResultInstance,
      cpfTechnicalError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
      occupationError: ValidationResultInstance,

      showErrorModal: false,
      showSuccess: false,
    };
  }

  async componentDidMount() {
    this.props.getSignature();
    this.props.getSession();
  }

  componentWillUnmount() { }

  componentWillReceiveProps(newProps) {
    if (newProps.account != null && newProps.account != this.props.account) {
      this.setState({
        nameTechnical: newProps.account.nameTechnical,
        email: newProps.account.email,
        cpfTechnical: newProps.account.cpfTechnical,
        phone: newProps.account.phone,
        occupation: newProps.account.occupation,
      });
    }
    if (newProps.updateAccountStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
      this.props.getSession();
      this.props.reset();
    }
    if (newProps.fetchSignatureStatus === HttpRequestStatus.SUCCESS && newProps.fetchSignatureStatus != this.props.fetchSignatureStatus) {
      this.setState({
        signature: newProps.signature,
      });
    }
    if (newProps.createSignatureStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
    }
    if (newProps.createSignatureStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  handleFileAttach = (document: IDocument) => {
    this.setState(prevState => ({
      signature: {
        ...prevState.signature,
        signatureDocument: document,
      },
    }));
  };


  search = () => { };

  validateAllFields = () => {
    let hasError = false;
    if (this.onValidateEmail()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateName = () => {
    const { nameTechnical } = this.state;
    if (nameTechnical == null || nameTechnical.length === 0) {
      return;
    }
    const validate = validatePersonName(nameTechnical);
    this.setState({
      nameTechnicalError: validate,
    });
    return validate.hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    if (email == null || email.length === 0) {
      return;
    }
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onSubmitSignature = () => {
    if (this.validateAllFields()) {
      return;
    }

    const { signature, nameTechnical, email, cpfTechnical, phone, occupation} = this.state;

    const user: IUser = {
      nameTechnical: nameTechnical && nameTechnical.length > 0 ? nameTechnical : null,
      email: email && email.length > 0 ? email : null,
      occupation: occupation && occupation.length > 0 ? occupation : null,
      cpfTechnical: cpfTechnical && cpfTechnical.length > 0 ? cpfTechnical : null,
      phone: phone && phone.length > 0 ? phone : null,
    };
    signature.signatureDocument.id = null;
    signature.user = user;
    this.props.createSignature(signature);
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  toggleSuccessModal = () => {
    this.setState({
      showSuccess: !this.state.showSuccess,
    });
  };


  onBackHandler = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, updateAccountStatus, account, fetchSignatureStatus, createSignatureStatus } = this.props;
    const {
      nameTechnical,email,occupation,cpfTechnical, phone,nameTechnicalError,emailError, occupationError
      ,cpfTechnicalError, phoneError,showErrorModal,showSuccess, signature
    } = this.state;
    if (loading || checkStatusOnGoing([fetchSignatureStatus, createSignatureStatus, updateAccountStatus])) {
      return <Loading />;
    }
    return (
      <div>
        <div className="user-profile-menu__items--title">
          <Translate contentKey={'profile.personal-data.responsibleTecnican'} />
        </div>
        <CustomTextField
          id={'nameTechnical'}
          style={{ marginTop: '10px' }}
          value={nameTechnical}
          onChange={(text: string) =>
            this.setState({
              nameTechnical: text,
            })
          }
          error={nameTechnicalError.hasError}
          errorText={nameTechnicalError.errorMessage ? translate(nameTechnicalError.errorMessage) : ''}
          label={translate('global.account.edit.nameTechnical')}
          placeholder={translate('global.account.edit.nameTechnical')}
          onBlur={this.onValidateName}
        />
        <CustomTextField
          id={'email'}
          style={{ marginTop: '10px' }}
          value={email}
          onChange={(text: string) =>
            this.setState({
              email: text,
            })
          }
          error={emailError.hasError}
          errorText={emailError.errorMessage ? translate(emailError.errorMessage) : ''}
          label={translate('global.account.edit.email')}
          placeholder={translate('global.account.edit.email')}
          onBlur={this.onValidateEmail}
        />

        <CustomTextField
          id={'cpf'}
          style={{ marginTop: '10px' }}
          value={cpfTechnical}
          onChange={(text: string) =>
            this.setState({
              cpfTechnical: text,
            })
          }
          onMask={maskCPF}
          error={cpfTechnicalError.hasError}
          errorText={cpfTechnicalError.errorMessage ? translate(cpfTechnicalError.errorMessage) : ''}
          label={translate('global.account.edit.cpf')}
          placeholder={translate('global.account.edit.cpf')}
          //onBlur={this.onValidateCpf}
        />
        <CustomTextField
          id={'occupation'}
          style={{ marginTop: '10px' }}
          value={occupation}
          onChange={(text: string) =>
            this.setState({
              occupation: text,
            })
          }
          error={occupationError.hasError}
          errorText={occupationError.errorMessage ? translate(occupationError.errorMessage) : ''}
          label={translate('global.account.edit.occupation')}
          placeholder={translate('global.account.edit.occupation')}
          //onBlur={this.onValidateCpf}
        />
        <CustomTextField
          id={'phone'}
          style={{ marginTop: '10px' }}
          value={phone}
          onChange={(text: string) =>
            this.setState({
              phone: text,
            })
          }
          onMask={maskPhone}
          error={phoneError.hasError}
          errorText={phoneError.errorMessage ? translate(phoneError.errorMessage) : ''}
          label={translate('global.account.edit.phone')}
          placeholder={translate('global.account.edit.phone')}
          //onBlur={this.onValidateCpf}
        />

        <div className="user-signature__items--title">
          <Translate contentKey={'profile.digital-signature.title'} />
        </div>
        <div className="user-signature__items--subtitle">
          <Translate contentKey={'profile.digital-signature.subtitle'} />
        </div>

        <SignatureComponent
          signature={signature ? signature.signatureDocument : null}
          responsibleName={account.name}
          onFileAttached={this.handleFileAttach}
        />

        <div className={'button-container'}>
          <button
            className={'button third'}
            type={'submit'}
            onClick={() => this.props.history.goBack()}
          >
           <Translate contentKey={'entity.action.back'} />
          </button>

          <button
            className={'button fourth button-save-container'}
            //disabled={signature == null || signature.signatureDocument == null || signature.signatureDocument.file == null}
            type={'submit'}
            onClick={this.onSubmitSignature}
          >
            <Translate contentKey={'mtr-signature.button-save'} />
          </button>
        </div>

        {/*
        <NextCancelButton
          onCancelClick={() => this.props.history.goBack()}
          onNextClick={this.onNextClick}
          nextButtonName={translate('entity.action.save')}
          noArrow
        />
        */}


        <AlertModal
          showModal={showSuccess}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.onBackHandler}
          statusImage="garbage.svg"
          alertMessage="alert.accountUpdate"
        />
        <AlertModal
          showModal={showErrorModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.account"
        />

        <AlertModal
          showModal={this.state.showErrorModal}
          buttonTwoActionMessage={'entity.action.ok'}
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage={this.state.emailError ? 'errorMessages.technical' : 'errorMessages.technical'}
        />
      </div>
    );
  }

}

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  loading: root.authentication.loading,
  updateAccountStatus: root.profile.updateAccountStatus,
  signature: root.profile.signature,
  fetchSignatureStatus: root.profile.fetchSignatureStatus,
  createSignatureStatus: root.profile.createSignatureStatus,
});

const mapDispatchToProps = {
  getSession,
  getSignature,
  createSignature,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserSignatureEdit);

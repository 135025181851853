import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './source-residue.reducer';

// tslint:disable-next-line:no-unused-variable

export interface ISourceResidueDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class SourceResidueDetail extends React.Component<ISourceResidueDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { sourceResidueEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.sourceResidue.detail.title">SourceResidue</Translate> [<b>{sourceResidueEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="sourceId">
                <Translate contentKey="resitrackApp.sourceResidue.sourceId">Source Id</Translate>
              </span>
            </dt>
            <dd>{sourceResidueEntity.sourceId}</dd>
            <dt>
              <span id="sourceType">
                <Translate contentKey="resitrackApp.sourceResidue.sourceType">Source Type</Translate>
              </span>
            </dt>
            <dd>{sourceResidueEntity.sourceType}</dd>
            <dt>
              <Translate contentKey="resitrackApp.sourceResidue.residue">Residue</Translate>
            </dt>
            <dd>{sourceResidueEntity.residue ? sourceResidueEntity.residue.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.sourceResidue.specificResidue">Specific Residue</Translate>
            </dt>
            <dd>{sourceResidueEntity.specificResidue ? sourceResidueEntity.specificResidue.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/source-residue" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/source-residue/${sourceResidueEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ sourceResidue }: IRootState) => ({
  sourceResidueEntity: sourceResidue.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SourceResidueDetail);

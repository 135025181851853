import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getAddresses } from 'app/entities/address/address.reducer';
import { getEntities as getCnaeCodes } from 'app/entities/cnae-code/cnae-code.reducer';
import { IRootState } from 'app/shared/reducers';
import { mapIdList } from 'app/shared/util/entity-utils';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './transporter.reducer';


export interface ITransporterUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ITransporterUpdateState {
  isNew: boolean;
  idscnaeCodes: any[];
  addressId: string;
}

export class TransporterUpdate extends React.Component<ITransporterUpdateProps, ITransporterUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      idscnaeCodes: [],
      addressId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getCnaeCodes();
    this.props.getAddresses();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { transporterEntity } = this.props;
      const entity = {
        ...transporterEntity,
        ...values,
        cnaeCodes: mapIdList(values.cnaeCodes),
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/transporter');
  };

  render() {
    const { transporterEntity, cnaeCodes, addresses, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.transporter.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.transporter.home.createOrEditLabel">Create or edit a Transporter</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : transporterEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="transporter-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="transporter-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="fantasyNameLabel" for="transporter-fantasyName">
                    <Translate contentKey="resitrackApp.transporter.fantasyName">Fantasy Name</Translate>
                  </Label>
                  <AvField id="transporter-fantasyName" type="text" name="fantasyName" />
                </AvGroup>
                <AvGroup>
                  <Label id="cnpjLabel" for="transporter-cnpj">
                    <Translate contentKey="resitrackApp.transporter.cnpj">Cnpj</Translate>
                  </Label>
                  <AvField id="transporter-cnpj" type="text" name="cnpj" />
                </AvGroup>
                <AvGroup>
                  <Label id="phoneLabel" for="transporter-phone">
                    <Translate contentKey="resitrackApp.transporter.phone">Phone</Translate>
                  </Label>
                  <AvField id="transporter-phone" type="text" name="phone" />
                </AvGroup>
                <AvGroup>
                  <Label id="companyNameLabel" for="transporter-companyName">
                    <Translate contentKey="resitrackApp.transporter.companyName">Company Name</Translate>
                  </Label>
                  <AvField id="transporter-companyName" type="text" name="companyName" />
                </AvGroup>
                <AvGroup>
                  <Label id="municipalRegistrationLabel" for="transporter-municipalRegistration">
                    <Translate contentKey="resitrackApp.transporter.municipalRegistration">Municipal Registration</Translate>
                  </Label>
                  <AvField id="transporter-municipalRegistration" type="text" name="municipalRegistration" />
                </AvGroup>
                <AvGroup>
                  <Label id="personTypeLabel" for="transporter-personType">
                    <Translate contentKey="resitrackApp.transporter.personType">Person Type</Translate>
                  </Label>
                  <AvInput
                    id="transporter-personType"
                    type="select"
                    className="form-control"
                    name="personType"
                    value={(!isNew && transporterEntity.personType) || 'PF'}
                  >
                    <option value="NATURAL">{translate('resitrackApp.PersonType.NATURAL')}</option>
                    <option value="LEGAL">{translate('resitrackApp.PersonType.LEGAL')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="transporter-cnaeCodes">
                    <Translate contentKey="resitrackApp.transporter.cnaeCodes">Cnae Codes</Translate>
                  </Label>
                  <AvInput
                    id="transporter-cnaeCodes"
                    type="select"
                    multiple
                    className="form-control"
                    name="cnaeCodes"
                    value={transporterEntity.cnaeCodes && transporterEntity.cnaeCodes.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {cnaeCodes
                      ? cnaeCodes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="transporter-address">
                    <Translate contentKey="resitrackApp.transporter.address">Address</Translate>
                  </Label>
                  <AvInput id="transporter-address" type="select" className="form-control" name="address.id">
                    <option value="" key="0" />
                    {addresses
                      ? addresses.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/transporter" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  cnaeCodes: storeState.cnaeCode.entities,
  addresses: storeState.address.entities,
  transporterEntity: storeState.transporter.entity,
  loading: storeState.transporter.loading,
  updating: storeState.transporter.updating,
  updateSuccess: storeState.transporter.updateSuccess,
});

const mapDispatchToProps = {
  getCnaeCodes,
  getAddresses,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterUpdate);

import React from 'react';
import '../style-for-all-cards.scss';
import { Col, Row } from 'reactstrap';
import GenericCardForPlans from '../GenericCardForPlans';
import { Translate } from 'react-jhipster';

const TranshipmentAndFinaDestination = () => {
    return (
        <Row>
            <Col>
                <GenericCardForPlans isCardFree isJustCard>
                    <ul className='manager-card__container'>
                        <li>
                            <div className='manager-card__container--list-items'>
                                <span className='one-card'>
                                    <Translate contentKey="register.plans.cards.transhipment.firstText" />
                                </span>
                                <div>
                                    <span className='manager-card__container--list-items-icon-check' />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='manager-card__container--list-items'>
                                <span className='one-card'>
                                    <Translate contentKey="register.plans.cards.transhipment.secondText" />
                                </span>
                                <div>
                                    <span className='manager-card__container--list-items-icon-check' />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='manager-card__container--list-items'>
                                <span className='one-card'>
                                    <Translate contentKey="register.plans.cards.transhipment.fourthText" />
                                </span>
                                <div>
                                    <span className='manager-card__container--list-items-icon-check' />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='manager-card__container--list-items'>
                                <span className='one-card'>
                                    <Translate contentKey="register.plans.cards.transhipment.fifthText" />
                                </span>
                                <div>
                                    <span className='manager-card__container--list-items-icon-check' />
                                </div>
                            </div>
                        </li>
                    </ul>
                </GenericCardForPlans>
            </Col>
        </Row>
    );
}

export default TranshipmentAndFinaDestination;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './producer-update-menu.scss';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from 'app/modules/account/register/full-register/steps/steps';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { ProducerRegisterAddress } from 'app/modules/account/register/full-register/producer/steps/address/producer-register-address';
import { ProducerRegisterResponsible } from 'app/modules/account/register/full-register/producer/steps/responsible/producer-register-responsible';
import { ProducerRegisterDocumentation } from 'app/modules/account/register/full-register/producer/steps/documentation/producer-register-documentation';
import AlertModal from 'app/components/alert-modal/alert-modal';
// import { createHolding, createSubsidiary, reset } from '../../producer-units-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { GeneratorCompanyType } from 'app/shared/model/producer.model';
import { ALERT_ICONS } from 'app/config/constants';
import { getSession } from 'app/shared/reducers/authentication';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import ProducerUpdateHolding from '../holding/producer-update-holding';
import ProducerUpdateSubsidiary from '../subsidiary/producer-update-subsidiary';
import { reset, updateHolding, updateSubsidiary } from '../../producer-units-reducer';
import { getProducerDetailById, reset as resetProducer } from 'app/entities/user-producer/user-producer.reducer';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';

export interface IProducerUpdateMenuProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IProducerUpdateMenuState {
  generatorCompanyType: GeneratorCompanyType;
  steps: StepItem[];
  currentIndex: number;
  userProducer: IUserProducer;
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
}

export class ProducerUpdateMenu extends React.Component<IProducerUpdateMenuProps, IProducerUpdateMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userProducer: {},
      generatorCompanyType: null,
      steps: null,
      currentIndex: 0,
      showResponsabilityModal: false,
      showErrorModal: false,
    };
  }

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  componentDidMount() {
    this.props.resetProducer();
    const { id } = (this.props.location.state as any) || { id: null };
    if (id) {
      this.props.getProducerDetailById(id);
    } else {
      this.props.history.goBack();
    }
  }

  onNext = (userProducer: IUserProducer) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsabilityModal: true,
        userProducer,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userProducer,
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (
      newProps.producerIdDetail != null &&
      newProps.producerDetailIdStatus != this.props.producerDetailIdStatus &&
      newProps.producerDetailIdStatus === HttpRequestStatus.SUCCESS
    ) {
      this.setState({
        userProducer: newProps.producerIdDetail,
      });
      if (newProps.producerIdDetail.producer.generatorCompanyType === GeneratorCompanyType.HOLDING) {
        this.setSteps(GeneratorCompanyType.HOLDING);
      } else {
        this.setSteps(GeneratorCompanyType.SUBSIDIARY);
      }
    }
    if (newProps.updateHoldingStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.updateSubsidiaryStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.updateHoldingStatus != this.props.updateHoldingStatus && newProps.updateHoldingStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
    if (
      newProps.updateSubsidiaryStatus != this.props.updateSubsidiaryStatus &&
      newProps.updateSubsidiaryStatus === HttpRequestStatus.ERROR
    ) {
      this.toggleErrorModal();
    }
  }

  goToSuccessAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.producerUpdate',
        buttonUrl: '/producer/units',
      },
    });
  };

  onCancelModal = () => {
    this.setState({
      showResponsabilityModal: false,
    });
  };

  onConfirmModal = () => {
    const { userProducer } = this.state;
    if (userProducer.producer.generatorCompanyType === GeneratorCompanyType.HOLDING) {
      this.props.updateHolding(userProducer);
    } else {
      this.props.updateSubsidiary(userProducer);
    }
  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  setSteps = (companyType: GeneratorCompanyType) => {
    if (companyType === GeneratorCompanyType.HOLDING) {
      this.setState({
        steps: this.mapStepsHolding(),
      });
    } else {
      this.setState({
        steps: this.mapStepsSubsidiary(),
      });
    }
  };

  private mapStepsHolding = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => <ProducerUpdateHolding onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <ProducerRegisterAddress onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.responsible'),
      render: () => <ProducerRegisterResponsible onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => <ProducerRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
  ];

  private mapStepsSubsidiary = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => <ProducerUpdateSubsidiary onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <ProducerRegisterAddress onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.responsible'),
      render: () => <ProducerRegisterResponsible onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => <ProducerRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userProducer={this.state.userProducer} />,
    },
  ];

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  handleGoBack = () => {
    this.props.history.replace('/producer/units');
  };

  render() {
    const { steps, currentIndex } = this.state;
    const { producerDetailIdStatus, updateHoldingStatus, updateSubsidiaryStatus } = this.props;
    if (checkStatusOnGoing([producerDetailIdStatus, updateHoldingStatus, updateSubsidiaryStatus])) {
      return <Loading />;
    }
    return (
      <div className="producer-update-menu__items" style={{ position: 'relative' }}>
        <BackHeaderComponent onBack={this.handleGoBack} />
        {steps && <Steps steps={steps} onIndexClick={this.onIndexClick} currentIndex={currentIndex} />}
        <AlertModal
          showModal={this.state.showResponsabilityModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={this.onConfirmModal}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="producerRegister.responsibilityTerms"
        />
        <AlertModal
          showModal={this.state.showErrorModal}
          buttonTwoActionMessage={'entity.action.ok'}
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.producerUpdate"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSession,
  getProducerDetailById,
  updateSubsidiary,
  updateHolding,
  reset,
  resetProducer,
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  producerDetailIdStatus: root.userProducer.producerDetailIdStatus,
  producerIdDetail: root.userProducer.producerIdDetail,
  updateSubsidiaryStatus: root.producerUnits.updateSubsidiaryStatus,
  updateHoldingStatus: root.producerUnits.updateHoldingStatus,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerUpdateMenu);

import {
    IResidue,
    ResidueClassification,
    ResiduePackingGroup,
    ResidueState,
    ResidueTreatment
} from 'app/shared/model/residue.model';
import { IRootState } from 'app/shared/reducers';
import React, { Fragment, useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './specific-residue-select.scss';
import { translateErrorMessage } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import { StaticTableBarItem } from '../static-table-bar/static-table-bar';
import { ISourceResidue } from 'app/shared/model/source-residue.model';
import { getEntities as getSpecificResidues, reset } from 'app/entities/specific-residue/specific-residue.reducer';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import axios from 'axios';
import { Conditioning } from 'app/shared/model/enumerations/conditioning';
import sourceResidue from 'app/entities/source-residue/source-residue';
import { MeasurementType } from "app/shared/model/authorization.model";

export interface ISpecificResidueSelectProps extends StateProps, DispatchProps {
    onBackHandler: () => void;
    onNextHandler: (sourceResidues: ISourceResidue[]) => void;
    selectedResidues: ISourceResidue[];
}
export interface ISpecificResidueSelectState {
    position: number;
    temporary: boolean;
    visibleDensity: boolean;
    typeDensity: string;
    sourceResidues: ISourceResidue[];
    conditioningOptions: Conditioning[];
    message: string;
    secondMessage: string;
    rccMessage: string;
    fepamMessage: string;
}

export class SpecificResidueSelect extends React.Component<ISpecificResidueSelectProps, ISpecificResidueSelectState> {
    timer = null;
    private tableHeaders: StaticTableBarItem[] = [
        { name: translate('residue.headers.code'), width: '10%' },
        { name: translate('residue.headers.classification'), width: '10%' },
        { name: translate('residue.headers.measurementType'), width: '10%' },
        { name: translate('residue.headers.residueSubcategory'), width: '20%' },
        { name: translate('residue.headers.description'), width: '30%' },
        { name: '', width: '10%' },
    ];

    constructor(props: Readonly<ISpecificResidueSelectProps>) {
        super(props);
        this.state = {
            sourceResidues: this.mapResiduesToSourceResidue(this.props.selectedResidues),
            position: 0,
            temporary: false,
            conditioningOptions: [],
            visibleDensity: false,
            typeDensity: '',
            message: '',
            secondMessage: '',
            rccMessage: '',
            fepamMessage: '',
        };
    }

    mapResiduesToSourceResidue = (residues: IResidue[]) => {
        return residues.map((item, index) => {
            const sourceResidue: ISourceResidue = {
                residue: item,
                error: {
                    inputClass: false,
                    inputSpecificResidue: false,
                    inputResidueState: false,
                    inputConditioning: false,
                    inputResidueTreatment: false,
                    inputBoardingName: false,
                    inputPackingGroup: false,
                    inputMeasurementType: false,
                },
            };
            return sourceResidue;
        });
    };


    componentDidMount() {
        this.props.reset();
        this.props.getSpecificResidues();
        this.alertResidue();

        let sourceResiduesUpdated = this.state.sourceResidues.map(sourceResidue => {
            if (sourceResidue.residue.residueClassification === ResidueClassification.DANGEROUS) {
                sourceResidue.sourceResidueClassification = 'DANGEROUS'
            }
            return sourceResidue
        })
        this.setState({ sourceResidues: sourceResiduesUpdated })
    }

    componentWillUnmount() {
        this.props.reset();
    }

    private handleTransformToTableContent = (content?: IResidue): (string)[][] => {
        const result: (string)[][] = [];

        result.push([
            content.code != null ? content.code ?? '' : '',
            content.residueClassification != null ? translate(`enums.residueClassification.${content.residueClassification}`) : '',
            content.measurementType != null ? translate(`enums.measurementType.${content.measurementType}`) : '',
            content.residueSubcategory != null ? content.residueSubcategory : '',
            content.description != null ? content.description : '',
        ]);

        return result;
    };

    onNextHandler = () => {
        const { sourceResidues, position } = this.state;
        if (this.onValidateSourceResidues()) {
            let jumpTo = position;

            sourceResidues.some((residue, index) => {
                return Object.keys(residue.error)
                    .some(errorKey => {
                        if (residue.error[errorKey] && jumpTo === position) {
                            jumpTo = index;
                            return true;
                        }
                    });
            });

            if (jumpTo !== position) {
                this.setState({
                    position: jumpTo
                });
            }


            return;
        }

        this.props.onNextHandler(sourceResidues);
    };

    getData = () => { };

    alertResidue = () => {
        const { sourceResidues } = this.state;
        const codesToCheck = ['01.01.03', '01.01.04', '01.01.05'];
        const codesSaudetoCeck = ['18.01', '18.02', '18.03', '18.04', '18.05'];
        const codesRccToCheck = ['17.10', '17.11', '17.12', '17.13'];
        const code1710 = ['17.10'];
        const code1711 = ['17.11'];
        const code1712 = ['17.12'];
        const code1713 = ['17.13'];

        sourceResidues.forEach(item => {
            const code = item.residue.code;
            console.log(`Code: ${code}`);

            if (codesToCheck.includes(code)) {
                // Set the message when the condition is met
                this.setState({ message: 'Resíduo não disponível para integração nos sistemas FEPAM, INEA e IMA. Caso utilize algum desses sistemas, buscar resíduo similar na lista anterior.' });
            }
            if (codesSaudetoCeck.includes(code)) {
                this.setState({
                    message: "Resíduo não disponível para integração nos sistemas INEA. Caso utilize esse sistema, buscar resíduo similar na lista anterior.",
                    secondMessage: "ATENÇÃO nos sistemas SINIR, FEPAM, FEAM, SIGOR e IMA. Pode ocorrer erros de resíduo não encontrado, pois o mecanismo de busca desses sistemas estão desatualizados, caso isso ocorra busque um resíduo similar na lista anterior."
                })
            }
            if (codesRccToCheck.includes(code)) {
                // Set the message when the condition is met
                this.setState({
                    message: 'Resíduo não disponível para integração no sistema INEA. Caso utilize esse sistema, buscar resíduo similar na lista anterior.',
                    secondMessage: "ATENÇÃO nos sistemas SINIR e SIGOR. Pode ocorrer erro de resíduos, pois o sistema de busca desses órgãos está desatualizado, caso isso ocorra busque resíduo similar na lista anterior."
                });
            }
            if (code1710.includes(code)) {
                this.setState({
                    rccMessage: "Para integração no FEAM, utilizar código 2101.\nPara integração no FEPAM, utilizar código 1701.\nPara integração no IMA, utilizar código 1709."
                })
            }
            if (code1711.includes(code)) {
                this.setState({
                    rccMessage: "Para integração no FEAM, utilizar código 2102. \nPara integração no FEPAM, utilizar código 1702. \nPara integração no IMA, utilizar código 1709."
                })
            }
            if (code1712.includes(code)) {
                this.setState({
                    rccMessage: "Para integração no FEAM, utilizar código 2103. \nPara integração no FEPAM, utilizar código 1703. \nPara integração no IMA, utilizar código 1709."
                })
            }
            if (code1713.includes(code)) {
                this.setState({
                    fepamMessage: "Esse resíduo não está disponível para integração no sistema FEAM. Caso utilize esse sistema, buscar resíduo similar na lista anterior.",
                    rccMessage: "Para integração no FEPAM, utilizar código 1705. \nPara integração no IMA, utlizar código 1709."
                })
            }
        });
    }

    nextResidue = () => {
        const { sourceResidues, position } = this.state;
        this.renderRow(sourceResidues[position].residue);
    }

    incrementPositionCount = () => {
        const { sourceResidues, position } = this.state;
        if (position < sourceResidues.length - 1) {
            this.setState((state) => {
                return { position: state.position + 1, temporary: true }
            }, () => this.setState({ temporary: false }));
            this.nextResidue()
        }
    }

    decrementPositionCount = () => {
        const { position } = this.state;
        if (position > 0) {
            this.setState((state) => {
                return { position: state.position - 1 }
            });
            this.nextResidue();
        }
    }

    isMandatory = (residueClass: string, error: boolean) => {
        if (residueClass === ResidueClassification.DANGEROUS) return error;
        return false
    }

    genericValidate = (input: string, errorInput: string, position: number) => {
        const sourceResidues = [...this.state.sourceResidues];
        let sourceResidue = sourceResidues[position]

        let hasError = false

        if (sourceResidue[input] == null) {
            hasError = true;
            sourceResidue.error[errorInput] = true;
        } else {
            sourceResidue.error[errorInput] = false
        }

        this.setState({ sourceResidues })
        return hasError;
    }

    onValidateBoardingName = (position: number) => {
        const sourceResidues = [...this.state.sourceResidues];
        let sourceResidue = sourceResidues[position]

        let hasError = false;

        if (sourceResidue?.sourceResidueClassification == ResidueClassification.DANGEROUS && sourceResidue.boardingName == null) {
            hasError = true;
            sourceResidue.error.inputBoardingName = true;
        } else {
            sourceResidue.error.inputBoardingName = false
        }

        this.setState({ sourceResidues });

        return hasError;
    }

    onValidateSpecificResidueDescription = (position: number) => {
        const sourceResidues = [...this.state.sourceResidues];
        let sourceResidue = sourceResidues[position]

        let hasError = false;

        if (sourceResidue?.specificResidueDescription == null) {
            hasError = true;
            sourceResidue.error.inputSpecificResidue = true;
        } else {
            sourceResidue.error.inputSpecificResidue = false
        }

        this.setState({ sourceResidues });

        return hasError;
    }

    onValidatePackingGroup = (position: number) => {
        const sourceResidues = [...this.state.sourceResidues];
        let sourceResidue = sourceResidues[position]
        let hasError = false;

        if (sourceResidue.sourceResidueClassification == ResidueClassification.DANGEROUS && sourceResidue.packingGroup == null) {
            hasError = true;
            sourceResidue.error.inputPackingGroup = true;
        } else {
            sourceResidue.error.inputPackingGroup = false
        }

        this.setState({ sourceResidues })
        return hasError;
    }

    onValidateSourceResidues = () => {
        const sourceResidues = [...this.state.sourceResidues];
        let hasError = false;
        sourceResidues.forEach(item => {
            if (item.sourceResidueClassification == null) {
                hasError = true;
                item.error.inputClass = true;
            } else {
                item.error.inputClass = false
            }
            // if (item.specificResidue == null) {
            //     hasError = true;
            //     item.error.inputSpecificResidue = true;
            // } else {
            //     item.error.inputSpecificResidue = false
            // }
            if (item.specificResidueDescription == null) {
                hasError = true;
                item.error.inputSpecificResidue = true;
            } else {
                item.error.inputSpecificResidue = false
            }
            if (item.residueState == null) {
                hasError = true;
                item.error.inputResidueState = true;
            } else {
                item.error.inputResidueState = false
            }
            if (item.measurementType == null) {
                hasError = true;
                item.error.inputMeasurementType = true;
            } else {
                item.error.inputMeasurementType = false
            }
            if (item.conditioning == null) {
                hasError = true;
                item.error.inputConditioning = true;
            } else {
                item.error.inputConditioning = false
            }
            if (item.residueTreatment == null) {
                hasError = true;
                item.error.inputResidueTreatment = true;
            } else {
                item.error.inputResidueTreatment = false
            }
            if (item.sourceResidueClassification == ResidueClassification.DANGEROUS && item.boardingName == null) {
                hasError = true;
                item.error.inputBoardingName = true;
            } else {
                item.error.inputBoardingName = false
            }
            if (item.sourceResidueClassification == ResidueClassification.DANGEROUS && item.packingGroup == null) {
                hasError = true;
                item.error.inputPackingGroup = true;
            } else {
                item.error.inputPackingGroup = false
            }

        });

        this.setState(
            {
                sourceResidues,
            },
            () => this.forceUpdate()
        );
        return hasError;

    };

    getAllConditioning = async () => {
        const sourceResidues = [...this.state.sourceResidues];
        const position = this.state.position

        const state = sourceResidues[position]?.residueState

        const options = await axios.get(`api/source-residues/conditionings?state=${state}`)
            .then(function (response) {
                return response.data.map(conditioning => ({
                    id: conditioning, name: translate(`residueConditioning.${conditioning.toLowerCase()}`)
                }))
            });
        this.setState({ conditioningOptions: options })
    }

    private residueTreatment = [
        { id: ResidueTreatment.LANDFILL_RESIDUE_CLASS_I, name: translate("residueTreatment.landfill_residue_class_I") },
        { id: ResidueTreatment.LANDFILL_RESIDUE_CLASSES_IIA_AND_IIB, name: translate("residueTreatment.landfill_residue_classes_IIA_and_IIB") },
        { id: ResidueTreatment.AUTOCLAVE, name: translate("residueTreatment.autoclave") },
        { id: ResidueTreatment.TAILING_DAM, name: translate("residueTreatment.tailing_dam") },
        { id: ResidueTreatment.BIO_DIGESTION, name: translate("residueTreatment.bio_digestion") },
        { id: ResidueTreatment.BIO_METHANIZATION, name: translate("residueTreatment.bio_methanization") },
        { id: ResidueTreatment.MINING_DIGGING_DISPOSITION, name: translate("residueTreatment.mining_digging_disposition") },
        { id: ResidueTreatment.BIOREMEDIATION, name: translate("residueTreatment.bioremediation") },
        { id: ResidueTreatment.CO_PROCESSING_BLEND, name: translate("residueTreatment.co_processing_blend") },
        { id: ResidueTreatment.BLEND_FOR_INCINERATION, name: translate("residueTreatment.blend_for_incineration") },
        { id: ResidueTreatment.LAMP_DECONTAMINATION, name: translate("residueTreatment.lamp_decontamination") },
        { id: ResidueTreatment.VEHICLE_UNMOUNT, name: translate("residueTreatment.vehicle_unmount") },
        { id: ResidueTreatment.INCINERATION, name: translate("residueTreatment.incineration") },
        { id: ResidueTreatment.EFFLUENT_TREATMENT, name: translate("residueTreatment.effluent_treatment") },
        { id: ResidueTreatment.DIDACTIC_OR_RESEARCH_PURPOSES, name: translate("residueTreatment.didactic_or_research_purposes") },
        { id: ResidueTreatment.GASIFICATION, name: translate("residueTreatment.gasification") },
        { id: ResidueTreatment.MICROWAVES, name: translate("residueTreatment.microwaves") },
        { id: ResidueTreatment.STERILE_STACK, name: translate("residueTreatment.sterile_stack") },
        { id: ResidueTreatment.PYROLYSIS, name: translate("residueTreatment.pyrolysis") },
        { id: ResidueTreatment.RECYCLING, name: translate("residueTreatment.recycling") },
        { id: ResidueTreatment.RE_REFINE, name: translate("residueTreatment.re_refine") },
        { id: ResidueTreatment.ENERGETIC_RECOVERY, name: translate("residueTreatment.energetic_recovery") },
        { id: ResidueTreatment.SCREENING_WITH_STORAGE, name: translate("residueTreatment.screening_with_storage") },
        { id: ResidueTreatment.AGRICULTURAL_USAGE, name: translate("residueTreatment.agricultural_usage") },
        { id: ResidueTreatment.ANIMAL_FEED_USAGE, name: translate("residueTreatment.animal_feed_usage") },
        { id: ResidueTreatment.THERMAL_TREATMENT_OTHERS, name: translate("residueTreatment.thermal_treatment_others") },
        { id: ResidueTreatment.COMPOSTING, name: translate("residueTreatment.composting") },
        { id: ResidueTreatment.TRANSSHIPMENT_STATION_RSU, name: translate("residueTreatment.transshipment_station_rsu") },
        { id: ResidueTreatment.RESERVATION_LANDFILL_RCC, name: translate("residueTreatment.reservation_landfill_rcc") },
        { id: ResidueTreatment.LANDFILL_RSU, name: translate("residueTreatment.landfill_rsu") },
        { id: ResidueTreatment.CO_PROCESSING, name: translate("residueTreatment.co_processing") },
        { id: ResidueTreatment.PHOSPHINE_DEACTIVATION, name: translate("residueTreatment.phosphine_deactivation") },
        { id: ResidueTreatment.SCREENING_AND_TRANSSHIPMENT, name: translate("residueTreatment.screening_and_transshipment") },
        { id: ResidueTreatment.FERTIRRIGATION, name: translate("residueTreatment.fertirrigation") },
        { id: ResidueTreatment.OTHERS, name: translate("residueTreatment.others") },
    ]

    private classes = [
        { id: ResidueClassification.DANGEROUS, name: translate('residueClasses.dangerous'), dangerous: true },
        { id: ResidueClassification.NOT_DANGEROUS_INERT, name: translate('residueClasses.notDangerousInert'), dangerous: false },
        { id: ResidueClassification.NOT_DANGEROUS_NOT_INERT, name: translate('residueClasses.notDangerousNotInert'), dangerous: false },
        { id: ResidueClassification.RCC_A, name: translate('residueClasses.rcc_a'), dangerous: false },
        { id: ResidueClassification.RCC_B, name: translate('residueClasses.rcc_b'), dangerous: false },
        { id: ResidueClassification.RCC_C, name: translate('residueClasses.rcc_c'), dangerous: false },
        { id: ResidueClassification.RCC_D, name: translate('residueClasses.rcc_d'), dangerous: false },
        { id: ResidueClassification.RSS_A, name: translate('residueClasses.rss_a'), dangerous: false },
        { id: ResidueClassification.RSS_B, name: translate('residueClasses.rss_b'), dangerous: false },
        { id: ResidueClassification.RSS_C, name: translate('residueClasses.rss_c'), dangerous: false },
        { id: ResidueClassification.RSS_D, name: translate('residueClasses.rss_d'), dangerous: false },
        { id: ResidueClassification.RSS_E, name: translate('residueClasses.rss_e'), dangerous: false },
        { id: ResidueClassification.RSS_A1, name: translate('residueClasses.rss_a1'), dangerous: false },
        { id: ResidueClassification.RSS_A2, name: translate('residueClasses.rss_a2'), dangerous: false },
        { id: ResidueClassification.RSS_A3, name: translate('residueClasses.rss_a3'), dangerous: false },
        { id: ResidueClassification.RSS_A4, name: translate('residueClasses.rss_a4'), dangerous: false },
        { id: ResidueClassification.RSS_A5, name: translate('residueClasses.rss_a5'), dangerous: false },
        { id: ResidueClassification.OTHERS, name: translate('residueClasses.others'), dangerous: false },
    ]

    private residueState = [
        { id: ResidueState.LIQUID, name: translate('residueState.liquid') },
        { id: ResidueState.GAS, name: translate('residueState.gas') },
        { id: ResidueState.SOLID, name: translate('residueState.solid') },
        { id: ResidueState.SEMI_SOLID, name: translate('residueState.semi_solid') },
    ]

    private packingGroup = [
        { id: ResiduePackingGroup.GROUP_I, name: translate('packingGroup.one') },
        { id: ResiduePackingGroup.GROUP_II, name: translate('packingGroup.two') },
        { id: ResiduePackingGroup.GROUP_III, name: translate('packingGroup.three') }
    ]

    private measurementType = [
        { id: MeasurementType.KG, name: translate('residue.measurementType.kg') },
        { id: MeasurementType.TONS, name: translate('residue.measurementType.tons') },
        { id: MeasurementType.CUBIC_METERS, name: translate('residue.measurementType.cubic_meters') },
        { id: MeasurementType.UNITS, name: translate('residue.measurementType.unit') },
        { id: MeasurementType.LITERS, name: translate('residue.measurementType.liters') }
    ]

    mapClassesToSearch = (classification: ResidueClassification) => {
        // if (classification == ResidueClassification.DANGEROUS) {
        //     return this.classes.filter(classification => classification.dangerous)
        //       //.sort((a, b) => a.name.localeCompare(b.name))
        //       ;
        // } else {
        //   return this.classes.filter(classification => !classification.dangerous)
        //     //.sort((a, b) => a.name.localeCompare(b.name))
        //     ;
        // }
        return this.classes;
    }

    mapSpecificResiduesToSearch = () => {
        const { specificResidues } = this.props;
        const objects = specificResidues.map(specificResidue => {
            return { name: specificResidue.name, id: specificResidue.id };
        });
        return objects.sort((a, b) => a.name.localeCompare(b.name));
    };

    mapDataToSearch = (list) => {
        return list.sort((a, b) => a.name.localeCompare(b.name));
    }

    showSelectedData = (list, enumKey) => {
        if (enumKey != null) {
            return list.filter((element) => element.id == enumKey)[0];
        }
        return null;
    }

    renderRowItem = (info: string | JSX.Element, index: number) => (
        <div>
            {info}
        </div>
    );

    selectTypeDensity(id) {
        if (id == 'LITERS') {
            this.setState({ visibleDensity: true })
            return 'G/CM³';
        } else if (id == 'CUBIC_METERS') {
            this.setState({ visibleDensity: true })
            return 'T/M³';
        } else {
            this.setState({ visibleDensity: false })
            return '';
        }
    }

    renderRow = (residue: IResidue) => {
        const row = this.handleTransformToTableContent(residue);
        const residueData = this.tableHeaders
        return (
            <div >
                <table className="table-specific-residue">
                    <tr>
                        <td>
                            <span className="table-header">{residueData?.[0]?.name ?? ''}</span>
                            <span className="table-text">{this.renderRowItem(row[0][0], 0)}</span>
                        </td>
                        <td>
                            <span className="table-header">{residueData?.[1]?.name ?? ''}</span>
                            <span className="table-text">{this.renderRowItem(row[0][1], 1)}</span>
                        </td>
                        {/* <td>
                            <span className="table-header">{residueData?.[2]?.name ?? ''}</span>
                            <span className="table-text">{this.renderRowItem(row[0][2], 2)}</span>
                        </td> */}
                    </tr>
                    <hr />
                    <tr>
                        <td colSpan={2}>
                            <span className="table-header">{residueData?.[3]?.name ?? ''}</span>
                            <span className="table-text">
                                {this.renderRowItem(row[0][3], 3)}
                            </span>
                        </td>
                    </tr>
                    <hr />
                    <tr>
                        <td colSpan={2}>
                            <span className="table-header">{residueData?.[4]?.name ?? ''}</span>
                            <span className="table-text">{this.renderRowItem(row[0][4], 4)}</span>
                        </td>
                    </tr>
                </table>
            </div>
        );
    };


    renderForm = (sourceResidues: ISourceResidue[], position: number) => {
        const sourceResiduesState = [...this.state.sourceResidues]
        return (
            <Col md={{ size: 5 }} style={{ backgroundColor: 'white', padding: '30px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}>
                <span className='title'>{translate('residueFields.title')}</span>
                <DropdownSearch
                    title={translate('residueFields.class')}
                    placeholder={translate('residueFields.class')}
                    style={{ marginTop: '10px', backgroundColor: '#f6f6f6' }}
                    data={this.mapClassesToSearch(this.state.sourceResidues[position].residue.residueClassification)}
                    getData={this.getData}
                    onClickItem={(item) => {
                        console.log('Item selecionado:', item);
                        sourceResiduesState[position].sourceResidueClassification = item.id
                        this.setState({ sourceResidues: sourceResiduesState })
                        this.genericValidate('sourceResidueClassification', 'inputClass', position)
                    }}
                    notAlphabeticalOrder
                    showInsiderSelect
                    filterLocalData
                    selectedData={this.mapClassesToSearch(this.state.sourceResidues[position].residue.residueClassification).length == 1 ?
                        this.classes[0] :
                        this.showSelectedData(this.classes, sourceResidues[position].sourceResidueClassification)
                    }
                    error={sourceResiduesState[position].error.inputClass}
                    errorMessage={translateErrorMessage('residueError.class')}
                />

                {/*
                  <DropdownSearch
                      title={translate('residueFields.specificResidue')}
                      placeholder={translate('residueFields.specificResidue')}
                      style={{ marginTop: '10px', backgroundColor: '#f6f6f6' }}
                      data={this.mapSpecificResiduesToSearch()}
                      getData={this.getData}
                      onClickItem={(item) => {
                          sourceResiduesState[position].specificResidue = item
                          this.setState({ sourceResidues: sourceResiduesState })
                          this.genericValidate('specificResidue', 'inputSpecificResidue', position)
                      }}
                      notAlphabeticalOrder
                      showInsiderSelect
                      filterLocalData
                      showSearchField
                      selectedData={sourceResidues[position].specificResidue}
                      error={sourceResiduesState[position].error.inputSpecificResidue}
                      errorMessage={translateErrorMessage('specificResidue.residues.errorMessage')}
                  />
                  */}

                <CustomTextField
                    id={'specific-residue-description'}
                    placeholder={translate('residueFields.specificResidueDescription')}
                    label={translate('residueFields.specificResidueDescription')}
                    style={{ marginTop: '10px', backgroundColor: '#ffffff' }}
                    value={sourceResidues[position].specificResidueDescription ?? ''}
                    onChange={(text) => {
                        sourceResiduesState[position].specificResidueDescription = text
                        this.setState({ sourceResidues: sourceResiduesState })
                    }}
                    onBlur={() => this.onValidateSpecificResidueDescription(position)}
                    error={sourceResiduesState[position].error.inputSpecificResidue}
                    errorText={translateErrorMessage('specificResidue.residues.errorMessage')}
                />

                <DropdownSearch
                    title={translate('residueFields.measurementType')}
                    placeholder={translate('residueFields.measurementType')}
                    style={{ marginTop: '10px', backgroundColor: '#f6f6f6' }}
                    data={this.mapDataToSearch(this.measurementType)}
                    getData={this.getData}
                    onClickItem={(item) => {
                        sourceResiduesState[position].measurementType = item.id
                        this.setState({ sourceResidues: sourceResiduesState })
                        this.genericValidate('measurementType', 'inputMeasurementType', position)
                        this.setState({ typeDensity: this.selectTypeDensity(item.id) })
                    }}
                    notAlphabeticalOrder
                    showInsiderSelect
                    filterLocalData
                    selectedData={this.showSelectedData(this.measurementType, sourceResidues[position].measurementType)}
                    error={sourceResiduesState[position].error.inputMeasurementType}
                    errorMessage={translateErrorMessage('residueError.measurementType')}
                />

                {this.state.visibleDensity &&
                    <table className="table-specific-residue">
                        <tr>
                            <td>
                                <CustomTextField
                                    id={'density'}
                                    placeholder={translate('residueFields.density')}
                                    label={translate('residueFields.density')}
                                    style={{ marginTop: '10px', backgroundColor: '#ffffff', borderRadius: '6px' }}
                                    value={sourceResidues[position].density != null ? String(sourceResidues[position].density).replace('.', ',') : ''}
                                    onChange={(text) => {
                                        sourceResiduesState[position].density = Number(text.split('.').join('').replace(/,/, '.'));
                                    }}
                                />
                            </td>
                            <td>
                                <div className="list__items--component" style={{ marginTop: '30px', borderRadius: '6px', paddingLeft: '10px' }}>
                                    {this.state.typeDensity}
                                </div>
                            </td>
                        </tr>
                    </table>
                }

                {!this.state.temporary && <CustomTextField
                    id={'risk-classification'}
                    placeholder={translate('residueFields.riskClassification')}
                    label={translate('residueFields.riskClassification')}
                    style={{ marginTop: '10px', backgroundColor: '#ffffff' }}
                    value={sourceResidues[position].riskClassification ?? ''}
                    onChange={(text) => {
                        sourceResiduesState[position].riskClassification = text
                        this.setState({ sourceResidues: sourceResiduesState })
                    }}
                    dinamic
                />}
                {!this.state.temporary && <CustomTextField
                    id={'onu-classification'}
                    placeholder={translate('residueFields.onuClassification')}
                    label={translate('residueFields.onuClassification')}
                    style={{ marginTop: '10px', backgroundColor: '#ffffff' }}
                    value={sourceResidues[position].onuClassification ?? ''}
                    onChange={(text) => {
                        sourceResiduesState[position].onuClassification = text
                        this.setState({ sourceResidues: sourceResiduesState })
                    }}
                    dinamic
                />}
                <DropdownSearch
                    title={translate('residueFields.residueState')}
                    placeholder={translate('residueFields.residueState')}
                    style={{ marginTop: '10px', backgroundColor: '#f6f6f6' }}
                    data={this.mapDataToSearch(this.residueState)}
                    getData={this.getData}
                    onClickItem={(item) => {
                        sourceResiduesState[position].residueState = item.id
                        this.setState({ sourceResidues: sourceResiduesState }, () => this.getAllConditioning())
                        this.genericValidate('residueState', 'inputResidueState', position)
                    }}
                    notAlphabeticalOrder
                    showInsiderSelect
                    filterLocalData
                    selectedData={this.showSelectedData(this.residueState, sourceResidues[position].residueState)}
                    error={sourceResiduesState[position].error.inputResidueState}
                    errorMessage={translateErrorMessage('residueError.residueState')}
                />
                <DropdownSearch
                    title={translate('residueFields.packingWaste')}
                    placeholder={translate('residueFields.packingWaste')}
                    style={{ marginTop: '10px', backgroundColor: '#f6f6f6', maxHeight: '56px' }}
                    data={this.mapDataToSearch(this.state.conditioningOptions) ?? []}
                    getData={this.getData}
                    onClickItem={(item) => {
                        sourceResiduesState[position].conditioning = item.id
                        this.setState({ sourceResidues: sourceResiduesState })
                        this.genericValidate('conditioning', 'inputConditioning', position)
                    }}
                    notAlphabeticalOrder
                    showInsiderSelect
                    filterLocalData
                    selectedData={this.showSelectedData(this.state.conditioningOptions, sourceResidues[position].conditioning)}
                    error={sourceResiduesState[position].error.inputConditioning}
                    errorMessage={translateErrorMessage('residueError.packingWaste')}
                />
                <DropdownSearch
                    title={translate('residueFields.wasteTreatment')}
                    placeholder={translate('residueFields.wasteTreatment')}
                    style={{ marginTop: '10px', backgroundColor: '#f6f6f6' }}
                    data={this.mapDataToSearch(this.residueTreatment)}
                    getData={this.getData}
                    onClickItem={(item) => {
                        sourceResiduesState[position].residueTreatment = item.id
                        this.setState({ sourceResidues: sourceResiduesState })
                        this.genericValidate('residueTreatment', 'inputResidueTreatment', position)
                    }}
                    notAlphabeticalOrder
                    showInsiderSelect
                    filterLocalData
                    selectedData={this.showSelectedData(this.residueTreatment, sourceResidues[position].residueTreatment)}
                    error={sourceResiduesState[position].error.inputResidueTreatment}
                    errorMessage={translateErrorMessage('residueError.wasteTreatment')}
                />
                {!this.state.temporary && <CustomTextField
                    id={'boarding-name'}
                    placeholder={translate('residueFields.boardingName')}
                    label={translate('residueFields.boardingName')}
                    style={{ marginTop: '10px', backgroundColor: '#ffffff' }}
                    value={sourceResidues[position].boardingName ?? ''}
                    onChange={(text) => {
                        sourceResiduesState[position].boardingName = text
                        this.setState({ sourceResidues: sourceResiduesState })
                    }}
                    dinamic
                    onBlur={() => this.onValidateBoardingName(position)}
                    error={this.isMandatory(sourceResiduesState[position].sourceResidueClassification, sourceResiduesState[position].error.inputBoardingName)}
                    errorText={translateErrorMessage('residueError.boardingName')}
                />}
                <DropdownSearch
                    title={translate('residueFields.packingGroup')}
                    placeholder={translate('residueFields.packingGroup')}
                    style={{ marginTop: '10px', backgroundColor: '#f6f6f6' }}
                    data={this.mapDataToSearch(this.packingGroup)}
                    getData={this.getData}
                    onClickItem={(item) => {
                        sourceResiduesState[position].packingGroup = item.id
                        this.setState({ sourceResidues: sourceResiduesState })
                        this.onValidatePackingGroup(position)
                    }}
                    notAlphabeticalOrder
                    showInsiderSelect
                    filterLocalData
                    selectedData={this.showSelectedData(this.packingGroup, sourceResidues[position].packingGroup)}
                    error={this.isMandatory(sourceResiduesState[position].sourceResidueClassification, sourceResiduesState[position].error.inputPackingGroup)}
                    errorMessage={translateErrorMessage('residueError.packingGroup')}
                />

            </Col>
        )
    }

    render() {
        const { loading } = this.props;
        const { sourceResidues, position, message, secondMessage, rccMessage, fepamMessage } = this.state;
        if (loading) return <Loading />;
        return (
            <div>
                <Container>
                    <div>
                        <h1 style={{ marginTop: '28px', marginBottom: '30px', fontWeight: 600 }}>{translate('mtr-transporter.residuesTitleSecond')}</h1>
                    </div>
                    <Row>
                        <Col md={{ size: 1, offset: 0 }}>
                            <div className='icon'>
                                <img src="../../../../../content/images/icons/icon-voltar-big.png"
                                    onClick={this.decrementPositionCount}
                                />
                            </div>
                        </Col>
                        <Col md={{ size: 5, offset: 1 }} style={{ marginLeft: '0px', padding: '30px' }}>
                            <span className='title'>{translate('residueFields.titleResidue')}</span>
                            <div className='add-residues-list__container'>
                                {sourceResidues &&
                                    <div className="add-residues-list-item__container" style={{ marginTop: '10px' }}>
                                        {this.renderRow(sourceResidues[position].residue)}
                                    </div>
                                }
                                {message &&
                                    <div className="add-residues-list-item__container">
                                        <p className="alertResidue" >{message}</p>
                                        {fepamMessage &&
                                            <p className="alertResidue">{fepamMessage}</p>
                                        }
                                        {rccMessage &&
                                            <p className="alertResidue">{rccMessage}</p>
                                        }
                                        {secondMessage &&
                                            <p className="alertResidue">{secondMessage}</p>
                                        }
                                    </div>
                                }
                            </div>

                        </Col>
                        {this.renderForm(this.state.sourceResidues, this.state.position)}
                        <Col md={{ size: 1 }} >
                            <div className='icon'>
                                <img
                                    onClick={this.incrementPositionCount}
                                    src="../../../../../content/images/icons/icon-avan-ar-big.png"
                                />
                                <Fragment>
                                    {sourceResidues.length ? `${position + 1}/${sourceResidues.length}` : ''}
                                </Fragment>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="mtr-add-residues__items--modal">
                    <Container>
                        <div className="mtr-add-residues__items--modal-buttons">
                            <Row>
                                <Col md={{ size: 2, offset: 7 }}>
                                    <Button className={'button third'} type={'submit'} onClick={this.props.onBackHandler}>
                                        <Translate contentKey={'entity.action.back'} />
                                    </Button>
                                </Col>
                                <Col md="3">
                                    <Button className={'button fourth button-fourth'} type={'submit'} onClick={this.onNextHandler}>
                                        <Translate contentKey={'mtr-transporter.residuesButton'} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (root: IRootState) => ({
    loading: root.specificResidue.loading,
    specificResidues: root.specificResidue.entities,
});

const mapDispatchToProps = {
    getSpecificResidues,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SpecificResidueSelect);

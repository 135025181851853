import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './producer-register-address.scss';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import AddressFormComponent from 'app/components/address-form-component/address-form-component';
import { IAddress } from 'app/shared/model/address.model';

export interface IProducerRegisterAddressProps extends StateProps, DispatchProps {
  userProducer: IUserProducer;
  onNext: (userProducer: IUserProducer) => void;
  onBack: () => void;
}

export interface IProducerRegisterAddressState {
  address: IAddress;
}

export class ProducerRegisterAddress extends React.Component<IProducerRegisterAddressProps, IProducerRegisterAddressState> {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.userProducer.producer
        ? this.props.userProducer.producer.address
          ? this.props.userProducer.producer.address
          : {}
        : {},
    };
  }

  onNextHandle = (address: IAddress) => {
    const { userProducer } = { ...this.props };
    userProducer.producer = {
      ...userProducer.producer,
      address: address,
    };

    this.props.onNext(userProducer);
  };

  render() {
    const { address } = this.state;
    return (
      <div>
        <AddressFormComponent onBack={this.props.onBack} onNextHandler={this.onNextHandle} address={address} />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerRegisterAddress);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../content/images/logo-fundo-branco.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container__header {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 80px;\n  border: solid 1px #e1e2e6;\n  background: #ffffff;\n  z-index: 10;\n}\n\n.header {\n  width: 200px;\n  display: flex;\n  align-items: center;\n  height: 80px;\n}\n\n.header--logo {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}", ""]);
// Exports
module.exports = exports;

import React from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
    isLoading: boolean;
    textButton: string;
    className: string;
    colorIconLoading: string;
    isDisabled?: boolean;
    onClick?: () => void;
}

const ButtonLoading = ({ isLoading, textButton, className, colorIconLoading, isDisabled, onClick }: IProps) => {
    return (
        <Button
            className={className}
            type={'submit'}
            disabled={isDisabled}
            onClick={() => onClick()}
        >
            {isLoading ? <CircularProgress size={20} style={{ color: colorIconLoading }} /> : textButton}
        </Button>
    );
}

export default ButtonLoading;

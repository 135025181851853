import './home.scss';

import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const pathFromUserType = () => {
    if (props.isProducerAdmin || props.isProducerUser) {
      return '/producer/mtr';
    }
    if (props.isManagerAdmin || props.isManagerUser) {
      return '/manager/mtr';
    }
    if (props.isFinalDestinationAdmin || props.isFinalDestinationUser) {
      return '/final-destination/mtr';
    }
    if (props.isTransporterAdmin || props.isTransporterUser) {
      return '/transporter/mtr';
    }
    if (props.isTransshipmentAdmin || props.isTransshipmentUser) {
      return '/transshipment/mtr';
    }
    if (props.isDriverUser) {
      return '/logout';
    }
    return '/entity'
  };

  if (props.isAuthenticated) {
    if (props.needsFinishingRegister) {
      return <Redirect to={'/full-register'} />;
    } else {
      return <Redirect to={pathFromUserType()} />;
    }
  } else {
    return <Redirect to={'/login'} />;
  }
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  needsFinishingRegister: storeState.authentication.needsFinishingRegister,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.USER]),
  isTransporterAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.TRANSPORTER_ADMIN]),
  isTransporterUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.TRANSPORTER_USER]),
  isTransshipmentAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN]),
  isTransshipmentUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_USER]),
  isManagerAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
  isManagerUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.MANAGER_USER]),
  isProducerAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN]),
  isProducerUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.PRODUCER_USER]),
  isFinalDestinationAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN]),
  isFinalDestinationUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_USER]),
  isDriverUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.DRIVER_USER]),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);

import React from 'react';
import CustomMenuItem from 'app/shared/layout/menus/common/custom-menu-item/custom-menu-item';
import { Translate } from 'react-jhipster';

export interface IFinalDestinationMenuProps {
    isAdmin: boolean;
    isUser: boolean;
    currentUrl: string;
}

export interface IFinalDestinationMenuState {
    subCategorySelected: boolean;
}

export class FinalDestinationMenu extends React.Component<IFinalDestinationMenuProps, IFinalDestinationMenuState> {
    constructor(props) {
        super(props);
        this.state = {
            subCategorySelected: false,
        };
    }

    checkSubcategorySelected = (path: string) => {
        const { currentUrl } = this.props;
        return currentUrl.indexOf(path) != -1;
    };

    render() {
        const { isAdmin, isUser, currentUrl } = this.props;
        // const active = this.checkSubcategorySelected('/contractor/rides');
        return (
            <div style={{ display: 'flex' }}>
                <CustomMenuItem id={'mtr'} to="/final-destination/mtr" active={this.checkSubcategorySelected("/final-destination/mtr")}>
                    <Translate contentKey={'header.common.mtr'}>MTR</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'residues'} to="/final-destination/residues" active={this.checkSubcategorySelected("/final-destination/residues")}>
                    <Translate contentKey={'header.common.residues'}>Residues</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'units'} to="/mtr-units" active={this.checkSubcategorySelected("/mtr-units")}>
                    <Translate contentKey={'header.finalDestination.units'}>Units</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'licenses'} to="/final-destination/licenses" active={this.checkSubcategorySelected("/final-destination/licenses")}>
                    <Translate contentKey={'header.common.licenses'}>Licenses</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'authorizations'} to="/final-destination/authorizations" active={this.checkSubcategorySelected("/final-destination/authorizations")}>
                    <Translate contentKey={'header.common.authorizations'}>Authorizations</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'invite'} to="/final-destination/summary" active={this.checkSubcategorySelected("/final-destination/summary")}>
                    <Translate contentKey={'header.common.invite'}>Invite</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'cdf'} to="/final-destination/cdf" active={this.checkSubcategorySelected("/final-destination/cdf")}>
                    <Translate contentKey={'header.finalDestination.cdf'}>CDF</Translate>
                </CustomMenuItem>
            </div>
        );
    }
}

export default FinalDestinationMenu;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../content/images/icons/abrir.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mtr-waste__items {\n  background: #fafafa;\n}\n.mtr-waste__items .mtr-unity__items--matrix {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 52px;\n  border-radius: 6px;\n  border: solid 1px #e1e2e6;\n  background: white;\n  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);\n  padding: 0 24px 0 20px;\n  margin-bottom: 28px;\n}\n.mtr-waste__items .mtr-unity__items--matrix .mtr-unity__items--text {\n  font-family: Catamaran;\n  font-size: 18px;\n  color: #4a4a4a;\n}\n.mtr-waste__items .mtr-unity__items--matrix .mtr-unity__items--img {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 35px;\n  height: 35px;\n  cursor: pointer;\n}\n.mtr-waste__items .button.primary {\n  width: 200px;\n  border-radius: 6px;\n  margin-right: 15px;\n}", ""]);
// Exports
module.exports = exports;

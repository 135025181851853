// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../content/images/icons/add-green.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".add-license__button {\n  margin-top: 35px;\n  margin-bottom: 32px;\n  border-radius: 6px;\n  border: dashed 2px #30ab64;\n  background-color: #ffffff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 80px;\n}\n.add-license__button span {\n  font-family: Catamaran;\n  font-size: 16px;\n  font-weight: 600;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  color: #30ab64;\n}\n.add-license__button .plus-sign__image {\n  margin-right: 10px;\n  width: 24px;\n  height: 24px;\n  -o-object-fit: contain;\n     object-fit: contain;\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.transport-item__container {\n  margin-top: 10px;\n  width: 100%;\n  min-height: 52px;\n  max-height: 80px;\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 20px;\n  border-radius: 6px;\n  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);\n  cursor: pointer;\n}\n.transport-item__container span {\n  font-family: Catamaran;\n  font-size: 15px;\n  font-weight: 300;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  color: #4a4a4a;\n}", ""]);
// Exports
module.exports = exports;

import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

// import { cleanMtrModel } from '../../../shared/util/MtrModel-utils';
import { FAILURE, REQUEST, SUCCESS } from '../../../shared/reducers/action-type.util';

import { defaultValue } from '../../../shared/model/authorization.model';
import { HttpRequestStatus } from '../../../shared/model/enum/HttpRequestStatus';
import StringUtils from '../../../shared/util/string-utils';
import { organizeSearchParameters } from '../../../shared/util/resitrack-utils';
import { ICrudSearchActionCustom } from '../../../shared/util/reducer-utils';

export const ACTION_TYPES = {
  GET_MTR_MODEL_LIST: 'mtr_model/GET_MTR_MODEL_LIST',
  GET_MTR_MODEL: 'mtr_model/GET_MTR_MODEL',
  CREATE_MTR_MODEL: 'mtr_model/CREATE_MTR_MODEL',
  UPDATE_MTR_MODEL: 'mtr_model/UPDATE_MTR_MODEL',
  DELETE_MTR_MODEL: 'mtr_model/DELETE_MTR_MODEL',
  RESET: 'mtr_model/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  mtrModelList: [] as Array<any>,
  mtrModel: {} as any,
  getMtrModelListStatus: HttpRequestStatus.NOOP,
  getMtrModeltatus: HttpRequestStatus.NOOP,
  createMtrModeltatus: HttpRequestStatus.NOOP,
  updateMtrModelStatus: HttpRequestStatus.NOOP,
  deleteMtrModelStatus: HttpRequestStatus.NOOP,
  totalItems: 0,
};

export type MtrModelState = Readonly<typeof initialState>;

// Reducer

export default (state: MtrModelState = initialState, action): MtrModelState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_MTR_MODEL_LIST):
      return {
        ...state,
        errorMessage: null,
        getMtrModelListStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_MTR_MODEL_LIST):
      return {
        ...state,
        getMtrModelListStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_MTR_MODEL_LIST):
      return {
        ...state,
        getMtrModelListStatus: HttpRequestStatus.SUCCESS,
        mtrModelList: action.payload.data,
        totalItems: parseInt(action.payload.data.length, 10),
      };
    case REQUEST(ACTION_TYPES.GET_MTR_MODEL):
      return {
        ...state,
        errorMessage: null,
        getMtrModeltatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_MTR_MODEL):
      return {
        ...state,
        getMtrModeltatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_MTR_MODEL):
      return {
        ...state,
        getMtrModeltatus: HttpRequestStatus.SUCCESS,
        mtrModel: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.CREATE_MTR_MODEL):
      return {
        ...state,
        errorMessage: null,
        createMtrModeltatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_MTR_MODEL):
      return {
        ...state,
        createMtrModeltatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_MTR_MODEL):
      return {
        ...state,
        createMtrModeltatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_MTR_MODEL):
      return {
        ...state,
        errorMessage: null,
        updateMtrModelStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_MTR_MODEL):
      return {
        ...state,
        updateMtrModelStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_MTR_MODEL):
      return {
        ...state,
        updateMtrModelStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.DELETE_MTR_MODEL):
      return {
        ...state,
        errorMessage: null,
        deleteMtrModelStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.DELETE_MTR_MODEL):
      return {
        ...state,
        deleteMtrModelStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DELETE_MTR_MODEL):
      return {
        ...state,
        deleteMtrModelStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/mtr-models';

// Actions

export const getMtrModels: ICrudSearchActionCustom<any> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}${params}`;
  return {
    type: ACTION_TYPES.GET_MTR_MODEL_LIST,
    payload: axios.get<any>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getMtrModel: ICrudGetAction<any> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.GET_MTR_MODEL,
    payload: axios.get<any>(requestUrl),
  };
};

export const createMtrModel: ICrudPutAction<any> = mtrModel => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MTR_MODEL,
    payload: axios.post(apiUrl, mtrModel),
  });
  dispatch(getMtrModels());
  return result;
};

export const updateMtrModel: ICrudPutAction<any> = mtrModel => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_MTR_MODEL,
    payload: axios.put(apiUrl, mtrModel),
  });
  dispatch(getMtrModels());
  return result;
};

export const deleteMtrModel: ICrudDeleteAction<any> = id => async dispatch => {
  const requestUrl = `api/mtr-models/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MTR_MODEL,
    payload: axios.delete(requestUrl),
  });
  dispatch(getMtrModels());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

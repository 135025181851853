import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate } from 'react-jhipster';
import './new-user.scss';

export const MtrManageNewUser = () => {
  return (
    <div className="new-user__items">
      <div className="new-user__items--back">
        <Container>
          <div className="confirmation--back">
            <div className="confirmation--back-icon" />
            <span>
              <Translate contentKey={'mtr-signature.back'} />
            </span>
          </div>
        </Container>
      </div>
      <div style={{ marginBottom: '35px' }}>
        <h1> <Translate contentKey={'profile.manage-users.title-second'} /> </h1>
      </div>
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <div className="new-user__items--component">Insira o nome completo do usuário</div>
          <div className="new-user__items--component">Insira o email do usuário</div>
          <div className="new-user__items--component">Insira o CPF do usuário</div>
          <div className="new-user__items--component">Insira o número de contato do usuário</div>
          <div className="new-user__items--component">Selecione o tipo de perfil</div>
        </Col>
      </Row>
      <div className="new-user--buttons">
        <button style={{ width: '172px' }} className={'button third'} type={'submit'}>
          <Translate contentKey={'mtr-signature.button-cancel'} />
        </button>
        <button style={{ width: '172px' }} className={'button primary'} type={'button'} onClick={() => { }}>
          <Translate contentKey={'mtr-signature.button-save'} />
        </button>
      </div>
    </div>
  );
};
export default MtrManageNewUser;

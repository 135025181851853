import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ManagerMtrList from './mtr/manager-mtr-list';
import ManagerResidues from './residues/manager-residues';
import ManagerResiduesAdd from './residues/add/manager-residues-add';
import Authorization from './authorizations/index';
import ManagerFavoriteSummary from './favorite/manager-favorite-summary';
import FavoriteTransporterList from '../generic/favorite-list/favorite-transporter/favorite-transporter-list';
import FavoriteFinalDestinationList
    from '../generic/favorite-list/favorite-final-destination/favorite-final-destination-list';
import FavoriteTransshipmentList from '../generic/favorite-list/favorite-transshipment/favorite-transshipment-list';
import GenericInvite from '../generic/invite/generic-invite';
import TransporterDetails from '../generic/details/transporter/transporter-details';
import FinalDestinationDetails from '../generic/details/final-destination/final-destination-details';
import TransshipmentDetails from '../generic/details/transshipment/transshipment-details'
import ManagerProducerList from './producers/manager-producers';
import ProducerLink from './producers/link/producer-link';
import ProducerDetails from '../generic/details/producer/producer-details';
import MtrList from '../generic/mtr/mtr-list';
import MtrManage from '../generic/mtr/manage/mtr-manage';
import MtrSignatureStep from '../generic/mtr/signature/mtr-signature';
import CustomMtrReportCreate from 'app/components/custom-mtr-report-create/custom-mtr-report-create';
import CustomCdfList from 'app/components/custom-cdf-list/custom-cdf-list';
import CustomCdfManage from 'app/components/custom-cdf-manage/custom-cdf-manage';
import CustomCdfCreate from 'app/components/custom-cdf-create/custom-cdf-create';
import CustomCdfSignature from 'app/components/custom-cdf-signature/custom-cdf-signature';
import MtrModelRoutes from '../generic/mtr-model/index'
import customMtrSignatureAll from 'app/components/custom-mtr-signature-all/custom-mtr-signature-all';


const Routes = ({ match }) => (
    <>
        <Switch>
            <Route exact path={`${match.url}/mtr/manage`} component={MtrManage} />
            <Route path={`${match.url}/mtr/signature`} component={MtrSignatureStep} />
            <Route exact path={`${match.url}/mtr`} component={MtrList} />
            <Route exact path={`${match.url}/mtr`} component={ManagerMtrList} />
            <Route path={`${match.url}/mtr-model`} component={MtrModelRoutes} />
            <Route path={`${match.url}/mtr-report/create`} component={CustomMtrReportCreate} />
            <Route path={`${match.url}/mtr-signature/all`} component={customMtrSignatureAll} />
            <Route exact path={`${match.url}/residues`} component={ManagerResidues} />
            <Route path={`${match.url}/residues/add`} component={ManagerResiduesAdd} />
            <Route path={`${match.url}/authorizations`} component={Authorization} />
            <Route exact path={`${match.url}/summary`} component={ManagerFavoriteSummary} />
            <Route exact path={`${match.url}/summary/transporters`} component={FavoriteTransporterList} />
            <Route path={`${match.url}/summary/transporters/detail`} component={TransporterDetails} />
            <Route exact path={`${match.url}/summary/final-destinations`} component={FavoriteFinalDestinationList} />
            <Route path={`${match.url}/summary/final-destinations/detail`} component={FinalDestinationDetails} />
            <Route exact path={`${match.url}/summary/transshipments`} component={FavoriteTransshipmentList} />
            <Route path={`${match.url}/summary/transshipments/detail`} component={TransshipmentDetails} />
            <Route path={`${match.url}/invite`} component={GenericInvite} />
            <Route exact path={`${match.url}/producers`} component={ManagerProducerList} />
            <Route path={`${match.url}/producers/details`} component={ProducerDetails} />
            <Route path={`${match.url}/producers/link`} component={ProducerLink} />
            <Route exact path={`${match.url}/cdf`} component={CustomCdfList} />
            <Route path={`${match.url}/cdf/:cdfNumber/manage/signature`} component={CustomCdfSignature} />
            <Route path={`${match.url}/cdf/:cdfNumber/manage`} component={CustomCdfManage} />
            <Route path={`${match.url}/cdf/create`} component={CustomCdfCreate} />
        </Switch>
    </>
);

export default Routes;

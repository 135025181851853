import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './driver.reducer';

export interface IDriverDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class DriverDetail extends React.Component<IDriverDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { driverEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.driver.detail.title">Driver</Translate> [<b>{driverEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="driverLicense">
                <Translate contentKey="resitrackApp.driver.driverLicense">Driver License</Translate>
              </span>
            </dt>
            <dd>{driverEntity.driverLicense}</dd>
            <dt>
              <span id="expirationDate">
                <Translate contentKey="resitrackApp.driver.expirationDate">Expiration Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={driverEntity.expirationDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="driverType">
                <Translate contentKey="resitrackApp.driver.driverType">Driver Type</Translate>
              </span>
            </dt>
            <dd>{driverEntity.driverType}</dd>
            <dt>
              <Translate contentKey="resitrackApp.driver.transporter">Transporter</Translate>
            </dt>
            <dd>{driverEntity.transporter ? driverEntity.transporter.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.driver.userTransporter">User Transporter</Translate>
            </dt>
            <dd>{driverEntity.userTransporter ? driverEntity.userTransporter.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/driver" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/driver/${driverEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ driver }: IRootState) => ({
  driverEntity: driver.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetail);

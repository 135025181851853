// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../content/images/icons/add-green-circle.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".add-credit-card-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: \"Catamaran\";\n  width: 414px;\n  height: 76px;\n  background-color: transparent;\n  border: 2px dashed #a4a4a4;\n  border-radius: 6px;\n  font-size: 16px;\n  color: #a4a4a4;\n  font-weight: 300;\n  cursor: pointer;\n}\n.add-credit-card-container span {\n  margin-left: 12px;\n}\n.add-credit-card-container--icon {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", ""]);
// Exports
module.exports = exports;

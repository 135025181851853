import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transshipment-register-documentation.scss';
import { UserTransshipment } from 'app/shared/model/user-transshipment.model';
import { StorageLicense } from 'app/shared/model/storage-license.model';
import { StorageLicenseList } from 'app/components/storage-license-form/list/storage-license-list';

// import {StorageLicenseList} from 'app/components/storage-license-form/list/storage-license-list';

export interface ITransshipmentRegisterDocumentationProps extends StateProps, DispatchProps {
  userTransshipment: UserTransshipment;
  onNext: (userTransshipment: UserTransshipment) => void;
  onBack: () => void;
}

export interface ITransshipmentRegisterDocumentationState {
  storageLicenses: StorageLicense[];
}

export class TransshipmentRegisterDocumentation extends React.Component<
    ITransshipmentRegisterDocumentationProps,
    ITransshipmentRegisterDocumentationState
    > {
  constructor(props) {
    super(props);
    this.state = {
      storageLicenses: this.props.userTransshipment.transshipment
          ? this.props.userTransshipment.transshipment.storageLicenses
          : [],
    };
  }

  onNextHandler = (storageLicensesUpdated: StorageLicense[]) => {
    const userTransshipmentUpdated = { ...this.props.userTransshipment };
    userTransshipmentUpdated.transshipment.storageLicenses = storageLicensesUpdated;
    this.props.onNext(userTransshipmentUpdated);
  };

  render() {
    const { storageLicenses } = this.state;
    return (
        <div>
          <StorageLicenseList
              onNextHandler={this.onNextHandler}
              onBack={this.props.onBack}
              storageLicenses={storageLicenses}
          />
        </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentRegisterDocumentation);

import { IDocument } from 'app/shared/model/document.model';
import { ITransporter } from 'app/shared/model/transporter.model';

export enum TransportLicenseType {
  PRELIMINARY = 'PRELIMINARY',
  INSTALLATION = ' INSTALLATION',
  OPERATION = ' OPERATION',
  DISMISS = ' DISMISS',
  AUTHORIZATION = ' AUTHORIZATION',
  SIMPLIFIED = ' SIMPLIFIED',
}

export interface ITransportLicense {
  id?: number;
  licenseNumber?: string;
  expireDate?: string;
  issuingBody?: string;
  transportLicenseType?: TransportLicenseType;
  licenseDocument?: IDocument;
  transporter?: ITransporter;
  active?: boolean;
}

export const defaultValue: Readonly<ITransportLicense> = {};

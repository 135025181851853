enum PaymentStatusType {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  ACTIVE = 'ACTIVE',
  TRIAL_ACTIVE = 'TRIAL_ACTIVE',
  AWAITING_EXPIRATION = 'AWAITING_EXPIRATION',
  CANCELLED = 'CANCELLED',
}

export default PaymentStatusType;

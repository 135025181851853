import * as React from 'react';
import './clickable-icon.scss';

interface Props {
    iconPath: string | NodeRequire;
    style?: string;
    onClick: (...args: any) => void;
}

export class ClickableIcon extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <img src={this.props.iconPath as string} className={this.props.style ? `clickable-icon ${this.props.style}` : 'clickable-icon'} onClick={this.props.onClick} />
        );
    }
}

export default ClickableIcon;

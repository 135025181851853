import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { defaultValue, UserTransshipment } from 'app/shared/model/user-transshipment.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';

export const ACTION_TYPES = {
  INITIALIZE_TRANSSHIPMENT: 'transshipmentRegister/INITIALIZE_TRANSSHIPMENT',
  CREATE_TRANSSHIPMENT: 'transshipmentRegister/CREATE_TRANSSHIPMENT',
  UPDATE_TRANSSHIPMENT: 'transshipmentRegister/UPDATE_TRANSSHIPMENT',
  RESET: 'transshipmentRegister/RESET',
};

const initialState = {
  errorMessage: null,
  entity: defaultValue,
  initializeTransshipmentStatus: HttpRequestStatus.NOOP,
};

export type TransshipmentRegisterState = Readonly<typeof initialState>;

// Reducer

export default (state: TransshipmentRegisterState = initialState, action): TransshipmentRegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.INITIALIZE_TRANSSHIPMENT):
    case REQUEST(ACTION_TYPES.UPDATE_TRANSSHIPMENT):
      return {
        ...state,
        errorMessage: null,
        initializeTransshipmentStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.INITIALIZE_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.UPDATE_TRANSSHIPMENT):
      return {
        ...state,
        errorMessage: action.payload,
        initializeTransshipmentStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.INITIALIZE_TRANSSHIPMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_TRANSSHIPMENT):
      return {
        ...state,
        entity: action.payload.data,
        initializeTransshipmentStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-transshipments';

export const initializeTransshipment: ICrudPutAction<UserTransshipment> = entity => async dispatch => {
  const url = `${apiUrl}/initialize`;
  const result = await dispatch({
    type: ACTION_TYPES.INITIALIZE_TRANSSHIPMENT,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const updateTransshipment: ICrudPutAction<UserTransshipment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TRANSSHIPMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const createTransshipment: ICrudPutAction<UserTransshipment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.INITIALIZE_TRANSSHIPMENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './invite-second.scss';
import { AlertModal } from 'app/components/alert-modal/alert-modal';

export const MtrInviteSecond = () => {
  return (
    <div className="invite-second__items">
      <h1>{translate('mtr-transporter.invite.title')}</h1>
      <div style={{ display:'flex', justifyContent:'center'}}>
        <div className="invite-second__items--subtitle">{translate('mtr-transporter.invite.subtitle')}</div>
      </div>
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <div className="authorization-second__items--component">Digite o nome</div>
          <div className="authorization-second__items--component">Digite o email</div>
        </Col>
      </Row>
      <div className={'button-container'}>
        <button className={'button third'} type={'submit'}>
          <Translate contentKey={'mtr-signature.button-cancel'} />
        </button>
        <button className={'button fourth'} type={'submit'}>
          <Translate contentKey={'mtr-transporter.invite.button-invite'}/>
        </button>
      </div>
      {/* ==========================================================================
                            MODAL
      ==========================================================================  */}

      <AlertModal
        showModal={false}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="transporter-driver-1.svg"
        alertMessage="modal-invite-second"

      />
    </div>
  );
};

export default MtrInviteSecond;

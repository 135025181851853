import { ICity } from 'app/shared/model/city.model';

export interface IAddress {
  id?: number;
  street?: string;
  name?: string;
  description?: string;
  number?: string;
  zipcode?: string;
  district?: string;
  complement?: string;
  geolocated?: boolean;
  lat?: number;
  lng?: number;
  city?: ICity;
}

export function convertToAdressLabel(address?: IAddress) {
  let result = '';
  if (address != null) {
    result += address.street != null ? address.street + ', ' : '';
    result += address.number != null ? address.number + '. ' : '';
    result += address.city != null ? address.city.name : '';
    result += address.city.state != null ? '/' + address.city.state.name + '.' : '';
  }

  return result;
}

export const defaultValue: Readonly<IAddress> = {
  geolocated: false,
};

import { ITransporter } from 'app/shared/model/transporter.model';
import { MeasurementType } from './authorization.model';

export enum VehicleType {
  COMPACTOR_TRUCK = 'COMPACTOR_TRUCK',
  TILT_TRUCK = 'TILT_TRUCK',
  CHEST_TRUCK = 'CHEST_TRUCK',
  BODYWORK_TRUCK = 'BODYWORK_TRUCK',
  POLY_CRANE_TRUCK = 'POLY_CRANE_TRUCK',
  ROLL_ON_ROLL_OFF_TRUCK = 'ROLL_ON_ROLL_OFF_TRUCK',
  PICKUP = 'PICKUP',
  VAN = 'VAN',
  OTHERS = 'OTHERS',
}

export interface IVehicle {
  id?: number;
  plate?: string;
  year?: string;
  model?: string;
  otherVehicleType?: string;
  capacity?: number;
  measurementType?: MeasurementType;
  vehicleType?: VehicleType;
  transporter?: ITransporter;
}

export function mapVehicleToSearch(vehicles: IVehicle[]) {
  const objects = vehicles.map(it => {
    return { name: it.plate, id: it.id };
  });
  return objects.sort((a, b) => a.name.localeCompare(b.name));
}

export const defaultValue: Readonly<IVehicle> = {};

import { ITransporter } from 'app/shared/model/transporter.model';

export interface INaturalPerson {
  id?: number;
  cpf?: string;
  name?: string;
  phone?: string;
  transporter?: ITransporter;
}

export const defaultValue: Readonly<INaturalPerson> = {};

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transshipment-register-responsible.scss';
import { UserTransshipment } from 'app/shared/model/user-transshipment.model';
import ResponsibleFormComponent from 'app/components/responsible-form-component/responsible-form-component';
import { IUserGeneric } from 'app/shared/model/user-generic.model';

export interface ITransshipmentRegisterResponsibleProps extends StateProps, DispatchProps {
  userTransshipment: UserTransshipment;
  onNext: (userTransshipment: UserTransshipment) => void;
  onBack: () => void;
}

export interface ITransshipmentRegisterResponsibleState {}

export class TransshipmentRegisterResponsible extends React.Component<
    ITransshipmentRegisterResponsibleProps,
    ITransshipmentRegisterResponsibleState
    > {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onNextHandler = (userTransshipmentGeneric: IUserGeneric) => {
    const userTransshipment = { ...this.props.userTransshipment };

    const userTransshipmentUpdated: UserTransshipment = {
      ...userTransshipment,
      user: userTransshipmentGeneric.user,
    };

    this.props.onNext(userTransshipmentUpdated);
  };

  mapToUserGeneric = () => {
    const { userTransshipment } = this.props;
    if (userTransshipment != null) {
      const userTransshipmentGeneric: IUserGeneric = {
        user: userTransshipment.user,
      };
      return userTransshipmentGeneric;
    }
    return {};
  };

  render() {
    return (
        <div>
          <ResponsibleFormComponent
              validationIgnore={
                new Map([
                  ['classCouncil', true],
                  ['registrationNumber', true],
                ])
              }
              ignoreClassCouncilAndRegistrationNumber
              userGeneric={this.mapToUserGeneric()}
              onNextHandler={this.onNextHandler}
              onBack={this.props.onBack}
              isResponsibleRequired
          />
        </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentRegisterResponsible);

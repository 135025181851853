import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './driver-info.scss';
import { AlertModal } from 'app/components/alert-modal/alert-modal';

export const MtrDriverInfo = () => {
  return (
    <div className="driver-info__items">
      <div className="driver-info__items--back">
        <Container>
          <div className="confirmation--back">
            <div className="confirmation--back-icon" />
            <span>
              <Translate contentKey={'mtr-signature.back'} />
            </span>
          </div>
        </Container>
      </div>
      <div style={{ marginBottom: '35px' }}>
        <h1>{translate('mtr-transporter.driver.title')}</h1>
      </div>
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <div className="driver-info__items--component">Insira o nome do motorista</div>
          <div className="driver-info__items--component">Insira o CPF</div>
          <div className="driver-info__items--component">Insira o telefone de contato</div>
          <div className="driver-info__items--component">Insira o número da carteira de motorista</div>
          <div className="driver-info__items--component">Selecione o tipo de carteira de motorista</div>
          <div className="driver-info__items--component-calendar">
            <div>Insira a data de validade da licença</div>
            <div className="driver-info__items--component-calendar-img" />
          </div>
        </Col>
      </Row>
      <div className="driver-info--buttons">
        <button style={{ width: '172px' }} className={'button third'} type={'submit'}>
          <Translate contentKey={'mtr-signature.button-cancel'} />
        </button>
        <button style={{ width: '172px' }} className={'button primary'} type={'button'} onClick={() => {}}>
          <Translate contentKey={'mtr-signature.button-save'} />
        </button>
      </div>


      {/* ==========================================================================
                            MODAIS TRANSPORTADOR/MOTORISTAS
      ==========================================================================  */}

      <AlertModal
        showModal={false}
        buttonOneActionMessage={'mtr-signature.button-back'}
        buttonTwoActionMessage={'mtr-signature.button-agree'}
        hasTwoButtons
        buttonTwoAction={() => {}}
        buttonOneAction={() => {}}
        statusImage="transporter-driver-1.svg"
        alertMessage="modal-transporter-driver-save"
      />

      <AlertModal
        showModal={false}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="transporter-driver-1.svg"
        alertMessage="modal-transporter-driver-agree"
      />
    </div>
  );
};

export default MtrDriverInfo;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './register-choose.scss';
import { Translate } from 'react-jhipster';
import Card from './card';
import { RouteComponentProps } from 'react-router-dom';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

export interface IRegisterChooseProps extends StateProps, RouteComponentProps<{}> {}

const RegisterChoose = (props: IRegisterChooseProps) => {
  const onProducerClick = () => {
    if (!props.hasProducerAuthority) {
      props.history.push(`producer`);
    }
  };

  const onTransporterClick = () => {
    if (!props.hasTransporterAuthority) {
      props.history.push(`transporter`);
    }
  };

  const onFinalDestinationClick = () => {
    if (!props.hasFinalDestinationAuthority) {
      props.history.push(`final-destination`);
    }
  };

  const onManagerClick = () => {
    if (!props.hasManagerAuthority) {
      props.history.push(`manager`);
    }
  };

  const onTransshipmentClick = () => {
    if (!props.hasTransshipmentAuthority) {
      props.history.push(`transshipment`);
    }
  };

  return (
    <div className="profile-company__container container">
      <Container>
        <Row>
          <Col style={{ margin: 'auto' }} md="5">
            <div className="profile-company__container--title">
            <span className="title-container-register">
              <Translate contentKey={'register.profile-company.title'} />
            </span>
              <Translate contentKey={'register.profile-company.title-second'} />
            </div>
          </Col>
        </Row>
        <Row className={'cards-wrapper'}>
          <Card
              icon="content/images/icons/profile-company-card1-icon.svg"
              iconAlt="content/images/icons/profile-company-card1-iconw.svg"
              title={<Translate contentKey={'register.profile-company.card.first-title'} />}
              text={<Translate contentKey={'register.profile-company.card.first-text'} />}
              onClick={onManagerClick}
              disabled={props.hasManagerAuthority}
          />
          <Card
              icon="content/images/icons/profile-company-card2-icon.svg"
              iconAlt="content/images/icons/profile-company-card2-iconw.svg"
              title={<Translate contentKey={'register.profile-company.card.second-title'} />}
              text={<Translate contentKey={'register.profile-company.card.second-text'} />}
              onClick={onProducerClick}
              disabled={props.hasProducerAuthority}
          />
          <Card
              icon="content/images/icons/profile-company-card3-icon.svg"
              iconAlt="content/images/icons/profile-company-card3-iconw.svg"
              title={<Translate contentKey={'register.profile-company.card.third-title'} />}
              text={<Translate contentKey={'register.profile-company.card.third-text'} />}
              onClick={onTransporterClick}
              disabled={props.hasTransporterAuthority}
          />
          <Card
              icon="content/images/icons/transbordo-verde.svg"
              iconAlt="content/images/icons/transbordo-verde.svg"
              title={<Translate contentKey={'register.profile-company.card.fifth-title'} />}
              text={<Translate contentKey={'register.profile-company.card.fifth-text'} />}
              onClick={onTransshipmentClick}
              disabled={props.hasTransshipmentAuthority}
          />
          <Card
              icon="content/images/icons/profile-company-card4-icon.svg"
              iconAlt="content/images/icons/profile-company-card4-iconw.svg"
              title={<Translate contentKey={'register.profile-company.card.fourth-title'} />}
              text={<Translate contentKey={'register.profile-company.card.fourth-text'} />}
              onClick={onFinalDestinationClick}
              disabled={props.hasFinalDestinationAuthority}
          />
        </Row>
      </Container>
    </div>
);
}
const mapStateToProps = (root: IRootState) => ({
  hasManagerAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
  hasProducerAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]),
  hasTransporterAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSPORTER_ADMIN, AUTHORITIES.TRANSPORTER_USER]),
  hasTransshipmentAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER]),
  hasFinalDestinationAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER]),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(RegisterChoose);

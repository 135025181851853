// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../content/images/icons/x-2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".select-payment__container {\n  font-family: \"Catamaran\";\n  padding-top: 15px;\n}\n.select-payment__container-payment--title {\n  color: #000000;\n  font-weight: 400;\n  font-size: 24px;\n  text-align: center;\n  line-height: 39px;\n  margin-bottom: 5px;\n}\n.select-payment__container-payment--title-bold {\n  font-weight: 600;\n}\n.select-payment__container-payment--logged-user {\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 29px;\n  color: #808080;\n  display: block;\n  text-align: center;\n}\n.select-payment__container-payment--icon-close {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: absolute;\n  top: 6px;\n  right: -200px;\n  cursor: pointer;\n}\n.select-payment__container-payment--buttons-methods {\n  display: flex;\n  box-sizing: border-box;\n  width: 414px;\n  margin: 32px auto 0 auto;\n  -moz-column-gap: 16px;\n       column-gap: 16px;\n}\n.select-payment__container-payment--ticket {\n  max-width: 410px;\n  margin: 0 auto;\n  margin-top: 18px;\n  color: #7b7b7b;\n  font-size: 16px;\n  font-weight: 300;\n  line-height: 26px;\n}\n.select-payment__container-payment--ticket .bold {\n  font-weight: 600;\n  color: #30ab64;\n}\n.select-payment__container-payment--credit-card {\n  box-sizing: border-box;\n  width: 414px;\n  margin: 16px auto 0 auto;\n}", ""]);
// Exports
module.exports = exports;

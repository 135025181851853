import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getAddresses } from 'app/entities/address/address.reducer';
import { getEntities as getCnaeCodes } from 'app/entities/cnae-code/cnae-code.reducer';
import { getEntities as getDocuments } from 'app/entities/document/document.reducer';
import { getEntities as getProducers } from 'app/entities/producer/producer.reducer';
import { IRootState } from 'app/shared/reducers';
import { mapIdList } from 'app/shared/util/entity-utils';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './producer.reducer';

export interface IProducerUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IProducerUpdateState {
  isNew: boolean;
  idscnaeCode: any[];
  addressId: string;
  holdingCompanyId: string;
  businessLicenseId: string;
}

export class ProducerUpdate extends React.Component<IProducerUpdateProps, IProducerUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      idscnaeCode: [],
      addressId: '0',
      holdingCompanyId: '0',
      businessLicenseId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAddresses();
    this.props.getProducers();
    this.props.getCnaeCodes();
    this.props.getDocuments();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { producerEntity } = this.props;
      const entity = {
        ...producerEntity,
        ...values,
        cnaeCodes: mapIdList(values.cnaeCodes),
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/producer');
  };

  render() {
    const { producerEntity, addresses, producers, cnaeCodes, documents, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.producer.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.producer.home.createOrEditLabel">Create or edit a Producer</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : producerEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="producer-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="producer-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="cnpjLabel" for="producer-cnpj">
                    <Translate contentKey="resitrackApp.producer.cnpj">Cnpj</Translate>
                  </Label>
                  <AvField
                    id="producer-cnpj"
                    type="text"
                    name="cnpj"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="companyCnpjLabel" for="producer-companyCnpj">
                    <Translate contentKey="resitrackApp.producer.companyCnpj">Company Cnpj</Translate>
                  </Label>
                  <AvField id="producer-companyCnpj" type="text" name="companyCnpj" />
                </AvGroup>
                <AvGroup>
                  <Label id="companyNameLabel" for="producer-companyName">
                    <Translate contentKey="resitrackApp.producer.companyName">Company Name</Translate>
                  </Label>
                  <AvField id="producer-companyName" type="text" name="companyName" />
                </AvGroup>
                <AvGroup>
                  <Label id="fantasyNameLabel" for="producer-fantasyName">
                    <Translate contentKey="resitrackApp.producer.fantasyName">Fantasy Name</Translate>
                  </Label>
                  <AvField id="producer-fantasyName" type="text" name="fantasyName" />
                </AvGroup>
                <AvGroup>
                  <Label id="phoneLabel" for="producer-phone">
                    <Translate contentKey="resitrackApp.producer.phone">Phone</Translate>
                  </Label>
                  <AvField id="producer-phone" type="text" name="phone" />
                </AvGroup>
                <AvGroup>
                  <Label id="generatorTypeLabel" for="producer-generatorType">
                    <Translate contentKey="resitrackApp.producer.generatorType">Generator Type</Translate>
                  </Label>
                  <AvInput
                    id="producer-generatorType"
                    type="select"
                    className="form-control"
                    name="generatorType"
                    value={(!isNew && producerEntity.generatorType) || 'AGRICULTURE'}
                  >
                    <option value="AGRICULTURE">{translate('resitrackApp.GeneratorType.AGRICULTURE')}</option>
                    <option value="TRADE">{translate('resitrackApp.GeneratorType.TRADE')}</option>
                    <option value="CONSTRUCTION">{translate('resitrackApp.GeneratorType.CONSTRUCTION')}</option>
                    <option value="EDUCATION">{translate('resitrackApp.GeneratorType.EDUCATION')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="generatorCompanyTypeLabel" for="producer-generatorCompanyType">
                    <Translate contentKey="resitrackApp.producer.generatorCompanyType">Generator Company Type</Translate>
                  </Label>
                  <AvInput
                    id="producer-generatorCompanyType"
                    type="select"
                    className="form-control"
                    name="generatorCompanyType"
                    value={(!isNew && producerEntity.generatorCompanyType) || 'HOLDING'}
                  >
                    <option value="HOLDING">{translate('resitrackApp.GeneratorCompanyType.HOLDING')}</option>
                    <option value="SUBSIDIARY">{translate('resitrackApp.GeneratorCompanyType.SUBSIDIARY')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="activityTypeLabel" for="producer-activityType">
                    <Translate contentKey="resitrackApp.producer.activityType">Activity Type</Translate>
                  </Label>
                  <AvInput
                    id="producer-activityType"
                    type="select"
                    className="form-control"
                    name="activityType"
                    value={(!isNew && producerEntity.activityType) || 'ACTIVITY'}
                  >
                    <option value="ACTIVITY">{translate('resitrackApp.ActivityType.ACTIVITY')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="producer-address">
                    <Translate contentKey="resitrackApp.producer.address">Address</Translate>
                  </Label>
                  <AvInput id="producer-address" type="select" className="form-control" name="address.id">
                    <option value="" key="0" />
                    {addresses
                      ? addresses.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="producer-holdingCompany">
                    <Translate contentKey="resitrackApp.producer.holdingCompany">Holding Company</Translate>
                  </Label>
                  <AvInput id="producer-holdingCompany" type="select" className="form-control" name="holdingCompany.id">
                    <option value="" key="0" />
                    {producers
                      ? producers.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="producer-cnaeCode">
                    <Translate contentKey="resitrackApp.producer.cnaeCode">Cnae Code</Translate>
                  </Label>
                  <AvInput
                    id="producer-cnaeCode"
                    type="select"
                    multiple
                    className="form-control"
                    name="cnaeCodes"
                    value={producerEntity.cnaeCodes && producerEntity.cnaeCodes.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {cnaeCodes
                      ? cnaeCodes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="producer-businessLicense">
                    <Translate contentKey="resitrackApp.producer.businessLicense">Business License</Translate>
                  </Label>
                  <AvInput id="producer-businessLicense" type="select" className="form-control" name="businessLicense.id">
                    <option value="" key="0" />
                    {documents
                      ? documents.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/producer" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  addresses: storeState.address.entities,
  producers: storeState.producer.entities,
  cnaeCodes: storeState.cnaeCode.entities,
  documents: storeState.document.entities,
  producerEntity: storeState.producer.entity,
  loading: storeState.producer.loading,
  updating: storeState.producer.updating,
  updateSuccess: storeState.producer.updateSuccess,
});

const mapDispatchToProps = {
  getAddresses,
  getProducers,
  getCnaeCodes,
  getDocuments,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerUpdate);

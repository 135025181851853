import './search.scss';
import React from 'react';


const SearchPage = () => {
  return (
    <div className="mtr-list__search">
      <div className="form-inline md-form">
        <input className="mtr-list__search--form" type="text" placeholder="Buscar resíduos" aria-label="Search" icon="search" />
        <div className="mtr-list__search--icon" />
      </div>
    </div>
  );
};

export default SearchPage;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../content//images/icons/green-check-no-background.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../../../../content/images/icons/green-x-no-background.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".manager-card__container {\n  font-size: 14px;\n  color: #2d3e46;\n  padding-left: 17px;\n  margin-bottom: 30px;\n}\n.manager-card__container li {\n  text-align: left;\n}\n.manager-card__container li::marker {\n  color: #30ab64;\n}\n.manager-card__container--list-items {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 55px;\n}\n.manager-card__container--list-items > span {\n  width: 160px;\n}\n.manager-card__container--list-items .one-card {\n  width: 930px;\n}\n.manager-card__container--list-items-icon-check {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.manager-card__container--list-items-icon-x {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.manager-card__container--list-items div {\n  width: 120px;\n  text-align: center;\n}\n.manager-card__container--free {\n  color: #30ab64;\n  font-size: 24px;\n  font-weight: 600;\n}\n.manager-card__container--premium {\n  color: #2d3e46;\n  font-size: 24px;\n  font-weight: 600;\n}\n.manager-card__container--sign-button {\n  height: 46px;\n  width: 172px;\n  margin-top: 10px;\n  color: #30ab64;\n  border: 1px solid #30ab64;\n  background: #fff;\n  border-radius: 4px;\n  font-size: 14px;\n  font-weight: 500;\n}", ""]);
// Exports
module.exports = exports;

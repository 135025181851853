import './mtr-filter-open.scss';
import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { translate } from 'react-jhipster';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';

const MtrFilterOpen = () => {
  return (
    <div className="mtr-list__filter-open">
      <Row style={{ marginBottom: '30px' }}>
        <Col md="5">
          <div className="mtr-list__filter-open--title">{translate('mtr-list.filter-open.title-first')}</div>
          <div className="mtr-list__filter-open--items">
            <span>{translate('mtr-list.filter-open.if')}</span>
            <div className="mtr-list__filter-open--date">
              <span>Date</span>
              <div className="mtr-list__filter-open--img" />
            </div>
            <span>{translate('mtr-list.filter-open.until')}</span>
            <div className="mtr-list__filter-open--date">
              <span>Date</span>
              <div className="mtr-list__filter-open--img" />
            </div>
          </div>
        </Col>
        <Col md="7">
          <div className="mtr-list__filter-open--title">{translate('mtr-list.filter-open.title-second')}</div>
          <div className="mtr-list__filter-open--items">
            <SimpleSearchField placeholder={'Buscar MTR/CTR'} onChange={() => null} />
            <div className="mtr-list__filter-open--button">
              <span>{translate('mtr-list.filter-open.button')}</span>
              <div className="mtr-list__filter-open--img" />
            </div>
            <div className="mtr-list__filter-open--clear-filter">{translate('mtr-list.filter-open.clear-filter')}</div>
            <div className="mtr-list__filter-open--close" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MtrFilterOpen;

import { IDocument } from '../model/document.model';
import StringUtils from './string-utils';
import _ from 'lodash';

export const organizeSearchParameters = (page, size, sort, search) => {
  let searchParams = '?';
  searchParams = page ? searchParams + `page=${page}&` : searchParams;
  searchParams = size ? searchParams + `size=${size}&` : searchParams;
  searchParams = sort && !_.isEmpty(sort) ? searchParams + createSortParameter(sort) : searchParams;
  searchParams = search ? searchParams + `${search}&` : searchParams;
  return searchParams;
};

export const createSortParameter = sort => {
  return `sort=${sort}&`;
};

export const openOrDownloadDocument = (document: IDocument) => {
  if (document.presignedUrl == null && document.file != null) {
    const blobUrl = StringUtils.generateFileUrl(document.file, document.fileContentType);
    const blob = StringUtils.b64toBlob(document.file, document.fileContentType, null);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, document.name);
    } else {
      window.open(blobUrl, '_blank');
    }
  } else {
    window.open(document.presignedUrl);
  }
};

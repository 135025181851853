import { ColumnSort } from 'app/components/simple-ordered-table/simple-ordered-table';
import { FavoriteType } from 'app/shared/model/favorite.model';
import { Pageable } from 'app/shared/model/pageable';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './generic-favorite-list.scss';
import { RouteComponentProps } from 'react-router-dom';

export interface IGenericFavoriteProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export class GenericFavorite extends React.Component<IGenericFavoriteProps> {
    titleProducer = translate('entity.producer');
    tableProducerHeaders: ColumnSort[] = [
        { name: translate('favorite.headers.name'), sortCode: 'producer.name' },
        { name: translate('favorite.headers.residueName'), sortCode: 'specificResidue.name' },
        { name: translate('favorite.headers.city'), sortCode: 'city.name' },
        { name: translate('favorite.headers.phone'), sortCode: 'user.phone' },
        { name: translate('favorite.headers.favorite'), sortCode: 'favorite.id' },
    ];

    titleFinalDestination = translate('entity.finalDestination');
    tableFinalDestinationHeaders: ColumnSort[] = [
        { name: translate('favorite.headers.name'), sortCode: 'fantasyName' },
        { name: translate('favorite.headers.residueName'), sortCode: 'specificResidue.name' },
        { name: translate('favorite.headers.finalCategory'), sortCode: 'finalDestination.category' },
        { name: translate('favorite.headers.city'), sortCode: 'city.name' },
        { name: translate('favorite.headers.phone'), sortCode: 'user.phone' },
        { name: translate('favorite.headers.favorite'), sortCode: 'favorite.id' },
    ];

    titleTransshipment = translate('entity.transshipment');
    tableTransshipmentHeaders: ColumnSort[] = [
        { name: translate('favorite.headers.name'), sortCode: 'transshipment.name' },
        { name: translate('favorite.headers.residueName'), sortCode: 'specificResidue.name' },
        { name: translate('favorite.headers.city'), sortCode: 'city.name' },
        { name: translate('favorite.headers.phone'), sortCode: 'user.phone' },
        { name: translate('favorite.headers.favorite'), sortCode: 'favorite.id' },
    ];

    titleTransporter = translate('entity.transporter');
    tableTransporterHeaders: ColumnSort[] = [
        { name: translate('favorite.headers.name'), sortCode: 'transporter.name' },
        { name: translate('favorite.headers.residueName'), sortCode: 'specificResidue.name' },
        { name: translate('favorite.headers.city'), sortCode: 'city.name' },
        { name: translate('favorite.headers.phone'), sortCode: 'user.phone' },
        { name: translate('favorite.headers.favorite'), sortCode: 'favorite.id' },
    ];

    pageSummary: Pageable = { size: 3, page: 0, totalElements: 0, totalPages: 0 };
    page: Pageable = { size: 15, page: 0, totalElements: 0, totalPages: 0 };

    inviteProducerTransporter = translate('favorite.invitePartner');
    inviteFinalDestinationOrTransporter = translate('favorite.invitePartner');
    inviteFinalDestinationOrProducer = translate('favorite.invitePartner');
    inviteByTransshipment = translate('favorite.invitePartner');

    seeAllTransporters = translate('favorite.seeAllTransporters');
    seeAllProducers = translate('favorite.seeAllProducers');
    seeAllManagers = translate('favorite.seeAllManagers');
    seeAllTransshipments = translate('favorite.seeAllTransshipments');
    seeAllFinalDestinations = translate('favorite.seeAllFinalDestinations');

    constructor(props) {
        super(props);
        this.state = {};
    }

    goToInvitationPage = () => {
        this.props.history.push(`invite`);
    };

    handleGoBack = () => {
        this.props.history.goBack();
    };

    handleListClick = (favoriteType: FavoriteType) => {
        const { url } = this.props.match;
        switch (favoriteType) {
            case FavoriteType.TRANSPORTER:
                this.props.history.push(`${url}/transporters`);
                break;
            case FavoriteType.FINAL_DESTINATION:
                this.props.history.push(`${url}/final-destinations`);
                break;
            case FavoriteType.TRANSSHIPMENT:
                this.props.history.push(`${url}/transshipments`);
                break;
            case FavoriteType.PRODUCER:
                this.props.history.push(`${url}/producers`);
                break;
        }
    };

    onItemClicked = (id: number, favoriteType: FavoriteType) => {
        const { url } = this.props.match;
        switch (favoriteType) {
            case FavoriteType.TRANSPORTER:
                this.props.history.push({
                    pathname: `${url.replace('/transporters', '')}/transporters/detail`,
                    state: { id: id },
                });
                break;
            case FavoriteType.FINAL_DESTINATION:
                this.props.history.push({
                    pathname: `${url.replace('/final-destinations', '')}/final-destinations/detail`,
                    state: { id: id },
                });
                break;
            case FavoriteType.TRANSSHIPMENT:
                this.props.history.push({
                    pathname: `${url.replace('/transshipments', '')}/transshipments/detail`,
                    state: { id: id },
                });
                break;
            case FavoriteType.PRODUCER:
                this.props.history.push({
                    pathname: `${url.replace('/producers', '')}/producers/detail`,
                    state: { id: id },
                });
                break;
        }
    };
}

const mapStateToProps = (root: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GenericFavorite);

import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { getEntitiesByStateId as getCitiesByState } from 'app/entities/city/city.reducer';
import { getEntities as getStates } from 'app/entities/state/state.reducer';
import {
  validateCNPJ,
  validateGenericField,
  validatePhoneNumber,
} from 'app/modules/validation/validation-constants';
import { IFinalDestination } from 'app/shared/model/final-destination.model';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import { IUserGeneric } from 'app/shared/model/user-generic.model';
import { IUser } from 'app/shared/model/user.model';
import { IRootState } from 'app/shared/reducers';
import { maskCnpj, maskPhone, unmask } from 'app/shared/util/mask-utils';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import NextCancelButton from '../../../../../components/next-cancel-button/next-cancel-button';

export interface IResponsibleFormComponentProps extends StateProps, DispatchProps {
  userFinalDestination?: IUserFinalDestination;
  userGeneric: IUserGeneric;
  onNextHandler: (userGeneric: IUserGeneric) => void;
  onBack: () => void;
  isNew?: boolean;
  validationIgnore?: Map<string, boolean>;
  cnpjHolding?: string
  companyName?: string
}

export interface IResponsibleFormComponentState {
  companyName: string;
  fantasyName: string;
  phone: string;
  fantasyNameError: ValidationResult;
  phoneError: ValidationResult;
}

export class ResponsibleFormComponent extends React.Component<IResponsibleFormComponentProps, IResponsibleFormComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      companyName: this.props?.companyName ?? null,
      fantasyName: this.props.userFinalDestination?.finalDestination?.fantasyName ?? null,
      phone: this.props.userFinalDestination?.finalDestination?.phone ?? null,
      fantasyNameError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    this.props.getStates();
  }

  onValidateAll = () => {
    const { isNew } = this.props;
    let hasError = false;

    if (this.onValidateName()) {
      hasError = true;
    }
    if (isNew && this.onValidatePhone()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateName = () => {
    const { fantasyName } = this.state;
    const validate = validateGenericField(fantasyName);
    this.setState({
      fantasyNameError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };


  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const userFinalDestination = { ...this.props.userFinalDestination };
    const { fantasyName, phone } = this.state;
    const userFinalDestinationUpdated: IUserFinalDestination = {
      ...userFinalDestination,
      finalDestination: {
        ...userFinalDestination.finalDestination,
        fantasyName: fantasyName,
        phone: phone
      }
    } 
    this.props.onNextHandler(userFinalDestinationUpdated);
  };


  render() {
    const {
      fantasyName,
      phone,
      fantasyNameError,
      phoneError,
    } = this.state;
    return (
      <div className="responsible__items">
        <h1>{translate('responsible.title')}</h1>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <CustomTextField
              readonly
              id="cnpj"
              style={{ marginTop: '20px' }}
              value={this.props.cnpjHolding}
              onMask={maskCnpj}
              label="CNPJ"
              placeholder="CNPJ"
            />
            <CustomTextField
              readonly
              id="companyName"
              style={{ marginTop: '20px' }}
              value={this.props.companyName}
              label="Nome da Empresa"
              placeholder="Nome da Empresa"
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  fantasyName: text,
                })
              }
              id="fantasyName"
              style={{ marginTop: '20px' }}
              value={fantasyName}
              label="Nome da Unidade"
              placeholder="Nome da Unidade"
              error={fantasyNameError.hasError}
              errorText={translateErrorMessage(fantasyNameError.errorMessage)}
              onBlur={this.onValidateName}
            />
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  phone: text,
                })
              }
              id="phone"
              style={{ marginTop: '20px' }}
              value={phone}
              onMask={maskPhone}
              label="Telefone"
              placeholder="Telefone"
              error={phoneError.hasError}
              errorText={translateErrorMessage(phoneError.errorMessage)}
              onBlur={this.onValidatePhone}
            />
          </Col>
        </Row>

        <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCitiesByState,
  getStates,
};

const mapStateToProps = (root: IRootState) => ({
  states: root.state.entities,
  cities: root.city.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleFormComponent);

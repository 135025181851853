import { DriverType, IDriver } from 'app/shared/model/driver.model';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transporter-register-driver.scss';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import { validateGenericField, validateObject } from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { maskNumber } from 'app/shared/util/mask-utils';
import { translate } from 'react-jhipster';
import InlineSelect from 'app/components/inline-select/inline-select';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import { Col, Row } from 'reactstrap';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface ITransporterRegisterDriverProps extends StateProps, DispatchProps {
  userTransporter: IUserTransporter;
  onNext: (userTransporter: IUserTransporter) => void;
  onBack: () => void;
}

export interface ITransporterRegisterDriverState {
  driverLicense: string;
  expirationDate: string;
  driverType: DriverType;
  driverLicenseError: ValidationResult;
  expirationDateError: ValidationResult;
  driverTypeError: ValidationResult;
}

export class TransporterRegisterDriver extends React.Component<ITransporterRegisterDriverProps, ITransporterRegisterDriverState> {
  constructor(props) {
    super(props);
    const driver = this.props.userTransporter.driver;
    this.state = {
      driverLicense: driver ? driver.driverLicense : null,
      expirationDate: driver ? driver.expirationDate : null,
      driverType: driver ? driver.driverType : null,
      driverLicenseError: ValidationResultInstance,
      expirationDateError: ValidationResultInstance,
      driverTypeError: ValidationResultInstance,
    };
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateDriverLicense()) {
      hasError = true;
    }
    if (this.onValidateExpirationDate()) {
      hasError = true;
    }
    if (this.onValidateDriverType()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateDriverLicense = () => {
    const { driverLicense } = this.state;
    const validate = validateGenericField(driverLicense);
    this.setState({
      driverLicenseError: validate,
    });
    return validate.hasError;
  };

  onValidateExpirationDate = () => {
    const { expirationDate } = this.state;
    const validate = validateGenericField(expirationDate);
    this.setState({
      expirationDateError: validate,
    });
    return validate.hasError;
  };

  onValidateDriverType = () => {
    const { driverType } = this.state;
    const validate = validateObject(driverType);
    this.setState({
      driverTypeError: validate,
    });
    return validate.hasError;
  };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }
    const userTransporter = { ...this.props.userTransporter };

    const { driverLicense, expirationDate, driverType } = this.state;

    const driverUpdated: IDriver = {
      ...userTransporter,
      driverLicense,
      expirationDate,
      driverType,
    };

    userTransporter.driver = driverUpdated;

    this.props.onNext(userTransporter);
  };

  mapDriverTypeToOptions = () => {
    const optionsList = [];
    Object.keys(DriverType).forEach((item, index) => {
      optionsList.push({ label: `${item}`, value: item });
    });
    return optionsList;
  };

  onSelectDriverType = (item: any) => {
    this.setState(
      {
        driverType: item,
      },
      () => this.onValidateDriverType()
    );
  };

  mapSelectedDriverTypeToSelect = () => {
    const { driverType } = this.state;

    return driverType ? { label: driverType, value: driverType } : null;
  };

  render() {
    const { driverLicense, expirationDate, driverLicenseError, expirationDateError, driverTypeError } = this.state;
    return (
      <div>
        <h1>{translate('transporterRegister.person.driverLicense.title')}</h1>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <CustomTextField
              onChange={(text: string) =>
                this.setState({
                  driverLicense: text,
                })
              }
              id="driverLicense"
              onMask={maskNumber}
              style={{ marginTop: '20px' }}
              value={driverLicense}
              label={translate('transporterRegister.person.driverLicense.licenseNumber')}
              placeholder={translate('transporterRegister.person.driverLicense.licenseNumber')}
              error={driverLicenseError.hasError}
              errorText={translateErrorMessage(driverLicenseError.errorMessage)}
              onBlur={this.onValidateDriverLicense}
            />
            <InlineSelect
              placeholder={translate('transporterRegister.person.driverLicense.driverType')}
              error={driverTypeError.hasError}
              errorMessage={translateErrorMessage(driverTypeError.errorMessage)}
              options={this.mapDriverTypeToOptions()}
              onChange={this.onSelectDriverType}
              startIndex={0}
              selectedOption={this.mapSelectedDriverTypeToSelect()}
            />
            <DatePickerComponent
              dateFormat={APP_LOCAL_DATE_FORMAT}
              onDateChange={value => {
                this.setState(
                  {
                    expirationDate: value,
                  },
                  () => this.onValidateExpirationDate()
                );
              }}
              dateSelected={expirationDate}
              placeholder={translate('transporterRegister.person.driverLicense.expirationDate')}
              error={expirationDateError.hasError}
              errorMessage={translateErrorMessage(expirationDateError.errorMessage)}
            />
            <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterDriver);

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transporter-register-legal.scss';
import { translate } from 'react-jhipster';
import { ITransporter } from 'app/shared/model/transporter.model';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import {
  validateCNPJ,
  validateGenericField,
  validateGenericFieldList,
  validatePhoneNumber,
} from 'app/modules/validation/validation-constants';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { getEntities as getCnaeCodes } from 'app/entities/cnae-code/cnae-code.reducer';
import InlineSelect from 'app/components/inline-select/inline-select';
import { ICnaeCode } from 'app/shared/model/cnae-code.model';
import CnaeCodeComponent from 'app/components/cnae-code-component/cnaeCodeComponent';
import { maskCnpj, maskNumber, maskPhone, unmask } from 'app/shared/util/mask-utils';
import { ILegalPerson } from 'app/shared/model/legal-person.model';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface ITransporterRegisterLegalProps extends StateProps, DispatchProps {
  transporter: ITransporter;
  onNext: (transporter: ITransporter) => void;
  onBack: () => void;
}

export interface ITransporterRegisterLegalState {
  cnpj: string;
  fantasyName: string;
  companyName: string;
  municipalRegistration: string;
  phone: string;
  // cnaeCodes: ICnaeCode[];
  cnpjError: ValidationResult;
  fantasyNameError: ValidationResult;
  companyNameError: ValidationResult;
  municipalRegistrationError: ValidationResult;
  phoneError: ValidationResult;
  // cnaeCodeError: ValidationResult;
}

export class TransporterRegisterLegal extends React.Component<ITransporterRegisterLegalProps, ITransporterRegisterLegalState> {
  constructor(props) {
    super(props);
    const legalPerson = this.props.transporter.legalPerson;
    this.state = {
      cnpj: legalPerson ? maskCnpj(legalPerson.cnpj) : null,
      fantasyName: legalPerson ? legalPerson.fantasyName : null,
      companyName: legalPerson ? legalPerson.companyName : null,
      municipalRegistration: legalPerson ? legalPerson.municipalRegistration : null,
      phone: legalPerson ? maskPhone(legalPerson.phone) : null,
      // cnaeCodes: this.props.transporter.cnaeCodes ? this.props.transporter.cnaeCodes : [],
      cnpjError: ValidationResultInstance,
      fantasyNameError: ValidationResultInstance,
      companyNameError: ValidationResultInstance,
      municipalRegistrationError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
      // cnaeCodeError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    // await this.props.getCnaeCodes();
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateCnpj()) {
      hasError = true;
    }
    if (this.onValidateFantasyName()) {
      hasError = true;
    }
    if (this.onValidateCompanyName()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    // if (this.onValidateCnaeCodes()) {
    //   hasError = true;
    // }
    return hasError;
  };

  onValidateCnpj = () => {
    const { cnpj } = this.state;
    const validate = validateCNPJ(cnpj);
    this.setState({
      cnpjError: validate,
    });
    return validate.hasError;
  };

  onValidateFantasyName = () => {
    const { fantasyName } = this.state;
    const validate = validateGenericField(fantasyName);
    this.setState({
      fantasyNameError: validate,
    });
    return validate.hasError;
  };

  onValidateCompanyName = () => {
    const { companyName } = this.state;
    const validate = validateGenericField(companyName);
    this.setState({
      companyNameError: validate,
    });
    return validate.hasError;
  };


  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  // onValidateCnaeCodes = () => {
  //   const { cnaeCodes } = this.state;
  //   const validate = validateGenericFieldList(cnaeCodes);
  //   this.setState({
  //     cnaeCodeError: validate,
  //   });
  //   return validate.hasError;
  // };

  // mapCnaeCodesToOptions = () => {
  //   const { cnaeCodes } = this.props;
  //   const optionsList = [];
  //   optionsList.push({ label: translate('transporterRegister.person.legal.form.selectCnae'), value: -1 });
  //   if (cnaeCodes == null || cnaeCodes.length === 0) {
  //     return optionsList;
  //   } else {
  //     cnaeCodes.forEach((item, index) => {
  //       optionsList.push({ label: `${item.code} - ${item.description}`, value: item.id });
  //     });
  //     return optionsList;
  //   }
  // };

  // onSelectCnaeCode = value => {
  //   if (value === -1) {
  //     return;
  //   }
  //   const cnaeCodeSelected = this.props.cnaeCodes.filter(it => it.id === value)[0];
  //   const cnaeCodes = [];
  //   if (cnaeCodes.filter(it => it.id === value).length === 0) {
  //     cnaeCodes.push(cnaeCodeSelected);
  //     this.setState(
  //       {
  //         cnaeCodes,
  //       },
  //       () => this.onValidateCnaeCodes()
  //     );
  //   }
  // };

  // onDeleteCnaeCode = value => {
  //   const cnaeCodes = [...this.state.cnaeCodes];
  //   cnaeCodes.splice(value, 1);
  //   this.setState(
  //     {
  //       cnaeCodes,
  //     },
  //     () => this.onValidateCnaeCodes()
  //   );
  // };

  onNextHandler = () => {
    if (this.onValidateAll()) {
      return;
    }
    const { cnpj, fantasyName, companyName, municipalRegistration, phone } = this.state;
    const { transporter } = this.props;

    const legalPerson: ILegalPerson = {
      cnpj: unmask(cnpj),
      fantasyName,
      companyName,
      municipalRegistration,
      phone: unmask(phone),
    };

    const transporterUpdated: ITransporter = {
      ...transporter,
      legalPerson,
      // cnaeCodes,
    };

    this.props.onNext(transporterUpdated);
  };

  render() {
    const {
      cnpj,
      cnpjError,
      fantasyName,
      fantasyNameError,
      companyName,
      companyNameError,
      phone,
      phoneError,
      municipalRegistration,
      municipalRegistrationError,
      // cnaeCodes,
      // cnaeCodeError,
    } = this.state;
    return (
      <div className="transporter-register-legal__items">
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              cnpj: text,
            })
          }
          id="cnpj"
          style={{ marginTop: '20px' }}
          value={cnpj}
          onMask={maskCnpj}
          label={translate('transporterRegister.person.legal.form.cnpj')}
          placeholder={translate('transporterRegister.person.legal.form.cnpj')}
          error={cnpjError.hasError}
          errorText={translateErrorMessage(cnpjError.errorMessage)}
          onBlur={this.onValidateCnpj}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              fantasyName: text,
            })
          }
          id="fantasyName"
          style={{ marginTop: '20px' }}
          value={fantasyName}
          label={translate('transporterRegister.person.legal.form.fantasyName')}
          placeholder={translate('transporterRegister.person.legal.form.fantasyName')}
          error={fantasyNameError.hasError}
          errorText={translateErrorMessage(fantasyNameError.errorMessage)}
          onBlur={this.onValidateFantasyName}
        />
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              companyName: text,
            })
          }
          id="companyName"
          style={{ marginTop: '20px' }}
          value={companyName}
          label={translate('transporterRegister.person.legal.form.companyName')}
          placeholder={translate('transporterRegister.person.legal.form.companyName')}
          error={companyNameError.hasError}
          errorText={translateErrorMessage(companyNameError.errorMessage)}
          onBlur={this.onValidateCompanyName}
        />
        {/* <CustomTextField
          onChange={(text: string) =>
            this.setState({
              municipalRegistration: text,
            })
          }
          helpText={'TOpzera'}
          id="municipalRegistration"
          onMask={maskNumber}
          style={{ marginTop: '20px' }}
          value={municipalRegistration}
          label={translate('transporterRegister.person.legal.form.municipalRegistration')}
          placeholder={translate('transporterRegister.person.legal.form.municipalRegistration')}
          error={municipalRegistrationError.hasError}
          errorText={translateErrorMessage(municipalRegistrationError.errorMessage)}
        /> */}
        <CustomTextField
          onChange={(text: string) =>
            this.setState({
              phone: text,
            })
          }
          onMask={maskPhone}
          id="phone"
          style={{ marginTop: '20px' }}
          value={phone}
          label={translate('transporterRegister.person.legal.form.phone')}
          placeholder={translate('transporterRegister.person.legal.form.phone')}
          error={phoneError.hasError}
          errorText={translateErrorMessage(phoneError.errorMessage)}
          onBlur={this.onValidatePhone}
        />
        {/* {cnaeCodes != null &&
          cnaeCodes.length > 0 &&
          cnaeCodes.map((item, index) => (
            <CnaeCodeComponent key={`cnaeCode-${item.id}-${index}`} cnaeCode={item} index={index} onDelete={this.onDeleteCnaeCode} />
          ))}
        {(cnaeCodes == null || cnaeCodes.length == 0) && (
          <div>
            <InlineSelect
              placeholder={translate('producerRegister.company.holding.form.selectCnae')}
              error={cnaeCodeError.hasError}
              errorMessage={translateErrorMessage(cnaeCodeError.errorMessage)}
              dontAttachSelected
              options={this.mapCnaeCodesToOptions()}
              onChange={this.onSelectCnaeCode}
              startIndex={0}
            />
          </div>
        )} */}

        <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNextHandler} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  // getCnaeCodes,
};

const mapStateToProps = (root: IRootState) => ({
  // cnaeCodes: root.cnaeCode.entities,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterLegal);

import Checkbox from '@material-ui/core/Checkbox';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import { IFavoriteItemVM } from 'app/shared/model/favorite-item-vm.model';
import { FavoriteType, IFavorite } from 'app/shared/model/favorite.model';
import { Pageable } from 'app/shared/model/pageable';
import { IRootState } from 'app/shared/reducers';
import { maskPhone } from 'app/shared/util/mask-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { default as Col } from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './generic-favorite-list.scss';
import { addFavorite, deleteEntity as deleteFavorite, getFavoriteEntities, reset } from './generic-favorite.reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import StringUtils from 'app/shared/util/string-utils';

export interface IGenericFavoriteListProps extends StateProps, DispatchProps {
  title: string;
  headers: ColumnSort[];
  isSummary?: boolean;
  page: Pageable;
  seAllTitle?: string;
  favoriteType: FavoriteType;
  onListClick?: (type: FavoriteType) => void;
  onItemClick?: (id: number, type: FavoriteType) => void;
}

export interface IGenericFavoriteListState {
  page: Pageable;
  entities: IFavoriteItemVM[];
  searchValue: string;
}

export class GenericFavoriteList extends React.Component<IGenericFavoriteListProps, IGenericFavoriteListState> {
  constructor(props: Readonly<IGenericFavoriteListProps>) {
    super(props);
    this.state = {
      page: this.props.page,
      entities: [],
      searchValue: '',
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getFavorites();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps: IGenericFavoriteListProps) {
    const { favoriteType } = this.props;

    switch (favoriteType) {
      case FavoriteType.FINAL_DESTINATION:
        if (
          newProps.fetchFavoriteFinalDestinationStatus === HttpRequestStatus.SUCCESS &&
          newProps.fetchFavoriteFinalDestinationStatus != this.props.fetchFavoriteFinalDestinationStatus
        ) {
          this.setState({
            entities: newProps.finalDestinations,
          });
        }
        break;
      case FavoriteType.TRANSSHIPMENT:
        if (
          newProps.fetchFavoriteTransshipmentStatus === HttpRequestStatus.SUCCESS &&
          newProps.fetchFavoriteTransshipmentStatus != this.props.fetchFavoriteTransshipmentStatus
        ) {
          this.setState({
            entities: newProps.transshipments,
          });
        }
        break;
      case FavoriteType.TRANSPORTER:
        if (
          newProps.fetchFavoriteTransporterStatus === HttpRequestStatus.SUCCESS &&
          newProps.fetchFavoriteTransporterStatus != this.props.fetchFavoriteTransporterStatus
        ) {
          this.setState({
            entities: newProps.transporters,
          });
        }
        break;
      case FavoriteType.PRODUCER:
        if (
          newProps.fetchFavoriteProducerStatus === HttpRequestStatus.SUCCESS &&
          newProps.fetchFavoriteProducerStatus != this.props.fetchFavoriteProducerStatus
        ) {
          this.setState({
            entities: newProps.producers,
          });
        }
        break;
    }
    if (newProps.totalItems != null && newProps.totalItems != this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
    if (
      newProps.favoritedEntity != null &&
      (newProps.addFavoriteStatus === HttpRequestStatus.SUCCESS && this.props.addFavoriteStatus != newProps.addFavoriteStatus)
    ) {
      let entityId = null;
      switch (newProps.favoritedEntity.favoriteType) {
        case FavoriteType.FINAL_DESTINATION:
          entityId = newProps.favoritedEntity.finalDestination.id
          break;
        case FavoriteType.PRODUCER:
          entityId = newProps.favoritedEntity.producer.id
          break;
        case FavoriteType.TRANSPORTER:
          entityId = newProps.favoritedEntity.transporter.id
          break;
        case FavoriteType.TRANSSHIPMENT:
          entityId = newProps.favoritedEntity.transshipment.id
          break;
      }

      if (entityId != null) {
        this.setFavoriteToEntity(entityId, newProps.favoritedEntity)
      }
    }
  }

  setFavoriteToEntity = (entityId: number, favorite: IFavorite) => {
    const { entities } = this.state;
    entities.filter(it => it.id === entityId).forEach(it =>
      it.favorite = favorite)
  }

  getFavorites = () => {
    const { favoriteType } = this.props;
    const { page, searchValue } = this.state;
    this.props.getFavoriteEntities(page.page, page.size, page.sort ? page.sort : 'favorite.id,desc', favoriteType, searchValue);
  };

  private handleTransformToTableContent = (content?: IFavoriteItemVM[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    const { favoriteType } = this.props;

    content.map((item: IFavoriteItemVM) => {
      let itens = [
        item.name ?? '',
        item.residueName ?? '',
        item.city ?? '',
        item.phone ? maskPhone(item.phone) : '',
        <Checkbox
          icon={<img src="content/images/icons/estrela-normal.svg" alt="" />}
          checkedIcon={<img src="content/images/icons/estrela-selecionado.svg" alt="" />}
          checked={item.favorite.id != null}
          disabled={this.props.addFavoriteStatus === HttpRequestStatus.ONGOING}
          onClick={(e) => {
            e.stopPropagation();
            this.onFavoriteClicked(e.target.checked, item);
          }}
        />,
      ];
      if (favoriteType === FavoriteType.FINAL_DESTINATION)
        itens.splice(2, 0, item.finalDestinationCategory != null ? translate(`enums.finalDestinationCategory.${item.finalDestinationCategory}`) : '');

      result.push(itens);
    });

    return result;
  };

  onFavoriteClicked = (checked: boolean, item: IFavoriteItemVM) => {
    if (!checked) {
      if (item.favorite?.id != null) {
        this.props.deleteFavorite(item.favorite.id);
      }
      item.favorite = {};
    } else {
      if (item.favorite?.id == null) {
        this.onAddFavorite(item);
      }
      item.favorite = { id: -1 };
    }
  };


  onAddFavorite = (item: IFavoriteItemVM) => {
    const { favoriteType } = this.props;
    const favorite: IFavorite = { favoriteType };
    switch (favoriteType) {
      case FavoriteType.FINAL_DESTINATION:
        favorite.finalDestination = {
          id: item.id,
        };
        break;
      case FavoriteType.PRODUCER:
        favorite.producer = {
          id: item.id,
        };
        break;
      case FavoriteType.TRANSPORTER:
        favorite.transporter = {
          id: item.id,
        };
        break;
      case FavoriteType.TRANSSHIPMENT:
        favorite.transshipment = {
          id: item.id,
        };
        break;
    }

    this.props.addFavorite(favorite);
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getFavorites()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },

      () => this.getFavorites()
    );
  };

  onEntityClicked = (index: number) => {
    const { entities } = this.state;

    this.props.onItemClick(entities[index].id, this.props.favoriteType);
  };

  handleSearchInputChange = (event) => {
    this.setState({ searchValue: event.target.value }, () => {
      // Após atualizar o estado de pesquisa, chame a função para atualizar os resultados da tabela.
      this.getFavorites();
    });
  };

  render() {
    const { headers, title, isSummary, seAllTitle } = this.props;
    const { entities } = this.state;
    const rows = this.handleTransformToTableContent(entities);

    return (
      <div key={`generic-favorite-list-${this.props.favoriteType}-${this.state.page.size}`}>
        <Row style={{ marginBottom: '5px', paddingTop: '40px', alignItems: 'center' }}>
          <Col md="12">
            <h1 style={{ textAlign: 'left', fontWeight: 600 }}>{title}</h1>
          </Col>
        </Row>
        {/* {!isSummary ? (
          <Row style={{ marginBottom: '15px', alignItems: 'center', width: '600px' }}>
            <Col md="12">
              <div className="mtr-list__search">
                <div className="form-inline md-form">
                  <input
                    className="mtr-list__search--form"
                    placeholder='Buscar por nome'
                    value={this.state.searchValue}
                    onChange={this.handleSearchInputChange}
                  />
                  <div className="mtr-list__search--icon" />
                </div>
              </div>
            </Col>
          </Row>
        ) : null} */}
        <Row>
          <Col>
            <SimpleOrderedTable
              rows={rows}
              columNameKeys={headers}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onSort={(code: string) => this.handleSort(code)}
              onClick={this.onEntityClicked}
              hidePagination={isSummary}
            />
            {isSummary && (
              <div className="mtr-invite__items--link">
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    this.props.onListClick(this.props.favoriteType);
                  }}
                >
                  {seAllTitle}
                </a>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchFavoriteProducerStatus: root.genericFavorite.fetchFavoriteProducerStatus,
  fetchFavoriteTransporterStatus: root.genericFavorite.fetchFavoriteTransporterStatus,
  fetchFavoriteTransshipmentStatus: root.genericFavorite.fetchFavoriteTransshipmentStatus,
  fetchFavoriteFinalDestinationStatus: root.genericFavorite.fetchFavoriteFinalDestinationStatus,
  deleteFavoriteStatus: root.genericFavorite.deleteFavoriteStatus,
  addFavoriteStatus: root.genericFavorite.addFavoriteStatus,
  producers: root.genericFavorite.producers,
  finalDestinations: root.genericFavorite.finalDestinations,
  transporters: root.genericFavorite.transporters,
  transshipments: root.genericFavorite.transshipments,
  totalItems: root.genericFavorite.totalItems,
  favoritedEntity: root.genericFavorite.favoritedEntity,
});

const mapDispatchToProps = {
  getFavoriteEntities,
  deleteFavorite,
  addFavorite,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GenericFavoriteList);

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './authorization-name.scss';

export const MtrAuthorizationName = () => {
  return (
    <div className="authorization-name__items">
      <div className="authorization-name__items--back">
        <Container>
          <Row>
            <Col md="10">
              <div className="confirmation--back">
                <div className="confirmation--back-icon" />
                <span>
                  <Translate contentKey={'mtr-signature.back'} />
                </span>
              </div>
            </Col>
            <Col md="2">
              <div className="authorization-name__items--edit">
                <Translate contentKey={'mtr-signature.edit'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ marginBottom: '35px' }}>
        <h1>{translate('mtr-generator.license.title')}</h1>
      </div>
      <Row>
        <Col md="5" style={{ margin: 'auto' }}>
          <div className="authorization-name__items--component">
            <div className="authorization-name__items--title">Nome</div>
            <div className="authorization-name__items--text">Autorização</div>
          </div>
          <div className="authorization-name__items--component">
            <div className="authorization-name__items--title">Tipo do Resíduo</div>
            <div className="authorization-name__items--text">Nome do resíduo</div>
          </div>
          <div className="authorization-name__items--component">
            <div className="authorization-name__items--title">Peso</div>
            <div className="authorization-name__items--text">3.000 Kg</div>
          </div>
          <div className="authorization-name__items--component">
            <div className="authorization-name__items--title">Data de validade da licença</div>
            <div className="authorization-name__items--text">00/00/0000</div>
          </div>
          <div className="authorization-name__items--subtitle">{translate('mtr-transporter.license.subtitle-second')}</div>
          <div className="authorization-name__items--component-second">
            <div className="authorization-name__items--title">Autorização ambiental</div>
            <div style={{display:'flex'}}>
              <div className="authorization-name__items--img" />
              <div className="authorization-name__items--text">Nome-do-arquivo.pdf</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MtrAuthorizationName;

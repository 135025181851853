import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IAuthorization } from 'app/shared/model/authorization.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import StringUtils from 'app/shared/util/string-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';

export const ACTION_TYPES = {
  FETCH_AUTHORIZATION_LIST: 'authorization/FETCH_AUTHORIZATION_LIST',
  FETCH_AUTHORIZATION: 'authorization/FETCH_AUTHORIZATION',
  CREATE_AUTHORIZATION: 'authorization/CREATE_AUTHORIZATION',
  UPDATE_AUTHORIZATION: 'authorization/UPDATE_AUTHORIZATION',
  DELETE_AUTHORIZATION: 'authorization/DELETE_AUTHORIZATION',
  RESET: 'authorization/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  authorizations: [] as Array<IAuthorization>,
  authorization: defaultValue,
  fetchAuthorizationListStatus: HttpRequestStatus.NOOP,
  fetchAuthorizationStatus: HttpRequestStatus.NOOP,
  createAuthorizationStatus: HttpRequestStatus.NOOP,
  updateAuthorizationStatus: HttpRequestStatus.NOOP,
  deleteAuthorizationStatus: HttpRequestStatus.NOOP,
  totalItems: 0,
};

export type GenericAuthorizationState = Readonly<typeof initialState>;

// Reducer

export default (state: GenericAuthorizationState = initialState, action): GenericAuthorizationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_AUTHORIZATION_LIST):
      return {
        ...state,
        errorMessage: null,
        fetchAuthorizationListStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_AUTHORIZATION_LIST):
      return {
        ...state,
        fetchAuthorizationListStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_AUTHORIZATION_LIST):
      return {
        ...state,
        fetchAuthorizationListStatus: HttpRequestStatus.SUCCESS,
        authorizations: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.FETCH_AUTHORIZATION):
      return {
        ...state,
        errorMessage: null,
        fetchAuthorizationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_AUTHORIZATION):
      return {
        ...state,
        fetchAuthorizationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_AUTHORIZATION):
      return {
        ...state,
        fetchAuthorizationStatus: HttpRequestStatus.SUCCESS,
        authorization: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.CREATE_AUTHORIZATION):
      return {
        ...state,
        errorMessage: null,
        createAuthorizationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_AUTHORIZATION):
      return {
        ...state,
        createAuthorizationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_AUTHORIZATION):
      return {
        ...state,
        createAuthorizationStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_AUTHORIZATION):
      return {
        ...state,
        errorMessage: null,
        updateAuthorizationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_AUTHORIZATION):
      return {
        ...state,
        updateAuthorizationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_AUTHORIZATION):
      return {
        ...state,
        updateAuthorizationStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.DELETE_AUTHORIZATION):
      return {
        ...state,
        errorMessage: null,
        deleteAuthorizationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.DELETE_AUTHORIZATION):
      return {
        ...state,
        deleteAuthorizationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DELETE_AUTHORIZATION):
      return {
        ...state,
        deleteAuthorizationStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/authorization';

// Actions

export const getAuthorizations: ICrudSearchActionCustom<IAuthorization> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}${params}`;
  return {
    type: ACTION_TYPES.FETCH_AUTHORIZATION_LIST,
    payload: axios.get<IAuthorization>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IAuthorization> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_AUTHORIZATION,
    payload: axios.get<IAuthorization>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IAuthorization> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_AUTHORIZATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getAuthorizations());
  return result;
};

export const updateEntity: ICrudPutAction<IAuthorization> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_AUTHORIZATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getAuthorizations());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAuthorization> = id => async dispatch => {
  const requestUrl = `api/authorizations/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_AUTHORIZATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getAuthorizations());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

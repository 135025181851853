import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './vehicle.reducer';

export interface IVehicleDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class VehicleDetail extends React.Component<IVehicleDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { vehicleEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.vehicle.detail.title">Vehicle</Translate> [<b>{vehicleEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="plate">
                <Translate contentKey="resitrackApp.vehicle.plate">Plate</Translate>
              </span>
            </dt>
            <dd>{vehicleEntity.plate}</dd>
            <dt>
              <span id="year">
                <Translate contentKey="resitrackApp.vehicle.year">Year</Translate>
              </span>
            </dt>
            <dd>{vehicleEntity.year}</dd>
            <dt>
              <span id="model">
                <Translate contentKey="resitrackApp.vehicle.model">Model</Translate>
              </span>
            </dt>
            <dd>{vehicleEntity.model}</dd>
            <dt>
              <span id="capacity">
                <Translate contentKey="resitrackApp.vehicle.capacity">Capacity</Translate>
              </span>
            </dt>
            <dd>{vehicleEntity.capacity}</dd>
            <dt>
              <span id="measurementType">
                <Translate contentKey="resitrackApp.vehicle.measurementType">Measurement Type</Translate>
              </span>
            </dt>
            <dd>{vehicleEntity.measurementType}</dd>
            <dt>
              <span id="vehicleType">
                <Translate contentKey="resitrackApp.vehicle.vehicleType">Vehicle Type</Translate>
              </span>
            </dt>
            <dd>{vehicleEntity.vehicleType}</dd>
            <dt>
              <Translate contentKey="resitrackApp.vehicle.transporter">Transporter</Translate>
            </dt>
            <dd>{vehicleEntity.transporter ? vehicleEntity.transporter.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/vehicle" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/vehicle/${vehicleEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ vehicle }: IRootState) => ({
  vehicleEntity: vehicle.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetail);

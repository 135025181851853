import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './producer-units.scss';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import { translate, Translate } from 'react-jhipster';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { getIntegrationUnits, reset } from './producer-units-reducer';
import Loading from 'app/components/loading/loading';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { maskCnpj, maskPhone } from 'app/shared/util/mask-utils';
import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { Pageable } from 'app/shared/model/pageable';
import {
  deleteEntity,
  getProducerDetailByUuid,
  reset as resetProducer
} from 'app/entities/user-producer/user-producer.reducer';
import { GeneratorCompanyType } from 'app/shared/model/producer.model';
import StringUtils from 'app/shared/util/string-utils';
import HoldingDetailComponent from './holding-detail-component/holding-detail-component';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface IProducerUnitsProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IProducerUnitsState {
  filterName: string;
  page: Pageable;
}

export class ProducerUnits extends React.Component<IProducerUnitsProps, IProducerUnitsState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('producer.units.header.cnpj'), sortCode: 'producer.cnpj' },
    { name: translate('producer.units.header.companyName'), sortCode: 'producer.companyName' },
    { name: translate('producer.units.header.city'), sortCode: 'producer.address.city.name' },
    { name: translate('producer.units.header.responsible'), sortCode: 'user.name' },
    { name: translate('producer.units.header.phone'), sortCode: 'producer.phone' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IProducerUnitsProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.props.resetProducer();
    await this.props.getProducerDetailByUuid();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.userUuidDetail != this.props.userUuidDetail &&
      newProps.userUuidDetail.producer != null &&
      newProps.userUuidDetail.producer.generatorCompanyType === GeneratorCompanyType.HOLDING
    ) {
      this.getSubsidiariesByHolding();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
    if (newProps.deleteEntityStatus != this.props.deleteEntityStatus && newProps.deleteEntityStatus === HttpRequestStatus.SUCCESS) {
      this.getSubsidiariesByHolding();
    }
  }

  getSubsidiariesByHolding = () => {
    const { page } = this.state;
    this.props.getSubsidiariesByHolding(
      page.page,
      page.size,
      page.sort,
      StringUtils.toObject('producer.companyName', this.state.filterName)
    );
  };

  private handleTransformToTableContent = (content?: IUserProducer[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IUserProducer) => {
      result.push([
        item.producer.cnpj != null ? maskCnpj(item.producer.cnpj) ?? '' : '',
        item.producer.companyName ?? '',
        item.producer.address != null ? item.producer.address.city.name : '',
        item.user.name,
        item.user.phone ? maskPhone(item.user.phone) : '',
        <DeleteEditButton
          id={item.id}
          hideEdit={!this.props.isProducerAdmin}
          hideDelete={!this.props.isProducerAdmin}
          onDelete={this.onDeleteSubsidiary}
          onEdit={this.onEditSubsidiary}
        />,
      ]);
    });

    return result;
  };

  private handleTransformSubsidiaryToTableContent = (content?: IUserProducer[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IUserProducer) => {
      result.push([
        item.producer.cnpj != null ? maskCnpj(item.producer.cnpj) ?? '' : '',
        item.producer.companyName ?? '',
        item.producer.address != null ? item.producer.address.city.name : '',
        item.user.name,
        item.user.phone ? maskPhone(item.user.phone) : '',
        <DeleteEditButton
          id={item.id}
          hideDelete
          hideEdit={!this.props.isProducerAdmin}
          onDelete={this.onDeleteSubsidiary}
          onEdit={this.onEditSubsidiary}
        />,
      ]);
    });

    return result;
  };

  onDeleteSubsidiary = (id: number) => {
    this.props.deleteEntity(id);
  };

  onCreateSubsidiary = () => {
    this.props.history.push(`${this.props.match.url}/subsidiary/create/new`);
  };

  onEditSubsidiary = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/subsidiary/update`,
      state: { id: id },
    });
  };

  onEditHolding = (id: number) => {
    // this.props.history.push(`${this.props.match.url}/holding`);
    this.props.history.push({
      pathname: `${this.props.match.url}/holding/update`,
      state: { id: id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getSubsidiariesByHolding()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getSubsidiariesByHolding()
    );
  };

  onHandleFilterChanged = value => {
    this.setState({ filterName: value }, () => this.getSubsidiariesByHolding());
  };

  onSubsidiaryClick = (index: number) => {
    const { userUuidDetail } = this.props;

    if (userUuidDetail.producer.generatorCompanyType === GeneratorCompanyType.HOLDING) {
      const { subsidiaries } = this.props;
      const subsidiaryClicked = subsidiaries[index];
      this.props.history.push({
        pathname: `${this.props.match.url}/details`,
        state: { id: subsidiaryClicked.id },
      });
    } else {
      this.props.history.push({
        pathname: `${this.props.match.url}/details`,
        state: { id: userUuidDetail.id },
      });
    }
  };

  render() {
    const { getHoldingSubidiariesStatus, userUuidDetailStatus, deleteEntityStatus, userUuidDetail, isProducerAdmin } = this.props;
    const rows: (string | JSX.Element)[][] =
      userUuidDetail.producer && userUuidDetail.producer.generatorCompanyType === GeneratorCompanyType.HOLDING
        ? this.handleTransformToTableContent(this.props.subsidiaries)
        : this.handleTransformSubsidiaryToTableContent(userUuidDetail.producer ? [userUuidDetail] : null);
    if (checkStatusOnGoing([userUuidDetailStatus, deleteEntityStatus])) {
      return <Loading />;
    }
    return (
      <div className="mtr-unity__items">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('mtr-unity.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px' }}>
            <Col md="6">
              <SimpleSearchField placeholder="Buscar Unidades" onChange={this.onHandleFilterChanged} />
            </Col>
            <Col md={{ size: 2, offset: 4 }}>
              {userUuidDetail.producer && userUuidDetail.producer.generatorCompanyType === GeneratorCompanyType.HOLDING && isProducerAdmin && (
                <Button className={'button primary'} type={'submit'} onClick={this.onCreateSubsidiary}>
                  <Translate contentKey={'mtr-unity.button'} />
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <HoldingDetailComponent
                isAdmin={isProducerAdmin}
                onEditHolding={this.onEditHolding}
                userProducer={this.props.userUuidDetail}
              />
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onSubsidiaryClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  getHoldingSubidiariesStatus: root.producerUnits.getHoldingSubidiariesStatus,
  subsidiaries: root.producerUnits.units,
  totalItems: root.producerUnits.totalItems,
  userUuidDetail: root.userProducer.producerUuidDetail,
  userUuidDetailStatus: root.userProducer.producerDetailUuidStatus,
  deleteEntityStatus: root.userProducer.deleteEntityStatus,
  isProducerAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN]),
});

const mapDispatchToProps = {
  getProducerDetailByUuid,
  getSubsidiariesByHolding: getIntegrationUnits,
  resetProducer,
  deleteEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerUnits);

/**
 * @reason Required by Yup
 */
/* eslint-disable no-template-curly-in-string */
/* tslint:disable:no-invalid-template-strings no-invalid-this */

import moment from 'moment';
import { addMethod, setLocale, string } from 'yup';

/* TODO:: Adicionar translations para esse arquivo inteiro */

// https://github.com/jquense/yup/blob/85e93e10c48111d592dcaf681c076c1d45cd5fdc/src/locale.js
setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório',
    notType: ({ type /* , path, value, originalValue */ }) => {
      if (type === 'number') {
        return 'Deve ser um número';
      }

      return 'Valor inválido';
    },
  },
  string: {
    max: 'Deve ter menos que ${max} caracteres',
    min: 'Deve ter mais que ${min} caracteres',
    email: 'Deve ser um e-mail válido',
    url: 'Deve ser um link válido. Ex: http://link.com',
    // phone: 'Deve ser um telefone válido',
  },
  number: {
    max: 'Deve ser menor ou igual a ${max}',
    min: 'Deve ser maior ou igual a ${min}',
  },
  array: {
    // required: 'Deve haver pelo menos um valor',
  },
});
/* eslint-enable */

/* DEFINE NEW METHODS */
// 00:00:00
addMethod(string, 'hasTimeFormat', function hasTimeFormat() {
  return this.matches(/^\d{2}:\d{2}:\d{2}$/, 'Informe um horário válido');
});
// DD/MM/YYYY
addMethod(string, 'hasDateFormat', function hasDateFormat(format) {
  return this.test('isDate', 'Informe uma data válida', value =>
    value ? value.match(/^\d{2}\/\d{2}\/\d{4}/) != null && moment(value, format).isValid() : false
  );
});

import AlertModal from 'app/components/alert-modal/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS } from 'app/config/constants';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IRootState } from 'app/shared/reducers';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
    createStorageLicense,
    getStorageLicenseDetail,
    reset,
    updateStorageLicense
} from 'app/entities/licenses/licenses-reducer';
import { Col, Row } from 'reactstrap';
import { StorageLicense } from 'app/shared/model/storage-license.model';
import StorageLicenseForm from 'app/components/storage-license-form/form/storage-license-form';

export interface IStorageLicenseEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IStorageLicenseEditState {
  storageLicense: StorageLicense;
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
  isNew: boolean;
}

export class StorageLicenseEdit extends React.Component<IStorageLicenseEditProps, IStorageLicenseEditState> {
  constructor(props: Readonly<IStorageLicenseEditProps>) {
    super(props);
    this.state = {
      storageLicense: null,
      showErrorModal: false,
      showResponsabilityModal: false,
      isNew: null,
    };
  }

  async componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getStorageLicenseDetail(id);
    } else {
      this.setState({
        isNew: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.storageLicenseDetail != null &&
      newProps.getStorageLicenseDetailStatus != this.props.getStorageLicenseDetailStatus &&
      newProps.getStorageLicenseDetailStatus === HttpRequestStatus.SUCCESS
    ) {
      this.setState({
        storageLicense: newProps.storageLicenseDetail,
      });
    }

    if (newProps.createStorageLicenseStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    }

    if (newProps.updateStorageLicenseStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessUpdateAlertPage();
      this.props.reset();
    }

    if (newProps.createStorageLicenseStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }

    if (newProps.updateStorageLicenseStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  toggleResponsabilityModal = () => {
    this.setState({
      showResponsabilityModal: !this.state.showResponsabilityModal,
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onSaveHandler = () => {
    const { isNew, storageLicense } = this.state;
    if (isNew) {
      this.props.createStorageLicense(storageLicense);
    } else {
      this.props.updateStorageLicense(storageLicense);
    }
  };

  onBackHandler = () => {
    this.props.history.replace('/transshipment/licenses');
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.licenseRegister',
        buttonUrl: '/transshipment/licenses',
      },
    });
  };

  goToSuccessUpdateAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.licenseUpdate',
        buttonUrl: '/transshipment/licenses',
      },
    });
  };

  onStartSaving = (storageLicense: StorageLicense) => {
    this.setState(
      {
        storageLicense,
      },
      () => this.toggleResponsabilityModal()
    );
  };

  renderForm = () => {
    const { storageLicense, isNew } = this.state;

    return (
      <Row>
        <Col style={{ margin: 'auto' }} md="8">
          <StorageLicenseForm
            storageLicense={storageLicense ? storageLicense : {}}
            defaultForm
            onCreate={this.onStartSaving}
            onClose={this.onBackHandler}
            title={isNew ? translate('licenses.formTitleRegister') : translate('licenses.formTitleUpdate')}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const {
      getStorageLicenseDetailStatus,
      createStorageLicenseStatus,
      updateStorageLicenseStatus,
      storageLicenseDetail,
    } = this.props;
    const { isNew } = this.state;
    if (checkStatusOnGoing([getStorageLicenseDetailStatus, createStorageLicenseStatus, updateStorageLicenseStatus])) {
      return <Loading />;
    }
    return (
      <div>
        <div style={{ paddingTop: '70px' }}>
          <BackHeaderComponent hasButton={false} onBack={this.onBackHandler} />
          {isNew && this.renderForm()}
          {!isNew && storageLicenseDetail.id && this.renderForm()}

          <AlertModal
            showModal={this.state.showResponsabilityModal}
            buttonOneActionMessage={'entity.action.cancel'}
            buttonTwoActionMessage={'entity.action.agree'}
            hasTwoButtons
            buttonTwoAction={this.onSaveHandler}
            buttonOneAction={this.toggleResponsabilityModal}
            statusImage="paper-question-mark.svg"
            alertMessage="alert.responsibilityTerms"
          />

          <AlertModal
            showModal={this.state.showErrorModal}
            buttonTwoActionMessage={'entity.action.ok'}
            buttonTwoAction={this.toggleErrorModal}
            statusImage="bag-error.svg"
            alertMessage={this.state.isNew ? 'errorMessages.licenseRegister' : 'errorMessages.licenseUpdate'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  storageLicenseDetail: root.licenses.storageLicenseDetail,
  getStorageLicenseDetailStatus: root.licenses.getStorageLicenseDetailStatus,
  createStorageLicenseStatus: root.licenses.createStorageLicenseStatus,
  updateStorageLicenseStatus: root.licenses.updateStorageLicenseStatus,
  // user: root.userTransshipment
});

const mapDispatchToProps = {
  getStorageLicenseDetail,
  createStorageLicense,
  updateStorageLicense,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StorageLicenseEdit);

import { IAddress } from 'app/shared/model/address.model';
import { IAuthorization } from 'app/shared/model/authorization.model';
import { ICnaeCode } from './cnae-code.model';
import { IDocument } from './document.model';
import { IEnvironmentalLicense } from './environmental-license.model';
import { LegalPerson, NaturalPerson } from './producer.model';

export enum FinalDestinationCategory {
  ANIMAL_FEEDING = 'ANIMAL_FEEDING',
  CONSTRUCTION_LANDFILL = 'CONSTRUCTION_LANDFILL',
  INDUSTRIAL_OWN_LANDFILL = 'INDUSTRIAL_OWN_LANDFILL',
  INDUSTRIAL_THIRD_LANDFILL = 'INDUSTRIAL_THIRD_LANDFILL',
  MUNICIPAL_LANDFILL = 'MUNICIPAL_LANDFILL',
  LANDFILL = 'LANDFILL',
  COMPOSTING = 'COMPOSTING',
  CEMENT_OVEN_COPROCESSING = 'CEMENT_OVEN_COPROCESSING',
  HYDRIC_BODY = 'HYDRIC_BODY',
  FERTIGATION = 'FERTIGATION',
  HYGIENIZATION = 'HYGIENIZATION',
  HYGIENIZATION_REFACTORING = 'HYGIENIZATION_REFACTORING',
  INCINERATION = 'INCINERATION',
  AGRICULTURAL_SOIL = 'AGRICULTURAL_SOIL',
  SOIL_INFILTRATION = 'SOIL_INFILTRATION',
  DRYING_BED = 'DRYING_BED',
  SELECTIVE_POST = 'SELECTIVE_POST',
  CEMENT_PRODUCTION = 'CEMENT_PRODUCTION',
  ANIMAL_FOOD = 'ANIMAL_FOOD',
  EXTERNAL_RECYCLING = 'EXTERNAL_RECYCLING',
  INTERNAL_RECYCLING = 'INTERNAL_RECYCLING',
  OIL_REPROCESSING = 'OIL_REPROCESSING',
  SOLVENT_REPROCESSING = 'SOLVENT_REPROCESSING',
  OIL_REFINE = 'OIL_REFINE',
  MANUFACTURER_RETURN = 'MANUFACTURER_RETURN',
  REUTILIZATION_RECYCLING = 'REUTILIZATION_RECYCLING',
  REUTILIZATION_EXTERNAL = 'REUTILIZATION_EXTERNAL',
  REUTILIZATION_INTERNAL = 'REUTILIZATION_INTERNAL',
  INTERMEDIARY_SCRAP_DEALERS = 'INTERMEDIARY_SCRAP_DEALERS',
  BOILERS_UTILIZATION = 'BOILERS_UTILIZATION',
  MICRONUTRIENTS_FORMULATION = 'MICRONUTRIENTS_FORMULATION',
  INDUSTRIAL_OVEN_UTILIZATION = 'INDUSTRIAL_OVEN_UTILIZATION',
  EMPTY_CATEGORY = 'EMPTY_CATEGORY',
}

interface finalDestinationUnit {
  id: string;
}

export interface IFinalDestination {
  id?: number;
  cnpj?: string;
  cpf?: string;
  name?: string;
  fantasyName?: string;
  companyName?: string;
  phone?: string;
  finalDestinationCategory?: FinalDestinationCategory;
  address?: IAddress;
  addressLabel?: string;
  addressZipcode?: string;
  authorizations?: IAuthorization[];
  environmentalLicenses?: IEnvironmentalLicense[];
  holdingCnpj?: string;
  holdingCompany?: IFinalDestination;
  isSubsidiary?: boolean;
  responsible?: string;
  cnaeCodes?: ICnaeCode[];
  businessLicense?: IDocument;
  activityType?: string;
  finalDestinationUnit?: finalDestinationUnit;
  municipalRegistration?: string;
  legalPerson?: LegalPerson;
  naturalPerson?: NaturalPerson;
  personType?: string;
  parUf?: string;
}

export interface IFinalDestinationMtr {
  id?: number;
  legalPerson?: {
    cnpj?: string;
    companyName?: string;
    fantasyName?: string;
  };
  naturalPerson?: {
    cpf?: string;
    id?: number;
    name?: string;
  };
  finalDestinationCategory?: FinalDestinationCategory;
  address?: IAddress;
  environmentalLicenses?: IEnvironmentalLicense[];
  addressLabel?: string;
  addressZipcode?: string;
  isSubsidiary?: boolean;
  responsible?: string;
  cnaeCodes?: ICnaeCode[];
  businessLicense?: IDocument;
  activityType?: string;
  municipalRegistration?: string;
}

export const enum FinalDestinationCompanyType {
  HOLDING = 'HOLDING',
  SUBSIDIARY = 'SUBSIDIARY',
}

export function mapFinalDestinationToSearch(props) {
  const { finalDestinations } = props;

  const objects = finalDestinations.map(finalDestination => {
    return { name: finalDestination?.naturalPerson?.name ?? finalDestination?.legalPerson?.companyName, id: finalDestination?.id };
  });
  return objects.sort((a, b) => a.name?.localeCompare(b?.name));
}

export const defaultValue: Readonly<IFinalDestination> = {};

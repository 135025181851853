import React, { useState } from 'react';
import { Carousel as BootstrapCarousel, CarouselCaption, CarouselIndicators, CarouselItem } from 'reactstrap';
import { Translate } from 'react-jhipster';
import './ImageCarousel.scss';

export const ImageCarousel = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);

  const items = [
    {
      src: 'content/images/1.jpg',
      altText: 'Slide 1',
      captionHeader: <Translate contentKey={'login.carousel.title'} />,
      captionText: <Translate contentKey={'login.carousel.title-second'} />,
    },
    {
      src: 'content/images/2.jpg',
      altText: 'Slide 2',
      captionHeader: <Translate contentKey={'login.carousel-2.title'} />,
      captionText: <Translate contentKey={'login.carousel-2.title-second'} />,
    },
    {
      src: 'content/images/3.jpg',
      altText: 'Slide 4',
      captionHeader: <Translate contentKey={'login.carousel-3.title'} />,
      captionText: <Translate contentKey={'login.carousel-3.title-second'} />,
    },
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
        <div className="carousel--item" style={{ backgroundImage: `url(${item.src})` }} />
        <CarouselCaption captionText={item.captionText ?? ''} captionHeader={item.captionHeader ?? ''} />
      </CarouselItem>
    );
  });

  return (
    <div className="carousel--color">
      <BootstrapCarousel activeIndex={activeIndex} next={next} previous={previous} interval={5000}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
      </BootstrapCarousel>
    </div>
  );
};

export default ImageCarousel;

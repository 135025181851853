import './header.scss';

import React, { useState } from 'react';
import { Collapse, Container, Nav, Navbar, NavbarToggler } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Brand } from './header-components';
import { AccountMenu, AdminMenu } from '../menus/common';
import ProducerMenu from '../menus/producer/producer-menu';
import TransporterMenu from '../menus/transporter/transporter-menu';
import { translate } from 'react-jhipster';
import { IUser } from 'app/shared/model/user.model';
import FinalDestinationMenu from '../menus/finaldestination/final-destination-menu';
import ManagerMenu from '../menus/manager/manager-menu';
import { RouteProps } from 'react-router-dom';
import TransshipmentMenu from 'app/shared/layout/menus/transshipment/transshipment-menu';
import { IUserProducer } from 'app/shared/model/user-producer.model';

export interface IHeaderProps extends RouteProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isUser: boolean;
  isTransporterAdmin: boolean;
  isTransporterUser: boolean;
  isTransshipmentAdmin: boolean;
  isTransshipmentUser: boolean;
  isManagerAdmin: boolean;
  isManagerUser: boolean;
  isProducerAdmin: boolean;
  isProducerUser: boolean;
  isFinalDestinationAdmin: boolean;
  isFinalDestinationUser: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  account: IUser;
  userProducer: IUserProducer;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const currentUrl = props.location.pathname;

  // checks if it is admin profile, but does not include isTransporterAdmin
  const isProfileAdmin = [props.isFinalDestinationAdmin, props.isManagerAdmin, props.isProducerAdmin, props.isTransshipmentAdmin].includes(true)

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  const returnTranslateType = () => {
    if (props.isAdmin) {
      return translate('entity.admin');
    }
    if (props.isProducerAdmin || props.isProducerUser) {
      return translate('entity.producer');
    }
    if (props.isTransporterAdmin || props.isTransporterUser) {
      return translate('entity.transporter');
    }
    if (props.isManagerAdmin || props.isManagerUser) {
      return translate('entity.manager');
    }
    if (props.isFinalDestinationAdmin || props.isFinalDestinationUser) {
      return translate('entity.finalDestination');
    }
    if (props.isTransshipmentAdmin || props.isTransshipmentUser) {
      return translate('entity.transshipment');
    }
  };

  return (
    <div id="app-header">
      <LoadingBar className="loading-bar" />
      <Navbar dark expand="sm" fixed="top" className="jh-navbar">
        <Container>
          <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
          <Brand />
          {props.isAuthenticated && (
            <Collapse isOpen={menuOpen} navbar>
              <Nav id="header-tabs" className="ml-auto" navbar>
                {props.isAuthenticated && props.isAdmin && <AdminMenu showSwagger={props.isSwaggerEnabled} />}
                {(props.isProducerAdmin || props.isProducerUser) && (
                  <ProducerMenu isAdmin={props.isProducerAdmin} isUser={props.isUser} currentUrl={currentUrl} userProducer={props.userProducer} />
                )}
                {(props.isTransporterAdmin || props.isTransporterUser) && (
                  <TransporterMenu isAdmin={props.isTransporterAdmin} isUser={props.isTransporterUser} currentUrl={currentUrl} />
                )}
                {(props.isFinalDestinationAdmin || props.isFinalDestinationUser) && (
                  <FinalDestinationMenu isAdmin={props.isFinalDestinationAdmin} isUser={props.isFinalDestinationUser} currentUrl={currentUrl} />
                )}
                {(props.isTransshipmentAdmin || props.isTransshipmentUser) && (
                  <TransshipmentMenu isAdmin={props.isTransshipmentAdmin} isUser={props.isTransshipmentUser} currentUrl={currentUrl} />
                )}
                {(props.isManagerAdmin || props.isManagerUser) && (
                  <ManagerMenu isAdmin={props.isManagerAdmin} isUser={props.isManagerUser} currentUrl={currentUrl} />
                )}
                <AccountMenu
                  isAdmin={
                    props.isTransporterAdmin || props.isFinalDestinationAdmin ||
                    props.isManagerAdmin || props.isProducerAdmin || props.isTransshipmentAdmin}
                  isManager={props.isManagerAdmin || props.isManagerUser}
                  IsTransporter={props.isTransporterAdmin || props.isTransporterUser}
                  isAuthenticated={props.isAuthenticated}
                  profileType={returnTranslateType()}
                  userName={props.account.name}
                  companyName={props.account.companyName}
                  isProfileAdmin={isProfileAdmin}
                />
              </Nav>
            </Collapse>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;

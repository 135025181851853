import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './producer-register-documentation.scss';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { IEnvironmentalLicense } from 'app/shared/model/environmental-license.model';
import { EnvironmentalLicenseList } from 'app/components/environmental-license-form/list/environmental-license-list';

export interface IProducerRegisterDocumentationProps extends StateProps, DispatchProps {
  userProducer: IUserProducer;
  onNext: (userProducer: IUserProducer) => void;
  onBack: () => void;
}

export interface IProducerRegisterDocumentationState {
  enviromentalLicenses: IEnvironmentalLicense[];
}

export class ProducerRegisterDocumentation extends React.Component<
  IProducerRegisterDocumentationProps,
  IProducerRegisterDocumentationState
> {
  constructor(props) {
    super(props);
    this.state = {
      enviromentalLicenses: this.props.userProducer.producer ? this.props.userProducer.producer.environmentalLicenses : [],
    };
  }

  onNextHandler = (environmentalLicensesUpdated: IEnvironmentalLicense[]) => {
    const userProducerUpdated = { ...this.props.userProducer };
    userProducerUpdated.producer.environmentalLicenses = environmentalLicensesUpdated;
    this.props.onNext(userProducerUpdated);
  };

  render() {
    const { enviromentalLicenses } = this.state;

    console.log('enviromentalLicenses', this.props.userProducer)
    return (
      <div>
        <EnvironmentalLicenseList
          onNextHandler={this.onNextHandler}
          onBack={this.props.onBack}
          environmentalLicenses={enviromentalLicenses}
          userProducer={this.props.userProducer}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerRegisterDocumentation);

import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, IUserManager } from 'app/shared/model/user-manager.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudPutAction } from 'react-jhipster';
import { IInvitationVM } from 'app/shared/model/invitation-vm.model';

export const ACTION_TYPES = {
  CREATE: 'managerRegister/CREATE_LEGAL',
  INVITE: 'managerRegister/INVITE',
  CHECK_PRODUCER_CNPJ: 'managerRegister/CHECK_PRODUCER_CNPJ',
  CHECK_PRODUCER_CPF: 'managerRegister/CHECK_PRODUCER_CPF',
  RESET: 'managerRegister/RESET',
};

const initialState = {
  errorMessage: null,
  entity: defaultValue,
  createManagerStatus: HttpRequestStatus.NOOP,
  checkProducerCnpjStatus: HttpRequestStatus.NOOP,
  checkInviteStatus: HttpRequestStatus.NOOP,
  checkProducerCpfStatus: HttpRequestStatus.NOOP,
};

export type ManagerRegisterState = Readonly<typeof initialState>;

// Reducer

export default (state: ManagerRegisterState = initialState, action): ManagerRegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE):
      return {
        ...state,
        errorMessage: null,
        createManagerStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE):
      return {
        ...state,
        errorMessage: action.payload,
        createManagerStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.CREATE):
      return {
        ...state,
        entity: action.payload.data,
        createManagerStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.CHECK_PRODUCER_CNPJ):
      return {
        ...state,
        errorMessage: null,
        checkProducerCnpjStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CHECK_PRODUCER_CNPJ):
      return {
        ...state,
        errorMessage: action.payload,
        checkProducerCnpjStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.CHECK_PRODUCER_CNPJ):
      return {
        ...state,
        checkProducerCnpjStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.INVITE):
      return {
        ...state,
        errorMessage: null,
        checkInviteStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.INVITE):
      return {
        ...state,
        errorMessage: action.payload,
        checkInviteStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.INVITE):
      return {
        ...state,
        checkInviteStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.CHECK_PRODUCER_CPF):
      return {
        ...state,
        errorMessage: null,
        checkProducerCpfStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CHECK_PRODUCER_CPF):
      return {
        ...state,
        errorMessage: action.payload,
        checkProducerCpfStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.CHECK_PRODUCER_CPF):
      return {
        ...state,
        checkProducerCpfStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-managers';

// Actions

export const create: ICrudPutAction<IUserManager> = entity => async dispatch => {
  const url = `${apiUrl}/new`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const findProducerByCnpj: ICrudPutAction<string> = (cnpj: string) => async dispatch => {
  const url = `api/producers/find/${cnpj}`;
  const result = await dispatch({
    type: ACTION_TYPES.CHECK_PRODUCER_CNPJ,
    payload: axios.get(url),
  });
  return result;
};

export const findProducerByCPF: ICrudPutAction<string> = (cpf: string) => async dispatch => {
  const url = `api/producers/find/cpf/${cpf}`;
  const result = await dispatch({
    type: ACTION_TYPES.CHECK_PRODUCER_CPF,
    payload: axios.get(url),
  });
  return result;
};

export const sendInvitation: ICrudPutAction<IInvitationVM> = entity => async dispatch => {
  const url = `${apiUrl}/invite`;
  const result = await dispatch({
    type: ACTION_TYPES.INVITE,
    payload: axios.post(url, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import React from 'react';
import CustomMenuItem from 'app/shared/layout/menus/common/custom-menu-item/custom-menu-item';
import { Translate } from 'react-jhipster';
import { MenuDropDown } from '../producer/producer-menu-dropdonw';
import { ProfileType } from 'app/shared/model/enum/Profile-dropdonw';

export interface ITransshipmentMenuProps {
    isAdmin: boolean;
    isUser: boolean;
    currentUrl: string;
}

export interface ITransshipmentMenuState {
    subCategorySelected: boolean;
}

export class TransshipmentMenu extends React.Component<ITransshipmentMenuProps, ITransshipmentMenuState> {
    constructor(props) {
        super(props);
        this.state = {
            subCategorySelected: false,
        };
    }

    checkSubcategorySelected = (path: string) => {
        const { currentUrl } = this.props;
        return currentUrl.indexOf(path) != -1;
    };

    render() {
        const { isAdmin, isUser, currentUrl } = this.props;
        // const active = this.checkSubcategorySelected('/contractor/rides');
        return (
            <div style={{ display: 'flex' }}>
                <CustomMenuItem id={'mtr'} to="/transshipment/mtr" active={this.checkSubcategorySelected("/transshipment/mtr")}>
                    <Translate contentKey={'header.common.mtr'}>MTR</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'units'} to="/mtr-units" active={this.checkSubcategorySelected("/mtr-units")}>
                    <Translate contentKey={'header.producer.units'}>Units</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'residues'} to="/transshipment/residues" active={this.checkSubcategorySelected("/transshipment/residues")}>
                    <Translate contentKey={'header.common.residues'}>Residues</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'licenses'} to="/transshipment/licenses" active={this.checkSubcategorySelected("/transshipment/licenses")}>
                    <Translate contentKey={'header.common.licenses'}>Licenses</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'invite'} to="/transshipment/summary" active={this.checkSubcategorySelected("/transshipment/summary")}>
                    <Translate contentKey={'header.common.invite'}>Invite</Translate>
                </CustomMenuItem>
            </div>
        );
    }
}

export default TransshipmentMenu;

import './table-list.scss';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox, Paper, Table, TableBody, TableContainer } from '@material-ui/core';

interface Data {
  data: number;
  code: number;
  generator: number;
  transporter: string;
  ending: number;
  status: number;
}

function createData(transporter: string, data: number, generator: number, code: number, ending: number): Data {
  return { transporter, data, generator, code, ending };
}

const rows = [
  createData('001', 0, 'Resíduos da prospecção e exploração de minas e pedreiras, bem como de tratamentos físicos e quimicos das matérias extraidas', 37, 4.3, 25),
  createData('002', 0, 'Resíduos da prospecção e exploração de minas e pedreiras, bem como de tratamentos físicos e quimicos das matérias extraidas', 51, 4.9, 5),
  createData('003', 0, 'Resíduos da prospecção e exploração de minas e pedreiras, bem como de tratamentos físicos e quimicos das matérias extraidas', 24, 6.0, 35)
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'data', numeric: true, disablePadding: false, label: 'CNPJ' },
  { id: 'code', numeric: true, disablePadding: false, label: 'NOME DA FILIAL' },
  { id: 'generator', numeric: true, disablePadding: false, label: 'BAIRRO/CIDADE' },
  { id: 'transporter', numeric: false, disablePadding: true, label: 'RESPONSÁVEL' },
  { id: 'ending', numeric: true, disablePadding: false, label: 'TELEFONE' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div className="invoice-table--head">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>
            </div>
          </TableCell>
        ))}
        <TableCell padding="checkbox">
          <div className="invoice-table--head">
            <div className="invoice-table--head-check">SELECIONAR</div>
            {/* <Checkbox
                            icon={<img src={CheckGray} alt="check" />}
                            indeterminateIcon={<img src={CheckGray} alt="check" />}
                            checkedIcon={<img src={CheckGreen} alt="check" />}
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all transporter' }}
                        /> */}
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

// const useToolbarStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             paddingLeft: theme.spacing(2),
//             paddingRight: theme.spacing(1)
//         },
//         highlight:
//             theme.palette.type === 'light'
//                 ? {
//                       color: theme.palette.secondary.main,
//                       backgroundColor: lighten(theme.palette.secondary.light, 0.85)
//                   }
//                 : {
//                       color: theme.palette.text.primary,
//                       backgroundColor: theme.palette.secondary.dark
//                   },
//         title: {
//             flex: '1 1 100%'
//         }
//     })
// );

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('data');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.transporter);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, transporter: string) => {
    const selectedIndex = selected.indexOf(transporter);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, transporter);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event: unknown, newPage: number) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setRowsPerPage(Number(event.target.code));
  //     setPage(0);
  // };

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setDense(event.target.checked);
  // };

  const isSelected = (transporter: string) => selected.indexOf(transporter) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className="invoice-table__items">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.transporter);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.transporter)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.transporter}
                      selected={isItemSelected}
                    >
                      <TableCell id={labelId} scope="row" padding="none">
                        <div className="invoice-table-large--rows  invoice-table--radius-first">{row.transporter}</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="invoice-table-large--rows ">{row.data}</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="invoice-table-large--rows">{row.generator}</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="invoice-table-large--rows  ">{row.code}</div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="invoice-table-large--rows">
                          <span>{row.ending}</span>
                        </div>
                      </TableCell>

                      <TableCell padding="checkbox" align="right">
                        <div className="invoice-table-large--rows invoice-table--radius-last">
                          <Checkbox
                            // icon={<img src={CheckGray} alt="" />}
                            // checkedIcon={<img src={CheckGreen} alt="" />}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}

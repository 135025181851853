// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../content/images/icons/container-back.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mtr-signature__steps {\n  padding-top: 60px;\n}\n.mtr-signature__steps--info {\n  font-size: 0.75rem;\n  line-height: 1.42;\n  margin-top: 5px;\n}\n.mtr-signature__steps .mtr-signature--buttons {\n  display: flex;\n  justify-content: center;\n  margin-top: 51px;\n}\n\n.mtr-signature__items .steps__header__step__line {\n  width: 198px !important;\n  left: 9px !important;\n}\n.mtr-signature__items .steps .steps__header {\n  top: 80px;\n}\n.mtr-signature__items .steps .steps__header .steps__header__step__title {\n  width: 176px;\n}\n.mtr-signature__items .container__confirmation--back {\n  height: 45px;\n  border: solid 1px #e3e3e3;\n  position: fixed;\n  background: white;\n  top: 70px;\n  left: 0;\n  right: 0;\n  z-index: 10;\n  overflow: hidden;\n}\n.mtr-signature__items .container__confirmation--back .confirmation--back {\n  display: flex;\n  align-items: center;\n  height: 45px;\n}\n.mtr-signature__items .container__confirmation--back .confirmation--back .confirmation--back-icon {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 10px;\n  cursor: pointer;\n}\n.mtr-signature__items .container__confirmation--back .confirmation--back span {\n  font-size: 0.875rem;\n  font-weight: 300;\n  font-family: Catamaran;\n  color: #30ab64;\n  cursor: pointer;\n}", ""]);
// Exports
module.exports = exports;

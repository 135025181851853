import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transshipment-register-company.scss';
import { translate } from 'react-jhipster';
import { UserTransshipment } from 'app/shared/model/user-transshipment.model';
import { Transshipment } from 'app/shared/model/transshipment.model';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { Col, Row } from 'reactstrap';
import { translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import { validateCNPJ, validateGenericField, validatePhoneNumber } from 'app/modules/validation/validation-constants';
import { maskCnpj, maskPhone, unmask } from 'app/shared/util/mask-utils';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';

export interface ITransshipmentRegisterCompanyProps extends StateProps, DispatchProps {
  userTransshipment: UserTransshipment;
  onNext: (userTransshipment: UserTransshipment) => void;
  onBack: () => void;
}

export interface ITransshipmentRegisterCompanyState {
  cnpj: string;
  fantasyName: string;
  companyName: string;
  phone: string;
  cnpjError: ValidationResult;
  fantasyNameError: ValidationResult;
  companyNameError: ValidationResult;
  phoneError: ValidationResult;
  transshipmentCategoryError: ValidationResult;
}

export class TransshipmentRegisterCompany extends React.Component<
    ITransshipmentRegisterCompanyProps,
    ITransshipmentRegisterCompanyState
    > {
  constructor(props) {
    super(props);
    const { transshipment } = this.props.userTransshipment;
    this.state = {
      cnpj: transshipment ? transshipment.cnpj : null,
      fantasyName: transshipment ? transshipment.fantasyName : null,
      companyName: transshipment ? transshipment.companyName : null,
      phone: transshipment ? transshipment.phone : null,
      cnpjError: ValidationResultInstance,
      companyNameError: ValidationResultInstance,
      fantasyNameError: ValidationResultInstance,
      transshipmentCategoryError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
    };
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateCnpj()) {
      hasError = true;
    }
    if (this.onValidateFantasyName()) {
      hasError = true;
    }
    if (this.onValidateCompanyName()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    return hasError;
  };

  onValidateCnpj = () => {
    const { cnpj } = this.state;
    const validate = validateCNPJ(cnpj);
    this.setState({
      cnpjError: validate,
    });
    return validate.hasError;
  };

  onValidateFantasyName = () => {
    const { fantasyName } = this.state;
    const validate = validateGenericField(fantasyName);
    this.setState({
      fantasyNameError: validate,
    });
    return validate.hasError;
  };

  onValidateCompanyName = () => {
    const { companyName } = this.state;
    const validate = validateGenericField(companyName);
    this.setState({
      companyNameError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  onBack = () => {
    this.props.onBack();
  };

  onNext = () => {
    if (this.onValidateAll()) {
      return;
    }
    const userTransshipment = { ...this.props.userTransshipment };
    const { cnpj, fantasyName, companyName, phone } = this.state;
    const transshipment: Transshipment = {
      ...userTransshipment.transshipment,
      cnpj: unmask(cnpj),
      fantasyName,
      companyName,
      phone: unmask(phone)
    };

    userTransshipment.transshipment = transshipment;
    this.props.onNext(userTransshipment);
  };

  renderForms = () => {
    const {
      cnpj,
      cnpjError,
      fantasyName,
      fantasyNameError,
      companyName,
      companyNameError,
      phone,
      phoneError,
    } = this.state;

    return (
        <div>
            <h1>{translate('finalDestinationRegister.company.register-title')}</h1>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <CustomTextField
                  onChange={(text: string) =>
                      this.setState({
                        cnpj: text,
                      })
                  }
                  id="cnpj"
                  style={{ marginTop: '20px' }}
                  value={cnpj}
                  onMask={maskCnpj}
                  label={translate('producerRegister.company.holding.form.cnpj')}
                  placeholder={translate('producerRegister.company.holding.form.cnpj')}
                  error={cnpjError.hasError}
                  errorText={translateErrorMessage(cnpjError.errorMessage)}
                  onBlur={this.onValidateCnpj}
              />
              <CustomTextField
                  onChange={(text: string) =>
                      this.setState({
                        fantasyName: text,
                      })
                  }
                  id="fantasyName"
                  style={{ marginTop: '20px' }}
                  value={fantasyName}
                  label={translate('producerRegister.company.holding.form.fantasyName')}
                  placeholder={translate('producerRegister.company.holding.form.fantasyName')}
                  error={fantasyNameError.hasError}
                  errorText={translateErrorMessage(fantasyNameError.errorMessage)}
                  onBlur={this.onValidateFantasyName}
              />
              <CustomTextField
                  onChange={(text: string) =>
                      this.setState({
                        companyName: text,
                      })
                  }
                  id="companyName"
                  style={{ marginTop: '20px' }}
                  value={companyName}
                  label={translate('producerRegister.company.subsidiary.form.companyName')}
                  placeholder={translate('producerRegister.company.subsidiary.form.companyName')}
                  error={companyNameError.hasError}
                  errorText={translateErrorMessage(companyNameError.errorMessage)}
                  onBlur={this.onValidateCompanyName}
              />
              <CustomTextField
                  onChange={(text: string) =>
                      this.setState({
                        phone: text,
                      })
                  }
                  onMask={maskPhone}
                  id="phone"
                  style={{ marginTop: '20px' }}
                  value={phone}
                  label={translate('producerRegister.company.holding.form.phone')}
                  placeholder={translate('producerRegister.company.holding.form.phone')}
                  error={phoneError.hasError}
                  errorText={translateErrorMessage(phoneError.errorMessage)}
                  onBlur={this.onValidatePhone}
              />
              <NextCancelButton onCancelClick={this.props.onBack} onNextClick={this.onNext} />
            </Col>
          </Row>
        </div>
    );
  };

  render() {
    return <div>{this.renderForms()}</div>;
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentRegisterCompany);

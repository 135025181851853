import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './address.reducer';


export interface IAddressDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AddressDetail extends React.Component<IAddressDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { addressEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.address.detail.title">Address</Translate> [<b>{addressEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="street">
                <Translate contentKey="resitrackApp.address.street">Street</Translate>
              </span>
            </dt>
            <dd>{addressEntity.street}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="resitrackApp.address.name">Name</Translate>
              </span>
            </dt>
            <dd>{addressEntity.name}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="resitrackApp.address.description">Description</Translate>
              </span>
            </dt>
            <dd>{addressEntity.description}</dd>
            <dt>
              <span id="number">
                <Translate contentKey="resitrackApp.address.number">Number</Translate>
              </span>
            </dt>
            <dd>{addressEntity.number}</dd>
            <dt>
              <span id="zipcode">
                <Translate contentKey="resitrackApp.address.zipcode">Zipcode</Translate>
              </span>
            </dt>
            <dd>{addressEntity.zipcode}</dd>
            <dt>
              <span id="district">
                <Translate contentKey="resitrackApp.address.district">District</Translate>
              </span>
            </dt>
            <dd>{addressEntity.district}</dd>
            <dt>
              <span id="complement">
                <Translate contentKey="resitrackApp.address.complement">Complement</Translate>
              </span>
            </dt>
            <dd>{addressEntity.complement}</dd>
            <dt>
              <span id="geolocated">
                <Translate contentKey="resitrackApp.address.geolocated">Geolocated</Translate>
              </span>
            </dt>
            <dd>{addressEntity.geolocated ? 'true' : 'false'}</dd>
            <dt>
              <span id="lat">
                <Translate contentKey="resitrackApp.address.lat">Lat</Translate>
              </span>
            </dt>
            <dd>{addressEntity.lat}</dd>
            <dt>
              <span id="lng">
                <Translate contentKey="resitrackApp.address.lng">Lng</Translate>
              </span>
            </dt>
            <dd>{addressEntity.lng}</dd>
            <dt>
              <Translate contentKey="resitrackApp.address.city">City</Translate>
            </dt>
            <dd>{addressEntity.city ? addressEntity.city.name : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/address" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/address/${addressEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ address }: IRootState) => ({
  addressEntity: address.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressDetail);

import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import {
  deleteEntity as deleteAuthorization,
  getAuthorizations,
  reset,
} from 'app/modules/generic/authorization/generic-authorization.reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { Pageable } from 'app/shared/model/pageable';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/shared/util/string-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './generic-authorization.scss';
import moment from 'moment';
import { IAuthorization } from 'app/shared/model/authorization.model';
import { ALL_ADMIN_AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IGenericAuthorizationListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IGenericAuthorizationListState {
  filterName: string;
  page: Pageable;
}

export class GenericAuthorizationList extends React.Component<IGenericAuthorizationListProps, IGenericAuthorizationListState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('authorizations.headers.authorization'), sortCode: 'authorizationDocument.fileName' },
    { name: translate('authorizations.headers.specificResidue'), sortCode: 'specificResidue.name' },
    { name: translate('authorizations.headers.amount'), sortCode: 'amount' },
    { name: translate('authorizations.headers.amountReceipt'), sortCode: 'amountReceipt' },
    { name: translate('authorizations.headers.measurementType'), sortCode: 'measurementType' },
    { name: translate('authorizations.headers.expireDate'), sortCode: 'expireDate' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IGenericAuthorizationListProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getAuthorizations();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      this.props.getAuthorizations();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getAuthorizations = () => {
    const { page } = this.state;
    this.props.getAuthorizations(
      page.page,
      page.size,
      page.sort,
      StringUtils.toObject('authorizationDocument.fileName', this.state.filterName,)
    );
  };

  private handleTransformToTableContent = (content?: IAuthorization[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IAuthorization) => {
      result.push([
        item.authorizationDocument != null ? item.authorizationDocument.fileName ?? '' : '',
        item.sourceResidue.specificResidueDescription ? item.sourceResidue.specificResidueDescription : '',
        item.amount ? item.amount : '',
        item.amountReceipt ? item.amountReceipt : '',
        item.measurementType ? translate(`enums.measurementType.${item.measurementType}`) : '',
        item.expirationDate ? moment(item.expirationDate).format('DD/MM/YYYY') : '',
        <DeleteEditButton
          id={item.id}
          onDelete={this.onDeleteAuthorization}
          onEdit={this.onEditAuthorization}
          hideDelete={!this.props.hasAnyAdminAuthority}
          hideEdit={!this.props.hasAnyAdminAuthority}
        />,
      ]);
    });

    return result;
  };

  onDeleteAuthorization = (id: number) => {
    this.props.deleteAuthorization(id);
  };

  onCreateAuthorization = () => {
    this.props.history.push(`${this.props.match.url}/new`);
  };

  onEditAuthorization = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/edit`,
      state: { id: id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getAuthorizations()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getAuthorizations()
    );
  };

  onHandleFilterChanged = value => {
    this.setState({ filterName: value }, () => this.getAuthorizations());
  };

  onAuthorizationClick = (index: number) => {
    const { authorizations } = this.props;
    const authorization = authorizations[index];
    this.props.history.push({
      pathname: `${this.props.match.url}/details`,
      state: { id: authorization.id },
    });
  };

  render() {
    const { authorizations, hasAnyAdminAuthority } = this.props;
    const rows = this.handleTransformToTableContent(authorizations);
    return (
      <div className="mtr-unity__items mtr-unity__authorization">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('authorizations.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px' }}>
            <Col md="6">
              <SimpleSearchField placeholder={translate('authorizations.search')} onChange={this.onHandleFilterChanged} />
            </Col>
            {hasAnyAdminAuthority && (
              <Col md={{ size: 2, offset: 4 }}>
                <Button className={'button primary'} type={'submit'} onClick={this.onCreateAuthorization}>
                  <Translate contentKey={'authorizations.add'} />
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onAuthorizationClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchAuthorizationListStatus: root.genericAuthorization.fetchAuthorizationListStatus,
  deleteStatus: root.genericAuthorization.deleteAuthorizationStatus,
  authorizations: root.genericAuthorization.authorizations,
  totalItems: root.genericAuthorization.totalItems,
  hasAnyAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_ADMIN_AUTHORITIES),
});

const mapDispatchToProps = {
  getAuthorizations,
  deleteAuthorization,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GenericAuthorizationList);

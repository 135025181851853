import Loading from 'app/components/loading/loading';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './manage-details.scss';
import { Pageable } from 'app/shared/model/pageable';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IUser } from 'app/shared/model/user.model';
import { maskCPF, maskPhone } from 'app/shared/util/mask-utils';
import { Col, Row } from 'reactstrap';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import { createUser, getUser, reset, updateUser } from '../../profile-reducer';
import {
    checkStatusOnGoing,
    translateErrorMessage,
    ValidationResult,
    ValidationResultInstance
} from 'app/shared/util/validation-utils';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import {
    validateCPF,
    validateEmail,
    validatePersonName,
    validatePhoneNumber
} from 'app/modules/validation/validation-constants';

export interface IManagerUsersDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IManagerUsersDetailsState {
  page: Pageable;
  isNew: boolean;
  showErrorModal: boolean;
  name: string;
  email: string;
  cpf: string;
  phone: string;
  nameError: ValidationResult;
  emailError: ValidationResult;
  cpfError: ValidationResult;
  phoneError: ValidationResult;
}

export class ManagerUsersDetails extends React.Component<IManagerUsersDetailsProps, IManagerUsersDetailsState> {
  constructor(props: Readonly<IManagerUsersDetailsProps>) {
    super(props);
    this.state = {
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      isNew: null,
      showErrorModal: false,
      email: null,
      name: null,
      cpf: null,
      phone: null,
      nameError: ValidationResultInstance,
      emailError: ValidationResultInstance,
      cpfError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    this.props.reset();
    const { id } = (this.props.location.state as any) || { id: null };
    if (id) {
      this.props.getUser(id);
    } else {
      this.setState({
        isNew: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.user != null &&
      newProps.fetchUserStatus != this.props.fetchUserStatus &&
      newProps.fetchUserStatus === HttpRequestStatus.SUCCESS
    ) {
      this.mapUserToField(newProps.user);
    }
  }

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateEmail()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    if (this.onValidateCPF()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }

    return hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateCPF = () => {
    const { cpf } = this.state;
    const validate = validateCPF(cpf);
    this.setState({
      cpfError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  mapUserToField = (user: IUser) => {
    this.setState(
      {
        name: user.name,
        cpf: user.cpf,
        phone: user.phone,
        email: user.email,
      },
      () => this.onValidateAll()
    );
  };

  onGoBack = () => {
    this.props.history.push('/me/profile/user-manage');
  };

  onEditClicked = () => {
    const { user } = this.props;
    this.props.history.push({
      pathname: `/me/user-manage/edit`,
      state: { id: user.id },
    });
  };

  render() {
    const { createUserStatus, fetchUserStatus, updateUserStatus } = this.props;
    const { name, nameError, cpf, cpfError, phone, phoneError, email, emailError } = this.state;
    if (checkStatusOnGoing([createUserStatus, fetchUserStatus, updateUserStatus])) {
      return <Loading />;
    }
    return (
      <div className="new-user__items list__items">
        <BackHeaderComponent onBack={this.onGoBack} hasButton onButtonClick={this.onEditClicked} />
        <div style={{ marginBottom: '35px' }}>
          <h1>
            {' '}
            <Translate contentKey={'user.details.title'} />
          </h1>
        </div>
        <Row>
          <Col style={{ margin: 'auto' }} md="4">
            <CustomTextField
              onChange={null}
              readonly
              id="name"
              style={{ marginTop: '20px' }}
              value={name}
              label={translate('user.details.name')}
              placeholder={translate('user.details.name')}
              error={nameError.hasError}
              errorText={translateErrorMessage(nameError.errorMessage)}
              onBlur={this.onValidateName}
            />
            <CustomTextField
              onChange={null}
              readonly
              id="email"
              style={{ marginTop: '20px' }}
              value={email}
              label={translate('user.details.email')}
              placeholder={translate('user.details.email')}
              error={emailError.hasError}
              errorText={translateErrorMessage(emailError.errorMessage)}
              onBlur={this.onValidateEmail}
            />
            <CustomTextField
              onChange={null}
              id="cpf"
              readonly
              style={{ marginTop: '20px' }}
              value={cpf}
              onMask={maskCPF}
              label={translate('user.details.cpf')}
              placeholder={translate('user.details.cpf')}
              error={cpfError.hasError}
              errorText={translateErrorMessage(cpfError.errorMessage)}
              onBlur={this.onValidateCPF}
            />
            <CustomTextField
              onChange={null}
              id="phone"
              readonly
              style={{ marginTop: '20px' }}
              value={phone}
              onMask={maskPhone}
              label={translate('user.details.phone')}
              placeholder={translate('user.details.phone')}
              error={phoneError.hasError}
              errorText={translateErrorMessage(phoneError.errorMessage)}
              onBlur={this.onValidatePhone}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchUserStatus: root.profile.fetchUserStatus,
  createUserStatus: root.profile.createUserStatus,
  updateUserStatus: root.profile.updateUserStatus,
  user: root.profile.user,
  isTransporterAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSPORTER_ADMIN]),
  isManagerAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
  isProducerAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN]),
  isFinalDestinationAdmin: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_ADMIN]),
});

const mapDispatchToProps = {
  getUser,
  createUser,
  updateUser,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerUsersDetails);

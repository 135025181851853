import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, IUserProducer } from 'app/shared/model/user-producer.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudGetEmptyAction, ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_USERPRODUCER_LIST: 'userProducer/FETCH_USERPRODUCER_LIST',
  FETCH_USERPRODUCER: 'userProducer/FETCH_USERPRODUCER',
  CREATE_USERPRODUCER: 'userProducer/CREATE_USERPRODUCER',
  UPDATE_USERPRODUCER: 'userProducer/UPDATE_USERPRODUCER',
  DELETE_USERPRODUCER: 'userProducer/DELETE_USERPRODUCER',
  GET_PRODUCER_DETAIL_UUID: 'userProducer/GET_PRODUCER_DETAIL_UUID',
  GET_PRODUCER_DETAIL_ID: 'userProducer/GET_PRODUCER_DETAIL_ID',
  RESET: 'userProducer/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserProducer>,
  entity: defaultValue,
  producerIdDetail: defaultValue,
  producerUuidDetail: defaultValue,
  updating: false,
  updateSuccess: false,
  deleteEntityStatus: HttpRequestStatus.NOOP,
  producerDetailUuidStatus: HttpRequestStatus.NOOP,
  producerDetailIdStatus: HttpRequestStatus.NOOP,
  totalItems: 0,
};

export type UserProducerState = Readonly<typeof initialState>;

// Reducer

export default (state: UserProducerState = initialState, action): UserProducerState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERPRODUCER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERPRODUCER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USERPRODUCER):
    case REQUEST(ACTION_TYPES.UPDATE_USERPRODUCER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_USERPRODUCER):
      return {
        ...state,
        errorMessage: null,
        deleteEntityStatus: HttpRequestStatus.ONGOING,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USERPRODUCER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERPRODUCER):
    case FAILURE(ACTION_TYPES.CREATE_USERPRODUCER):
    case FAILURE(ACTION_TYPES.UPDATE_USERPRODUCER):
    case FAILURE(ACTION_TYPES.DELETE_USERPRODUCER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_USERPRODUCER):
      return {
        ...state,
        deleteEntityStatus: HttpRequestStatus.ERROR,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERPRODUCER_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERPRODUCER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERPRODUCER):
    case SUCCESS(ACTION_TYPES.UPDATE_USERPRODUCER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERPRODUCER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        deleteEntityStatus: HttpRequestStatus.SUCCESS,
        entity: {},
      };
    case REQUEST(ACTION_TYPES.GET_PRODUCER_DETAIL_ID):
      return {
        ...state,
        errorMessage: null,
        producerDetailIdStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_PRODUCER_DETAIL_ID):
      return {
        ...state,
        producerDetailIdStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_PRODUCER_DETAIL_ID):
      return {
        ...state,
        producerDetailIdStatus: HttpRequestStatus.SUCCESS,
        producerIdDetail: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.GET_PRODUCER_DETAIL_UUID):
      return {
        ...state,
        errorMessage: null,
        producerDetailUuidStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_PRODUCER_DETAIL_UUID):
      return {
        ...state,
        producerDetailUuidStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_PRODUCER_DETAIL_UUID):
      return {
        ...state,
        producerDetailUuidStatus: HttpRequestStatus.SUCCESS,
        producerUuidDetail: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-producers';

// Actions

export const getEntities: ICrudGetAllAction<IUserProducer> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_USERPRODUCER_LIST,
  payload: axios.get<IUserProducer>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IUserProducer> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERPRODUCER,
    payload: axios.get<IUserProducer>(requestUrl),
  };
};

export const getEntityByProducer: ICrudGetAction<IUserProducer> = id => {
  const requestUrl = `${apiUrl}/producer/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERPRODUCER,
    payload: axios.get<IUserProducer>(requestUrl),
  };
};

export const getProducerDetailById: ICrudGetAction<IUserProducer> = id => {
  const requestUrl = `${apiUrl}/${id}/details`;
  return {
    type: ACTION_TYPES.GET_PRODUCER_DETAIL_ID,
    payload: axios.get<IUserProducer>(requestUrl),
  };
};

export const getProducerDetailByUuid: ICrudGetEmptyAction<IUserProducer> = () => {
  const requestUrl = `${apiUrl}/details`;
  return {
    type: ACTION_TYPES.GET_PRODUCER_DETAIL_UUID,
    payload: axios.get<IUserProducer>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERPRODUCER,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IUserProducer> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERPRODUCER,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IUserProducer> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERPRODUCER,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './mtr-signature-generator-edit.scss';

export const MtrSignatureGeneratorEdit = () => {
  return (
    <div className="signature-generator-edit">
      <div className="signature-generator__menu">
        <div className="signature-generator__menu--back">
          <Container>
            <Row>
              <Col md="6">
                <div className="confirmation--back">
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              <Col md="2" style={{ paddingRight: '0' }}>
                <div className="signature-generator__menu--cancel">
                  <Translate contentKey={'mtr-signature.cancel-mtr'} />
                </div>
              </Col>
              <Col md="2" style={{ paddingLeft: '0', paddingRight: '0' }}>
                <div className="signature-generator__menu--edit">
                  <Translate contentKey={'mtr-signature.edit'} />
                </div>
              </Col>
              <Col md="2" style={{ paddingLeft: '0', paddingRight: '0' }}>
                <div className="signature-generator__menu--new">
                  <Translate contentKey={'mtr-signature.new-mtr'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="signature-generator__items">
        <Container>
          <Row style={{ marginTop: '20px' }}>
            <Col md={{ size: 6, offset: 1 }}>
              <div className="signature-generator__items--title">
                <div style={{ display: 'flex' }}>
                  <div className="signature-generator__items--title-text">
                    <span>{translate('mtr-signature.generator.title')}</span>
                    <span style={{ fontWeight: 300, color: 'black' }}>{translate('mtr-signature.generator.title-second')}</span>
                    <span>{translate('mtr-signature.generator.title-third')}</span>
                    <div style={{ width: '1px', height: '31px', border: 'solid 1px #aaaaaa' }} />
                  </div>
                  <div className="signature-generator__items--title-img" />
                </div>
                <div className="signature-generator__items--title-description">{translate('mtr-signature.generator.description')}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <div className="signature-generator__items--list">{translate('mtr-signature.generator.generator')}</div>
              <div className="list__items--component-third">
                <div className="list__items--title">{translate('mtr.companyName')}</div>
                <div className="list__items--text">Lorem Ipsum Dolor</div>
              </div>
              <div className="list__items--component-third">
                <div className="list__items--title">{translate('mtr.activityType')}</div>
                <div className="list__items--text">Lorem Ipsum Dolor</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
              <div style={{ borderRight: '0px' }} className="list__items--component-third">
                <div className="list__items--title">{translate('mtr.address')}</div>
                <div className="list__items--text">Rua Lorem Ipsum Dolor, 000</div>
              </div>
            </Col>
            <Col md="5" style={{ paddingLeft: '0px' }}>
              <div className="list__items--component-third">
                <div className="list__items--title">{translate('mtr.complement')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0px' }}>
              <div style={{ borderRight: '0px', borderBottom: 'solid 1px #e3e3e3' }} className="list__items--component-third">
                <div className="list__items--title">{translate('mtr.city')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <div style={{ borderRight: '0px', borderBottom: 'solid 1px #e3e3e3' }} className="list__items--component-third">
                <div className="list__items--title">{translate('mtr.state')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '0px' }}>
              <div style={{ borderBottom: 'solid 1px #e3e3e3' }} className="list__items--component-third">
                <div className="list__items--title">{translate('mtr.zipcode')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <div className="signature-generator__items--list">{translate('mtr-signature.generator.transporter')}</div>
              <div className="list__items--component-fourth">
                <div>
                  <div className="list__items--title">{translate('mtr.companyName')}</div>
                  <div className="list__items--text">Lorem Ipsum</div>
                </div>
                <div className="list__items--icon" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.address')}</div>
                <div className="list__items--text">Rua Lorem Ipsum Dolor, 000</div>
              </div>
            </Col>
            <Col md="5" style={{ paddingLeft: '2px' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.complement')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.city')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.state')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.zipcode')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.environmentalLicense')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <div className="list__items--component">
                <div className="list__items--title">Cadastro no Órgão Ambiental</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="signature-generator__items--list">{translate('mtr-signature.generator.driver')}</div>
              <div className="list__items--component-fourth">
                <div>
                  <div className="list__items--title">{translate('mtr.driver')}</div>
                  <div className="list__items--text">Lorem Ipsum</div>
                </div>
                <div className="list__items--icon" />
              </div>
            </Col>
            <Col md="5" style={{ paddingLeft: '2px' }}>
              <div className="signature-generator__items--list">{translate('mtr-signature.generator.vehicle')}</div>
              <div className="list__items--component-fourth">
                <div>
                  <div className="list__items--title">{translate('mtr.plate')}</div>
                  <div className="list__items--text">Lorem Ipsum</div>
                </div>
                <div className="list__items--icon" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="signature-generator__items--list">{translate('mtr-signature.generator.final-destiny')}</div>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.companyName')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="5" style={{ paddingLeft: '2px', marginTop: '52px' }}>
              <div className="list__items--component-fourth">
                <div>
                  <div className="list__items--title">{translate('mtr.cnpj')}</div>
                  <div className="list__items--text">Lorem Ipsum</div>
                </div>
                <div className="list__items--icon" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.finalDestinationCategory')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.address')}</div>
                <div className="list__items--text">Rua Lorem Ipsum Dolor, 000</div>
              </div>
            </Col>
            <Col md="5" style={{ paddingLeft: '2px' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.complement')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.city')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.state')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.zipcode')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.licenseNumber')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="3" style={{ paddingLeft: '2px' }}>
              <div className="list__items--component">
                <div className="list__items--title">Tipo</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
              <div className="list__items--component">
                <div className="list__items--title">Nome da Licença</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
            <Col md="6" style={{ paddingLeft: '2px' }}>
              <div className="list__items--component">
                <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                <div className="list__items--text">Lorem Ipsum</div>
              </div>
            </Col>
          </Row>
          <div className="signature-generator__items--frame">
            <Row>
              <Col md={{ size: 12 }} style={{ paddingLeft: '38px', paddingRight: '38px' }}>
                <div className="signature-generator__items--list">{translate('mtr-signature.generator.waste')}</div>
                <div className="list__items--component-fifth">
                  <div>
                    <div className="list__items--title">{translate('mtr.residueCode')}</div>
                    <div className="list__items--text">Lorem Ipsum</div>
                  </div>
                  <div className="list__items--icon" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6 }} style={{ paddingRight: '0', paddingLeft: '36px' }}>
                <div className="list__items--component-second">
                  <div className="list__items--title">{translate('mtr.residueType')}</div>
                  <div className="list__items--text">000 - Lorem Ipsum Dolor Amet Consectetuer</div>
                </div>
              </Col>
              <Col md="6" style={{ paddingLeft: '0', paddingRight: '36px' }}>
                <div className="list__items--component-second">
                  <div className="list__items--title">{translate('mtr.residueSubcategory')}</div>
                  <div className="list__items--text">000 - Amet Consectetuer</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6 }} style={{ paddingRight: '0', paddingLeft: '36px' }}>
                <div className="list__items--component-second">
                  <div className="list__items--title">{translate('mtr.residueDescription')}</div>
                  <div className="list__items--text">Adpiscing Lorem Consectetuer</div>
                </div>
              </Col>
              <Col md="6" style={{ paddingLeft: '0', paddingRight: '36px' }}>
                <div className="list__items--component-second">
                  <div className="list__items--title">{translate('mtr.specificResidue')}</div>
                  <div className="list__items--text">Lorem Ipsum</div>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: '30px' }}>
              <Col md={{ size: 12 }} style={{ paddingLeft: '38px', paddingRight: '38px' }}>
                <div style={{ fontSize: '14px' }} className="signature-generator__items--list">
                  {translate('mtr-signature.generator.total-waste')}
                </div>
                <div className="list__items--component-fifth">
                  <div>
                    <div style={{ fontSize: '22px' }} className="list__items--text">
                      2.000 KG
                    </div>
                  </div>
                  <div className="list__items--icon" />
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <div className="signature-generator--component">
                <div className="signature-generator--component-img" />
                <div className="signature-generator--component-text">Adicionar mais resíduo</div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom:'60px'}}>
            <Col md={{ size: 10, offset: 1 }}>
              <div className="signature-generator__items--list">{translate('mtr-signature.generator.date')}</div>
              <div className="list__items--component-fourth">
                  <div>
                    <div className="list__items--title">Data de coleta</div>
                    <div className="list__items--text">Lorem Ipsum</div>
                  </div>
                  <div className="list__items--icon" />
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MtrSignatureGeneratorEdit;

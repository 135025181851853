import { TextField } from '@material-ui/core';
import { maskCardVerificationValue, maskCreditCardNumber, maskCreditCardValidity, maskCPFNoLimit, maskCnpj, maskZipCode, maskPhone } from 'app/shared/util/mask-utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import './custom-text-field-for-plans-screen.scss';

interface IProps {
    inputName: string;
    label?: string;
    notIsFullWidth?: boolean;
    errorText?: string;
    isHidenInputValue?: boolean;
    value?: string;
    mask?: TypeMask;
    type?: string;
    onChange?: (e: string) => void;
}

export enum TypeMask {
    CREDIT_CARD_NUMBER = "CREDIT_CARD_NUMBER",
    CREDIT_CARD_VALIDITY = "CREDIT_CARD_VALIDITY",
    CARD_VERIFICATION_VALUE = "CARD_VERIFICATION_VALUE",
    CPF = "CPF",
    CNPJ = "CNPJ",
    ZIP_CODE = "ZIP_CODE",
    PHONE = "PHONE"
}

const getCreditCardMasks = (maskName: TypeMask, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const masks = {
        CREDIT_CARD_NUMBER: maskCreditCardNumber(e.target.value),
        CREDIT_CARD_VALIDITY: maskCreditCardValidity(e.target.value),
        CARD_VERIFICATION_VALUE: maskCardVerificationValue(e.target.value),
        CPF: maskCPFNoLimit(e.target.value),
        CNPJ: maskCnpj(e.target.value),
        ZIP_CODE: maskZipCode(e.target.value),
        PHONE: maskPhone(e.target.value)
    }

    return masks[maskName];
}

const CustomTextFieldForPlansScreen = ({
    inputName,
    label,
    notIsFullWidth,
    errorText,
    isHidenInputValue,
    value,
    mask,
    type,
    onChange
}: IProps) => {

    const { register, setValue } = useFormContext();

    const handleOnChage = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (mask != null) {
            setValue(inputName, getCreditCardMasks(mask, e));
        }

        if (onChange != null) {
            onChange(e.target.value);
        }
    };

    return (
        <div className='container-custom-text-field-fo-plans-screen'>
            <TextField
                {...register(inputName)}
                style={{ marginBottom: '16px' }}
                className={`input-text ${!isEmpty(errorText) ? 'error' : ''} ${isHidenInputValue ? 'hiden-input-value' : ''}`}
                type={type != null ? type : 'string'}
                variant='filled'
                autoComplete='off'
                fullWidth={notIsFullWidth ? false : true}
                label={label}
                helperText={errorText}
                onChange={handleOnChage}
                inputProps={{ maxLength: 255 }}
            />
            {isHidenInputValue && (
                <span className='container-custom-text-field-fo-plans-screen__text-masked'>{value}</span>
            )}
        </div>
    );
};

export default CustomTextFieldForPlansScreen;

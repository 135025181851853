import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './user-manager.reducer';

export interface IUserManagerDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class UserManagerDetail extends React.Component<IUserManagerDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { userManagerEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.userManager.detail.title">UserManager</Translate> [<b>{userManagerEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="registrationNumber">
                <Translate contentKey="resitrackApp.userManager.registrationNumber">Registration Number</Translate>
              </span>
            </dt>
            <dd>{userManagerEntity.registrationNumber}</dd>
            <dt>
              <span id="classCouncil">
                <Translate contentKey="resitrackApp.userManager.classCouncil">Class Council</Translate>
              </span>
            </dt>
            <dd>{userManagerEntity.classCouncil}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userManager.user">User</Translate>
            </dt>
            <dd>{userManagerEntity.user ? userManagerEntity.user.login : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userManager.manager">Manager</Translate>
            </dt>
            <dd>{userManagerEntity.manager ? userManagerEntity.manager.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/user-manager" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/user-manager/${userManagerEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ userManager }: IRootState) => ({
  userManagerEntity: userManager.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserManagerDetail);

import React, { useState } from 'react';
import './generic-card-for-plans.scss';
import greenIndustrySvg from '../../../../../../../../content/images/icons/green-industry.svg';
import greenHouseSvg from '../../../../../../../../content/images/icons/green-house.svg';
import { translate, Translate } from 'react-jhipster';
import { useHistory } from 'react-router-dom';
import TypesOfPlans from 'app/shared/model/enum/typeOfPlans';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import PlanService from 'app/services/PlanService';
import { realCoinMask } from 'app/shared/util/mask-utils';
import ButtonLoading from 'app/components/button-loding/ButtonLoading';
import { Button } from '@material-ui/core';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

interface IProps extends StateProps {
    isCardFree?: boolean;
    isJustCard?: boolean;
    pricePlanPremium?: number;
    children: JSX.Element;
}

const discountPercentage = 20;

const GenericCardForPlans = ({ children, isCardFree, isJustCard, pricePlanPremium, hasSubscription, hasAdminAuthority }: IProps) => {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleWithPlans = (typePlan: TypesOfPlans) => {
        if (typePlan === TypesOfPlans.FREE) {
            setIsLoading(true);

            hasSubscription ? (
                PlanService.updatePlan({ planType: TypesOfPlans.FREE }).then(() => {
                    setIsLoading(false);
                    history.push('/');
                })
            ) : (
                PlanService.createPlan({ planType: TypesOfPlans.FREE }).then(() => {

                    if (hasAdminAuthority) {
                        setIsLoading(false);
                        history.push('/invite-alert-page');
                    } else {
                        setIsLoading(false);
                        history.push('/');
                    }
                })
            );
        } else {
            history.push('/full-register/payment-method', {
                price: pricePlanPremium,
                planName: TypesOfPlans.PREMIUM
            });
        }
    };

    return (
        <div className={`card__container ${isCardFree ? 'push-card-right' : ''} ${isJustCard ? 'one-card' : ''}`}>
            <div className='card__container--header'>
                {isCardFree ? (
                    <>
                        <div>
                            <figure>
                                <img src={greenHouseSvg} alt="Imagem plano gratuito" />
                            </figure>
                        </div>
                        <div>
                            <Translate contentKey={'register.plans.free'} />
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <figure>
                                <img src={greenIndustrySvg} alt="Imagem plano pago" />
                            </figure>
                        </div>
                        <div>
                            <Translate contentKey={'register.plans.premium'} />
                        </div>
                    </>
                )}
            </div>

            {children}

            {isCardFree ? (
                <>
                    <div className="card__container--premium-amount-total">{""}</div>
                    <span className='card__container--free'>
                        <Translate contentKey="register.plans.cards.manager.free" />
                    </span>
                    <div className='card__container--sign-button'>
                        <ButtonLoading
                            className="button fifth"
                            colorIconLoading="#16934a"
                            textButton={translate('register.plans.sign')}
                            isLoading={isLoading}
                            onClick={() => handleWithPlans(TypesOfPlans.FREE)}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="card__container--premium-amount-total">
                        <span>{realCoinMask(pricePlanPremium + ((discountPercentage / 100) * pricePlanPremium))}</span>
                        <Translate contentKey="register.plans.month" />
                    </div>

                    <span className='card__container--premium'>{realCoinMask(pricePlanPremium)}</span>
                    <span className='card__container--premium-month'>
                        <Translate contentKey="register.plans.month" />
                    </span>

                    <div className='card__container--sign-button'>
                        <Button
                            className="button fifth"
                            onClick={() => handleWithPlans(TypesOfPlans.PREMIUM)}
                        >
                            <Translate contentKey={'register.plans.sign'} />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account.hasSubscription,
    hasAdminAuthority: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN]),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(GenericCardForPlans);
import { makeStyles } from '@material-ui/core/styles';
import AlertModal from '../../../../components/alert-modal/alert-modal';
import CustomTextField from '../../../../components/custom-text-field/custom-text-field';
import DatePickerComponent from '../../../../components/date-picker-component/date-picker-component';
import DropdownSearch from '../../../../components/dropdown-search/dropdown-search';
import { ALL_USER_AUTHORITIES, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from '../../../../config/constants';
import { searchFinalDestinations } from '../../../../entities/final-destination/final-destination.reducer';
import { validateGenericField, validateObject, validateResidueListMtr } from '../../../../modules/validation/validation-constants';
import { hasAnyAuthority } from '../../../../shared/auth/private-route';

import { FinalDestinationCategory, IFinalDestination, mapFinalDestinationToSearch } from '../../../../shared/model/final-destination.model';
import { IMtrResidue } from '../../../../shared/model/mtr-residue.model';
import { IMtr, MtrStatus } from '../../../../shared/model/mtr.model';
import { IProducerMtr, mapProducersToSearch } from '../../../../shared/model/producer.model';
import { ISourceResidueMtr, mapSourceResiduesToSearch } from '../../../../shared/model/source-residue.model';
import { ITransporterMtr, mapTransportToSearch } from '../../../../shared/model/transporter.model';
import { IVehicle, mapVehicleToSearch } from '../../../../shared/model/vehicle.model';
import { IRootState } from '../../../../shared/reducers';
import { InvalidReulst, translateErrorMessage, ValidationResult, ValidationResultInstance } from '../../../../shared/util/validation-utils';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import { reset as resetDrivers, searchDrivers } from '../../../../entities/driver/driver.reducer';
import { reset as resetProducers, searchProducers } from '../../../../entities/producer/producer.reducer';
import { reset as resetResidues, searchSourceResidues } from '../../../../entities/source-residue/source-residue.reducer';
import { reset as resetVehicles, searchVehicles } from '../../../../entities/vehicle/vehicle.reducer';
import { reset as resetTransporters, searchTransporters } from '../../../../modules/transporter/transport/transport-reducer';
import {
    getMtrFinalDestination,
    getMtrProducer,
    getMtrResidue,
    getMtrTransporter,
    getMtrTransshipment,
    reset as resetMtr,
    resetStatus
} from '../../../generic/mtr/mtr-reducer';
import { createMtrModel, getMtrModel, updateMtrModel, deleteMtrModel } from '../mtr-model.reducer'
import '../../../generic/mtr/manage/mtr-manage.scss';
import { renderFinalDestination, renderMtrResidue, renderProducer, renderTransporter, renderTransshipment, renderUnits, renderUnitsFinalDestiny, renderUnitsTransporter, renderUnitsTransshipment } from '../../../generic/mtr/manage/mtr-templates';
import { MeasurementType } from '../../../../shared/model/authorization.model';
import { mapTransshipmentToSearch, Transshipment } from '../../../../shared/model/transshipment.model';
import { searchTransshipments } from '../../../../entities/transshipment/transshipment.reducer';
import MtrModelView from '../mtr-model-view/mtr-model-view'
import { IUnit, mapUnitToSearch, ProducerUnit, UnitProducerOrFinalDestination } from 'app/shared/model/unit';
import {
    getIntegrationUnits,
    getIntegrationUnitsProducer,
    getIntegrationUnitsFinalDestiny,
    getIntegrationUnitsTransporter,
    getIntegrationUnitsTransshipment,
} from '../../../../modules/producer/units/producer-units-reducer'
import { ProfileType } from 'app/shared/model/enum/Profile-dropdonw';
import { getIntegrationUsers } from 'app/modules/generic/user-integration/user-integration.reducer';
import axios from 'axios';
import { toast } from 'react-toastify';
import CnpjSinirModal from 'app/components/cnpj-sinir-modal/cnpj-sinir-modal';
import mtrModelList from '../mtr-model-list';

export interface IMtrModelCreateProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
    transportador: String;
    destino: String;
}

const useStyles = makeStyles({
    underline: {
        '&&&:before': {
            borderBottom: 'none',
        },
        '&&:after': {
            borderBottom: 'none',
        },
    },
});

const MtrModelCreate = (props: IMtrModelCreateProps): JSX.Element => {
    const [state, setState] = useState({
        transportador: 'transportador',
        destino: 'destino',
    });
    const history = useHistory()
    const location = useLocation()
    const [finalDestination, setFinalDestination] = useState<IFinalDestination | undefined>();
    const [finalDestinationUnit, setFinalDestinationUnit] = useState<ProducerUnit | undefined>(undefined);
    const [unitFinalDestiny, setUnitFinalDestiny] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [transshipment, setTransshipment] = useState<Transshipment | undefined>();
    const [transshipmentUnit, setTransshipmentUnit] = useState<ProducerUnit | undefined>(undefined);
    const [unitTransshipment, setUnitTransshipment] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [transporter, setTransporter] = useState<ITransporterMtr | undefined>(undefined);
    const [residueIndex, setResidueIndex] = useState<number | undefined>(undefined);
    const [producer, setProducer] = useState<IProducerMtr | undefined>(undefined);
    const [producerUnit, setProducerUnit] = useState<ProducerUnit | undefined>(undefined);
    const [transporterUnit, setTransporterUnit] = useState<ProducerUnit | undefined>(undefined);
    const [unitTransporter, setUnitTransporter] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [residues, setResidues] = useState<IMtrResidue[]>([]);
    const [mtr, setMtr] = useState<IMtr>(undefined);
    const [editFlag, setEditFlag] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modelName, setModelName] = useState<string>('');
    const [modelNameError, setModelNameError] = useState<boolean>();
    const [residueError, setResidueError] = useState<Map<number, ValidationResult> | undefined>(new Map<number, ValidationResult>());
    const [UnitError, setUnitError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [amountError, setAmountError] = useState<Map<number, ValidationResult> | undefined>(new Map<number, ValidationResult>());
    // const [viewModeOnly, setViewModeOnly] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [unit, setUnit] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [isModalTransporterOpen, setIsModalTransporterOpen] = useState(false);
    const [isModalFinalDestinationOpen, setIsModalFinalDestinationOpen] = useState(false);
    const [apiDataTransporter, setApiDataTransporter] = useState([]);
    const [apiDataFinalDestination, setApiDataFinalDestination] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const mtrModelId = props.history?.location?.state?.id ?? null;
    // const parDescricaoTransportador = props.history.location.state?.parDescricaoTransportador ?? '';
    const useForceUpdate = () => useState()[1];

    useEffect(() => {
        if (mtrModelId === null) {
            if (props.hasTransporterAuthorities) {
                props.getMtrTransporter();
            } else if (props.hasProducerAuthorities) {
                props.getMtrProducer();
            }

            setResidues([{}]);
            // Initial get
            getProducers('');
            getTransporters('');
            getTransshipments('');
            getSourceResidues('');
            getFinalDestinations('');
        } else {
            setIsLoading(true)
            setResidues([{}]); // residues not comming from the api
            props.getMtrModel(mtrModelId,)

        }
    }, []);

    useEffect(() => {
        if (props.mtrModel?.producerUnit?.id) {
            props.unitsProducer?.forEach((element) => {
                if (element.id === props.mtrModel?.producerUnit?.id) {
                    setUnit({ name: element.fantasyName, unitCode: element.unitCode, unitAddressLabel: element.unitAddressLabel, unitZipCode: element.unitZipCode })
                }
            })
        }
    }, [props.unitsProducer])

    useEffect(() => {
        if (props.mtrModel?.finalDestinationUnit?.id) {
            props.unitsFinalDestination?.forEach((element) => {
                if (element.id === props.mtrModel?.finalDestinationUnit?.id) {
                    setUnitFinalDestiny({ name: element.fantasyName, unitCode: element.unitCode, unitAddressLabel: element.unitAddressLabel, unitZipCode: element.unitZipCode })
                }
            })
        }
    }, [props.unitsFinalDestination])

    useEffect(() => {
        if (props.mtrModel?.transporterUnit?.id) {
            props.unitsTransporter?.forEach((element) => {
                if (element.id === props.mtrModel?.transporterUnit?.id) {
                    setUnitTransporter({ name: element.fantasyName, unitCode: element.unitCode, unitAddressLabel: element.unitAddressLabel, unitZipCode: element.unitZipCode })
                }
            })
        }
    }, [props.unitsTransporter])

    useEffect(() => {
        if (props.mtrModel?.transshipmentUnit?.id) {
            props.unitsTransshipment?.forEach((element) => {
                if (element.id === props.mtrModel?.transshipmentUnit?.id) {
                    setUnitTransshipment({ name: element.fantasyName, unitCode: element.unitCode, unitAddressLabel: element.unitAddressLabel, unitZipCode: element.unitZipCode })
                }
            })
        }
    }, [props.unitsTransshipment])

    useEffect(() => {
        if (props.mtrModel?.producer?.id) {
            props.getIntegrationUnitsProducer(props.mtrModel.producer.id)
        }
        if (props.mtrModel?.finalDestination?.id) {
            props.getIntegrationUnitsFinalDestiny(props.mtrModel.finalDestination.id)
        }
        if (props.mtrModel?.transporter?.id) {
            props.getIntegrationUnitsTransporter(props.mtrModel.transporter.id)
        }
        if (props.mtrModel?.transshipment?.id) {
            props.getIntegrationUnitsTransshipment(props.mtrModel.transshipment.id)
        }
    }, [props.mtrModel])


    useEffect(() => {
        if (props.getMtrModeltatus === 2) {
            if (props.mtrModel?.name) {
                setModelName(props.mtrModel?.name)
            }
            if (props.mtrModel.producer?.id) {
                props.getMtrProducer(props.mtrModel.producer?.id);
            }
            if (props.mtrModel.transporter?.id) {
                props.getMtrTransporter(props.mtrModel.transporter?.id);
            } else {
                console.log("Transportador sem ID. Executando ação alternativa.");
                const formattedTransporter = {
                    legalPerson: {
                        companyName: props.mtrModel.parDescricaoTransportador,
                    },
                    addressLabel: props.mtrModel.parEnderecoTransportador,
                    addressZipcode: props.mtrModel.parZipcodeTransportador,
                };

                const formattedUnitTransporter = {
                    name: props.mtrModel.parDescricaoTransportador,
                    unitAddressLabel: props.mtrModel.parEnderecoTransportador,
                    unitCode: props.mtrModel.parCodigoTransportador,
                    unitZipCode: props.mtrModel.parZipcodeTransportador,
                    cnpj: props.mtrModel.parCnpjTransportador,
                }

                setTransporter(formattedTransporter);
                setUnitTransporter(formattedUnitTransporter);
            }
            if (props.mtrModel?.transshipment?.id) {
                props.getMtrTransshipment(props.mtrModel?.transshipment?.id);
            }
            if (props.mtrModel.finalDestination?.id) {
                props.getMtrFinalDestination(props.mtrModel.finalDestination?.id);
            } else {
                console.log("Destino final sem ID. Executando ação alternativa.");
                const formattedFinalDestination = {
                    addressLabel: props.mtrModel.parEnderecoDestino,
                    addressZipcode: props.mtrModel.parZipcodeDestino,
                    legalPerson: {
                        cnpj: props.mtrModel.parCnpjDestino,
                        companyName: props.mtrModel.parDescricaoDestino,
                    },
                    finalDestinationCategory: FinalDestinationCategory.EMPTY_CATEGORY,
                    parUf: props.mtrModel.parUfDestino
                };

                const formattedUnitFinalDestination = {
                    name: props.mtrModel.parDescricaoDestino,
                    unitAddressLabel: props.mtrModel.parEnderecoDestino,
                    unitCode: props.mtrModel.parCodigoDestino,
                    unitZipCode: props.mtrModel.parZipcodeDestino,
                }

                setFinalDestination(formattedFinalDestination);
                setUnitFinalDestiny(formattedUnitFinalDestination);
            }
            if (props.mtrModel?.mtrResidues) {
                setResidues(props.mtrModel?.mtrResidues)
            }
            setIsLoading(false)
        }
    }, [props.getMtrModeltatus])


    useEffect(() => {
        props.resetProducers();
        props.resetTransporters();
        props.resetStatus();
        props.resetResidues();

    }, []);


    useEffect(() => {
        if (props.producer != producer && props.producer != null) {
            setProducer(props.producer);
        }
    }, [props.producer]);

    useEffect(() => {
        if (transporter != props.transporter && props.transporter != null) {
            setTransporter(props.transporter);
        }
    }, [props.transporter]);

    useEffect(() => {
        if (finalDestination != props.finalDestination && props.finalDestination != null) {
            setFinalDestination(props.finalDestination);
        }
    }, [props.finalDestination]);
    useEffect(() => {
        if (transshipment != props.transshipment && props.transshipment != null) {
            setTransshipment(props.transshipment);
        }
    }, [props.transshipment]);

    useEffect(() => {
        const { id } = (props.location.state as any) || { id: null };
        if (props.hasProducerAuthorities || props.hasManagerAuthorities || props.hasTransporterAuthorities || props.hasFinalDestinationAuthorities || props.hasTransshipmentAuthorities) {
            props.getIntegrationUsers(id);
        }
    }, [props.hasProducerAuthorities, props.hasManagerAuthorities, props.hasTransporterAuthorities, props.hasFinalDestinationAuthorities, props.hasTransshipmentAuthorities]);

    useEffect(() => {
        if (props.residue != null && props.residue != null) {
            updateSourceResidue(props.residue, residueIndex);
        }
    }, [props.residue]);




    useEffect(() => {
        if (props.createMtrModelStatus === 2) {
            props.location.pathname.split('/').map(url => {
                if (ProfileType?.[url.toUpperCase()]) {

                    history.push(`/${ProfileType?.[url.toUpperCase()]}/mtr-model`)
                }

            })


            props.resetMtr()
        }
    }, [props.createMtrModelStatus]);

    useEffect(() => {
        if (props.updateMtrModelStatus === 2) {
            props.location.pathname.split('/').map(url => {
                if (ProfileType?.[url.toUpperCase()]) {

                    history.push(`/${ProfileType?.[url.toUpperCase()]}/mtr-model`)
                }

            })
            props.resetMtr()
        }
    }, [props.updateMtrModelStatus]);

    useEffect(() => {
        if (props.deleteMtrModelStatus === 2) {
            props.location.pathname.split('/').map(url => {
                if (ProfileType?.[url.toUpperCase()]) {

                    history.push(`/${ProfileType?.[url.toUpperCase()]}/mtr-model`)
                }

            })
            props.resetMtr()
        }

    }, [props.deleteMtrModelStatus])


    const getProducers = (text: string) => {
        props.searchProducers(0, 1000, undefined, { fantasyName: text });
    };
    const getUnitsProducer = (text: string) => {
        if (producer) {
            props.getIntegrationUnitsProducer(producer.id.toString());
        }
    };

    const getUnitsFinalDestination = (text: string) => {
        if (finalDestination) {
            props.getIntegrationUnitsFinalDestiny(finalDestination.id.toString());
        }
    };

    const getUnitsTransporter = (text: string) => {
        if (transporter) {
            props.getIntegrationUnitsTransporter(transporter.id.toString());
        }
    };

    const getUnitsTransshipment = (text: string) => {
        if (transshipment) {
            props.getIntegrationUnitsTransshipment(transshipment.id.toString());
        }
    };

    const getTransporters = (text: string) => {
        props.searchTransporters(0, 1000, undefined, { searchText: text });
    };
    const getTransshipments = (text: string) => {
        props.searchTransshipments(0, 1000, undefined, { fantasyName: text });
    };
    const getFinalDestinations = (text: string) => {
        props.searchFinalDestinations(0, 1000, undefined, { fantasyName: text });
    };
    const getSourceResidues = (text: string) => {
        props.searchSourceResidues(0, 1000, undefined, { 'specificResidue.name': text });
    };

    const validateUnit = () => {
        const validate = validateObject(unit);
        setUnitError(validate);
        return validate.hasError;
    };

    const validateTransshipmentResidues = () => {
        const validate = validateResidueListMtr(residues);
        return validate.hasError;
    };

    const setMtrTransshipment = item => {
        if (!validateTransshipmentResidues()) {
            props.getMtrTransshipment(item['id']);
        }
    };

    const validateAmountWeight = () => {
        let hasError = false;
        let maxWeightKgL = 45000;
        let maxWeightTC = 45;

        residues.forEach((item, index) => {
            if (item.amount <= 0) {
                amountError.clear()
                hasError = true;
                amountError.set(index, {
                    hasError: true,
                    errorMessage: 'mtr.errors.lower'
                } as InvalidReulst)
                return true;
            }
            switch (item.measurementType) {
                case MeasurementType.TONS:
                case MeasurementType.CUBIC_METERS:
                    if (item.amount > maxWeightTC) {
                        hasError = true;
                        amountError.clear()
                        amountError.set(index, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;
                case MeasurementType.KG:
                case MeasurementType.LITERS:
                default:
                    if (item.amount > maxWeightKgL) {
                        hasError = true;
                        amountError.clear()
                        amountError.set(index, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;

            }

            if (hasError === false) {
                amountError.clear();
            }
        });

        return hasError;
    };

    const saveMtrModel = () => {
        if (modelName === '') {
            setModelNameError(true)
            return;
        } else {
            setModelNameError(false)
        }

        residues.forEach(it => {
            if (!it.measurementType) {
                it.measurementType = MeasurementType.KG;
            }
        });
        if (mtrModelId) {
            props.updateMtrModel(
                {
                    id: mtrModelId,
                    name: modelName,
                    producer: producer?.id,
                    producerUnit,
                    finalDestinationUnit: finalDestinationUnit || unitFinalDestiny,
                    transporterUnit: transporterUnit || unitTransporter,
                    transshipmentUnit,
                    transporter: transporter?.id || transporter,
                    transshipment: transshipment?.id,
                    finalDestination: finalDestination?.id || finalDestination,
                    mtrResidues: residues.filter(it => it.sourceResidue != null)
                }
            )
        } else {
            const createMtrModelData = {
                name: modelName,
                producer: producer?.id,
                producerUnit,
                finalDestinationUnit: finalDestinationUnit || unitFinalDestiny,
                transporterUnit: transporterUnit || unitTransporter,
                transshipmentUnit,
                transporter: transporter?.id || transporter,
                transshipment: transshipment?.id,
                finalDestination: finalDestination?.id || finalDestination,
                mtrResidues: residues.filter(it => it.sourceResidue != null)
            };
            // Adicionando o console.log para visualizar os dados
            console.log('createMtrModel data:', createMtrModelData);
            // Chamando a função com o objeto
            props.createMtrModel(createMtrModelData);
        }
    };

    const clearTransporter = () => {
        setTransporter(undefined);
    };

    const clearTransshipment = () => {
        setTransshipment(undefined);
    };

    const clearUnits = () => {
        setUnit(undefined);
    };

    const clearUnitsFinalDestiny = () => {
        setUnitFinalDestiny(undefined);
    };

    const clearUnitsTransporter = () => {
        setUnitTransporter(undefined);
    };

    const clearUnitsTransshipment = () => {
        setUnitTransshipment(undefined);
    };

    const setUnitWithProducer = (item) => {
        setProducerUnit({ id: item.id })
        setUnit(item)
    }

    const setUnitWithTransporter = (item) => {
        setTransporterUnit({ id: item.id })
        setUnitTransporter(item)
    }

    const setUnitWithTransshipment = (item) => {
        setTransshipmentUnit({ id: item.id })
        setUnitTransshipment(item)
    }

    const setUnitWithFinalDestiny = (item) => {
        setFinalDestinationUnit({ id: item.id })
        setUnitFinalDestiny(item)
    }

    const renderUnitContent = () => {
        if (unit) {
            return renderUnits(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearUnits,
                unit
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {producer !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearch(props.unitsProducer)}
                                getData={value => getUnitsProducer(value)}
                                onClickItem={item => setUnitWithProducer(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };

    const renderUnitFinalDestinyContent = () => {
        if (unitFinalDestiny) {
            return renderUnitsFinalDestiny(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearUnitsFinalDestiny,
                unitFinalDestiny
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {finalDestination !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearch(props?.unitsFinalDestination)}
                                getData={value => getUnitsFinalDestination(value)}
                                onClickItem={item => setUnitWithFinalDestiny(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };


    const renderTransporterContent = () => {
        const isCnpj = (value) => {
            // Remove todos os caracteres que não são números
            const cleanValue = value.replace(/\D/g, '');
            // Expressão regular para validar o CNPJ sem máscara (apenas números)
            const cnpjRegex = /^\d{14}$/;
            return cnpjRegex.test(cleanValue);
        };

        const removeMaskFromCnpj = (cnpj) => {
            // Remove pontos, barra e hífen do CNPJ
            return cnpj.replace(/\D/g, '');
        };

        const handleGetDataTransporters = async (value) => {
            if (isCnpj(value)) {
                setIsSearching(true);
                try {
                    const { integrationUsers } = props;
                    const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode);
                    const login = targetUnit.secondLogin;
                    const unidade = targetUnit.unit.unitCode;
                    const senha = targetUnit.password;
                    const tipo = state.transportador;
                    const cleanCnpj = removeMaskFromCnpj(value);
                    console.log("CNPJ sem máscara: " + cleanCnpj);
                    const response = await axios.get(`/consulta-cnpj-sinir`, {
                        params: {
                            cleanCnpj,
                            login,
                            unidade,
                            senha,
                            tipo
                        }
                    });

                    if (response.status === 200 && response.data) {
                        const objetoRespostaArray = response.data.objetoResposta || [];
                        if (objetoRespostaArray.length > 0) {
                            setApiDataTransporter(objetoRespostaArray);
                            setIsModalTransporterOpen(true);
                            console.log("Retorno da chamada:", objetoRespostaArray);
                        } else {
                            console.error('Nenhum dado encontrado na resposta.');
                            toast.warn('Nenhum Parceiro com esse CNPJ foi encontrando no Sistema governamental');
                        }
                    } else {
                        console.error('Erro na resposta da API:', response.status);
                        toast.error("Sistema governamental está passando por instabilidades, tente novamente em instantes");
                    }
                } catch (error) {
                    console.error('Erro na requisição:', error);
                    toast.error("Sistema governamental está passando por instabilidades, tente novamente em instantes");
                } finally {
                    setIsSearching(false);
                }
            } else {
                getTransporters(value);
            }
        };

        const handleSelectTransporter = (item: any) => {
            console.log("Item Selecionado Transportador: ", item);

            const addressParts = item.parEndereco.split(',');

            const zipCode = addressParts[2]?.trim();

            const addressLabel = `${addressParts[0]?.trim()}, ${addressParts[1]?.trim()}, ${addressParts[3]?.trim()}`.trim();

            const formattedTransporter = {
                legalPerson: {
                    companyName: item.parDescricao,
                },
                addressLabel: addressLabel,
                addressZipcode: zipCode,
                parUf: item.parUf,
            };

            const formattedUnitTransporter = {
                name: item.parDescricao,
                unitAddressLabel: addressLabel,
                unitCode: item.parCodigo,
                unitZipCode: zipCode,
                cnpj: item.parCnpj,
            }

            setTransporter(formattedTransporter);
            setUnitTransporter(formattedUnitTransporter);
            setIsModalTransporterOpen(false);
            console.log("Transportador Atualizado: ", formattedTransporter);
            console.log("Unidade Transportador Atualizado: ", formattedUnitTransporter);
        };

        if (transporter != null) {
            return renderTransporter(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearTransporter,
                transporter,
                mtr
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.transporter')}</div>
                        {isSearching && (
                            <div className='cnpj-searching'>
                                <div className="searching-message">
                                    <p>Procurando Parceiros, Aguarde...</p>
                                    <div className="loader-cnpj"></div>
                                </div>
                            </div>
                        )}
                        <DropdownSearch
                            data={mapTransportToSearch(props.transporters)}
                            // getData={value => getTransporters(value)}
                            getData={handleGetDataTransporters}
                            onClickItem={item => props.getMtrTransporter(item['id'])}
                            title={'Selecione o transportador ou digite o CNPJ'}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            showSearchField
                            filterLocalData
                        />
                    </Col>
                    <CnpjSinirModal
                        isOpen={isModalTransporterOpen}
                        toggle={() => setIsModalTransporterOpen(!isModalTransporterOpen)}
                        apiData={apiDataTransporter}
                        onSelect={handleSelectTransporter}
                    />
                </Row>
            );
        }
    };

    const renderUnitTransporterContent = () => {
        if (unitTransporter) {
            return renderUnitsTransporter(
                !props.hasFinalDestinationAuthorities,
                clearUnitsTransporter,
                unitTransporter
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {transporter !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearch(props?.unitsTransporter)}
                                getData={value => getUnitsTransporter(value)}
                                onClickItem={item => setUnitWithTransporter(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };

    const updateSourceResidue = (sourceResidue: ISourceResidueMtr, index: number) => {
        let newArr = [...residues];
        if (index != null) {
            newArr[index].sourceResidue = sourceResidue;
            setResidueIndex(undefined);
        }
        setResidues(newArr);
        resetStatus();
    };

    const updateAmount = (index: number, amount: string) => {
        let newArr = [...residues];
        if (index != null) {
            newArr[index].amount = Number(amount.split('.').join('').replace(/,/, '.'));
            setResidues(newArr);
            validateAmountWeight()
        }
    };

    const updateMeasurementType = (index: number, measurementType: any) => {
        let newArr = [...residues];
        if (index != null) {
            newArr[index].measurementType = measurementType.keyEnum;
            setResidues(newArr);
        }
    };

    const mapSelectedMeasurementTypeToSelect = (index: number) => {
        let newArr = [...residues];
        let measurementType: MeasurementType;
        if (index != null) {
            if (newArr[index].measurementType != null) {
                measurementType = newArr[index].measurementType;
            } else {
                measurementType = newArr[index].sourceResidue?.measurementType;
            }
        }
        return measurementType
            ? {
                name: translate(`enums.measurementTypeAbbreviation.${measurementType}`),
                keyEnum: measurementType,
            }
            : {
                name: translate(`enums.measurementTypeAbbreviation.KG`),
                keyEnum: translate(`enums.measurementTypeAbbreviation.KG`),
            };
    };

    const removeSourceResidue = (index: number) => {
        let newArr = [...residues];
        if (residueError.get(index) != null) {
            residueError.delete(index);
        }
        if (amountError.get(index) != null) {
            amountError.delete(index);
        }
        if (newArr.length > 1) {
            newArr.splice(index, 1);
        } else {
            newArr[index] = {};
        }
        setResidues(newArr);
    };

    const addSourceResidue = () => {
        let newArr = [...residues];
        newArr.push({});
        setResidues(newArr);
    };

    const renderProducerContent = () => {
        if (producer != null) {
            return renderProducer(!props.hasProducerAuthorities && !props.hasFinalDestinationAuthorities, () => setProducer(undefined), producer);
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.generator')}</div>
                        <DropdownSearch
                            data={mapProducersToSearch(props)}
                            getData={value => getProducers(value)}
                            onClickItem={item => props.getMtrProducer(item['id'])}
                            title={translate('mtr-manage.producer')}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            filterLocalData
                            showSearchField
                        />
                    </Col>
                </Row>
            );
        }
    };

    const renderFinalDestinationContent = () => {
        const isCnpj = (value) => {
            // Remove todos os caracteres que não são números
            const cleanValue = value.replace(/\D/g, '');
            // Expressão regular para validar o CNPJ sem máscara (apenas números)
            const cnpjRegex = /^\d{14}$/;
            return cnpjRegex.test(cleanValue);
        };

        const removeMaskFromCnpj = (cnpj) => {
            // Remove pontos, barra e hífen do CNPJ
            return cnpj.replace(/\D/g, '');
        };

        const handleGetDataFinalDestinations = async (value) => {
            if (isCnpj(value)) {
                setIsSearching(true);
                try {
                    const { integrationUsers } = props;
                    const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode);
                    const login = targetUnit.secondLogin;
                    const unidade = targetUnit.unit.unitCode;
                    const senha = targetUnit.password;
                    const tipo = state.destino;
                    const cleanCnpj = removeMaskFromCnpj(value);
                    console.log("CNPJ sem máscara: " + cleanCnpj);
                    const response = await axios.get(`/consulta-cnpj-sinir`, {
                        params: {
                            cleanCnpj,
                            login,
                            unidade,
                            senha,
                            tipo
                        }
                    });

                    if (response.status === 200 && response.data) {
                        const objetoRespostaArray = response.data.objetoResposta || [];
                        if (objetoRespostaArray.length > 0) {
                            setApiDataFinalDestination(objetoRespostaArray);
                            setIsModalFinalDestinationOpen(true);
                            console.log("Retorno da chamada:", objetoRespostaArray);
                        } else {
                            console.error('Nenhum dado encontrado na resposta.');
                            toast.warn('Nenhum Parceiro com esse CNPJ foi encontrando no Sistema governamental');
                        }
                    } else {
                        console.error('Erro na resposta da API:', response.status);
                        toast.error("Sistema governamental está passando por instabilidades, tente novamente em instantes");
                    }
                } catch (error) {
                    console.error('Erro na requisição:', error);
                    toast.error("Sistema governamental está passando por instabilidades, tente novamente em instantes");
                } finally {
                    setIsSearching(false);
                }
            } else {
                getFinalDestinations(value)
            }
        };


        const handleSelectFinalDestination = (item: any) => {
            console.log('Item Selecionado Destino Final:', item);
            const addressParts = item.parEndereco.split(',');

            const zipCode = addressParts[2]?.trim();

            const addressLabel = `${addressParts[0]?.trim()}, ${addressParts[1]?.trim()}, ${addressParts[3]?.trim()}`.trim();
            const formattedFinalDestination = {
                addressLabel: addressLabel,
                addressZipcode: zipCode,
                legalPerson: {
                    cnpj: item.parCnpj,
                    companyName: item.parDescricao,
                },
                finalDestinationCategory: FinalDestinationCategory.EMPTY_CATEGORY,
                parUf: item.parUf,
            };

            const formattedUnitFinalDestination = {
                name: item.parDescricao,
                unitAddressLabel: addressLabel,
                unitCode: item.parCodigo,
                unitZipCode: zipCode,
            }

            setFinalDestination(formattedFinalDestination);
            setUnitFinalDestiny(formattedUnitFinalDestination);
            setIsModalFinalDestinationOpen(false);
            console.log("Destino Final Atualizado: ", formattedFinalDestination);
            console.log("Unidade Destino Final Atualizado: ", formattedUnitFinalDestination);
        };

        if (finalDestination != null) {
            return renderFinalDestination(!props.hasFinalDestinationAuthorities, () => setFinalDestination(undefined), finalDestination, mtr);
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.final-destiny')}</div>
                        {isSearching && (
                            <div className='cnpj-searching'>
                                <div className="searching-message">
                                    <p>Procurando Parceiros, Aguarde...</p>
                                    <div className="loader-cnpj"></div>
                                </div>
                            </div>
                        )}
                        <DropdownSearch
                            data={mapFinalDestinationToSearch(props)}
                            getData={handleGetDataFinalDestinations}
                            onClickItem={item => props.getMtrFinalDestination(item['id'])}
                            title={translate('mtr-manage.finalDestination')}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            showSearchField
                            filterLocalData
                            disableOpen={props.hasFinalDestinationAuthorities}
                        />
                    </Col>
                    <CnpjSinirModal
                        isOpen={isModalFinalDestinationOpen}
                        toggle={() => setIsModalFinalDestinationOpen(!isModalFinalDestinationOpen)}
                        apiData={apiDataFinalDestination}
                        onSelect={handleSelectFinalDestination}
                    />
                </Row>
            );
        }
    };

    const renderTransshipmentContent = () => {
        if (transshipment != null) {
            return renderTransshipment(!props.hasFinalDestinationAuthorities, () => setTransshipment(undefined), transshipment, mtr);
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.transshipment')}</div>
                        <DropdownSearch
                            data={mapTransshipmentToSearch(props)}
                            getData={value => getTransshipments(value)}
                            onClickItem={item => setMtrTransshipment(item)}
                            title={'Selecione o transbordo'}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            filterLocalData
                            disableOpen={props.hasTransshipmentAuthorities}

                        />
                    </Col>
                </Row>
            );
        }
    };

    const renderUnitTransshipmentContent = () => {
        if (unitTransshipment) {
            return renderUnitsTransporter(
                !props.hasFinalDestinationAuthorities,
                clearUnitsTransshipment,
                unitTransshipment
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {transshipment !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearch(props?.unitsTransshipment)}
                                getData={value => getUnitsTransshipment(value)}
                                onClickItem={item => setUnitWithTransshipment(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };


    const renderResidue = (residue: IMtrResidue, index: number) => {
        return (
            <div className="signature-generator__items--frame">
                {residue.sourceResidue != null ? (
                    renderMtrResidue(true, () => removeSourceResidue(index), residue.sourceResidue)
                ) : (
                    <Row style={{ marginBottom: '30px' }}>
                        <Col md={{ size: 6 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.specific-waste')}</div>
                            <div className="">
                                <DropdownSearch
                                    data={mapSourceResiduesToSearch(props.sourceResidues as IVehicle[])}
                                    getData={value => getSourceResidues(value)}
                                    onClickItem={item => {
                                        props.getMtrResidue(item.id);
                                        setResidueIndex(index);
                                    }}
                                    title={translate('mtr-manage.residue')}
                                    style={{ backgroundColor: '#f6f6f6' }}
                                    notAlphabeticalOrder
                                    showInsiderSelect
                                    filterLocalData


                                />
                            </div>
                        </Col>
                    </Row>
                )}

            </div>
        );
    };

    const renderForm = () => {

        return (
            <div className="signature-generator__items" style={{ paddingTop: '39.5px' }}>
                <Container>
                    {renderProducerContent()}
                    {renderUnitContent()}
                    {renderTransporterContent()}
                    {renderUnitTransporterContent()}
                    {renderTransshipmentContent()}
                    {renderUnitTransshipmentContent()}
                    {renderFinalDestinationContent()}
                    {renderUnitFinalDestinyContent()}
                    {residues?.map((it, index) => renderResidue(it, index))}
                    {residues?.findIndex(it => it.sourceResidue != null) > -1 && (transshipment?.id === undefined || (transshipment?.id != undefined && residues.length < 1)) && (
                        <Row style={{ marginBottom: '60px' }}>
                            <Col md={{ size: 10, offset: 1 }}>
                                <div className="signature-generator--component">
                                    <div className="signature-generator--component-img" onClick={addSourceResidue} />
                                    <div className="signature-generator--component-text">Adicionar mais resíduo</div>
                                </div>
                            </Col>
                        </Row>
                    )}

                </Container>
            </div>
        );
    };

    const goToEdit = () => {
        props.location.pathname.split('/').map(url => {
            if (ProfileType?.[url.toUpperCase()]) {
                history.push({
                    pathname: `/${ProfileType?.[url.toUpperCase()]}/mtr-model/edit`,
                    state: { id: mtrModelId },
                })
            }

        })
    }

    const showEditButton = () => (

        <div className="signature-generator__menu color-edit" onClick={() => goToEdit()}>
            <Translate contentKey={'mtr-signature.edit'} />
        </div>

    )
    const showDeleteButton = () => (

        <div className="signature-generator__menu color-delete" onClick={() => props.deleteMtrModel(mtrModelId)}>
            <Translate contentKey={'mtrModel.deleteModel'} />
        </div>

    )


    const mapType = () => {
        const objects = Object.keys(MeasurementType).map(key => {
            return { name: translate(`enums.measurementTypeAbbreviation.${key}`), keyEnum: key };
        });
        return objects.sort((a, b) => a.name.localeCompare(b.name));
    };

    const search = () => { };

    const renderContent = () => {

        switch (true) {
            case location.pathname.includes('new') || location.pathname.includes('edit'):
                return (
                    <>
                        <div className="signature-generator__items" style={{ paddingBottom: '0px', display: 'flex', justifyContent: 'center' }} >


                            <CustomTextField
                                id={`modelName`}
                                onChange={(text: string) => {
                                    setModelName(text)
                                    if (modelName !== '') {
                                        setModelNameError(false)
                                    } else {
                                        setModelNameError(true)
                                    }
                                }
                                }
                                style={{ width: '30%' }}
                                value={modelName}
                                label={translate('mtrModel.modelName')} // att p modelName
                                placeholder={translate('mtrModel.modelName')} // att p modelName
                                error={modelNameError}
                                errorText={'Campo Obrigatório'}
                            />
                        </div>
                        {renderForm()}
                        <Container>
                            <Row className="justify-center">
                                <Col md="3">
                                    <div className="mtr-button-container">
                                        <Button className={'button fourth button-fourth'} type={'submit'} onClick={() => saveMtrModel()}>
                                            <Translate contentKey={'mtrModel.saveModel'} />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </>
                )
            case location.pathname.includes('details'):
                return (
                    <>
                        <MtrModelView
                            mtr={{
                                producer: producer,
                                unitProducer: unit,
                                unitFinalDestiny,
                                unitTransporter,
                                unitTransshipment,
                                transporter: transporter,
                                transshipment: transshipment,
                                finalDestination: finalDestination,
                                mtrResidues: residues.filter(it => it.sourceResidue != null),
                            }}
                            modelName={modelName}
                        />
                    </>
                )

            default:
                <div />
        }


    }

    return (

        <>{!isLoading &&

            <div className={editFlag || mtr == null ? 'signature-generator-collapse' : 'signature-generator'}>
                <div className="signature-generator__menu--back">
                    <Container>
                        <Row>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <div className="confirmation--back">
                                    <div className="confirmation--back-icon" />
                                    <span onClick={() => {
                                        props.history.goBack()
                                        props.resetMtr()
                                    }}>
                                        <Translate contentKey={'mtr-signature.back'} />
                                    </span>
                                </div>
                                {location.pathname.includes('details') && <div>{showEditButton()}</div>}
                                {location.pathname.includes('details') && <div>{showDeleteButton()}</div>}
                            </div>
                        </Row>
                    </Container>
                </div>


                {renderContent()}



            </div>
        }</>
    );
};

const mapStateToProps = (root: IRootState) => ({
    units: root.producerUnits.units,
    unitsProducer: root.producerUnits.unitsProducer,
    unitsFinalDestination: root.producerUnits.unitsFinalDestination,
    unitsTransporter: root.producerUnits.unitsTransporter,
    unitsTransshipment: root.producerUnits.unitsTransshipment,
    mtrModelList: root.mtrModel.mtrModelList,
    mtrModel: root.mtrModel.mtrModel,
    getMtrModelListStatus: root.mtrModel.getMtrModelListStatus,
    getMtrModeltatus: root.mtrModel.getMtrModeltatus,
    createMtrModelStatus: root.mtrModel.createMtrModeltatus,
    updateMtrModelStatus: root.mtrModel.updateMtrModelStatus,
    deleteMtrModelStatus: root.mtrModel.deleteMtrModelStatus,
    residue: root.mtr.mtrResidue,
    producer: root.mtr.mtrProducer,
    producers: root.producer.producers,
    transporter: root.mtr.mtrTransporter,
    transporters: root.transport.transporters,
    transshipment: root.mtr.mtrTransshipment,
    transshipments: root.transshipment.transshipments,
    finalDestination: root.mtr.mtrFinalDestination,
    finalDestinations: root.finalDestination.finalDestinations,
    sourceResidues: root.sourceResidue.sourceResidues,
    totalItems: root.mtr.totalItems,

    hasTransporterAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSPORTER_ADMIN,
        AUTHORITIES.TRANSPORTER_USER,
    ]),
    hasTransshipmentAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSSHIPMENT_ADMIN,
        AUTHORITIES.TRANSSHIPMENT_USER,
    ]),
    hasProducerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]),
    hasFinalDestinationAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.FINAL_DESTINATION_ADMIN,
        AUTHORITIES.FINAL_DESTINATION_USER,
    ]),
    hasManagerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
    hasAnyUserAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_USER_AUTHORITIES),
    hasProducerUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_USER]),
    hasManagerUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_USER]),
    hasTransporterUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSPORTER_USER]),
    hasTransshipmentUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_USER]),
    hasFinalDestinationUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_USER]),
    integrationUsers: root.integrationUser.integrationUsers,
});

const mapDispatchToProps = {
    searchFinalDestinations,
    getMtrFinalDestination,
    searchTransshipments,
    getMtrTransshipment,
    searchSourceResidues,
    searchTransporters,
    getMtrTransporter,
    resetTransporters,
    searchProducers,
    getMtrProducer,
    searchVehicles,
    resetProducers,
    searchDrivers,
    getMtrResidue,
    resetResidues,
    resetVehicles,
    resetDrivers,
    resetStatus,
    resetMtr,
    createMtrModel,
    getMtrModel,
    updateMtrModel,
    deleteMtrModel,
    getSubsidiariesByHolding: getIntegrationUnits,
    getIntegrationUnitsProducer,
    getIntegrationUnitsFinalDestiny,
    getIntegrationUnitsTransporter,
    getIntegrationUnitsTransshipment,
    getIntegrationUsers,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MtrModelCreate);

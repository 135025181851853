import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { IEnvironmentalLicense } from 'app/shared/model/environmental-license.model';
import { EnvironmentalLicenseList } from 'app/components/environmental-license-form/list/environmental-license-list';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';

export interface IFinalDestinationRegisterDocumentationProps extends StateProps, DispatchProps {
  userFinalDestination: IUserFinalDestination;
  onNext: (userFinalDestination: IUserFinalDestination) => void;
  onBack: () => void;
}

export interface IFinalDestinationRegisterDocumentationState {
  enviromentalLicenses: IEnvironmentalLicense[];
}

export class FinalDestinationRegisterDocumentation extends React.Component<
  IFinalDestinationRegisterDocumentationProps,
  IFinalDestinationRegisterDocumentationState
> {
  constructor(props) {
    super(props);
    this.state = {
      enviromentalLicenses: this.props.userFinalDestination.finalDestination ? this.props.userFinalDestination.finalDestination.environmentalLicenses : [],
    };
  }

  onNextHandler = (environmentalLicensesUpdated: IEnvironmentalLicense[]) => {
    const userFinalDestinationUpdated = { ...this.props.userFinalDestination };
    userFinalDestinationUpdated.finalDestination.environmentalLicenses = environmentalLicensesUpdated;
    this.props.onNext(userFinalDestinationUpdated);
  };

  render() {
    const { enviromentalLicenses } = this.state;
    return (
      <div>
        <EnvironmentalLicenseList
          onNextHandler={this.onNextHandler}
          onBack={this.props.onBack}
          environmentalLicenses={enviromentalLicenses}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationRegisterDocumentation);

import React from 'react';
import { Switch } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Document from './document';
// import UserToken from './user-token';
import Country from './country';
import State from './state';
import City from './city';
import Address from './address';
import CnaeCode from './cnae-code';
import EnvironmentalLicense from './environmental-license';
import Authorization from './authorization';
import Producer from './producer';
import UserProducer from './user-producer';
import TransportLicense from './transport-license';
import Transporter from './transporter';
import UserTransporter from './user-transporter';
import Driver from './driver';
import FinalDestination from './final-destination';
import UserFinalDestination from './user-final-destination';
import LegalPerson from './legal-person';
import NaturalPerson from './natural-person';
import Manager from './manager';
import UserManager from './user-manager';
import Vehicle from './vehicle';
import Residue from './residue';
import SpecificResidue from './specific-residue';
import SourceResidue from './source-residue';
import Favorite from './favorite';
import Signature from './signature';
import ManagerProducerInvitation from './manager-producer-invitation';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}/document`} component={Document} />
      {/* <ErrorBoundaryRoute path={`${match.url}/user-token`} component={UserToken} /> */}
      <ErrorBoundaryRoute path={`${match.url}/country`} component={Country} />
      <ErrorBoundaryRoute path={`${match.url}/state`} component={State} />
      <ErrorBoundaryRoute path={`${match.url}/city`} component={City} />
      <ErrorBoundaryRoute path={`${match.url}/address`} component={Address} />
      <ErrorBoundaryRoute path={`${match.url}/cnae-code`} component={CnaeCode} />
      <ErrorBoundaryRoute path={`${match.url}/environmental-license`} component={EnvironmentalLicense} />
      <ErrorBoundaryRoute path={`${match.url}/authorization`} component={Authorization} />
      <ErrorBoundaryRoute path={`${match.url}/producer`} component={Producer} />
      <ErrorBoundaryRoute path={`${match.url}/user-producer`} component={UserProducer} />
      <ErrorBoundaryRoute path={`${match.url}environmental-license`} component={EnvironmentalLicense} />
      <ErrorBoundaryRoute path={`${match.url}/transport-license`} component={TransportLicense} />
      <ErrorBoundaryRoute path={`${match.url}/transporter`} component={Transporter} />
      <ErrorBoundaryRoute path={`${match.url}/user-transporter`} component={UserTransporter} />
      <ErrorBoundaryRoute path={`${match.url}/driver`} component={Driver} />
      <ErrorBoundaryRoute path={`${match.url}/final-destination`} component={FinalDestination} />
      <ErrorBoundaryRoute path={`${match.url}/user-final-destination`} component={UserFinalDestination} />
      <ErrorBoundaryRoute path={`${match.url}/legal-person`} component={LegalPerson} />
      <ErrorBoundaryRoute path={`${match.url}/natural-person`} component={NaturalPerson} />
      <ErrorBoundaryRoute path={`${match.url}/manager`} component={Manager} />
      <ErrorBoundaryRoute path={`${match.url}/user-manager`} component={UserManager} />
      <ErrorBoundaryRoute path={`${match.url}/vehicle`} component={Vehicle} />
      <ErrorBoundaryRoute path={`${match.url}/residue`} component={Residue} />
      <ErrorBoundaryRoute path={`${match.url}/favorite`} component={Favorite} />
      <ErrorBoundaryRoute path={`${match.url}/specific-residue`} component={SpecificResidue} />
      <ErrorBoundaryRoute path={`${match.url}/source-residue`} component={SourceResidue} />
      <ErrorBoundaryRoute path={`${match.url}/signature`} component={Signature} />
      <ErrorBoundaryRoute path={`${match.url}/manager-producer-invitation`} component={ManagerProducerInvitation} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;

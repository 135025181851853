import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import './payment-method.scss';
import { translate, Translate } from 'react-jhipster';
import lockSvg from '../../../../../../../content/images/lock-green.svg';
import SelectPaymentMethod from './select-payment-method/SelectPaymentMethod';
import AddNewCreditCard from './credit-card/AddNewCreditCard';
import { useHistory, useLocation } from 'react-router-dom';
import { realCoinMask } from 'app/shared/util/mask-utils';
import ICreditCard from 'app/shared/model/credit-card.model';
import PaymentMethodType from 'app/shared/model/enum/paymentMethodType';
import IBankSlip from 'app/shared/model/bank-slip.model';
import PlanService from 'app/services/PlanService';
import { ITypeSendPlan } from 'app/shared/model/plan.model';
import ButtonLoading from 'app/components/button-loding/ButtonLoading';
import TypesOfPlans from 'app/shared/model/enum/typeOfPlans';

export interface PremiumPlan {
    price: number;
    planName: string;
    creditCard?: ICreditCard;
    bankSlip?: IBankSlip;
    subscriptionId?: number;
}

const PaymentMethod = () => {
    const history = useHistory();
    const location = useLocation<PremiumPlan>();
    const { price, planName, subscriptionId } = location.state;

    const [isAddNewCard, setIsAddNewCard] = useState<boolean>(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodType>();
    const [bankSlip, setBankSlip] = useState<IBankSlip | null>(null);
    const [creditCard, setCreditCard] = useState<ICreditCard | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCloseAddNewCreaditCardComponent = () => {
        setSelectedPaymentMethod(PaymentMethodType.CREDIT_CARD);
        setIsAddNewCard(false);
    };

    const handleButtonContinue = () => {
        if (subscriptionId != null) {
            setIsLoading(true);

            let formatData: ITypeSendPlan = {
                subscription: { id: subscriptionId },
                billingType: bankSlip != null ? PaymentMethodType.BOLETO : PaymentMethodType.CREDIT_CARD,
                planType: TypesOfPlans.PREMIUM
            }

            if (creditCard != null) {
                formatData = { ...formatData, customerBillingInfo: { id: creditCard.id } }
            }

            PlanService.updatePlan(formatData).then(() => {
                setIsLoading(false);
                history.goBack();
            });
        } else {
            history.push('/full-register/purchase-summary', {
                price: price,
                planName: planName,
                creditCard: creditCard
            });
        }
    };

    return (
        <div className='plan--container' style={{ position: 'relative' }}>
            {isAddNewCard ? (
                <div>
                    <AddNewCreditCard
                        hadleCloseAddNew={() => handleCloseAddNewCreaditCardComponent()}
                    />
                </div>
            ) : (
                <SelectPaymentMethod
                    creditCard={creditCard}
                    bankSlip={bankSlip}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    handleAddNewCard={() => setIsAddNewCard(true)}
                    setBankSlip={setBankSlip}
                    setCreditCard={setCreditCard}
                />
            )}

            <div className='plan--container__footer'>
                <Container style={{ height: '100%' }}>
                    <Row style={{ height: '100%' }}>
                        <Col style={{ height: '100%' }}>
                            <div className='plan--container__footer--container' style={{ justifyContent: 'space-around' }}>
                                <div className='plan--container__footer--container-safe-buy' style={{ justifyContent: 'space-around' }}>
                                    <figure>
                                        <img src={lockSvg} alt='imagem cadeado verde' />
                                    </figure>
                                    <div className='plan--container__footer--container-safe-buy-banner'>
                                        <span className='plan--container__footer--container-safe-buy-banner-safe-buy'>
                                            <Translate contentKey={'register.select-payment.safeBuy'} />
                                        </span>
                                        <span className='plan--container__footer--container-safe-buy-banner-certificate'>
                                            <Translate contentKey={'register.select-payment.certificate'} />
                                        </span>
                                    </div>
                                </div>

                                <div className='plan--container__footer--container-plan'>
                                    <div className='plan--container__footer--container-plan-banner'>
                                        <span className='plan--container__footer--container-plan-banner-plan'>{planName}</span>
                                        <span className='plan--container__footer--container-plan-banner-local-currency'>
                                            {realCoinMask(price)}
                                            <Translate contentKey={'register.select-payment.month'} />
                                        </span>
                                    </div>
                                    <Col md='2' style={{ paddingRight: 0 }}>
                                        <ButtonLoading
                                            className="button fourth button-fourth"
                                            colorIconLoading="white"
                                            isDisabled={bankSlip == null && creditCard == null}
                                            textButton={translate('register.select-payment.continue')}
                                            isLoading={isLoading}
                                            onClick={() => handleButtonContinue()}
                                        />
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default PaymentMethod;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transporter-register-responsible.scss';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';
import ResponsibleFormComponent from 'app/components/responsible-form-component/responsible-form-component';
import { IUserGeneric } from 'app/shared/model/user-generic.model';

export interface ITransporterRegisterResponsibleProps extends StateProps, DispatchProps {
  userTransporter: IUserTransporter;
  onNext: (userTransporter: IUserTransporter) => void;
  onBack: () => void;
}

export interface ITransporterRegisterResponsibleState {}

export class TransporterRegisterResponsible extends React.Component<
  ITransporterRegisterResponsibleProps,
  ITransporterRegisterResponsibleState
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onNextHandler = (userTransporterGeneric: IUserGeneric) => {
    const userTransporter = { ...this.props.userTransporter };

    const userTransporterUpdated: IUserTransporter = {
      ...userTransporter,
      classCouncil: userTransporterGeneric.classCouncil,
      registrationNumber: userTransporterGeneric.registrationNumber,
      user: userTransporterGeneric.user,
    };

    this.props.onNext(userTransporterUpdated);
  };

  mapToUserGeneric = () => {
    const { userTransporter } = this.props;
    if (userTransporter != null) {
      const userTransporterGeneric: IUserGeneric = {
        user: userTransporter.user,
        classCouncil: userTransporter.classCouncil,
        registrationNumber: userTransporter.registrationNumber,
      };
      return userTransporterGeneric;
    }
    return {};
  };

  render() {
    return (
      <div>
        <ResponsibleFormComponent
          validationIgnore={
            new Map([
              ['classCouncil', true],
              ['registrationNumber', true],
            ])
          }
          userGeneric={this.mapToUserGeneric()}
          onNextHandler={this.onNextHandler}
          onBack={this.props.onBack}
          isResponsibleRequired
        />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterResponsible);

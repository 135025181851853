import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FinalDestinationRegisterMenu from './menu/final-destination-register-menu';

const Routes = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.url}`} component={FinalDestinationRegisterMenu} />
    </Switch>
  </>
);

export default Routes;

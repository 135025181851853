import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import './custom-cdf-manage.scss';
import CustomCustomCdfManageCard from 'app/components/custom-cdf-manage-card/custom-cdf-manage-card';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { generateCdfReport, getEntity as findCdf, reset as resetCdfStatus } from 'app/entities/cdf/cdf.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { CdfStatus } from 'app/shared/model/enum/cdfStatus';

interface ICustomCdfManageProps extends StateProps, DispatchProps, RouteComponentProps<{ cdfNumber: string }> {
    // TODO
}

class CustomCdfManage extends React.Component<ICustomCdfManageProps> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { findCdf, match } = this.props;
        findCdf(match.params.cdfNumber);
    }

    componentWillReceiveProps(nextProps: Readonly<ICustomCdfManageProps>) {
        if (nextProps.getCdfStatus === HttpRequestStatus.ERROR) this.redirectToCdfList();
    }

    isProducerOrFinalDestination = () => {
        const { hasFinalDestinationAuthorities } = this.props;
        return hasFinalDestinationAuthorities ? 'final-destination' : this.props.hasManagerAuthorities ? 'manager' : 'producer';
    };

    redirectToCdfList = () => {
        const { history, match } = this.props;
        history.push(`/${this.isProducerOrFinalDestination()}/cdf`);
    };

    redirectToSignCdf = () => {
        this.props.history.push(`${this.props.match.url}/signature`);
    };

    render() {
        const { hasFinalDestinationAuthorities, cdf, getCdfStatus, generateCdfReportStatus, generateCdfReport, match } = this.props;
        if (checkStatusOnGoing([getCdfStatus, generateCdfReportStatus])) return <Loading />;
        return (
            <div className="list__items" style={{ background: '#fafafa' }}>
                <div style={{ height: '48px' }} className="list__items--back">
                    <Container>
                        <Row>
                            <Col>
                                <div className="confirmation--back">
                                    <div style={{ display: 'flex', alignItems: 'center', flex: '0 0 50%' }}>
                                        <div className="confirmation--back-icon" style={{ marginTop: '-1px' }} onClick={this.redirectToCdfList} />
                                        <span onClick={this.redirectToCdfList}>
                                            <Translate contentKey={'mtr-signature.back'} />
                                        </span>
                                    </div>

                                    <div style={{ display: 'flex', flex: '0 0 50%', justifyContent: 'flex-end' }}>
                                        {cdf.cdfStatus === CdfStatus.APPROVED && (
                                            <button
                                                className="menu-button"
                                                style={{ marginRight: '16px' }}
                                                onClick={() => generateCdfReport(match.params.cdfNumber)}
                                            >
                                                <Translate contentKey={'cdf.button2'} />
                                            </button>
                                        )}
                                        {hasFinalDestinationAuthorities && cdf.finalDestinationSignature?.id == null && (
                                            <button className="menu-button-second" onClick={this.redirectToSignCdf}>
                                                <Translate contentKey={'cdf.button3'} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row>
                        <Col style={{ margin: 'auto' }}>
                            <CustomCustomCdfManageCard cdf={cdf || {}} />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (root: IRootState) => ({
    cdf: root.cdf.entity,
    getCdfStatus: root.cdf.findCdfStatus,
    generateCdfReportStatus: root.cdf.generateCdfReportStatus,
    hasManagerAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
    hasProducerAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER,]),
    hasFinalDestinationAuthorities: hasAnyAuthority(
        root.authentication.account.authorities,
        [AUTHORITIES.FINAL_DESTINATION_ADMIN, AUTHORITIES.FINAL_DESTINATION_USER,])
});

const mapDispatchToProps = {
    findCdf,
    resetCdfStatus,
    generateCdfReport
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(CustomCdfManage);

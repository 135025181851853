import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Authorization from '../generic/authorization/index';
import GenericInvite from '../generic/invite/generic-invite';
import MtrManage from '../generic/mtr/manage/mtr-manage';
import MtrList from '../generic/mtr/mtr-list';
import MtrSignatureStep from '../generic/mtr/signature/mtr-signature';
import TransshipmentFavoriteSummary from 'app/modules/transshipment/favorite/transshipment-favorite-summary';
import FavoriteTransporterList from 'app/modules/generic/favorite-list/favorite-transporter/favorite-transporter-list';
import TransporterDetails from 'app/modules/generic/details/transporter/transporter-details';
import FavoriteProducerList from 'app/modules/generic/favorite-list/favorite-producer/favorite-producer-list';
import ProducerDetails from 'app/modules/generic/details/producer/producer-details';
import FinalDestinationDetails from 'app/modules/generic/details/final-destination/final-destination-details';
import FavoriteFinalDestinationList
    from 'app/modules/generic/favorite-list/favorite-final-destination/favorite-final-destination-list';
import TransshipmentLicensesList from 'app/modules/transshipment/licenses/transshipment-licenses';
import TransshipmentResidues from './residues/transshipment-residues';
import TransshipmentResiduesAdd from './residues/transshipment-residues-add';
import StorageLicenseEdit from './licenses/edit/storage-license-edit';
import StorageLicenseDetails from './licenses/detail/license-details';
import CustomMtrReportCreate from 'app/components/custom-mtr-report-create/custom-mtr-report-create';
import MtrModelRoutes from '../generic/mtr-model/index';
import CustomMtrSignatureAll from 'app/components/custom-mtr-signature-all/custom-mtr-signature-all';
import CustomMtrComplementarList from 'app/components/custom-mtr-complementar-list/custom-mtr-complementar-list';


const Routes = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/mtr/manage`} component={MtrManage} />
        <Route path={`${match.url}/mtr/manage/signature`} component={MtrSignatureStep} />
        <Route exact path={`${match.url}/mtr`} component={MtrList} />
        <Route exact path={`${match.url}/mtr/mtr-complementar`} component={CustomMtrComplementarList} />
        <Route path={`${match.url}/mtr-model`} component={MtrModelRoutes} />
        <Route path={`${match.url}/mtr-report/create`} component={CustomMtrReportCreate} />
        <Route path={`${match.url}/mtr-signature/all`} component={CustomMtrSignatureAll} />
        <Route exact path={`${match.url}/licenses`} component={TransshipmentLicensesList} />
        <Route path={`${match.url}/licenses/edit`} component={StorageLicenseEdit} />
        <Route path={`${match.url}/licenses/new`} component={StorageLicenseEdit} />
        <Route path={`${match.url}/licenses/details`} component={StorageLicenseDetails} />
        <Route path={`${match.url}/authorizations`} component={Authorization} />
        <Route exact path={`${match.url}/summary`} component={TransshipmentFavoriteSummary} />
        <Route exact path={`${match.url}/summary/transporters`} component={FavoriteTransporterList} />
        <Route path={`${match.url}/summary/transporters/detail`} component={TransporterDetails} />
        <Route exact path={`${match.url}/summary/producers`} component={FavoriteProducerList} />
        <Route path={`${match.url}/summary/producers/detail`} component={ProducerDetails} />
        <Route exact path={`${match.url}/summary/final-destinations`} component={FavoriteFinalDestinationList} />
        <Route path={`${match.url}/summary/final-destinations/detail`} component={FinalDestinationDetails} />
        <Route path={`${match.url}/invite`} component={GenericInvite} />
        <Route exact path={`${match.url}/residues`} component={TransshipmentResidues} />
        <Route path={`${match.url}/residues/add`} component={TransshipmentResiduesAdd} />

    </Switch>
);

export default Routes;

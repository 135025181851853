import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import {Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getProducers} from 'app/entities/producer/producer.reducer';
import {getEntities as getManagers} from 'app/entities/manager/manager.reducer';
import {createEntity, getEntity, reset, updateEntity} from './manager-producer-invitation.reducer';

// tslint:disable-next-line:no-unused-variable

export interface IManagerProducerInvitationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IManagerProducerInvitationUpdateState {
  isNew: boolean;
  producerId: string;
  managerId: string;
}

export class ManagerProducerInvitationUpdate extends React.Component<
  IManagerProducerInvitationUpdateProps,
  IManagerProducerInvitationUpdateState
> {
  constructor(props) {
    super(props);
    this.state = {
      producerId: '0',
      managerId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getProducers();
    this.props.getManagers();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { managerProducerInvitationEntity } = this.props;
      const entity = {
        ...managerProducerInvitationEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/manager-producer-invitation');
  };

  render() {
    const { managerProducerInvitationEntity, producers, managers, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.managerProducerInvitation.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.managerProducerInvitation.home.createOrEditLabel">
                Create or edit a ManagerProducerInvitation
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : managerProducerInvitationEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="manager-producer-invitation-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="manager-producer-invitation-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="invitationStatusLabel" for="manager-producer-invitation-invitationStatus">
                    <Translate contentKey="resitrackApp.managerProducerInvitation.invitationStatus">Invitation Status</Translate>
                  </Label>
                  <AvInput
                    id="manager-producer-invitation-invitationStatus"
                    type="select"
                    className="form-control"
                    name="invitationStatus"
                    value={(!isNew && managerProducerInvitationEntity.invitationStatus) || 'PENDING'}
                  >
                    <option value="PENDING">{translate('resitrackApp.InvitationStatus.PENDING')}</option>
                    <option value="ACCEPTED">{translate('resitrackApp.InvitationStatus.ACCEPTED')}</option>
                    <option value="REFUSED">{translate('resitrackApp.InvitationStatus.REFUSED')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="manager-producer-invitation-producer">
                    <Translate contentKey="resitrackApp.managerProducerInvitation.producer">Producer</Translate>
                  </Label>
                  <AvInput
                    id="manager-producer-invitation-producer"
                    type="select"
                    className="form-control"
                    name="producer.id"
                    value={isNew ? producers[0] && producers[0].id : managerProducerInvitationEntity.producer.id}
                    required
                  >
                    {producers
                      ? producers.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="manager-producer-invitation-manager">
                    <Translate contentKey="resitrackApp.managerProducerInvitation.manager">Manager</Translate>
                  </Label>
                  <AvInput
                    id="manager-producer-invitation-manager"
                    type="select"
                    className="form-control"
                    name="manager.id"
                    value={isNew ? managers[0] && managers[0].id : managerProducerInvitationEntity.manager.id}
                    required
                  >
                    {managers
                      ? managers.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/manager-producer-invitation" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  producers: storeState.producer.entities,
  managers: storeState.manager.entities,
  managerProducerInvitationEntity: storeState.managerProducerInvitation.entity,
  loading: storeState.managerProducerInvitation.loading,
  updating: storeState.managerProducerInvitation.updating,
  updateSuccess: storeState.managerProducerInvitation.updateSuccess,
});

const mapDispatchToProps = {
  getProducers,
  getManagers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerProducerInvitationUpdate);

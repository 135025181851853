import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserProducer from './user-producer';
import UserProducerDetail from './user-producer-detail';
import UserProducerUpdate from './user-producer-update';
import UserProducerDeleteDialog from './user-producer-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserProducerUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserProducerUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserProducerDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserProducer} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={UserProducerDeleteDialog} />
  </>
);

export default Routes;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './producer-register-responsible.scss';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import ResponsibleFormComponent from 'app/components/responsible-form-component/responsible-form-component';
import { IUserGeneric } from 'app/shared/model/user-generic.model';
import { GeneratorCompanyType } from 'app/shared/model/producer.model';

export interface IProducerRegisterResponsibleProps extends StateProps, DispatchProps {
  userProducer: IUserProducer;
  onNext: (userProducer: IUserProducer) => void;
  onBack: () => void;
  isNew?: boolean;
}

export interface IProducerRegisterResponsibleState { }

export class ProducerRegisterResponsible extends React.Component<IProducerRegisterResponsibleProps, IProducerRegisterResponsibleState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onNextHandler = (userProducerGeneric: IUserGeneric) => {
    const userProducer = { ...this.props.userProducer };

    const userProducerUpdated: IUserProducer = {
      ...userProducer,
      classCouncil: userProducerGeneric.classCouncil,
      registrationNumber: userProducerGeneric.registrationNumber,
      user: userProducerGeneric.user,
    };

    this.props.onNext(userProducerUpdated);
  };

  mapToUserGeneric = () => {
    const { userProducer } = this.props;
    if (userProducer != null) {
      const userProducerGeneric: IUserGeneric = {
        user: userProducer.user,
        classCouncil: userProducer.classCouncil,
        registrationNumber: userProducer.registrationNumber,
      };
      return userProducerGeneric;
    }
    return {};
  };

  render() {
    return (
      <div>
        <ResponsibleFormComponent
          isNew={this.props.isNew}
          userGeneric={this.mapToUserGeneric()}
          onNextHandler={this.onNextHandler}
          onBack={this.props.onBack}
          validationIgnore={
            new Map([
              ['classCouncil', true],
              ['registrationNumber', true],
            ])
          }
          ignoreClassCouncilAndRegistrationNumber={this.props.userProducer.producer.generatorCompanyType === GeneratorCompanyType.NATURAL}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerRegisterResponsible);

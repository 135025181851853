import AlertModal from 'app/components/alert-modal/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS } from 'app/config/constants';
import {
  getInvitationByProducer,
  linkProducer,
  reset,
  updateInvitation
} from 'app/modules/manager/manager-link-reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IManagerProducerInvitation } from 'app/shared/model/manager-producer-invitation.model';
import { PersonType } from 'app/shared/model/manager.model';
import { IProducerLinkVM } from 'app/shared/model/producer-link-vm';
import { IRootState } from 'app/shared/reducers';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './producer-manager-link.scss';

export interface IProducerManagerLinkProps extends StateProps, DispatchProps, RouteComponentProps<{ invitationId: string }> { }

export interface IProducerManagerLinkState {
  showErrorModal: boolean;
  invitation: IManagerProducerInvitation;
}

export class ProducerManagerLink extends React.Component<IProducerManagerLinkProps, IProducerManagerLinkState> {
  constructor(props: Readonly<IProducerManagerLinkProps>) {
    super(props);
    this.state = {
      showErrorModal: false,
      invitation: null,
    };
  }

  async componentDidMount() {
    const { invitationId } = this.props.match.params;
    if (invitationId == null) {
      this.props.history.goBack();
    }
    this.props.reset();
    this.props.getInvitationByProducer(invitationId);
  }

  componentWillReceiveProps(newProps: IProducerManagerLinkProps) {
    if (newProps.getInvitationStatus === HttpRequestStatus.SUCCESS && newProps.invitation != null) {
      this.setState({
        invitation: newProps.invitation,
      });
    }
    if (newProps.addProducerStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    } else if (newProps.addProducerStatus === HttpRequestStatus.ERROR) {
      this.props.reset();
    }

    if (newProps.addProducerStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onBackHandler = () => {
    this.props.history.replace(`/manager/producers`);
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.linkProducer',
        buttonUrl: '/producer/mtr',
      },
    });
  };

  goToRefuseRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/refuse-page',
      state: {
        statusImage: ALERT_ICONS.MANAGER_RED,
        alertMessage: 'alert.linkProducerRefuse',
        buttonUrl: '/producer/mtr',
      }
    })
  }

  addProducer = () => {
    const { invitation } = this.state;

    const producerLink: IProducerLinkVM = {
      invitationId: invitation.id,
    };

    this.props.linkProducer(producerLink);
  };

  render() {
    const { addProducerStatus } = this.props;
    const { invitation } = this.state;
    if (checkStatusOnGoing([addProducerStatus])) {
      return <Loading />;
    }
    return (
      <div>
        <div style={{ paddingTop: '70px' }}>
          <BackHeaderComponent hasButton={false} onBack={this.onBackHandler} />
          <h1>{translate('manager.producer.link.title')}</h1>
          {invitation && invitation.manager && (
            <div>
              <AlertModal
                showModal={this.state.showErrorModal}
                buttonTwoActionMessage={'entity.action.ok'}
                buttonTwoAction={this.toggleErrorModal}
                statusImage="bag-error.svg"
                alertMessage={'errorMessages.linkProducer'}
              />

              <AlertModal
                showModal={!this.state.showErrorModal}
                hasTwoButtons
                buttonOneActionMessage={'manager.producer.link.no'}
                buttonOneAction={this.goToRefuseRegisterAlertPage}
                // buttonOneAction={() => this.props.history.push('/producer/mtr')}
                buttonTwoActionMessage={'manager.producer.link.yes'}
                buttonTwoAction={this.addProducer}
                statusImage="manager-green.svg"
                alertMessage={'manager.producer.link.confirm'}
                params={{
                  companyName:
                    invitation.manager.personType === PersonType.LEGAL
                      ? invitation.manager.legalPerson.companyName
                      : invitation.manager.naturalPerson.name,
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  addProducerStatus: root.managerlink.addProducerStatus,
  fetchAllProducersStatus: root.managerlink.fetchAllProducersStatus,
  producers: root.managerlink.producers,
  getInvitationStatus: root.managerlink.getInvitationStatus,
  updateInvitationStatus: root.managerlink.updateInvitationStatus,
  invitation: root.managerlink.invitation,
});

const mapDispatchToProps = {
  linkProducer,
  reset,
  getInvitationByProducer,
  updateInvitation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerManagerLink);

import AlertModal from 'app/components/alert-modal/alert-modal';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import RadioFieldMeds from 'app/components/select-field/select-field';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getIntegrationUser, reset, saveIntegrationUser } from '../user-integration.reducer';
import { Col, Row } from 'reactstrap';
import InlineSelect from 'app/components/custom-select-field/custom-select-field';
import { maskCnpj, maskCnpjOrCpf, maskCPF } from 'app/shared/util/mask-utils';
import { IIntegrationUser } from 'app/shared/model/integration-user.model';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import { checkStatusOnGoing, translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import StringUtils from 'app/shared/util/string-utils';
import { validateCNPJ, validateCPF, validateObject, validatePassword } from 'app/modules/validation/validation-constants';
import { getIntegrationUnits } from 'app/modules/producer/units/producer-units-reducer';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { IUnit, mapUnitToSearchUserIntegration } from 'app/shared/model/unit';

export interface IUserProfileDataEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IUserProfileDataEditState {
  id: number;
  firstLogin: string;
  secondLogin: string;
  firstLoginError: ValidationResult;
  secondLoginError: ValidationResult;
  typeError: ValidationResult;
  passwordError: ValidationResult;
  type: string;
  password: string;
  showSuccess: boolean;
  isLoading: boolean;
  showErrorModal: boolean;
  selectedUnit: IUnit;
}

export class UserIntegrationNew extends React.Component<IUserProfileDataEditProps, IUserProfileDataEditState> {
  constructor(props: Readonly<IUserProfileDataEditProps>) {
    super(props);
    this.state = {
      id: null,
      firstLogin: null,
      secondLogin: null,
      type: null,
      password: '',
      isLoading: true,
      showErrorModal: false,
      showSuccess: false,
      selectedUnit: null,
      firstLoginError: ValidationResultInstance,
      secondLoginError: ValidationResultInstance,
      typeError: ValidationResultInstance,
      passwordError: ValidationResultInstance
    };
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.getIntegrationUnits(id);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.errorMessage != null) {
      this.setState({
        isLoading: false
      });
    }

    if (newProps.updateSuccess) {
      this.toggleSuccessModal();
      this.props.reset();
    }

    this.setState({
      isLoading: false
    });
  }

  onNextClick = () => {
    if (this.onValidateAll()) {
      return;
    }
    const user: IIntegrationUser = {
      ...this.state,
      firstLogin: StringUtils.removeMask(this.state.firstLogin),
      secondLogin: StringUtils.removeMask(this.state.secondLogin),
      unit: this.state.selectedUnit,
    };

    console.log('Informações antes de chamar saveIntegrationUser:', user);

    this.props.saveIntegrationUser(user);
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  toggleSuccessModal = () => {
    this.setState({
      showSuccess: !this.state.showSuccess,
    });
  };

  onBackHandler = () => {
    this.props.history.goBack();
  };

  onGoBack = () => {
    this.props.history.push('/me/profile/user-integration');
  };


  onValidateFirstLogin = () => {
    const { firstLogin } = this.state;
    let validate = null;
    if (StringUtils.removeMask(firstLogin).length > 11) {
      validate = validateCNPJ(firstLogin);
    } else {
      validate = validateCPF(firstLogin);
    }
    this.setState({
      firstLoginError: validate,
    });
    return validate.hasError;
  };

  onValidateSecondLogin = () => {
    const { secondLogin } = this.state;
    const validate = validateCPF(secondLogin);
    this.setState({
      secondLoginError: validate,
    });
    return validate.hasError;
  };


  onValidateType = () => {
    const { type } = this.state;
    const validate = validateObject(type);
    this.setState({
      typeError: validate,
    });
    return validate.hasError;
  };

  onValidatePassword = () => {
    const { password } = this.state;
    const validate = validatePassword(password);
    this.setState({
      passwordError: validate,
    });
    return validate.hasError;
  };

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateFirstLogin()) {
      hasError = true;
    }
    if (this.onValidateSecondLogin()) {
      hasError = true;
    }
    if (this.onValidateType()) {
      hasError = true;
    }
    if (this.onValidatePassword()) {
      hasError = true;
    }

    return hasError;
  };

  onClickUnit = (selectedUnit) => {
    this.setState({
      selectedUnit: selectedUnit
    });
  };

  render() {
    const {
      firstLogin,
      secondLogin,
      type,
      password,
      showErrorModal,
      showSuccess,
      firstLoginError,
      secondLoginError,
      typeError,
      passwordError
    } = this.state;
    if (this.state.isLoading) {
      return (<div />)
    }

    const types = [{ value: 'FEPAM', label: 'FEPAM (RS)' },
    { value: 'SINIR', label: 'SINIR (NACIONAL)' },
    { value: 'IMA', label: 'IMA (SC)' },
    { value: 'SIGOR', label: 'SIGOR (SP)' },
    { value: 'FEAM', label: 'FEAM (MG)' },
    { value: 'INEA', label: 'INEA (RJ)' }]

    if (checkStatusOnGoing([this.props.fetchIntegrationUserStatus, this.props.updateIntegrationUserStatus])) {
      return <Loading />;
    }

    return (
      <div className="new-user__items">
        <BackHeaderComponent onBack={this.onGoBack} />
        <div style={{ marginBottom: '35px' }}>
          <h1>
            <span>{translate('profile.manage-users.integration-user')}</span>
          </h1>
          <div className='integration-user-alert'>
            <p >{translate('profile.manage-users.integration-user-alert')}</p>
            <p >{translate('profile.manage-users.integration-user-alert1')}</p>
          </div>
        </div>
        <Row>
          <Col style={{ margin: 'auto' }} md="4">
            <CustomTextField
              id={'firstLogin'}
              style={{ marginTop: '10px' }}
              value={firstLogin}
              onChange={(text: string) =>
                this.setState({
                  firstLogin: text,
                })
              }
              onMask={maskCnpjOrCpf}
              label={translate('global.account.integration.firstLogin')}
              placeholder={translate('global.account.integration.firstLogin')}
              error={firstLoginError.hasError}
              errorText={translateErrorMessage(firstLoginError.errorMessage)}
              onBlur={this.onValidateFirstLogin}
            />
            <CustomTextField
              id={'secondLogin'}
              style={{ marginTop: '10px' }}
              value={this.state.secondLogin}
              onChange={(text: string) =>
                this.setState({
                  secondLogin: text,
                })
              }
              onMask={maskCPF}
              error={secondLoginError.hasError}
              errorText={translateErrorMessage(secondLoginError.errorMessage)}
              label={translate('global.account.integration.secondLogin')}
              placeholder={translate('global.account.integration.secondLogin')}
              onBlur={this.onValidateSecondLogin}
            />
            <InlineSelect
              placeholder={'Tipo'}
              options={types}
              onChange={(text) => {
                this.setState({
                  type: text,
                })
              }}
              startIndex={0}
              error={typeError.hasError}
              errorMessage={translateErrorMessage(typeError.errorMessage)}
              selectedOption={type}
            />
            <DropdownSearch
              title={'Selecione a Unidade de acesso do sistema estadual/federal'}
              style={{ backgroundColor: '#f6f6f6', marginTop: '10px' }}
              data={mapUnitToSearchUserIntegration(this.props.units)}
              getData={(item) => { }}
              onClickItem={this.onClickUnit}
              showInsiderSelect
              notAlphabeticalOrder
              filterLocalData
            />
            <CustomTextField
              id={'password'}
              style={{ marginTop: '10px' }}
              value={password}
              onChange={(text: string) =>
                this.setState({
                  password: text,
                })
              }
              isPassword
              label={translate('global.account.integration.insertPassword')}
              placeholder={translate('global.account.integration.insertPassword')}
              error={passwordError.hasError}
              errorText={translateErrorMessage(passwordError.errorMessage)}
              onBlur={this.onValidatePassword}
            />
          </Col>
        </Row>
        <NextCancelButton
          onCancelClick={() => this.props.history.goBack()}
          onNextClick={this.onNextClick}
          nextButtonName={translate('entity.action.save')}
          noArrow
        />

        <AlertModal
          showModal={showSuccess}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.onBackHandler}
          statusImage="garbage.svg"
          alertMessage="alert.userIntegrationCreate"
        />
        <AlertModal
          showModal={showErrorModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.account"
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  integrationUser: root.integrationUser.integrationUser,
  units: root.producerUnits.units,
  updateSuccess: root.integrationUser.updateSuccess,
  fetchIntegrationUserStatus: root.integrationUser.fetchIntegrationUserStatus,
  updateIntegrationUserStatus: root.integrationUser.updateIntegrationUserStatus,
  errorMessage: root.integrationUser.errorMessage,
});

const mapDispatchToProps = {
  saveIntegrationUser,
  getIntegrationUser,
  getIntegrationUnits,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserIntegrationNew);

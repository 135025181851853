import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import moment from 'moment';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './multi-date-picker-component.scss';

class ptBrUtils extends DateFnsUtils {
    getWeekdays() {
        return ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    }
}

export interface IMultiDatePickerComponentProps {
    dateFormat: string;
    dateSelected: string[]; // Agora é um array de strings
    placeholder?: string;
    error?: boolean;
    errorMessage?: string;
    onDateChange: (dates: string[]) => void; // Recebe um array de strings
    minDate?: string;
    maxDate?: string;
}

export default class MultiDatePickerComponent extends React.Component<IMultiDatePickerComponentProps> {
    constructor(props: IMultiDatePickerComponentProps) {
        super(props);
    }

    onDateChange = (date: Date) => {
        const { dateSelected, onDateChange } = this.props;
        const formattedDate = moment(date).format('YYYY-MM-DD');

        // Adiciona ou remove a data do array
        const newDates = dateSelected.includes(formattedDate)
            ? dateSelected.filter(d => d !== formattedDate) // Remove a data se já estiver no array
            : [...dateSelected, formattedDate]; // Adiciona a nova data

        onDateChange(newDates); // Atualiza o array de datas
    };

    render() {
        const { dateFormat, dateSelected, placeholder, error, errorMessage, minDate, maxDate } = this.props;

        const parsedMinDate = minDate ? moment(minDate, 'YYYY-MM-DD').toDate() : undefined;
        const parsedMaxDate = maxDate ? moment(maxDate, 'YYYY-MM-DD').toDate() : undefined;
        const formattedDates = dateSelected.length > 1
            ? dateSelected.map(date => moment(date).format(dateFormat)).join(', ') // Formata as datas com o formato definido
            : '';

        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 35%' }}>
                <div className="datepicker__items">
                    <MuiPickersUtilsProvider utils={ptBrUtils} locale={ptBr}>
                        <DatePicker
                            autoOk={false}
                            label={placeholder}
                            value={dateSelected.length > 0 ? moment(dateSelected[0]).toDate() : null}
                            format="dd/MM/yyyy"
                            variant="inline"
                            inputVariant="filled"
                            className="form-control input-field datepicker__items--input"
                            onChange={this.onDateChange}
                            minDate={parsedMinDate}
                            maxDate={parsedMaxDate}
                            disablePast
                            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                                const isSelected = dateSelected.includes(moment(day).format('YYYY-MM-DD'));
                                const isDisabled = moment(day).isBefore(moment(), 'day');
                                const style = {
                                    backgroundColor: isSelected ? '#30ab64' : 'transparent', // Altera a cor se for selecionada ou desabilitada
                                    color: isSelected ? '#FFF' : isDisabled ? '#b0b0b0' : '#000', // Altera a cor do texto se for selecionada ou desabilitada
                                    pointerEvents: isDisabled ? 'none' : 'auto', // Desabilita interações se a data estiver apagada
                                };

                                return React.cloneElement(dayComponent, {
                                    style,
                                });
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <div className="datepicker__items--img" />
                </div>
                {dateSelected.length > 1 && (
                    <div className="selected-dates">
                        <label>Datas selecionadas:</label>
                        <div className="dates-display">
                            {formattedDates || ''}
                        </div>
                    </div>
                )}
                {error && <span className="datepicker__error">{errorMessage}</span>}
            </div>
        );
    }
}

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate } from 'react-jhipster';
import './personal-data.scss';

export const MtrPersonalData = () => {
  return (
    <div className="personal-data__items">
      <div className="personal-data__items--back">
        <Container>
          <Row>
            <Col md="10">
              <div className="confirmation--back">
                <div className="confirmation--back-icon" />
                <span>
                  <Translate contentKey={'mtr-signature.back'} />
                </span>
              </div>
            </Col>
            <Col md="2">
              <div className="license-name__items--edit">
                <Translate contentKey={'mtr-signature.edit'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="container">
        <Row>
          <Col md="2">
            <div className="personal-data__items--sidebar">
              <div className="personal-data__items--sidebar-title">
                <Translate contentKey={'profile.personal-data.profile'} />
              </div>
              <div className="personal-data__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.personal-data'} />
              </div>
              <div className="personal-data__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.digital-signature'} />
              </div>
              <div className="personal-data__items--sidebar-subtitle">
                <Translate contentKey={'profile.personal-data.manage-users'} />
              </div>
            </div>
          </Col>
          <Col
            md="9"
            style={{
              marginLeft: '-62px',
              paddingLeft: '78px',
            }}
          >
            <div className="personal-data__items--title"><Translate contentKey={'profile.personal-data.title'}/></div>
            <div className="personal-data__items--component">
              <div className="personal-data__items--title-second">Nome do administrador</div>
              <div className="personal-data__items--text">Fulano</div>
            </div>
            <div className="personal-data__items--component">
              <div className="personal-data__items--title-second">Email</div>
              <div className="personal-data__items--text">fulano@lorem.com</div>
            </div>
            <div className="personal-data__items--component">
              <div className="personal-data__items--title-second">Senha</div>
              <div className="personal-data__items--text">******</div>
            </div>
            <div className="personal-data__items--component">
              <div className="personal-data__items--title-second">Perfil</div>
              <div className="personal-data__items--text">Admin</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MtrPersonalData;

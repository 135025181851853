import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './license-name.scss';

export const MtrLicenseName = () => {
  return (
    <div className="license-name__items">
      <div className="license-name__items--back">
        <Container>
          <Row>
            <Col md="10">
              <div className="confirmation--back">
                <div className="confirmation--back-icon" />
                <span>
                  <Translate contentKey={'mtr-signature.back'} />
                </span>
              </div>
            </Col>
            <Col md="2">
              <div className="license-name__items--edit">
                <Translate contentKey={'mtr-signature.edit'} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ marginBottom: '35px' }}>
        <h1>{translate('mtr-transporter.license.title-second')}</h1>
      </div>
      <Row>
        <Col md="6" style={{ margin: 'auto' }}>
          <div className="license-name__items--component">
            <div className="license-name__items--title">Tipo de licença</div>
            <div className="license-name__items--text">Autorização de transporte</div>
          </div>
          <div className="license-name__items--component">
            <div className="license-name__items--title">Orgão licenciador</div>
            <div className="license-name__items--text">Nome do orgão</div>
          </div>
          <div className="license-name__items--component">
            <div className="license-name__items--title">Cidade</div>
            <div className="license-name__items--text">Curitiba</div>
          </div>
          <div className="license-name__items--component">
            <div className="license-name__items--title">Data de vencimento da licença</div>
            <div className="license-name__items--text">00/00/0000</div>
          </div>
          <div className="license-name__items--subtitle">{translate('mtr-transporter.license.subtitle-second')}</div>
          <div className="license-name__items--component-second">
            <div className="license-name__items--title">Licença de transporte</div>
            <div style={{display:'flex'}}>
              <div className="license-name__items--img" />
              <div className="license-name__items--text">Nome-do-arquivo.pdf</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MtrLicenseName;

import { IState } from 'app/shared/model/state.model';

export interface ICity {
  id?: number;
  name?: string;
  active?: boolean;
  state?: IState;
}

export const defaultValue: Readonly<ICity> = {
  active: false,
};

import { IDocument } from 'app/shared/model/document.model';
import { IProducer } from 'app/shared/model/producer.model';
import { ISourceResidue } from './source-residue.model';

export enum MeasurementType {
  KG = 'KG',
  TONS = 'TONS',
  CUBIC_METERS = 'CUBIC_METERS',
  UNITS = 'UNITS',
  LITERS = 'LITERS',
}

export enum ResidueType {
  TOXIC = 'TOXIC',
}

export interface IAuthorization {
  id?: number;
  amount?: number;
  amountReceipt?: number;
  expirationDate?: string;
  measurementType?: MeasurementType;
  sourceResidue?: ISourceResidue;
  authorizationDocument?: IDocument;
  producer?: IProducer;
}

export const defaultValue: Readonly<IAuthorization> = {};

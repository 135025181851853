import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getProducers } from 'app/entities/producer/producer.reducer';
import { getEntities as getManagers } from 'app/entities/manager/manager.reducer';
import { getEntities as getFinalDestinations } from 'app/entities/final-destination/final-destination.reducer';
import { getEntities as getTransporters } from 'app/entities/transporter/transporter.reducer';
import { createEntity, getEntity, reset, updateEntity } from './residue.reducer';

// tslint:disable-next-line:no-unused-variable

export interface IResidueUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IResidueUpdateState {
  isNew: boolean;
  producerId: string;
  managerId: string;
  finalDestinationId: string;
  transporterId: string;
}

export class ResidueUpdate extends React.Component<IResidueUpdateProps, IResidueUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      producerId: '0',
      managerId: '0',
      finalDestinationId: '0',
      transporterId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getProducers();
    this.props.getManagers();
    this.props.getFinalDestinations();
    this.props.getTransporters();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { residueEntity } = this.props;
      const entity = {
        ...residueEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/residue');
  };

  render() {
    const { residueEntity, producers, managers, finalDestinations, transporters, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.residue.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.residue.home.createOrEditLabel">Create or edit a Residue</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : residueEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="residue-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="residue-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="codeLabel" for="residue-code">
                    <Translate contentKey="resitrackApp.residue.code">Code</Translate>
                  </Label>
                  <AvField id="residue-code" type="text" name="code" />
                </AvGroup>
                <AvGroup>
                  <Label id="residueNameLabel" for="residue-residueName">
                    <Translate contentKey="resitrackApp.residue.residueName">Residue Name</Translate>
                  </Label>
                  <AvField id="residue-residueName" type="text" name="residueName" />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="residue-description">
                    <Translate contentKey="resitrackApp.residue.description">Description</Translate>
                  </Label>
                  <AvField id="residue-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label id="measurementTypeLabel" for="residue-measurementType">
                    <Translate contentKey="resitrackApp.residue.measurementType">Measurement Type</Translate>
                  </Label>
                  <AvInput
                    id="residue-measurementType"
                    type="select"
                    className="form-control"
                    name="measurementType"
                    value={(!isNew && residueEntity.measurementType) || 'KG'}
                  >
                    <option value="KG">{translate('resitrackApp.MeasurementType.KG')}</option>
                    <option value="TONS">{translate('resitrackApp.MeasurementType.TONS')}</option>
                    <option value="CUBIC_METERS">{translate('resitrackApp.MeasurementType.CUBIC_METERS')}</option>
                    <option value="LITERS">{translate('resitrackApp.MeasurementType.LITERS')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="residueClassificationLabel" for="residue-residueClassification">
                    <Translate contentKey="resitrackApp.residue.residueClassification">Residue Classification</Translate>
                  </Label>
                  <AvInput
                    id="residue-residueClassification"
                    type="select"
                    className="form-control"
                    name="residueClassification"
                    value={(!isNew && residueEntity.residueClassification) || 'I'}
                  >
                    <option value="I">{translate('resitrackApp.ResidueClassification.I')}</option>
                    <option value="II_A">{translate('resitrackApp.ResidueClassification.II_A')}</option>
                    <option value="II_B">{translate('resitrackApp.ResidueClassification.II_B')}</option>
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/residue" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  producers: storeState.producer.entities,
  managers: storeState.manager.entities,
  finalDestinations: storeState.finalDestination.entities,
  transporters: storeState.transporter.entities,
  residueEntity: storeState.residue.entity,
  loading: storeState.residue.loading,
  updating: storeState.residue.updating,
  updateSuccess: storeState.residue.updateSuccess,
});

const mapDispatchToProps = {
  getProducers,
  getManagers,
  getFinalDestinations,
  getTransporters,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResidueUpdate);

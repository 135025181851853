import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IRootState } from 'app/shared/reducers';
import { AvForm } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {
    checkActivationKey,
    handleChangePasswordByActivationKey,
    reset
} from '../../password-reset/password-reset.reducer';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS } from 'app/config/constants';
import './change-password.scss';
import { validatePassword, validatePasswordMatch } from 'app/modules/validation/validation-constants';
import { fetchIsOnGoing, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';

export interface IChangePasswordProps extends StateProps, DispatchProps, RouteComponentProps<{ key: string }> {}

export interface IChangePasswordState {
  password: string;
  confirmPassword: string;
  passwordError: ValidationResult;
  confirmPasswordError: ValidationResult;
}

export class ChangePasswordPage extends React.Component<IChangePasswordProps, IChangePasswordState> {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      confirmPassword: null,
      passwordError: ValidationResultInstance,
      confirmPasswordError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    const key = this.props.match.params.key;
    this.props.checkActivationKey(key);
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.changePasswordStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.checkActivationStatus === HttpRequestStatus.ERROR) {
      this.goToErrorAlertPage();
      this.props.reset();
    }
  }

  goToSuccessAlertPage = () => {
    this.props.history.push({
      pathname: '/alert-page',
      state: { statusImage: ALERT_ICONS.PAPER_OK, alertMessage: 'alert.changePassword', buttonUrl: '/login' },
    });
  };

  goToErrorAlertPage = () => {
    this.props.history.push({
      pathname: '/alert-page',
      state: { statusImage: ALERT_ICONS.BAG_ERROR, alertMessage: 'alert.invalidActivationKey', buttonUrl: '/login' },
    });
  };

  handleValidSubmit = (event, values) => {
    if (this.validateAllFields()) {
      return;
    }
    const key = this.props.match.params.key;
    this.props.handleChangePasswordByActivationKey(key, this.state.password);
  };

  validateAllFields = () => {
    let hasError = false;
    if (this.onValidatePassword()) {
      hasError = true;
    }
    if (this.onValidateConfirmPassword()) {
      hasError = true;
    }
    return hasError;
  };

  updatePassword = e => {
    this.setState({
      password: e.target.value,
    });
  };

  onValidatePassword = () => {
    const { password } = this.state;
    const validate = validatePassword(password);
    this.setState({
      passwordError: validate,
    });
    return validate.hasError;
  };

  onValidateConfirmPassword = () => {
    const { password, confirmPassword } = this.state;
    const validate = validatePasswordMatch({ password, match: confirmPassword });
    this.setState({
      confirmPasswordError: validate,
    });
    return validate.hasError;
  };

  getResetForm = () => {
    const { password, passwordError, confirmPassword, confirmPasswordError } = this.state;
    return (
      <div className="password-reset__container">
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <CustomTextField
            id={'firstPassword'}
            style={{ marginTop: '10px' }}
            value={password}
            onChange={(text: string) =>
              this.setState({
                password: text,
              })
            }
            error={passwordError.hasError}
            errorText={passwordError.errorMessage ? translate(passwordError.errorMessage) : ''}
            isPassword
            label={translate('register.form.newpassword.placeholder')}
            placeholder={translate('register.form.newpassword.placeholder')}
            onBlur={this.onValidatePassword}
          />
          <CustomTextField
            id={'secondPassword'}
            value={confirmPassword}
            style={{ marginTop: '10px' }}
            onChange={(text: string) =>
              this.setState({
                confirmPassword: text,
              })
            }
            error={confirmPasswordError.hasError}
            errorText={confirmPasswordError.errorMessage ? translate(confirmPasswordError.errorMessage) : ''}
            isPassword
            label={translate('register.form.confirmpassword.placeholder')}
            placeholder={translate('register.form.confirmpassword.placeholder')}
            onBlur={this.onValidateConfirmPassword}
          />
          <Row className={'centered-container marginT9'}>
            <Button className={'button primary'} type={'submit'}>
              <Translate contentKey={'reset.finish.form.button'} />
            </Button>
          </Row>
        </AvForm>
      </div>
    );
  };

  render() {
    const { changePasswordStatus, checkActivationStatus } = this.props;
    return (
      <div className="password-reset__itens">
        {(fetchIsOnGoing(changePasswordStatus) ||
          fetchIsOnGoing(checkActivationStatus) ||
          checkActivationStatus === HttpRequestStatus.ERROR) && <Loading />}
        <Row className="justify-content-center">
          <Col md="4">
            <h1>
              <Translate contentKey="reset.finish.title">Reset password</Translate>
            </h1>
            <div>{this.getResetForm()}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ passwordReset }: IRootState) => ({
  changePasswordStatus: passwordReset.changePasswordStatus,
  checkActivationStatus: passwordReset.checkActivationStatus,
  checkResetActivationEntity: passwordReset.checkResetActivationEntity,
});

const mapDispatchToProps = { handleChangePasswordByActivationKey, checkActivationKey, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);

import axios from 'axios';

interface IBankSlipType {
  id: number;
}

const basePath = '/api/integration-cdf';

const planCdf = () => {
  const getCdf = (id: number) => {
    return axios.get<IBankSlipType>(`${basePath}/${id}`);
  };

  return {
    getCdf,
  };
};

export default planCdf();

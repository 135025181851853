import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import './transporter-register-documentation.scss';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';
import { ITransportLicense } from 'app/shared/model/transport-license.model';
import TransportLicenseList from 'app/components/transport-license-form/list/transport-license-list';

export interface ITransporterRegisterDocumentationProps extends StateProps, DispatchProps {
  userTransporter: IUserTransporter;
  onNext: (userTransporter: IUserTransporter) => void;
  onBack: () => void;
}

export interface ITransporterRegisterDocumentationState {
  transportLicenses: ITransportLicense[];
}

export class TransporterRegisterDocumentation extends React.Component<
  ITransporterRegisterDocumentationProps,
  ITransporterRegisterDocumentationState
> {
  constructor(props) {
    super(props);
    this.state = {
      transportLicenses: this.props.userTransporter.transporter ? this.props.userTransporter.transporter.transportLicenses : [],
    };
  }

  onNextHandler = (transportLicensesUpdated: ITransportLicense[]) => {
    const userTransporterUpdated = { ...this.props.userTransporter };
    userTransporterUpdated.transporter.transportLicenses = transportLicensesUpdated;
    this.props.onNext(userTransporterUpdated);
  };

  render() {
    const { transportLicenses } = this.state;
    return (
      <div>
        <TransportLicenseList onNextHandler={this.onNextHandler} onBack={this.props.onBack} transportLicenses={transportLicenses} />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterDocumentation);

import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import axios from 'axios'
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { useEffect } from 'react';
import { Pageable } from 'app/shared/model/pageable';
import React, { useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { useHistory } from 'react-router-dom';
import { UnitService } from 'app/services/unit';
import { IUnit } from 'app/shared/model/unit';
import { toast } from 'react-toastify';

const MtrUnits = (props) => {
    const [searchString, setSearchString] = useState<string>('');
    const [page, setPage] = useState<Pageable>({ size: 10, page: 0, totalElements: 0, totalPages: 0 });
    const [units, setUnits] = useState<IUnit[]>([]);
    const history = useHistory();
    const tableHeaders: ColumnSort[] = [
        { name: "Nome Fantasia", sortCode: 'fantasyName' },
        { name: "Código da Unidade", sortCode: 'unitCode' },
        { name: "Endereço", sortCode: 'unitCode' },
        { sortDisabled: true },
    ];

    const handleTransformToTableContent = (content?: IUnit[]): (string | JSX.Element)[][] => {
        if (content == null || content.length === 0) {
            return [];
        }
        const result: (string | JSX.Element)[][] = [];

        content.map((item) => {
            result.push([
                item.fantasyName != null ? item.fantasyName ?? '' : '',
                item.unitCode ? item.unitCode : '',
                item.address.street ? item.address.street : '',
                <DeleteEditButton
                    id={item.id}
                    onDelete={() => handleDeleteUnit(item.id)}
                    onEdit={() => handleGoToEdit(item.id)}
                />,
            ]);
        });

        return result;
    };

    const mappingRow = handleTransformToTableContent(units)

    const getAllUnits = () => {
        UnitService.getAllUnits(page, searchString)
            .then(response => {
                setPage(oldState => ({
                    ...oldState,
                    totalElements: response.totalElements,
                    totalPages: response.totalPages,
                    size: response.size,
                }))
                setUnits(response.content);
            })
            .catch(error => console.log(error));
    }

    const handleGoToDetails = (unitId: number) => {
        history.push({
            pathname: `${props.match.url}/details`,
            state: { id: unitId },
        });
    }

    const handleGoToEdit = (unitId: number) => {
        history.push({
            pathname: `${props.match.url}/edit`,
            state: { id: unitId },
        });
    }

    const handleGoToNew = () => {
        history.push("/mtr-units/new");
    }

    const handleDeleteUnit = (id: number) => {
        UnitService.deleteUnit(id)
            .then(() => {
                getAllUnits();
                toast.success(translate('unitTranslation.deleteSuccess'));
            })
    }

    const handleChangePage = (newPage: number) => {
        const newPageObj: Pageable = { ...page, page: newPage };
        setPage(newPageObj);
    };

    const resetPage = () => {
        setPage(prevState => ({
            ...prevState,
            page: 0
        }))
    }

    const handleSort = (code: string) => {
        setPage(prevState => ({
            ...prevState,
            sort: code
        }))
    };

    useEffect(() => {
        getAllUnits();
    }, [page.page, searchString, page.sort]);

    useEffect(() => {
        resetPage();
    }, [searchString])

    return (
        <div className="mtr-unity__items mtr-unity__authorization">
            <Container>
                <Row>
                    <Col style={{ margin: 'auto' }} md="12">
                        <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>Unidades</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '27px' }}>
                    <Col md="6">
                        <SimpleSearchField onChange={setSearchString} value={searchString} placeholder={'Buscar Unidades'} />
                    </Col>

                    <Col md={{ size: 2, offset: 4 }}>
                        <Button className={'button primary'} type={'submit'} onClick={() => handleGoToNew()} >
                            <Translate contentKey={'authorizations.add'} />
                        </Button>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <SimpleOrderedTable
                            rows={mappingRow}
                            columNameKeys={tableHeaders}
                            page={page}
                            onChangePage={handleChangePage}
                            onSort={(code: string) => handleSort(code)}
                            onClick={(index) => handleGoToDetails(units[index].id)}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MtrUnits
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {TextFormat, Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {getEntity} from './transport-license.reducer';

export interface ITransportLicenseDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class TransportLicenseDetail extends React.Component<ITransportLicenseDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { transportLicenseEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.transportLicense.detail.title">TransportLicense</Translate> [
            <b>{transportLicenseEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="licenseNumber">
                <Translate contentKey="resitrackApp.transportLicense.licenseNumber">License Number</Translate>
              </span>
            </dt>
            <dd>{transportLicenseEntity.licenseNumber}</dd>
            <dt>
              <span id="expireDate">
                <Translate contentKey="resitrackApp.transportLicense.expireDate">Expire Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={transportLicenseEntity.expireDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="issuingBody">
                <Translate contentKey="resitrackApp.transportLicense.issuingBody">Issuing Body</Translate>
              </span>
            </dt>
            <dd>{transportLicenseEntity.issuingBody}</dd>
            <dt>
              <span id="transportLicenseType">
                <Translate contentKey="resitrackApp.transportLicense.transportLicenseType">Transport License Type</Translate>
              </span>
            </dt>
            <dd>{transportLicenseEntity.transportLicenseType}</dd>
            <dt>
              <Translate contentKey="resitrackApp.transportLicense.licenseDocument">License Document</Translate>
            </dt>
            <dd>{transportLicenseEntity.licenseDocument ? transportLicenseEntity.licenseDocument.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.transportLicense.transporter">Transporter</Translate>
            </dt>
            <dd>{transportLicenseEntity.transporter ? transportLicenseEntity.transporter.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/transport-license" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/transport-license/${transportLicenseEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ transportLicense }: IRootState) => ({
  transportLicenseEntity: transportLicense.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransportLicenseDetail);

import { defaultValue, IFinalDestination, IFinalDestinationMtr } from 'app/shared/model/final-destination.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import StringUtils from 'app/shared/util/string-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';

export const ACTION_TYPES = {
  FETCH_FINALDESTINATION_LIST: 'finalDestination/FETCH_FINALDESTINATION_LIST',
  SEARCH_FINALDESTINATION: 'finalDestination/SEARCH_FINALDESTINATION',
  FETCH_FINALDESTINATION: 'finalDestination/FETCH_FINALDESTINATION',
  CREATE_FINALDESTINATION: 'finalDestination/CREATE_FINALDESTINATION',
  UPDATE_FINALDESTINATION: 'finalDestination/UPDATE_FINALDESTINATION',
  DELETE_FINALDESTINATION: 'finalDestination/DELETE_FINALDESTINATION',
  RESET: 'finalDestination/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IFinalDestination>,
  finalDestinations: [] as ReadonlyArray<IFinalDestinationMtr>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type FinalDestinationState = Readonly<typeof initialState>;

// Reducer

export default (state: FinalDestinationState = initialState, action): FinalDestinationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_FINALDESTINATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_FINALDESTINATION):
    case REQUEST(ACTION_TYPES.SEARCH_FINALDESTINATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_FINALDESTINATION):
    case REQUEST(ACTION_TYPES.UPDATE_FINALDESTINATION):
    case REQUEST(ACTION_TYPES.DELETE_FINALDESTINATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_FINALDESTINATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_FINALDESTINATION):
    case FAILURE(ACTION_TYPES.CREATE_FINALDESTINATION):
    case FAILURE(ACTION_TYPES.UPDATE_FINALDESTINATION):
    case FAILURE(ACTION_TYPES.DELETE_FINALDESTINATION):
    case FAILURE(ACTION_TYPES.SEARCH_FINALDESTINATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_FINALDESTINATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_FINALDESTINATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_FINALDESTINATION):
      return {
        ...state,
        loading: false,
        finalDestinations: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_FINALDESTINATION):
    case SUCCESS(ACTION_TYPES.UPDATE_FINALDESTINATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_FINALDESTINATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/final-destinations';

// Actions

export const getEntities: ICrudGetAllAction<IFinalDestination> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_FINALDESTINATION_LIST,
  payload: axios.get<IFinalDestination>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const searchFinalDestinations: ICrudSearchActionCustom<IFinalDestinationMtr> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}/search${params}`;
  return {
    type: ACTION_TYPES.SEARCH_FINALDESTINATION,
    payload: axios.get<IFinalDestinationMtr>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IFinalDestination> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_FINALDESTINATION,
    payload: axios.get<IFinalDestination>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IFinalDestination> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_FINALDESTINATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IFinalDestination> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_FINALDESTINATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IFinalDestination> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_FINALDESTINATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities as getTransporters } from 'app/entities/transporter/transporter.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './legal-person.reducer';

export interface ILegalPersonUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ILegalPersonUpdateState {
  isNew: boolean;
  transporterId: string;
}

export class LegalPersonUpdate extends React.Component<ILegalPersonUpdateProps, ILegalPersonUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      transporterId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getTransporters();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { legalPersonEntity } = this.props;
      const entity = {
        ...legalPersonEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/legal-person');
  };

  render() {
    const { legalPersonEntity, transporters, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.legalPerson.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.legalPerson.home.createOrEditLabel">Create or edit a LegalPerson</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : legalPersonEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="legal-person-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="legal-person-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="cnpjLabel" for="legal-person-cnpj">
                    <Translate contentKey="resitrackApp.legalPerson.cnpj">Cnpj</Translate>
                  </Label>
                  <AvField id="legal-person-cnpj" type="text" name="cnpj" />
                </AvGroup>
                <AvGroup>
                  <Label id="fantasyNameLabel" for="legal-person-fantasyName">
                    <Translate contentKey="resitrackApp.legalPerson.fantasyName">Fantasy Name</Translate>
                  </Label>
                  <AvField id="legal-person-fantasyName" type="text" name="fantasyName" />
                </AvGroup>
                <AvGroup>
                  <Label id="companyNameLabel" for="legal-person-companyName">
                    <Translate contentKey="resitrackApp.legalPerson.companyName">Company Name</Translate>
                  </Label>
                  <AvField
                    id="legal-person-companyName"
                    type="text"
                    name="companyName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="phoneLabel" for="legal-person-phone">
                    <Translate contentKey="resitrackApp.legalPerson.phone">Phone</Translate>
                  </Label>
                  <AvField id="legal-person-phone" type="text" name="phone" />
                </AvGroup>
                <AvGroup>
                  <Label id="municipalRegistrationLabel" for="legal-person-municipalRegistration">
                    <Translate contentKey="resitrackApp.legalPerson.municipalRegistration">Municipal Registration</Translate>
                  </Label>
                  <AvField id="legal-person-municipalRegistration" type="text" name="municipalRegistration" />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/legal-person" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  transporters: storeState.transporter.entities,
  legalPersonEntity: storeState.legalPerson.entity,
  loading: storeState.legalPerson.loading,
  updating: storeState.legalPerson.updating,
  updateSuccess: storeState.legalPerson.updateSuccess,
});

const mapDispatchToProps = {
  getTransporters,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LegalPersonUpdate);

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './driver-edit.scss';
import { translate } from 'react-jhipster';
import Loading from 'app/components/loading/loading';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import {
  checkStatusOnGoing,
  translateErrorMessage,
  ValidationResult,
  ValidationResultInstance,
} from 'app/shared/util/validation-utils';
import { maskCPF, maskNumber, maskPhone, unmask } from 'app/shared/util/mask-utils';
import { DriverType, IDriver } from 'app/shared/model/driver.model';
import {
  validateCPF,
  validateEmail,
  validateGenericField,
  validateObject,
  validatePersonName,
  validatePhoneNumber,
} from 'app/modules/validation/validation-constants';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import InlineSelect from 'app/components/inline-select/inline-select';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import { ALERT_ICONS, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { createDriver, getDriverDetail, reset, updateDriver } from '../../transport-reducer';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';
import AlertModal from 'app/components/alert-modal/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';

export interface IDriverEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IDriverEditState {
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
  isNew: boolean;
  email: string;
  name: string;
  cpf: string;
  phone: string;
  driverLicense: string;
  driverType: DriverType;
  expirationDate: string;
  nameError: ValidationResult;
  emailError: ValidationResult;
  cpfError: ValidationResult;
  phoneError: ValidationResult;
  driverLicenseError: ValidationResult;
  driverTypeError: ValidationResult;
  expirationDateError: ValidationResult;
}

export class DriverEdit extends React.Component<IDriverEditProps, IDriverEditState> {
  constructor(props: Readonly<IDriverEditProps>) {
    super(props);
    this.state = {
      showErrorModal: false,
      showResponsabilityModal: false,
      isNew: null,
      name: null,
      email: null,
      cpf: null,
      phone: null,
      driverLicense: null,
      driverType: null,
      expirationDate: null,
      nameError: ValidationResultInstance,
      cpfError: ValidationResultInstance,
      phoneError: ValidationResultInstance,
      driverLicenseError: ValidationResultInstance,
      driverTypeError: ValidationResultInstance,
      expirationDateError: ValidationResultInstance,
      emailError: ValidationResultInstance,
    };
  }

  async componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    if (id) {
      this.props.getDriverDetail(id);
    } else {
      this.setState({
        isNew: true,
      });
    }
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.driverDetail != null &&
      newProps.getDriveDetailStatus != this.props.getDriveDetailStatus &&
      newProps.getDriveDetailStatus === HttpRequestStatus.SUCCESS
    ) {
      this.mapDriverToField(newProps.driverDetail);
    }

    if (newProps.createDriverStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    }

    if (newProps.updateDriverStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessUpdateAlertPage();
      this.props.reset();
    }

    if (newProps.createDriverStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }

    if (newProps.updateDriverStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  mapDriverToField = (driver: IDriver) => {
    this.setState({
      email: driver.userTransporter.user.email,
      name: driver.userTransporter.user.name,
      cpf: driver.userTransporter.user.cpf,
      phone: driver.userTransporter.user.phone,
      driverLicense: driver.driverLicense,
      driverType: driver.driverType,
      expirationDate: driver.expirationDate,
    });
  };

  toggleResponsabilityModal = () => {
    this.setState({
      showResponsabilityModal: !this.state.showResponsabilityModal,
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  onValidateAll = () => {
    let hasError = false;
    if (this.onValidateEmail()) {
      hasError = true;
    }
    if (this.onValidateName()) {
      hasError = true;
    }
    if (this.onValidateCPF()) {
      hasError = true;
    }
    if (this.onValidatePhone()) {
      hasError = true;
    }
    if (this.onValidateType()) {
      hasError = true;
    }
    if (this.onValidateDriverLicense()) {
      hasError = true;
    }
    if (this.onValidateExpireDate()) {
      hasError = true;
    }

    return hasError;
  };

  onValidateEmail = () => {
    const { email } = this.state;
    const validate = validateEmail(email);
    this.setState({
      emailError: validate,
    });
    return validate.hasError;
  };

  onValidateName = () => {
    const { name } = this.state;
    const validate = validatePersonName(name);
    this.setState({
      nameError: validate,
    });
    return validate.hasError;
  };

  onValidateCPF = () => {
    const { cpf } = this.state;
    const validate = validateCPF(cpf);
    this.setState({
      cpfError: validate,
    });
    return validate.hasError;
  };

  onValidatePhone = () => {
    const { phone } = this.state;
    const validate = validatePhoneNumber(phone);
    this.setState({
      phoneError: validate,
    });
    return validate.hasError;
  };

  onValidateDriverLicense = () => {
    const { driverLicense } = this.state;
    const validate = validateGenericField(driverLicense);
    this.setState({
      driverLicenseError: validate,
    });
    return validate.hasError;
  };

  onValidateType = () => {
    const { driverType } = this.state;
    const validate = validateObject(driverType);
    this.setState({
      driverTypeError: validate,
    });
    return validate.hasError;
  };

  onValidateExpireDate = () => {
    const { expirationDate } = this.state;
    const validate = validateGenericField(expirationDate);
    this.setState({
      expirationDateError: validate,
    });
    return validate.hasError;
  };

  mapDriverTypeToOptions = () => {
    const optionsList = [];
    Object.keys(DriverType).forEach((item, index) => {
      optionsList.push({ label: item, value: item });
    });
    return optionsList;
  };

  onSelectDriverType = (item: any) => {
    this.setState(
      {
        driverType: item,
      },
      () => this.onValidateType()
    );
  };

  mapSelectedDriverTypeToSelect = () => {
    const { driverType } = this.state;

    return driverType ? { label: driverType, value: driverType } : null;
  };

  onSaveHandler = () => {
    if (this.onValidateAll()) {
      return;
    }

    const { driverDetail } = this.props;
    const { email, name, cpf, phone, driverLicense, driverType, expirationDate } = this.state;

    const userTransporter: IUserTransporter = {
      id: driverDetail.userTransporter ? driverDetail.userTransporter.id : null,
      user: {
        id: driverDetail.userTransporter ? driverDetail.userTransporter.user.id : null,
        name,
        cpf: unmask(cpf),
        email,
        phone: unmask(phone),
        authorities: [AUTHORITIES.DRIVER_USER],
      },
    };

    const driver: IDriver = {
      ...driverDetail,
      userTransporter,
      driverLicense,
      driverType,
      expirationDate,
    };

    const { isNew } = this.state;
    if (isNew) {
      this.props.createDriver(driver);
    } else {
      this.props.updateDriver(driver);
    }
  };

  onBackHandler = () => {
    this.props.history.replace('/transporter/transport/drivers');
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.driverRegister',
        buttonUrl: '/transporter/transport/drivers',
      },
    });
  };

  goToSuccessUpdateAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.driverUpdate',
        buttonUrl: '/transporter/transport/drivers',
      },
    });
  };

  onStartSaving = () => {
    if (this.onValidateAll()) {
      return;
    }

    this.toggleResponsabilityModal();
  };

  renderForm = () => {
    const {
      email,
      emailError,
      name,
      nameError,
      cpf,
      cpfError,
      phone,
      phoneError,
      driverLicense,
      driverLicenseError,
      driverTypeError,
      expirationDate,
      expirationDateError,
    } = this.state;

    return (
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                email: text,
              })
            }
            id="email"
            style={{ marginTop: '20px' }}
            value={email}
            label={translate('transporter.transport.driver.form.email')}
            placeholder={translate('transporter.transport.driver.form.email')}
            error={emailError.hasError}
            errorText={translateErrorMessage(emailError.errorMessage)}
            onBlur={this.onValidateEmail}
          />
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                name: text,
              })
            }
            id="name"
            style={{ marginTop: '20px' }}
            value={name}
            label={translate('transporter.transport.driver.form.name')}
            placeholder={translate('transporter.transport.driver.form.name')}
            error={nameError.hasError}
            errorText={translateErrorMessage(nameError.errorMessage)}
            onBlur={this.onValidateName}
          />
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                cpf: text,
              })
            }
            id="cpf"
            style={{ marginTop: '20px' }}
            value={cpf}
            onMask={maskCPF}
            label={translate('transporter.transport.driver.form.cpf')}
            placeholder={translate('transporter.transport.driver.form.cpf')}
            error={cpfError.hasError}
            errorText={translateErrorMessage(cpfError.errorMessage)}
            onBlur={this.onValidateCPF}
          />
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                phone: text,
              })
            }
            id="phone"
            style={{ marginTop: '20px' }}
            value={phone}
            onMask={maskPhone}
            label={translate('transporter.transport.driver.form.phone')}
            placeholder={translate('transporter.transport.driver.form.phone')}
            error={phoneError.hasError}
            errorText={translateErrorMessage(phoneError.errorMessage)}
            onBlur={this.onValidatePhone}
          />
          <CustomTextField
            onChange={(text: string) =>
              this.setState({
                driverLicense: text,
              })
            }
            id="driverLicense"
            style={{ marginTop: '20px' }}
            value={driverLicense}
            onMask={maskNumber}
            label={translate('transporter.transport.driver.form.driverLicense')}
            placeholder={translate('transporter.transport.driver.form.driverLicense')}
            error={driverLicenseError.hasError}
            errorText={translateErrorMessage(driverLicenseError.errorMessage)}
            onBlur={this.onValidateDriverLicense}
          />
          <InlineSelect
            placeholder={translate('transporter.transport.driver.form.driverType')}
            error={driverTypeError.hasError}
            errorMessage={translateErrorMessage(driverTypeError.errorMessage)}
            options={this.mapDriverTypeToOptions()}
            onChange={this.onSelectDriverType}
            startIndex={0}
            selectedOption={this.mapSelectedDriverTypeToSelect()}
          />
          <DatePickerComponent
            dateFormat={APP_LOCAL_DATE_FORMAT}
            onDateChange={value => {
              this.setState(
                {
                  expirationDate: value,
                },
                () => this.onValidateExpireDate()
              );
            }}
            dateSelected={expirationDate}
            placeholder={translate('transporter.transport.driver.form.expireDate')}
            error={expirationDateError.hasError}
            errorMessage={translateErrorMessage(expirationDateError.errorMessage)}
          />
          <NextCancelButton
            onCancelClick={this.onBackHandler}
            onNextClick={this.onStartSaving}
            nextButtonName={translate('entity.action.save')}
            noArrow
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { getDriveDetailStatus, createDriverStatus, updateDriverStatus, driverDetail } = this.props;
    const { isNew } = this.state;
    if (checkStatusOnGoing([getDriveDetailStatus, createDriverStatus, updateDriverStatus])) {
      return <Loading />;
    }
    return (
      <div>
        <div style={{ paddingTop: '70px' }}>
          <BackHeaderComponent hasButton={false} onBack={this.onBackHandler} />
          <h1>{translate('mtr-transporter.driver.title')}</h1>
          {isNew && this.renderForm()}
          {!isNew && driverDetail && driverDetail.userTransporter && this.renderForm()}

          <AlertModal
            showModal={this.state.showResponsabilityModal}
            buttonOneActionMessage={'entity.action.cancel'}
            buttonTwoActionMessage={'entity.action.agree'}
            hasTwoButtons
            buttonTwoAction={this.onSaveHandler}
            buttonOneAction={this.toggleResponsabilityModal}
            statusImage="paper-question-mark.svg"
            alertMessage="alert.responsibilityTerms"
          />

          <AlertModal
            showModal={this.state.showErrorModal}
            buttonTwoActionMessage={'entity.action.ok'}
            buttonTwoAction={this.toggleErrorModal}
            statusImage="bag-error.svg"
            alertMessage={this.state.isNew ? 'errorMessages.driverRegister' : 'errorMessages.driverUpdate'}
          />

          <AlertModal
            showModal={this.state.showErrorModal}
            buttonTwoActionMessage={'entity.action.ok'}
            buttonTwoAction={this.toggleErrorModal}
            statusImage="bag-error.svg"
            alertMessage={this.state.emailError ? 'errorMessages.driverEmail' : 'errorMessages.driverUpdate'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  driverDetail: root.transport.driverDetail,
  getDriveDetailStatus: root.transport.getDriveDetailStatus,
  createDriverStatus: root.transport.createDriverStatus,
  updateDriverStatus: root.transport.updateDriverStatus,
});

const mapDispatchToProps = {
  getDriverDetail,
  createDriver,
  updateDriver,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DriverEdit);

import React, { Fragment, useEffect } from 'react';
import { translate } from 'react-jhipster';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './mtr-view.scss';
import { connect } from 'react-redux';
import { IMtr, MtrStatus } from 'app/shared/model/mtr.model';
import { APP_LOCAL_DATE_FORMAT, APP_ONLY_DATE_FORMAT } from 'app/config/constants';
import moment from 'moment';
import { PersonType } from 'app/shared/model/transporter.model';
import { maskCnpj, maskZipCode } from 'app/shared/util/mask-utils';
import { ISignature } from 'app/shared/model/signature.model';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';
/*
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import {
  getSubsidiariesFinalDestiny,
  getSubsidiariesProducer
} from '../../../../../modules/producer/units/producer-units-reducer'
 */


export const getMtrStatusStyle = (mtrStatus: MtrStatus) => {
  if (mtrStatus == null) {
    return <div />;
  }
  let style = 'status-tag ';
  switch (mtrStatus) {
    case MtrStatus.COMPLETE:
      style = style.concat('complete');
      break;
    case MtrStatus.INCOMPLETE:
      style = style.concat('incomplete');
      break;
    case MtrStatus.CANCELLED:
      style = style.concat('cancelled');
      break;
    case MtrStatus.PENDING:
      style = style.concat('pending');
      break;
  }

  return (
    <div className={style}>
      <span>{translate(`mtr-list.status.${mtrStatus}`).toUpperCase()}</span>
    </div>
  );
};

export const mtrView = (mtr: IMtr, _1, _2, setIsOpen, isOpen): JSX.Element => {
  const producer = mtr.producer ?? {};
  const transporter = mtr.transporter ?? {};
  const driver = mtr.driver ?? {};
  const vehicle = mtr.vehicle ?? {};
  const transshipment = mtr.transshipment ?? {};
  const finalDestination = mtr.finalDestination ?? {};
  const transportLicense = mtr.transportLicense ?? {};
  const environmentalLicense = mtr.finalDestinationLicense ?? {};
  const storageLicense = mtr.transshipmentLicense ?? {};
  const status = mtr.status;

  let addressLabelProducerUnit = mtr.producerUnit?.address?.street ?? '';
  if (mtr.producerUnit?.address?.complement) {
    addressLabelProducerUnit += ', ' + mtr.producerUnit.address.complement;
  }
  if (addressLabelProducerUnit) {
    addressLabelProducerUnit += '. ';
  }
  const producerCity = mtr.producerUnit?.address?.city?.name ?? '';
  const producerState = mtr.producerUnit?.address?.city?.state?.name ?? '';

  if (producerCity) {
    addressLabelProducerUnit += producerCity;
    if (producerState) {
      addressLabelProducerUnit += '/' + producerState;
    }
  }

  let addressLabelTransporterUnit = mtr.transporterUnit?.address?.street ?? '';
  if (mtr.transporterUnit?.address?.complement) {
    addressLabelTransporterUnit += ', ' + mtr.transporterUnit.address.complement;
  }
  if (addressLabelTransporterUnit) {
    addressLabelTransporterUnit += '. ';
  }
  const transporterCity = mtr.transporterUnit?.address?.city?.name ?? '';
  const transporterState = mtr.transporterUnit?.address?.city?.state?.name ?? '';

  if (transporterCity) {
    addressLabelTransporterUnit += transporterCity;
    if (transporterState) {
      addressLabelTransporterUnit += '/' + transporterState;
    }
  }

  let addressLabelFinalDestinyUnit = mtr.finalDestinationUnit?.address?.street ?? '';
  if (mtr.finalDestinationUnit?.address?.complement) {
    addressLabelFinalDestinyUnit += ', ' + mtr.finalDestinationUnit.address.complement;
  }
  if (addressLabelFinalDestinyUnit) {
    addressLabelFinalDestinyUnit += '. ';
  }
  const finalDestinyCity = mtr.finalDestinationUnit?.address?.city?.name ?? '';
  const finalDestinyState = mtr.finalDestinationUnit?.address?.city?.state?.name ?? '';

  if (finalDestinyCity) {
    addressLabelFinalDestinyUnit += finalDestinyCity;
    if (finalDestinyState) {
      addressLabelFinalDestinyUnit += '/' + finalDestinyState;
    }
  }

  let addressLabelTransshipmentUnit = mtr.transshipmentUnit?.address?.street ?? '';
  if (mtr.transshipmentUnit?.address?.complement) {
    addressLabelTransshipmentUnit += ', ' + mtr.transshipmentUnit.address.complement;
  }
  if (addressLabelTransshipmentUnit) {
    addressLabelTransshipmentUnit += '. ';
  }
  const transshipmentCity = mtr.transshipmentUnit?.address?.city?.name ?? '';
  const transshipmentState = mtr.transshipmentUnit?.address?.city?.state?.name ?? '';

  if (transshipmentCity) {
    addressLabelTransshipmentUnit += transshipmentCity;
    if (transshipmentState) {
      addressLabelTransshipmentUnit += '/' + transshipmentState;
    }
  }
  /*
  console.log("a----A");
  console.log(mtr)
  console.log("a----A");
*/

  return (
    <div className="signature-generator__items">
      {mtr.integrationMtr != null && mtr.integrationMtr
        .map(it => {
          return (
            it.errorMessage != null &&
            <div style={{ marginLeft: '150px', marginBottom: '20px' }}>
              <Row>
                <Col md={{ size: 11 }} style={{ paddingRight: '55px' }}>
                  {it.errorMessage === "MTR ainda não foi Recebido no SINIR" || it.errorMessage === "MTR foi Cancelado no órgão" ? (
                    <Alert severity="error">
                      {`${translate('mtr-signature.integrationStatusError')} : ${it.errorMessage}`}
                    </Alert>
                  ) : (
                    <Alert severity="error">
                      {`${translate('mtr-signature.integrationError')} ${it.agency} : ${it.errorMessage}`}
                    </Alert>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
      <Container>
        <Row style={{ marginTop: '20px' }}>
          <Col md={{ size: 4, offset: 1 }}>
            <div className="signature-generator__items--title">
              <div style={{ display: 'flex' }}>
                <div className="signature-generator__items--title-text">
                  <span>{translate('mtr-signature.generator.title')}</span>
                  <span
                    style={{ fontWeight: 300, color: 'black' }}>{translate('mtr-signature.generator.title-second')}</span>
                  <span>{translate('mtr-signature.generator.title-third')}</span>
                  <div style={{ width: '1px', height: '31px', border: 'solid 1px #aaaaaa' }} />
                </div>
                <div className="signature-generator__items--title-img" />
              </div>
              <div
                className="signature-generator__items--title-description">{translate('mtr-signature.generator.description')}</div>
              <div>{getMtrStatusStyle(status)}</div>
            </div>
          </Col>
          {mtr.integrationMtr != null &&
            mtr.integrationMtr
              .filter(it => it.integrationMtrStatus == "FINISHED_MTR")
              .map(it => {
                return (
                  <Col md={{ size: 2 }}>
                    {it.integrationNumberMtr &&
                      <div className="signature-generator__items--info" style={{ color: '#ee312a' }}>
                        {translate('mtr-signature.generator.numberIntegration')} {it.agency || mtr.agencyName}
                        <span style={{ color: '#ee312a' }}>{it.integrationNumberMtr ?? ''}</span>
                      </div>}
                  </Col>
                );
              })}
          <Col md={{ size: 2 }}>
            <div className="signature-generator__items--info">
              {translate('mtr-signature.generator.number')}
              <span>{mtr.numberMtr ?? ''}</span>
            </div>
          </Col>
          <Col md={{ size: 2 }}>
            <div className="signature-generator__items--info">
              {translate('mtr-signature.generator.date')}
              <span>{mtr.collectionDate ? moment(mtr.collectionDate).format(APP_ONLY_DATE_FORMAT) : ''}</span>
            </div>
          </Col>
          <div className={`signature-generator__items--info-img${!isOpen ? '--closed' : ''}`}
            onClick={() => setIsOpen(!isOpen)} />
        </Row>
        {isOpen && (
          <div>
            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                <div className="signature-generator__items--list">{translate('mtr-signature.generator.generator')}</div>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.companyName')}</div>
                  <div
                    className="list__items--text">{producer?.naturalPerson?.name.toUpperCase() ?? producer?.legalPerson?.companyName.toUpperCase()} {mtr.parDescricaoGerador}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.address')}</div>
                  <div className="list__items--text">{producer.addressLabel ?? ''}</div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.zipcode')}</div>
                  <div
                    className="list__items--text">{producer?.address?.zipcode != null ? maskZipCode(producer.address.zipcode) : ''}</div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                    <div className="list__items--text">{mtr.producerUnit?.fantasyName || mtr.parDescricaoGerador || ''}</div>
                  </div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                    <div className="list__items--text">{mtr.producerUnit?.unitCode || mtr.parCodigoGerador || ''}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                    <div className="list__items--text">{addressLabelProducerUnit}</div>
                  </div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                    <div className="list__items--text">{mtr.producerUnit?.address.zipcode ?? ''}</div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                <div
                  className="signature-generator__items--list">{translate('mtr-signature.generator.transporter')}</div>
                <div className="list__items--component">
                  {transporter.personType === PersonType.NATURAL ? (
                    <>
                      <div className="list__items--title">Nome completo</div>
                      <div className="list__items--text">{transporter.name?.toUpperCase() ?? ''}</div>
                    </>
                  ) : (
                    <>
                      <div className="list__items--title">{translate('mtr.companyName')}</div>
                      <div
                        className="list__items--text">{transporter.legalPerson?.companyName?.toUpperCase() ?? ''}</div>
                    </>
                  )}
                  {<div
                    className="list__items--text">{mtr.parDescricaoTransportador ?? ''}</div>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.address')}</div>
                  <div className="list__items--text">{transporter.addressLabel || mtr.parEnderecoTransportador || ''}</div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.zipcode')}</div>
                  <div
                    className="list__items--text">{transporter?.address?.zipcode != null ? maskZipCode(transporter.address.zipcode) : ''}
                  </div>
                  <div className="list__items--text">{mtr.parZipcodeTransportador || ''} </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 2, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.transportLicense')}</div>
                  <div className="list__items--text">{transportLicense.licenseNumber ?? ''}</div>
                </div>
              </Col>
              <Col md="5" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                  <div className="list__items--text">{transportLicense.issuingBody?.toUpperCase() ?? ''}</div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                  <div className="list__items--text">
                    {transportLicense.expireDate != null ? moment(transportLicense.expireDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                    <div className="list__items--text">{mtr.transporterUnit?.fantasyName || mtr.parDescricaoTransportador || ''}</div>
                  </div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                    <div className="list__items--text">{mtr.transporterUnit?.unitCode || mtr.parCodigoTransportador || ''}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                    <div className="list__items--text">{addressLabelTransporterUnit || mtr.parEnderecoTransportador}</div>
                  </div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                    <div className="list__items--text">{mtr.transporterUnit?.address.zipcode || mtr.parZipcodeTransportador || ''}</div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="signature-generator__items--list">{translate('mtr-signature.generator.driver')}</div>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.driver')}</div>
                  <div className="list__items--text">{driver?.userTransporter?.user?.name?.toUpperCase() || mtr?.driverName?.toUpperCase() || ''}</div>
                </div>
              </Col>
              <Col md="5" style={{ paddingLeft: '2px' }}>
                <div className="signature-generator__items--list">{translate('mtr-signature.generator.vehicle')}</div>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.plate')}</div>
                  <div className="list__items--text">{vehicle.plate || mtr.vehicleLicensePlate || ''}</div>
                </div>
              </Col>
            </Row>
            {!isEmpty(transshipment) && (
              <Fragment>
                <Row>
                  <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div
                      className="signature-generator__items--list">{translate('mtr-signature.generator.transshipment')}</div>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.companyName')}</div>
                      <div className="list__items--text">{transshipment.companyName?.toUpperCase() ?? ''}</div>
                    </div>
                  </Col>
                  <Col md="5" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.cnpj')}</div>
                      <div className="list__items--text">{transshipment.cnpj ? maskCnpj(transshipment.cnpj) : ''}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.address')}</div>
                      <div className="list__items--text">{transshipment.addressLabel ?? ''}</div>
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.zipcode')}</div>
                      <div
                        className="list__items--text">{transshipment?.address?.zipcode != null ? maskZipCode(transshipment.address.zipcode) : ''}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.licenseNumber')}</div>
                      <div className="list__items--text">{storageLicense.licenseNumber ?? ''}</div>
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                      <div className="list__items--text">{storageLicense.issuingBody?.toUpperCase() ?? ''}</div>
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.type')}</div>
                      <div className="list__items--text">
                        {storageLicense.storageLicenseType ?? ''}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 10, offset: 1 }}>
                    <div className="list__items--component">
                      <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                      <div className="list__items--text">
                        {storageLicense.expireDate != null ? moment(storageLicense.expireDate).format('DD/MM/YYYY') : ''}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                    <div className="list__items--component">
                      <div>
                        <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                        <div className="list__items--text">{mtr.transshipmentUnit?.fantasyName ?? ''}</div>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                      <div>
                        <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                        <div className="list__items--text">{mtr.transshipmentUnit?.unitCode ?? ''}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                      <div>
                        <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                        <div className="list__items--text">{addressLabelTransshipmentUnit}</div>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                      <div>
                        <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                        <div className="list__items--text">{mtr.transshipmentUnit?.address.zipcode ?? ''}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
            <Row>
              <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
                <div
                  className="signature-generator__items--list">{translate('mtr-signature.generator.final-destiny')}</div>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.companyName')}</div>
                  <div
                    className="list__items--text">{(finalDestination.legalPerson?.companyName?.toUpperCase() || finalDestination.naturalPerson?.name.toUpperCase()) || mtr.parDescricaoDestino.toUpperCase() || ''}</div>
                </div>
              </Col>
              <Col md="5" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.cnpj')}</div>
                  <div
                    className="list__items--text">{finalDestination.legalPerson?.cnpj ? maskCnpj(finalDestination.legalPerson?.cnpj) : '' || mtr.parCnpjDestino ? maskCnpj(mtr.parCnpjDestino) : ''}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.finalDestinationCategory')}</div>
                  <div className="list__items--text">
                    {finalDestination.finalDestinationCategory
                      ? translate(`enums.finalDestinationCategory.${finalDestination.finalDestinationCategory}`)
                      : ''}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.address')}</div>
                  <div className="list__items--text">{finalDestination.addressLabel || mtr.parEnderecoDestino || ''}</div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.zipcode')}</div>
                  <div
                    className="list__items--text">{finalDestination?.address?.zipcode != null ? maskZipCode(finalDestination.address.zipcode) : '' || mtr.parZipcodeDestino ? maskZipCode(mtr.parZipcodeDestino) : ''}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.licenseNumber')}</div>
                  <div className="list__items--text">{environmentalLicense.licenseNumber ?? ''}</div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                  <div className="list__items--text">{environmentalLicense.issuingBody?.toUpperCase() ?? ''}</div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div className="list__items--title">Tipo</div>
                  <div className="list__items--text">
                    {environmentalLicense.environmentalLicenseType != null
                      ? translate(`enums.environmentalLicenseType.${environmentalLicense.environmentalLicenseType}`)
                      : ''}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                <div className="list__items--component">
                  <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                  <div className="list__items--text">
                    {environmentalLicense.expireDate != null ? moment(environmentalLicense.expireDate).format('DD/MM/YYYY') : ''}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                    <div className="list__items--text">{mtr.finalDestinationUnit?.fantasyName || mtr.parDescricaoDestino || ''}</div>
                  </div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                    <div className="list__items--text">{mtr.finalDestinationUnit?.unitCode || mtr.parCodigoDestino || ''}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                    <div className="list__items--text">{addressLabelFinalDestinyUnit || mtr.parEnderecoDestino}</div>
                  </div>
                </div>
              </Col>
              <Col md="3" style={{ paddingLeft: '2px' }}>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                    <div className="list__items--text">{mtr.finalDestinationUnit?.address.zipcode || mtr.parZipcodeDestino || ''}</div>
                  </div>
                </div>
              </Col>
            </Row>

            {mtr.mtrResidues != null &&
              mtr.mtrResidues
                .map(it => {
                  const residue = it.sourceResidue;
                  const name = residue?.specificResidue?.name || it.specificResidue || '';
                  const description = residue?.description || it.descriptionResidue || '';
                  const measure = it.measurementType ?? it.sourceResidue?.measurementType;
                  const measurementType = residue != null ? translate(`enums.measurementTypeAbbreviation.${measure}`) : '' || it.measurementTypeResidue;
                  return (
                    <div>
                      <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                          <div
                            className="signature-generator__items--list">{translate('mtr-signature.generator.waste')}</div>
                          <div className="list__items--component-second--residue">
                            <div className="list__items--title">{translate('mtr.residueDescription')}</div>
                            <div className="list__items--text">{description}</div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 6, offset: 1 }} style={{ paddingRight: '0' }}>
                          <div className="list__items--component-second--residue">
                            <div className="list__items--title">{translate('mtr.specificResidue')}</div>
                            <div className="list__items--text">{name} - {description}</div>
                          </div>
                        </Col>
                        <Col md={{ size: 2 }} style={{ paddingRight: '0', paddingLeft: '2px' }}>
                          <div className="list__items--component-second--residue">
                            <div className="list__items--title">{translate('mtr.amountResidue')}</div>
                            <div className="list__items--text">{`${it.amount || it.amountResidue || ''} ${measurementType}`}</div>
                          </div>
                        </Col>
                        <Col md={{ size: 2 }} style={{ paddingLeft: '2px' }}>
                          <div className="list__items--component-second--residue">
                            <div className="list__items--title">{translate('mtr.amountResidueReceipt')}</div>
                            <div className="list__items--text">{`${it.amountReceipt ?? ''} ${measurementType}`}</div>
                          </div>
                        </Col>
                        <Col md={{ size: 10, offset: 1 }}>
                          <div className="signature-generator__items--list">{translate('mtr-signature.generator.justify')}</div>
                          <div className="list__items--component-second--residue">
                            <div className="list__items--title">{translate('mtr.justify')}</div>
                            <div className="list__items--text">{`${it.justification ?? ''}`}</div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}

            <Row style={{ marginBottom: '30px' }}>
              <Col md={{ size: 10, offset: 1 }}>
                <div className="signature-generator__items--list">{translate('mtr-signature.generator.observation')}</div>
                <div className="list__items--component">
                  <div>
                    <div className="list__items--title">{translate('mtr.observation')}</div>
                    <div className="list__items--text">{mtr.observation}</div>
                  </div>
                </div>
              </Col>
            </Row>


          </div>
        )}
        <Row className="signature-generator__items--signature-container" style={{ borderTop: 'solid 1px #e3e3e3' }}>
          {signatureMtrComponent(
            mtr ? mtr.producerSignature : null,
            mtr ? mtr.producerSignatureDate : null,
            translate('entity.producer'),
            2
          )}
          {signatureMtrComponent(
            mtr ? mtr.transporterSignature : null,
            mtr ? mtr.transporterSignatureDate : null,
            translate('entity.transporter')
          )}
          {!isEmpty(transshipment) && signatureMtrComponent(
            mtr ? mtr.transshipmentSignature : null,
            mtr ? mtr.transshipmentSignatureDate : null,
            translate('entity.transshipment')
          )}
          {signatureMtrComponent(
            mtr ? mtr.finalDestinationSignature : null,
            mtr ? mtr.finalDestinationSignatureDate : null,
            translate('entity.finalDestination'),
            !isEmpty(transshipment) ? 2 : undefined
          )}
        </Row>
      </Container>
    </div>
  );
};

export const signatureMtrComponent = (signature: ISignature, signatureDate?, userType?: string, offset?: number): JSX.Element => {
  return (
    <Col md={{ size: 3, offset: 0.7 }}>
      <div className="signature-generator__items--signature">
        {signature != null &&
          signature.signatureDocument != null &&
          (signature.signatureDocument.presignedUrl != null || signature.signatureDocument.file != null) ? (
          <div>
            {signature.signatureDocument.file == null && signature.signatureDocument.presignedUrl != null && (
              <img src={signature.signatureDocument.presignedUrl} style={{ maxHeight: '80px', objectFit: 'cover' }} />
            )}
            {signature.signatureDocument.file != null && (
              <img
                src={`data:${signature.signatureDocument.fileContentType};base64,${signature.signatureDocument.file}`}
                style={{ maxHeight: '80px', objectFit: 'cover', width: '100%' }}
              />
            )}
            <div className="signature-generator__items--signature-line" />
          </div>
        ) : (
          <div className="signature-generator__items--signature-line" />
        )}
        {signature != null && signature.user != null && signature.user.nameTechnical != null ? (
          <div className="signature-generator__items--signature-name" style={{ color: 'black' }}>
            {signature.user.nameTechnical}
          </div>
        ) : (
          <div className="signature-generator__items--signature-name">{'Nome do responsável'}</div>
        )}
        <div className="signature-generator__items--signature-type">{userType}</div>
        <div className="signature-generator__items--signature-type">
          {signature != null && signatureDate != null ? moment(signatureDate).format(APP_ONLY_DATE_FORMAT) : '00/00/00'}
        </div>
      </div>
    </Col>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { createEntity, getEntity, reset, updateEntity } from './favorite.reducer';

// tslint:disable-next-line:no-unused-variable

export interface IFavoriteUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IFavoriteUpdateState {
  isNew: boolean;
}

export class FavoriteUpdate extends React.Component<IFavoriteUpdateProps, IFavoriteUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { favoriteEntity } = this.props;
      const entity = {
        ...favoriteEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/favorite');
  };

  render() {
    const { favoriteEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.favorite.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.favorite.home.createOrEditLabel">Create or edit a Favorite</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : favoriteEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="favorite-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="favorite-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="favoriteTypeLabel" for="favorite-favoriteType">
                    <Translate contentKey="resitrackApp.favorite.favoriteType">Favorite Type</Translate>
                  </Label>
                  <AvInput
                    id="favorite-favoriteType"
                    type="select"
                    className="form-control"
                    name="favoriteType"
                    value={(!isNew && favoriteEntity.favoriteType) || 'PRODUCER'}
                  >
                    <option value="PRODUCER">{translate('resitrackApp.FavoriteType.PRODUCER')}</option>
                    <option value="FINAL_DESTINATION">{translate('resitrackApp.FavoriteType.FINAL_DESTINATION')}</option>
                    <option value="TRANSPORTER">{translate('resitrackApp.FavoriteType.TRANSPORTER')}</option>
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/favorite" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  favoriteEntity: storeState.favorite.entity,
  loading: storeState.favorite.loading,
  updating: storeState.favorite.updating,
  updateSuccess: storeState.favorite.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteUpdate);

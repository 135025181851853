import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './manager-producer-invitation.reducer';

// tslint:disable-next-line:no-unused-variable

export interface IManagerProducerInvitationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class ManagerProducerInvitation extends React.Component<IManagerProducerInvitationProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { managerProducerInvitationList, match } = this.props;
    return (
      <div>
        <h2 id="manager-producer-invitation-heading">
          <Translate contentKey="resitrackApp.managerProducerInvitation.home.title">Manager Producer Invitations</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="resitrackApp.managerProducerInvitation.home.createLabel">
              Create new Manager Producer Invitation
            </Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {managerProducerInvitationList && managerProducerInvitationList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.managerProducerInvitation.invitationStatus">Invitation Status</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.managerProducerInvitation.producer">Producer</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.managerProducerInvitation.manager">Manager</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {managerProducerInvitationList.map((managerProducerInvitation, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${managerProducerInvitation.id}`} color="link" size="sm">
                        {managerProducerInvitation.id}
                      </Button>
                    </td>
                    <td>
                      <Translate contentKey={`resitrackApp.InvitationStatus.${managerProducerInvitation.invitationStatus}`} />
                    </td>
                    <td>
                      {managerProducerInvitation.producer ? (
                        <Link to={`producer/${managerProducerInvitation.producer.id}`}>{managerProducerInvitation.producer.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {managerProducerInvitation.manager ? (
                        <Link to={`manager/${managerProducerInvitation.manager.id}`}>{managerProducerInvitation.manager.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${managerProducerInvitation.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${managerProducerInvitation.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${managerProducerInvitation.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="resitrackApp.managerProducerInvitation.home.notFound">No Manager Producer Invitations found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ managerProducerInvitation }: IRootState) => ({
  managerProducerInvitationList: managerProducerInvitation.entities,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerProducerInvitation);

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './final-destination-update-menu.scss';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from 'app/modules/account/register/full-register/steps/steps';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS } from 'app/config/constants';
import { getSession } from 'app/shared/reducers/authentication';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import { reset, updateHolding, updateSubsidiary } from '../../final-destination-units-reducer';
import {
    getFinalDestinationDetailById,
    reset as resetFinalDestination
} from 'app/entities/user-final-destination/user-final-destination.reducer';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import FinalDestinationRegisterAddress from '../../steps/address/final-destination-register-address';
import FinalDestinationRegisterResponsible from '../../steps/responsible/final-destination-register-responsible';
import FinalDestinationRegisterDocumentation from '../../steps/documentation/final-destination-register-documentation';
import userFinalDestination from 'app/entities/user-final-destination/user-final-destination';


export interface IFinalDestinationUpdateMenuProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IFinalDestinationUpdateMenuState {
  isSubsidiary: boolean;
  steps: StepItem[];
  currentIndex: number;
  userFinalDestination: IUserFinalDestination;
  showResponsabilityModal: boolean;
  showErrorModal: boolean;
}

export class FinalDestinationUpdateMenu extends React.Component<IFinalDestinationUpdateMenuProps, IFinalDestinationUpdateMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userFinalDestination: {},
      isSubsidiary: null,
      steps: null,
      currentIndex: 0,
      showResponsabilityModal: false,
      showErrorModal: false,
    };
  }

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  componentDidMount() {
    this.props.resetFinalDestination();
    const { id } = (this.props.location.state as any) || { id: null };
    if (id) {
      this.props.getFinalDestinationDetailById(id);
    } else {
      this.props.history.goBack();
    }
  }

  onNext = (userFinalDestination: IUserFinalDestination) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsabilityModal: true,
        userFinalDestination,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userFinalDestination,
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (
      newProps.finalDestinationIdDetail != null &&
      newProps.finalDestinationDetailIdStatus != this.props.finalDestinationDetailIdStatus &&
      newProps.finalDestinationDetailIdStatus === HttpRequestStatus.SUCCESS
    ) {
      this.setState({userFinalDestination: newProps.finalDestinationIdDetail});
      this.setSteps(newProps.finalDestinationIdDetail?.finalDestination?.isSubsidiary);
    }
    if (newProps.updateHoldingStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.updateSubsidiaryStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.updateHoldingStatus != this.props.updateHoldingStatus && newProps.updateHoldingStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
    if (
      newProps.updateSubsidiaryStatus != this.props.updateSubsidiaryStatus &&
      newProps.updateSubsidiaryStatus === HttpRequestStatus.ERROR
    ) {
      this.toggleErrorModal();
    }
  }

  goToSuccessAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.finalDestinationSubsidiaryUpdate',
        buttonUrl: '/final-destination/destination',
      },
    });
  };

  onCancelModal = () => {
    this.setState({
      showResponsabilityModal: false,
    });
  };

  onConfirmModal = () => {
    const { userFinalDestination } = this.state;
    if (userFinalDestination.finalDestination?.isSubsidiary) {
      this.props.updateSubsidiary(userFinalDestination);
    } else {
      this.props.updateHolding(userFinalDestination);
    }
  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  setSteps = (isSubsidiary: boolean) => {
    if (isSubsidiary) {
      this.setState({
        steps: this.mapStepsSubsidiary(),
      });
    } else {
      this.setState({
        steps: this.mapStepsHolding(),
      });
    }
  };

  private mapStepsHolding = (): StepItem[] => [
    {
      title: translate('fullRegister.header.responsible'),
      render: () => (
        <FinalDestinationRegisterResponsible isNew={true} onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />
      ),
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <FinalDestinationRegisterAddress onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />,
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => <FinalDestinationRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />,
    },
  ];

  private mapStepsSubsidiary = (): StepItem[] => [
    {
      title: translate('fullRegister.header.responsible'),
      render: () => (
        <FinalDestinationRegisterResponsible isNew={true} onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />
      ),
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <FinalDestinationRegisterAddress onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />,
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => <FinalDestinationRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userFinalDestination={this.state.userFinalDestination} />,
    },
  ];

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  handleGoBack = () => {
    this.props.history.replace('/final-destination/destination');
  };

  render() {
    const { steps, currentIndex } = this.state;
    const { finalDestinationDetailIdStatus, updateHoldingStatus, updateSubsidiaryStatus } = this.props;
    if (checkStatusOnGoing([finalDestinationDetailIdStatus, updateHoldingStatus, updateSubsidiaryStatus])) {
      return <Loading />;
    }

    return (
      <div className="producer-update-menu__items" style={{ position: 'relative' }}>
        <BackHeaderComponent onBack={this.handleGoBack} />
        {steps && <Steps steps={steps} onIndexClick={this.onIndexClick} currentIndex={currentIndex} />}
        <AlertModal
          showModal={this.state.showResponsabilityModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={this.onConfirmModal}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="producerRegister.responsibilityTerms"
        />
        <AlertModal
          showModal={this.state.showErrorModal}
          buttonTwoActionMessage={'entity.action.ok'}
          buttonTwoAction={this.toggleErrorModal}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.finalDestinationUpdate"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSession,
  getFinalDestinationDetailById,
  updateSubsidiary,
  updateHolding,
  reset,
  resetFinalDestination,
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  finalDestinationDetailIdStatus: root.userFinalDestination.finalDestinationDetailIdStatus,
  finalDestinationIdDetail: root.userFinalDestination.finalDestinationIdDetail,
  updateSubsidiaryStatus: root.finalDestinationUnits.updateSubsidiaryStatus,
  updateHoldingStatus: root.finalDestinationUnits.updateHoldingStatus,
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationUpdateMenu);

import mtrSignatureApi from 'app/api/mtrSignatureApi';
import { HttpStatusNumber } from 'app/shared/model/enum/HttpStatusNumber';
import { MtrReport } from 'app/shared/model/mtr-report.model';
import { AxiosResponse } from 'axios';

const MtrSignatureService = () => {
  const sign = async (id: MtrReport, signature: any): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await mtrSignatureApi.signMtr(id, signature);
      if (result.status === HttpStatusNumber.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else {
        throw {};
      }
    } catch {
      return Promise.reject();
    }
  };
  return {
    sign,
  };
};

export default MtrSignatureService();

import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './authorization.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAuthorizationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export class AuthorizationDetail extends React.Component<IAuthorizationDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { authorizationEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.authorization.detail.title">Authorization</Translate> [<b>{authorizationEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="amount">
                <Translate contentKey="resitrackApp.authorization.amount">Amount</Translate>
                <Translate contentKey="resitrackApp.authorization.amountReceipt">amountReceipt</Translate>
              </span>
            </dt>
            <dd>{authorizationEntity.amount}
              {authorizationEntity.amountReceipt}</dd>
            <dt>
              <span id="expirationDate">
                <Translate contentKey="resitrackApp.authorization.expirationDate">Expiration Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={authorizationEntity.expirationDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="measurementType">
                <Translate contentKey="resitrackApp.authorization.measurementType">Measurement Type</Translate>
              </span>
            </dt>
            <dd>{authorizationEntity.measurementType}</dd>
            <dt>
              <span id="residueType">
                <Translate contentKey="resitrackApp.authorization.residueType">Residue Type</Translate>
              </span>
            </dt>
            <dd>{authorizationEntity.residueType}</dd>
            <dt>
              <Translate contentKey="resitrackApp.authorization.authorizationDocument">Authorization Document</Translate>
            </dt>
            <dd>{authorizationEntity.authorizationDocument ? authorizationEntity.authorizationDocument.id : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.authorization.producer">Producer</Translate>
            </dt>
            <dd>{authorizationEntity.producer ? authorizationEntity.producer.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/authorization" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/authorization/${authorizationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ authorization }: IRootState) => ({
  authorizationEntity: authorization.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationDetail);

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {TextFormat, Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {getEntities} from './transport-license.reducer';

export interface ITransportLicenseProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class TransportLicense extends React.Component<ITransportLicenseProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { transportLicenseList, match } = this.props;
    return (
      <div>
        <h2 id="transport-license-heading">
          <Translate contentKey="resitrackApp.transportLicense.home.title">Transport Licenses</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="resitrackApp.transportLicense.home.createLabel">Create new Transport License</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {transportLicenseList && transportLicenseList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.transportLicense.licenseNumber">License Number</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.transportLicense.expireDate">Expire Date</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.transportLicense.issuingBody">Issuing Body</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.transportLicense.transportLicenseType">Transport License Type</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.transportLicense.licenseDocument">License Document</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.transportLicense.transporter">Transporter</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {transportLicenseList.map((transportLicense, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${transportLicense.id}`} color="link" size="sm">
                        {transportLicense.id}
                      </Button>
                    </td>
                    <td>{transportLicense.licenseNumber}</td>
                    <td>
                      <TextFormat type="date" value={transportLicense.expireDate} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                    <td>{transportLicense.issuingBody}</td>
                    <td>
                      <Translate contentKey={`resitrackApp.TransportLicenseType.${transportLicense.transportLicenseType}`} />
                    </td>
                    <td>
                      {transportLicense.licenseDocument ? (
                        <Link to={`document/${transportLicense.licenseDocument.id}`}>{transportLicense.licenseDocument.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {transportLicense.transporter ? (
                        <Link to={`transporter/${transportLicense.transporter.id}`}>{transportLicense.transporter.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${transportLicense.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${transportLicense.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${transportLicense.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="resitrackApp.transportLicense.home.notFound">No Transport Licenses found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ transportLicense }: IRootState) => ({
  transportLicenseList: transportLicense.entities,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransportLicense);

import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, UserTransshipment } from 'app/shared/model/user-transshipment.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudGetEmptyAction } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_USER_TRANSSHIPMENT_LIST: 'user_transshipment/FETCH_USER_TRANSSHIPMENT_LIST',
  FETCH_USER_TRANSSHIPMENT: 'user_transshipment/FETCH_USER_TRANSSHIPMENT',
  CREATE_USER_TRANSSHIPMENT: 'user_transshipment/CREATE_USER_TRANSSHIPMENT',
  UPDATE_USER_TRANSSHIPMENT: 'user_transshipment/UPDATE_USER_TRANSSHIPMENT',
  DELETE_USER_TRANSSHIPMENT: 'user_transshipment/DELETE_USER_TRANSSHIPMENT',
  GET_TRANSSHIPMENT_DETAIL_UUID: 'user_transshipment/GET_TRANSSHIPMENT_DETAIL_UUID',
  RESET: 'user_transshipment/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<UserTransshipment>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  transshipmentUuidDetail: defaultValue,
  transshipmentDetailUuidStatus: HttpRequestStatus.NOOP,
};

export type UserTransshipmentState = Readonly<typeof initialState>;

// Reducer

export default (state: UserTransshipmentState = initialState, action): UserTransshipmentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USER_TRANSSHIPMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USER_TRANSSHIPMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USER_TRANSSHIPMENT):
    case REQUEST(ACTION_TYPES.UPDATE_USER_TRANSSHIPMENT):
    case REQUEST(ACTION_TYPES.DELETE_USER_TRANSSHIPMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USER_TRANSSHIPMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USER_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.CREATE_USER_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.UPDATE_USER_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.DELETE_USER_TRANSSHIPMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER_TRANSSHIPMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER_TRANSSHIPMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USER_TRANSSHIPMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_USER_TRANSSHIPMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USER_TRANSSHIPMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };

    case REQUEST(ACTION_TYPES.GET_TRANSSHIPMENT_DETAIL_UUID):
      return {
        ...state,
        errorMessage: null,
        transshipmentDetailUuidStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSSHIPMENT_DETAIL_UUID):
      return {
        ...state,
        transshipmentDetailUuidStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSSHIPMENT_DETAIL_UUID):
      return {
        ...state,
        transshipmentDetailUuidStatus: HttpRequestStatus.SUCCESS,
        transshipmentUuidDetail: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-transshipments';

// Actions

export const getEntities: ICrudGetAllAction<UserTransshipment> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_USER_TRANSSHIPMENT_LIST,
  payload: axios.get<UserTransshipment>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<UserTransshipment> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USER_TRANSSHIPMENT,
    payload: axios.get<UserTransshipment>(requestUrl),
  };
};

export const getEntityByTransshipment: ICrudGetAction<UserTransshipment> = id => {
  const requestUrl = `${apiUrl}/transshipment/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USER_TRANSSHIPMENT,
    payload: axios.get<UserTransshipment>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<UserTransshipment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER_TRANSSHIPMENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<UserTransshipment> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USER_TRANSSHIPMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<UserTransshipment> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USER_TRANSSHIPMENT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const getTransshipmentDetailByUuid: ICrudGetEmptyAction<UserTransshipment> = () => {
  const requestUrl = `${apiUrl}/details`;
  return {
    type: ACTION_TYPES.GET_TRANSSHIPMENT_DETAIL_UUID,
    payload: axios.get<UserTransshipment>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import React from 'react';
import CustomMenuItem from 'app/shared/layout/menus/common/custom-menu-item/custom-menu-item';
import { Translate } from 'react-jhipster';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { MenuDropDown } from './producer-menu-dropdonw'
import { ProfileType } from '../../../model/enum/Profile-dropdonw'
import PlanService from 'app/services/PlanService';
import { ITypePlan } from 'app/shared/model/plan.model';

export interface IProducerMenuProps {
    isAdmin: boolean;
    isUser: boolean;
    currentUrl: string;
    userProducer: IUserProducer;
}

export interface IProducerMenuState {
    subCategorySelected: boolean;
    plan: ITypePlan | void;
}

export class ProducerMenu extends React.Component<IProducerMenuProps, IProducerMenuState> {
    constructor(props) {
        super(props);
        this.state = {
            subCategorySelected: false,
            plan: null,
        };
    }

    checkSubcategorySelected = (path: string) => {
        const { currentUrl } = this.props;
        return currentUrl.indexOf(path) != -1;
    };

    async componentDidMount() {
        await this.setState({
            plan: await PlanService.getPlan().then((res) => {
                return res
            }).catch((error) => console.log(error))
        })
    }

    render() {
        const { isAdmin, isUser, currentUrl, userProducer } = this.props;
        // const active = this.checkSubcategorySelected('/contractor/rides');

        return (
            <div style={{ display: 'flex' }}>

                <MenuDropDown currentUrl={this.props.currentUrl} profile={ProfileType.PRODUCER} />

                <CustomMenuItem id={'units'} to="/mtr-units" active={this.checkSubcategorySelected("/mtr-units")}>
                    <Translate contentKey={'header.producer.units'}>Units</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'residues'} to="/producer/residues" active={this.checkSubcategorySelected('/producer/residues')}>
                    <Translate contentKey={'header.common.residues'}>MTR</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'authorizations'} to="/producer/authorizations" active={this.checkSubcategorySelected('/producer/authorizations')}>
                    <Translate contentKey={'header.common.authorizations'}>MTR</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'invite'} to="/producer/summary" active={this.checkSubcategorySelected('/producer/summary')}>
                    <Translate contentKey={'header.common.invite'}>Invite</Translate>
                </CustomMenuItem>
                <CustomMenuItem id={'cdf'} to="/producer/cdf" active={this.checkSubcategorySelected("/producer/cdf")}>
                    <Translate contentKey={'header.finalDestination.cdf'}>CDF</Translate>
                </CustomMenuItem>
            </div>
        );
    }
}

export default ProducerMenu;

import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IIntegrationUser } from 'app/shared/model/integration-user.model';
import { PageableResponse } from 'app/shared/model/pageable';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
  FETCH_INTEGRATION_USERS: 'integration-user/FETCH_INTEGRATION_USERS',
  FETCH_INTEGRATION_USER: 'integration-user/FETCH_INTEGRATION_USER',
  CREATE_INTEGRATION_USER: 'integration-user/CREATE_INTEGRATION_USERS',
  UPDATE_INTEGRATION_USER: 'integration-user/UPDATE_INTEGRATION_USERS',
  DELETE_INTEGRATION_USER: 'integration-user/DELETE_INTEGRATION_USERS',
  RESET: 'integration-user/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  integrationUsers: [] as Array<IIntegrationUser>,
  integrationUser: {} as IIntegrationUser,
  updateIntegrationUserStatus: HttpRequestStatus.NOOP,
  fetchIntegrationUsersStatus: HttpRequestStatus.NOOP,
  fetchIntegrationUserStatus: HttpRequestStatus.NOOP,
  deleteIntegrationUserStatus: HttpRequestStatus.NOOP,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

export type IntegrationUserState = Readonly<typeof initialState>;

// Reducer

export default (state: IntegrationUserState = initialState, action): IntegrationUserState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_INTEGRATION_USER):
    case REQUEST(ACTION_TYPES.UPDATE_INTEGRATION_USER):
      return {
        ...state,
        errorMessage: null,
        updateIntegrationUserStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_INTEGRATION_USER):
    case FAILURE(ACTION_TYPES.UPDATE_INTEGRATION_USER):
      return {
        ...state,
        errorMessage: action.payload,
        updateIntegrationUserStatus: HttpRequestStatus.ERROR,
        updateSuccess: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INTEGRATION_USER):
    case SUCCESS(ACTION_TYPES.UPDATE_INTEGRATION_USER):
      return {
        ...state,
        errorMessage: null,
        updateIntegrationUserStatus: HttpRequestStatus.SUCCESS,
        updateSuccess: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_INTEGRATION_USER):
      return {
        ...state,
        errorMessage: null,
        fetchIntegrationUserStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_INTEGRATION_USER):
      return {
        ...state,
        fetchIntegrationUserStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INTEGRATION_USER):
      return {
        ...state,
        fetchIntegrationUserStatus: HttpRequestStatus.SUCCESS,
        integrationUser: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.FETCH_INTEGRATION_USERS):
      return {
        ...state,
        errorMessage: null,
        fetchIntegrationUsersStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_INTEGRATION_USERS):
      return {
        ...state,
        fetchIntegrationUsersStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INTEGRATION_USERS):
      return {
        ...state,
        fetchIntegrationUsersStatus: HttpRequestStatus.SUCCESS,
        integrationUsers: action.payload.data.content,
        totalItems: action.payload.data.totalElements,
      };
    case REQUEST(ACTION_TYPES.DELETE_INTEGRATION_USER):
      return {
        ...state,
        errorMessage: null,
        deleteIntegrationUserStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.DELETE_INTEGRATION_USER):
      return {
        ...state,
        deleteIntegrationUserStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DELETE_INTEGRATION_USER):
      return {
        ...state,
        deleteIntegrationUserStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/integration-users';

// Actions
export const getIntegrationUsers: ICrudGetAllAction<IIntegrationUser> = (page, size, sort) => {
  const params = organizeSearchParameters(page, size, sort, null);
  return {
    type: ACTION_TYPES.FETCH_INTEGRATION_USERS,
    payload: axios.get<IIntegrationUser>(`${apiUrl}/all${params}`),
  };
};

export const getIntegrationUser: ICrudGetAction<IIntegrationUser> = (userId: number) => ({
  type: ACTION_TYPES.FETCH_INTEGRATION_USER,
  payload: axios.get<IIntegrationUser>(`${apiUrl}/${userId}`),
});

export const updateIntegrationUser: ICrudPutAction<IIntegrationUser> = (entity: IIntegrationUser) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INTEGRATION_USER,
    payload: axios.put(`${apiUrl}`, cleanEntity(entity)),
  });
  return result;
};

export const saveIntegrationUser: ICrudPutAction<IIntegrationUser> = (entity: IIntegrationUser) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INTEGRATION_USER,
    payload: axios.post(`${apiUrl}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteIntegrationUser: ICrudDeleteAction<IIntegrationUser> = (id: number) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INTEGRATION_USER,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

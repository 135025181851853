import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm } from 'availity-reactstrap-validation';
import { Col, Row } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { ALERT_ICONS } from 'app/config/constants';
import Loading from 'app/components/loading/loading';
import './password-reset-init.scss';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import { ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import { validateEmail } from 'app/modules/validation/validation-constants';

export interface IPasswordResetInitProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

interface IPasswordResetInitState {
  email: string;
  emailError: ValidationResult;
}

export class PasswordResetInit extends React.Component<IPasswordResetInitProps, IPasswordResetInitState> {
  constructor(props: Readonly<IPasswordResetInitProps>) {
    super(props);
    this.state = {
      emailError: ValidationResultInstance,
      email: null,
    };
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = (event, values) => {
    const { emailError, email } = this.state;
    if (emailError.hasError) {
      return;
    }
    this.props.handlePasswordResetInit(email);
    event.preventDefault();
  };

  componentWillReceiveProps(newProps) {
    if (newProps.resetPasswordSuccess) {
      this.goToSuccessAlertPage();
      this.props.reset();
    }
  }

  goToSuccessAlertPage = () => {
    this.props.history.push({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.passwordResetInit',
        buttonUrl: '/login',
        messageParams: { email: this.state.email },
      },
    });
  };

  onChangeEmail = value => {
    this.setState({
      email: value,
    });
  };

  onValidateLogin = () => {
    this.setState({
      emailError: validateEmail(this.state.email),
    });
  };

  render() {
    const { loading } = this.props;
    const { email, emailError } = this.state;
    return (
      <div className="password-reset__container">
        {loading && <Loading />}
        <Row>
          <Col style={{ margin: 'auto' }} md="4">
            <div className="password-reset__container--itens">
              <div className="password-reset__container--title">
                <span className="title-password-property">
                  {' '}
                  <Translate contentKey="password.reset.title" />{' '}
                </span>
                <Translate contentKey="password.reset.title-second" />
              </div>
              <AvForm id="register-form" onValidSubmit={this.handleValidSubmit}>
                <CustomTextField
                  onChange={this.onChangeEmail}
                  id="username"
                  isEmail
                  value={email}
                  label={translate('userLogin.placeholders.email')}
                  placeholder={translate('userLogin.placeholders.email')}
                  error={emailError.hasError}
                  errorText={emailError.errorMessage ? translate(emailError.errorMessage) : ''}
                  onBlur={this.onValidateLogin}
                />
                <div className={'button-container'}>
                  <button className={'button primary'} type={'submit'}>
                    <Translate contentKey="reset.request.form.button">Reset password</Translate>
                  </button>
                </div>
              </AvForm>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (root: IRootState) => ({
  resetPasswordSuccess: root.passwordReset.resetPasswordSuccess,
  loading: root.passwordReset.loading,
});
const mapDispatchToProps = { handlePasswordResetInit, reset };

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetInit);

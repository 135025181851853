import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ISpecificResidue } from 'app/shared/model/specific-residue.model';

export const ACTION_TYPES = {
  FETCH_SPECIFICRESIDUE_LIST: 'specificResidue/FETCH_SPECIFICRESIDUE_LIST',
  FETCH_SPECIFICRESIDUE: 'specificResidue/FETCH_SPECIFICRESIDUE',
  CREATE_SPECIFICRESIDUE: 'specificResidue/CREATE_SPECIFICRESIDUE',
  UPDATE_SPECIFICRESIDUE: 'specificResidue/UPDATE_SPECIFICRESIDUE',
  DELETE_SPECIFICRESIDUE: 'specificResidue/DELETE_SPECIFICRESIDUE',
  RESET: 'specificResidue/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISpecificResidue>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type SpecificResidueState = Readonly<typeof initialState>;

// Reducer

export default (state: SpecificResidueState = initialState, action): SpecificResidueState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SPECIFICRESIDUE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SPECIFICRESIDUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SPECIFICRESIDUE):
    case REQUEST(ACTION_TYPES.UPDATE_SPECIFICRESIDUE):
    case REQUEST(ACTION_TYPES.DELETE_SPECIFICRESIDUE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SPECIFICRESIDUE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SPECIFICRESIDUE):
    case FAILURE(ACTION_TYPES.CREATE_SPECIFICRESIDUE):
    case FAILURE(ACTION_TYPES.UPDATE_SPECIFICRESIDUE):
    case FAILURE(ACTION_TYPES.DELETE_SPECIFICRESIDUE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SPECIFICRESIDUE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SPECIFICRESIDUE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SPECIFICRESIDUE):
    case SUCCESS(ACTION_TYPES.UPDATE_SPECIFICRESIDUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SPECIFICRESIDUE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/specific-residues';

// Actions

export const getEntities: ICrudGetAllAction<ISpecificResidue> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SPECIFICRESIDUE_LIST,
  payload: axios.get<ISpecificResidue>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<ISpecificResidue> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SPECIFICRESIDUE,
    payload: axios.get<ISpecificResidue>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ISpecificResidue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SPECIFICRESIDUE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISpecificResidue> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SPECIFICRESIDUE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISpecificResidue> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SPECIFICRESIDUE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import AlertModal from 'app/components/alert-modal/alert-modal';
import { AlertModalPlanRestricted } from 'app/components/alertModalPlanRestricted/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import Loading from 'app/components/loading/loading';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import { ALERT_ICONS } from 'app/config/constants';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { InvitationStatus } from 'app/shared/model/manager-producer-invitation.model';
import { IProducer } from 'app/shared/model/producer.model';
import { IRootState } from 'app/shared/reducers';
import { maskCPF, maskCnpj } from 'app/shared/util/mask-utils';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { createInvitation, getAllProducers, reset } from '../../manager-link-reducer';
import './producer-link.scss';

export interface IProducerLinkProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IProducerLinkState {
  showErrorModal: boolean;
  showErrorModalPlan: boolean;
  premium: boolean;
  selectedProducers: IProducer[];
}

export class ProducerLink extends React.Component<IProducerLinkProps, IProducerLinkState> {
  constructor(props: Readonly<IProducerLinkProps>) {
    super(props);
    this.state = {
      showErrorModal: false,
      showErrorModalPlan: false,
      premium: false,
      selectedProducers: [],
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.props.getAllProducers();
  }

  componentWillReceiveProps(newProps) {
    const messageErrorPlanFree = "Você está com o plano free ativo, e tem direito a apenas 3 geradores. Para habilitar mais geradores, é necessário assinar o plano Premium"
    const messageErrorPlanPremium = "Você tem direito a 30 geradores por conta. Caso tenha alguma dúvida, entre em contato com o suporte da Resitrack"
    if (newProps.createInvitationStatus === HttpRequestStatus.SUCCESS) {
      this.goToSuccessRegisterAlertPage();
      this.props.reset();
    }

    if (newProps.createInvitationStatus === HttpRequestStatus.ERROR && (!newProps.planErrorMessage?.message?.includes(messageErrorPlanFree) && !newProps.planErrorMessage?.message?.includes(messageErrorPlanPremium))) {
      this.toggleErrorModal();
    }

    if (newProps?.planErrorMessage?.message?.includes(messageErrorPlanFree)) {
      this.toggleErrorModalPlan();
    } else if (newProps?.planErrorMessage?.message?.includes(messageErrorPlanPremium)) {
      this.toggleErrorModalPlan(true);
    }
  }

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  toggleErrorModalPlan = (premium: boolean = false) => {
    this.setState({
      showErrorModalPlan: !this.state.showErrorModalPlan,
      premium,
    });
  };

  closeModal = () => {
    this.setState({
      showErrorModalPlan: !this.state.showErrorModalPlan,
    });
  };

  onBackHandler = () => {
    this.props.history.replace(`/manager/producers`);
  };

  goToSuccessRegisterAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.linkManager',
        buttonUrl: '/manager/producers',
      },
    });
  };

  mapProducersToSearch = () => {
    const { producers } = this.props;

    const objects = producers.map(producer => {
      let companyName = '';
      let cnpj = '';

      // Verifica se é uma pessoa jurídica
      if (producer.legalPerson) {
        companyName = producer.legalPerson.companyName;
        cnpj = maskCnpj(producer.legalPerson.cnpj);
      } else if (producer.naturalPerson) {
        companyName = producer.naturalPerson.name;
        cnpj = maskCPF(producer.naturalPerson.cpf);
      }

      // Retorne o objeto com o nome da empresa e o CNPJ
      return { name: `${companyName} | ${cnpj}`, id: producer.id };
    });

    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  onClickProducer = (item: any) => {
    const { producers } = this.props;
    this.setState({
      selectedProducers: producers.filter(it => it.id === item.id),
    });
  };

  search = () => { };

  onLink = () => {
    const { selectedProducers } = this.state;

    if (selectedProducers.length === 0) {
      return;
    }

    this.props.createInvitation({
      producer: selectedProducers[0],
      invitationStatus: InvitationStatus.PENDING,
    });
  };

  render() {
    const { createInvitationStatus } = this.props;
    const { selectedProducers } = this.state;
    if (checkStatusOnGoing([createInvitationStatus])) {
      return <Loading />;
    }

    return (
      <div>
        <div style={{ paddingTop: '70px' }}>
          <BackHeaderComponent hasButton={false} onBack={this.onBackHandler} />
          <Container>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
              <Col md="6">
                <h1 style={{ marginBottom: '30px' }}>{translate('manager.producer.link.title')}</h1>
                <DropdownSearch
                  data={this.mapProducersToSearch()}
                  getData={this.search}
                  onClickItem={this.onClickProducer}
                  placeholder={selectedProducers[0] ? maskCnpj(selectedProducers[0].legalPerson.cnpj) : null}
                  title={translate('manager.producer.link.search')}
                  style={
                    selectedProducers[0] ? { backgroundColor: 'white', borderTop: '1px solid #9ac91c' } : { backgroundColor: '#f6f6f6' }
                  }
                  notAlphabeticalOrder
                  showInsiderSelect
                  showSearchField
                  filterLocalData
                  showSelectedStyle
                />
                {/* <DropdownSearch
                                    showSearchField
                                    filterLocalData
                                    showInsiderSelect
                                    notAlphabeticalOrder
                                    style={{ backgroundColor: '#f6f6f6' }}
                                    title={hasFinalDestinationAuthorities ? translate('cdf.selectGenerator') : translate('cdf.selectFinalDestination')}
                                    data={this.mapAllProducersOrFinalDestinations()}
                                    getData={this.getAllProducersOrFinalDestinations}
                                    onClickItem={this.setSelectedProducerOrFinalDestination}
                                    selectedData={selectedProducerOrFinalDestination.id != null ? selectedProducerOrFinalDestination : undefined}
                                /> */}
                <NextCancelButton
                  cancelButtonName={translate('entity.action.cancel')}
                  onCancelClick={this.onBackHandler}
                  nextButtonName={translate('entity.action.save')}
                  onNextClick={this.onLink}
                  disableNextButton={selectedProducers.length === 0}
                  noArrow
                />
              </Col>
            </Row>
          </Container>

          <AlertModal
            showModal={this.state.showErrorModal}
            buttonTwoActionMessage={'entity.action.ok'}
            buttonTwoAction={this.toggleErrorModal}
            statusImage="bag-error.svg"
            alertMessage={'errorMessages.linkProducer'}
          />
          <AlertModalPlanRestricted closeModal={() => this.closeModal()} showModal={this.state.showErrorModalPlan} title='exceededProducer' description={this.props?.planErrorMessage?.message} premium={this.state.premium} history={() => this.props.history.push('/full-register/plans')} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  planErrorMessage: root.managerlink.planErrorMessage,
  createInvitationStatus: root.managerlink.createInvitationStatus,
  fetchAllProducersStatus: root.managerlink.fetchAllProducersStatus,
  producers: root.managerlink.producers,
});

const mapDispatchToProps = {
  createInvitation,
  getAllProducers,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerLink);

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './transporter-register-menu.scss';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from '../../steps/steps';
import { IUserTransporter } from 'app/shared/model/user-transporter.model';
import { TransporterRegisterPerson } from '../steps/person/transporter-register-person';
import { TransporterRegisterAddress } from '../steps/address/transporter-register-address';
import { TransporterRegisterResponsible } from '../steps/responsible/transporter-register-responsible';
import { TransporterRegisterDocumentation } from '../steps/documentation/transporter-register-documentation';
import { createLegal, createNatural, reset } from '../transporter-register.reducer';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS, AUTHORITIES } from 'app/config/constants';
import { changeAuthority, getSession } from 'app/shared/reducers/authentication';
import { TransporterRegisterDriver } from '../steps/driverLicense/transporter-register-driver';
import { PersonType } from 'app/shared/model/transporter.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getTransporterDetailByUuid, updateTransporter } from 'app/entities/user-transporter/user-transporter.reducer';

export interface ITransporterRegisterMenuProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface ITransporterRegisterMenuState {
  steps: StepItem[];
  currentIndex: number;
  userTransporter: IUserTransporter;
  showResponsabilityModal: boolean;
  isEdit: boolean;
}

export class TransporterRegisterMenu extends React.Component<ITransporterRegisterMenuProps, ITransporterRegisterMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userTransporter: {
        user: this.props.account,
      },
      steps: this.onMapSteps(null),
      currentIndex: 0,
      showResponsabilityModal: false,
      isEdit: false,
    };
  }

  componentDidMount() {
    if (this.props.location.pathname.indexOf('/full-register/transporter/edit') >= 0) {
      this.setState({ isEdit: true })
      this.props.getTransporterDetailByUuid()
        .then((response) => this.setState({ userTransporter: response.value.data }))
    }
  }

  private mapLegalSteps = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => <TransporterRegisterPerson onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />,
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <TransporterRegisterAddress onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />,
    },
    {
      title: translate('fullRegister.header.responsible'),
      render: () => (
        <TransporterRegisterResponsible onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />
      ),
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => (
        <TransporterRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />
      ),
    },
  ];

  private mapNaturalSteps = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => <TransporterRegisterPerson onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />,
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <TransporterRegisterAddress onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />,
    },
    {
      title: translate('fullRegister.header.driverLicense'),
      render: () => <TransporterRegisterDriver onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />,
    },
    {
      title: translate('fullRegister.header.documentation'),
      render: () => (
        <TransporterRegisterDocumentation onNext={this.onNext} onBack={this.onBack} userTransporter={this.state.userTransporter} />
      ),
    },
  ];

  onMapSteps = (userTransporter: IUserTransporter) => {
    if (userTransporter != null && userTransporter.transporter != null && userTransporter.transporter.personType === PersonType.NATURAL) {
      return this.mapNaturalSteps();
    } else {
      return this.mapLegalSteps();
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.createTransporterStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.createTransporterStatus === HttpRequestStatus.ERROR) {
      this.goToErrorAlertPage();
      this.props.reset();
    }
  }



  goToErrorAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_NO,
        alertMessage: 'alert.transporterRegisterError',
        buttonUrl: '/full-register/transporter',
      },
    });
  }

  goToSuccessAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.transporterRegister',
        buttonUrl: '/login',
      },
    });
  };

  onCancelModal = () => {
    this.setState({
      showResponsabilityModal: false,
    });
  };

  onConfirmModal = () => {
    const { userTransporter, isEdit } = this.state;
    if (isEdit) {
      this.props.updateTransporter(userTransporter)
    } else {
      if (userTransporter.transporter.personType === PersonType.NATURAL) {
        this.props.createNatural(userTransporter);
      } else {
        this.props.createLegal(userTransporter);
      }
    }

    if (this.props.hasTransporterAdminAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.TRANSPORTER_ADMIN);
    } else if (this.props.hasTransporterUserAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.TRANSPORTER_USER);
    }
  };

  onNext = (userTransporter: IUserTransporter) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsabilityModal: true,
        userTransporter,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userTransporter,
        steps: this.onMapSteps(userTransporter),
      });
    }
  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  render() {
    const { steps, currentIndex } = this.state;
    const { createTransporterStatus } = this.props;
    if (createTransporterStatus === HttpRequestStatus.ONGOING) {
      return <Loading />;
    }
    return (
      <div style={{ position: 'relative' }}>
        <Steps onIndexClick={this.onIndexClick} steps={steps} currentIndex={currentIndex} />
        <AlertModal
          showModal={this.state.showResponsabilityModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={this.onConfirmModal}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="transporterRegister.responsibilityTerms"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSession,
  createLegal,
  createNatural,
  reset,
  changeAuthority,
  getTransporterDetailByUuid,
  updateTransporter
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  createTransporterStatus: root.transporterRegister.createTransporterStatus,
  isCreatingNewProfile: root.authentication.isCreatingNewProfile,
  hasTransporterUserAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSPORTER_USER]),
  hasTransporterAdminAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.TRANSPORTER_ADMIN])
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransporterRegisterMenu);

import { IAddress } from 'app/shared/model/address.model';
import { ICnaeCode } from 'app/shared/model/cnae-code.model';
import { IDocument } from 'app/shared/model/document.model';
import { IEnvironmentalLicense } from './environmental-license.model';
import { ProducerUnit } from './unit';

export enum GeneratorType {
  AGRICULTURE = 'AGRICULTURE',
  TRADE = 'TRADE',
  CONSTRUCTION = 'CONSTRUCTION',
  EDUCATION = 'EDUCATION',
  INDUSTRY = 'INDUSTRY',
  MINING = 'MINING',
  LABORATORY = 'LABORATORY',
  HEALTH_SERVICES = 'HEALTH_SERVICES',
  RECYCLING = 'RECYCLING',
  PUBLIC_AGENCY = 'PUBLIC_AGENCY',
  TRANSPORTATION = 'TRANSPORTATION',
  SERVICES = 'SERVICES',
}

export const enum GeneratorCompanyType {
  HOLDING = 'HOLDING',
  SUBSIDIARY = 'SUBSIDIARY',
  NATURAL = 'NATURAL',
}

export const enum ActivityType {
  ACTIVITY = 'ACTIVITY',
}

export interface IProducer {
  id?: number;
  registrationNumber?: string;
  generatorType?: GeneratorType;
  generatorCompanyType?: GeneratorCompanyType;
  companyName?: string;
  address?: IAddress;
  cnaeCodes?: ICnaeCode[];
  businessLicense?: IDocument;
  environmentalLicenses?: IEnvironmentalLicense[];
  naturalPerson?: NaturalPerson;
  legalPerson?: LegalPerson;
  holdingCnpj?: string;
  activityType?: string;
}

export interface NaturalPerson {
  id?: number;
  cpf: string;
  name?: string;
  phone?: string;
  municipalRegistration?: string;
}

export interface LegalPerson {
  id?: number;
  cnpj: string;
  fantasyName?: string;
  companyName?: string;
  phone?: string;
  municipalRegistration?: string;
}

export interface IProducerMtr {
  id?: number;
  companyName?: string;
  activityType?: string;
  address?: IAddress;
  addressLabel?: string;
  addressZipcode?: string;
  naturalPerson?: NaturalPerson;
  legalPerson?: LegalPerson;
}

export function mapProducersToSearch(props) {
  const { producers } = props;
  const objects = producers.map(producers => {
    return { name: producers?.legalPerson?.companyName ?? producers?.naturalPerson?.name, id: producers?.id };
  });
  return objects.sort((a, b) => a?.name?.localeCompare(b?.name));
}

export const defaultValue: Readonly<IProducer> = {};

import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IRootState } from 'app/shared/reducers';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate, Translate } from 'react-jhipster';
import { Button, Col } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import SimpleSearchField from '../simple-search-field/simple-search-field';
import { Pageable } from 'app/shared/model/pageable';
import './custom-mtr-complementar-list.scss';
interface ICustomMtrComplementarListProps extends StateProps, DispatchProps, RouteComponentProps {
    // TODO
}

interface ICustomMtrComplementarListState {
}



class CustomMtrComplementarList extends React.Component<ICustomMtrComplementarListProps, ICustomMtrComplementarListState> {


    constructor(props: Readonly<ICustomMtrComplementarListProps>) {
        super(props);
        this.state = {

        };
    }

    renderAddPage() {
        return (
            <Fragment>
                <Container>
                    <div>
                        <h1 style={{ marginTop: '28px', marginBottom: '15px', fontWeight: 600 }}>
                            {translate('mtrComplementar.requestMtrComplementar')}
                        </h1>
                        <div className={'custom-mtr-complementar-create'}>
                            <span style={{ marginBottom: '15px', fontSize: '18px' }}>
                                {translate('mtrComplementar.selectMtrComplementar')}
                            </span>
                        </div>
                    </div>
                    <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <Col md={{ size: '6', offset: '3' }}>
                            {/* <SimpleSearchField placeholder={translate('cdf.searchMtr')} onChange={this.onHandleFilterChanged} /> */}
                            <SimpleSearchField placeholder={translate('mtrComplementar.searchMtr')} />
                        </Col>
                        <div className={'mtr-list__items'}>
                            <div className='mtr-list__items--filter'>
                                <div className='mtr-list__items--filter-img' />
                                <span>{translate('mtr-list.filter')}</span>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Fragment>
        );
    };

    render() {
        return (
            <div className={'mtr-add-mtrs__items'}>
                {this.renderAddPage()}
            </div>
        );
    }
}


const mapStateToProps = (root: IRootState) => ({
    hasTransshipmentAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_ADMIN, AUTHORITIES.TRANSSHIPMENT_USER])
});

const mapDispatchToProps = {
};
type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(CustomMtrComplementarList);
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import AddressFormComponent from 'app/components/address-form-component/address-form-component';
import { IAddress } from 'app/shared/model/address.model';
import { IUserFinalDestination } from 'app/shared/model/user-final-destination.model';

export interface IFinalDestinationRegisterAddressProps extends StateProps, DispatchProps {
  userFinalDestination: IUserFinalDestination;
  onNext: (userFinalDestination: IUserFinalDestination) => void;
  onBack: () => void;
}

export interface IFinalDestinationRegisterState {
  address: IAddress;
}

export class FinalDestinationRegisterAddress extends React.Component<IFinalDestinationRegisterAddressProps, IFinalDestinationRegisterState> {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.userFinalDestination.finalDestination
        ? this.props.userFinalDestination.finalDestination.address
          ? this.props.userFinalDestination.finalDestination.address
          : {}
        : {},
    };
  }

  onNextHandle = (address: IAddress) => {
    const { userFinalDestination } = { ...this.props };
    userFinalDestination.finalDestination = {
      ...userFinalDestination.finalDestination,
      address: address,
    };

    this.props.onNext(userFinalDestination);
  };

  render() {
    const { address } = this.state;
    return (
      <div>
        <AddressFormComponent onBack={this.props.onBack} onNextHandler={this.onNextHandle} address={address} />
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FinalDestinationRegisterAddress);

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import './manager-producer-modal.scss';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import { IProducer } from 'app/shared/model/producer.model';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { getAllManagerProducers, reset } from '../../manager-link-reducer';
import { IUserProducer } from 'app/shared/model/user-producer.model';
import { maskCnpj, maskCPF } from 'app/shared/util/mask-utils';

export interface IManagerProducerModalProps extends StateProps, DispatchProps {
  showModal: boolean;
  onCloseModal?: () => void;
  onSelectProducer?: (producer: IProducer) => void;
}

export interface IManagerProducerModalState {
  selectedUserProducer: IUserProducer;
}

export class ManagerProducerModal extends React.Component<IManagerProducerModalProps, IManagerProducerModalState> {
  constructor(props: Readonly<IManagerProducerModalProps>) {
    super(props);
    this.state = {
      selectedUserProducer: null,
    };
  }

  componentDidMount() {
    this.props.reset();
    this.props.getAllManagerProducers();
  }

  mapProducersToSearch = () => {
    const { managerProducers } = this.props;

    const objects = managerProducers.map(userProducer => {
      let companyName = '';
      let cnpj = '';

      // Verifica se é uma pessoa jurídica
      if (userProducer.producer?.legalPerson) {
        companyName = userProducer.producer.legalPerson.companyName;
        cnpj = maskCnpj(userProducer.producer.legalPerson.cnpj);
      } else if (userProducer.producer?.naturalPerson) {
        companyName = userProducer.producer.naturalPerson.name;
        cnpj = maskCPF(userProducer.producer.naturalPerson.cpf);
      }

      // Retorne o objeto com o nome da empresa e o CNPJ
      return { name: `${companyName} | ${cnpj}`, id: userProducer.id };
    });

    return objects.sort((a, b) => a.name.localeCompare(b.name));
  };

  onClickProducer = (item: any) => {
    const { managerProducers } = this.props;
    this.setState({
      selectedUserProducer: managerProducers.filter(it => it.id === item.id)[0],
    });
  };

  search = () => { };

  onSelectProducer = () => {
    const { selectedUserProducer } = this.state;

    this.props.onSelectProducer(selectedUserProducer.producer);
  };

  render() {
    const { showModal } = this.props;
    const { selectedUserProducer } = this.state;
    return (
      <Modal className={'alert-modal__props'} isOpen={showModal}>
        <ModalBody>
          <Container>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Col md="6">
                <h1 style={{ marginBottom: '30px' }}>{translate('manager.producer.link.title')}</h1>
                <DropdownSearch
                  data={this.mapProducersToSearch()}
                  getData={this.search}
                  onClickItem={this.onClickProducer}
                  placeholder={selectedUserProducer ? maskCnpj(selectedUserProducer.producer?.legalPerson?.cnpj) ?? maskCPF(selectedUserProducer.producer?.naturalPerson?.cpf) : ''}
                  title={translate('manager.producer.link.search')}
                  style={
                    selectedUserProducer ? { backgroundColor: 'white', borderTop: '1px solid #9ac91c' } : { backgroundColor: '#f6f6f6' }
                  }
                  notAlphabeticalOrder
                  showInsiderSelect
                  filterLocalData
                  showSelectedStyle
                />
                <NextCancelButton
                  cancelButtonName={translate('entity.action.cancel')}
                  onCancelClick={this.props.onCloseModal}
                  nextButtonName={translate('entity.action.save')}
                  onNextClick={this.onSelectProducer}
                  disableNextButton={selectedUserProducer == null}
                  noArrow
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchManagerProducersStatus: root.managerlink.fetchManagerProducersStatus,
  managerProducers: root.managerlink.managerProducers,
});

const mapDispatchToProps = {
  getAllManagerProducers,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerProducerModal);


import { IFinalDestinationMtr } from './../../../shared/model/final-destination.model';
import { IProducerMtr } from './../../../shared/model/producer.model';
import { ITransporterMtr } from './../../../shared/model/transporter.model';
import axios from 'axios';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import StringUtils from 'app/shared/util/string-utils';
import { ICrudGetCustom } from 'app/shared/util/reducer-utils';
import { IMtr, IResidueMtr, MtrStatus } from 'app/shared/model/mtr.model';
import { ICrudPutAction } from 'react-jhipster';
import { ITransshipmentMtr } from 'app/shared/model/transshipment.model';

import { MtrReport } from 'app/shared/model/mtr-report.model';
import { defaultValue, ISignature } from 'app/shared/model/signature.model';

export const ACTION_TYPES = {
  GET_MTR_TRANSPORTER: 'mtr/GET_MTR_TRANSPORTER',
  SAVE_MTR: 'mtr/SAVE_MTR',
  GET_MTR: 'mtr/GET_MTR',
  GET_MTR_FILTER: 'mtr/GET_MTR_FILTER',
  SAVE_PDF: 'mtr/SAVE_PDF',
  SAVE_PDF_BY_FILTER: 'SAVE_PDF_BY_FILTER',
  GENERATE_CDF: 'mtr/GENERATE_CDF',
  CANCEL_MTR: 'mtr/CANCEL_MTR',
  GET_MTR_PRODUCER: 'mtr/GET_MTR_PRODUCER',
  GET_MTR_RESIDUE: 'mtr/GET_MTR_RESIDUE',
  GET_MTR_FINAL_DESTINATION: 'mtr/GET_MTR_FINAL_DESTINATION',
  GET_MTR_TRANSSHIPMENT: 'mtr/GET_MTR_TRANSSHIPMENT',
  FIND_MTR: 'mtr/FIND_MTR',
  RESET: 'mtr/RESET',
  RESET_STATUS: 'mtr/RESET_STATUS',
  SIGN_MTR: 'profile/SIGN_MTR',
};

const initialState = {
  cancelMtrStatus: HttpRequestStatus.NOOP,
  saveMtr: HttpRequestStatus.NOOP,
  generateCsv: HttpRequestStatus.NOOP,
  csv: null,
  getMtr: HttpRequestStatus.NOOP,
  mtrFinalDestination: undefined,
  mtrTransporter: undefined,
  mtrTransshipment: undefined,
  mtrProducer: undefined,
  mtrResidue: undefined,
  savedMtr: undefined,
  errorMessage: null,
  saveMtrErrorMessage: null,
  mtr: undefined,
  loading: false,
  totalItems: 0,
  mtrs: [],
  createMtrReportStatus: HttpRequestStatus.NOOP,
  signature: defaultValue,
  signMtrStatus: HttpRequestStatus.NOOP,
};

export type MtrState = Readonly<typeof initialState>;

// Reducer

export default (state: MtrState = initialState, action): MtrState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GENERATE_CDF):
      return {
        ...state,
        generateCsv: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.FIND_MTR):
      return {
        ...state,
        errorMessage: null,
        getMtr: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.CANCEL_MTR):
      return {
        ...state,
        errorMessage: null,
        cancelMtrStatus: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.SAVE_MTR):
      return {
        ...state,
        errorMessage: null,
        saveMtrErrorMessage: null,
        saveMtr: HttpRequestStatus.ONGOING,
      };
    case REQUEST(ACTION_TYPES.GET_MTR_RESIDUE):
    case REQUEST(ACTION_TYPES.GET_MTR_PRODUCER):
    case REQUEST(ACTION_TYPES.GET_MTR_TRANSPORTER):
    case REQUEST(ACTION_TYPES.GET_MTR_TRANSSHIPMENT):
    case REQUEST(ACTION_TYPES.SAVE_PDF):
    case REQUEST(ACTION_TYPES.SAVE_PDF_BY_FILTER):
    case REQUEST(ACTION_TYPES.GET_MTR_FINAL_DESTINATION):
    case REQUEST(ACTION_TYPES.GET_MTR):
    case REQUEST(ACTION_TYPES.GET_MTR_FILTER):
      return {
        ...state,
        errorMessage: null,
      };
    case REQUEST(ACTION_TYPES.SIGN_MTR):
      return {
        ...state,
        errorMessage: null,
        signMtrStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.SIGN_MTR):
      return {
        ...state,
        errorMessage: action.payload,
        signMtrStatus: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.GENERATE_CDF):
      return {
        ...state,
        generateCsv: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.GET_MTR_RESIDUE):
    case FAILURE(ACTION_TYPES.GET_MTR_PRODUCER):
    case FAILURE(ACTION_TYPES.GET_MTR_TRANSPORTER):
    case FAILURE(ACTION_TYPES.GET_MTR_TRANSSHIPMENT):
    case FAILURE(ACTION_TYPES.SAVE_PDF):
    case FAILURE(ACTION_TYPES.SAVE_PDF_BY_FILTER):
    case FAILURE(ACTION_TYPES.GET_MTR_FINAL_DESTINATION):
    case FAILURE(ACTION_TYPES.GET_MTR):
    case FAILURE(ACTION_TYPES.GET_MTR_FILTER):
    case FAILURE(ACTION_TYPES.FIND_MTR):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.SAVE_MTR):
      return {
        ...state,
        saveMtrErrorMessage: action?.payload?.response?.data?.message,
        saveMtr: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.CANCEL_MTR):
      return {
        ...state,
        errorMessage: action.payload,
        cancelMtrStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.GENERATE_CDF):
      return {
        ...state,
        csv: action.payload.data,
        generateCsv: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.GET_MTR):
      return {
        ...state,
        mtrs: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.GET_MTR_FILTER):
      return {
        ...state,
        mtrs: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.GET_MTR_RESIDUE):
      return {
        ...state,
        mtrResidue: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.SIGN_MTR):
      return {
        ...state,
        signMtrStatus: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.FIND_MTR):
      return {
        ...state,
        mtr: action.payload.data,
        getMtr: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.SAVE_PDF):
      return {
        ...state,
      };
    case SUCCESS(ACTION_TYPES.SAVE_PDF_BY_FILTER):
      return {
        ...state,
      };
    case SUCCESS(ACTION_TYPES.CANCEL_MTR):
      return {
        ...state,
        cancelMtrStatus: HttpRequestStatus.SUCCESS,
      };
    case SUCCESS(ACTION_TYPES.GET_MTR_TRANSPORTER):
      return {
        ...state,
        mtrTransporter: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_MTR_TRANSSHIPMENT):
      return {
        ...state,
        mtrTransshipment: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.SAVE_MTR):
      return {
        ...state,
        saveMtr: HttpRequestStatus.SUCCESS,
        savedMtr: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_MTR_FINAL_DESTINATION):
      return {
        ...state,
        mtrFinalDestination: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_MTR_PRODUCER):
      return {
        ...state,
        mtrProducer: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.RESET_STATUS:
      return {
        ...state,
        loading: false,
        mtrResidue: undefined,
        errorMessage: undefined,
        getMtr: HttpRequestStatus.NOOP,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/mtr';

const apiUrlMtrSignatures = 'api/mtr-signature';

// Actions

export const getMtrs = (page, size, sort, search?, status?: MtrStatus) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}${params}`;
  return {
    type: ACTION_TYPES.GET_MTR,
    payload: axios.get<IMtr>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}&status=${status || ''}`),
  };
};
// export const fetchMtrsAndSignatures = (page, size, sort, search?, status?: MtrStatus) => {
//   const searchParams = StringUtils.parseQueryParams(search);
//   const params = organizeSearchParameters(page, size, sort, searchParams);
//   const requestUrl = `${apiUrl}${params}`;

//   // Faz a solicitação para a API
//   const request = axios.get<IMtr[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}&status=${status || ''}`);

//   // Filtra os resultados após receber a resposta
//   return {
//     type: ACTION_TYPES.GET_MTR,
//     payload: request.then(response => {
//       // Filtra os dados para excluir os itens sem producerSignature
//       const filteredData: IMtr[] = [];

//       for (const item of response.data) {
//         if (!item.producerSignature) {
//           filteredData.push(item);
//         }
//       }

//       // Retorna os dados filtrados
//       return filteredData;
//     }),
//   };
// };

export const fetchMtrsAndSignatures = (page, size, sort, search?, status?: MtrStatus) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}${params}`;

  return {
    type: ACTION_TYPES.GET_MTR,
    payload: axios.get<IMtr>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}&status=${status || ''}`),
  };
};

export const searchMtrs = (
  page: number,
  size: number,
  sort: string,
  search?: any,
  status?: MtrStatus,
  startDate?: string,
  endDate?: string,
  isFinalDestination?: boolean,
  entityId?: number
) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const pageableAndSearchText = `?page=${page ?? 0}&size=${size ?? 0}&sort=${sort ?? ''}&${searchParams}&status=${status || ''}
      &startDate=${startDate || ''}&endDate=${endDate || ''}&${isFinalDestination ? 'producer.id' : 'finalDestination.id'}=${
    entityId || ''
  }`;
  return {
    type: ACTION_TYPES.GET_MTR,
    payload: axios.get<IMtr>(`${apiUrl}${pageableAndSearchText}&cacheBuster=${new Date().getTime()}`),
  };
};

export const getMtrResidue: ICrudGetCustom<IResidueMtr> = (id?: number) => {
  return {
    type: ACTION_TYPES.GET_MTR_RESIDUE,
    payload: axios.get<IResidueMtr>(`${apiUrl}-residue/${id ?? ''}`),
  };
};

export const saveMtr: ICrudGetCustom<IMtr> = mtr => {
  return {
    type: ACTION_TYPES.SAVE_MTR,
    payload: axios.post<IMtr>(`${apiUrl}`, mtr),
  };
};

export const savePdf: ICrudPutAction<any> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SAVE_PDF,
    responseType: 'blob',
    payload: axios.get<any>(`${apiUrl}/report/base64/${id}`),
  });
  let fileName = 'mtr.pdf';
  if (result.action.payload.headers['content-disposition'] != null) {
    fileName = String(result.action.payload.headers['content-disposition']).split('filename=')[1];
  }
  const blobUrl = StringUtils.generateFileUrl(result.action.payload.data, 'text/csv');
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  return result;
};

const decodeAndDownload = result => {
  let fileName = 'mtr.csv';

  if (result.action.payload.headers['content-disposition'] != null) {
    fileName = String(result.action.payload.headers['content-disposition']).split('filename=')[1];
  }

  const link = document.createElement('a');

  let decodeCsv = decodeURIComponent(
    atob(result.action.payload.data || '')
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  link.setAttribute('download', fileName);
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(decodeCsv));

  document.body.appendChild(link);
  link.click();

  return result;
};

// export const generateCsvWithFilter = (sort: string, search?: any, startDate?: string, endDate?: string) => async dispatch => {
//   const searchParams = StringUtils.parseQueryParams(search);
//   const pageableAndSearchText = `?page=0&size=&sort=${sort ?? ''}&${searchParams}&startDate=${startDate || ''}&endDate=${endDate || ''}`;

//   const result = await dispatch({
//     type: ACTION_TYPES.SAVE_PDF,
//     responseType: 'blob',
//     payload: axios.get<any>(`${apiUrl}/csv${pageableAndSearchText}`),
//   });

//   decodeAndDownload(result);
// };

export const generateCsvSelectedMtr = (entity: MtrReport) => async dispatch => {
  const mtrIds = entity.mtrs.map(mtr => mtr.id);

  const result = await dispatch({
    type: ACTION_TYPES.SAVE_PDF_BY_FILTER,
    responseType: 'blob',
    payload: axios.put(`${apiUrl}/csv/select`, mtrIds),
  });

  decodeAndDownload(result);
};

export const generateCsvUnselectedMtr = (entity: MtrReport, search?) => async dispatch => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(null, null, null, searchParams);
  const requestUrl = `${apiUrl}/csv/unselect${params}`;

  const mtrIds = entity.mtrs.map(mtr => mtr.id);

  const result = await dispatch({
    type: ACTION_TYPES.SAVE_PDF_BY_FILTER,
    responseType: 'blob',
    payload: axios.put(requestUrl, mtrIds),
  });

  decodeAndDownload(result);
};

export const findMtr: ICrudGetCustom<IMtr> = (id: number) => {
  return {
    type: ACTION_TYPES.FIND_MTR,
    payload: axios.get<IMtr>(`${apiUrl}/${id}`),
  };
};

export const cancelMtr: ICrudGetCustom<IMtr> = (id: number) => {
  return {
    type: ACTION_TYPES.CANCEL_MTR,
    payload: axios.get<IMtr>(`${apiUrl}/cancel/${id}`),
  };
};

export const getMtrTransporter: ICrudGetCustom<ITransporterMtr> = (id?: number) => {
  return {
    type: ACTION_TYPES.GET_MTR_TRANSPORTER,
    payload: axios.get<ITransporterMtr>(`${apiUrl}-transporter?transporterId=${id ?? ''}`),
  };
};

export const getMtrProducer: ICrudGetCustom<ITransporterMtr> = (id?: number) => {
  return {
    type: ACTION_TYPES.GET_MTR_PRODUCER,
    payload: axios.get<IProducerMtr>(`${apiUrl}-producer?producerId=${id ?? ''}`),
  };
};

export const getMtrFinalDestination: ICrudGetCustom<IFinalDestinationMtr> = (id: number) => {
  return {
    type: ACTION_TYPES.GET_MTR_FINAL_DESTINATION,
    payload: axios.get<IFinalDestinationMtr>(`${apiUrl}-final-destination/${id}`),
  };
};

export const getMtrTransshipment: ICrudGetCustom<ITransshipmentMtr> = (id: number) => {
  return {
    type: ACTION_TYPES.GET_MTR_TRANSSHIPMENT,
    payload: axios.get<ITransshipmentMtr>(`${apiUrl}-transshipment/${id}`),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const resetStatus = () => ({
  type: ACTION_TYPES.RESET_STATUS,
});

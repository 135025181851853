import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';

import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getCities } from 'app/entities/city/city.reducer';
import { createEntity, getEntity, reset, updateEntity } from './address.reducer';


export interface IAddressUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAddressUpdateState {
  isNew: boolean;
  cityId: string;
}

export class AddressUpdate extends React.Component<IAddressUpdateProps, IAddressUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      cityId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getCities();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { addressEntity } = this.props;
      const entity = {
        ...addressEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/address');
  };

  render() {
    const { addressEntity, cities, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.address.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.address.home.createOrEditLabel">Create or edit a Address</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : addressEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="address-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="address-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="streetLabel" for="address-street">
                    <Translate contentKey="resitrackApp.address.street">Street</Translate>
                  </Label>
                  <AvField
                    id="address-street"
                    type="text"
                    name="street"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="nameLabel" for="address-name">
                    <Translate contentKey="resitrackApp.address.name">Name</Translate>
                  </Label>
                  <AvField
                    id="address-name"
                    type="text"
                    name="name"
                    validate={{
                      minLength: { value: 0, errorMessage: translate('entity.validation.minlength', { min: 0 }) },
                      maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="address-description">
                    <Translate contentKey="resitrackApp.address.description">Description</Translate>
                  </Label>
                  <AvField
                    id="address-description"
                    type="text"
                    name="description"
                    validate={{
                      minLength: { value: 0, errorMessage: translate('entity.validation.minlength', { min: 0 }) },
                      maxLength: { value: 511, errorMessage: translate('entity.validation.maxlength', { max: 511 }) },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="numberLabel" for="address-number">
                    <Translate contentKey="resitrackApp.address.number">Number</Translate>
                  </Label>
                  <AvField id="address-number" type="text" name="number" />
                </AvGroup>
                <AvGroup>
                  <Label id="zipcodeLabel" for="address-zipcode">
                    <Translate contentKey="resitrackApp.address.zipcode">Zipcode</Translate>
                  </Label>
                  <AvField id="address-zipcode" type="text" name="zipcode" />
                </AvGroup>
                <AvGroup>
                  <Label id="districtLabel" for="address-district">
                    <Translate contentKey="resitrackApp.address.district">District</Translate>
                  </Label>
                  <AvField id="address-district" type="text" name="district" />
                </AvGroup>
                <AvGroup>
                  <Label id="complementLabel" for="address-complement">
                    <Translate contentKey="resitrackApp.address.complement">Complement</Translate>
                  </Label>
                  <AvField id="address-complement" type="text" name="complement" />
                </AvGroup>
                <AvGroup>
                  <Label id="geolocatedLabel" check>
                    <AvInput id="address-geolocated" type="checkbox" className="form-control" name="geolocated" />
                    <Translate contentKey="resitrackApp.address.geolocated">Geolocated</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="latLabel" for="address-lat">
                    <Translate contentKey="resitrackApp.address.lat">Lat</Translate>
                  </Label>
                  <AvField id="address-lat" type="text" name="lat" />
                </AvGroup>
                <AvGroup>
                  <Label id="lngLabel" for="address-lng">
                    <Translate contentKey="resitrackApp.address.lng">Lng</Translate>
                  </Label>
                  <AvField id="address-lng" type="text" name="lng" />
                </AvGroup>
                <AvGroup>
                  <Label for="address-city">
                    <Translate contentKey="resitrackApp.address.city">City</Translate>
                  </Label>
                  <AvInput id="address-city" type="select" className="form-control" name="city.id">
                    <option value="" key="0" />
                    {cities
                      ? cities.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/address" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  cities: storeState.city.entities,
  addressEntity: storeState.address.entity,
  loading: storeState.address.loading,
  updating: storeState.address.updating,
  updateSuccess: storeState.address.updateSuccess,
});

const mapDispatchToProps = {
  getCities,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressUpdate);

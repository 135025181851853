import ResidueList from 'app/components/residues-components/list/residues-list';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

export interface ITransshipmentResiduesProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export class TransshipmentResidues extends React.Component<ITransshipmentResiduesProps> {
  render() {
    const { history, match, location } = this.props;
    return (
      <div>
        <ResidueList
          history={history}
          match={match}
          location={location}
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({});

const mapDispatchToProps = {
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentResidues);

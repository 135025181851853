import './cnaeCodeComponent.scss';
import React from 'react';
import {Col, Row} from 'reactstrap';
import {ICnaeCode} from 'app/shared/model/cnae-code.model';
import {translate} from 'react-jhipster';

export interface IGenericCardProps {
  cnaeCode: ICnaeCode;
  key?: string;
  index: number;
  onDelete: (index: number) => void;
}

export class CnaeCodeComponent extends React.Component<IGenericCardProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { cnaeCode, onDelete, key, index } = this.props;
    const labels = translate('cnaeComponent');
    return (
      <div className="cnae-code__items" key={key}>
        <div className="cnae-code__items--border"/>
        <Row>
          <Col md="3" style={{ margin: 'auto'; textAlign: 'center'}}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="cnae-code__items--title">{labels.code}</div>
              <div className="cnae-code__items--subtitle">{cnaeCode.code}</div>
            </div>
          </Col>
          <Col md="7" style={{ margin: 'auto'}}>
            <div className="cnae-code__items--description">{cnaeCode.description}</div>
          </Col>
          <Col md="2" style={{ margin: 'auto'; textAlign: 'center'}}>
            <div style={{ cursor: 'pointer'}}>
              <div className="cnae-code__items--delete" onClick={() => onDelete(index)}>{labels.delete}</div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CnaeCodeComponent;

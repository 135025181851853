import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './authorization.reducer';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAuthorizationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export class Authorization extends React.Component<IAuthorizationProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { authorizationList, match } = this.props;
    return (
      <div>
        <h2 id="authorization-heading">
          <Translate contentKey="resitrackApp.authorization.home.title">Authorizations</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="resitrackApp.authorization.home.createLabel">Create new Authorization</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          {authorizationList && authorizationList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.authorization.amount">Amount</Translate>
                    <Translate contentKey="resitrackApp.authorization.amountReceipt">amountReceipt</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.authorization.expirationDate">Expiration Date</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.authorization.measurementType">Measurement Type</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.authorization.residueType">Residue Type</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.authorization.authorizationDocument">Authorization Document</Translate>
                  </th>
                  <th>
                    <Translate contentKey="resitrackApp.authorization.producer">Producer</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {authorizationList.map((authorization, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${authorization.id}`} color="link" size="sm">
                        {authorization.id}
                      </Button>
                    </td>
                    <td>{authorization.amount}{authorization.amountReceipt}</td>
                    <td>
                      <TextFormat type="date" value={authorization.expirationDate} format={APP_LOCAL_DATE_FORMAT} />
                    </td>
                    <td>
                      <Translate contentKey={`resitrackApp.MeasurementType.${authorization.measurementType}`} />
                    </td>
                    <td>{authorization.sourceResidue.id}</td>
                    <td>
                      {authorization.authorizationDocument ? (
                        <Link to={`document/${authorization.authorizationDocument.id}`}>{authorization.authorizationDocument.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {authorization.producer ? <Link to={`producer/${authorization.producer.id}`}>{authorization.producer.id}</Link> : ''}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${authorization.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${authorization.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${authorization.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="resitrackApp.authorization.home.notFound">No Authorizations found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authorization }: IRootState) => ({
  authorizationList: authorization.entities,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);

import { AxiosError, AxiosResponse } from 'axios';
import creditCardApi from '../api/creditCardApi';
import { HttpStatusNumber } from 'app/shared/model/enum/HttpStatusNumber';
import ICreditCard from 'app/shared/model/credit-card.model';
import toastUtils from 'app/shared/util/toast-utils';
import { translate } from 'react-jhipster';

const CreditCardService = () => {
  const createCreditCard = async (creditCard: ICreditCard) => {
    try {
      const result: AxiosResponse<ICreditCard> = await creditCardApi.createCreditCard(creditCard);

      if (result.status === HttpStatusNumber.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ result: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllCreditCards = async () => {
    try {
      const result: AxiosResponse<ICreditCard[]> = await creditCardApi.getAllCreditCards();
      if (result.status === HttpStatusNumber.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(response);
      return Promise.reject(response);
    }
  };

  const deleteCreditCard = async (creditCardId: number) => {
    try {
      const result: AxiosResponse<ICreditCard> = await creditCardApi.deleteCreditCard(creditCardId);

      if (result.status === HttpStatusNumber.OK) {
        return Promise.resolve(result.data ?? []);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(error);
      return Promise.reject(error?.response as AxiosResponse);
    }
  };

  return {
    createCreditCard,
    getAllCreditCards,
    deleteCreditCard,
  };
};

export default CreditCardService();

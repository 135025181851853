import { getProducerDetailById, reset } from 'app/entities/user-producer/user-producer.reducer';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './subsidiary-details.scss';
import { maskCnpj, maskPhone, maskZipCode } from 'app/shared/util/mask-utils';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import SimpleFileComponent from 'app/components/simple-file-component/simple-file-component';

export interface ISubsidiaryDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISubsidiaryDetailsState {}

export class SubsidiaryDetails extends React.Component<ISubsidiaryDetailsProps, ISubsidiaryDetailsState> {
  constructor(props: Readonly<ISubsidiaryDetailsProps>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getProducerDetailById(id);
    }
  }

  componentWillReceiveProps(newProps) {}

  handleGoBack = () => {
    this.props.history.replace('/producer/units');
  };

  onEditSubsidiary = (id: number) => {
    this.props.history.push({
      pathname: `/producer/units/subsidiary/update`,
      state: { id: id },
    });
  };

  render() {
    const { producerIdDetail, producerDetailIdStatus } = this.props;
    if (checkStatusOnGoing([producerDetailIdStatus])) {
      return <Loading />;
    }
    return (
      <div className="list__items">
        <div className="list__items--back">
          <Container>
            <Row>
              <Col md="10">
                <div className="confirmation--back" onClick={this.handleGoBack}>
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              <Col md="2">
                <div className="list__items--edit" onClick={() => this.onEditSubsidiary(producerIdDetail.id)}>
                  <Translate contentKey={'mtr-signature.edit'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <h1 style={{ fontWeight: 550 }}>{producerIdDetail.producer ? producerIdDetail.producer.companyName : ''}</h1>
        </div>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('producer.units.subsidiary.companyData')}</div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.cnpj')}</div>
              <div className="list__items--text">{producerIdDetail.producer ? maskCnpj(producerIdDetail.producer.cnpj) : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.companyName')}</div>
              <div className="list__items--text">{producerIdDetail.producer ? producerIdDetail.producer.companyName : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.activityType')}</div>
              <div className="list__items--text">{producerIdDetail.producer ? producerIdDetail.producer.activityType : ''}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, offset: 3 }}>
            <div className="list__items--title-second">{translate('producer.units.subsidiary.address')}</div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, offset: 3 }} style={{ paddingRight: '0' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.address')}</div>
              <div className="list__items--text">{producerIdDetail.producer ? producerIdDetail.producer.address.street : ''}</div>
            </div>
          </Col>
          <Col md="3" style={{ paddingLeft: '0' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.complement')}</div>
              <div className="list__items--text">{producerIdDetail.producer ? producerIdDetail.producer.address.complement : ''}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, offset: 3 }} style={{ paddingRight: '0' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.city')}</div>
              <div className="list__items--text">{producerIdDetail.producer ? producerIdDetail.producer.address.city.name : ''}</div>
            </div>
          </Col>
          <Col md="2" style={{ paddingLeft: '0', paddingRight: '50px' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.state')}</div>
              <div className="list__items--text">{producerIdDetail.producer ? producerIdDetail.producer.address.city.state.name : ''}</div>
            </div>
          </Col>
          <Col md="2" style={{ paddingLeft: '57px', marginLeft: '-121px' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.zipcode')}</div>
              <div className="list__items--text">
                {producerIdDetail.producer ? maskZipCode(producerIdDetail.producer.address.zipcode) : ''}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('producer.units.subsidiary.responsible')}</div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.name')}</div>
              <div className="list__items--text">{producerIdDetail.user ? producerIdDetail.user.name : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.email')}</div>
              <div className="list__items--text">{producerIdDetail.user ? producerIdDetail.user.email : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.phone')}</div>
              <div className="list__items--text">{producerIdDetail.user ? maskPhone(producerIdDetail.user.phone) : ''}</div>
            </div>
            <div className="list__items--subtitle">{translate('mtr-transporter.license.subtitle-second')}</div>
            <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
              <div className="list__items--title">{translate('producer.units.subsidiary.businessLicense')}</div>
              <SimpleFileComponent file={producerIdDetail.producer ? producerIdDetail.producer.businessLicense : null} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  producerIdDetail: root.userProducer.producerIdDetail,
  producerDetailIdStatus: root.userProducer.producerDetailIdStatus,
});

const mapDispatchToProps = {
  getProducerDetailById,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubsidiaryDetails);

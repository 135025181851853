import { IRootState } from 'app/shared/reducers';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import './alert-modal.scss';

export interface IAlertModalProps extends StateProps, DispatchProps {
  statusImage: string;
  alertMessage: string;
  hasTwoButtons?: boolean;
  hasOneButton?: boolean;
  buttonOneActionMessage?: string;
  buttonTwoActionMessage: string;
  buttonTwoAction: () => void;
  buttonOneAction?: () => void;
  params?: any;
  showModal: boolean;
}

export interface IAlertModalState { }

export class AlertModal extends React.Component<IAlertModalProps, IAlertModalState> {
  constructor(props: Readonly<IAlertModalProps>) {
    super(props);
    this.state = {
      statusImage: null,
      alertMessage: null,
    };
  }

  componentDidMount() { }

  componentWillReceiveProps(values) { }

  componentWillUnmount() {
    this.props.showHeader();
  }

  render() {
    const {
      statusImage,
      alertMessage,
      hasTwoButtons,
      buttonOneAction,
      buttonOneActionMessage,
      buttonTwoAction,
      buttonTwoActionMessage,
      showModal,
      params,
    } = this.props;
    if (alertMessage == null) {
      return <div />;
    }
    return (
      <Modal className={'alert-modal__props'} isOpen={showModal}>
        <ModalBody>
          <Row style={{ height: 'calc(100vh - 130px)', margin: 'auto' }}>
            <Col style={{ margin: 'auto' }}>
              <div className="alert-modal-container">
                <div className="image-wrapper">
                  <img src={`content/images/icons/${statusImage}`} />
                </div>
                <div className="message-wrapper">
                  <span>{translate(`${alertMessage}.message`, { params: params })}</span>
                </div>
                <div className={'button-container'}>
                  {hasTwoButtons && (
                    <button className={'button third'} type={'submit'} onClick={buttonOneAction}>
                      <Translate contentKey={buttonOneActionMessage}>Back</Translate>
                    </button>
                  )}
                  <button className={'button fourth'} type={'submit'} onClick={buttonTwoAction}>
                    <Translate contentKey={buttonTwoActionMessage}>Next</Translate>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { hideHeader, showHeader };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);

import Loading from 'app/components/loading/loading';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './user-signature.scss';
import SignatureComponent from 'app/components/signature-component/signature-component';
import { createSignature, getSignature, reset } from '../profile-reducer';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { ISignature } from 'app/shared/model/signature.model';
import { IDocument } from 'app/shared/model/document.model';
import {maskPhone, maskCPF} from "app/shared/util/mask-utils";

export interface IUserSignatureProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IUserSignatureState {
  signature: ISignature;
  showSuccessModal: boolean;
  showErrorModal: boolean;
}

export class UserSignature extends React.Component<IUserSignatureProps, IUserSignatureState> {
  constructor(props: Readonly<IUserSignatureProps>) {
    super(props);
    this.state = {
      signature: null,
      showErrorModal: false,
      showSuccessModal: false,
    };
  }

  async componentDidMount() {
    this.props.getSignature();
    this.props.getSession();
  }

  toggleSuccessModal = () => {
    this.setState({
      showSuccessModal: !this.state.showSuccessModal,
    });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.fetchSignatureStatus === HttpRequestStatus.SUCCESS && newProps.fetchSignatureStatus != this.props.fetchSignatureStatus) {
      this.setState({
        signature: newProps.signature,
      });
    }
    if (newProps.createSignatureStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
    }
    if (newProps.createSignatureStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
  }

  handleFileAttach = (document: IDocument) => {
    this.setState(prevState => ({
      signature: {
        ...prevState.signature,
        signatureDocument: document,
      },
    }));
  };

  onSubmitSignature = () => {
    const { signature } = this.state;
    signature.signatureDocument.id = null;
    this.props.createSignature(signature);
  };

  renderInfo = (title: string, info: string) => (
    <div className="user-profile-menu__items--component">
      <div className="user-profile-menu__items--title-second">{title}</div>
      <div className="user-profile-menu__items--text">{info}</div>
    </div>
  );

  render() {
    const { loading, account, fetchSignatureStatus, createSignatureStatus } = this.props;
    const { showSuccessModal, showErrorModal, signature } = this.state;
    if (loading || checkStatusOnGoing([fetchSignatureStatus, createSignatureStatus])) {
      return <Loading />;
    }
    return (
      <div className="user-signature__items">
        <div>
          <div className="user-profile-menu__items--title">
            <Translate contentKey={'profile.personal-data.responsibleTecnican'} />
          </div>
          {this.renderInfo(translate('global.account.info.nameTechnical'), account.nameTechnical)}
          {this.renderInfo(translate('global.account.info.email'), account.email)}
          {this.renderInfo(translate('global.account.info.cpf'), account.cpfTechnical ? maskCPF(account.cpfTechnical) : '')}
          {this.renderInfo(translate('global.account.info.occupation'), account.occupation)}
          {this.renderInfo(translate('global.account.info.phone'), account.phone ? maskPhone(account.phone) : '')}
        </div>
        <br />
        <div className="user-signature__items--title">
          <Translate contentKey={'profile.digital-signature.signature'} />
        </div>
        {/*
        <div className="user-signature__items--subtitle">
          <Translate contentKey={'profile.digital-signature.subtitle'} />
        </div>
        */}

        {signature != null &&
        signature.signatureDocument != null &&
        (signature.signatureDocument.presignedUrl != null || signature.signatureDocument.file != null) ? (
          <div>
            {signature.signatureDocument.file == null && signature.signatureDocument.presignedUrl != null && (
              <img src={signature.signatureDocument.presignedUrl} style={{ maxHeight: '150px', objectFit: 'cover', width: '500px' }} />
            )}
            {signature.signatureDocument.file != null && (
              <img
                src={`data:${signature.signatureDocument.fileContentType};base64,${signature.signatureDocument.file}`}
                style={{ maxHeight: '150px', objectFit: 'cover', width: '500px' }}
              />
            )}
            <div className="signature-generator__items--signature-line" />
          </div>
        ) : (
          <div className="signature-generator__items--signature-line" />
        )}

        {/*
        <SignatureComponent
          signature={signature ? signature.signatureDocument : null}
          responsibleName={account.name}
          //onFileAttached={this.handleFileAttach}
        />
        */}

        {/*
        <button
          className={'button fourth button-save-container'}
          disabled={signature == null || signature.signatureDocument == null || signature.signatureDocument.file == null}
          type={'submit'}
          onClick={this.onSubmitSignature}
        >
          <Translate contentKey={'mtr-signature.button-save'} />
        </button>
        */}

        <AlertModal
          showModal={showSuccessModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={() => this.toggleSuccessModal()}
          statusImage="assinatura.svg"
          alertMessage="modal-digital-signature-second"
        />

        <AlertModal
          showModal={showErrorModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={() => this.toggleErrorModal()}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.signature"
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  loading: root.authentication.loading,
  signature: root.profile.signature,
  fetchSignatureStatus: root.profile.fetchSignatureStatus,
  createSignatureStatus: root.profile.createSignatureStatus,
});

const mapDispatchToProps = {
  getSession,
  getSignature,
  createSignature,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserSignature);

import { IAddress } from 'app/shared/model/address.model';
import { ILegalPerson } from 'app/shared/model/legal-person.model';
import { INaturalPerson } from 'app/shared/model/natural-person.model';
import { IProducer } from 'app/shared/model/producer.model';

export enum PersonType {
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
}

export interface IManager {
  id?: number;
  personType?: PersonType;
  address?: IAddress;
  legalPerson?: ILegalPerson;
  naturalPerson?: INaturalPerson;
  producers?: IProducer[];
}

export const defaultValue: Readonly<IManager> = {};

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Authorization from '../generic/authorization/index';
import ProducerResidues from './residues/producer-residues';
import ProducerResiduesAdd from './residues/producer-residues-add';
import ProducerUnits from './units/producer-units';
import SubsidiaryCreateMenu from './units/subsidiary-create/menu/subsidiary-create-menu';
import SubsidiaryDetails from './units/subsidiary-details/subsidiary-details';
import ProducerUpdateMenu from './units/update/menu/producer-update-menu';
import ProducerFavoriteSummary from './favorite/producer-favorite-summary';
import FavoriteTransporterList from '../generic/favorite-list/favorite-transporter/favorite-transporter-list';
import FavoriteFinalDestinationList
  from '../generic/favorite-list/favorite-final-destination/favorite-final-destination-list';
import GenericInvite from '../generic/invite/generic-invite';
import FinalDestinationDetails from '../generic/details/final-destination/final-destination-details';
import TransporterDetails from '../generic/details/transporter/transporter-details';
import ProducerManagerLink from './link/producer-manager-link';
import MtrList from '../generic/mtr/mtr-list';
import MtrManage from '../generic/mtr/manage/mtr-manage';
import MtrSignatureStep from '../generic/mtr/signature/mtr-signature';
import TransshipmentDetails from 'app/modules/generic/details/transshipment/transshipment-details';
import FavoriteTransshipmentList
  from 'app/modules/generic/favorite-list/favorite-transshipment/favorite-transshipment-list';
import CustomCdfList from 'app/components/custom-cdf-list/custom-cdf-list';
import CustomCdfManage from 'app/components/custom-cdf-manage/custom-cdf-manage';
import CustomCdfCreate from 'app/components/custom-cdf-create/custom-cdf-create';
import CustomCdfSignature from 'app/components/custom-cdf-signature/custom-cdf-signature';
import CustomMtrReportCreate from 'app/components/custom-mtr-report-create/custom-mtr-report-create';
import MtrModelRoutes from '../generic/mtr-model/index'
import CustomMtrSignatureAll from 'app/components/custom-mtr-signature-all/custom-mtr-signature-all';


const Routes = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.url}/mtr/manage/signature`} component={MtrSignatureStep} />
      <Route exact path={`${match.url}/mtr/manage`} component={MtrManage} />
      <Route exact path={`${match.url}/mtr`} component={MtrList} />
      <Route path={`${match.url}/mtr-model`} component={MtrModelRoutes} />
      <Route path={`${match.url}/mtr-report/create`} component={CustomMtrReportCreate} />
      <Route exact path={`${match.url}/mtr-signature/all`} component={CustomMtrSignatureAll} />
      <Route exact path={`${match.url}/units`} component={ProducerUnits} />
      <Route exact path={`${match.url}/units/details`} component={SubsidiaryDetails} />
      <Route exact path={`${match.url}/units/subsidiary/update`} component={ProducerUpdateMenu} />
      <Route exact path={`${match.url}/units/subsidiary/create/new`} component={SubsidiaryCreateMenu} />
      <Route exact path={`${match.url}/units/holding/update`} component={ProducerUpdateMenu} />
      <Route exact path={`${match.url}/residues`} component={ProducerResidues} />
      <Route path={`${match.url}/residues/add`} component={ProducerResiduesAdd} />
      <Route path={`${match.url}/authorizations`} component={Authorization} />
      <Route exact path={`${match.url}/summary`} component={ProducerFavoriteSummary} />
      <Route exact path={`${match.url}/summary/transporters`} component={FavoriteTransporterList} />
      <Route exact path={`${match.url}/summary/transshipments`} component={FavoriteTransshipmentList} />
      <Route exact path={`${match.url}/summary/final-destinations`} component={FavoriteFinalDestinationList} />
      <Route path={`${match.url}/summary/transporters/detail`} component={TransporterDetails} />
      <Route path={`${match.url}/summary/final-destinations/detail`} component={FinalDestinationDetails} />
      <Route path={`${match.url}/summary/transshipments/detail`} component={TransshipmentDetails} />
      <Route path={`${match.url}/invite`} component={GenericInvite} />
      <Route path={`${match.url}/link/:invitationId`} component={ProducerManagerLink} />
      <Route exact path={`${match.url}/cdf`} component={CustomCdfList} />
      <Route path={`${match.url}/cdf/:cdfNumber/manage/signature`} component={CustomCdfSignature} />
      <Route path={`${match.url}/cdf/:cdfNumber/manage`} component={CustomCdfManage} />
      <Route path={`${match.url}/cdf/create`} component={CustomCdfCreate} />
    </Switch>
  </>
);

export default Routes;

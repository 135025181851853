import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import SimpleFileComponent from 'app/components/simple-file-component/simple-file-component';
import { getTransportLicenseDetail, reset } from 'app/entities/licenses/licenses-reducer';
import moment from 'moment';

export interface ITransportLicenseDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ITransportLicenseDetailsState {}

export class TransportLicenseDetails extends React.Component<ITransportLicenseDetailsProps, ITransportLicenseDetailsState> {
  constructor(props: Readonly<ITransportLicenseDetailsProps>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getTransportLicenseDetail(id);
    }
  }

  componentWillReceiveProps(newProps) {}

  handleGoBack = () => {
    this.props.history.replace('/transporter/licenses');
  };

  onEditTransportLicense = (id: number) => {
    this.props.history.push({
      pathname: `/transporter/licenses/edit`,
      state: { id: id },
    });
  };

  renderComponent = (title: string, info: string) => (
    <div className="list__items--component">
      <div className="list__items--title">{title}</div>
      <div className="list__items--text">{info}</div>
    </div>
  );

  render() {
    const { transportLicenseDetail, getTransportLicenseDetailStatus } = this.props;
    if (checkStatusOnGoing([getTransportLicenseDetailStatus])) {
      return <Loading />;
    }
    return (
      <div className="list__items">
        <div className="list__items--back">
          <Container>
            <Row>
              <Col md="10">
                <div className="confirmation--back" onClick={this.handleGoBack}>
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              <Col md="2">
                <div className="list__items--edit" onClick={() => this.onEditTransportLicense(transportLicenseDetail.id)}>
                  <Translate contentKey={'mtr-signature.edit'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <h1 style={{ fontWeight: 550 }}>{translate('licenses.details')}</h1>
        </div>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('licenses.detail.title')}</div>
            {this.renderComponent(
              translate('licenses.detail.licenseType'),
              transportLicenseDetail.transportLicenseType
                ? translate(`enums.transportLicenseType.${transportLicenseDetail.transportLicenseType}`)
                : ''
            )}
            {this.renderComponent(
              translate('licenses.detail.issuingBody'),
              transportLicenseDetail.issuingBody ? transportLicenseDetail.issuingBody : ''
            )}
            {this.renderComponent(
              translate('licenses.detail.licenseNumber'),
              transportLicenseDetail.licenseNumber ? transportLicenseDetail.licenseNumber : ''
            )}
            {this.renderComponent(
              translate('licenses.detail.expireDate'),
              transportLicenseDetail.expireDate ? moment(transportLicenseDetail.expireDate).format('DD/MM/YYYY') : ''
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('licenses.detail.docTitle')}</div>
            <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
              <div className="list__items--title">{translate('producer.units.subsidiary.businessLicense')}</div>
              <SimpleFileComponent file={transportLicenseDetail.licenseDocument ? transportLicenseDetail.licenseDocument : null} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  transportLicenseDetail: root.licenses.transportLicenseDetail,
  getTransportLicenseDetailStatus: root.licenses.getTransportLicenseDetailStatus,
});

const mapDispatchToProps = {
  getTransportLicenseDetail,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransportLicenseDetails);

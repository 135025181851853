import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './subsidiary-details.scss';
import { maskCnpj, maskPhone, maskZipCode } from 'app/shared/util/mask-utils';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import {
    getFinalDestinationDetailById,
    reset
} from 'app/entities/user-final-destination/user-final-destination.reducer';

export interface ISubsidiaryDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISubsidiaryDetailsState {}

export class SubsidiaryDetails extends React.Component<ISubsidiaryDetailsProps, ISubsidiaryDetailsState> {
  constructor(props: Readonly<ISubsidiaryDetailsProps>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };

    this.props.reset();
    if (id) {
      this.props.getFinalDestinationDetailById(id)
    }
  }

  componentWillReceiveProps(newProps) {}

  handleGoBack = () => {
    this.props.history.replace('/final-destination/destination');
  };

  onEditSubsidiary = (id: number) => {
    this.props.history.push({
      pathname: `/final-destination/destination/subsidiary/update`,
      state: { id: id },
    });
  };

  render() {
    const { finalDestinationIdDetail, finalDestinationDetailIdStatus } = this.props;
    if (checkStatusOnGoing([finalDestinationDetailIdStatus])) {
      return <Loading />;
    }
    return (
      <div className="list__items">
        <div className="list__items--back">
          <Container>
            <Row>
              <Col md="10">
                <div className="confirmation--back" onClick={this.handleGoBack}>
                  <div className="confirmation--back-icon" />
                  <span>
                    <Translate contentKey={'mtr-signature.back'} />
                  </span>
                </div>
              </Col>
              <Col md="2">
                <div className="list__items--edit" onClick={() => this.onEditSubsidiary(finalDestinationIdDetail.id)}>
                  <Translate contentKey={'mtr-signature.edit'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <h1 style={{ fontWeight: 550 }}>{finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.companyName : ''}</h1>
        </div>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('producer.units.subsidiary.companyData')}</div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.cnpj')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.finalDestination ? maskCnpj(finalDestinationIdDetail.finalDestination.holdingCnpj) : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.companyName')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.companyName : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.activityType')}</div>
              {/* <div className="list__items--text">{finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.activityType : ''}</div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, offset: 3 }}>
            <div className="list__items--title-second">{translate('producer.units.subsidiary.address')}</div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, offset: 3 }} style={{ paddingRight: '0' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.address')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.address.street : ''}</div>
            </div>
          </Col>
          <Col md="3" style={{ paddingLeft: '0' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.complement')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.address.complement : ''}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3, offset: 3 }} style={{ paddingRight: '0' }}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.city')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.address.city.name : ''}</div>
            </div>
          </Col>
          <Col md="2" style={{ paddingLeft: '0px', paddingRight: '0px'}}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.state')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.address.city.state.name : ''}</div>
            </div>
          </Col>
          <Col md="1" style={{ paddingLeft: '0px'}}>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.zipcode')}</div>
              <div className="list__items--text">
                {finalDestinationIdDetail.finalDestination ? maskZipCode(finalDestinationIdDetail.finalDestination.address.zipcode) : ''}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" style={{ margin: 'auto' }}>
            <div className="list__items--title-second">{translate('producer.units.subsidiary.responsible')}</div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.name')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.user ? finalDestinationIdDetail.user.name : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.email')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.user ? finalDestinationIdDetail.user.email : ''}</div>
            </div>
            <div className="list__items--component">
              <div className="list__items--title">{translate('producer.units.subsidiary.phone')}</div>
              <div className="list__items--text">{finalDestinationIdDetail.user ? maskPhone(finalDestinationIdDetail.user.phone) : ''}</div>
            </div>
            <div className="list__items--subtitle">{translate('mtr-transporter.license.subtitle-second')}</div>
            <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
              <div className="list__items--title">{translate('producer.units.subsidiary.businessLicense')}</div>
              {/* <SimpleFileComponent file={finalDestinationIdDetail.finalDestination ? finalDestinationIdDetail.finalDestination.businessLicense : null} /> */}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  finalDestinationIdDetail: root.userFinalDestination.finalDestinationIdDetail,
  finalDestinationDetailIdStatus: root.userFinalDestination.finalDestinationDetailIdStatus,
});

const mapDispatchToProps = {
  getFinalDestinationDetailById,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubsidiaryDetails);

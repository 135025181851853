import ResidueAdd from 'app/components/residues-components/add/residues-add';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

export interface IManagerResiduesAddProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export class ManagerResiduesAdd extends React.Component<IManagerResiduesAddProps> {
  render() {
    const { history, match, location } = this.props;
    return (
      <div>
        <ResidueAdd history={history} match={match} location={location} />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerResiduesAdd);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../content/images/icons/file-gray.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../../../../content/images/icons/credit-card-gray.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../../../../../content/images/icons/file-white.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../../../../../content/images/icons/credit-card-white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".button-payment-method, .button-payment-method-active {\n  display: flex;\n  align-items: center;\n  width: 199px;\n  box-sizing: border-box;\n  height: 76px;\n  background-color: #ffffff;\n  border: 1px solid #e3e3e3;\n  border-radius: 6px;\n  box-shadow: 0 2px 4px 0 #E1E2E6;\n  font-size: 12px;\n  line-height: 22px;\n  font-weight: 400;\n  color: #2d3e46;\n}\n.button-payment-method--icon-file, .button-payment-method--icon-credit-card {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin: 0 17px 0 27px;\n}\n.button-payment-method--icon-credit-card {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.button-payment-method--text {\n  margin-bottom: -2px;\n}\n.button-payment-method-active {\n  color: white;\n  background-color: #4e5960;\n  box-shadow: none;\n}\n.button-payment-method-active .button-payment-method--icon-file, .button-payment-method-active .button-payment-method--icon-credit-card {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.button-payment-method-active .button-payment-method--icon-credit-card {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}", ""]);
// Exports
module.exports = exports;

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import {Switch} from 'react-router-dom';
import UserFinalDestination from './user-final-destination';
import UserFinalDestinationDeleteDialog from './user-final-destination-delete-dialog';
import UserFinalDestinationDetail from './user-final-destination-detail';
import UserFinalDestinationUpdate from './user-final-destination-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserFinalDestinationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserFinalDestinationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserFinalDestinationDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserFinalDestination} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={UserFinalDestinationDeleteDialog} />
  </>
);

export default Routes;

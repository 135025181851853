import AlertModal from 'app/components/alert-modal/alert-modal';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import NextCancelButton from 'app/components/next-cancel-button/next-cancel-button';
import RadioFieldMeds from 'app/components/select-field/select-field';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getIntegrationUser, reset, saveIntegrationUser } from '../user-integration.reducer';
import { Col, Row } from 'reactstrap';
import '../user-integration.scss';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import Loading from 'app/components/loading/loading';
import { maskCnpj, maskCnpjOrCpf, maskCPF } from 'app/shared/util/mask-utils';

export interface IUserProfileDataEditProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IUserProfileDataEditState {
  id: number;
  firstLogin: string;
  secondLogin: string;
  type: string;
  password: string;
  showSuccess: boolean;
  isLoading: boolean;
  unitCode: string;
  unitName: string;
  showErrorModal: boolean;
}

export class UserIntegrationDetails extends React.Component<IUserProfileDataEditProps, IUserProfileDataEditState> {
  constructor(props: Readonly<IUserProfileDataEditProps>) {
    super(props);
    this.state = {
      id: null,
      firstLogin: null,
      secondLogin: null,
      type: null,
      password: null,
      unitCode: null,
      unitName: null,
      isLoading: true,
      showErrorModal: false,
      showSuccess: false,
    };
  }

  async componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.getIntegrationUser(id);
  }

  componentWillReceiveProps(newProps: IUserProfileDataEditProps) {
    if (newProps.integrationUser != null) {
      this.setState({
        id: newProps.integrationUser?.id,
        firstLogin: newProps.integrationUser?.firstLogin,
        secondLogin: newProps.integrationUser?.secondLogin,
        type: newProps.integrationUser?.type,
        password: newProps.integrationUser?.password,
        unitCode: newProps.integrationUser?.unit?.unitCode,
        unitName: newProps.integrationUser?.unit?.fantasyName,
        isLoading: false
      });
    } else if (newProps.errorMessage != null) {
      this.setState({
        isLoading: false
      });
    }

    if (newProps.updateSuccess) {
      this.toggleSuccessModal();
      this.props.reset();
    }
  }

  onNextClick = () => {
    const user = {
      ...this.state
    };

    this.props.saveIntegrationUser(user);
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  toggleSuccessModal = () => {
    this.setState({
      showSuccess: !this.state.showSuccess,
    });
  };

  onBackHandler = () => {
    this.props.history.goBack();
  };

  onGoBack = () => {
    this.props.history.push('/me/profile/user-integration');
  };

  onEditClicked = () => {
    const { integrationUser } = this.props;
    this.props.history.push({
      pathname: `/me/user-integration/edit`,
      state: { id: integrationUser.id },
    });
  };

  render() {
    const {
      firstLogin,
      secondLogin,
      type,
      unitCode,
      unitName,
      password,
      showErrorModal,
      showSuccess,
    } = this.state;
    if (this.state.isLoading) {
      return (<div />)
    }
    const types = [{ value: 'FEPAM', label: 'FEPAM (RS)' },
    { value: 'SINIR', label: 'SINIR (NACIONAL)' },
    { value: 'IMA', label: 'IMA (SC)' },
    { value: 'SIGOR', label: 'SIGOR (SP)' },
    { value: 'FEAM', label: 'FEAM (MG)' },
    { value: 'INEA', label: 'INEA (RJ)' }]

    if (checkStatusOnGoing([this.props.fetchIntegrationUserStatus])) {
      return <Loading />;
    }

    return (
      <div className="new-user__items list__items">
        <BackHeaderComponent onBack={this.onGoBack} hasButton onButtonClick={this.onEditClicked} />
        <div style={{ marginBottom: '35px' }}>
          <h1>
            {' '}
            <Translate contentKey={'user.details.title'} />
          </h1>
        </div>
        <Row>
          <Col style={{ margin: 'auto' }} md="4">
            <CustomTextField
              id={'firstLogin'}
              style={{ marginTop: '10px' }}
              value={firstLogin}
              error={false}
              label={translate('global.account.integration.cnpj')}
              placeholder={translate('global.account.integration.cnpj')}
              onMask={maskCnpj}
              readonly
            />
            <CustomTextField
              id={'secondLogin'}
              style={{ marginTop: '10px' }}
              value={secondLogin}
              error={false}
              label={translate('global.account.integration.cpf')}
              placeholder={translate('global.account.integration.cpf')}
              onMask={maskCPF}
              readonly
            />

            <CustomTextField
              id={'type'}
              style={{ marginTop: '10px' }}
              value={type}
              error={false}
              label={translate('global.account.integration.type')}
              placeholder={translate('global.account.integration.type')}
              readonly
            />
            <h1 style={{ marginTop: "20px" }}>Unidade de Integração</h1>
            <CustomTextField
              id={'unit'}
              style={{ marginTop: '10px' }}
              value={unitCode && unitName ? `${unitCode} - ${unitName}` : ''}
              error={false}
              label={translate('global.account.integration.unit')}
              placeholder={translate('global.account.integration.unit')}
              readonly
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  integrationUser: root.integrationUser.integrationUser,
  fetchIntegrationUserStatus: root.integrationUser.fetchIntegrationUserStatus,
  updateSuccess: root.integrationUser.updateSuccess,
  errorMessage: root.integrationUser.errorMessage,
});

const mapDispatchToProps = {
  saveIntegrationUser,
  getIntegrationUser,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserIntegrationDetails);

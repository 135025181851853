import { defaultValue, IDriver } from 'app/shared/model/driver.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IVehicle } from 'app/shared/model/vehicle.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import StringUtils from 'app/shared/util/string-utils';
import axios from 'axios';
import { ICrudGetAction, ICrudPutAction } from 'react-jhipster';
import { ITransporterMtr } from 'app/shared/model/transporter.model';

export const ACTION_TYPES = {
  CREATE_DRIVER: 'userProducer/CREATE_DRIVER',
  UPDATE_DRIVER: 'userProducer/UPDATE_DRIVER',
  GET_TRANSPORTER_DRIVERS: 'userProducer/GET_TRANSPORTER_DRIVERS',
  GET_TRANSPORTER_VEHICLES: 'userProducer/GET_TRANSPORTER_VEHICLES',
  GET_DRIVER_DETAIL: 'userProducer/GET_DRIVER_DETAIL',
  SEARCH_TRANSPORTER: 'transporter/SEARCH_TRANSPORTER',
  RESET: 'userProducer/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  createDriverStatus: HttpRequestStatus.NOOP,
  updateDriverStatus: HttpRequestStatus.NOOP,
  getTransporterDriversStatus: HttpRequestStatus.NOOP,
  getTransporterVehiclesStatus: HttpRequestStatus.NOOP,
  getDriveDetailStatus: HttpRequestStatus.NOOP,
  transporters: [] as ReadonlyArray<ITransporterMtr>,
  drivers: [],
  vehicles: [],
  driverDetail: defaultValue,
  totalItems: 0,
};

export type TransportState = Readonly<typeof initialState>;

// Reducer

export default (state: TransportState = initialState, action): TransportState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_TRANSPORTER):
      return {
        ...state,
        errorMessage: null,
      };
    case REQUEST(ACTION_TYPES.CREATE_DRIVER):
      return {
        ...state,
        errorMessage: null,
        createDriverStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_DRIVER):
      return {
        ...state,
        createDriverStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DRIVER):
      return {
        ...state,
        createDriverStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_DRIVER):
      return {
        ...state,
        errorMessage: null,
        updateDriverStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_DRIVER):
      return {
        ...state,
        updateDriverStatus: HttpRequestStatus.ERROR,
      };
    case FAILURE(ACTION_TYPES.SEARCH_TRANSPORTER):
      return {
        ...state,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_DRIVER):
      return {
        ...state,
        updateDriverStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_TRANSPORTER_DRIVERS):
      return {
        ...state,
        errorMessage: null,
        getTransporterDriversStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSPORTER_DRIVERS):
      return {
        ...state,
        getTransporterDriversStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSPORTER_DRIVERS):
      return {
        ...state,
        getTransporterDriversStatus: HttpRequestStatus.SUCCESS,
        drivers: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.GET_TRANSPORTER_VEHICLES):
      return {
        ...state,
        errorMessage: null,
        getTransporterVehiclesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_TRANSPORTER_VEHICLES):
      return {
        ...state,
        getTransporterVehiclesStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_TRANSPORTER_VEHICLES):
      return {
        ...state,
        getTransporterVehiclesStatus: HttpRequestStatus.SUCCESS,
        vehicles: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.GET_DRIVER_DETAIL):
      return {
        ...state,
        errorMessage: null,
        getDriveDetailStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_DRIVER_DETAIL):
      return {
        ...state,
        getDriveDetailStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_TRANSPORTER):
      return {
        ...state,
        loading: false,
        transporters: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_DRIVER_DETAIL):
      return {
        ...state,
        getDriveDetailStatus: HttpRequestStatus.SUCCESS,
        driverDetail: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/drivers';

// Actions

export const getDriversByTransporter: ICrudSearchActionCustom<IDriver> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${apiUrl}/transporter${params}`;
  return {
    type: ACTION_TYPES.GET_TRANSPORTER_DRIVERS,
    payload: axios.get<IDriver>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const searchTransporters: ICrudSearchActionCustom<ITransporterMtr> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `api/transporters/search${params}`;
  return {
    type: ACTION_TYPES.SEARCH_TRANSPORTER,
    payload: axios.get<ITransporterMtr>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getVehiclesByTransporter: ICrudSearchActionCustom<IVehicle> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `api/vehicles/transporter${params}`;
  return {
    type: ACTION_TYPES.GET_TRANSPORTER_VEHICLES,
    payload: axios.get<IVehicle>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const createDriver: ICrudPutAction<IDriver> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/new`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DRIVER,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  dispatch(getDriversByTransporter());
  return result;
};

export const updateDriver: ICrudPutAction<IDriver> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/update`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DRIVER,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getDriversByTransporter());
  return result;
};

export const getDriverDetail: ICrudGetAction<IDriver> = driverId => {
  const requestUrl = `${apiUrl}/details/${driverId}`;
  return {
    type: ACTION_TYPES.GET_DRIVER_DETAIL,
    payload: axios.get<IDriver>(`${requestUrl}`),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z, APP_ONLY_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { isEmpty } from 'lodash';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const currentDatePlusTenDays = () => moment().add(10, 'days').format(APP_ONLY_DATE_FORMAT);

export const convertBrazilianStandardDate = (date: string) => (!isEmpty(date) ? moment(date).format(APP_LOCAL_DATE_FORMAT) : '');

import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { deleteEntity, reset as resetVehicle } from 'app/entities/vehicle/vehicle.reducer';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { Pageable } from 'app/shared/model/pageable';
import { IVehicle } from 'app/shared/model/vehicle.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/shared/util/string-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { getVehiclesByTransporter, reset } from '../../transport-reducer';
import './vehicle-list.scss';

export interface IVehicleListProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IVehicleListState {
  filterName: string;
  page: Pageable;
}

export class VehicleList extends React.Component<IVehicleListProps, IVehicleListState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('transporter.transport.vehicle.headers.model'), sortCode: 'model' },
    { name: translate('transporter.transport.vehicle.headers.plate'), sortCode: 'plate' },
    { name: translate('transporter.transport.vehicle.headers.year'), sortCode: 'year' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IVehicleListProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getVehiclesByTransporter();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS) {
      this.props.resetVehicle();
      this.getVehiclesByTransporter();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getVehiclesByTransporter = () => {
    const { page } = this.state;
    this.props.getVehiclesByTransporter(page.page, page.size, page.sort, StringUtils.toObject('name', this.state.filterName));
  };

  private handleTransformToTableContent = (content?: IVehicle[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IVehicle) => {
      result.push([
        item.model ?? '',
        item.plate != null ? item.plate : '',
        item.year ? item.year : '',
        <DeleteEditButton id={item.id} onDelete={this.onDeleteVehicle} onEdit={this.onEditVehicle} />,
      ]);
    });

    return result;
  };

  onDeleteVehicle = (id: number) => {
    this.props.deleteEntity(id);
  };

  onCreateVehicle = () => {
    this.props.history.push(`${this.props.match.url}/new`);
  };

  onEditVehicle = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/edit`,
      state: { id: id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getVehiclesByTransporter()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getVehiclesByTransporter()
    );
  };

  onHandleFilterChanged = value => {
    this.setState({ filterName: value }, () => this.getVehiclesByTransporter());
  };

  onVehicleClick = (index: number) => {
    const { vehicles } = this.props;
    const vehicle = vehicles[index];
    this.props.history.push({
      pathname: `${this.props.match.url}/details`,
      state: { id: vehicle.id },
    });
  };

  render() {
    const { vehicles } = this.props;
    const rows = this.handleTransformToTableContent(vehicles);
    return (
      <div className="mtr-unity__items mtr-invite__table">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('transporter.transport.vehicle.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px' }}>
            <Col md="6">
              <SimpleSearchField placeholder={translate('transporter.transport.vehicle.search')} onChange={this.onHandleFilterChanged} />
            </Col>
            <Col md={{ size: 2, offset: 4 }}>
              <Button className={'button primary'} type={'submit'} onClick={this.onCreateVehicle}>
                <Translate contentKey={'transporter.transport.vehicle.add'} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onVehicleClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  getTransporterVehiclesStatus: root.transport.getTransporterVehiclesStatus,
  totalItems: root.transport.totalItems,
  vehicles: root.transport.vehicles,
  deleteStatus: root.vehicle.deleteVehicleStatus,
});

const mapDispatchToProps = {
  getVehiclesByTransporter,
  deleteEntity,
  resetVehicle,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);

import axios from 'axios';
import { ICrudDeleteAction } from 'react-jhipster';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { IUserProducer } from 'app/shared/model/user-producer.model';
import { IProducerLinkVM } from 'app/shared/model/producer-link-vm';
import { IProducer } from 'app/shared/model/producer.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import StringUtils from 'app/shared/util/string-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import { ICrudSearchActionCustom } from 'app/shared/util/reducer-utils';
import { defaultValue as defaultInvitation, IManagerProducerInvitation } from 'app/shared/model/manager-producer-invitation.model';

export const ACTION_TYPES = {
  FETCH_MANAGER_PRODUCERS: 'manager-link/FETCH_MANAGER_PRODUCERS',
  FETCH_ALL_PRODUCERS: 'manager-link/FETCH_ALL_PRODUCERS',
  REMOVE_PRODUCER: 'manager-link/REMOVE_PRODUCER',
  CREATE_INVITATION: 'manager-link/CREATE_INVITATION',
  UPDATE_INVITATION: 'manager-link/UPDATE_INVITATION',
  GET_INVITATION: 'manager-link/GET_INVITATION',
  ADD_PRODUCER: 'manager-link/ADD_PRODUCER',
  RESET: 'manager-link/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  planErrorMessage: null,
  managerProducers: [] as Array<IUserProducer>,
  producers: [] as Array<IProducer>,
  fetchManagerProducersStatus: HttpRequestStatus.NOOP,
  fetchAllProducersStatus: HttpRequestStatus.NOOP,
  removeProducerStatus: HttpRequestStatus.NOOP,
  updateInvitationStatus: HttpRequestStatus.NOOP,
  createInvitationStatus: HttpRequestStatus.NOOP,
  getInvitationStatus: HttpRequestStatus.NOOP,
  addProducerStatus: HttpRequestStatus.NOOP,
  invitation: defaultInvitation,
  totalItems: 0,
};

export type ManagerLinkState = Readonly<typeof initialState>;

// Reducer

export default (state: ManagerLinkState = initialState, action): ManagerLinkState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MANAGER_PRODUCERS):
      return {
        ...state,
        errorMessage: null,
        fetchManagerProducersStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_MANAGER_PRODUCERS):
      return {
        ...state,
        fetchManagerProducersStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MANAGER_PRODUCERS):
      return {
        ...state,
        fetchManagerProducersStatus: HttpRequestStatus.SUCCESS,
        managerProducers: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.FETCH_ALL_PRODUCERS):
      return {
        ...state,
        errorMessage: null,
        fetchAllProducersStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_ALL_PRODUCERS):
      return {
        ...state,
        fetchAllProducersStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_PRODUCERS):
      return {
        ...state,
        fetchAllProducersStatus: HttpRequestStatus.SUCCESS,
        producers: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.REMOVE_PRODUCER):
      return {
        ...state,
        errorMessage: null,
        removeProducerStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.REMOVE_PRODUCER):
      return {
        ...state,
        removeProducerStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.REMOVE_PRODUCER):
      return {
        ...state,
        removeProducerStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.CREATE_INVITATION):
      return {
        ...state,
        errorMessage: null,
        planErrorMessage: null,
        createInvitationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_INVITATION):
      return {
        ...state,
        createInvitationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
        planErrorMessage: action.payload?.response?.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INVITATION):
      return {
        ...state,
        createInvitationStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_INVITATION):
      return {
        ...state,
        errorMessage: null,
        updateInvitationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_INVITATION):
      return {
        ...state,
        updateInvitationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_INVITATION):
      return {
        ...state,
        updateInvitationStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_INVITATION):
      return {
        ...state,
        errorMessage: null,
        getInvitationStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_INVITATION):
      return {
        ...state,
        getInvitationStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_INVITATION):
      return {
        ...state,
        getInvitationStatus: HttpRequestStatus.SUCCESS,
        invitation: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.ADD_PRODUCER):
      return {
        ...state,
        errorMessage: null,
        addProducerStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.ADD_PRODUCER):
      return {
        ...state,
        addProducerStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.ADD_PRODUCER):
      return {
        ...state,
        addProducerStatus: HttpRequestStatus.SUCCESS,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const userProducerUrl = 'api/user-producers';
const managerUrl = 'api/managers';
const producerUrl = 'api/producers';
const invitationUrl = 'api/manager-producer-invitations';

// Actions

export const getAllManagerProducers: ICrudSearchActionCustom<IProducer> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${userProducerUrl}/manager/linked${params}`;
  return {
    type: ACTION_TYPES.FETCH_MANAGER_PRODUCERS,
    payload: axios.get<IProducer>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getAllProducers: ICrudSearchActionCustom<IProducer> = (page, size, sort, search?) => {
  const searchParams = StringUtils.parseQueryParams(search);
  const params = organizeSearchParameters(page, size, sort, searchParams);
  const requestUrl = `${producerUrl}/search/link${params}`;
  return {
    type: ACTION_TYPES.FETCH_ALL_PRODUCERS,
    payload: axios.get<IProducer>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const createInvitation = (invitation: IManagerProducerInvitation) => async dispatch => {
  const requestUrl = `${invitationUrl}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVITATION,
    payload: axios.post(requestUrl, invitation),
  });
  return result;
};

export const updateInvitation = (invitation: IManagerProducerInvitation) => async dispatch => {
  const requestUrl = `${invitationUrl}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INVITATION,
    payload: axios.put(requestUrl, invitation),
  });
  return result;
};

export const getInvitationByProducer = (invitationId: string) => {
  const requestUrl = `${invitationUrl}/${invitationId}/producer`;
  return {
    type: ACTION_TYPES.GET_INVITATION,
    payload: axios.get(requestUrl),
  };
};

export const linkProducer = (producer: IProducerLinkVM) => async dispatch => {
  const requestUrl = `${managerUrl}/producer/link`;
  const result = await dispatch({
    type: ACTION_TYPES.ADD_PRODUCER,
    payload: axios.post(requestUrl, producer),
  });
  return result;
};

export const removeProducer: ICrudDeleteAction<IProducer> = id => async dispatch => {
  const requestUrl = `${managerUrl}/producer/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.REMOVE_PRODUCER,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

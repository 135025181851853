import React from 'react';
import {CommonLoading} from 'react-loadingg';

export interface ILoadingProps {
  color?: string;
  height?: string;
  width?: string;
  size?: string;
}

export class Loading extends React.Component<ILoadingProps> {
  render() {
    const { color, height, width, size } = this.props;
    return (
      <div>
        <CommonLoading color={color ? color : '#30ab64'} size={size ? size : 'small'} />
      </div>
    );
  }
}

export default Loading;

import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, IUserFinalDestination } from 'app/shared/model/user-final-destination.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudGetEmptyAction } from 'react-jhipster';
import { getSubsidiariesByHolding } from '../../../app/modules/final-destination/units/final-destination-units-reducer';

export const ACTION_TYPES = {
  FETCH_USERFINALDESTINATION_LIST: 'userFinalDestination/FETCH_USERFINALDESTINATION_LIST',
  FETCH_USERFINALDESTINATION: 'userFinalDestination/FETCH_USERFINALDESTINATION',
  CREATE_USERFINALDESTINATION: 'userFinalDestination/CREATE_USERFINALDESTINATION',
  UPDATE_USERFINALDESTINATION: 'userFinalDestination/UPDATE_USERFINALDESTINATION',
  DELETE_USERFINALDESTINATION: 'userFinalDestination/DELETE_USERFINALDESTINATION',
  GET_FINALDESTINATION_DETAIL_UUID: 'userFinalDestination/GET_FINALDESTINATION_DETAIL_UUID',
  GET_FINALDESTINATION_DETAIL_ID: 'userFinalDestination/GET_FINALDESTINATION_DETAIL_ID',
  UPDATE_HOLDING: 'userFinalDestination/UPDATE_HOLDING',
  UPDATE_SUBSIDIARY: 'userFinalDestination/UPDATE_SUBSIDIARY',
  GET_USERFINALDESTINATION_DETAIL_UUID: 'userFinalDestination/GET_USERFINALDESTINATION_DETAIL_UUID',
  RESET: 'userFinalDestination/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserFinalDestination>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  finalDestinationIdDetail: defaultValue,
  finalDestinationUuidDetail: defaultValue,
  deleteEntityStatus: HttpRequestStatus.NOOP,
  finalDestinationDetailUuidStatus: HttpRequestStatus.NOOP,
  finalDestinationDetailIdStatus: HttpRequestStatus.NOOP,
  totalItems: 0,
  updateHoldingStatus: HttpRequestStatus.NOOP,
  updateSubsidiaryStatus: HttpRequestStatus.NOOP,
};

export type UserFinalDestinationState = Readonly<typeof initialState>;

// Reducer

export default (state: UserFinalDestinationState = initialState, action): UserFinalDestinationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERFINALDESTINATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERFINALDESTINATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USERFINALDESTINATION):
    case REQUEST(ACTION_TYPES.UPDATE_USERFINALDESTINATION):
    case REQUEST(ACTION_TYPES.DELETE_USERFINALDESTINATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USERFINALDESTINATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERFINALDESTINATION):
    case FAILURE(ACTION_TYPES.CREATE_USERFINALDESTINATION):
    case FAILURE(ACTION_TYPES.UPDATE_USERFINALDESTINATION):
    case FAILURE(ACTION_TYPES.DELETE_USERFINALDESTINATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERFINALDESTINATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERFINALDESTINATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERFINALDESTINATION):
    case SUCCESS(ACTION_TYPES.UPDATE_USERFINALDESTINATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERFINALDESTINATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };

    case REQUEST(ACTION_TYPES.GET_FINALDESTINATION_DETAIL_ID):
      return {
        ...state,
        errorMessage: null,
        finalDestinationDetailIdStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_FINALDESTINATION_DETAIL_ID):
      return {
        ...state,
        finalDestinationDetailIdStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_FINALDESTINATION_DETAIL_ID):
      return {
        ...state,
        finalDestinationDetailIdStatus: HttpRequestStatus.SUCCESS,
        finalDestinationIdDetail: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.GET_FINALDESTINATION_DETAIL_UUID):
      return {
        ...state,
        errorMessage: null,
        finalDestinationDetailUuidStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_FINALDESTINATION_DETAIL_UUID):
      return {
        ...state,
        finalDestinationDetailUuidStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_FINALDESTINATION_DETAIL_UUID):
      return {
        ...state,
        finalDestinationDetailUuidStatus: HttpRequestStatus.SUCCESS,
        finalDestinationUuidDetail: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.GET_USERFINALDESTINATION_DETAIL_UUID):
      return {
        ...state,
        errorMessage: null,
        finalDestinationDetailUuidStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_USERFINALDESTINATION_DETAIL_UUID):
      return {
        ...state,
        finalDestinationDetailUuidStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_USERFINALDESTINATION_DETAIL_UUID):
      return {
        ...state,
        finalDestinationDetailUuidStatus: HttpRequestStatus.SUCCESS,
        finalDestinationUuidDetail: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        errorMessage: null,
        updateHoldingStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        updateHoldingStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_HOLDING):
      return {
        ...state,
        updateHoldingStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        errorMessage: null,
        updateSubsidiaryStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        updateSubsidiaryStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SUBSIDIARY):
      return {
        ...state,
        updateSubsidiaryStatus: HttpRequestStatus.SUCCESS,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-final-destinations';

// Actions

export const getEntities: ICrudGetAllAction<IUserFinalDestination> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_USERFINALDESTINATION_LIST,
  payload: axios.get<IUserFinalDestination>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IUserFinalDestination> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERFINALDESTINATION,
    payload: axios.get<IUserFinalDestination>(requestUrl),
  };
};

export const getEntityByFinalDestination: ICrudGetAction<IUserFinalDestination> = id => {
  const requestUrl = `${apiUrl}/final-destination/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERFINALDESTINATION,
    payload: axios.get<IUserFinalDestination>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERFINALDESTINATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERFINALDESTINATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const deleteSubsidiary: ICrudDeleteAction<IUserFinalDestination> = id => async dispatch => {
  const requestUrl = `${apiUrl}/activate-deactivate/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERFINALDESTINATION,
    payload: axios.put(requestUrl),
  });
  dispatch(getSubsidiariesByHolding());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IUserFinalDestination> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERFINALDESTINATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const getFinalDestinationDetailByUuid: ICrudGetEmptyAction<IUserFinalDestination> = () => {
  const requestUrl = `${apiUrl}/details`;
  return {
    type: ACTION_TYPES.GET_USERFINALDESTINATION_DETAIL_UUID,
    payload: axios.get<IUserFinalDestination>(requestUrl),
  };
};

export const updateSubsidiary: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/subsidiary`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SUBSIDIARY,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateHolding: ICrudPutAction<IUserFinalDestination> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/holding`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_HOLDING,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getFinalDestinationDetailById: ICrudGetAction<IUserFinalDestination> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.GET_FINALDESTINATION_DETAIL_ID,
    payload: axios.get<IUserFinalDestination>(requestUrl),
  };
};

import React, { Fragment } from 'react';
import './custom-cdf-manage-card.scss'
import CustomMtrTag from 'app/components/custom-mtr-tag/custom-mtr-tag';
import { Cdf } from 'app/shared/model/cdf.model';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Alert } from '@material-ui/lab';
import { IMtr } from 'app/shared/model/mtr.model';
import { cloneDeep, groupBy, orderBy, result } from 'lodash';
import moment from 'moment';
import {
    APP_LOCAL_DATE_FORMAT,
    APP_LOCAL_DATE_FORMAT_MONTH_YEAR,
    APP_LOCAL_DATE_FORMAT_MONTH_YEAR_SMALL,
    APP_LOCAL_DATE_FORMAT_ORDER_BY
} from 'app/config/constants';
import { CdfStatus } from 'app/shared/model/enum/cdfStatus';
import { translate } from 'react-jhipster';
import { maskCnpj, maskZipCode, maskCPF } from 'app/shared/util/mask-utils';
import { ResidueCdfVm } from 'app/shared/model/vm/residue-cdf.vm';
import { MeasurementType } from 'app/shared/model/authorization.model';
import { IIntegrationCdf } from 'app/shared/model/integration-cdf.model';
import setupAxiosInterceptors from 'app/config/axios-interceptor';
import integrationCdfApi from 'app/api/integrationCdfApi';

interface ICustomCdfManageProps {
    cdf: Cdf;
}

class CustomCdfManageCard extends React.Component<ICustomCdfManageProps> {
    constructor(props) {
        super(props);
        this.state = {
            results: []
        }
    }

    getCdfPeriod = (mtrs: IMtr[]) => {
        const { cdf } = this.props;
        if (cdf.startDate == null) {
            const mtrsCopy = cloneDeep(mtrs);
            const orderedMtrsCopy: IMtr[] = orderBy(mtrsCopy, (item: IMtr) => moment(item.collectionDate).format(APP_LOCAL_DATE_FORMAT_ORDER_BY), ['asc']);
            const initialDate = orderedMtrsCopy?.[0]?.collectionDate ? moment(orderedMtrsCopy[0].collectionDate).format(APP_LOCAL_DATE_FORMAT_MONTH_YEAR) : '';
            const finalDate = orderedMtrsCopy?.[orderedMtrsCopy.length - 1]?.collectionDate ? moment(orderedMtrsCopy[orderedMtrsCopy.length - 1].collectionDate).format(APP_LOCAL_DATE_FORMAT_MONTH_YEAR) : '';
            return initialDate && finalDate ? `${initialDate} - ${finalDate}` : '';
        }
        const initialDate = cdf.startDate ? moment(cdf.startDate).format(APP_LOCAL_DATE_FORMAT_MONTH_YEAR) : '';
        const finalDate = cdf.endDate ? moment(cdf.endDate).format(APP_LOCAL_DATE_FORMAT_MONTH_YEAR) : '';
        return initialDate && finalDate ? `${initialDate} - ${finalDate}` : '';
    };

    componentDidMount() {
        const getNumeroCDF = async () => {
            const { cdf } = this.props;
            const { data: integrationNumberCdf, data: integrationType, data: integrationCdfStatus, data: errorMessage } = await integrationCdfApi.getCdf(cdf.id);

            return this.setState({
                results: integrationNumberCdf, integrationType, integrationCdfStatus, errorMessage
            });

        }
        getNumeroCDF();
    }


    getDates = (mtrs: IMtr[], isInitialDate?: boolean) => {
        const { cdf } = this.props;
        if (cdf.startDate == null) {
            const mtrsCopy = cloneDeep(mtrs);
            const orderedMtrsCopy: IMtr[] = orderBy(mtrsCopy, (item: IMtr) => moment(item.collectionDate).format(APP_LOCAL_DATE_FORMAT_ORDER_BY), ['asc']);
            const initialDate = orderedMtrsCopy?.[0]?.collectionDate ? moment(orderedMtrsCopy[0].collectionDate).format(APP_LOCAL_DATE_FORMAT) : '';
            const finalDate = orderedMtrsCopy?.[orderedMtrsCopy.length - 1]?.collectionDate ? moment(orderedMtrsCopy[orderedMtrsCopy.length - 1].collectionDate).format(APP_LOCAL_DATE_FORMAT) : '';
            return isInitialDate ? initialDate : finalDate;
        }
        const initialDate = cdf.startDate ? moment(cdf.startDate).format(APP_LOCAL_DATE_FORMAT) : '';
        const finalDate = cdf.endDate ? moment(cdf.endDate).format(APP_LOCAL_DATE_FORMAT) : '';
        return isInitialDate ? initialDate : finalDate;
    };

    getMtrs = (mtrs: IMtr[]) => {
        const mtrsCopy = cloneDeep(mtrs);
        return orderBy(mtrsCopy, (item: IMtr) => moment(item.collectionDate).format(APP_LOCAL_DATE_FORMAT_ORDER_BY), ['asc']);
    };

    getCdfStatusStyle = (cdfStatus: CdfStatus) => {
        if (cdfStatus == null) return <div />;

        let style = 'status-tag ';

        switch (cdfStatus) {
            case CdfStatus.APPROVED:
                style = style.concat('complete');
                break;

            case CdfStatus.CANCELLED:
                style = style.concat('cancelled');
                break;

            case CdfStatus.PENDING:
                style = style.concat('pending');
                break;
        }

        return (
            <div className={style}>
                <span>{translate(`cdf.status.${cdfStatus}`)?.toUpperCase()}</span>
            </div>
        );
    };

    getProducerCompanyname = () => {
        const { cdf } = this.props;
        if (cdf.producer?.legalPerson.companyName) return cdf.producer?.legalPerson.companyName
    }
    getProducerCnpjFormatted = () => {
        const { cdf } = this.props;
        if (cdf.producer?.legalPerson.cnpj) return maskCnpj(cdf.producer?.legalPerson.cnpj);
    };

    getProducerCpfFormatted = () => {
        const { cdf } = this.props;
        if (cdf.producer?.naturalPerson.cpf) return maskCPF(cdf.producer?.naturalPerson.cpf);
    }

    getFinalDestinationCompanyname = () => {
        const { cdf } = this.props;
        if (cdf.finalDestination?.legalPerson.companyName) return cdf.finalDestination?.legalPerson.companyName
    }

    getFinalDestinationCnpjFormatted = () => {
        const { cdf } = this.props;
        if (cdf.finalDestination?.legalPerson.cnpj) return maskCnpj(cdf.finalDestination?.legalPerson.cnpj);
    }

    getResidues = (mtrs: IMtr[]) => {
        const residues: ResidueCdfVm[] = [];
        const newResidues: ResidueCdfVm[] = [];

        mtrs?.forEach(mtr => {
            mtr.mtrResidues?.forEach(mtrResidue => {
                const sourceResidue = mtrResidue.sourceResidue;

                const measurementType = translate(`enums.measurementTypeAbbreviation.${mtrResidue.measurementType ?? MeasurementType.KG}`)


                const residueTreatment = sourceResidue.residueTreatment != null
                    ? translate(`enums.residueTreatment.${sourceResidue.residueTreatment}`)
                    : '';

                const residueVm: ResidueCdfVm = {
                    measurementType,
                    residueTreatment,
                    amount: mtrResidue.amount,
                    amountReceipt: mtrResidue.amountReceipt,
                    residueCode: sourceResidue.residue?.code,
                    description: sourceResidue.residue?.description
                };

                residues.push(residueVm);
            });
        });

        const residuesGroupingBy = groupBy(residues, (item) => [item.residueCode, item.residueTreatment, item.description, item.measurementType]);

        Object.keys(residuesGroupingBy).forEach(key => {
            let amount = 0;
            let amountReceipt = 0;
            const cdfs = residuesGroupingBy[key];
            cdfs.map(value => amount += value.amount || 0);
            cdfs.map(value => amountReceipt += value.amountReceipt || 0);

            const residueVm: ResidueCdfVm = {
                amount, amountReceipt,
                residueCode: cdfs[0].residueCode,
                description: cdfs[0].description,
                measurementType: cdfs[0].measurementType,
                residueTreatment: cdfs[0].residueTreatment
            };

            newResidues.push(residueVm);
        });

        newResidues.sort((a, b) => a.amountReceipt > b.amountReceipt ? -1 : 1);
        // newResidues.sort((a, b) => a.amountReceipt > b.amountReceipt ? -1 : 1);
        newResidues.sort((a, b) => a.amount > b.amount ? -1 : 1);

        return newResidues;
    };

    renderResidues = (residues: ResidueCdfVm[]) => {
        return (
            residues.map((item, index) =>
                <div className="table-lines" key={index}>
                    <div style={{ flex: '0 0 48%' }} className="table-line">
                        <div className="table-line--title">
                            {translate('cdf.file.description')}
                        </div>
                        <div className="table-line--text">
                            {item.description || ''}
                        </div>
                    </div>
                    <div style={{ flex: '0 0 19%' }} className="table-line">
                        <div className="table-line--title">
                            {translate('cdf.file.treatment')}
                        </div>
                        <div className="table-line--text">
                            {item.residueTreatment || ''}
                        </div>
                    </div>
                    <div style={{ flex: '0 0 12%' }} className="table-line table-line-gray">
                        <div className="table-line--title">
                            {translate('cdf.file.code')}
                        </div>
                        <div className="table-line--text">
                            {item.residueCode || ''}
                        </div>
                    </div>
                    <div style={{ flex: '0 0 10%' }} className="table-line table-line-green">
                        <div className="table-line--title">
                            {translate('cdf.file.quantity')}
                        </div>
                        <div className="table-line--text">
                            {`${item.amount} ${item.measurementType}`}
                        </div>
                    </div>
                    <div style={{ flex: '0 0 10%' }} className="table-line table-line-green">
                        <div className="table-line--title">
                            {translate('cdf.file.quantityReceipt')}
                        </div>
                        <div className="table-line--text">
                            {`${item.amountReceipt} ${item.measurementType}`}
                        </div>
                    </div>

                </div>
            )
        );
    };


    render() {
        const { cdf } = this.props;

        const { results } = this.state;

        return (
            <Fragment>
                <div className="cdf-signature__card">
                    <div className="cdf-signature__card-padding">
                        {results.errorMessage != null &&
                            <div style={{ marginLeft: '150px', marginBottom: '20px' }}>
                                <Row>
                                    <Col md={{ size: 11 }} style={{ paddingRight: '55px' }}>
                                        <Alert
                                            severity="error">{`${translate('mtr-signature.integrationError')} ${results.integrationType} : ${results.errorMessage}`}</Alert>
                                    </Col>
                                </Row>
                            </div>
                        }
                        <table style={{ width: '100%', padding: '20px 82px 0px 82px' }}>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <td style={{ width: '100%' }}>
                                    <div className="img-logo" />
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    {cdf.integrationCdf != null &&
                                        cdf.integrationCdf
                                            .filter(cdfintegration => cdfintegration.integrationCdfStatus == "FINISHED_CDF" || "FINISHED_ALL")
                                            .map(cdfintegration => {
                                                return (
                                                    <div>
                                                        {cdfintegration.integrationNumberCdf &&
                                                            <div>
                                                                {translate('cdf.file.cdfAgencyNumber')} {cdfintegration.agency}
                                                                <span>{cdfintegration.integrationNumberCdf ?? ''}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                </td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: '10px' }}>
                                    {results.integrationType && (
                                        <div className="text-title AgencyColor" style={{ marginRight: '30px' }}>
                                            {translate('cdf.ncdf')} {results.integrationType}
                                        </div>
                                    )}
                                    {results.integrationNumberCdf && (
                                        <div className="text-subtitle2" style={{ marginRight: '30px' }}>
                                            <span>{results.integrationNumberCdf}</span>
                                        </div>
                                    )}
                                    {/* <div className="text-title AgencyColor" style={{ marginRight: '30px' }}>
                                        {translate('cdf.ncdf')} {results.integrationType}
                                    </div> */}
                                    {/* <div className="text-subtitle2" style={{ marginRight: '30px' }}>
                                        <span>{results.integrationNumberCdf}</span>
                                    </div> */}
                                </td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: '10px' }}>
                                    <div className="text-title" style={{ marginRight: '100px' }}>
                                        {translate('cdf.file.cdfNumber')}
                                    </div>
                                    <div className="text-subtitle" style={{ marginRight: '100px' }}>
                                        <span>{cdf.cdfNumber}</span>
                                    </div>
                                </td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: '10px' }}>
                                    <div className="text-title">
                                        {translate('cdf.file.period')}
                                    </div>
                                    <div className="text-subtitle">
                                        {this.getCdfPeriod(cdf.mtrs || [])}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div className="tag-status">
                            {this.getCdfStatusStyle(cdf.cdfStatus || CdfStatus.CANCELLED)}
                        </div>
                        <div className="body-text">
                            {translate('cdf.file.text', {
                                cnpj: this.getFinalDestinationCnpjFormatted(),
                                companyName: this.getFinalDestinationCompanyname(),
                                endDate: this.getDates(cdf.mtrs || []),
                                initialDate: this.getDates(cdf.mtrs || [], true)
                            })}
                        </div>
                        <div className="body-title">
                            {translate('cdf.file.finalDestination')}
                        </div>
                        <div style={{ marginBottom: '24px' }} className="table-col">
                            <div className="table-lines">
                                <div style={{ flex: '0 0 50%' }} className="table-line">
                                    <div className="table-line--title">
                                        {translate('cdf.file.companyName')}
                                    </div>
                                    <div className="table-line--text">
                                        {(cdf.finalDestination?.legalPerson.companyName || cdf.finalDestination?.naturalPerson.name || '')}
                                    </div>
                                </div>
                                <div style={{ flex: '0 0 50%' }} className="table-line">
                                    <div className="table-line--title">
                                        {translate('cdf.file.cnpj')}
                                    </div>
                                    <div className="table-line--text">
                                        {this.getFinalDestinationCnpjFormatted()}
                                        {/* {(cdf.finalDestination?.legalPerson.cnpj || cdf.finalDestination?.naturalPerson.cpf || '')} */}
                                        {/* {cdf.finalDestination?.legalPerson.cnpj ? maskCnpj(cdf.finalDestination.legalPerson.cnpj) : ''} */}
                                    </div>
                                </div>
                            </div>
                            <div className="table-lines">
                                <div style={{ flex: '0 0 70%' }} className="table-line">
                                    <div className="table-line--title">
                                        {translate('cdf.file.address')}
                                    </div>
                                    <div className="table-line--text">
                                        {cdf.finalDestination?.addressLabel || ''}
                                    </div>
                                </div>
                                <div style={{ flex: '0 0 30%' }} className="table-line">
                                    <div className="table-line--title">
                                        {translate('cdf.file.zipcode')}
                                    </div>
                                    <div className="table-line--text">
                                        {cdf.finalDestination?.addressZipcode ? maskZipCode(cdf.finalDestination.addressZipcode) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="body-title">
                            {translate('cdf.file.residues')}
                        </div>
                        <div style={{ marginBottom: '24px' }} className="table-col">
                            {this.renderResidues(this.getResidues(cdf.mtrs || []))}
                        </div>
                        <div className="body-title">
                            {translate('cdf.file.mtrList')}
                        </div>
                        <div className="container-days">
                            <div className="container-days-line">
                                {this.getMtrs(cdf.mtrs || []).map((item, index) => (
                                    <Fragment key={index}>
                                        <CustomMtrTag
                                            tag={item.numberMtr || ''}
                                            date={item.collectionDate ? moment(item.collectionDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '1px', background: '#e3e3e3', marginBottom: '30px' }} />
                    <div className="cdf-signature__card-padding">
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ height: '100px', background: '#ffffff', margin: 'auto', width: '196px' }}>
                                    <img
                                        alt=""
                                        style={{ width: '196px', height: '100px' }}
                                        src={cdf.finalDestinationSignature?.signatureDocument?.presignedUrl || undefined} />
                                    <span><br /></span>
                                </div>
                                <div style={{ marginTop: '15px' }}>
                                    <div style={{ fontSize: '18px', color: '#000000', lineHeight: '22px' }}>
                                        <span>{(cdf.finalDestination?.legalPerson.companyName || cdf.finalDestination?.naturalPerson.name || '')}</span>
                                        {/* <span>{cdf.finalDestination?.legalPerson.companyName || cdf.finalDestination?.naturalPerson.name || ''}</span> */}
                                    </div>
                                    <div style={{
                                        opacity: '0.6',
                                        fontSize: '14px',
                                        color: '#7b7b7b',
                                        lineHeight: '18px'
                                    }}>
                                        {translate('cdf.file.finalDestination2')}
                                    </div>
                                    <div style={{
                                        opacity: '0.6',
                                        fontSize: '14px',
                                        color: '#7b7b7b',
                                        lineHeight: '18px'
                                    }}>
                                        <span>{cdf.finalDestinationSignatureDate ? moment(cdf.finalDestinationSignatureDate).format(APP_LOCAL_DATE_FORMAT) : '00/00/00'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default CustomCdfManageCard;

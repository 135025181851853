import { AxiosError, AxiosResponse } from 'axios';
import { HttpStatusNumber } from 'app/shared/model/enum/HttpStatusNumber';
import planApi from 'app/api/planApi';
import { ITypeSendPlan, ITypePlan, IBankSlipType } from 'app/shared/model/plan.model';
import toastUtils from 'app/shared/util/toast-utils';
import { translate } from 'react-jhipster';

const PlanService = () => {
  const createPlan = async (plan: ITypeSendPlan) => {
    try {
      const result: AxiosResponse<ITypeSendPlan> = await planApi.createPlan(plan);

      if (result.status === HttpStatusNumber.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ result: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPlan = async () => {
    try {
      const result: AxiosResponse<ITypePlan> = await planApi.getPlan();

      if (result.status === HttpStatusNumber.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ result: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBankSlip = async (planId: number) => {
    try {
      const result: AxiosResponse<IBankSlipType> = await planApi.getBankSlip(planId);

      if (result.status === HttpStatusNumber.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ result: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updatePlan = async (plan: ITypeSendPlan) => {
    try {
      const result: AxiosResponse<ITypeSendPlan> = await planApi.updatePlan(plan);
      if (result.status === HttpStatusNumber.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updatePaymentMethod = async (plan: ITypeSendPlan) => {
    try {
      const result: AxiosResponse<ITypeSendPlan> = await planApi.updatePaymentMethod(plan);
      if (result.status === HttpStatusNumber.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(error);

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    createPlan,
    getPlan,
    getBankSlip,
    updatePlan,
    updatePaymentMethod,
  };
};

export default PlanService();

import { AxiosError, AxiosResponse } from 'axios';
import { HttpStatusNumber } from 'app/shared/model/enum/HttpStatusNumber';
import publicIpAddressApi from 'app/api/publicIpAddress';
import IAddressIpType from 'app/shared/model/public-ip-address.model';

const PublicIpAddressService = () => {
  const getIp = async () => {
    try {
      const result: AxiosResponse<IAddressIpType> = await publicIpAddressApi.getIp();

      if (result.status === HttpStatusNumber.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ result: result.status });
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getIp,
  };
};

export default PublicIpAddressService();

import _ from 'lodash';
import { getUrlParameter, IPayload, translate } from 'react-jhipster';
import qs from 'qs';

export default class StringUtils {
  static parseQueryParams(obj: object, addQuery?: boolean) {
    const arg = removeEmptyStrings(obj);
    const result = qs.stringify(arg, { encode: true, skipNulls: true, addQueryPrefix: addQuery });
    if (result === '' && addQuery) {
      return '?';
    }
    return result;
  }

  static toValidString(text: any) {
    if (_.isEmpty(text)) {
      return '';
    }
    return text;
  }

  static validateCPF = (value, ctx, input, cb) => {
    let sum;
    let remnant;
    let isValid = true;
    const valueReplaced = value.replace(/[\D.-]/g, '');
    sum = 0;
    if (valueReplaced === '00000000000') isValid = false;
    for (let i = 1; i <= 9; i++) sum = sum + parseInt(valueReplaced.substring(i - 1, i), 10) * (11 - i);
    remnant = (sum * 10) % 11;

    if (remnant === 10 || remnant === 11) remnant = 0;
    if (remnant !== parseInt(valueReplaced.substring(9, 10), 10)) isValid = false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(valueReplaced.substring(i - 1, i), 10) * (12 - i);
    remnant = (sum * 10) % 11;

    if (remnant === 10 || remnant === 11) remnant = 0;
    if (remnant !== parseInt(valueReplaced.substring(10, 11), 10)) isValid = false;
    cb(isValid ? isValid : translate('error.invalidCPF'));
  };

  static convertDateToIsoString(date: Date) {
    return new Date(date.getTime()).toISOString();
  }

  static b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  static removeMask = (text: string) => {
    return text.replace(/\D/g, '');
  };

  static generateFileUrl = (file: string, type: string) => {
    const blobObject = StringUtils.b64toBlob(file, type, null);
    return URL.createObjectURL(blobObject);
  };

  static getSearchParam = (searchParam, location): string => {
    return getUrlParameter(searchParam, location.search);
  };

  static toObject(key: string, value: string) {
    const result = {};
    result[key] = value;
    return result;
  }

  static isStringInvalid(text) {
    return _.isNil(text) || text === '';
  }

  isOnlyNumber(text) {
    const regex = /^[0-9]*$/;
    return regex.test(text);
  }

  static checkEmptyString(text) {
    const regex = /^\s+/;
    return regex.test(text);
  }

  static isFullName(text) {
    const regex = /^[A-ZÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-ZÀ-Ÿ][A-zÀ-ÿ']+$/;
    return regex.test(text);
  }

  static hideCardNumber = (cardNumber: string) => {
    let separateCardNumber = cardNumber.trimRight().split(' ');

    separateCardNumber.map((value, index) => {
      if (index < 3) {
        let numberMasked = '';
        for (let i = 0; i < value.length; i++) {
          numberMasked = numberMasked + '*';
        }
        separateCardNumber[index] = numberMasked;
      }
    });

    return separateCardNumber.join(' ');
  };
}

const removeEmptyStrings = (obj?: object) => {
  if (obj == null) return {};
  const newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop] !== '') {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export declare type ICrudGetAllSearchAction<T> = (
  page?: number,
  size?: number,
  sort?: string,
  search?: any
) => IPayload<T> | ((dispatch: any) => IPayload<T>);

import React from 'react';
import './cnpj-sinir-modal.scss';
import { maskCnpj } from 'app/shared/util/mask-utils';

interface CnpjItem {
    parCodigo: number;
    parDescricao: string;
    parCnpj: string;
    parEndereco: string;
    parCidade: string;
    parUf: string;
}

interface CnpjSinirModalProps {
    isOpen: boolean;
    toggle: () => void;
    apiData: CnpjItem[];
    onSelect: (item: CnpjItem) => void; // Tipagem mais específica para o item selecionado
}

const CnpjSinirModal: React.FC<CnpjSinirModalProps> = ({ isOpen, toggle, apiData, onSelect }) => {
    return (
        <div className={`cnpj-sinir-modal ${isOpen ? 'open' : ''}`} role="dialog" aria-modal="true">
            <div className="modal-overlay" onClick={toggle} aria-label="Fechar modal"></div>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Lista de Parceiros do SINIR</h5>
                    <button className="close-button" onClick={toggle} aria-label="Fechar modal">×</button>
                </div>
                <div className="modal-body">
                    {apiData.length > 0 ? (
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th>Unidade</th>
                                    <th>CNPJ</th>
                                    <th>Razão Social</th>
                                    <th>Endereço</th>
                                    <th>Selecionar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.parCodigo}</td>
                                        <td>{maskCnpj(item.parCnpj)}</td>
                                        <td>{item.parDescricao}</td>
                                        <td>{item.parEndereco}</td>
                                        <td>
                                            <button
                                                className="select-button"
                                                onClick={() => onSelect(item)}
                                            >
                                                Selecionar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Nenhum dado carregado</p>
                    )}
                </div>
                <div className="modal-footer">
                    <button className="close-button" onClick={toggle}>Fechar</button>
                </div>
            </div>
        </div>
    );
};

export default CnpjSinirModal;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../content/images/icons/container-back.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".list__items--back {\n  height: 45px;\n  border: solid 1px #e3e3e3;\n  position: fixed;\n  background: white;\n  top: 79px;\n  left: 0;\n  right: 0;\n  z-index: 2;\n  overflow: hidden;\n}\n.list__items--back .confirmation--back {\n  display: flex;\n  align-items: center;\n  height: 46px;\n}\n.list__items--back .confirmation--back .confirmation--back-icon {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 10px;\n  cursor: pointer;\n}\n.list__items--back .confirmation--back span {\n  font-size: 0.875rem;\n  font-weight: 300;\n  font-family: Catamaran;\n  color: #30ab64;\n  cursor: pointer;\n}", ""]);
// Exports
module.exports = exports;

import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './manager-register-menu.scss';
import { translate } from 'react-jhipster';
import { StepItem, Steps } from '../../steps/steps';
import { IUserManager } from 'app/shared/model/user-manager.model';
import { ManagerRegisterPerson } from '../steps/person/manager-register-person';
import { ManagerRegisterAddress } from '../steps/address/manager-register-address';
import { ManagerRegisterResponsible } from '../steps/responsible/manager-register-responsible';
import { create, reset } from '../manager-register.reducer';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import Loading from 'app/components/loading/loading';
import { ALERT_ICONS, AUTHORITIES } from 'app/config/constants';
import { changeAuthority, getSession } from 'app/shared/reducers/authentication';
// import ManagerRegisterProducer from '../steps/producer/manager-register-producer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IManagerRegisterMenuProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IManagerRegisterMenuState {
  steps: StepItem[];
  currentIndex: number;
  userManager: IUserManager;
  showResponsabilityModal: boolean;
}

export class ManagerRegisterMenu extends React.Component<IManagerRegisterMenuProps, IManagerRegisterMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      userManager: {
        user: this.props.account,
      },
      steps: this.mapSteps(),
      currentIndex: 0,
      showResponsabilityModal: false,
    };
  }

  private mapSteps = (): StepItem[] => [
    {
      title: translate('fullRegister.header.company'),
      render: () => <ManagerRegisterPerson onNext={this.onNext} onBack={this.onBack} userManager={this.state.userManager} />,
    },
    {
      title: translate('fullRegister.header.address'),
      render: () => <ManagerRegisterAddress onNext={this.onNext} onBack={this.onBack} userManager={this.state.userManager} />,
    },
    {
      title: translate('fullRegister.header.responsible'),
      render: () => <ManagerRegisterResponsible onNext={this.onNext} onBack={this.onBack} userManager={this.state.userManager} />,
    },
    // {
    //   title: translate('fullRegister.header.link'),
    //   render: () => <ManagerRegisterProducer onNext={this.onNext} onBack={this.onBack} userManager={this.state.userManager} />,
    // },
  ];

  componentWillReceiveProps(newProps) {
    if (newProps.createManagerStatus === HttpRequestStatus.SUCCESS) {
      this.props.getSession();
      this.goToSuccessAlertPage();
      this.props.reset();
    }
    if (newProps.createManagerStatus === HttpRequestStatus.ERROR) {
      this.goToErrorAlertPage();
      this.props.reset();
    }
  }

  goToSuccessAlertPage = () => {
    this.props.history.push({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_OK,
        alertMessage: 'alert.managerRegister',
        buttonUrl: '/login',
      },
    });
  };

  goToErrorAlertPage = () => {
    this.props.history.replace({
      pathname: '/alert-page',
      state: {
        statusImage: ALERT_ICONS.PAPER_NO,
        alertMessage: 'alert.managerRegisterError',
        buttonUrl: '/full-register/manager',
      },
    });
  }


  onCancelModal = () => {
    this.setState({
      showResponsabilityModal: false,
    });
  };

  onConfirmModal = () => {
    const { userManager } = this.state;
    this.props.create(userManager);

    if (this.props.hasManagerAdminAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.MANAGER_ADMIN);
    } else if (this.props.hasManagerUserAuthority && this.props.isCreatingNewProfile) {
      this.props.changeAuthority(AUTHORITIES.MANAGER_USER);
    }
  };

  onNext = (userManager: IUserManager) => {
    const { currentIndex, steps } = this.state;
    if (currentIndex === steps.length - 1) {
      this.setState({
        showResponsabilityModal: true,
        userManager,
      });
    } else {
      this.setState({
        currentIndex: currentIndex + 1,
        userManager,
      });
    }
  };

  onBack = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        currentIndex: currentIndex - 1,
      });
    }
  };

  onIndexClick = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };

  render() {
    const { steps, currentIndex } = this.state;
    const { createManagerStatus } = this.props;
    if (createManagerStatus === HttpRequestStatus.ONGOING) {
      return <Loading />;
    }
    return (
      <div style={{ position: 'relative' }}>
        <Steps onIndexClick={this.onIndexClick} steps={steps} currentIndex={currentIndex} />
        <AlertModal
          showModal={this.state.showResponsabilityModal}
          buttonOneActionMessage={'entity.action.cancel'}
          buttonTwoActionMessage={'entity.action.agree'}
          hasTwoButtons
          buttonTwoAction={this.onConfirmModal}
          buttonOneAction={this.onCancelModal}
          statusImage="paper-question-mark.svg"
          alertMessage="managerRegister.responsibilityTerms"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSession,
  create,
  reset,
  changeAuthority
};

const mapStateToProps = (root: IRootState) => ({
  account: root.authentication.account,
  createManagerStatus: root.managerRegister.createManagerStatus,
  isCreatingNewProfile: root.authentication.isCreatingNewProfile,
  hasManagerUserAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.MANAGER_USER]),
  hasManagerAdminAuthority: hasAnyAuthority(root.authentication.account.allAuthorities, [AUTHORITIES.MANAGER_ADMIN])
});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerRegisterMenu);

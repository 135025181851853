import { IUser } from 'app/shared/model/user.model';
import { IManager } from 'app/shared/model/manager.model';
import { ClassCouncil } from './user-generic.model';

export interface IUserManager {
  id?: number;
  registrationNumber?: string;
  classCouncil?: ClassCouncil;
  user?: IUser;
  manager?: IManager;
}

export const defaultValue: Readonly<IUserManager> = {};

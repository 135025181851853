import React from 'react';
import MenuItem from 'app/shared/layout/menus/common/menu-item';
import { Translate, translate } from 'react-jhipster';
import { AccountDropDown } from './account-dropdown';

const accountMenuItemsAuthenticated = (isAdmin: boolean, isManager: boolean, isTransporter: boolean) => (
  <>
    <MenuItem icon={null} className="dropdown-item right" to="/me/profile/data">
      <Translate contentKey="global.menu.account.data">Data</Translate>
    </MenuItem>
    <MenuItem icon={null} className="dropdown-item right" to="/me/profile/password">
      <Translate contentKey="global.menu.account.change-password">Alterar Senha</Translate>
    </MenuItem>
    {isAdmin && !isManager && (
      <MenuItem className="dropdown-item right" icon={null} to="/me/profile/signature">
        <Translate contentKey="global.menu.account.responsibleTecnican">Signature</Translate>
      </MenuItem>
    )}
    {isAdmin && !isTransporter && (
      <MenuItem className="dropdown-item right" icon={null} to="/me/profile/user-manage">
        <Translate contentKey="global.menu.account.manageUsers">Manage</Translate>
      </MenuItem>
    )}
    <MenuItem className="dropdown-item right" icon={null} to="/logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

export const AccountMenu = ({
  isAuthenticated = false,
  isAdmin = false,
  isManager = false,
  IsTransporter = false,
  companyName = '',
  userName = '',
  profileType = '',
  isProfileAdmin,
}) => (
  <AccountDropDown
  icon="user"
  name={translate('global.menu.account.main')}
  id="account-menu"
  isAdmin={isAdmin}
  userName={userName}
  profileType={profileType}
  companyName={companyName}
  isManager={isManager}
  isTransporter={IsTransporter}
  isProfileAdmin={isProfileAdmin}
  >
  </AccountDropDown>
);

export default AccountMenu;

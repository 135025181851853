import { IProducerMtr } from 'app/shared/model/producer.model';
import { ITransporter, ITransporterMtr, PersonType } from 'app/shared/model/transporter.model';
import moment from 'moment';
import React from 'react';
import { translate } from 'react-jhipster';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './mtr-manage.scss';
import { IFinalDestinationMtr } from 'app/shared/model/final-destination.model';
import { ISourceResidueMtr } from 'app/shared/model/source-residue.model';
import { IMtr } from 'app/shared/model/mtr.model';
import { ITransshipmentMtr } from 'app/shared/model/transshipment.model';
import { IUnit } from 'app/shared/model/unit';
import { maskCPF } from 'app/shared/util/mask-utils';

export const renderProducer = (canRemove: boolean, cleanProducer, producer?: IProducerMtr) => {
    const name = producer?.legalPerson?.companyName.toUpperCase() ?? producer?.naturalPerson?.name.toUpperCase();
    const address = producer?.addressLabel ?? '';
    const zipCode = producer?.addressZipcode ?? '';
    return (
        <div>
            <Row>
                <Col md={{ size: 10, offset: 1 }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.generator')}</div>
                    <div className="list__items--component-third">
                        <div>
                            <div className="list__items--title">{translate('mtr.companyName')}</div>
                            <div className="list__items--text">{name}</div>
                        </div>
                        <div> {canRemove && <div className="list__items--icon" onClick={cleanProducer} />}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.address')}</div>
                            <div className="list__items--text">{address ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.zipcode')}</div>
                            <div className="list__items--text">{zipCode ?? ''}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const renderUnits = (canRemove: boolean, cleanUnits, unit) => {
    return (
        <div>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                            <div className="list__items--text">{unit.name?.toUpperCase() ?? ''}</div>
                        </div>
                        <div> {canRemove && <div className="list__items--icon" onClick={cleanUnits} />}</div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                            <div className="list__items--text">{unit?.unitCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                            <div className="list__items--text">{unit.unitAddressLabel ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                            <div className="list__items--text">{unit?.unitZipCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const renderUnitsTransshipment = (canRemove: boolean, cleanUnits, unit) => {
    return (
        <div>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                            <div className="list__items--text">{unit?.name?.toUpperCase() ?? ''}</div>
                        </div>
                        <div> {canRemove && <div className="list__items--icon" onClick={cleanUnits} />}</div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                            <div className="list__items--text">{unit?.unitCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                            <div className="list__items--text">{unit.unitAddressLabel ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                            <div className="list__items--text">{unit?.unitZipCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const renderUnitsFinalDestiny = (canRemove: boolean, cleanUnits, unit) => {
    return (
        <div>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                            <div className="list__items--text">{unit?.name?.toUpperCase() ?? ''}</div>
                        </div>
                        <div> {canRemove && <div className="list__items--icon" onClick={cleanUnits} />}</div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                            <div className="list__items--text">{unit?.unitCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                            <div className="list__items--text">{unit.unitAddressLabel ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                            <div className="list__items--text">{unit?.unitZipCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const renderUnitsTransporter = (canRemove: boolean, cleanUnits, unit) => {
    return (
        <div>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                            <div className="list__items--text">{unit?.name?.toUpperCase() ?? ''}</div>
                        </div>
                        <div> {canRemove && <div className="list__items--icon" onClick={cleanUnits} />}</div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                            <div className="list__items--text">{unit?.unitCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitAddressLabel')}</div>
                            <div className="list__items--text">{unit.unitAddressLabel ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr-units.unitZipCode')}</div>
                            <div className="list__items--text">{unit?.unitZipCode}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};


export const renderTransporter = (canRemove: boolean, cleanTransporter, transporter?: ITransporter & ITransporterMtr, mtr?: IMtr) => {
    const name = transporter?.name?.toUpperCase() ?? '';
    const address = transporter?.addressLabel ?? '';
    const zipCode = transporter?.addressZipcode ?? '';
    const transportLicenses =
        mtr?.transportLicense
            ? mtr.transportLicense
            : transporter?.transportLicenses?.length > 0
                ? transporter.transportLicenses[0]
                : null;
    return (
        <div>
            <Row>
                <Col md={{ size: 10, offset: 1 }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.transporter')}</div>
                    <div className="list__items--component">
                        <div>
                            {transporter.personType === PersonType.NATURAL ? (
                                <>
                                    <div className="list__items--title">Nome completo</div>
                                    <div className="list__items--text">{name ?? ''}</div>
                                </>
                            ) : (
                                <>
                                    <div className="list__items--title">{translate('mtr.companyName')}</div>
                                    <div className="list__items--text">{transporter?.legalPerson?.companyName?.toUpperCase() ?? ''}</div>
                                </>
                            )}
                        </div>
                        <div> {canRemove && <div className="list__items--icon" onClick={cleanTransporter} />}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.address')}</div>
                            <div className="list__items--text">{address ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.zipcode')}</div>
                            <div className="list__items--text">{zipCode ?? ''}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 2, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.transportLicense')}</div>
                            <div className="list__items--text">{transportLicenses?.licenseNumber ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="5" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                            <div className="list__items--text">{transportLicenses?.issuingBody?.toUpperCase() ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                            <div className="list__items--text">
                                {transportLicenses ? moment(transportLicenses.expireDate).format('DD/MM/YYYY') : ''}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const renderFinalDestination = (canRemove: boolean, cleanFinalDestination, finalDestination?: IFinalDestinationMtr, mtr?: IMtr) => {
    const name = finalDestination?.legalPerson?.companyName?.toUpperCase() ?? finalDestination?.naturalPerson?.name?.toUpperCase() ?? '';
    const cnpj = finalDestination?.legalPerson?.cnpj ?? maskCPF(finalDestination?.naturalPerson?.cpf ?? '');
    const address = finalDestination?.addressLabel ?? '';
    const zipCode = finalDestination?.addressZipcode ?? '';
    const finalDestinationCategory =
        finalDestination ? translate(`enums.finalDestinationCategory.${finalDestination.finalDestinationCategory}`) : {};
    const environmentalLicenses =
        mtr?.finalDestinationLicense
            ? mtr.finalDestinationLicense
            : finalDestination?.environmentalLicenses?.length > 0
                ? finalDestination.environmentalLicenses[0]
                : null;
    return (
        <div>
            <Row>
                <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.final-destiny')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{finalDestination?.naturalPerson?.cpf ? translate('mtr.name') : translate('mtr.companyName')}</div>
                            <div className="list__items--text">{name}</div>
                        </div>
                    </div>
                </Col>
                <Col md="5" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{finalDestination?.naturalPerson?.cpf ? translate('mtr.cpf') : translate('mtr.cnpj')}</div>
                            <div className="list__items--text">{cnpj}</div>
                        </div>
                        {canRemove && <div className="list__items--icon" onClick={cleanFinalDestination} />}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 10, offset: 1 }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.finalDestinationCategory')}</div>
                            <div className="list__items--text">{finalDestinationCategory}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.address')}</div>
                            <div className="list__items--text">{address ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.zipcode')}</div>
                            <div className="list__items--text">{zipCode ?? ''}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.licenseNumber')}</div>
                            <div className="list__items--text">{environmentalLicenses?.licenseNumber ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                            <div className="list__items--text">{environmentalLicenses?.issuingBody?.toUpperCase() ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">Tipo</div>
                            <div className="list__items--text">
                                {environmentalLicenses
                                    ? translate(`enums.environmentalLicenseType.${environmentalLicenses.environmentalLicenseType}`)
                                    : ''}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 10, offset: 1 }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                            <div className="list__items--text">
                                {environmentalLicenses ? moment(environmentalLicenses.expireDate).format('DD/MM/YYYY') : ''}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const renderTransshipment = (canRemove: boolean, cleanTransshipment, transshipment?: ITransshipmentMtr, mtr?: IMtr) => {
    const name = transshipment?.companyName?.toUpperCase() ?? '';
    const cnpj = transshipment?.cnpj ?? '';
    const address = transshipment?.addressLabel ?? '';
    const zipCode = transshipment?.addressZipcode ?? '';

    const storageLicenses =
        mtr?.transshipmentLicense
            ? mtr.transshipmentLicense
            : transshipment?.storageLicenses?.length > 0
                ? transshipment.storageLicenses[0]
                : null;
    return (
        <div>
            <Row>
                <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.transshipment')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.companyName')}</div>
                            <div className="list__items--text">{name}</div>
                        </div>
                    </div>
                </Col>
                <Col md="5" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.cnpj')}</div>
                            <div className="list__items--text">{cnpj}</div>
                        </div>
                        {canRemove && <div className="list__items--icon" onClick={cleanTransshipment} />}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.address')}</div>
                            <div className="list__items--text">{address ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.zipcode')}</div>
                            <div className="list__items--text">{zipCode ?? ''}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.licenseNumber')}</div>
                            <div className="list__items--text">{storageLicenses?.licenseNumber ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                            <div className="list__items--text">{storageLicenses?.issuingBody?.toUpperCase() ?? ''}</div>
                        </div>
                    </div>
                </Col>
                <Col md="3" style={{ paddingLeft: '2px' }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.type')}</div>
                            <div className="list__items--text">
                                {storageLicenses?.storageLicenseType ?? ''}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 10, offset: 1 }}>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                            <div className="list__items--text">
                                {storageLicenses ? moment(storageLicenses.expireDate).format('DD/MM/YYYY') : ''}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const renderMtrResidue = (canRemove: boolean, cleanResidue, residue?: ISourceResidueMtr) => {
    const code = residue?.code ?? '';
    const name = residue?.specificResidue?.name ?? '';
    const description = residue?.description ?? '';
    const measurementType = translate('enums.measurementTypeAbbreviation.'.concat((residue?.measurementType ?? '')));

    return (
        <div>
            <Row>
                <Col md={{ size: 12 }} style={{ paddingLeft: '38px', paddingRight: '38px' }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.waste')}</div>
                    <div className="list__items--component-fifth">
                        <div>
                            <div className="list__items--title">{translate('mtr.residueDescription')}</div>
                            <div className="list__items--text">{code} - {description}</div>
                        </div>
                        <div>
                            <div className="list__items--title">Unidade</div>
                            <div className="list__items--text">{measurementType}</div>
                        </div>
                        {canRemove && <div className="list__items--icon" onClick={cleanResidue} />}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md="12" style={{ paddingLeft: '36px', paddingRight: '36px' }}>
                    <div className="list__items--component-second green_background">
                        <div className="list__items--title">{translate('mtr.specificResidue')}</div>
                        <div className="list__items--text">{name}</div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

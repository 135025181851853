import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SourceResidue from './source-residue';
import SourceResidueDetail from './source-residue-detail';
import SourceResidueUpdate from './source-residue-update';
import SourceResidueDeleteDialog from './source-residue-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SourceResidueUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SourceResidueUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SourceResidueDetail} />
      <ErrorBoundaryRoute path={match.url} component={SourceResidue} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={SourceResidueDeleteDialog} />
  </>
);

export default Routes;

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getEntities as getFinalDestinations} from 'app/entities/final-destination/final-destination.reducer';
import {getUsers} from 'app/modules/administration/user-management/user-management.reducer';
import {IRootState} from 'app/shared/reducers';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import React from 'react';
import {Translate, translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {createEntity, getEntity, reset, updateEntity} from './user-final-destination.reducer';

export interface IUserFinalDestinationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IUserFinalDestinationUpdateState {
  isNew: boolean;
  userId: string;
  finalDestinationId: string;
}

export class UserFinalDestinationUpdate extends React.Component<IUserFinalDestinationUpdateProps, IUserFinalDestinationUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userId: '0',
      finalDestinationId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUsers();
    this.props.getFinalDestinations();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { userFinalDestinationEntity } = this.props;
      const entity = {
        ...userFinalDestinationEntity,
        ...values,
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/user-final-destination');
  };

  render() {
    const { userFinalDestinationEntity, users, finalDestinations, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="resitrackApp.userFinalDestination.home.createOrEditLabel">
              <Translate contentKey="resitrackApp.userFinalDestination.home.createOrEditLabel">
                Create or edit a UserFinalDestination
              </Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : userFinalDestinationEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="user-final-destination-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="user-final-destination-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="registrationNumberLabel" for="user-final-destination-registrationNumber">
                    <Translate contentKey="resitrackApp.userFinalDestination.registrationNumber">Registration Number</Translate>
                  </Label>
                  <AvField
                    id="user-final-destination-registrationNumber"
                    type="text"
                    name="registrationNumber"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="classCouncilLabel" for="user-final-destination-classCouncil">
                    <Translate contentKey="resitrackApp.userFinalDestination.classCouncil">Class Council</Translate>
                  </Label>
                  <AvInput
                    id="user-final-destination-classCouncil"
                    type="select"
                    className="form-control"
                    name="classCouncil"
                    value={(!isNew && userFinalDestinationEntity.classCouncil) || 'CREA'}
                  >
                    <option value="CREA">{translate('resitrackApp.ClassCouncil.CREA')}</option>
                    <option value="CRQ">{translate('resitrackApp.ClassCouncil.CRQ')}</option>
                    <option value="CRBIO">{translate('resitrackApp.ClassCouncil.CRBIO')}</option>
                    <option value="CAU">{translate('resitrackApp.ClassCouncil.CAU')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="user-final-destination-user">
                    <Translate contentKey="resitrackApp.userFinalDestination.user">User</Translate>
                  </Label>
                  <AvInput
                    id="user-final-destination-user"
                    type="select"
                    className="form-control"
                    name="user.id"
                    value={isNew ? users[0] && users[0].id : userFinalDestinationEntity.user.id}
                    required
                  >
                    {users
                      ? users.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.login}
                          </option>
                        ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="user-final-destination-finalDestination">
                    <Translate contentKey="resitrackApp.userFinalDestination.finalDestination">Final Destination</Translate>
                  </Label>
                  <AvInput id="user-final-destination-finalDestination" type="select" className="form-control" name="finalDestination.id">
                    <option value="" key="0" />
                    {finalDestinations
                      ? finalDestinations.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/user-final-destination" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  finalDestinations: storeState.finalDestination.entities,
  userFinalDestinationEntity: storeState.userFinalDestination.entity,
  loading: storeState.userFinalDestination.loading,
  updating: storeState.userFinalDestination.updating,
  updateSuccess: storeState.userFinalDestination.updateSuccess,
});

const mapDispatchToProps = {
  getUsers,
  getFinalDestinations,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserFinalDestinationUpdate);

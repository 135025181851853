import { Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import AlertModal from 'app/components/alert-modal/alert-modal';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { searchProducers } from 'app/entities/producer/producer.reducer';
import { searchFinalDestinations } from 'app/entities/final-destination/final-destination.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { Pageable } from 'app/shared/model/pageable';
import { IMtr, MtrStatus } from 'app/shared/model/mtr.model';
import { IRootState } from 'app/shared/reducers';
import React, { Fragment } from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './custom-mtr-receipt-create.scss';
import StaticTableBar, { StaticTableBarItem } from 'app/components/residues-components/add/static-table-bar/static-table-bar';
import moment from 'moment';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import { findMtr, reset, resetStatus } from 'app/modules/generic/mtr/mtr-reducer';
import { IProducer } from 'app/shared/model/producer.model';
import { IFinalDestination } from 'app/shared/model/final-destination.model';
import { isEmpty, isEqual } from 'lodash';
import { MtrReport } from 'app/shared/model/mtr-report.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import EmptyList from '../empty-list/empty-list';
import { generateCsvSelectedMtr, generateCsvUnselectedMtr } from 'app/modules/generic/mtr/mtr-reducer';
import { getMtrs } from 'app/modules/generic/mtr/mtr-reducer';

interface ICustomMtrReceiptCreateProps extends StateProps, DispatchProps, RouteComponentProps {
    // TODO
}

interface ICustomMtrReceiptCreateState {
    page: Pageable;
    endDate?: string;
    startDate?: string;
    filterName: string;
    showFilter: boolean;
    isFirstGet: boolean;
    selectedMtrs: IMtr[];
    allSelected: boolean;
    unSelectedMtrs: IMtr[];
    showSuccess: boolean;
    producers: IProducer[];
    showErrorModal: boolean;
    producerOrFinalDestinationText: string;
    finalDestinations: IFinalDestination[];
    hasChangedProducerOrFinalDestination: boolean;
    selectedProducerOrFinalDestination: { id: number | null; name: string };
    searchFilter?: string;
}

class CustomMtrReceiptCreate extends React.Component<ICustomMtrReceiptCreateProps, ICustomMtrReceiptCreateState> {
    timer = null;

    private tableHeaders: StaticTableBarItem[] = [
        { name: translate('mtr-report.mtrList.date'), width: '17%' },
        { name: translate('mtr-report.mtrList.mtrCode'), width: '17%' },
        { name: translate('mtr-report.mtrList.transporter'), width: '17%' },
        { name: translate(`mtr-report.mtrList.${this.props.hasFinalDestinationAuthorities ? 'producer' : 'finalDestination'}`), width: '17%' },
        { name: translate('mtr-report.mtrList.status'), width: '17%' },
        {
            name: translate('mtr-report.mtrList.selectAll'),
            width: '17%',
            color: '#30AB64',
            checkbox: true,
            onClick: event => this.selectAllMtrs(event),
        },
    ];

    constructor(props: Readonly<ICustomMtrReceiptCreateProps>) {
        super(props);
        this.state = {
            producers: [],
            filterName: '',
            selectedMtrs: [],
            unSelectedMtrs: [],
            allSelected: false,
            showFilter: false,
            isFirstGet: false,
            showSuccess: false,
            showErrorModal: false,
            finalDestinations: [],
            producerOrFinalDestinationText: '',
            hasChangedProducerOrFinalDestination: false,
            selectedProducerOrFinalDestination: { id: null, name: '' },
            page: { totalElements: 0, size: 20, totalPages: 0, page: 0 },
            searchFilter: '',
        };
    }

    componentDidMount() {
        this.resetAll();
        this.getMtrs();
        this.getAllProducersOrFinalDestinations();
    }

    getMtrs = () => {
        const { page } = this.state;
        this.props.getMtrs(page.page, page.size, page.sort, {
            numberMtr: this.state.filterName,
            searchText: this.state.searchFilter,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        });
    };

    componentWillUnmount() {
        this.resetAll();
    }

    componentWillReceiveProps(newProps: Readonly<ICustomMtrReceiptCreateProps>) {
        if (newProps.totalItems !== this.props.totalItems) {
            this.setState({
                page: {
                    ...this.state.page,
                    totalElements: newProps.totalItems,
                },
            });
        }

        if (!isEqual(newProps.producers, this.state.producers) && newProps.producers.length !== 0) {
            // @ts-ignore
            this.setState({ producers: newProps.producers }, async () => {
                if (!this.state.isFirstGet) {
                    this.isFirstGet(true);
                    this.getMtrs();
                }
            });
        }

        if (!isEqual(newProps.finalDestinations, this.state.finalDestinations) && newProps.finalDestinations.length !== 0) {
            // @ts-ignore
            this.setState({ finalDestinations: newProps.finalDestinations }, async () => {
                if (!this.state.isFirstGet) {
                    this.isFirstGet(true);
                    this.getMtrs();
                }
            });
        }

        if (newProps.createMtrReportStatus === HttpRequestStatus.SUCCESS) {
            this.toggleSuccessModal();
        }

        if (newProps.createMtrReportStatus === HttpRequestStatus.ERROR) {
            this.toggleErrorModal();
        }
    }

    resetAll = () => {
        this.props.reset();
    };

    isFirstGet = (isFirstGet: boolean) => {
        this.setState({
            isFirstGet,
        });
    };

    isSelectedProducerOrFinalDestinationEmpty = () => {
        const { selectedProducerOrFinalDestination } = this.state;
        return selectedProducerOrFinalDestination.id == null;
    };

    setSelectedProducerOrFinalDestination = async (selectedProducerOrFinalDestination: IProducer | IFinalDestination) => {
        if (selectedProducerOrFinalDestination.id === this.state.selectedProducerOrFinalDestination.id) return;
        this.setState({
            hasChangedProducerOrFinalDestination: true,
            selectedProducerOrFinalDestination: {
                id: selectedProducerOrFinalDestination.id,
                name: selectedProducerOrFinalDestination['name'] || selectedProducerOrFinalDestination?.legalPerson?.companyName || '',
            },
        });
    };

    hasChangedProducerOrFinalDestination = (hasChangedProducerOrFinalDestination: boolean) => {
        this.setState({
            hasChangedProducerOrFinalDestination,
        });
    };

    showFilter = (showFilter: boolean) => {
        this.setState(
            {
                showFilter,
            },
            () => this.getAllProducersOrFinalDestinations()
        );
    };

    selectAllMtrs = (event: boolean) => {
        if (this.state.allSelected) {
            this.setState({ unSelectedMtrs: [] });
        }

        this.setState({ allSelected: !this.state.allSelected });
    };

    selectedMtr = (mtr: IMtr) => {
        const selectedMtrs = [...this.state.selectedMtrs];
        const selectedMtr = selectedMtrs.filter(it => it.id === mtr.id)[0];
        const { allSelected } = this.state;
        const unSelectedMtrs = [...this.state.unSelectedMtrs];
        const unSelectedMtr = unSelectedMtrs.filter(it => it.id === mtr.id)[0];

        if (allSelected) {
            if (!unSelectedMtr) {
                unSelectedMtrs.push(mtr);
            } else {
                unSelectedMtrs.splice(unSelectedMtrs.indexOf(unSelectedMtr), 1);
            }

            this.setState({ unSelectedMtrs });
        } else {
            if (selectedMtr != null) {
                selectedMtrs.splice(selectedMtrs.indexOf(selectedMtr), 1);
            } else {
                selectedMtrs.push(mtr);
            }
            this.setState({
                selectedMtrs,
            });
        }
    };

    checkedFunc = (content?: IMtr): boolean => {
        const { allSelected, unSelectedMtrs, selectedMtrs } = this.state;
        let checked = false;

        if (allSelected) {
            if (unSelectedMtrs.filter(mtr => mtr.id === content.id).length > 0) {
                checked = false;
            } else {
                checked = true;
            }
        } else {
            if (selectedMtrs.filter(mtr => mtr.id === content.id).length > 0) {
                checked = true;
            } else {
                checked = false;
            }
        }

        return checked;
    };

    handleTransformToTableContent = (content?: IMtr): (string | JSX.Element)[][] => {
        const { hasFinalDestinationAuthorities } = this.props;

        const result: (string | JSX.Element)[][] = [];

        const checked = this.checkedFunc(content);

        result.push([
            content.collectionDate != null ? moment(content.collectionDate).format(APP_LOCAL_DATE_FORMAT) : '',
            content.numberMtr || '',
            content.transporter != null ? content.transporter.legalPerson?.companyName || content.transporter.naturalPerson?.name : '',
            hasFinalDestinationAuthorities ? content.producer?.companyName || '' : content.finalDestination?.legalPerson?.companyName || '',
            this.getMtrStatusStyle(content.status),
            <Checkbox
                icon={<img src={'content/images/icons/check-gray.svg'} alt="" />}
                checkedIcon={<img src={'content/images/icons/check-green.svg'} alt="" />}
                checked={checked}
            />,
        ]);

        return result;
    };

    onCreateMtr = () => {
        this.props.history.push(`${this.props.match.url}/new`);
    };

    onHandleFilterChanged = (searchFilter: string) => {
        this.setState(
            {
                searchFilter,
                page: {
                    ...this.state.page,
                    page: 0,
                },
            },
            () => this.getMtrs()
        );
    };

    onMtrClick = (index: number) => {
        const { mtrs } = this.props;
        const mtr = mtrs[index];
        this.selectedMtr(mtr);
    };

    onFinishAdding = () => {
        const { generateCsvUnselectedMtr, generateCsvSelectedMtr } = this.props;
        const { unSelectedMtrs, selectedMtrs, allSelected } = this.state;

        if (allSelected) {
            const mtrReport: MtrReport = { mtrs: unSelectedMtrs };
            generateCsvUnselectedMtr(mtrReport, {
                numberMtr: this.state.filterName,
                searchText: this.state.searchFilter,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            });
        } else {
            const mtrReport: MtrReport = { mtrs: selectedMtrs };
            generateCsvSelectedMtr(mtrReport);
        }
    };

    onBackHandler = () => {
        this.props.history.goBack();
    };

    toggleErrorModal = () => {
        this.setState({
            showErrorModal: !this.state.showErrorModal,
        });
    };

    toggleSuccessModal = () => {
        this.setState({
            showSuccess: !this.state.showSuccess,
        });
    };

    cleanFilter = () => {
        this.setState(
            {
                searchFilter: '',
                endDate: undefined,
                startDate: undefined,
            },
            () => this.getMtrs()
        );
    };

    handleChangePage = (newPage: number) => {
        this.setState(
            {
                page: {
                    ...this.state.page,
                    page: newPage,
                },
            },
            () => this.getMtrs()
        );
    };

    getMtrStatusStyle = (mtrStatus: MtrStatus) => {
        if (mtrStatus == null) {
            return <div />;
        }
        let style = 'status-tag ';
        switch (mtrStatus) {
            case MtrStatus.COMPLETE:
                style = style.concat('complete');
                break;
            case MtrStatus.INCOMPLETE:
                style = style.concat('incomplete');
                break;
            case MtrStatus.CANCELLED:
                style = style.concat('cancelled');
                break;
            case MtrStatus.PENDING:
                style = style.concat('pending');
                break;
        }
        return (
            <div className={style}>
                <span>{translate(`mtr-list.status.${mtrStatus}`).toUpperCase()}</span>
            </div>
        );
    };

    getAllProducersOrFinalDestinations = (searchText?: string) => {
        const { mtrs, hasFinalDestinationAuthorities } = this.props;
        const { selectedProducerOrFinalDestination } = this.state;
        if (mtrs.length > 0 && selectedProducerOrFinalDestination.id == null) {
            const typeAuthority = hasFinalDestinationAuthorities ? 'producers' : 'finalDestinations';
            let objects = mtrs
                .map(({ finalDestination, producer }) => (hasFinalDestinationAuthorities ? producer : finalDestination))
                .filter((item, index, array) =>
                    searchText
                        ? item?.companyName.toLowerCase().includes(searchText.toLowerCase())
                        : true &&
                        index === array.findIndex(t => t?.companyName === item?.companyName || t?.id === item?.id) &&
                        item?.companyName != null
                );
            // @ts-ignore
            this.setState({ [`${typeAuthority}`]: objects });
        }
    };

    mapAllProducersOrFinalDestinations = () => {
        const { producers, finalDestinations } = this.state;
        const { hasFinalDestinationAuthorities } = this.props;
        // @ts-ignore
        const items: (IProducer | IFinalDestination)[] = hasFinalDestinationAuthorities ? producers : finalDestinations;
        const objects = items
            ?.map(item => ({ name: item?.legalPerson?.companyName, id: item?.id }))
            .filter((item, index, array) => index === array.findIndex(t => t?.name === item?.name || t?.id === item?.id));
        return objects || [];
    };

    handleFilter = () => {
        this.setState(
            {
                page: {
                    ...this.state.page,
                    page: 0,
                },
            },
            () => this.getMtrs()
        );
    };

    renderRowItem = (info: string | JSX.Element, index: number) => (
        <div className="add-mtrs-list__item" style={{ width: this.tableHeaders[index].width }}>
            {info}
        </div>
    );

    renderRow = (mtr: IMtr) => {
        const row = this.handleTransformToTableContent(mtr);
        return (
            <div className="add-mtrs-list__row" onClick={() => this.selectedMtr(mtr)}>
                {this.renderRowItem(row[0][0], 0)}
                {this.renderRowItem(row[0][1], 1)}
                {this.renderRowItem(row[0][2], 2)}
                {this.renderRowItem(row[0][3], 3)}
                {this.renderRowItem(row[0][4], 4)}
                {this.renderRowItem(row[0][5], 5)}
            </div>
        );
    };

    renderMtrFilter = () => {
        // @ts-ignore
        return (
            <div className="mtr-list__filter-open" style={{ marginBottom: '0', paddingTop: '0' }}>
                <div className="sessao_1">
                    <div className="mtr-list__filter-open--title">{translate('mtr-list.filter-open.title-first')}</div>
                    <div className="mtr-list__filter-open--items">
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={value => this.setState({ startDate: value })}
                            dateSelected={this.state.startDate}
                            placeholder={translate('mtr-list.filter-open.if')}
                        />
                        <DatePickerComponent
                            dateFormat={APP_LOCAL_DATE_FORMAT}
                            onDateChange={value => this.setState({ endDate: value })}
                            dateSelected={this.state.endDate}
                            placeholder={translate('mtr-list.filter-open.until')}
                        />
                        <div className="mtr-list__filter-open--button" onClick={() => this.getMtrs()}>
                            <span>{translate('mtr-list.filter-open.button')}</span>
                            <div className="mtr-list__filter-open--img" />
                        </div>
                        <div className="mtr-list__filter-open--clear-filter" onClick={() => this.cleanFilter()}>
                            {translate('mtr-list.filter-open.clear-filter')}
                        </div>
                    </div>
                </div>
                <div className="mtr-list__filter-open--close" onClick={() => this.setState({ showFilter: false })} />
            </div>
        );
    };

    renderAddPage = () => {
        const { mtrs, createMtrReportStatus } = this.props;
        const { showErrorModal, showSuccess, showFilter, selectedMtrs } = this.state;
        return (
            <Fragment>
                <Container>
                    <div>
                        <h1 style={{ marginTop: '28px', marginBottom: '5px', fontWeight: 600 }}>{translate('mtr-receipt.requestMtrReceipt')}</h1>
                        <div className={'custom-mtr-create'}>
                            <p style={{ marginBottom: '0px', fontSize: '18px' }}>{translate('mtr-receipt.selectMtr')}</p>
                        </div>
                        <div className={'custom-mtr-create'}>
                            <p style={{ marginBottom: '15px', fontSize: '15px', color: 'red' }}>{translate('mtr-receipt.tips')}</p>
                        </div>
                    </div>
                    <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <Col md={{ size: '6', offset: '3' }}>
                            <SimpleSearchField
                                value={this.state.searchFilter}
                                placeholder={translate('mtr-receipt.searchMtr')}
                                onChange={this.onHandleFilterChanged}
                            />
                        </Col>
                        <div className={'mtr-list__items'}>
                            <div className="mtr-list__items--filter" onClick={() => this.showFilter(!showFilter)}>
                                <div className="mtr-list__items--filter-img" />
                                <span>{translate('mtr-list.filter')}</span>
                            </div>
                        </div>
                    </Row>
                    {showFilter && <Row style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>{this.renderMtrFilter()}</Row>}
                    <Row>
                        <Col>
                            <StaticTableBar staticItems={this.tableHeaders} />
                            <div className={'add-mtrs-list__container'}>
                                <div className="add-mtrs-list-item__container">{!isEmpty(mtrs) ? mtrs.map(mtr => this.renderRow(mtr)) : <EmptyList />}</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '40px' }} />
                        </Col>
                    </Row>
                </Container>
                <div className="mtr-add-mtrs__items--modal">
                    <Container>
                        <div className="mtr-add-mtrs__items--modal-buttons">
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col md="5">
                                    <Pagination
                                        color="primary"
                                        page={this.state.page.page + 1}
                                        count={Math.ceil(this.state.page.totalElements / this.state.page.size)}
                                        onChange={(e, page) => this.handleChangePage(page - 1)}
                                    />
                                </Col>
                                <Col md={{ size: 2, offset: 2 }}>
                                    <Button className={'button third'} type={'submit'} onClick={this.onBackHandler}>
                                        <Translate contentKey={'entity.action.cancel'} />
                                    </Button>
                                </Col>
                                <Col md="2">
                                    <Button
                                        onClick={ }
                                        className={'button fourth button-fourth'}
                                        disabled={this.state.allSelected ? false : isEmpty(selectedMtrs) || createMtrReportStatus === HttpRequestStatus.ONGOING}
                                    >
                                        <Translate contentKey={'mtr-receipt.receipt'} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <AlertModal
                    hasOneButton
                    showModal={showSuccess}
                    statusImage="garbage.svg"
                    alertMessage="mtr-report.success"
                    buttonTwoAction={this.onBackHandler}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                />
                <AlertModal
                    hasOneButton
                    alertMessage="mtr-report.error"
                    showModal={showErrorModal}
                    statusImage="bag-error.svg"
                    buttonTwoAction={this.toggleErrorModal}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                />
            </Fragment>
        );
    };

    render() {
        return <div className={'mtr-add-mtrs__items'}>{this.renderAddPage()}</div>;
    }
}

const mapStateToProps = (root: IRootState) => ({
    mtrs: root.mtr.mtrs,
    totalItems: root.mtr.totalItems,
    producers: root.producer.producers,
    createMtrReportStatus: root.mtr.createMtrReportStatus,
    finalDestinations: root.finalDestination.finalDestinations,
    hasProducerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSSHIPMENT_ADMIN,
        AUTHORITIES.TRANSSHIPMENT_USER,
    ]),
    hasFinalDestinationAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.FINAL_DESTINATION_ADMIN,
        AUTHORITIES.FINAL_DESTINATION_USER,
    ]),
});

const mapDispatchToProps = {
    reset,
    findMtr,
    generateCsvSelectedMtr,
    generateCsvUnselectedMtr,
    resetStatus,
    searchProducers,
    searchFinalDestinations,
    getMtrs,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(CustomMtrReceiptCreate);

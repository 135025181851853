import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import {Switch} from 'react-router-dom';
import UserTransporter from './user-transporter';
import UserTransporterDeleteDialog from './user-transporter-delete-dialog';
import UserTransporterDetail from './user-transporter-detail';
import UserTransporterUpdate from './user-transporter-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserTransporterUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserTransporterUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserTransporterDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserTransporter} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={UserTransporterDeleteDialog} />
  </>
);

export default Routes;

import { ICnaeCode } from 'app/shared/model/cnae-code.model';
import { IAddress } from 'app/shared/model/address.model';
import { ITransportLicense } from 'app/shared/model/transport-license.model';
import { ILegalPerson } from './legal-person.model';
import { INaturalPerson } from './natural-person.model';

export enum PersonType {
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
}

export interface ITransporter {
  id?: number;
  legalPerson?: ILegalPerson;
  naturalPerson?: INaturalPerson;
  personType?: PersonType;
  cnaeCodes?: ICnaeCode[];
  address?: IAddress;
  transportLicenses?: ITransportLicense[];
}

export interface ITransporterMtr {
  id?: number;
  name?: string;
  personType?: PersonType;
  address?: IAddress;
  transportLicenses?: ITransportLicense[];
  addressLabel?: string;
  addressZipcode?: string;
  parUf?: string;
}

export function mapTransportToSearch(transporters) {
  const objects = transporters.map(it => {
    return { name: it.companyName ?? it.name ?? 'N/D', id: it.id };
  });
  return objects.sort((a, b) => a.name.localeCompare(b.name));
}

export const defaultValue: Readonly<ITransporter> = {};

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './user-integration.scss';
import SimpleOrderedTable, { ColumnSort } from 'app/components/simple-ordered-table/simple-ordered-table';
import { Pageable } from 'app/shared/model/pageable';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { deleteUser, reset, getUsersByEntity } from '../profile/profile-reducer';
import { getIntegrationUsers, deleteIntegrationUser } from './user-integration.reducer';
import { IUser } from 'app/shared/model/user.model';
import { maskCnpjOrCpf, maskCPF, maskPhone } from 'app/shared/util/mask-utils';
import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { Col, Row } from 'reactstrap';
import AlertModal from 'app/components/alert-modal/alert-modal';
import { IIntegrationUser } from 'app/shared/model/integration-user.model';

export interface IUserIntegrationProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IUserIntegrationState {
  page: Pageable;
  showDeleteModal: boolean;
  deleteItemId: number;
}

export class UserIntegration extends React.Component<IUserIntegrationProps, IUserIntegrationState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: "CNPJ/CPF Acesso", sortCode: 'firstLogin' },
    { name: "Unidade", sortCode: 'unit' },
    { name: "Tipo", sortCode: 'type' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IUserIntegrationProps>) {
    super(props);
    this.state = {
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      showDeleteModal: false,
      deleteItemId: null,
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getAllIntegrationUsers();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS && newProps.deleteStatus !== this.props.deleteStatus) {
      this.closeDeleteModal();
      const { page } = this.state;
      this.props.getIntegrationUsers(page.page, page.size, page.sort);
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getAllIntegrationUsers = () => {
    const { page } = this.state;
    this.props.getIntegrationUsers(page.page, page.size, page.sort);
  };

  private handleTransformToTableContent = (content?: IIntegrationUser[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IIntegrationUser) => {

      const unitCode = item.unit?.unitCode ? item.unit?.unitCode : '';
      const unitName = item.unit?.fantasyName ? item.unit?.fantasyName : '';
      const combinedUnitInfo = unitCode && unitName ? `${unitCode} - ${unitName}` : unitCode || unitName || '';

      result.push([
        item.firstLogin != null ? maskCnpjOrCpf(item.firstLogin) : '',
        combinedUnitInfo,
        item.type != null ? item.type : '',
        <DeleteEditButton id={item.id} onDelete={this.onDeleteUserModal} onEdit={this.onEditUser} />,
      ]);
    });

    return result;
  };

  onCreateUser = () => {
    this.props.history.push(`/me/user-integration/new`);
  };

  onEditUser = (id: number) => {
    this.props.history.push({
      pathname: `/me/user-integration/edit`,
      state: { id: id },
    });
  };

  onUserClick = (index: number) => {
    const { integrationUsers } = this.props;
    const integrationUser = integrationUsers[index];
    this.props.history.push({
      pathname: `/me/user-integration/details`,
      state: { id: integrationUser.id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getAllIntegrationUsers()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getAllIntegrationUsers()
    );
  };

  chooseDelete = () => {
    this.props.deleteIntegrationUser(this.state.deleteItemId);
  };

  onDeleteUserModal = (id: number) => {
    this.setState({
      showDeleteModal: true,
      deleteItemId: id,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      deleteItemId: null,
    });
  };

  render() {
    const { integrationUsers } = this.props;
    const { showDeleteModal } = this.state;

    const rows = this.handleTransformToTableContent(integrationUsers);
    return (
      <div>
        <div className="manage-users__items--title">
          <Translate contentKey={'profile.personal-data.title'} />
          <button style={{ width: '218px', padding: '0 15px', margin: '0' }} className={'button fourth'} type={'submit'} onClick={this.onCreateUser}>
            <Translate contentKey={'profile.manage-users.button'} />
          </button>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onUserClick}
              />
            </Col>
          </Row>
        </div>

        <AlertModal
          showModal={showDeleteModal}
          buttonOneActionMessage={'mtr-signature.button-no'}
          buttonTwoActionMessage={'mtr-signature.button-yes'}
          hasTwoButtons
          buttonTwoAction={this.chooseDelete}
          buttonOneAction={this.closeDeleteModal}
          statusImage="money-bag.svg"
          alertMessage="alert.userDelete"
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchSignatureStatus: root.integrationUser.fetchIntegrationUsersStatus,
  deleteStatus: root.integrationUser.deleteIntegrationUserStatus,
  integrationUsers: root.integrationUser.integrationUsers,
  totalItems: root.integrationUser.totalItems,
});

const mapDispatchToProps = {
  getSession,
  reset,
  getIntegrationUsers,
  deleteIntegrationUser
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserIntegration);

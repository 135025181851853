import React from 'react';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './mtr-waste.scss';
import TableList from '../waste/table-list';
import Search from '../waste/search';

export const MtrWaste = () => {
  return (
    <div className="mtr-waste__items">
      <Container>
        <Row>
          <Col style={{ margin: 'auto' }} md="12">
            <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight:'600' }}>{translate('mtr-unity.title')}</h1>
          </Col>
        </Row>
        <Row style={{ marginBottom: '27px'}}>
          <Col md="6">
            <Search />
          </Col>
          <Col>
            <Button className={'button primary'} type={'submit'}>
              <Translate contentKey={'mtr-unity.button'} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
          <div className="mtr-unity__items--matrix">
            <div className="mtr-unity__items--text">{translate('mtr-unity.matrix')}</div>
            <div className="mtr-unity__items--img"/>
          </div>
            <TableList />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MtrWaste;

import React, { HTMLAttributes } from 'react';
import { Translate } from 'react-jhipster';
import './add-credit-card.scss';

interface Props extends HTMLAttributes<HTMLButtonElement> {
    handleAddCard: () => void;
}

const AddCreditCard = ({ handleAddCard, ...rest }: Props) => {
    return (
        <button className='add-credit-card-container' onClick={handleAddCard} {...rest}>
            <div className='add-credit-card-container--icon' />
            <Translate contentKey={'register.select-payment.addCreditCard'} />
        </button>
    );
};

export default AddCreditCard;

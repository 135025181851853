import './search.scss';
import React from 'react';


const SearchPage = (onHandleFilterChanged) => {
  return (
    <div className="mtr-list__search">
      <div className="form-inline md-form">
        <input className="mtr-list__search--form" type="text" placeholder="Buscar MTR/CTR" aria-label="Search" icon="search" onChange={onHandleFilterChanged} />
        <div className="mtr-list__search--icon" />
      </div>
    </div>
  );
};

export default SearchPage;

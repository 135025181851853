import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import './manage-users.scss';
import SimpleOrderedTable, { ColumnSort } from 'app/components/simple-ordered-table/simple-ordered-table';
import { Pageable } from 'app/shared/model/pageable';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { deleteUser, getUsersByEntity, reset } from '../profile-reducer';
import { IUser } from 'app/shared/model/user.model';
import { maskCPF, maskPhone } from 'app/shared/util/mask-utils';
import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { Col, Row } from 'reactstrap';
import AlertModal from 'app/components/alert-modal/alert-modal';

export interface IManagerUsersProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IManagerUsersState {
  page: Pageable;
  showDeleteModal: boolean;
  deleteItemId: number;
}

export class ManagerUsers extends React.Component<IManagerUsersProps, IManagerUsersState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('user.headers.name'), sortCode: 'name' },
    { name: translate('user.headers.email'), sortCode: 'email' },
    { name: translate('user.headers.cpf'), sortCode: 'cpf' },
    { name: translate('user.headers.phone'), sortCode: 'phone' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IManagerUsersProps>) {
    super(props);
    this.state = {
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
      showDeleteModal: false,
      deleteItemId: null,
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getUsersByEntity();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS && newProps.deleteStatus !== this.props.deleteStatus) {
      this.closeDeleteModal();
      this.props.getUsersByEntity();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getUsersByEntity = () => {
    const { page } = this.state;
    this.props.getUsersByEntity(page.page, page.size, page.sort);
  };

  private handleTransformToTableContent = (content?: IUser[]): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.map((item: IUser) => {
      result.push([
        item.name != null ? item.name : '',
        item.email ? item.email : '',
        item.cpf != null ? maskCPF(item.cpf) : '',
        item.phone != null ? maskPhone(item.phone) : '',
        <DeleteEditButton id={item.id} onDelete={this.onDeleteUserModal} onEdit={this.onEditUser} />,
      ]);
    });

    return result;
  };

  onDeleteUser = (id: number) => {
    this.props.deleteUser(id);
  };

  onCreateUser = () => {
    this.props.history.push(`/me/user-manage/new`);
  };

  onEditUser = (id: number) => {
    this.props.history.push({
      pathname: `/me/user-manage/edit`,
      state: { id: id },
    });
  };

  onUserClick = (index: number) => {
    const { users } = this.props;
    const user = users[index];
    this.props.history.push({
      pathname: `/me/user-manage/details`,
      state: { id: user.id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getUsersByEntity()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getUsersByEntity()
    );
  };

  chooseDelete = () => {
    this.props.deleteUser(this.state.deleteItemId);
  };

  onDeleteUserModal = (id: number) => {
    this.setState({
      showDeleteModal: true,
      deleteItemId: id,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      deleteItemId: null,
    });
  };

  render() {
    const { users } = this.props;
    const { showDeleteModal } = this.state;
    const rows = this.handleTransformToTableContent(users);
    return (
      <div>
        <div className="manage-users__items--title">
          <Translate contentKey={'profile.manage-users.title'} />
          <button style={{ width:'218px', padding:'0 15px', margin:'0'}} className={'button fourth'} type={'submit'} onClick={this.onCreateUser}>
            <Translate contentKey={'profile.manage-users.button'} />
          </button>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onUserClick}
              />
            </Col>
          </Row>
        </div>

        <AlertModal
          showModal={showDeleteModal}
          buttonOneActionMessage={'mtr-signature.button-no'}
          buttonTwoActionMessage={'mtr-signature.button-yes'}
          hasTwoButtons
          buttonTwoAction={this.chooseDelete}
          buttonOneAction={this.closeDeleteModal}
          statusImage="money-bag.svg"
          alertMessage="alert.userDelete"
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  fetchSignatureStatus: root.profile.fetchSignatureStatus,
  deleteStatus: root.profile.deleteUserStatus,
  users: root.profile.users,
  totalItems: root.profile.totalItems,
});

const mapDispatchToProps = {
  getSession,
  getUsersByEntity,
  deleteUser,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManagerUsers);

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Authorization from '../generic/authorization/index';
import FinalDestinationDetails from '../generic/details/final-destination/final-destination-details';
import ProducerDetails from '../generic/details/producer/producer-details';
import FavoriteFinalDestinationList
  from '../generic/favorite-list/favorite-final-destination/favorite-final-destination-list';
import FavoriteProducerList from '../generic/favorite-list/favorite-producer/favorite-producer-list';
import GenericInvite from '../generic/invite/generic-invite';
import MtrManage from '../generic/mtr/manage/mtr-manage';
import MtrList from '../generic/mtr/mtr-list';
import MtrSignatureStep from '../generic/mtr/signature/mtr-signature';
import TransporterFavoriteSummary from './favorite/transporter-favorite-summary';
import TransporterLicenseDetails from './licenses/detail/transporter-license-details';
import TransporterLicenseEdit from './licenses/edit/transporter-license-edit';
import TransporterLicensesList from './licenses/transporter-licenses';
import TransporterResidues from './residues/transporter-residues';
import TransporterResiduesAdd from './residues/transporter-residues-add';
import DriverDetails from './transport/driver/details/driver-details';
import DriverEdit from './transport/driver/edit/driver-edit';
import DriverList from './transport/driver/list/driver-list';
import VehicleDetails from './transport/vehicle/details/vehicle-details';
import VehicleEdit from './transport/vehicle/edit/vehicle-edit';
import VehicleList from './transport/vehicle/list/vehicle-list';
import FavoriteTransshipmentList
  from 'app/modules/generic/favorite-list/favorite-transshipment/favorite-transshipment-list';
import TransshipmentDetails from 'app/modules/generic/details/transshipment/transshipment-details';
import CustomMtrReportCreate from 'app/components/custom-mtr-report-create/custom-mtr-report-create';
import MtrModelRoutes from '../generic/mtr-model/index';
import CustomMtrSignatureAll from 'app/components/custom-mtr-signature-all/custom-mtr-signature-all';

const Routes = ({ match }) => (
  <>
    <Switch>
      <Route exact path={`${match.url}/mtr/manage`} component={MtrManage} />
      <Route path={`${match.url}/mtr/manage/signature`} component={MtrSignatureStep} />
      <Route exact path={`${match.url}/mtr`} component={MtrList} />
      <Route path={`${match.url}/mtr-model`} component={MtrModelRoutes} />
      <Route path={`${match.url}/mtr-report/create`} component={CustomMtrReportCreate} />
      <Route path={`${match.url}/mtr-signature/all`} component={CustomMtrSignatureAll} />
      <Route exact path={`${match.url}/transport/drivers`} component={DriverList} />
      <Route path={`${match.url}/transport/drivers/new`} component={DriverEdit} />
      <Route path={`${match.url}/transport/drivers/edit`} component={DriverEdit} />
      <Route path={`${match.url}/transport/drivers/details`} component={DriverDetails} />
      <Route exact path={`${match.url}/transport/vehicles`} component={VehicleList} />
      <Route path={`${match.url}/transport/vehicles/new`} component={VehicleEdit} />
      <Route path={`${match.url}/transport/vehicles/edit`} component={VehicleEdit} />
      <Route path={`${match.url}/transport/vehicles/details`} component={VehicleDetails} />
      <Route exact path={`${match.url}/licenses`} component={TransporterLicensesList} />
      <Route path={`${match.url}/licenses/edit`} component={TransporterLicenseEdit} />
      <Route path={`${match.url}/licenses/new`} component={TransporterLicenseEdit} />
      <Route path={`${match.url}/licenses/details`} component={TransporterLicenseDetails} />
      <Route exact path={`${match.url}/residues`} component={TransporterResidues} />
      <Route path={`${match.url}/residues/add`} component={TransporterResiduesAdd} />
      <Route path={`${match.url}/authorizations`} component={Authorization} />
      <Route exact path={`${match.url}/summary`} component={TransporterFavoriteSummary} />
      <Route exact path={`${match.url}/summary/producers`} component={FavoriteProducerList} />
      <Route exact path={`${match.url}/summary/final-destinations`} component={FavoriteFinalDestinationList} />
      <Route exact path={`${match.url}/summary/transshipments`} component={FavoriteTransshipmentList} />
      <Route exact path={`${match.url}/summary/final-destinations/detail`} component={FinalDestinationDetails} />
      <Route path={`${match.url}/summary/producers/detail`} component={ProducerDetails} />
      <Route path={`${match.url}/summary/transshipments/detail`} component={TransshipmentDetails} />
      <Route path={`${match.url}/invite`} component={GenericInvite} />
    </Switch>
  </>
);

export default Routes;

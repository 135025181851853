import { IRootState } from 'app/shared/reducers';
import { maskCPF, maskPhone } from 'app/shared/util/mask-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import './producer-details.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { getEntityByProducer as getProducer, reset, } from 'app/entities/user-producer/user-producer.reducer';
import Loading from 'app/components/loading/loading';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import moment from 'moment';
import { APP_ONLY_DATE_FORMAT } from 'app/config/constants';
import { formatToCEP, formatToCNPJ } from 'brazilian-values';
import SimpleFileComponent from 'app/components/simple-file-component/simple-file-component';

export interface IProducerDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IProducerDetailsState { }

interface DetailComponent {
  title: string;
  info: string;
}

export class ProducerDetails extends React.Component<IProducerDetailsProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.reset();
    if (id) {
      this.props.getProducer(id);
    }
  }

  renderComponent = (title: string, info: string) => (
    <div className="list__items--component">
      <div className="list__items--title">{title}</div>
      <div className="list__items--text">{info}</div>
    </div>
  );

  renderComponentSeries = (details: DetailComponent[]) => (
    <Row className="no-margin">
      {details &&
        details.map((item, index) => (
          <Col className={`no-padding ${index < details.length - 1 ? 'marginR1' : ''}`}>{this.renderComponent(item.title, item.info)}</Col>
        ))}
    </Row>
  );

  createDetailComponent = (title: string, info: string) => {
    return {
      title,
      info,
    };
  };

  renderTitle = (title: string) => {
    return <div className="list__items--title-second">{title}</div>;
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, userProducer } = this.props;
    if (loading) {
      return <Loading />;
    }
    const environmentalLicenses = userProducer.producer
      ? userProducer.producer.environmentalLicenses
        ? userProducer.producer.environmentalLicenses
        : null
      : null;
    return (
      <div className="list__items">
        <BackHeaderComponent onBack={this.handleGoBack} />
        <div>
          <h1 style={{ fontWeight: 550 }}>
            {userProducer.producer ? userProducer.producer?.naturalPerson?.name ?? userProducer.producer?.legalPerson?.companyName : ''}
          </h1>
          <Row>
            <Col md="6" style={{ margin: 'auto' }}>
              {this.renderTitle(translate('detailsInfo.companyInfo.title'))}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.companyInfo.companyName'),
                  userProducer.producer ? userProducer.producer?.naturalPerson?.name ?? userProducer.producer?.legalPerson?.companyName : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.companyInfo.cnpj'),
                  userProducer.producer?.legalPerson?.cnpj ? formatToCNPJ(userProducer.producer?.legalPerson?.cnpj) : maskCPF(userProducer.producer?.naturalPerson?.cpf)
                ),
              ])}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.licenseNumber'),
                  environmentalLicenses && environmentalLicenses[0] ? environmentalLicenses[0].licenseNumber : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.issuingBody'),
                  environmentalLicenses && environmentalLicenses[0] ? environmentalLicenses[0].issuingBody : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.licenseInfo.licenseType'),
                  environmentalLicenses && environmentalLicenses[0]
                    ? translate(`enums.environmentalLicenseType.${environmentalLicenses[0].environmentalLicenseType}`)
                    : ''
                ),
              ])}
              {this.renderComponent(
                translate('detailsInfo.licenseInfo.expirationDate'),
                environmentalLicenses && environmentalLicenses[0] ? moment(environmentalLicenses[0].expireDate).format(APP_ONLY_DATE_FORMAT) : ''
              )}
              {this.renderTitle(translate('detailsInfo.addressInfo.title'))}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.address'),
                  userProducer.producer ? userProducer.producer.address.street : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.address'),
                  userProducer.producer ? userProducer.producer.address.complement : ''
                ),
              ])}
              {this.renderComponentSeries([
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.city'),
                  userProducer.producer ? userProducer.producer.address.city.name : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.state'),
                  userProducer.producer ? userProducer.producer.address.city.state.name : ''
                ),
                this.createDetailComponent(
                  translate('detailsInfo.addressInfo.zipcode'),
                  userProducer.producer ? formatToCEP(userProducer.producer.address.zipcode) : ''
                ),
              ])}
              {this.renderTitle(translate('detailsInfo.responsible.title'))}
              {this.renderComponent(
                translate('detailsInfo.responsible.name'),
                userProducer.user ? userProducer.user.name : ''
              )}
              {this.renderComponent(
                translate('detailsInfo.responsible.email'),
                userProducer.user ? userProducer.user.email : ''
              )}
              {this.renderComponent(
                translate('detailsInfo.responsible.phone'),
                userProducer.user ? maskPhone(userProducer.user.phone) : ''
              )}
              {this.renderTitle(translate('detailsInfo.documentation.title'))}
              <div className="list__items--component-second" style={{ marginBottom: '95px' }}>
                <div className="list__items--title">{translate('detailsInfo.documentation.environmentalLicense')}</div>
                <SimpleFileComponent file={environmentalLicenses && environmentalLicenses[0] ? environmentalLicenses[0].licenseDocument : null} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  loading: root.userProducer.loading,
  userProducer: root.userProducer.entity,
});

const mapDispatchToProps = {
  getProducer,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProducerDetails);

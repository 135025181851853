import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { defaultValue, ISignature } from 'app/shared/model/signature.model';
import { defaultValue as defaultUser, IUser } from 'app/shared/model/user.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { organizeSearchParameters } from 'app/shared/util/resitrack-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

interface ProfilesObjs {
  id: number;
  active: boolean;
}

interface Profiles {
  userFinalDestination?: ProfilesObjs;
  userProducer?: ProfilesObjs;
  userManager?: ProfilesObjs;
  userTransshipment?: ProfilesObjs;
  userTransporter?: ProfilesObjs;
}

export const ACTION_TYPES = {
  FETCH_SIGNATURE: 'profile/FETCH_SIGNATURE',
  CREATE_SIGNATURE: 'profile/CREATE_SIGNATURE',
  UPDATE_ACCOUNT: 'profile/UPDATE_ACCOUNT',
  CREATE_USER: 'profile/CREATE_USER',
  UPDATE_USER: 'profile/UPDATE_USER',
  GET_USERS_BY_ENTITY: 'profile/GET_USERS_BY_ENTITY',
  GET_USER: 'profile/GET_USER',
  DELETE_USER: 'profile/DELETE_USER',
  RESET: 'authorization/RESET',
  ACTIVATE_DEACTIVATE: 'profile/ACTIVATE_DEACTIVATE',
  GET_PROFILES_STATUS: 'profile/GET_PROFILES_STATUS',
  UPDATE_PASSWORD: 'profile/UPDATE_PASSWORD',
};

const initialState = {
  loading: false,
  errorMessage: null,
  createUserErrorMessage: null,
  signature: defaultValue,
  users: [] as Array<IUser>,
  profiles: {} as Profiles,
  user: defaultUser,
  fetchSignatureStatus: HttpRequestStatus.NOOP,
  createSignatureStatus: HttpRequestStatus.NOOP,
  updateAccountStatus: HttpRequestStatus.NOOP,
  updatePasswordStatus: HttpRequestStatus.NOOP,
  createUserStatus: HttpRequestStatus.NOOP,
  updateUserStatus: HttpRequestStatus.NOOP,
  deleteUserStatus: HttpRequestStatus.NOOP,
  fetchUsersByEntityStatus: HttpRequestStatus.NOOP,
  fetchUserStatus: HttpRequestStatus.NOOP,
  profilesStatus: HttpRequestStatus.NOOP,
  totalItems: 0,
};

export type ProfileState = Readonly<typeof initialState>;

// Reducer

export default (state: ProfileState = initialState, action): ProfileState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        errorMessage: null,
        fetchSignatureStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        fetchSignatureStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
        fetchSignatureStatus: HttpRequestStatus.SUCCESS,
        signature: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.CREATE_SIGNATURE):
      return {
        ...state,
        errorMessage: null,
        createSignatureStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_SIGNATURE):
      return {
        ...state,
        createSignatureStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SIGNATURE):
      return {
        ...state,
        createSignatureStatus: HttpRequestStatus.SUCCESS,
        signature: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        errorMessage: null,
        updateAccountStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        updateAccountStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACCOUNT):
      return {
        ...state,
        updateAccountStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_USERS_BY_ENTITY):
      return {
        ...state,
        errorMessage: null,
        fetchUsersByEntityStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_USERS_BY_ENTITY):
      return {
        ...state,
        fetchUsersByEntityStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_USERS_BY_ENTITY):
      return {
        ...state,
        fetchUsersByEntityStatus: HttpRequestStatus.SUCCESS,
        users: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case REQUEST(ACTION_TYPES.CREATE_USER):
      return {
        ...state,
        errorMessage: null,
        createUserErrorMessage: null,
        createUserStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.CREATE_USER):
      return {
        ...state,
        createUserStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
        createUserErrorMessage: action.payload?.response?.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USER):
      return {
        ...state,
        createUserStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.UPDATE_USER):
      return {
        ...state,
        errorMessage: null,
        updateUserStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.UPDATE_USER):
      return {
        ...state,
        updateUserStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_USER):
      return {
        ...state,
        updateUserStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        errorMessage: null,
        deleteUserStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        deleteUserStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        deleteUserStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_USER):
      return {
        ...state,
        errorMessage: null,
        fetchUserStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.GET_USER):
      return {
        ...state,
        fetchUserStatus: HttpRequestStatus.ERROR,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_USER):
      return {
        ...state,
        fetchUserStatus: HttpRequestStatus.SUCCESS,
        user: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case REQUEST(ACTION_TYPES.ACTIVATE_DEACTIVATE):
      return {
        ...state,
        errorMessage: null,
        profilesStatus: HttpRequestStatus.ONGOING,
      };
    case FAILURE(ACTION_TYPES.ACTIVATE_DEACTIVATE):
      return {
        ...state,
        errorMessage: action.payload,
        profilesStatus: HttpRequestStatus.ERROR,
      };
    case SUCCESS(ACTION_TYPES.ACTIVATE_DEACTIVATE):
      return {
        ...state,
        profilesStatus: HttpRequestStatus.SUCCESS,
      };
    case REQUEST(ACTION_TYPES.GET_PROFILES_STATUS):
      return {
        ...state,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.GET_PROFILES_STATUS):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_PROFILES_STATUS):
      return {
        ...state,
        profiles: action.payload.data,
      };
    default:
      return state;
  }
};

const signatureUrl = 'api/signatures';
const userUrl = 'api/users';

// Actions

export const getSignature = () => {
  const requestUrl = `${signatureUrl}/current`;
  return {
    type: ACTION_TYPES.FETCH_SIGNATURE,
    payload: axios.get<ISignature>(requestUrl),
  };
};

export const createSignature: ICrudPutAction<ISignature> = entity => async dispatch => {
  const requestUrl = `${signatureUrl}/new`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SIGNATURE,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateUserOnly: ICrudPutAction<ISignature> = entity => async dispatch => {
  const requestUrl = `${userUrl}/userOnly`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ACCOUNT,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateAccount: ICrudPutAction<IUser> = entity => async dispatch => {
  const requestUrl = `${userUrl}/account`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ACCOUNT,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const getUsersByEntity: ICrudGetAllAction<IUser> = (page, size, sort) => {
  const params = organizeSearchParameters(page, size, sort, null);
  const requestUrl = `${userUrl}/entity${params}`;
  return {
    type: ACTION_TYPES.GET_USERS_BY_ENTITY,
    payload: axios.get<IUser>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getUser: ICrudGetAction<IUser> = id => {
  const requestUrl = `${userUrl}/${id}`;
  return {
    type: ACTION_TYPES.GET_USER,
    payload: axios.get<IUser>(`${requestUrl}`),
  };
};

export const createUser: ICrudPutAction<IUser> = entity => async dispatch => {
  const requestUrl = `${userUrl}/entity`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateUser: ICrudPutAction<IUser> = entity => async dispatch => {
  const requestUrl = `${userUrl}/entity`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteUser: ICrudDeleteAction<IUser> = id => async dispatch => {
  const requestUrl = `${userUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USER,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const activateDeactivateProfile = (type, id) => async dispatch => {
  const requestUrl = `api/user-${type}s/activate-deactivate/${id}`;
  await dispatch({
    type: ACTION_TYPES.ACTIVATE_DEACTIVATE,
    payload: axios.put(requestUrl),
  });
};

export const getProfilesStatus = () => async dispatch => {
  const requestUrl = `${userUrl}/profiles-status`;
  const result = await dispatch({
    type: ACTION_TYPES.GET_PROFILES_STATUS,
    payload: axios.get(requestUrl),
  });
  return result;
};

import { IRootState } from 'app/shared/reducers';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import close from '../../../content/images/icons/close.svg'
import './alert-modal.scss';

export interface IAlertModalProps extends StateProps, DispatchProps {
  showModal: boolean;
  closeModal: () => void,
}

export interface IAlertModalState { }

export class AlertModalPlan extends React.Component<IAlertModalProps, IAlertModalState> {
  constructor(props: Readonly<IAlertModalProps>) {
    super(props);
  }

  componentWillUnmount() {
    this.props.showHeader();
  }

  render() {
    const {
      showModal,
      closeModal,
    } = this.props;

    return (
      <Modal className={'alert-modal__props'} isOpen={showModal}>
        <ModalBody>
          <Row style={{ height: 'calc(100vh - 130px)', margin: 'auto' }}>
            <Col >
              <div className='containerAlert'>
                <img src={close} onClick={() => closeModal()} />
                <Translate contentKey='profile.manage-plans.change-plans' />
                <Translate contentKey='mtr.errors.change-plans' />
                <button style={{ width: '172px', padding: '0 15px', margin: '0' }} className={'button fourth'}
                  onClick={() => closeModal()}>
                  <Translate contentKey={'profile.manage-plans.change-plans-buttom'} />
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { hideHeader, showHeader };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AlertModalPlan);

import AlertModal from 'app/components/alert-modal/alert-modal';
import BackHeaderComponent from 'app/components/back-header-component/back-header-component';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import Loading from 'app/components/loading/loading';
import SignatureComponent from 'app/components/signature-component/signature-component';
import { ALL_ADMIN_AUTHORITIES, ALL_USER_AUTHORITIES, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IDocument } from 'app/shared/model/document.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { ISignature } from 'app/shared/model/signature.model';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { checkStatusOnGoing } from 'app/shared/util/validation-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { findMtr, resetStatus as resetMtrStatus } from '../mtr-reducer';
import { getResponsibleSignature, getSignature, reset, signMtr } from './mtr-signature.reducer';
import './mtr-signature.scss';
import { toast } from 'react-toastify';

export interface IMtrSignatureStepProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IMtrSignatureStepState {
  signature: ISignature;
  showSuccessModal: boolean;
  showErrorModal: boolean;
}

export class MtrSignatureStep extends React.Component<IMtrSignatureStepProps, IMtrSignatureStepState> {
  constructor(props: Readonly<IMtrSignatureStepProps>) {
    super(props);
    this.state = {
      signature: null,
      showErrorModal: false,
      showSuccessModal: false,
    };
  }

  async componentDidMount() {
    const { id } = (this.props.location.state as any) || { id: null };
    const { hasAdminAuthority, hasUserAuthority } = this.props;
    // this.props.reset();
    if (hasAdminAuthority) {
      this.props.getSignature();
    } else {
      this.props.getResponsibleSignature();
    }
    if (!id) {
      this.props.history.goBack();
    }
  }

  toggleSuccessModal = () => {
    toast.success(translate('mtr-signature.signatureSuccess'));
    this.props.history.goBack();
    // this.setState({
    //   showSuccessModal: !this.state.showSuccessModal,
    // });
  };

  toggleErrorModal = () => {
    this.setState({
      showErrorModal: !this.state.showErrorModal,
    });
  };

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.fetchSignatureStatus === HttpRequestStatus.SUCCESS && newProps.fetchSignatureStatus != this.props.fetchSignatureStatus) {
      this.setState({
        signature: newProps.signature,
      });
    }
    if (
      newProps.fetchResponsibleSignatureStatus === HttpRequestStatus.SUCCESS &&
      newProps.fetchResponsibleSignatureStatus != this.props.fetchResponsibleSignatureStatus
    ) {
      this.setState({
        signature: newProps.signature,
      });
    }
    if (newProps.signMtrStatus === HttpRequestStatus.SUCCESS) {
      this.toggleSuccessModal();
    }
    if (newProps.signMtrStatus === HttpRequestStatus.ERROR) {
      this.toggleErrorModal();
    }
    if (newProps.getMtrStatus != this.props.getMtrStatus && newProps.getMtrStatus === HttpRequestStatus.SUCCESS) {
    }
  }

  handleFileAttach = (document: IDocument) => {
    const { account } = this.props;
    this.setState(prevState => ({
      signature: {
        ...prevState.signature,
        signatureDocument: document,
        user: account,
      },
    }));
  };

  onSubmitSignature = () => {
    const { signature } = this.state;
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.signMtr(signature, id);
  };

  onBackHandler = () => {
    this.props.history.goBack();
  };

  onSuccessFinish = () => {
    const { id } = (this.props.location.state as any) || { id: null };
    this.props.findMtr(id);
    this.onBackHandler()
  };

  render() {
    const { fetchSignatureStatus, fetchResponsibleSignatureStatus, signMtrStatus } = this.props;
    const { showSuccessModal, showErrorModal, signature } = this.state;
    if (checkStatusOnGoing([fetchSignatureStatus, fetchResponsibleSignatureStatus, signMtrStatus])) {
      return <Loading />;
    }
    return (
      <div className="mtr-signature__steps">
        <BackHeaderComponent onBack={this.onBackHandler} />
        <Container>
          <Row style={{ justifyContent: 'center' }}>
            <Col md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ marginBottom: '30px' }}>
                <h1 style={{ fontWeight: 600, textTransform: 'uppercase', color: '#30AB64' }}>
                  {!this.props.hasFinalDestinationAuthorities && (
                    <Translate contentKey={'mtr-signature.title'} />
                  )}
                  {!this.props.hasManagerAuthorities && !this.props.hasTransshipmentAuthorities && !this.props.hasProducerAuthority && !this.props.hasTransporterAuthorities && (
                    <Translate contentKey={'mtr-signature.receipt'} />
                  )}
                </h1>
              </div>
              <SignatureComponent
                signature={signature ? signature.signatureDocument : null}
                responsibleName={signature ? (signature.user ? signature.user.nameTechnical : '') : ''}
                onFileAttached={this.handleFileAttach}
                className={''}
                isUser={this.props.hasUserAuthority}
              />
              {!this.props.hasFinalDestinationAuthorities && (
                <DatePickerComponent
                  dateFormat={APP_LOCAL_DATE_FORMAT}
                  onDateChange={value => {
                    this.setState(prevState => ({
                      signature: {
                        ...prevState.signature,
                        signatureDate: value,
                      },
                    }));
                  }}
                  dateSelected={signature ? signature.signatureDate : null}
                  placeholder={translate('environmentalLicense.form.emission-mtr')}
                  error={false}
                  errorMessage=""
                />
              )}
              {!this.props.hasManagerAuthorities && !this.props.hasTransshipmentAuthorities && !this.props.hasProducerAuthority && !this.props.hasTransporterAuthorities && (
                <DatePickerComponent
                  dateFormat={APP_LOCAL_DATE_FORMAT}
                  onDateChange={value => {
                    this.setState(prevState => ({
                      signature: {
                        ...prevState.signature,
                        signatureDate: value,
                      },
                    }));
                  }}
                  dateSelected={signature ? signature.signatureDate : null}
                  placeholder={translate('environmentalLicense.form.receipt-residue')}
                  error={false}
                  errorMessage=""
                />
              )}
              <div className="mtr-signature--buttons">
                <button style={{ width: '172px' }} className={'button third'} type={'submit'} onClick={() => this.onBackHandler()}>
                  <Translate contentKey={'mtr-signature.button-cancel'} />
                </button>
                <button
                  style={{ width: '172px' }}
                  className={'button primary'}
                  type={'button'}
                  disabled={
                    this.state.signature == null ||
                    this.state.signature.signatureDocument == null ||
                    this.state.signature.signatureDate == null
                  }
                  onClick={() => this.onSubmitSignature()}
                >
                  <Translate contentKey={'mtr-signature.button-sign'} />
                </button>
              </div>
            </Col>
          </Row>
        </Container>

        <AlertModal
          showModal={showSuccessModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={() => this.onSuccessFinish()}
          statusImage="assinatura.svg"
          alertMessage="alert.mtrSignatureSuccess"
        />

        <AlertModal
          showModal={showErrorModal}
          buttonTwoActionMessage={'mtr-signature.button-ok'}
          hasOneButton
          buttonTwoAction={() => this.toggleErrorModal()}
          statusImage="bag-error.svg"
          alertMessage="errorMessages.signature"
        />
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  signature: root.mtrSignature.signature,
  fetchSignatureStatus: root.mtrSignature.fetchSignatureStatus,
  fetchResponsibleSignatureStatus: root.mtrSignature.fetchResponsibleSignatureStatus,
  signMtrStatus: root.mtrSignature.signMtrStatus,
  getMtrStatus: root.mtr.getMtr,
  mtr: root.mtr.mtr,
  account: root.authentication.account,
  hasAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_ADMIN_AUTHORITIES),
  hasUserAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_USER_AUTHORITIES),
  hasTransporterAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.TRANSPORTER_ADMIN,
    AUTHORITIES.TRANSPORTER_USER,
  ]),
  hasFinalDestinationAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.FINAL_DESTINATION_ADMIN,
    AUTHORITIES.FINAL_DESTINATION_USER,
  ]),
  hasTransshipmentAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
    AUTHORITIES.TRANSSHIPMENT_ADMIN,
    AUTHORITIES.TRANSSHIPMENT_USER,
  ]),
  hasManagerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
  hasProducerAuthority: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]),
  fetchManagerProducersStatus: root.managerlink.fetchManagerProducersStatus,
  managerProducers: root.managerlink.managerProducers,
});

const mapDispatchToProps = {
  getSession,
  getSignature,
  getResponsibleSignature,
  signMtr,
  findMtr,
  resetMtrStatus,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MtrSignatureStep);

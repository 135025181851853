import { HttpRequestStatus } from '../model/enum/HttpRequestStatus';
import { translate } from 'react-jhipster';

export type Predicate<T> = (value: T | undefined | null) => boolean;

export const fetchIsOnGoing = (status: HttpRequestStatus) => {
  return status === HttpRequestStatus.ONGOING;
};

export const checkStatusOnGoing = (status: HttpRequestStatus[]) => {
  let check = false;
  status.forEach((item, index) => {
    if (item === HttpRequestStatus.ONGOING) check = true;
  });
  return check;
};

export const translateErrorMessage = (value?: string) => {
  return value ? translate(value) : '';
};

export const ValidationResultInstance: ValidResult = {
  hasError: null,
  errorMessage: undefined,
};

export interface Validation<T> {
  predicate: Predicate<T>;
  errorMessage: string;
}

export interface Validations<T> {
  [key: string]: Validation<T>;
}

export interface ValidResult {
  hasError: false;
  errorMessage?: undefined;
}

export interface InvalidReulst {
  hasError: true;
  errorMessage: string;
}

export type ValidationResult = ValidResult | InvalidReulst;

const validatePredicate = <T>(value: T | undefined | null, validation: Validation<T>): ValidationResult => {
  return validation.predicate(value) ? { hasError: false } : { hasError: true, errorMessage: validation.errorMessage };
};

const validate = <T>(value: T | undefined | null, validation: Validations<T>): ValidationResult => {
  return (
    Object.values(validation)
      .map(it => validatePredicate(value, it))
      .find(it => it.hasError) ?? { hasError: false }
  );
};

export const ValidationUtils = {
  validate,
};

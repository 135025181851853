import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {Translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {getEntity} from './user-final-destination.reducer';

export interface IUserFinalDestinationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class UserFinalDestinationDetail extends React.Component<IUserFinalDestinationDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { userFinalDestinationEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="resitrackApp.userFinalDestination.detail.title">UserFinalDestination</Translate> [
            <b>{userFinalDestinationEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="registrationNumber">
                <Translate contentKey="resitrackApp.userFinalDestination.registrationNumber">Registration Number</Translate>
              </span>
            </dt>
            <dd>{userFinalDestinationEntity.registrationNumber}</dd>
            <dt>
              <span id="classCouncil">
                <Translate contentKey="resitrackApp.userFinalDestination.classCouncil">Class Council</Translate>
              </span>
            </dt>
            <dd>{userFinalDestinationEntity.classCouncil}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userFinalDestination.user">User</Translate>
            </dt>
            <dd>{userFinalDestinationEntity.user ? userFinalDestinationEntity.user.login : ''}</dd>
            <dt>
              <Translate contentKey="resitrackApp.userFinalDestination.finalDestination">Final Destination</Translate>
            </dt>
            <dd>{userFinalDestinationEntity.finalDestination ? userFinalDestinationEntity.finalDestination.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/user-final-destination" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/user-final-destination/${userFinalDestinationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ userFinalDestination }: IRootState) => ({
  userFinalDestinationEntity: userFinalDestination.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserFinalDestinationDetail);

import React from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './license-info.scss';
import { AlertModal } from 'app/components/alert-modal/alert-modal';

export const MtrLicenseInfo = () => {
  return (
    <div className="license-info__items">
      <div className="license-info__items--back">
        <Container>
          <div className="confirmation--back">
            <div className="confirmation--back-icon" />
            <span>
              <Translate contentKey={'mtr-signature.back'} />
            </span>
          </div>
        </Container>
      </div>
      <div style={{ marginBottom: '35px' }}>
        <h1>{translate('mtr-transporter.license.title')}</h1>
      </div>
      <Row>
        <Col style={{ margin: 'auto' }} md="4">
          <div className="license-info__items--component-second">
            <div>Insira a sua licença</div>
            <button>ANEXAR LICENÇA</button>
          </div>
          <div className="license-info__items--component">Insira o tipo da licença</div>
          <div className="license-info__items--component">Insira o número da licença</div>
          <div className="license-info__items--component">Selecione o orgão emissor</div>
          <div className="license-info__items--component-calendar">
            <div>Insira a data de validade da licença</div>
            <div className="license-info__items--component-calendar-img" />
          </div>
        </Col>
      </Row>
      <div className="license-info--buttons">
        <button style={{ width: '172px' }} className={'button third'} type={'submit'}>
          <Translate contentKey={'mtr-signature.button-cancel'} />
        </button>
        <button style={{ width: '172px' }} className={'button primary'} type={'button'} onClick={() => {}}>
          <Translate contentKey={'mtr-signature.button-save'} />
        </button>
      </div>

      {/* ==========================================================================
                            MODAL LICENÇA
      ==========================================================================  */}



      <AlertModal
        showModal={false}
        buttonTwoActionMessage={'mtr-signature.button-ok'}
        hasOneButton
        buttonTwoAction={() => {}}
        statusImage="transporter-driver-1.svg"
        alertMessage="modal-transporter-license-agree"
      />
    </div>
  );
};

export default MtrLicenseInfo;

import DeleteEditButton from 'app/components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from 'app/components/simple-ordered-table/simple-ordered-table';
import SimpleSearchField from 'app/components/simple-search-field/simple-search-field';
import { ALL_ADMIN_AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { Pageable, PageableResponse } from 'app/shared/model/pageable';
import { StorageLicense } from 'app/shared/model/storage-license.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/shared/util/string-utils';
import moment from 'moment';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import {
    deleteStoragelLicense,
    getStorageLicensesByTransshipment,
    reset
} from 'app/entities/licenses/licenses-reducer';

export interface ITransshipmentLicensesListProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface ITransshipmentLicensesListState {
  filterName: string;
  page: Pageable;
}

export class TransshipmentLicensesList extends React.Component<ITransshipmentLicensesListProps, ITransshipmentLicensesListState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    { name: translate('licenses.headers.licenseDocument'), sortCode: 'licenseDocument.fileName' },
    { name: translate('licenses.headers.licenseType'), sortCode: 'storageLicenseType' },
    { name: translate('licenses.headers.licenseNumber'), sortCode: 'licenseNumber' },
    { name: translate('licenses.headers.issuingBody'), sortCode: 'issuingBody' },
    { name: translate('licenses.headers.expireDate'), sortCode: 'expireDate' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<ITransshipmentLicensesListProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getTransshipmentLicenses();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      this.props.getStorageLicensesByTransshipment();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getTransshipmentLicenses = () => {
    const { page } = this.state;
    this.props.getStorageLicensesByTransshipment(
      page.page,
      page.size,
      page.sort,
      StringUtils.toObject('licenseDocument.fileName', this.state.filterName)
    );
  };

  private handleTransformToTableContent = (content?: PageableResponse<StorageLicense>): (string | JSX.Element)[][] => {
    if (content.content == null || content.content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];

    content.content.map((item: StorageLicense) => {
      result.push([
        item.licenseDocument != null ? item.licenseDocument.fileName ?? '' : '',
        item.storageLicenseType ? translate(`enums.environmentalLicenseType.${item.storageLicenseType}`) : '',
        item.licenseNumber ? item.licenseNumber : '',
        item.issuingBody ? item.issuingBody : '',
        item.expireDate ? moment(item.expireDate).format('DD/MM/YYYY') : '',
        <DeleteEditButton
          id={item.id}
          hideDelete={!this.props.hasAnyAdminAuthority}
          hideEdit={!this.props.hasAnyAdminAuthority}
          onDelete={this.onDeleteLicense}
          onEdit={this.onEditLicense}
        />,
      ]);
    });

    return result;
  };

  onDeleteLicense = (id: number) => {
    this.props.deleteStoragelLicense(id);
  };

  onCreateLicense = () => {
    this.props.history.push(`${this.props.match.url}/new`);
  };

  onEditLicense = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/edit`,
      state: { id: id },
    });
  };

  private handleSort = (code: string) => {
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getTransshipmentLicenses()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getTransshipmentLicenses()
    );
  };

  onHandleFilterChanged = value => {
    this.setState({ filterName: value }, () => this.getTransshipmentLicenses());
  };

  onLicenseClick = (index: number) => {
    const { storageLicenses } = this.props;
    const storageLicense = storageLicenses.content[index];
    this.props.history.push({
      pathname: `${this.props.match.url}/details`,
      state: { id: storageLicense.id },
    });
  };

  render() {
    const { storageLicenses, hasAnyAdminAuthority } = this.props;
    const rows = this.handleTransformToTableContent(storageLicenses);
    return (
      <div className="mtr-unity__items mtr-invite__table">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('licenses.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px' }}>
            <Col md="6">
              <SimpleSearchField placeholder={translate('licenses.search')} onChange={this.onHandleFilterChanged} />
            </Col>
            {hasAnyAdminAuthority && (
              <Col md={{ size: 2, offset: 4 }}>
                <Button className={'button primary'} type={'submit'} onClick={this.onCreateLicense}>
                  <Translate contentKey={'licenses.add'} />
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onLicenseClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  storageLicenses: root.licenses.storageLicenses,
  deleteStatus: root.licenses.deleteStorageLicenseStatus,
  totalItems: root.licenses.totalItems,
  hasAnyAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_ADMIN_AUTHORITIES),
});

const mapDispatchToProps = {
  getStorageLicensesByTransshipment,
  deleteStoragelLicense,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransshipmentLicensesList);

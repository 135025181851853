import { IRootState } from 'app/shared/reducers';
import { hideHeader, showHeader } from 'app/shared/reducers/application-profile';
import React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import './invite-alert-page.scss';

export interface IInviteAlertPageProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IInviteAlertPageState {
}

export class InviteAlertPage extends React.Component<IInviteAlertPageProps, IInviteAlertPageState> {
    constructor(props: Readonly<IInviteAlertPageProps>) {
        super(props);
        this.state = {
        };
    }

    // componentDidMount() {
    //     const { statusImage, alertMessage, buttonUrl, buttonInviteUrl, messageParams } = this.props.location.state as any;
    //     this.setState({
    //         statusImage,
    //         alertMessage,
    //         buttonUrl,
    //         buttonInviteUrl,
    //         messageParams: messageParams ? messageParams : {},
    //     });
    // }

    onClickButton = () => {
        // const { buttonUrl } = this.state;
        this.props.history.push('/');
    };

    onInviteClickButton = () => {
        // const { buttonInviteUrl } = this.state;
        this.props.history.push('/manager/producers/link');
    }

    render() {
        // const { statusImage, alertMessage, messageParams } = this.state;

        return (
            <Container>
                <Row style={{ height: 'calc(100vh - 130px)', margin: 'auto' }}>
                    <Col style={{ margin: 'auto' }}>
                        <div className="invite-alert-page-container">
                            <div className="image-wrapper">
                                <img src={`content/images/icons/paper-ok.svg`} />
                            </div>
                            <div className="message-wrapper">
                                <p>{translate('managerInvite.messageInvite')}<span>{translate('managerInvite.message')}</span>{translate('managerInvite.messageP')}</p>
                            </div>
                            <div className={'button-container'}>
                                <button className={'button btn-style'} type={'button'} onClick={this.onClickButton}>
                                    <span>{translate('managerInvite.buttonInviteCancel')}</span>
                                </button>
                                <button className={'button primary'} type={'button'} onClick={this.onInviteClickButton}>
                                    <span>{translate('managerInvite.buttonInvite')}</span>
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { hideHeader, showHeader };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InviteAlertPage);
import {ITransportLicense} from 'app/shared/model/transport-license.model';
import {IRootState} from 'app/shared/reducers';
import React from 'react';
import {translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import NextCancelButton from '../../next-cancel-button/next-cancel-button';
import TransportLicenseForm from '../form/transport-license-form';
import './transport-license-list.scss';

export interface ITransportLicenseListProps extends StateProps, DispatchProps {
  transportLicenses: ITransportLicense[];
  onNextHandler: (transportLicenses: ITransportLicense[]) => void;
  onBack: () => void;
}

export interface ITransportLicenseListState {
  transportLicenses: ITransportLicense[];
  showForm: boolean;
  licenseIndex: number;
  transportLicenseToUpdate: ITransportLicense;
  error: boolean;
}

export class TransportLicenseList extends React.Component<ITransportLicenseListProps, ITransportLicenseListState> {
  constructor(props) {
    super(props);
    this.state = {
      transportLicenses: this.props.transportLicenses ? this.props.transportLicenses : [],
      showForm: false,
      licenseIndex: null,
      transportLicenseToUpdate: null,
      error: false,
    };
  }

  async componentDidMount() {}

  setUpdateIndex = (transportLicense: ITransportLicense, index: number) => {
    this.setState(
      {
        licenseIndex: index,
        transportLicenseToUpdate: transportLicense,
      },
      () => this.toggleShowForm()
    );
  };

  clearUpdateIndex = () => {
    this.setState({
      licenseIndex: null,
      transportLicenseToUpdate: null,
    });
  };

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  };

  onSaveTransportLicense = (transportLicense: ITransportLicense) => {
    const transportLicenses = [...this.state.transportLicenses];
    transportLicenses.push(transportLicense);
    this.setState(
      {
        transportLicenses,
        error: false,
      },
      () => this.toggleShowForm()
    );
  };

  onUpdateTransportLicense = (transportLicense: ITransportLicense, index: number) => {
    const transportLicenses = [...this.state.transportLicenses];
    transportLicenses[index] = transportLicense;
    this.setState(
      {
        transportLicenses,
      },
      () => {
        this.clearUpdateIndex();
        this.toggleShowForm();
      }
    );
  };

  onDeleteTransportLicense = (index: number) => {
    const transportLicenses = [...this.state.transportLicenses];
    transportLicenses.splice(index, 1);
    this.setState({
      transportLicenses,
    });
  };

  onClose = () => {
    this.clearUpdateIndex();
    this.toggleShowForm();
  };

  renderTransportLicenseForm = () => {
    const { licenseIndex, transportLicenseToUpdate } = this.state;

    return (
      <TransportLicenseForm
        index={licenseIndex}
        transportLicense={transportLicenseToUpdate ? transportLicenseToUpdate : {}}
        onCreate={this.onSaveTransportLicense}
        onUpdate={this.onUpdateTransportLicense}
        onClose={this.onClose}
      />
    );
  };

  onNextHandler = () => {
    const { transportLicenses } = this.state;
    if (transportLicenses.length === 0) {
      this.setState({
        error: true,
      });
      return;
    }
    this.props.onNextHandler(transportLicenses);
  };

  renderList = () => {
    const { transportLicenses, error } = this.state;
    return (
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <div className="transport-list__container">
            <h1>{translate('transportLicense.addTitle')}</h1>
            <div>
              <AddLicenseButton onClick={this.toggleShowForm} />
            </div>
            {error && <span className="datepicker__error">{translate('validation.licenseList.empty')}</span>}
            <div>
              {transportLicenses &&
                transportLicenses.map((item, index) => (
                  <TransportLicenseItem
                    index={index}
                    transportLicense={item}
                    onClick={this.setUpdateIndex}
                    onDelete={this.onDeleteTransportLicense}
                  />
                ))}
            </div>
            <NextCancelButton onNextClick={this.onNextHandler} onCancelClick={this.props.onBack} />
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const { showForm } = this.state;
    return (
      <div>
        {showForm && this.renderTransportLicenseForm()}
        {!showForm && this.renderList()}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (root: IRootState) => ({});
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TransportLicenseList);

export const AddLicenseButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <div className="add-license__button" onClick={onClick}>
      <div className="plus-sign__image" />
      <span>{translate('transportLicense.add').toUpperCase()}</span>
    </div>
  );
};

export const TransportLicenseItem = ({
  index,
  transportLicense,
  onClick,
  onDelete,
}: {
  index: number;
  transportLicense: ITransportLicense;
  onClick: (transportLicense: ITransportLicense, index: number) => void;
  onDelete: (index: number) => void;
}): JSX.Element => {
  return (
    <div
      className="transport-item__container"
      key={`item-${index}-${transportLicense.licenseNumber}`}
      onClick={() => onClick(transportLicense, index)}
    >
      <span>{transportLicense.licenseNumber}</span>
      <div className="invoice-table--img">
        <div
          className="invoice-table--delete-img"
          onClick={e => {
            e.stopPropagation();
            onDelete(index);
          }}
        />
        <div className="invoice-table--open-img" onClick={() => onClick(transportLicense, index)} />
      </div>
    </div>
  );
};

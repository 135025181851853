import { HttpStatusNumber } from 'app/shared/model/enum/HttpStatusNumber';
import { MtrReport } from 'app/shared/model/mtr-report.model';
import { ISignature } from 'app/shared/model/signature.model';
import axios, { AxiosResponse } from 'axios';

const basePath = '/api/mtr';

const MtrSignatureApi = () => {
  const signMtr = async (id: MtrReport, signature: ISignature): Promise<AxiosResponse<void>> => {
    const mtrIds = id.mtrs.map(mtr => mtr.id);

    const responses: AxiosResponse<void>[] = [];

    for (const mtrId of mtrIds) {
      const requestUrl = `${basePath}/sign/${mtrId}`;
      try {
        const response = await axios.post<void>(requestUrl, signature);
        responses.push(response);
      } catch (error) {
        // Tratar erro, se necessário
      }
    }
    // Aqui você pode retornar uma resposta "manual" com o status que preferir,
    // já que não há um corpo de resposta real (void)
    return {
      data: undefined,
      status: HttpStatusNumber.OK,
      statusText: 'Success',
      headers: {},
      config: {},
    };
  };
  return {
    signMtr,
  };
};

export default MtrSignatureApi();

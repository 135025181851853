import React from 'react';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { Translate, translate } from 'react-jhipster';
import './mtr-residues.scss';
import TableList from '../residues/table-list';
import Search from '../residues/search';

export const MtrResidues = () => {
  return (
    <div className="mtr-residues__items">
      <Container>
        <Row>
          <Col style={{ margin: 'auto' }} md="12">
            <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight:'600' }}>{translate('mtr-transporter.residues.title')}</h1>
          </Col>
        </Row>
        <Row style={{ marginBottom: '27px'}}>
          <Col md="6">
            <Search />
          </Col>
          <Col md={{ size: 2, offset: 4 }}>
            <Button className={'button primary'} type={'submit'}>
              <Translate contentKey={'mtr-transporter.residues.button'} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableList />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MtrResidues;

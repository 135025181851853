import { IDocument } from 'app/shared/model/document.model';
import { IUser } from 'app/shared/model/user.model';

export interface ISignature {
  id?: number;
  signatureDocument?: IDocument;
  user?: IUser;
  hasSignature?: boolean;
  signatureDate?: string;
}

export const defaultValue: Readonly<ISignature> = {};
